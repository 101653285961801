<template>
    <modal :show="show" v-if=" isValid('CanAddCategory') || isValid('CanEditCategory') ">

        <div style="margin-bottom:0px" class="card" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <div class="card-body">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="modal-header" v-if="type=='Edit'">

                            <h5 class="modal-title DayHeading" id="myModalLabel"> {{ $t('AddCategory.UpdateCategory') }}</h5>

                        </div>
                        <div class="modal-header" v-else>

                            <h5 class="modal-title DayHeading" id="myModalLabel"> {{ $t('AddCategory.AddCategory') }}</h5>

                        </div>
                        <div>
                            <div class="card-body">
                                <div class="row ">
                                    <div v-bind:key="rendered" class=" col-sm-12" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                        <label>{{ $t('AddCategory.Code') }} :<span class="LabelColour"> *</span></label>
                                        <div v-bind:class="{'has-danger' : $v.category.code.$error}">
                                            <input readonly class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.category.code.$model" />
                                            <span v-if="$v.category.code.$error" class="error text-danger">
                                            </span>
                                        </div>
                                    </div>
                                    <div v-if="english=='true'" class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.category.name.$error} && ($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                        <label class="text  font-weight-bolder"> {{ $t('AddCategory.CategoryName') |englishLanguage }}:<span class="LabelColour"> *</span> </label>
                                        <input class="form-control" v-model="$v.category.name.$model" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" type="text" />
                                        <span v-if="$v.category.name.$error" class="error">
                                            <span v-if="!$v.category.name.required"> {{ $t('AddCategory.NameRequired') }}</span>
                                            <span v-if="!$v.category.name.maxLength">{{ $t('AddCategory.NameLength') }}</span>
                                        </span>
                                    </div>

                                    <div v-if="isOtherLang()" class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.category.nameArabic.$error}">
                                        <label class="text  font-weight-bolder">{{ $t('AddCategory.CategoryNameAr') |arabicLanguage }}:<span class="LabelColour"> *</span> </label>
                                        <input class="form-control "  v-bind:class="isLeftToRight() ? 'text-left' : 'arabicLanguage'"  v-model="$v.category.nameArabic.$model" type="text" />
                                        <span v-if="$v.category.nameArabic.$error" class="error">
                                            <span v-if="!$v.category.nameArabic.required"> {{ $t('AddCategory.NameRequired') }}</span>
                                            <span v-if="!$v.category.nameArabic.maxLength">{{ $t('AddCategory.NameLength') }}</span>
                                        </span>
                                    </div>

                                    <!--<div class="col-sm-12">
                                        <label>COSG Account :<span class="LabelColour"> *</span></label>
                                        <div>
                                            <accountdropdown v-model="category.cOGSAccountId"></accountdropdown>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <label>Inventory Account :<span class="LabelColour"> *</span></label>
                                        <div>
                                            <accountdropdown v-model="category.inventoryAccountId"></accountdropdown>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <label>Income Account :<span class="LabelColour"> *</span></label>
                                        <div>
                                            <accountdropdown v-model="category.incomeAccountId"></accountdropdown>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <label>Purchase Account :<span class="LabelColour"> *</span></label>
                                        <div>
                                            <accountdropdown v-model="category.purchaseAccountId"></accountdropdown>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <label>Sale Account :<span class="LabelColour"> *</span></label>
                                        <div>
                                            <accountdropdown v-model="category.saleAccountId"></accountdropdown>
                                        </div>
                                    </div>-->
                                    <!--<div class="col-sm-12" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
        <label>{{ $t('AddCategory.Description') }} :</label>
        <div v-bind:class="{'has-danger' : $v.category.description.$error}">
            <textarea class="form-control" v-model="$v.category.description.$model" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" />
            <span v-if="$v.category.description.$error" class="error">
                {{ $t('AddCategory.descriptionLength150') }}
            </span>
        </div>
    </div>-->
                                    
                                    <div class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.category.description.$error}">
                                        <label class="text  font-weight-bolder"> {{ $t('AddCategory.Description') }}: </label>
                                        <textarea class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.category.description.$model" type="text" />
                                        <span v-if="$v.category.description.$error" class="error">{{ $t('AddCategory.descriptionLength150') }}</span>
                                    </div>
                                    <div class="form-group col-md-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                        <label style="margin: 7px;">{{ $t('AddCategory.Active') }}</label> <br />
                                        <div class="bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-animate" v-bind:class="{'bootstrap-switch-on': category.isActive, 'bootstrap-switch-off': !category.isActive}" v-on:click="category.isActive = !category.isActive" style="width: 72px;">
                                            <div class="bootstrap-switch-container" style="width: 122px; margin-left: 0px;">
                                                <span class="bootstrap-switch-handle-on bootstrap-switch-success" style="width: 50px;">
                                                    <i class="nc-icon nc-check-2"></i>
                                                </span>
                                                <span class="bootstrap-switch-label" style="width: 30px;">&nbsp;</span>
                                                <span class="bootstrap-switch-handle-off bootstrap-switch-success" style="width: 50px;">
                                                    <i class="nc-icon nc-simple-remove"></i>
                                                </span>
                                                <input class="bootstrap-switch" type="checkbox" data-toggle="switch" checked="" data-on-label="<i class='nc-icon nc-check-2'></i>" data-off-label="<i class='nc-icon nc-simple-remove'></i>" data-on-color="success" data-off-color="success">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                        <label style="margin: 7px;">IsService</label> <br />
                                        <div class="bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-animate" v-bind:class="{'bootstrap-switch-on': category.isService, 'bootstrap-switch-off': !category.isService}" v-on:click="category.isService = !category.isService" style="width: 72px;">
                                            <div class="bootstrap-switch-container" style="width: 122px; margin-left: 0px;">
                                                <span class="bootstrap-switch-handle-on bootstrap-switch-success" style="width: 50px;">
                                                    <i class="nc-icon nc-check-2"></i>
                                                </span>
                                                <span class="bootstrap-switch-label" style="width: 30px;">&nbsp;</span>
                                                <span class="bootstrap-switch-handle-off bootstrap-switch-success" style="width: 50px;">
                                                    <i class="nc-icon nc-simple-remove"></i>
                                                </span>
                                                <input class="bootstrap-switch" type="checkbox" data-toggle="switch" checked="" data-on-label="<i class='nc-icon nc-check-2'></i>" data-off-label="<i class='nc-icon nc-simple-remove'></i>" data-on-color="success" data-off-color="success">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                        <label style="margin: 7px;">Return Days</label> <br />
                                        <div class="bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-animate" v-bind:class="{'bootstrap-switch-on': category.isReturn, 'bootstrap-switch-off': !category.isReturn}" v-on:click="category.isReturn = !category.isReturn" style="width: 72px;">
                                            <div class="bootstrap-switch-container" style="width: 122px; margin-left: 0px;">
                                                <span class="bootstrap-switch-handle-on bootstrap-switch-success" style="width: 50px;">
                                                    <i class="nc-icon nc-check-2"></i>
                                                </span>
                                                <span class="bootstrap-switch-label" style="width: 30px;">&nbsp;</span>
                                                <span class="bootstrap-switch-handle-off bootstrap-switch-success" style="width: 50px;">
                                                    <i class="nc-icon nc-simple-remove"></i>
                                                </span>
                                                <input class="bootstrap-switch" type="checkbox" data-toggle="switch" checked="" data-on-label="<i class='nc-icon nc-check-2'></i>" data-off-label="<i class='nc-icon nc-simple-remove'></i>" data-on-color="success" data-off-color="success">
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="category.isReturn" class="form-group has-label col-sm-12 ">
                                        <label class="text  font-weight-bolder"> Return Days:</label>
                                        <input class="form-control" v-model="category.returnDays" @click="$event.target.select()" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" type="text" />

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="!loading">
                            <div class="modal-footer justify-content-right" v-if="type=='Edit' && isValid('CanEditCategory')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <button type="button" class="btn btn-primary  " v-on:click="SaveCategory" v-bind:disabled="$v.category.$invalid"> {{ $t('AddCategory.btnUpdate') }}</button>
                                <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{ $t('AddCategory.btnClear') }}</button>
                            </div>
                            <div class="modal-footer justify-content-right" v-if="type!='Edit' && isValid('CanAddCategory')">
                                <button type="button" class="btn btn-primary  " v-on:click="SaveCategory" v-bind:disabled="$v.category.$invalid"> {{ $t('AddCategory.btnSave') }}</button>
                                <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{ $t('AddCategory.btnClear') }}</button>
                            </div>
                        </div>
                        <div v-else>
                            <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
    <acessdenied v-else :model=true></acessdenied>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import { required, maxLength, requiredIf } from "vuelidate/lib/validators"
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default ({
        mixins: [clickMixin],
        name: 'AddCategory',
        props: ['show', 'category', 'type'],
        components: {
            Loading
        },
        data: function () {

            return {
                value: '',
                loading: false,
                rendered: 0,
                arabic: '',
                english: ''

            }
        },
        validations: {
            category:
            {
                code: { required },
                name: {
                    maxLength: maxLength(250)
                },
                nameArabic: {
                    required: requiredIf((x) => {
                        if (x.name == '' || x.name == null)
                            return true;
                        return false;
                    }),
                    maxLength: maxLength(250)
                },
                description: {
                    maxLength: maxLength(500)
                },
                purchaseAccountId: {},
                cOGSAccountId: {},
                inventoryAccountId: {},
                incomeAccountId: {},
                saleAccountId: {}


            }
        },
        methods: {
            close: function () {
                this.$emit('close');
            },
            SaveCategory: function () {
                this.loading = true;
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                if (this.category.isReturn == false) {
                    this.category.returnDays = 0;
                }
                this.$https
                    .post('/Product/SaveCategoryInformation', this.category, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {

                        if (response.data.isSuccess == true) {
                            if (root.type != "Edit") {
                                root.$swal({
                                    title: "Saved!",
                                    text: "Saved Successfully!",
                                    type: 'success',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 1500,
                                    timerProgressBar: true,
                                });
                                root.$emit('IsSave');
                                root.close();
                            }
                            else {


                                root.$swal({
                                    title: "Update!",
                                    text: "Update Successfully!",
                                    type: 'success',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 1500,
                                    timerProgressBar: true,
                                });
                                root.close();
                                root.$emit('IsSave');
                            }
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Something Went Wrong!',
                                text: error.response.data,
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false
                    })
                    .finally(() => root.loading = false)
            },
            AutoIncrementCode: function () {
                var root = this;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https
                    .get('/Product/CategoryAutoGenerateCode', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                        if (response.data != null) {
                            root.category.code = response.data;
                            root.rendered++
                        }
                    });
            },
        }
        ,
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            if (this.category.id == "00000000-0000-0000-0000-000000000000") {
                this.AutoIncrementCode();
            }
        }
    })

</script>