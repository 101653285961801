<template>
    <div class="Warehouse" v-if="isValid('CanViewWareHouse') || isValid('Noble Admin')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
        <div class="row">
            <div class="col-lg-12 col-sm-12 ml-auto mr-auto">
                <div class="card ">
                    <div class="card-header">
                        <div class="BorderBottom ml-2 mr-2">
                            <span class=" DayHeading">{{ $t('Warehouse.WareHouse') }}</span>
                        </div>

                        
                    </div>
              
                    <div class="card-body">
                        <div class="col-lg-12">
                            <div class="row" >
                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                    <div class="form-group">
                                        <label>{{ $t('Warehouse.SearchbyCategory') }}</label>
                                        <div>
                                            <input type="text" class="form-control search_input" v-model="searchQuery" name="search" id="search" :placeholder="$t('Warehouse.Search')" />
                                            <span class="fas fa-search search_icon"></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'" style="margin-top:18px;">
                                    <a v-if="isValid('CanAddWareHouse')" href="javascript:void(0)" class="btn btn-primary" v-on:click="AddWarehouse"><i class="fa fa-plus"></i>  {{ $t('Warehouse.AddNew') }}</a>
                                    <router-link :to="'/StartScreen'">
                                        <a href="javascript:void(0)" class="btn btn-outline-danger ">  {{ $t('Warehouse.Close') }} </a>
                                    </router-link>
                                </div>

                            </div>
                            
                            <div class="mt-2">
                                <div class=" table-responsive">
                                    <table class="table table-striped table-hover table_list_bg ">
                                        <thead class="m-0">
                                            <tr>
                                                <th>
                                                    #
                                                </th>
                                                <th v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                    {{ $t('Warehouse.Code') }}
                                                </th>
                                                <th v-if="english=='true'" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                    {{ $t('Warehouse.NameEn') |englishLanguage}}
                                                </th>
                                                <th v-if="isOtherLang()" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                    {{ $t('Warehouse.NameAr') |arabicLanguage}}
                                                </th>
                                                <th v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                    {{ $t('Warehouse.address') }}
                                                </th>
                                                <th v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                    {{ $t('Warehouse.city') }}
                                                </th>
                                                <th v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                    {{ $t('Warehouse.Active') }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(details,index) in resultQuery" v-bind:key="details.id">
                                                <td>
                                                    {{index+1}}
                                                </td>
                                                <td v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-if="isValid('CanEditWareHouse') || isValid('Noble Admin')">
                                                    <strong>
                                                        <a href="javascript:void(0)" v-on:click="EditWarehouseInfo(details.id)">{{details.storeID}}</a>
                                                    </strong>
                                                </td>
                                                <td v-else>
                                                    {{details.storeID}}
                                                </td>
                                                <td v-if="english=='true'" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">{{details.name}}</td>
                                                <td v-if="isOtherLang()" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">{{details.nameArabic}}</td>
                                                <td v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">{{details.address}}</td>
                                                <td v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">{{details.city}}</td>
                                                <td v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">{{details.isActive==true?$t('Warehouse.Active'):$t('Warehouse.De-Active')}}</td>
                                                <!--<td v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-if="!details.isActive">
                            <a href="javascript:void(0)" class="btn btn-danger btn-sm btn-icon " v-on:click="DeleteWarehouseInfo(details.id)"><i class=" fa fa-trash"></i></a>
                        </td>-->
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],

        name: 'Warehouse',
        data: function () {
            return {
                arabic: '',
                english: '',
                show: false,
                searchQuery: '',
                companyId: '00000000-0000-0000-0000-000000000000',
                warehouselist:[

                ]

            }
        },
        computed: {
            resultQuery: function () {
                var root = this;
                if (this.searchQuery) {
                    
                    return this.warehouselist.filter((cur) => {
                        return root.searchQuery.toLowerCase().split(' ').every(v => cur.name.toLowerCase().includes(v) || cur.nameArabic.toLowerCase().includes(v))
                    })
                } else {
                    return root.warehouselist;
                }
            },
        },
        methods: {
            AddWarehouse: function () {
               this.$router.push('/AddWarehouse') 
            },
            GetWarehouseData: function (comapnyId) {
                var root = this;
                var url = '/Company/GetWarehouseInformation?companyId=' + comapnyId;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get(url, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.$store.dispatch('GetWareHouseList', response.data.warehousesListModels);

                        root.warehouselist = response.data.warehousesListModels;
                    }
                });
            },
            EditWarehouseInfo: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Company/WarehouseDetailsViaId?Id=' + id + '&companyId=' + this.companyId, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.$router.push({
                            path: '/AddWarehouse',
                            query: { data: response.data, companyId: root.$route.query.id}
                        })
                    }
                });
                
            },
            DeleteWarehouseInfo: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Company/DeleteWarehouse?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        
                        root.$swal.fire({
                            icon:'warning',
                            title:'Deleted Successfully',
                            showConfirmButton: false,
                            timer: 800,
                            timerProgressBar: true,
                        });
                        root.GetWarehouseData();
                    }
                });
            }
        },
        created: function () {
            this.$emit('input', this.$route.name);
        },
        mounted: function () {
            
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            if (this.$route.query.id != undefined) {
                this.companyId = this.$route.query.id;
                this.GetWarehouseData(this.$route.query.id);
            } else {
                this.GetWarehouseData('00000000-0000-0000-0000-000000000000');
            }
        }
    }
</script>