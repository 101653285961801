<template>
    <div class="row" v-if="isValid('CanViewDetailSaleReturn')">
        <div class="col-xs-12 col-sm-9 col-md-9 col-lg-9">
            <div class="card" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                <div class="card-header ">
                    <div class="row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                            <h5 class="view_page_title">{{ $t('ViewSaleReturn.SaleReturn') }}</h5>
                        </div>
                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                            <a href="javascript:void(0)" class="btn btn-outline-danger" @click="goToSale">  <i class="fas fa-arrow-circle-left fa-lg"></i> </a>
                        </div>

                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                            <h5>{{ $t('ViewSaleReturn.From') }}</h5>
                            <div class="card" style="border: 1px #dddddd solid;">
                                <div class="card-body">
                                    <label>{{($i18n.locale == 'en' ||isLeftToRight())?headerFooter.company.nameEnglish:headerFooter.company.companyNameArabic}}</label>
                                    <br />
                                    <label>{{($i18n.locale == 'en' ||isLeftToRight())?headerFooter.company.addressEnglish:headerFooter.company.addressArabic}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                            <h5>{{ $t('ViewSaleReturn.InvoiceTo') }}</h5>
                            <div class="card" style="border: 1px #dddddd solid;">
                                <div class="card-body">
                                    <label v-if="!sale.isCredit">{{($i18n.locale == 'en' ||isLeftToRight())?sale.cashCustomer:(sale.cashCustomer==''?sale.cashCustomer:sale.cashCustomer)}}</label> <label>{{($i18n.locale == 'en' ||isLeftToRight())?sale.customerNameEn:(sale.customerNameAr==''?sale.customerNameEn:sale.customerNameAr)}}</label>
                                    <br />
                                    <label>{{sale.customerAddress}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body  mt-0 pt-0">
                    <div class="tab-content" id="nav-tabContent">

                        <!--<div class="row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">




                        <div class="col-lg-6" v-if="!sale.isCredit">
                            {{ $t('SaleReturn.Mobile') }}
                        </div>
                        <div v-if="!sale.isCredit" class="col-lg-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                            {{ sale.mobile }}
                        </div>
                        <div class="col-lg-6">
                            {{ $t('SaleReturn.WareHouse') }}
                        </div>
                        <div class="col-lg-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                            {{ sale.wareHouseName }}
                        </div>
                    </div>
                    <br />-->
                        <view-sale-return-item :saleItems="sale.saleItems" @input="SaveSaleItems" @summary="updateSummary" :key="rendered" />

                        <div class="row">
                            <div class="col-md-6 ">
                                <button class="btn btn-primary mr-2" v-on:click="Attachment()">
                                    {{ $t('ViewSaleReturn.Attachment') }}
                                </button>
                            </div>
                            <div class="col-md-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                                <button class="btn btn-danger  mr-2"
                                        v-on:click="goToSale">
                                    {{ $t('ViewSaleReturn.Cancel') }}
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
        <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                            <h5 class="view_page_title">{{ $t('ViewSaleReturn.BasicInfo') }}</h5>
                        </div>
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                            <vue-qrcode v-bind:value="qrValue" style="width:140px;" />
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <label class="invoice_lbl">{{ $t('ViewSaleReturn.SaleReturn') }}#</label>
                            <hr style="margin-top: 0.3rem; margin-bottom: 0.1rem;" />
                            <label>{{sale.registrationNo}}-<span v-if='sale.isCredit'> Credit</span><span v-else> Cash</span></label>
                            <hr style="margin-top: 0.1rem;" />
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <label class="invoice_lbl">{{ $t('ViewSaleReturn.InvoiceNo') }}#</label>
                            <hr style="margin-top: 0.3rem; margin-bottom: 0.1rem;" />
                            <label>{{ sale.invoiceNo }}</label>
                            <hr style="margin-top: 0.1rem;" />
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            <label class="invoice_lbl">{{ $t('ViewSaleReturn.WareHouse') }}</label>
                            <hr style="margin-bottom: 0.1rem; margin-top: 0.3rem;" />
                            <label> {{($i18n.locale == 'en' ||isLeftToRight())?sale.wareHouseName:(sale.wareHouseNameAr==''?sale.wareHouseName:sale.wareHouseNameAr)}}</label>
                            <hr style="margin-top: 0.1rem;" />
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            <label class="invoice_lbl">{{ $t('ViewSaleReturn.Mobile') }}</label>
                            <hr style="margin-bottom: 0.1rem; margin-top: 0.3rem;" />
                            <label> {{ sale.mobile }}</label>
                            <hr style="margin-top: 0.1rem;" />
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            <label class="invoice_lbl">{{ $t('ViewSaleReturn.Date') }}</label>
                            <hr style="margin-bottom: 0.1rem; margin-top: 0.3rem;" />
                            <label>{{sale.date}}</label>
                            <hr style="margin-top: 0.1rem;" />
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            <label class="invoice_lbl">{{ $t('ViewSaleReturn.DueDate') }}</label>
                            <hr style="margin-bottom: 0.1rem; margin-top: 0.3rem;" />
                            <label>{{sale.dueDate}}</label>
                            <hr style="margin-top: 0.1rem;" />
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            <label class="invoice_lbl">Send Copy To</label>
                            <hr style="margin-bottom: 0.1rem; margin-top: 0.3rem;" />
                            <label>Email</label>
                            <hr style="margin-top: 0.1rem;" />
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            <button class="btn btn-primary btn-block">Send Invoice</button>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 mt-2">
                            <button class="btn btn-light btn-block text-left">PDF <i class="fas fa-file-pdf float-right" style="color:#EB5757;"></i></button>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 mt-2">
                            <button class="btn btn-light btn-block text-left">Sheets <i class="fas fa-file-excel float-right" style="color:#198754;"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <bulk-attachment :documentid="sale.id" :show="show" v-if="show" @close="attachmentSave" />

    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import VueQrcode from 'vue-qrcode'

    /* END EXTERNAL SOURCE */
    /* BEGIN EXTERNAL SOURCE */

    import moment from "moment";
    import "vue-loading-overlay/dist/vue-loading.css";
    import { required, requiredIf } from "vuelidate/lib/validators";
    //import VueBarcode from 'vue-barcode';
    export default {
        components: {
            VueQrcode,
        },
        mixins: [clickMixin],
        name: "AddSaleReturn",

        data: function () {
            return {
                toggleValue: '',
                buttons: [
                    {
                        id: false,
                        icon: "money-bill-alt-regular.svg",
                        title: "Cash",
                    },
                    {
                        id: true,
                        icon: "credit-card-solid.svg",
                        title: "Credit",
                    },
                ],
                rendered: 0,
                warehouseRendered: 0,
                qrValue: '',

                sale: {
                    id: "00000000-0000-0000-0000-000000000000",
                    date: "",
                    saleInvoiceId: "",
                    registrationNo: "",
                    customerId: "00000000-0000-0000-0000-000000000000",
                    dueDate: "",
                    wareHouseId: "",
                    saleItems: [],
                    isCredit: false,
                    cashCustomer: "",
                    mobile: "",
                    code: "",
                    invoiceType: "",
                    customerName: "",
                    wareHouseName: "",
                    invoiceNo: "",
                    isSaleReturnPost: false
                },
                loading: false,
                show: false,
                summary: Object,
                autoNumber: '',
                language: 'Nothing',
                headerFooter: {
                    footerEn: '',
                    footerAr: '',
                    company: ''
                },
            };
        },
        validations: {
            sale: {
                date: { required },
                registrationNo: { required },
                customerId: {
                    required: requiredIf((x) => x.isCredit)
                },
                dueDate: {},
                wareHouseId: {},
                saleItems: {
                    required
                },
                cashCustomer: {
                    required: requiredIf((x) => !x.isCredit && (x.customerId == '00000000-0000-0000-0000-000000000000' || x.customerId == null))

                },
                mobile: {},
                code: {},
            }
        },
        computed: {
            calulateTotalQty: function () {
                return this.sale.saleItems.reduce(function (a, c) { return a + (Number((c.quantity) || 0) > 0 ? Number((c.quantity) || 0) : 0) }, 0)
            },
            calulateNetTotal: function () {
                return this.sale.saleItems.reduce(function (a, c) { return a + Number((c.total + c.includingVat) || 0) }, 0)
            },
            calulateTotalExclVAT: function () {
                return this.sale.saleItems.reduce(function (a, c) { return a + Number((c.total) || 0) }, 0)
            },
            calulateTotalVAT: function () {
                return this.sale.saleItems.reduce(function (a, c) { return a + Number((c.includingVat + c.inclusiveVat) || 0) }, 0)
            },
            calulateTotalInclusiveVAT: function () {
                return this.sale.saleItems.reduce(function (a, c) { return a + Number((c.inclusiveVat) || 0) }, 0)
            },
            calulateDiscountAmount: function () {
                return this.sale.saleItems.reduce(function (a, c) { return a + Number(c.discountAmount || 0) }, 0)
            },
            calulateBundleAmount: function () {
                return this.sale.saleItems.reduce(function (a, c) { return a + Number(c.bundleAmount || 0) }, 0)
            }

        },
        methods: {
            calulateDiscountAmount1: function () {
                return this.sale.saleItems.reduce(function (a, c) { return a + Number(c.discountAmount || 0) }, 0)
            },
            calulateBundleAmount1: function () {
                return this.sale.saleItems.reduce(function (a, c) { return a + Number(c.bundleAmount || 0) }, 0)
            },
            calulateNetTotalWithVAT: function () {
                var total = this.sale.saleItems.reduce(function (a, c) { return a + Number((c.total + c.includingVat) || 0) }, 0);
                var grandTotal = parseFloat(total) - (this.calulateDiscountAmount1() + this.calulateBundleAmount1())
                return (parseFloat(grandTotal).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,"));
            },
            calulateTotalVATofInvoice: function () {
                var total = this.sale.saleItems.reduce(function (a, c) { return a + Number((c.includingVat + c.inclusiveVat) || 0) }, 0);
                return (parseFloat(total).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,"));
            },
            Attachment: function () {
                this.show = true;
            },

            attachmentSave: function () {
                this.show = false;
            },

            isCredit: function (credit) {
                this.sale.isCredit = credit.id;
                if (!this.sale.isCredit) {
                    this.sale.customerId = null;
                }

            },

            getSaleDetail: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https.get('/Sale/SaleDetail?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {

                            root.sale.isCredit = response.data.isCredit;
                            root.sale.invoiceType = response.data.invoiceType;
                            root.sale.cashCustomer = response.data.cashCustomer;
                            root.sale.date = moment().format("LLL");
                            root.sale.dueDate = moment().format("LLL");
                            root.sale.mobile = response.data.mobile;
                            root.sale.code = response.data.code;
                            root.sale.customerId = response.data.customerId;
                            root.sale.saleItems = response.data.saleItems;

                            root.rendered++;
                        }
                    },
                        function (error) {
                            console.log(error);
                        });
            },

            updateSummary: function (summary) {
                this.summary = summary;
            },
            AutoIncrementCode: function () {
                var root = this;
                var token = "";
                if (root.$session.exists()) {
                    token = localStorage.getItem("token");
                }
                root.$https
                    .get("/Sale/SaleAutoGenerateNo?invoiceType=" + "SaleReturn", {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then(function (response) {
                        if (response.data != null) {
                            root.sale.registrationNo = response.data.saleReturn;
                        }
                    });
            },
            SaveSaleItems: function (saleItems) {
                this.sale.saleItems = saleItems;
            },
            saveSaleReturn: function (invoiceType, status) {
                this.loading = true;
                var root = this;

                root.sale.invoiceType = invoiceType;
                root.sale.isSaleReturnPost = status;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                if (!this.sale.isCredit) {
                    this.sale.dueDate = moment().format("DD MMM YYYY");
                }

                this.$https
                    .post('/Sale/SaveSaleReturn', root.sale, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {
                        if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Add") {
                            root.loading = false
                            root.info = response.data.bpi

                            root.$swal({
                                title: "Saved!",
                                text: response.data.message.isAddUpdate,
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonStyling: false,
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,

                            }).then(function (ok) {
                                if (ok != null) {
                                    root.$router.go();
                                }
                            });
                        }
                        else if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Update") {
                            root.loading = false
                            root.info = response.data.bpi

                            root.$swal({
                                title: "Saved!",
                                text: response.data.message.isAddUpdate,
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonStyling: false,
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,

                            }).then(function (ok) {
                                if (ok != null) {
                                    root.$router.go();
                                }
                            });
                        }
                        else {
                            root.$swal({
                                title: 'Error!',
                                text: response.data.message.isAddUpdate,
                                type: 'error',
                                confirmButtonClass: "btn btn-danger",
                                icon: 'error',
                                timer: 1500,
                                timerProgressBar: true,
                            });
                        }

                    })
                    .catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Something Went Wrong!',
                                text: error,
                            });

                        root.loading = false
                    })
                    .finally(() => root.loading = false)

            },

            goToSale: function () {
                this.$router.push({
                    path: '/SaleReturn',
                    query: {
                        data: 'AddSaleReturns'
                    }
                });

            },

            setDefaultWareHouse: function (id) {
                this.sale.wareHouseId = id;
                this.warehouseRendered++;
            },
            GetHeaderDetail: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get("/Company/GetCompanyDetail?id=" + localStorage.getItem('CompanyID'), { headers: { Authorization: `Bearer ${token}` }, })
                    .then(function (response) {
                        if (response.data != null) {
                            root.headerFooter.company = response.data;
                            var sellerNameBuff = root.GetTLVForValue('1', root.headerFooter.company.nameEnglish)
                            var vatRegistrationNoBuff = root.GetTLVForValue('2', root.headerFooter.company.vatRegistrationNo)
                            var timeStampBuff = root.GetTLVForValue('3', root.sale.date)
                            var totalWithVat = root.GetTLVForValue('4', root.calulateNetTotalWithVAT())
                            var totalVat = root.GetTLVForValue('5', root.calulateTotalVATofInvoice())
                            var tagArray = [sellerNameBuff, vatRegistrationNoBuff, timeStampBuff, totalWithVat, totalVat]
                            var qrCodeBuff = Buffer.concat(tagArray)
                            root.qrValue = qrCodeBuff.toString('base64')
                        }
                    });
            },
            GetTLVForValue: function (tagNumber, tagValue) {
                var tagBuf = Buffer.from([tagNumber], 'utf-8')
                var tagValueLenBuf = Buffer.from([tagValue.length], 'utf-8')
                var tagValueBuf = Buffer.from(tagValue, 'utf-8')
                var bufsArray = [tagBuf, tagValueLenBuf, tagValueBuf]
                return Buffer.concat(bufsArray)
            }
        },
        created: function () {
            this.$emit('input', this.$route.name);

            if (this.$route.query.data != undefined) {
                var data = this.$route.query.data;
                this.sale.id = data.id;
                this.sale.wareHouseId = data.wareHouseId;
                this.sale.invoiceType = data.invoiceType;
                this.sale.cashCustomer = data.cashCustomer;
                this.sale.date = moment(data.date).format("LLL");
                this.sale.dueDate = moment(data.dueDate).format("LLL");
                this.sale.registrationNo = data.registrationNo;
                this.sale.mobile = data.mobile;
                this.sale.code = data.code;
                this.sale.customerId = data.customerId;
                this.sale.isCredit = data.isCredit;
                this.sale.saleItems = data.saleItems;


                if (localStorage.getItem('IsMultiUnit') == 'true' && localStorage.getItem('decimalQuantity') == 'true') {
                    this.sale.saleItems.forEach(function (x) {
                        x.highQty = parseInt(parseFloat(x.remainingQuantity) / parseFloat(x.product.unitPerPack));
                        x.quantity = parseFloat(parseFloat(x.remainingQuantity) % parseFloat(x.product.unitPerPack)).toFixed(3).slice(0, -1);
                        x.unitPerPack = x.product.unitPerPack;
                    });
                }
                else if (localStorage.getItem('IsMultiUnit') == 'true' && localStorage.getItem('decimalQuantity') != 'true') {
                    this.sale.saleItems.forEach(function (x) {
                        x.highQty = parseInt(parseInt(x.remainingQuantity) / parseInt(x.product.unitPerPack));
                        x.quantity = parseInt(parseInt(x.remainingQuantity) % parseInt(x.product.unitPerPack));
                        x.unitPerPack = x.product.unitPerPack;
                    });
                }
                else {
                    this.sale.saleItems.forEach(function (x) {
                        x.highQty = 0;
                        x.quantity = parseInt(x.remainingQuantity);
                    });
                }
            }
            else {
                this.sale.wareHouseId = localStorage.getItem('WareHouseId');
            }
        },

        mounted: function () {

            this.language = this.$i18n.locale;
            this.GetHeaderDetail();
            if (this.$route.query.data == undefined) {
                this.AutoIncrementCode();
                this.sale.date = moment().format("LLL");
                this.sale.dueDate = moment().format("LLL");
            }
        },
    };
</script>
