<template>
    <div v-if="isValid('CanPullRecord')">
       
        <div class="row">
            <div class="col-lg-12 col-sm-12 ml-auto mr-auto">
                <div class="row mb-4" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                    <div class="col-sm-6 col-md-6 col-lg-6">
                        <h5 class="page_title">Pull Details</h5>
                        <!--<nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link :to="'/StartScreen'"><a href="javascript:void(0)"> {{ $t('Home') }}</a></router-link></li>

                                <li class="breadcrumb-item active" aria-current="page">Pull Details</li>

                            </ol>
                        </nav>-->
                    </div>
                    <div class=" col-sm-6 col-md-6 col-lg-6">
                        <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                            <button class="btn btn-primary  " @click="PullData" :disabled="loading">Pull Records</button>
                            <router-link :to="'/Synchronization'"><a href="javascript:void(0)" class="btn btn-outline-danger "> {{ $t('PullRecords.Close') }}</a></router-link>
                        </div>

                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <div class="col-lg-12">
                            <div class="progress" v-if="!loading">
                                <div class="progress-bar bg-success" role="progressbar" :style="'width:' + syncedRecords + '%'" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">{{Math.floor(syncedRecords)}}%</div>
                                <div class="progress-bar bg-warning" role="progressbar" :style="'width:' + pendingRecords + '%'" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100">{{Math.ceil(pendingRecords)}}%</div>
                            </div>
                            <div class="">
                                <ul class="nav nav-tabs" data-tabs="tabs">
                                    <li class="nav-item"><a class="nav-link" v-bind:class="{active:status == 'All'}" v-on:click="changeStatus('All')" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">All Records</a></li>
                                    <li class="nav-item"><a class="nav-link" v-bind:class="{active:status == 'Sync'}" v-on:click="changeStatus('Sync')" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">Synced</a></li>
                                    <li class="nav-item"><a class="nav-link" v-bind:class="{active:status == 'NotSync'}" v-on:click="changeStatus('NotSync')" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile1" role="tab" aria-controls="v-pills-profile" aria-selected="false">Pending</a></li>

                                </ul>
                            </div>
                            <div class="card-body" v-if="loading">
                                <div class="text-center" id="preloader">
                                    <div id="loader"></div>
                                </div>
                            </div>
                            <div class="tab-content mt-2" id="nav-tabContent" v-else>
                                <div v-if="status == 'All'">
                                    <div class="mt-2">
                                        <div class=" table-responsive">
                                            <table class="table table_list_bg" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                <thead class="m-0 ">
                                                    <tr>
                                                        <th>
                                                            Table
                                                        </th>
                                                        <th class="text-center">
                                                            Pull
                                                        </th>
                                                        <th class="text-center">
                                                            Pull Date
                                                        </th>
                                                        <th class="text-center">
                                                            Created Date
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="details in syncRecordList" v-bind:key="details.id">
                                                        <td>
                                                            {{details.table}}
                                                        </td>
                                                        <td class="text-center">
                                                            <span :class="details.pull == true ? 'badge badge-success' : 'badge badge-warning'">
                                                                {{details.pull == true ? 'Synced' : 'Pending'}}
                                                            </span>
                                                        </td>
                                                        <td class="text-center">
                                                            {{  details.pullDate | filterDate}}
                                                        </td>
                                                        <td class="text-center"> {{details.changeDate}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="float-left">
                                        <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                                        <span v-else-if="currentPage===1 && rowCount < 20">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage===1 && rowCount >= 21  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*20}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*20}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*20)-19}} {{ $t('Pagination.to') }} {{currentPage*20 >= rowCount ? rowCount : currentPage*20}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*20)-19}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                    </div>
                                    <div class="float-right">
                                        <div class="overflow-auto" v-on:click="getPage()" v-if="($i18n.locale == 'en' ||isLeftToRight())">
                                            <b-pagination pills size="lg" v-model="currentPage"
                                                          :total-rows="rowCount"
                                                          :per-page="20"
                                                          first-text="First"
                                                          prev-text="Previous"
                                                          next-text="Next"
                                                          last-text="Last"></b-pagination>
                                        </div>
                                        <div class="overflow-auto" v-on:click="getPage()" v-else>
                                            <b-pagination pills size="lg" v-model="currentPage"
                                                          :total-rows="rowCount"
                                                          :per-page="20"
                                                          first-text="الأولى"
                                                          prev-text="السابقة"
                                                          next-text="التالية"
                                                          last-text="الأخيرة"></b-pagination>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="status == 'Sync'">
                                    <div class="mt-2">
                                        <div class=" table-responsive">
                                            <table class="table table_list_bg" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                <thead class="m-0 ">
                                                    <tr>
                                                        <th>
                                                            Table
                                                        </th>
                                                        <th class="text-center">
                                                            Pull
                                                        </th>
                                                        <th class="text-center">
                                                            Pull Date
                                                        </th>
                                                        <th class="text-center">
                                                            Created Date
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="details in syncRecordList" v-bind:key="details.id">
                                                        <td>
                                                            {{details.table}}
                                                        </td>
                                                        <td class="text-center">
                                                            <span :class="details.pull == true ? 'badge badge-success' : 'badge badge-warning'">
                                                                {{details.pull == true ? 'Synced' : 'Pending'}}
                                                            </span>
                                                        </td>
                                                        <td class="text-center">
                                                            {{  details.pullDate | filterDate}}
                                                        </td>
                                                        <td class="text-center"> {{details.changeDate}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="float-left">
                                        <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                                        <span v-else-if="currentPage===1 && rowCount < 20">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage===1 && rowCount >= 21  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*20}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*20}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*20)-19}} {{ $t('Pagination.to') }} {{currentPage*20 >= rowCount ? rowCount : currentPage*20}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*20)-19}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                    </div>
                                    <div class="float-right">
                                        <div class="overflow-auto" v-on:click="getPage()" v-if="($i18n.locale == 'en' ||isLeftToRight())">
                                            <b-pagination pills size="lg" v-model="currentPage"
                                                          :total-rows="rowCount"
                                                          :per-page="20"
                                                          first-text="First"
                                                          prev-text="Previous"
                                                          next-text="Next"
                                                          last-text="Last"></b-pagination>
                                        </div>
                                        <div class="overflow-auto" v-on:click="getPage()" v-else>
                                            <b-pagination pills size="lg" v-model="currentPage"
                                                          :total-rows="rowCount"
                                                          :per-page="20"
                                                          first-text="الأولى"
                                                          prev-text="السابقة"
                                                          next-text="التالية"
                                                          last-text="الأخيرة"></b-pagination>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="status == 'NotSync'">
                                    <div class="mt-2">
                                        <div class=" table-responsive">
                                            <table class="table table_list_bg" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                <thead class="m-0 ">
                                                    <tr>
                                                        <th>
                                                            Table
                                                        </th>
                                                        <th class="text-center">
                                                            Pull
                                                        </th>
                                                        <th class="text-center">
                                                            Pull Date
                                                        </th>
                                                        <th class="text-center">
                                                            Created Date
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="details in syncRecordList" v-bind:key="details.id">
                                                        <td>
                                                            {{details.table}}
                                                        </td>
                                                        <td class="text-center">
                                                            <span :class="details.pull == true ? 'badge badge-success' : 'badge badge-warning'">
                                                                {{details.pull == true ? 'Synced' : 'Pending'}}
                                                            </span>
                                                        </td>
                                                        <td class="text-center">
                                                            {{  details.pullDate | filterDate}}
                                                        </td>
                                                        <td class="text-center"> {{details.changeDate}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="float-left">
                                        <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                                        <span v-else-if="currentPage===1 && rowCount < 20">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage===1 && rowCount >= 21  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*20}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*20}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*20)-19}} {{ $t('Pagination.to') }} {{currentPage*20 >= rowCount ? rowCount : currentPage*20}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*20)-19}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                    </div>
                                    <div class="float-right">
                                        <div class="overflow-auto" v-on:click="getPage()" v-if="($i18n.locale == 'en' ||isLeftToRight())">
                                            <b-pagination pills size="lg" v-model="currentPage"
                                                          :total-rows="rowCount"
                                                          :per-page="20"
                                                          first-text="First"
                                                          prev-text="Previous"
                                                          next-text="Next"
                                                          last-text="Last"></b-pagination>
                                        </div>
                                        <div class="overflow-auto" v-on:click="getPage()" v-else>
                                            <b-pagination pills size="lg" v-model="currentPage"
                                                          :total-rows="rowCount"
                                                          :per-page="20"
                                                          first-text="الأولى"
                                                          prev-text="السابقة"
                                                          next-text="التالية"
                                                          last-text="الأخيرة"></b-pagination>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else>  <acessdenied></acessdenied></div>
</template>
<style scoped>
    .higlight {
        background-color: red;
    }

    .progress {
        border-radius: 25px !important;
        height: 20px;
    }
</style>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import moment from "moment";
    export default {
        mixins: [clickMixin],
        name: 'product',
        data: function () {
            return {
                arabic: '',
                english: '',
                show: false,
                type: '',
                syncRecordList: [
                ],
                status: 'All',
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                searchQuery: '',
                loading: true,
                isSync: false,
                syncedRecords: 0,
                pendingRecords: 0,
            }
        },
        filters: {
            filterDate: function (val) {
                if (val == null || val == '')
                    return "--";

                return moment(val.toString()).format('DD/MM/YYYY HH:mm');
            }
        },
        methods: {
            changeStatus: function (status) {
                this.status = status;
                this.currentPage = 1;
                this.GetSyncRecords();
            },
            ImportDataFromCsv: function () {
                var root = this;
                root.$router.push({
                    path: '/ImportProduct'
                })
            },

            getPage: function () {

                this.GetSyncRecords(this.search, this.currentPage);
            },
            GetSyncRecords: function () {
                var root = this;

                var url = '/System/GetPullRecordsInformation?status=' + this.status + '&pageNumber=' + this.currentPage;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.loading = true;

                root.$https.get(url, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.syncRecordList = response.data.results.syncRecords;
                        if (root.syncRecordList.length > 0) {
                            var total = root.syncRecordList[0].synced + root.syncRecordList[0].pending;

                            if (total > 0) {
                                root.syncedRecords = (root.syncRecordList[0].synced / total) * 100;
                                root.pendingRecords = (root.syncRecordList[0].pending / total) * 100;
                            }
                        }
                        root.pageCount = response.data.pageCount;
                        root.rowCount = response.data.rowCount;
                        root.loading = false;

                    }
                    root.loading = false;
                });
            },

            PullData: function () {
                var root = this;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.isSync = true;

                this.$https
                    .get('/System/PullData', { headers: { "Authorization": `Bearer ${token}` } })
                    .then(() => { root.isSync = false; },
                        () => { root.isSync = false; });
            },
            recordUpdated() {
                var root = this;
                root.GetSyncRecords();
                setTimeout(() => {
                    if (!root.isSync)
                        return;
                    root.recordUpdated();
                }, 5000);
            }
        },
        created: function () {
            this.$emit('input', this.$route.name);
        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            if (this.$route.query.data != undefined) {
                this.currentPage = this.$route.query.data;
                this.GetSyncRecords();
            }
            else {
                this.GetSyncRecords(this.search, 1);
            }
        },
        watch: {
            isSync: {
                handler: function (val) {
                    if (val) {
                        this.recordUpdated();
                    }
                },
                deep: true
            }
        },
    }
</script>