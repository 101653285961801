<template>
    <div class="row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-bind:style="$i18n.locale == 'ar' ? languageChange('en') : languageChange('ar')" v-if="isValid('CanFlushDatabase')">
        <div class="col-lg-12 col-sm-12">
            <div class="card " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                <div class="card-header">
                    <h4 class="card-title DayHeading"> {{$t('FlushDatabase.FlushDatabase')}}</h4>
                    <hr />

                    <div class="row" v-if="lang == 'en'">
                        <div class="col-md-6 pb-3" v-for="list in flushList" v-bind:key="list.id">
                            <h5>
                                <input type="checkbox"
                                       v-model="list.checked"
                                       v-on:change="onSelectTable($event.target.checked, list.id)">

                                {{list.name}}
                            </h5>

                            <!--<div  class="row ml-3" v-for="child in childTable.filter(x => x.parentId == list.id)" v-bind:key="child.id + list.id">
                                <div class="col-md-12" v-if="list.checked">
                                     <h6>
                                         <input type="checkbox"
                                        checked disabled

                                     >
                                          {{child.name}}
                                     </h6>
                                </div>
                            </div>-->
                        </div>
                    </div>
                    <div class="row" v-if="lang == 'ar'">
                        <div class="col-md-6 pb-3" v-for="list in flushListArabic" v-bind:key="list.id">
                            <h5>
                                <input type="checkbox"
                                       v-model="list.checked"
                                       v-on:change="onSelectTable($event.target.checked, list.id)">

                                {{list.name}}
                            </h5>

                            <!--<div  class="row ml-3" v-for="child in childTable.filter(x => x.parentId == list.id)" v-bind:key="child.id + list.id">
                                <div class="col-md-12" v-if="list.checked">
                                     <h6>
                                         <input type="checkbox"
                                        checked disabled

                                     >
                                          {{child.name}}
                                     </h6>
                                </div>
                            </div>-->
                        </div>
                    </div>
                    <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                        <a href="javascript:void(0)" class="btn btn-outline-primary  " :disabled="loading1"
                           v-on:click="SupervisorLogin">
                            {{$t('FlushDatabase.Flush')}}
                        </a>
                    </div>

                </div>
            </div>


            <loading :name="loading1" :active.sync="loading1"
                     :can-cancel="true"
                     :is-full-page="true"></loading>

            <supervisor-login-model @close="onCloseEvent"
                                    :show="show"
                                    :isFlushData="true"
                                    :isReset="false"
                                    v-if="show" />
        </div>

    </div>
    <div v-else> <acessdenied></acessdenied></div>

</template>

<script>
    import 'vue-loading-overlay/dist/vue-loading.css';
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        data: function () {
            return {
                loading1: false,
                flushList: [
                    { id: 1, name: "All Data", table: "AllData", checked: false },
                    { id: 2, name: "Except Product info", table: "ProductInfo", checked: false },
                    { id: 3, name: "Except Product info, customer and supplier", table: "Contact", checked: false },
                ],
                flushListArabic: [
                    { id: 1, name: "كل المعلومات", table: "AllData", checked: false },
                    { id: 2, name: "باستثناء معلومات المنتج", table: "ProductInfo", checked: false },
                    { id: 3, name: "باستثناء معلومات المنتج والعميل والمورد", table: "Contact", checked: false },
                ],
                show: false,
                records: '',

                loginHistory: {
                    userId: '',
                    isLogin: false,
                    companyId: ''
                },
                lang: ''
            }
        },
        methods: {
            languageChange: function (lan) {
                if (this.lang == lan) {
                    var getLocale = this.$i18n.locale;
                    this.lang = getLocale;

                    this.$router.go('/FlushDatabase');
                }


            },
            onSelectTable: function (isChecked, id) {
                var root = this;
                this.flushList.forEach(x => {
                    if (x.id != id) {
                        x.checked = false;
                        // root.childTable.filter(c=>  c.parentId == x.id)
                        // .forEach(c => {
                        // c.checked = false;

                        // })

                    } else {
                        x.checked = true;
                        root.records = x.table;
                    }
                })
            },
            onCloseEvent: function (flag) {
                if (flag) {
                    this.flush()
                }
                this.show = false
            },
            SupervisorLogin: function () {
                this.show = true;
            },
            logoutHistorySave: function () {

                this.loginHistory.userId = localStorage.getItem('UserID')
                this.loginHistory.companyId = localStorage.getItem('CompanyID')
                this.$https.post('/account/LoginHistory', this.loginHistory).then(function (response) {
                    if (response.data == 1)
                        console.log('Logout History save done');
                    else
                        console.log('Logout History not save due to some error ' + response.data);
                });
            },
            logout: function () {
                var root = this;
                var url = '/account/logout';
                this.$https.post(url, this.login).then(function (response) {

                    if (response.data == "Success") {

                        root.logoutHistorySave();
                        root.$session.destroy();
                        localStorage.clear();
                        //document.cookie.split(';').forEach(cookie => document.cookie = cookie.replace(/^ +/, '').replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`));

                        root.$router.push('/')
                    }
                    else {
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Error Logging Out'
                            });
                    }

                });
            },

            flush: function () {
                var root = this;
                this.loading1 = true;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.loading1 = true;

                root.$https
                    .get('/System/FlushRecords?records=' + this.records, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                        if (response.status == 200) {
                            root.logout();
                            root.$swal({
                                title: "Success!",
                                text: "Flush data successfully",
                                type: 'error',
                                confirmButtonClass: "btn btn-Success",
                                buttonStyling: false,

                            });

                        }
                        root.loading1 = false;

                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Something Went Wrong!',
                                text: error.response.data,
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });
                        root.loading1 = false;
                    });
            }
        },
        created: function () {
            this.$emit('input', this.$route.name);
        },
        mounted: function () {
            this.lang = localStorage.getItem('locales')
        }

    }
</script>