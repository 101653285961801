<template>
    <div class="col-md-12 ml-auto mr-auto" v-if="isValid('CanAddProductionRecipe')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
        <div class="card" v-bind:style="$i18n.locale == 'ar' ? languageChange('en') : languageChange('ar')" >
            <div class="card-body">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="card-header p-0 row">
                            <div class="col-lg-9 ">
                                <span v-if="purchase.id === '00000000-0000-0000-0000-000000000000'"> <span class="MainLightHeading">{{ $t('AddRecipeNo.AddRecipe') }} - </span><span class="DayHeading">{{purchase.registrationNo}}</span></span>
                                <span v-else><span class="MainLightHeading">{{ $t('AddRecipeNo.UpdateRecipe') }} - </span><span class="DayHeading">{{purchase.registrationNo}}</span></span>
                                
                            </div>
                            <div class="col-lg-3 text-right">
                                <span>
                                    {{purchase.date}}
                                </span>
                            </div>
                        </div>
                        <div class="mt-3">
                            <div class="row">

                                <!--<div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                    <label>Sample Request:<span class="LabelColour"> *</span></label>
                                    <sample-request-dropdown v-on:input="GetSampleRecord(purchase.sampleRequestId)" v-model="purchase.sampleRequestId" v-bind:values="purchase.sampleRequestId"></sample-request-dropdown>
                                </div>-->

                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 form-group">
                                    <label>{{ $t('AddRecipeNo.RecipeName') }} :<span class="LabelColour"> *</span></label>
                                    <input type="text" style="height:43px;" v-model="purchase.recipeName" class="form-control" />
                                </div>
                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 form-group">
                                    <label>{{ $t('AddRecipeNo.FinishingProduct') }} :<span class="LabelColour"> *</span></label>
                                    <product-dropdown :raw="false" v-model="purchase.productId" v-bind:values="purchase.productId" :emptyselect="true" />
                                </div>
                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 form-group">
                                    <label>{{ $t('AddRecipeNo.Quantity') }}  :<span class="LabelColour"> *</span></label>
                                    <div v-bind:class="{'has-danger': $v.purchase.quantity.$error}">
                                        <input class="form-control " style="height:43px;" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'"
                                               v-model="$v.purchase.quantity.$model" />
                                        <span v-if="$v.purchase.quantity.$error"
                                              class="error text-danger">
                                        </span>
                                    </div>
                                </div>

                                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 form-group" hidden>
                                    <label>{{ $t('AddRecipeNo.ExpireOn') }} :</label>
                                    <div v-bind:class="{ 'has-danger': $v.purchase.expireDate.$error }">
                                        <datepicker v-model="$v.purchase.expireDate.$model" :key="daterander"></datepicker>
                                        <span v-if="$v.purchase.expireDate.$error"
                                              class="error text-danger">
                                        </span>
                                    </div>
                                </div>
                                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 form-group">
                                    <label>IsActive :</label>
                                    <toggle-button v-model="purchase.isActive" class="ml-2" color="#3178F6" />
                                </div>
                            </div>
                        </div>
                        <br />
                        <recipe-item @input="SavePurchaseItems" v-bind:purchase="purchase" :key="purchaseItemRander"  />

                    </div>
                    <div  class="col-md-12 text-right">
                        <div v-if="purchase.id === '00000000-0000-0000-0000-000000000000'">
                            <button class="btn btn-primary  mr-2"
                                       v-if="isValid('CanAddProductionRecipe')"
                                    v-on:click="savePurchase('Draft')"
                                    :disabled="$v.$invalid || purchase.recipeNoItems.filter(x => x.quantity=='').length > 0">
                                <i class="far fa-save"></i>  {{ $t('AddRecipeNo.SaveAsDraft') }}
                            </button>
                            <button class="btn btn-primary  mr-2"
                                       v-if="isValid('CanAddProductionRecipe')"
                                    v-on:click="savePurchase('Approved')"
                                    :disabled="$v.$invalid || purchase.recipeNoItems.filter(x => x.quantity=='').length > 0">
                                <i class="far fa-save"></i>  {{ $t('AddRecipeNo.SaveAsPost') }}
                            </button>
                            <button class="btn btn-danger  mr-2"
                                    v-on:click="goToPurchase">
                                {{ $t('AddRecipeNo.Cancel') }}
                            </button>
                        </div>
                        <div v-else>
                            <button class="btn btn-primary  mr-2"
                                    v-on:click="savePurchase('Draft')"
                                      v-if="isValid('CanAddProductionRecipe')"
                                    :disabled="$v.$invalid || purchase.recipeNoItems.filter(x => x.quantity=='').length > 0">
                                <i class="far fa-save"></i>  {{ $t('AddRecipeNo.UpdateAsDraft') }}
                            </button>

                            <button class="btn btn-primary  mr-2"
                                    v-on:click="savePurchase('Approved')"
                                    v-if="isValid('CanAddProductionRecipe')"
                                    :disabled="$v.$invalid || purchase.recipeNoItems.filter(x => x.quantity=='').length > 0">
                                <i class="far fa-save"></i> {{ $t('AddRecipeNo.UpdateAsPost') }}
                            </button>
                            <button class="btn btn-danger  mr-2"
                                    v-on:click="goToPurchase">
                                {{ $t('AddRecipeNo.Cancel') }}
                            </button>
                        </div>




                    </div>
                    <!--<div class="card-footer col-md-3" v-else>
                        <loading :active.sync="loading"
                                 :can-cancel="true"
                                 :on-cancel="onCancel"
                                 :is-full-page="true"></loading>
                    </div>-->
                </div>
            </div>
        </div>
    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'    
    import moment from "moment";
    import "vue-loading-overlay/dist/vue-loading.css";
    import { required } from "vuelidate/lib/validators";
    
    export default {
        mixins: [clickMixin],
        data: function () {
            return {
                language: 'Nothing',
                daterander: 0,
                purchaseItemRander: 0,
                rander: 0,
                purchase: {
                    id: "00000000-0000-0000-0000-000000000000",
                    recipeName: "",
                    date: "",
                    registrationNo: "",
                    expireDate: "",
                    productId: "",
                    quantity: "",
                    recipeNoItems: [],
                    isActive: true,
                },
                loading: false,
            };
        },
        validations: {
            purchase: {
                recipeName: { required },
                date: { required },
                expireDate: {  },
                registrationNo: { required },
                quantity: { required },
                productId: { required },
                recipeNoItems: { required },
            },
        },
        methods: {
            GetSampleRecord: function (Id) {

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Batch/SampleRequestDetail?Id=' + Id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {

                        if (response.data != null) {
                            root.purchase.recipeNoItems = response.data.sampleRequestItems;
                            root.purchaseItemRander++;
                        }
                        else {
                            console.log("error: something wrong from db.");
                        }
                    },
                        function (error) {
                            this.loading = false;
                            console.log(error);
                        });

            },

            languageChange: function (lan) {
                if (this.language == lan) {
                    if (this.purchase.id == '00000000-0000-0000-0000-000000000000') {

                        var getLocale = this.$i18n.locale;
                        this.language = getLocale;

                        this.$router.go('/AddRecipeNo');
                    }
                    else {
                        this.$swal({
                            title: 'Error!',
                            text: 'You cannot Change language during update, otherwise your current page data will be lose',
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            icon: 'error',
                            timer: 4000,
                            timerProgressBar: true,
                        });
                    }
                }


            },
            AutoIncrementCode: function () {
                var root = this;
                var token = "";
                if (root.$session.exists()) {
                    token = localStorage.getItem("token");
                }
                root.$https
                    .get("/Batch/RecipeNoAutoGenerateNo", {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then(function (response) {
                        if (response.data != null) {
                            root.purchase.registrationNo = response.data;
                        }
                    });
            },
            SavePurchaseItems: function (recipeNoItems) {

                this.purchase.recipeNoItems = recipeNoItems;
            },
            savePurchase: function (status) {
                this.purchase.approvalStatus = status
                this.loading = true;
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                this.$https
                    .post('/Batch/SaveRecipeNoInformation', root.purchase, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {
                        root.loading = false
                        root.info = response.data

                        root.$swal({
                            title: "Saved!",
                            text: "Data Saved Successfully!",
                            type: 'success',
                            icon: 'success',
                            timer: 1500,
                            timerProgressBar: true,
                        }).then(function (response) {
                            if (response != undefined) {
                                if (root.purchase.id == "00000000-0000-0000-0000-000000000000") {
                                    root.$router.push({
                                        path: '/RecipeNo',
                                        query: { data: status }
                                    });

                                } else {
                                    root.$router.push({
                                        path: '/RecipeNo',
                                        query: { data: status }
                                    });
                                }
                            }
                        });
                        
                    })
                    .catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Something Went Wrong!',
                                text: error,
                            });

                        root.loading = false
                    })
                    .finally(() => root.loading = false)

            },

            goToPurchase: function () {
                this.$router.push('/RecipeNo');
            },
        },
        created: function () {
            this.$emit('input', this.$route.name);
            if (this.$route.query.data != undefined) {
                this.purchase = this.$route.query.data;
                this.attachment = true;
                this.rander++;
            }
        },
        mounted: function () {
            var getLocale = this.$i18n.locale;
            this.language = getLocale;
            if (this.$route.query.data == undefined) {
                this.AutoIncrementCode();

                this.purchase.date = moment().format('llll');
                this.daterander++;
            }
        },
    };
</script>
