<template>
   <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">


        <multiselect :disabled="disableValue" v-if="isMultiple"
                     v-model="DisplayValue"
                     :options="options"
                     :multiple="true"
                     track-by="name"
                     :clear-on-select="false"
                     :show-labels="false"
                     label="name"
                     :preselect-first="true"
                     v-bind:placeholder="$t('CustomerDropdown.SelectOption')"
                     v-bind:class="$i18n.locale == 'en' ? 'text-left ' : 'arabicLanguage '">
            <span slot="noResult" class="btn btn-primary " v-on:click="AddCustomer()" v-if="isValid('CanAddCustomer')">{{ $t('CustomerDropdown.CreateQuickCustomer') }}</span><br />
        </multiselect>
        <multiselect :disabled="disableValue" v-else
                     v-model="DisplayValue"
                     :options="options"
                     :multiple="false"
                     track-by="name"
                     :clear-on-select="false"
                     :show-labels="false"
                     label="name"
                     :preselect-first="true"
                     v-bind:placeholder="$t('CustomerDropdown.SelectOption')"
                     v-bind:class="$i18n.locale == 'en' ? 'text-left ' : 'arabicLanguage '">
            <span slot="noResult" class="btn btn-primary " v-on:click="AddCustomer()" v-if="isValid('CanAddCustomer')">{{ $t('CustomerDropdown.CreateQuickCustomer') }}</span><br />
        </multiselect>


        <modal :show="show" v-if="show">
            <div style="margin-bottom:0px" class="card" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                <div class="card-body">
                    <div class="col-lg-12">
                        <div class="tab-content" id="nav-tabContent">
                            <div class="modal-header">
                                <h5 class="modal-title" id="myModalLabel"> {{ $t('CustomerDropdown.AddCustomer') }}</h5>
                            </div>
                            <div class="" v-if="reparingOrder==true">
                                <div class="card-body">
                                    <div class="row ">
                                        <div class="col-sm-12">
                                            <label>{{ $t('CustomerDropdown.CustomerCode') }} :<span class="LabelColour"> *</span></label>
                                            <div v-bind:class="{'has-danger' : $v.customer.code.$error}">
                                                <input readonly class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.customer.code.$model" />
                                                <span v-if="$v.customer.code.$error" class="error text-danger">
                                                </span>
                                            </div>
                                        </div>


                                        <div v-if="english=='true'" class="col-sm-12">
                                            <label>{{ $t('CustomerDropdown.CustomerName(English)') |englishLanguage}} :<span class="LabelColour"> *</span></label>
                                            <div v-bind:class="{'has-danger' : $v.customer.englishName.$error}">
                                                <input class="form-control " v-bind:class="$i18n.locale == 'en' ? 'text-left' : 'arabicLanguage'" v-model="$v.customer.englishName.$model" />
                                                <span v-if="$v.customer.englishName.$error" class="error text-danger">
                                                    <span v-if="!$v.customer.englishName.required">{{ $t('CustomerDropdown.EngValidation') }} </span>
                                                    <span v-if="!$v.customer.englishName.maxLength">{{ $t('CustomerDropdown.EngMax') }} </span>
                                                </span>
                                            </div>
                                        </div>
                                        <div v-if="arabic=='true'" class="col-sm-12">
                                            <label>{{ $t('CustomerDropdown.CustomerName(Arabic)') |arabicLanguage}} :<span class="LabelColour"> *</span></label>
                                            <div v-bind:class="{'has-danger' : $v.customer.arabicName.$error}">
                                                <input class="form-control arabicLanguage" v-model="$v.customer.arabicName.$model" />
                                                <span v-if="$v.customer.arabicName.$error" class="error text-danger">

                                                    <span v-if="!$v.customer.arabicName.maxLength">{{ $t('CustomerDropdown.ArMax') }} </span>
                                                </span>
                                            </div>
                                        </div>



                                        <div class="col-sm-12">
                                            <label>Mobile :</label>
                                            <input class="form-control text-left" v-model="customer.contactNo1" />
                                        </div>



                                    </div>
                                </div>
                            </div>
                            <div class="" v-else>
                                <div class="card-body">
                                    <div class="row ">
                                        <div class="col-sm-12">
                                            <label>{{ $t('CustomerDropdown.CustomerCode') }} :<span class="LabelColour"> *</span></label>
                                            <div v-bind:class="{'has-danger' : $v.customer.code.$error}">
                                                <input readonly class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.customer.code.$model" />
                                                <span v-if="$v.customer.code.$error" class="error text-danger">
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <label> {{ $t('AddCustomer.CustomerType') }}    :</label>
                                            <div>
                                                <multiselect v-model="customer.category" :options="optionsb2b" :show-labels="false" placeholder="Select Type">
                                                </multiselect>

                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <label> {{ $t('AddCustomer.CustomerCategory') }}:<span class="LabelColour"> *</span></label>
                                            <div v-bind:class="{'has-danger' : $v.customer.customerType.$error}">

                                                <multiselect v-if="($i18n.locale == 'en' ||isLeftToRight()) " v-model="$v.customer.customerType.$model" :options="['Individual', 'Establishment', 'Company','Factory','Manufacturer']" :show-labels="false" placeholder="Select Type">
                                                </multiselect>
                                                <multiselect v-else v-model="$v.customer.customerType.$model" :options="['فرد', 'مؤسسة', 'شركة']" :show-labels="false" v-bind:placeholder="$t('SelectOption')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                </multiselect>
                                                <span v-if="$v.customer.customerType.$error" class="error text-danger">

                                                </span>
                                            </div>
                                        </div>
                                        <div v-if="english=='true'" class="col-sm-12">
                                            <label>{{ $t('CustomerDropdown.CustomerName(English)') |englishLanguage}} :<span class="LabelColour"> *</span></label>
                                            <div v-bind:class="{'has-danger' : $v.customer.englishName.$error}">
                                                <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.customer.englishName.$model" />
                                                <span v-if="$v.customer.englishName.$error" class="error text-danger">
                                                    <span v-if="!$v.customer.englishName.required">{{ $t('CustomerDropdown.EngValidation') }} </span>
                                                    <span v-if="!$v.customer.englishName.maxLength">{{ $t('CustomerDropdown.EngMax') }} </span>
                                                </span>
                                            </div>
                                        </div>
                                        <div v-if="isOtherLang()" class="col-sm-12">
                                            <label>{{ $t('CustomerDropdown.CustomerName(Arabic)') |arabicLanguage}} :<span class="LabelColour"> *</span></label>
                                            <div v-bind:class="{'has-danger' : $v.customer.arabicName.$error}">
                                                <input class="form-control " v-bind:class="isLeftToRight() ? 'text-left' : 'arabicLanguage'" v-model="$v.customer.arabicName.$model" />
                                                <span v-if="$v.customer.arabicName.$error" class="error text-danger">

                                                    <span v-if="!$v.customer.arabicName.maxLength">{{ $t('CustomerDropdown.ArMax') }} </span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <label>{{ $t('CustomerDropdown.RegistrationDate') }}  :</label>
                                            <div v-bind:class="{'has-danger' : $v.customer.registrationDate.$error}">
                                                <datepicker v-model="$v.customer.registrationDate.$model"></datepicker>
                                                <span v-if="$v.customer.registrationDate.$error" class="error text-danger">
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <label>{{ $t('CustomerDropdown.VAT/NTN/Tax No') }} :<span class="LabelColour"> *</span></label>
                                            <div v-bind:class="{'has-danger' : $v.customer.vatNo.$error}">
                                                <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.customer.vatNo.$model" />
                                                <span v-if="$v.customer.vatNo.$error" class="error text-danger">
                                                    <span v-if="!$v.customer.vatNo.required">{{ $t('CustomerDropdown.VatNoRequired') }}</span>
                                                </span>
                                            </div>
                                        </div>

                                        <div class="col-sm-12">
                                            <label>Mobile :</label>
                                            <input class="form-control text-left" v-model="customer.contactNo1" />
                                        </div>
                                        <div class="col-sm-12">
                                            <label>Address :</label>
                                            <div>
                                                <textarea class="form-control text-left" v-model="customer.address" />

                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <label>{{ $t('CustomerDropdown.PaymentTerms') }} :<span class="LabelColour"> *</span></label>


                                            <multiselect v-model="customer.paymentTerms" v-if="($i18n.locale == 'en' ||isLeftToRight()) " :options="[ 'Cash', 'Credit']" :show-labels="false" placeholder="Select Type">
                                            </multiselect>
                                            <multiselect v-else v-model="customer.paymentTerms" :options="[ 'نقد', 'آجل']" :show-labels="false" v-bind:placeholder="$t('CustomerDropdown.SelectOption')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                            </multiselect>
                                            <span v-if="$v.customer.paymentTerms.$error" class="error text-danger">
                                                <span v-if="!$v.customer.paymentTerms.required">{{ $t('CustomerDropdown.PaymentValidation') }} </span>
                                            </span>

                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer justify-content-right">
                                <button type="button" class="btn btn-primary  " v-on:click="SaveCustomer" v-bind:disabled="$v.customer.$invalid"> {{ $t('CustomerDropdown.btnSave') }}</button>
                                <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{ $t('CustomerDropdown.btnClear') }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </modal>

    </div>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import moment from 'moment';
    import Multiselect from 'vue-multiselect'
    import { required, maxLength, requiredIf } from "vuelidate/lib/validators"
    export default {
        name: 'CustomerDropdown',
        props: ["values", "disable", "paymentTerm", "isMultiple"],
        mixins: [clickMixin],
        components: {
            Multiselect,

        },
        data: function () {
            return {
                b2b: false,
                b2c: false,
                customerList: [],
                arabic: '',
                english: '',
                options: [],
                optionsb2b: [],
                value: '',
                disableValue: false,
                show: false,
                customer: {
                    id: '00000000-0000-0000-0000-000000000000',
                    customerType: '',
                    code: '',
                    registrationDate: '',
                    englishName: '',
                    arabicName: '',
                    vatNo: '',
                    contactNo1: '',
                    address: '',
                    paymentTerms: '',
                    isCustomer: true,
                    isActive: true
                },
            }
        },
        validations: {
            customer: {
                code: { required },
                customerType: { required },
                registrationDate: { required },
                englishName: {
                    maxLength: maxLength(30)
                },
                arabicName: {
                    required: requiredIf((x) => {
                        if (x.englishName == '' || x.englishName == null)
                            return true;
                        return false;
                    }),
                    maxLength: maxLength(40)
                },
                vatNo: {
                    required
                },
                paymentTerms: { required },
            }
        },
        methods: {
            asyncFind: function (search) {

                this.getData(true, search);
            },
            GetAutoCodeGenerator: function () {
                var root = this;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https
                    .get('/Contact/AutoGenerateCode?isCustomer=true', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                        if (response.data != null) {
                            root.customer.code = response.data;

                        }
                    });
            },
            AddCustomer: function () {
                this.$v.$reset();
                if (this.reparingOrder == true) {
                    this.customer = {
                        id: '00000000-0000-0000-0000-000000000000',
                        code: '',
                        customerType: 'Factory',
                        category: 'B2C – Business to Client',
                        registrationDate: '',
                        englishName: '',
                        arabicName: '',
                        vatNo: '0000000',
                        paymentTerms: 'Credit',
                        contactNo1: '',
                        address: '',
                        isCustomer: true,
                        isActive: true
                    }
                }
                else {
                    this.customer = {
                        id: '00000000-0000-0000-0000-000000000000',
                        code: '',
                        customerType: '',
                        registrationDate: '',
                        englishName: '',
                        arabicName: '',
                        vatNo: '',
                        contactNo1: '',
                        address: '',
                        isCustomer: true,
                        isActive: true
                    }
                }

                this.GetAutoCodeGenerator();
                this.customer.registrationDate = moment().format('llll');
                this.show = !this.show;
            },
            SaveCustomer: function () {
                this.loading = true;
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https
                    .post('/Contact/SaveContact', this.customer, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {
                        if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Add") {
                            root.loading = false
                            root.info = response.data.bpi
                            root.$swal({
                                title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved Successfully' : 'حفظ بنجاح',
                                text: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved' : 'تم الحفظ',
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonStyling: false,
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true
                            });
                            root.show = !root.show;
                            this.getData(true);
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                                text: error.response.data,
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });
                        root.show = !root.show;
                        root.loading = false
                    })
                    .finally(() => root.loading = false)
            },
            close: function () {
                this.show = false;
            },
            GetCustomerAddress: function () {

                if (this.value != null) {
                    if (this.value.length > 0)
                        return this.value[0];
                    else
                        return this.value;
                }

            },
            getData: function (quick, search) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                if (quick) {
                    root.options = [];
                }
                if (search == undefined || search == null) {
                    search = '';
                }
                var paymentTerms = '';


                paymentTerms = this.paymentTerm == 'Credit' || this.paymentTerm == 'آجـل' ? 'Credit' : '';


                this.$https.get('/Contact/ContactList?IsDropDown=' + true + '&isCustomer=' + true + '&isActive=' + true + '&paymentTerms=' + paymentTerms + '&searchTerm=' + search, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {

                        response.data.results.forEach(function (cust) {
                            root.customerList = response.data.results;
                            root.options.push({
                                id: cust.id,
                                address: cust.address,
                                mobile: cust.contactNo1,
                                advanceAccountId: cust.advanceAccountId,
                                email: cust.email,
                                vatNo: (cust.vatNo == null || cust.vatNo == '') ? cust.commercialRegistrationNo : cust.vatNo,
                                name: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? (cust.englishName != '' && cust.englishName != null) ? cust.code + ' ' + cust.englishName : cust.code + ' ' + cust.arabicName : (cust.arabicName != '' && cust.arabicName != null) ? cust.code + ' ' + cust.arabicName : cust.code + ' ' + cust.englishName,
                            })
                        })

                        
                    }
                }).then(function () {
                    root.value = root.options.find(function (x) {
                        return x.id == root.values;
                    })
                    root.$emit('input', root.value.id, root.value.advanceAccountId);
                });
            },
        },
        computed: {
            DisplayValue: {

                get: function () {

                    if (this.value != "" || this.value != undefined) {
                        return this.value;
                    }
                    return this.values;
                },
                set: function (value) {

                    debugger;

                    if (value == null || value == undefined) {
                        this.value = value;
                        this.$emit('input', value, value);
                    }
                    else if (this.isMultiple == true) {
                        this.$emit('input', value, value);

                    }
                    else {

                        this.value = value;
                        this.$emit('input', value.id, value.advanceAccountId);
                    }
                }
            }
        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.b2b = localStorage.getItem('b2b') == 'true' ? true : false;
            this.b2c = localStorage.getItem('b2c') == 'true' ? true : false;
            if (this.b2b && this.b2c) {
                this.optionsb2b = ['B2B – Business to Business', 'B2C – Business to Client']
            }
            else if (this.b2b) {
                this.optionsb2b = ['B2B – Business to Business']
            }
            else if (this.b2c) {
                this.optionsb2b = ['B2C – Business to Client']
            }
            this.getData();
            this.disableValue = this.disable;
        },
    }
</script>