<template>
    <div class="row" v-if="isValid('CanViewDraftOrder') || isValid('CanViewInProcessOrder') || isValid('CanAddPurchaseOrder')|| isValid('CanEditPurchaseOrder')" v-bind:style="$i18n.locale == 'ar' ? languageChange('en') : languageChange('ar')">
        <div class="col-md-12 ml-auto mr-auto">
            <div class="card" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">


                            <div class="row " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <div class="col-lg-8 poHeading" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <span v-if="purchase.id === '00000000-0000-0000-0000-000000000000'">{{ $t('AddPurchaseOrder.AddPurchaseOrder') }} <span style="font-weight:bold"> - {{purchase.registrationNo}}</span></span>
                                    <span v-else>{{ $t('AddPurchaseOrder.UpdatePurchaseOrder') }}  <span style="font-weight:bold"> - {{purchase.registrationNo}}</span></span>
                                </div>
                                <div class="col-lg-4 dateHeading" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                                    <span>
                                        {{purchase.date}}
                                    </span>
                                </div>
                            </div>

                            <div class="row bottomBorder">

                            </div>



                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div v-if="raw=='true'" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                        <label>{{ $t('AddPurchaseOrder.RawProduct') }}  : </label>
                                        <toggle-button v-model="purchase.isRaw" @change="ChangeSupplier" class="ml-2 mt-2" color="#3178F6" />
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <label>{{ $t('AddPurchaseOrder.Supplier') }} :<span class="LabelColour"> *</span></label>
                                    <div v-bind:class="{'has-danger': $v.purchase.supplierId.$error,}" :key="supplierRender">
                                        <supplierdropdown v-model="$v.purchase.supplierId.$model" :disable="purchase.approvalStatus === 5 && purchase.id != '00000000-0000-0000-0000-000000000000'" v-bind:values="purchase.supplierId" :status="purchase.isRaw" />
                                        <span v-if="$v.purchase.supplierId.$error"
                                              class="error text-danger">
                                        </span>
                                    </div>
                                </div>

                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 form-group" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <label v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'pt-2'">{{ $t('AddPurchaseOrder.SupplierQuotationNumber') }} :</label>
                                    <div v-bind:class="{'has-danger': $v.purchase.invoiceNo.$error,}">
                                        <input class="form-control" v-bind:disabled="purchase.approvalStatus === 5 && purchase.id != '00000000-0000-0000-0000-000000000000'" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'"
                                               v-model="$v.purchase.invoiceNo.$model" />
                                        <span v-if="$v.purchase.invoiceNo.$error"
                                              class="error text-danger">
                                        </span>
                                    </div>
                                </div>

                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <label>{{ $t('AddPurchaseOrder.QuotationDate') }}:</label>
                                    <div v-bind:class="{'has-danger': $v.purchase.invoiceDate.$error,}">
                                        <datepicker v-model="$v.purchase.invoiceDate.$model" />
                                        <span v-if="$v.purchase.invoiceDate.$error"
                                              class="error text-danger">
                                        </span>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-bind:class=" ($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <label>{{ $t('AddPurchaseOrder.TaxMethod') }} :<span class="LabelColour"> *</span></label>
                                    <multiselect :options="options" v-bind:disabled="purchase.purchaseOrderItems.length>0" v-model="purchase.taxMethod" :show-labels="false" v-bind:placeholder="$t('SelectMethod')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    </multiselect>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <label>{{ $t('AddPurchaseOrder.VAT%') }} :<span class="LabelColour"> *</span></label>
                                    <taxratedropdown v-model="purchase.taxRateId" :isDisable="purchase.purchaseOrderItems.length>0? true :false" :key="vatRander" />
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <label>{{ $t('AddPurchaseOrder.PurchaseTemplate') }} :<span class="LabelColour"> *</span></label>
                                    <auto-purchase-template-dropdown v-model="purchase.purchaseTemplateId" @input="GetPurchaseTemplate(purchase.purchaseTemplateId)" />
                                </div>
                            </div>

                            <br />
                            <purchase-item @input="SavePurchaseItems" ref="childComponentRef" :taxMethod="purchase.taxMethod" :taxRateId="purchase.taxRateId" :raw="purchase.isRaw" :isSerial="true" :po="true" :purchaseid="purchase.id" :key="rander" />
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <label>{{ $t('AddPurchaseOrder.TermandCondition') }}:</label>
                                    <textarea class="form-control " rows="3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'"
                                              v-model="purchase.note" />
                                </div>
                            </div>


                            <div class="accordion" role="tablist" v-if="(purchase.approvalStatus === 5 || purchase.approvalStatus === 9) && purchase.id != '00000000-0000-0000-0000-000000000000'  && internationalPurchase=='true'">
                                <b-card no-body class="mb-1" v-if="isValid('CanUploadAttachment') || isValid('CanDownloadAttachment')">
                                    <b-card-header header-tag="header" class="p-1" role="tab">
                                        <b-button block v-b-toggle.accordion-1 variant="primary">{{ $t('AddPurchaseOrder.Attachment') }}</b-button>
                                    </b-card-header>
                                    <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                                        <b-card-body>
                                            <import-attachment :purchase="purchase" :show="attachment" v-if="attachment" @close="attachmentSave" :document="'Purchase'" />
                                            <div>
                                                <div class="row" v-if="isValid('CanUploadAttachment')">
                                                    <div class="col-md-12 text-right">
                                                        <a href="javascript:void(0)" class="btn btn-outline-primary " v-on:click="attachment=true"> Upload</a>
                                                    </div>
                                                </div>
                                                <div class=" table-responsive">
                                                    <table class="table ">
                                                        <thead class="m-0">
                                                            <tr>
                                                                <th>#</th>
                                                                <th>{{ $t('AddPurchaseOrder.Date') }} </th>
                                                                <th>{{ $t('AddPurchaseOrder.Description') }} </th>
                                                                <th v-if="isValid('CanDownloadAttachment')">{{ $t('AddPurchaseOrder.Attachment') }}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(contact,index) in purchase.purchaseAttachments" v-bind:key="index">
                                                                <td>
                                                                    {{index+1}}
                                                                </td>
                                                                <th>{{getDate(contact.date)}}</th>
                                                                <th>{{contact.description}}</th>

                                                                <td v-if="isValid('CanDownloadAttachment')">
                                                                    <button class="btn btn-primary  btn-icon mr-2"
                                                                            v-if="contact.path != ''"
                                                                            v-on:click="DownloadAttachment(contact.path)">
                                                                        <i class="fa fa-download"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                                <b-card no-body class="mb-1" v-if="isValid('CanAddOrderAction') || isValid('CanViewOrderAction')">
                                    <b-card-header header-tag="header" class="p-1" role="tab">
                                        <b-button block v-b-toggle.accordion-2 variant="primary">Actions</b-button>
                                    </b-card-header>
                                    <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                                        <b-card-body>
                                            <add-company-action :action="action" :show="show" v-if="show" @close="IsSave" :document="'Purchase'" />

                                            <div class="row">
                                                <div class="col-md-12" v-if="isValid('CanAddOrderAction')">
                                                    <div class="col-sm-6 float-right">
                                                        <a href="javascript:void(0)" class="btn btn-outline-primary  float-right" v-on:click="show=true"> Action</a>
                                                    </div>
                                                </div>
                                                <div class=" table-responsive" v-if="isValid('CanViewOrderAction')">
                                                    <table class="table ">
                                                        <thead class="m-0">
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Status</th>
                                                                <th>{{ $t('AddPurchaseOrder.Date') }} </th>
                                                                <th>Description/Reason</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(process,index) in purchase.actionProcess" v-bind:key="process.id">
                                                                <td>
                                                                    {{index+1}}
                                                                </td>
                                                                <th><span class="badge badge-primary">{{process.processName}}</span></th>
                                                                <th>{{getDate(process.date)}}</th>
                                                                <th>{{process.description}}</th>

                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                                <b-card no-body class="mb-1" v-if="isValid('CanAddAdvancePayment') || isValid('CanViewAdvancePayment') || isValid('CanViewDetailAdvancePayment')">
                                    <b-card-header header-tag="header" class="p-1" role="tab">
                                        <b-button block v-b-toggle.accordion-3 variant="primary">Payment</b-button>
                                    </b-card-header>
                                    <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                                        <b-card-body>
                                            <purchaseorder-payment :totalAmount="totalAmount" :customerAccountId="advanceAccountId" :show="payment" v-if="payment" @close="paymentSave" :isPurchase="'true'" :isSaleOrder="'false'" :purchaseOrderId="purchase.id" :formName="'AdvancePay'" />
                                            <div>
                                                <div class="row" v-if="isValid('CanAddAdvancePayment')">
                                                    <div class="col-md-12 text-right">
                                                        <a href="javascript:void(0)" class="btn btn-outline-primary " v-on:click="payment=true"> Add Payment</a>
                                                    </div>
                                                </div>
                                                <div class=" table-responsive" v-if="isValid('CanViewAdvancePayment')">
                                                    <table class="table ">
                                                        <thead class="m-0">
                                                            <tr>
                                                                <th>#</th>
                                                                <th style="width:20%;">{{ $t('AddPurchaseOrder.Date') }} </th>
                                                                <th class="text-right">{{ $t('AddPurchaseOrder.Amount') }} </th>
                                                                <th class="text-center">{{ $t('AddPurchaseOrder.PaymentMode') }} </th>
                                                                <th>{{ $t('AddPurchaseOrder.Description') }} </th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(payment,index) in purchase.paymentVoucher" v-bind:key="index">
                                                                <td>
                                                                    {{index+1}}
                                                                </td>
                                                                <th>{{getDate(payment.date)}}</th>
                                                                <th class="text-right">{{currency}} {{parseFloat(payment.amount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</th>
                                                                <th class="text-center"><span v-if="payment.paymentMode==0">Cash</span><span v-if="payment.paymentMode==1">Bank</span></th>
                                                                <th>{{payment.narration}}</th>
                                                                <th>
                                                                    <a href="javascript:void(0)" title="Payment View" class="btn  btn-icon btn-primary btn-sm" v-on:click="ViewPaymentVoucher(payment.id, false)" v-if="isValid('CanViewDetailAdvancePayment')"><i class=" fas fa-eye"></i></a>
                                                                </th>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>

                                <b-card no-body class="mb-1" v-if="isValid('CanViewOrderExpense') || isValid('CanAddOrderExpense') || isValid('CanViewDetailOrderExpense')">
                                    <b-card-header header-tag="header" class="p-1" role="tab">
                                        <b-button block v-b-toggle.accordion-4 variant="primary">Expense</b-button>
                                    </b-card-header>
                                    <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
                                        <b-card-body>
                                            <purchaseorder-expense :show="expense" v-if="expense" @close="expenseSave" :isPurchase="'true'" :purchaseOrderId="purchase.id" :formName="'AdvanceExpense'" />
                                            <div>
                                                <div class="row" v-if="isValid('CanAddOrderExpense')">
                                                    <div class="col-md-12 text-right">
                                                        <a href="javascript:void(0)" class="btn btn-outline-primary " v-on:click="expense=true"> Add Expense</a>
                                                    </div>
                                                </div>
                                                <div class=" table-responsive" v-if="isValid('CanViewOrderExpense')">
                                                    <table class="table ">
                                                        <thead class="m-0">
                                                            <tr>
                                                                <th>#</th>
                                                                <th style="width:20%;">{{ $t('AddPurchaseOrder.Date') }} </th>
                                                                <th class="text-right">{{ $t('AddPurchaseOrder.Amount') }} </th>
                                                                <th class="text-center">{{ $t('AddPurchaseOrder.PaymentMode') }} </th>
                                                                <th>{{ $t('AddPurchaseOrder.Description') }} </th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(payment,index) in purchase.purchaseOrderExpenses" v-bind:key="index">
                                                                <td>
                                                                    {{index+1}}
                                                                </td>
                                                                <th>{{getDate(payment.date)}}</th>
                                                                <th class="text-right">{{currency}} {{parseFloat(payment.amount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</th>
                                                                <th class="text-center"><span v-if="payment.paymentMode==0">Cash</span><span v-if="payment.paymentMode==1">Bank</span></th>
                                                                <th>{{payment.narration}}</th>
                                                                <th>
                                                                    <a href="javascript:void(0)" title="Payment View" class="btn  btn-icon btn-primary btn-sm" v-on:click="ViewPaymentVoucher(payment.id, true)" v-if="isValid('CanViewDetailOrderExpense')"><i class=" fas fa-eye"></i></a>
                                                                </th>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </div>

                            <div class="row" v-if="!loading">
                                <div class="col-md-12 arabicLanguage">
                                    <div v-if="purchase.id === '00000000-0000-0000-0000-000000000000'">
                                        <button class="btn btn-primary mr-2 float-left" v-on:click="Attachment()">
                                            {{ $t('AddPurchase.Attachment') }}
                                        </button>

                                        <button class="btn btn-primary  mr-2"
                                                v-if="isValid('CanViewDraftOrder')"
                                                v-on:click="savePurchase('Draft')"
                                                :disabled="$v.$invalid || purchase.purchaseOrderItems.filter(x => x.totalPiece=='').length > 0 || purchase.purchaseOrderItems.filter(x => x.unitPrice=='').length > 0">
                                            {{ $t('AddPurchaseOrder.SaveAsDraft') }}
                                        </button>
                                        <button class="btn btn-primary  mr-2"
                                                v-on:click="savePurchase('InProcess')"
                                                v-if="isValid('CanViewInProcessOrder')  && isValid('CanAllowOrderVersion')"
                                                :disabled="$v.$invalid || purchase.purchaseOrderItems.filter(x => x.totalPiece=='').length > 0 || purchase.purchaseOrderItems.filter(x => x.unitPrice=='').length > 0">
                                            {{ $t('AddPurchaseOrder.ConfirmAsInProcess') }}
                                        </button>
                                        <button class="btn btn-primary mr-2"
                                                v-on:click="savePurchase('Approved')"
                                                v-if="isValid('CanAddPurchaseOrder')"
                                                :disabled="$v.$invalid || purchase.purchaseOrderItems.filter(x => x.totalPiece=='').length > 0 || purchase.purchaseOrderItems.filter(x => x.unitPrice=='').length > 0">
                                            {{ $t('AddPurchaseOrder.SaveAsPost') }}
                                        </button>
                                        <button class="btn btn-outline-danger mr-2"
                                                v-on:click="goToPurchase">
                                            {{ $t('AddPurchaseOrder.Cancel') }}
                                        </button>
                                    </div>
                                    <div v-if="purchase.approvalStatus === 4 && purchase.id != '00000000-0000-0000-0000-000000000000'">
                                        <button class="btn btn-primary mr-2 float-left" v-on:click="Attachment()">
                                            {{ $t('AddPurchase.Attachment') }}
                                        </button>

                                        <button class="btn btn-primary  mr-2"
                                                v-on:click="savePurchase('Draft')"
                                                v-if="isValid('CanViewDraftOrder') && isValid('CanEditPurchaseOrder')"
                                                :disabled="$v.$invalid || purchase.purchaseOrderItems.filter(x => x.totalPiece=='').length > 0 || purchase.purchaseOrderItems.filter(x => x.unitPrice=='').length > 0">
                                            {{ $t('AddPurchaseOrder.UpdateAsDraft') }}
                                        </button>
                                        <button class="btn btn-primary mr-2"
                                                v-on:click="savePurchase('InProcess')"
                                                v-if="isValid('CanViewInProcessOrder')  && isValid('CanAllowOrderVersion')&& isValid('CanEditPurchaseOrder')"
                                                :disabled="$v.$invalid || purchase.purchaseOrderItems.filter(x => x.totalPiece=='').length > 0 || purchase.purchaseOrderItems.filter(x => x.unitPrice=='').length > 0">
                                            {{ $t('AddPurchaseOrder.UpdateInProcess') }}
                                        </button>
                                        <button class="btn btn-primary  mr-2"
                                                v-on:click="savePurchase('Approved')"
                                                v-if="isValid('CanAddPurchaseOrder') && isValid('CanEditPurchaseOrder')"
                                                :disabled="$v.$invalid || purchase.purchaseOrderItems.filter(x => x.totalPiece=='').length > 0 || purchase.purchaseOrderItems.filter(x => x.unitPrice=='').length > 0">
                                            {{ $t('AddPurchaseOrder.UpdateAsPost') }}
                                        </button>
                                        <button class="btn btn-outline-danger mr-2"
                                                v-on:click="goToPurchase">
                                            {{ $t('AddPurchaseOrder.Cancel') }}
                                        </button>
                                    </div>
                                    <div v-if="purchase.approvalStatus === 5 && purchase.id != '00000000-0000-0000-0000-000000000000'">
                                        <button class="btn btn-primary mr-2 float-left" v-on:click="Attachment()">
                                            {{ $t('AddPurchase.Attachment') }}
                                        </button>

                                        <button class="btn btn-primary mr-2"
                                                v-on:click="savePurchase('InProcess')"
                                                v-if="isValid('CanViewInProcessOrder')  && isValid('CanAllowOrderVersion')&& isValid('CanEditPurchaseOrder')"
                                                :disabled="$v.$invalid || purchase.purchaseOrderItems.filter(x => x.totalPiece=='').length > 0 || purchase.purchaseOrderItems.filter(x => x.unitPrice=='').length > 0">
                                            {{ $t('AddPurchaseOrder.UpdateInProcess') }}
                                        </button>
                                        <button class="btn btn-primary  mr-2"
                                                v-on:click="savePurchase('Approved')"
                                                v-if="isValid('CanAddPurchaseOrder') || isValid('CanEditPurchaseOrder')"
                                                :disabled="$v.$invalid || purchase.purchaseOrderItems.filter(x => x.totalPiece=='').length > 0 || purchase.purchaseOrderItems.filter(x => x.unitPrice=='').length > 0">
                                            {{ $t('AddPurchaseOrder.UpdateAsPost') }}
                                        </button>
                                        <button class="btn btn-outline-danger mr-2"
                                                v-on:click="goToPurchase">
                                            {{ $t('AddPurchaseOrder.Cancel') }}
                                        </button>
                                    </div>
                                </div>

                            </div>
                            <div class="card-footer col-md-3 text-left" v-else>
                                <loading :active.sync="loading"
                                         :can-cancel="false"
                                         :is-full-page="true"></loading>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <bulk-attachment :attachmentList="purchase.attachmentList" :show="isAttachshow" v-if="isAttachshow" @close="attachmentSaved" />
            <purchase-order-payment-view :data="paymentview" :formName="'AdvancePay'" @close="paymentView" :show="isPaymentview" v-if="isPaymentview" />
            <purchase-order-payment-view :data="paymentview" :formName="'AdvanceExpense'" @close="paymentView" :show="isExpenseview" v-if="isExpenseview" />
        </div>

    </div>
    <div v-else> <acessdenied></acessdenied></div>

</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import Loading from 'vue-loading-overlay';
    import moment from "moment";
    import "vue-loading-overlay/dist/vue-loading.css";
    import { required } from "vuelidate/lib/validators";
    import Multiselect from 'vue-multiselect'
    //import VueBarcode from 'vue-barcode';
    export default {
        mixins: [clickMixin],
        components: {
            Multiselect,
            Loading
        },
        data: function () {
            return {
                //versionAllow: '',
                internationalPurchase: '',
                advanceAccountId: '',
                currency: '',
                totalAmount: 0,
                daterander: 0,
                vatRander: 0,
                rander: 0,
                attachment: false,
                isAttachshow: false,
                purchase: {
                    id: "00000000-0000-0000-0000-000000000000",
                    date: "",
                    registrationNo: "",
                    supplierId: "",
                    invoiceNo: "",
                    invoiceDate: "",
                    purchaseOrder: "",
                    note: '',
                    purchaseOrderItems: [],
                    attachmentList: [],
                    taxMethod: '',
                    taxRateId: '',
                    purchaseTemplateId: '',
                    path: '',
                    isRaw: false,
                    internationalPurchase: false,
                },
                raw: '',
                loading: false,
                language: 'Nothing',
                options: [],
                supplierRender: 0,
                show: false,
                payment: false,
                expense: false,
                action: {
                    id: '00000000-0000-0000-0000-000000000000',
                    purchaseOrderId: '',
                    processId: '',
                    date: '',
                    description: '',
                },
                paymentview: '',
                isExpenseview: false,
                isPaymentview: false,
            };
        },
        validations: {
            purchase: {
                date: { required },
                registrationNo: { required },
                supplierId: { required },
                invoiceNo: {},
                invoiceDate: {},

                purchaseOrderItems: { required },
            },
        },
        methods: {
            Attachment: function () {
                this.isAttachshow = true;
            },

            attachmentSaved: function (attachment) {
                this.purchase.attachmentList = attachment;
                this.isAttachshow = false;
            },

            GetPurchaseTemplate: function (id) {
                var root = this;

                root.$https.get('Purchase/PurchaseTemplateDetail?id=' + id, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
                    .then(function (response) {
                        if (response.data != null && response.data != '') {
                            root.purchase.supplierId = response.data.supplierId;
                            root.purchase.taxMethod = response.data.taxMethod;
                            root.purchase.taxRateId = response.data.taxRateId;
                            root.purchase.note = response.data.note;

                            root.$refs.childComponentRef.clearList();

                            response.data.purchaseOrderItems.forEach(function (item) {
                                if (item.product.inventory != null && item.product.inventory.currentQuantity < (item.product.stockLevel == '' ? 0 : parseFloat(item.product.stockLevel))) {

                                    root.$refs.childComponentRef.addProduct(item.productId, item.product, item, true, root.purchase.taxRateId, root.purchase.taxMethod);

                                }
                                
                            });
                            root.supplierRender++;
                            root.vatRander++;
                            root.rendered++;
                        }
                    });
            },

            getTotalAmount: function () {
                this.totalAmount = this.$refs.childComponentRef.getTotalAmount();
            },
            getDate: function (date) {
                if (date == null || date == undefined) {
                    return "";
                }
                else {
                    return moment(date).format('LLL');
                }
            },
            ChangeSupplier: function () {
                this.supplierRender++;
                this.rander++;
            },
            languageChange: function (lan) {
                if (this.language == lan) {
                    if (this.purchase.id == '00000000-0000-0000-0000-000000000000') {

                        var getLocale = this.$i18n.locale;
                        this.language = getLocale;

                        this.$router.go('/addproduct');
                    }
                    else {
                        this.$swal({
                            title: 'Error!',
                            text: 'You cannot Change language during update, otherwise your current page data will be lose',
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            icon: 'error',
                            timer: 4000,
                            timerProgressBar: true,
                        });
                    }
                }


            },

            DownloadAttachment(path) {

                var root = this;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                var ext = path.split('.')[1];
                root.$https.get('/Contact/DownloadFile?filePath=' + path, { headers: { "Authorization": `Bearer ${token}` }, responseType: 'blob' })
                    .then(function (response) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'file.' + ext);
                        document.body.appendChild(link);
                        link.click();
                    });
            },

            uploadImage() {

                var root = this;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                var file = null;

                file = this.$refs.imgupload1.files;

                var fileData = new FormData();
                for (var k = 0; k < file.length; k++) {
                    fileData.append("files", file[k]);
                }
                root.$https.post('/Company/UploadFilesAsync', fileData, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {

                            root.purchase.path = response.data;

                        }
                    },
                        function () {
                            this.loading = false;
                            root.$swal({
                                title: "Error!",
                                text: "Something went wrong",
                                type: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                        });
            },

            AutoIncrementCode: function () {
                var root = this;
                var token = "";
                if (root.$session.exists()) {
                    token = localStorage.getItem("token");
                }
                root.$https
                    .get("/Purchase/PurchaseOrderAutoGenerateNo", {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then(function (response) {
                        if (response.data != null) {
                            root.purchase.registrationNo = response.data;
                        }
                    });
            },
            SavePurchaseItems: function (purchaseOrderItems) {
                this.purchase.purchaseOrderItems = purchaseOrderItems;
                this.getTotalAmount();
            },

            savePurchase: function (status) {
                this.purchase.approvalStatus = status
                localStorage.setItem('active', status);

                this.loading = true;
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.purchase.internationalPurchase = this.internationalPurchase == 'true' ? true : false;
                this.purchase.isMultiUnit = localStorage.getItem('IsMultiUnit') == 'true' ? true : false;
                //root.purchase.date = root.purchase.date + " " + moment().format("hh:mm A");
                this.$https.post('/Purchase/SavePurchaseOrderInformation', root.purchase, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {
                        if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Add") {
                            root.info = response.data.bpi
                            root.$swal({
                                title: "Saved!",
                                text: response.data.message.isAddUpdate,
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonStyling: false,
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,

                            }).then(function (ok) {
                                if (ok != null) {
                                    root.$router.go('addpurchaseorder');
                                }
                            });
                        }
                        else if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Update") {
                            root.info = response.data.bpi
                            root.$swal({
                                title: "Saved!",
                                text: response.data.message.isAddUpdate,
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonStyling: false,
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,

                            }).then(function (ok) {
                                if (ok != null) {
                                    root.$router.push({
                                        path: '/purchaseorder',
                                        query: {
                                            data: 'purchaseorders'
                                        }
                                    })
                                }
                            });
                        }
                        else {
                            root.$swal({
                                title: 'Error!',
                                text: response.data.message.isAddUpdate,
                                type: 'error',
                                confirmButtonClass: "btn btn-danger",
                                icon: 'error',
                                timer: 1500,
                                timerProgressBar: true,
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                type: 'error',
                                icon: 'error',
                                title: root.$t('AddPurchaseOrder.Error'),
                                text: error.response.data,
                                confirmButtonClass: "btn btn-danger",
                                showConfirmButton: true,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false
                    })
                    .finally(() => root.loading = false)

            },
            IsSave: function () {
                this.show = false;
                this.GetProcessType();
            },
            attachmentSave: function () {
                this.GetAttachment();
                this.GetProcessType();
                this.attachment = false;
                this.GetProcessType();
            },
            paymentSave: function () {
                this.payment = false;
                this.GetPaymentVoucher();
                this.GetProcessType();
            },
            expenseSave: function () {
                this.expense = false;
                this.GetExpenseVoucher();
                this.GetProcessType();
            },
            GetExpenseVoucher: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https.get('Purchase/PurchaseOrderExpensePaymentList?id=' + this.purchase.id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null && response.data != '') {
                            root.purchase.purchaseOrderExpenses = response.data;
                        }
                    });
            },
            GetPaymentVoucher: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https.get('Purchase/PurchaseOrderPaymentList?id=' + this.purchase.id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null && response.data != '') {
                            root.purchase.paymentVoucher = response.data;
                        }
                    });
            },
            GetAttachment: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https.get('Purchase/PurchaseOrderAttachmentList?id=' + this.purchase.id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null && response.data != '') {
                            root.purchase.purchaseAttachments = response.data;
                        }
                    });
            },
            GetProcessType: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https.get('Purchase/PurchaseOrderActionList?id=' + this.purchase.id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null && response.data != '') {
                            root.purchase.actionProcess = response.data;
                        }
                    });
            },
            goToPurchase: function () {
                
                if (this.isValid('CanViewDraftOrder') || this.isValid('CanViewPostOrder') || (this.isValid('CanViewInProcessOrder') && this.isValid('CanAllowOrderVersion'))) {
                    this.$router.push({
                        path: '/purchaseorder',
                        query: {
                            data: 'purchaseorders'
                        }
                    })
                }
                else {
                    this.$router.go();
                }
                
            },
            ViewPaymentVoucher: function (id, expense) {
                var root = this;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Purchase/PurchaseOrderPaymentDetail?Id=' + id + '&expense=' + expense, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.$https.get('/PaymentVoucher/PaymentVoucherDetails?Id=' + response.data.paymentVoucherId, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                            if (response.data != null) {
                                root.paymentview = response.data;
                                if (expense) {
                                    root.isExpenseview = true;
                                }
                                else {
                                    root.isPaymentview = true;
                                }
                            }
                        });
                    }
                });
            },
            paymentView: function () {
                this.isPaymentview = false;
                this.isExpenseview = false;
            }
        },
        created: function () {
            this.$emit('input', this.$route.name);

            if (this.$route.query.data != undefined) {
                this.purchase = this.$route.query.data;
                this.action.purchaseOrderId = this.purchase.id;
                this.advanceAccountId = this.$route.query.data.advanceAccountId;
                this.purchase.date = moment(this.purchase.date).format("LLL");
                this.rander++;
                this.rendered++;
            }
            else {
                this.purchase.taxRateId = localStorage.getItem('TaxRateId');
                this.purchase.taxMethod = localStorage.getItem('taxMethod');
            }
        },
        mounted: function () {
            this.language = this.$i18n.locale;
            this.currency = localStorage.getItem('currency');
            this.internationalPurchase = localStorage.getItem('InternationalPurchase');
            //this.versionAllow = localStorage.getItem('VersionAllow');
            if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {
                this.options = ['Inclusive', 'Exclusive'];
            }
            else {
                this.options = ['شامل', 'غير شامل'];
            }
            if (this.$session.exists()) {
                this.raw = localStorage.getItem('IsProduction');
            }
            if (this.$route.query.data == undefined) {
                this.AutoIncrementCode();

                this.purchase.date = moment().format("LLL");
                this.daterander++;
            }
        },
    };
</script>
<style scoped>
    .poHeading {
        font-size: 32px;
        font-style: normal;
        line-height: 37px;
        font-weight: 500;
        font-size: 24px;
        line-height: 26px;
        color: #3178F6;
        text-align: center
    }

    .dateHeading {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.01em;
        text-align: left;
        color: #35353D;
    }

    .bottomBorder {
        padding-top: 24px !important;
        border-bottom: 1px solid #EFF4F7;
    }
</style>
