<template>
    <div hidden>
        <div ref="mychildcomponent"  id='purchaseInvoice' style="width:400px;z-index:-9999">
            <!--INFORMATION-->
            <div style="width:400px;margin-left:20px;">
                <div style="text-align: center;margin-bottom:15px;" v-if="b2b && b2c">
                    <div v-if="list.customerCategory=='B2B – Business to Business'">
                        <span style="font-size:24px;color:black;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Invoice Type (Eng)'))">{{taxInvoiceLabel}}<br /></span>
                        <span style="font-size:24px;color:black;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Invoice Type (Ar)'))">{{taxInvoiceLabelAr}}<br /></span>
                    </div>
                    <div v-else-if="list.customerCategory=='B2C – Business to Client'">
                        <span style="font-size:24px;color:black;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Invoice Type (Eng)'))">{{simplifyTaxInvoiceLabel}}<br /></span>
                        <span style="font-size:24px;color:black;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Invoice Type (Ar)'))">{{simplifyTaxInvoiceLabelAr}}<br /></span>
                    </div>
                    <div v-else>
                        <span style="font-size:24px;color:black;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Invoice Type (Eng)'))">{{simplifyTaxInvoiceLabel}}<br /></span>
                        <span style="font-size:24px;color:black;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Invoice Type (Ar)'))">{{simplifyTaxInvoiceLabelAr}}<br /></span>
                    </div>
                </div>

                <div style="text-align: center;margin-bottom:15px;" v-else-if="b2b">
                    <span style="font-size:24px;color:black;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Invoice Type (Eng)'))">{{taxInvoiceLabel}}<br /></span>
                    <span style="font-size:24px;color:black;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Invoice Type (Ar)'))">{{taxInvoiceLabelAr}}<br /></span>
                </div>

                <div style="text-align: center;margin-bottom:15px;" v-else-if="b2c">

                    <span style="font-size:24px;color:black;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Invoice Type (Eng)'))">{{simplifyTaxInvoiceLabel}}<br /></span>
                    <span style="font-size:24px;color:black;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Invoice Type (Ar)'))">{{simplifyTaxInvoiceLabelAr}}<br /></span>

                </div>

                <div style="width:100%;text-align:center;margin-top:10px" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Print Logo'))">
                    <img :src="headerFooters.company.logoPath" style="width: auto; max-width: 150px; height: auto; max-height: 120px;">
                </div>

                <!--<div style="width:100%;text-align:center;">
            <vue-qrcode v-bind:value="qrValue" style="width:200px" v-bind:errorCorrectionLevel="correctionLevel" />
        </div>-->

                <div style="text-align: center;margin-bottom:3px;">
                    <div style="text-align: center;">
                        <span style="font-size:30px;font-weight:bold;color:black;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Business Name (Ar)'))">{{headerFooters.company.nameArabic}}<br /></span>
                        <span style="font-size:30px;font-weight:bold;color:black;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Business Name (Eng)'))">{{headerFooters.company.nameEnglish}}<br /></span>
                        <span style="font-size:18px;font-weight:bold;color:black;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Business Type (Ar)')) && headerFooters.company.categoryArabic!='null'">{{headerFooters.company.categoryArabic}}<br /></span>
                        <span style="font-size:18px;font-weight:bold;color:black;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Business Type (Eng)')) && headerFooters.company.categoryEnglish!='null'">{{headerFooters.company.categoryEnglish}}<br /></span>
                        <span style="font-size:18px;font-weight:bold;color:black;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Welcome/Tagline (Ar)')) && headerFooters.welcomeLineAr!='' && headerFooters.welcomeLineAr!=null">{{headerFooters.welcomeLineAr}}<br /></span>
                        <span style="font-size:18px;font-weight:bold;color:black;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Welcome/Tagline (Eng)')) && headerFooters.welcomeLineEn!='' && headerFooters.welcomeLineEn!=null">{{headerFooters.welcomeLineEn}}<br /></span>

                        <span v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Management No (Eng)')) || PrintOption(headerFooters.printOptions.find(x => x.label=='Management No (Ar)'))" style="font-size:15px;color:black;"><span v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Management No (Eng)'))">Management No: </span> {{headerFooters.managementNo}}<span v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Management No (Ar)'))"> :رقم الإدارة</span> <br /></span>
                        <span v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Store Contact No (Eng)')) || PrintOption(headerFooters.printOptions.find(x => x.label=='Store Contact No (Ar)'))" style="font-size:15px;color:black;"><span v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Store Contact No (Eng)'))">Store Contact Number: </span> {{headerFooters.contactNo}}<span v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Store Contact No (Ar)'))"> :الـرقم الإدارة / المحل</span> <br /></span>

                        <p style="border:1px solid; width:350px;color:black;margin-left:auto;margin-right:auto;margin-top:0.1rem;margin-bottom:0.3rem;">
                            <span style="font-weight: bold;"><span style="font-weight: bold;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='VAT / Tax ID (Eng)'))">VAT No :</span> {{headerFooters.company.vatRegistrationNo}}<span style="font-weight: bold;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='VAT / Tax ID (Ar)'))"> :الرقم الضريبي</span>    <br /></span>
                            <span style="font-weight: bold;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='CR Number (Eng)')) || PrintOption(headerFooters.printOptions.find(x => x.label=='CR Number (Ar)'))"><span style="font-weight: bold;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='CR Number (Eng)'))">CR Number  :</span> {{headerFooters.company.companyRegNo}}<span style="font-weight: bold;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='CR Number (Ar)'))"> :الرقـم السـجـل الـتـجـارى </span>    <br /></span>
                            <span style="font-size:18px;color:black;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Company Name (Ar)'))">{{headerFooters.company.companyNameArabic}}<br /></span>
                            <span style="font-size:18px;color:black;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Company Name (Eng)'))">{{headerFooters.company.companyNameEnglish}}<br /></span>

                            <span style="font-size:16px;color:black;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Company Address (Ar)'))">{{headerFooters.company.addressArabic}}<br /></span>
                            <span style="font-size:16px;color:black;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Company Address (Eng)'))">{{headerFooters.company.addressEnglish}}<br /></span>
                        </p>

                        <p style="font-size:16px;color:black;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Business Address (Arabic)')) || PrintOption(headerFooters.printOptions.find(x => x.label=='Business Address (English)'))">
                            <span v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Business Address (Arabic)'))">{{headerFooters.businessAddressArabic}}</span> <br />
                            <span v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Business Address (English)'))">{{headerFooters.businessAddressEnglish}}</span>
                        </p>
                    </div>
                </div>


                <div style="width:100%;text-align:center;margin-top:2rem;">
                    <p v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Invoice Number(Eng)')) && PrintOption(headerFooters.printOptions.find(x => x.label=='Invoice Number(Ar)'))" style="font-size: 15px; font-weight: bold;color:black;margin-bottom:2px;padding-bottom:2px;"><span> Invoice No.</span> <span style="border:4px solid;font-size:25px;font-weight:bold;padding-left:2px;padding-right:2px;color:black;text-align:center"> {{list.registrationNo}}</span> <span style="font-size: 15px; font-weight: bold;color:black;margin-bottom:2px;padding-bottom:2px;text-align:right">رقم الفاتورة</span></p>

                    <p v-else-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Invoice Number(Eng)'))" style="border: 1px solid; font-size: 15px; font-weight: bold; color: black; margin-bottom: 2px; padding-bottom: 2px; text-align: center;width: 350px;margin-left: auto;margin-right: auto;"><span> Invoice No : <span style="font-size: 18px;">{{list.registrationNo}}</span></span>  </p>
                    <p v-else-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Invoice Number(Ar)'))" style="border: 1px solid; font-size: 15px; font-weight: bold; color: black; margin-bottom: 2px; padding-bottom: 2px; text-align: center;width: 350px;margin-left: auto;margin-right: auto;"><span> <span style="font-size: 18px;">{{list.registrationNo}} :</span>رقم الفاتورة</span>  </p>
                    <p v-else style="font-size: 15px; font-weight: bold;color:black;margin-bottom:2px;padding-bottom:2px;"><span> Invoice No.</span> <span style="border:4px solid;font-size:25px;font-weight:bold;padding-left:2px;padding-right:2px;color:black;text-align:center"> {{list.registrationNo}}</span> <span style="font-size: 15px; font-weight: bold;color:black;margin-bottom:2px;padding-bottom:2px;text-align:right">رقم الفاتورة</span></p>
                    <barcode :width="1.9" :height="50" v-bind:value="list.barCode" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Print Barcode'))"></barcode>
                    <p style="font-size:15px;font-weight:bold;color:black;">Date: <span style="margin-right:20px;margin-left:10px;padding-left:20px;color:black;">{{getdate(list.date)}}</span>  التاريخ</p>

                    <table class="table table-borderless " style="width:400px;">
                        <tr style="font-size:16px;">
                            <td class="" style="border-top: 0;padding-left:1px;padding-right:1px;color:black;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Counter Name (Eng)')) || PrintOption(headerFooters.printOptions.find(x => x.label=='Counter Number (Ar)'))"><span style="font-weight:bold;color:black;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Counter Name (Eng)'))">Counter#</span> <span v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Counter Number (Ar)'))">رقم الكاونتر </span> <br>{{list.counterCode}}</td>
                            <td style="text-align:right; border-top: 0;padding-left:1px;padding-right:1px;color:black;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='User Name (Eng)')) || PrintOption(headerFooters.printOptions.find(x => x.label=='User Name (Ar)'))"><span style="font-weight:bold;color:black;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='User Name (Eng)'))">User</span> <span v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='User Name (Ar)'))">أسم المستخدم</span> <br>{{list.userName}}</td>
                        </tr>

                        <tr style="font-size:16px;" v-if="list.cashCustomer != null">
                            <td class="" style="border-top: 0;padding-left:1px;padding-right:1px;color:black;"><span style="font-weight:bold;color:black;">Customer</span><span v-if="isOtherLang()">/ اسم العميل </span><br>{{list.cashCustomer}}</td>
                        </tr>
                        <tr style="font-size:18px;" v-else>
                            <td class="" style="border-top: 0;padding-left:1px;padding-right:1px;color:black;"><span style="font-weight:bold;color:black;">Customer</span><span v-if="isOtherLang()">/ اسم العميل </span><br>{{ ($i18n.locale == 'en' ||isLeftToRight()) ? (list.customerNameEn != '' && list.customerNameEn != null) ? list.customerNameEn : list.customerNameAr : (list.customerNameAr != '' && list.customerNameAr != null) ? list.customerNameAr : list.customerNameEn}}</td>
                        </tr>
                    </table>
                    <!--<p style="font-size:15px;font-weight:bold;color:black;">
                <span>Customer :</span>
                <span style="margin-right:20px;margin-left:10px;padding-left:20px;color:black;" v-if="list.cashCustomer != null">{{list.cashCustomer}}</span>
                <span style="margin-right:20px;margin-left:10px;padding-left:20px;color:black;" v-else> {{ ($i18n.locale == 'en' ||isLeftToRight()) ? (list.customerNameEn != '' && list.customerNameEn != null) ? list.customerNameEn : list.customerNameAr : (list.customerNameAr != '' && list.customerNameAr != null) ? list.customerNameAr : list.customerNameEn}} </span>
                <span style="color:black;" class="text-right"> اسم العميل</span>
            </p>-->

                </div>
                <div style="width:100%;">
                    <table class="table report-tbl" style="width:400px;">
                        <tr class="heading" style="font-size:15px;border-bottom:1px solid black;border-top:1px solid; black" v-if="headerFooters.invoicePrint=='English & Arabic'">
                            <th style="text-align: left; width: 5%; border-bottom: 1px solid #000000;border-top: 1px solid #000000; padding-left: 1px; padding-right: 1px; color: black;">رقم <br />No.</th>
                            <th style="text-align: center; width: 50%; border-bottom: 1px solid #000000;border-top: 1px solid #000000; padding-left: 1px; padding-right: 1px; color: black;">الصنف<br />Item</th>
                            <th style="text-align: center; width: 10%; border-bottom: 1px solid #000000;border-top: 1px solid #000000; padding-left: 1px; padding-right: 1px; color: black;">الكمية<br />Qty</th>
                            <th style="text-align: right; width: 15%; border-bottom: 1px solid #000000;border-top: 1px solid #000000; padding-left: 1px; padding-right: 1px; color: black;">السعر<br />Price</th>
                            <th style="text-align: right; width: 20%; border-bottom: 1px solid #000000;border-top: 1px solid #000000; padding-left: 1px; padding-right: 1px; color: black;">مجموع<br />Total</th>
                        </tr>
                        <tr class="heading" style="font-size:15px;border-bottom:1px solid black;border-top:1px solid; color:black" v-else-if="headerFooters.invoicePrint=='العربية'">
                            <th style="text-align: left; width: 5%; border-bottom: 1px solid #000000;border-top: 1px solid #000000; padding-left: 1px; padding-right: 1px; color: black;">رقم </th>
                            <th style="text-align: center; width: 50%; border-bottom: 1px solid #000000;border-top: 1px solid #000000; padding-left: 1px; padding-right: 1px; color: black;">الصنف</th>
                            <th style="text-align: center; width: 10%; border-bottom: 1px solid #000000;border-top: 1px solid #000000; padding-left: 1px; padding-right: 1px; color: black;">الكمية</th>
                            <th style="text-align: right; width: 15%; border-bottom: 1px solid #000000;border-top: 1px solid #000000; padding-left: 1px; padding-right: 1px; color: black;">السعر</th>
                            <th style="text-align: right; width: 20%; border-bottom: 1px solid #000000;border-top: 1px solid #000000; padding-left: 1px; padding-right: 1px; color: black;">مجموع</th>
                        </tr>
                        <tr class="heading" style="font-size:15px;border-bottom:1px solid black;border-top:1px solid; color:black" v-else-if="headerFooters.invoicePrint=='English'">
                            <th style="text-align: left; width: 5%; border-bottom: 1px solid #000000;border-top: 1px solid #000000; padding-left: 1px; padding-right: 1px; color: black;">No.</th>
                            <th style="text-align: center; width: 50%; border-bottom: 1px solid #000000;border-top: 1px solid #000000; padding-left: 1px; padding-right: 1px; color: black;">Item</th>
                            <th style="text-align: center; width: 10%; border-bottom: 1px solid #000000;border-top: 1px solid #000000; padding-left: 1px; padding-right: 1px; color: black;">Qty</th>
                            <th style="text-align: right; width: 15%; border-bottom: 1px solid #000000;border-top: 1px solid #000000; padding-left: 1px; padding-right: 1px; color: black;">Price</th>
                            <th style="text-align: right; width: 20%; border-bottom: 1px solid #000000;border-top: 1px solid #000000; padding-left: 1px; padding-right: 1px; color: black;">Total</th>
                        </tr>
                        <template v-for="(item, index) in list.saleItems">
                            <tr style="font-size:15px;" v-bind:key="item.id">
                                <td style="text-align: left; border-top: 0; padding-top: 0; padding-left: 1px; padding-right: 1px; color: black;">{{index+1}}</td>
                                <td style="text-align: left; border-top: 0; padding-top: 0; padding-left: 1px; padding-right: 1px; color: black; font-weight: 600;" v-if="headerFooters.invoicePrint=='English & Arabic'">  {{item.arabicName}} &nbsp;{{item.productName }}</td>
                                <td style="text-align: left; border-top: 0; padding-top: 0; padding-left: 1px; padding-right: 1px; color: black; font-weight: 600;" v-else-if="headerFooters.invoicePrint=='English'">{{item.productName }}</td>
                                <td style="text-align: left; border-top: 0; padding-top: 0; padding-left: 1px; padding-right: 1px; color: black; font-weight: 600;" v-else-if="headerFooters.invoicePrint=='العربية'">{{item.arabicName }}</td>
                                <td style="text-align: left; border-top: 0; padding-top: 0; padding-left: 1px; padding-right: 1px; color: black; font-weight: 600;" v-else>  {{item.arabicName}} &nbsp;{{item.productName }}</td>

                                <!--<td style="text-align: left; border-top: 0; padding-top: 0; padding-left: 1px; padding-right: 1px; color: black; font-weight: 600;" v-else>  {{item.arabicName}} &nbsp;{{item.productName }}</td>-->

                                <td style="text-align: center; border-top: 0; padding-top: 0; padding-left: 1px; padding-right: 1px; color: black;">{{item.quantity }}</td>
                                <td style="text-align: right; border-top: 0; padding-top: 0; padding-left: 1px; padding-right: 1px; color: black;">{{item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                <td style="text-align: right; border-top: 0; padding-top: 0; padding-left: 1px; padding-right: 1px; color: black;">{{(item.total).toFixed(3).slice(0,-1)}}</td>
                            </tr>
                        </template>
                        <tr class="heading" style="font-size:16px;">
                            <th style="text-align:center; padding-left:1px;padding-right:1px;color:black;border-bottom: 1px solid #000000;border-top: 1px solid #000000;" colspan="2">T.Items  <span v-if="isOtherLang()">عدد العنف </span> <br /> {{list.saleItems.filter(prod => prod.quantity>0).length}}</th>
                            <th style="text-align: center; padding-left: 1px; padding-right: 1px; color: black;border-bottom: 1px solid #000000;border-top: 1px solid #000000;" colspan="2">T.Pieces  <span v-if="isOtherLang()">عدد القطع </span> <br /> {{calulateTotalQty}}</th>
                            <th style="text-align: center; padding-left: 1px; padding-right: 1px; color: black;border-bottom: 1px solid #000000;border-top: 1px solid #000000;">G.Total  <span v-if="isOtherLang()">الإجمالي  </span><br /> {{calulateTotalExclVAT.toFixed(3).slice(0,-1)}}</th>
                        </tr>
                        <tr style="font-size:16px;">
                            <td colspan="3" style="text-align: right; border-bottom: 0; padding-bottom: 1px; padding-left: 1px; padding-right: 0px; color: black;">Total wihout VAT  <span v-if="isOtherLang()">الإجمالي قبل ضريبة</span>:</td>
                            <td colspan="2" style="padding-right:3rem; text-align: right; border-bottom: 0; padding-bottom: 1px; padding-left: 10px; padding-right: 1px; color: black;">{{(calulateTotalExclVAT - calulateTotalInclusiveVAT).toFixed(3).slice(0,-1)}}</td>
                        </tr>
                        <tr style="font-size:16px;">
                            <td colspan="3" style="text-align: right; padding-left: 1px; padding-right: 0px; border-top: 0; padding-top: 0; border-bottom: 0; padding-bottom: 1px; color: black;">Discount <span v-if="isOtherLang()">قيمة الخصم</span>:</td>
                            <td colspan="2" style="padding-right: 3rem; text-align: right; padding-left: 10px; padding-right: 1px; border-top: 0; padding-top: 0; border-bottom: 0; padding-bottom: 1px; color: black; ">{{ calulateDiscountAmount.toFixed(3).slice(0,-1) }}</td>
                        </tr>
                        <tr style="font-size:16px;">
                            <td style="text-align: right; padding-left: 1px; padding-right: 0px; border-top: 0; padding-top: 0; border-bottom: 0; padding-bottom: 1px; color: black;" colspan="3">Total after Discount <span v-if="isOtherLang()">الإجمالي بعد الخصم </span>:</td>
                            <td colspan="2" style="padding-right: 3rem; text-align: right; padding-left: 10px; padding-right: 1px; border-top: 0; padding-top: 0; border-bottom: 0; padding-bottom: 1px; color: black; ">{{ ((calulateTotalExclVAT - calulateTotalInclusiveVAT) - calulateDiscountAmount).toFixed(3).slice(0,-1) }}</td>
                        </tr>

                        <tr style="font-size:16px;">
                            <td style="text-align: right; padding-left: 1px; padding-right: 0px; border-top: 0; padding-top: 0; padding-bottom: 3px; color: black;" colspan="3">VAT 15% <span v-if="isOtherLang()">الضريبة</span>:</td>
                            <td colspan="2" style="padding-right: 3rem; text-align: right; padding-left: 10px; padding-right: 1px; border-top: 0; padding-top: 0; padding-bottom: 3px; color: black; ">{{ calulateTotalVAT.toFixed(3).slice(0,-1) }}</td>
                        </tr>
                        <tr style="font-size:16px;font-weight:bold;" v-if="!isCalculationShow">
                            <td style="text-align: right; padding-left: 1px; padding-right: 0px; padding-top: 5px; color: black;border-top: 0;" colspan="3">Payable Amount <span v-if="isOtherLang()">الإجمالي المستحق</span>:</td>
                            <td colspan="2" style="padding-right: 3rem; text-align: right; padding-left: 10px; padding-right: 1px; padding-top: 5px; color: black;border-top: 0; ">{{ (calulateNetTotal - calulateDiscountAmount).toFixed(3).slice(0,-1) }}</td>
                        </tr>
                        <tr style="font-size:16px;font-weight:bold;" v-else>
                            <td style="text-align: right; padding-left: 1px; padding-right: 0px; padding-top: 5px; color: black;border-top: 0;" colspan="3">Total with Vat <span v-if="isOtherLang()">إجمالي مع ضريبةالقيمةالمضافة</span>:</td>
                            <td colspan="2" style="padding-right: 3rem; text-align: right; padding-left: 10px; padding-right: 1px; padding-top: 5px; color: black;border-top: 0; ">{{ (calulateNetTotal - calulateDiscountAmount).toFixed(3).slice(0,-1) }}</td>
                        </tr>

                        <tr style="font-size:19px;font-weight:bold;">
                            <td style="text-align:center; color: black;border-top: 1px solid #000000;" colspan="5">Payment Mode <span v-if="isOtherLang()">طريقة الدفع </span></td>
                        </tr>
                        <tr style="font-size:17px;padding-bottom:10px;" v-for="payment in list.paymentTypes" v-bind:key="payment.id">
                            <td style="text-align: left; color: black;border-top: 0; padding-bottom:4px;padding-top:4px;" colspan="3">{{payment.name}} <span v-if="payment.paymentType==2">(Rate={{payment.amountCurrency}}* Amount={{payment.rate}})</span>:</td>
                            <td style="text-align: right; color: black;border-top: 0;padding-bottom:4px;padding-top:4px;" colspan="2">{{parseFloat(payment.amount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>
                        </tr>
                        <tr style="font-size:17px;" v-if="isCalculationShow">
                            <td style="text-align: left; border-top: 0; padding-top: 0; color: black;" colspan="3">Invoice Amount قيمة الفاتورة:</td>
                            <td colspan="2" style="text-align: right; border-top: 0; padding-top: 0; color: black;">{{ (totalInvoiceAmount = calulateNetTotal - calulateDiscountAmount).toFixed(3).slice(0,-1) }}</td>
                        </tr>
                        <tr style="font-size: 15px; font-weight: bold; border-bottom: 1px solid #000000; border-top: 1px solid #000000 " v-if="isCalculationShow">
                            <td style="text-align: left; border-top:0; padding-top: 0; color: black;" colspan="3" v-if="(totalInvoiceAmount + totalPaidAmount) > 0">Payable Amount الإجمالي المستحق:</td>
                            <td style="text-align: left; border-top:0; padding-top: 0; color: black;" colspan="3" v-else>Refund Amount المبلغ المسترد:</td>
                            <td colspan="2" style="text-align: right; border-top: 0; padding-top: 0; color: black;">{{ (totalInvoiceAmount + totalPaidAmount).toFixed(3).slice(0,-1) }}</td>
                        </tr>
                        <tr style="font-size:17px;">
                            <th style="text-align: left; border-top: 0; padding-top: 0; color: black;" colspan="3">Change Due:</th>
                            <th colspan="2" style="text-align: right; border-top: 0; padding-top: 0; color: black;">{{ list.change }}</th>
                        </tr>

                        <!--<tr style="font-size:17px;" v-if="isDoublePrint">
                    <td style="text-align: center; text-transform: capitalize; color: black;border-top: 0;" colspan="5">{{ (totalInvoiceAmount - totalReturnInvoiceAmount).toFixed(3).slice(0,-1) | toWords}}</td>
                </tr>-->
                        <tr style="font-size:17px;" v-if="isCalculationShow">
                            <td style="text-align: center; text-transform: capitalize; color: black;border-top: 0;" colspan="5">{{ (totalInvoiceAmount + totalPaidAmount).toFixed(3).slice(0,-1) | toWords}}</td>
                        </tr>
                        <tr style="font-size:17px;" v-if="!isCalculationShow">
                            <td style="text-align: center; text-transform: capitalize; color: black;border-top: 0;" colspan="5">{{ (calulateNetTotal - calulateDiscountAmount).toFixed(3).slice(0,-1) | toWords}}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <div style="width:400px;display:flex;" v-if="isOtherLang() && english=='true'">
                <div style="width:200px;margin-left:10px;">
                    <p style="font-size:16px;color:black;font-weight:bold;">Refund / Exchange Policy</p>
                    <span style="font-size:15px;color:black;" v-html="headerFooters.footerEn"></span>
                </div>
                <div style="width:190px;">
                    <p style="font-size:17px;text-align:right;color:black;font-weight:bold;">سياسة الإسترجاع و الإستبدال</p>
                    <span style="font-size:17px;text-align:right;color:black;" v-html="headerFooters.footerAr"></span>
                </div>
            </div>
            <div style="width:400px;display:flex;" v-else-if="isOtherLang()">
                <div style="width:400px;">
                    <p style="font-size:17px;text-align:right;color:black;font-weight:bold;">سياسة الإسترجاع و الإستبدال</p>
                    <span style="font-size:17px;text-align:right;color:black;" v-html="headerFooters.footerAr"></span>
                </div>
            </div>
            <div style="width:400px;display:flex;" v-else-if="english=='true'">
                <div style="width:400px;margin-left:10px;">
                    <p style="font-size:16px;color:black;font-weight:bold;">Refund / Exchange Policy</p>
                    <span style="font-size:15px;color:black;" v-html="headerFooters.footerEn"></span>
                </div>
            </div>

            <div style="width:400px;display:block;margin-left:20px;margin-top:1.5rem" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Gratitude Message (Ar)')) || PrintOption(headerFooters.printOptions.find(x => x.label=='Gratitude Message (Eng)'))">
                <p style="color:transparent;">hidden</p>
                <p style="text-align:center;color:black;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Gratitude Message (Ar)'))"><span style="margin-right:5px;color:black;">*</span> <span style="margin-right:5px;color:black;">*</span> <span style="margin-right:10px;color:black;">*</span> <span style="font-size:25px; font-weight:bold;color:black;">{{headerFooters.closingLineAr}}</span>  <span style="margin-left: 10px;color:black;">*</span> <span style="margin-left: 5px;color:black;">*</span> <span style="margin-left:5px;color:black;">*</span></p>
                <h6 style="text-align:center;color:black;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Gratitude Message (Eng)'))">{{headerFooters.closingLineEn}}</h6>
            </div>

            <div v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Print QR'))" style="width:400px;display:block;margin-left:20px;margin-top:1.5rem;text-align:center;">
                <vue-qrcode v-bind:value="qrValue" style="width:200px" v-bind:errorCorrectionLevel="correctionLevel" />
            </div>

        </div>

        <div ref="myReturnComponent" id='saleReturn' style="width:400px;z-index:-9999">


            <!--For Sale Return-->

            <div style="width:400px;margin-left:20px;">

                <div style="width:100%;">
                    <div style="text-align: center;">
                        <span style="font-size:30px;font-weight:bold;color:black;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Business Name (Eng)')) || PrintOption(headerFooters.printOptions.find(x => x.label=='Business Name (Ar)'))"><span v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Business Name (Eng)'))">{{headerFooters.company.nameEnglish}}</span> <span v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Business Name (Ar)'))">{{headerFooters.company.nameArabic}}</span><br /> </span>
                        <span style="font-size:18px;font-weight:bold;color:black;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Business Type (Eng)')) || PrintOption(headerFooters.printOptions.find(x => x.label=='Business Type (Ar)'))"><span v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Business Type (Eng)'))">{{headerFooters.company.categoryEnglish}} </span><span v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Business Type (Ar)'))">{{headerFooters.company.categoryArabic}}</span><br /></span>
                        <p style="border:1px solid; width:350px;font-weight:bold;color:black;margin-left:auto;margin-right:auto;margin-top:1.3rem;margin-bottom:1.3rem;">
                            <span v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='VAT/Tax ID (Eng)')) || PrintOption(headerFooters.printOptions.find(x => x.label=='VAT/Tax ID (Ar)'))"> <span v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='VAT/Tax ID (Eng)'))">VAT No.</span> {{headerFooters.company.vatRegistrationNo}}<span v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='VAT/Tax ID (Ar)'))">الرقم الضريبي</span>    <br /></span>
                            <span style="font-size:18px;color:black;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Company Name (Eng)'))">{{headerFooters.company.companyNameEnglish}}<br /></span>
                            <span style="font-size:17px;color:black;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Company Name (Ar)'))">{{headerFooters.company.companyNameArabic}}</span>
                        </p>
                        <span style="font-size:18px;color:black;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Address (Ar)'))">{{headerFooters.company.addressArabic}}<br /></span>
                        <span style="font-size:16px;color:black;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Address (Eng)'))">{{headerFooters.company.addressEnglish}}<br /></span>
                        <span v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Company Phone No (Eng)')) || PrintOption(headerFooters.printOptions.find(x => x.label=='Company Phone No (Ar)'))" style="font-size:15px;color:black;"><span v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Company Phone No (Eng)'))">Mobile No.</span> {{headerFooters.company.phoneNo}}<span v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Company Phone No (Ar)'))">رقم الجوال</span> <br /></span>
                    </div>
                </div>


                <div style="width:100%;text-align:center;margin-top:2rem;">
                    <p style="font-size: 15px; font-weight: bold;color:black;margin-bottom:2px;padding-bottom:2px;">Return Invoice No. <span style="border:4px solid;font-size:25px;font-weight:bold;padding-left:2px;padding-right:2px;color:black;"> {{returnList.registrationNo}}</span> رقم الفاتورة المرتجعة</p>
                    <p style="font-size: 15px; font-weight: bold;color:black;margin-bottom:2px;padding-bottom:2px;">Old Invoice No. <span style="border:4px solid;font-size:25px;font-weight:bold;padding-left:2px;padding-right:2px;color:black;"> {{saleReturnRegNo}}</span> رقم الفاتورة القديمة</p>
                    <barcode :width="1.9" :height="50" v-bind:value="returnList.barCode" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Print Barcode'))"></barcode>

                    <p style="font-size:15px;font-weight:bold;color:black;">Date: <span style="margin-right:20px;margin-left:10px;padding-left:20px;color:black;">{{returnList.date}}</span>  التاريخ</p>


                    <table class="table table-borderless " style="width:400px;">
                        <tr style="font-size:16px;">
                            <td class="" style="border-top: 0;padding-left:1px;padding-right:1px;color:black;"><span style="font-weight:bold;color:black;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Counter Name (Eng)'))">Counter#</span> <span v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Counter Number (Ar)'))">رقم الكاونتر </span> <br>{{counterCode}}</td>
                            <td style="text-align:right; border-top: 0;padding-left:1px;padding-right:1px;color:black;"><span style="font-weight:bold;color:black;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='User Name (Eng)'))">User</span> <span v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='User Name (Ar)'))">أسم المستخدم</span> <br>{{employeeName}}</td>
                        </tr>
                        <tr style="font-size:16px;" v-if="list.cashCustomer != null">
                            <td class="" style="border-top: 0;padding-left:1px;padding-right:1px;color:black;"><span style="font-weight:bold;color:black;">Customer/</span>اسم العميل<br>{{list.cashCustomer}}</td>
                        </tr>
                        <tr style="font-size:18px;" v-else>
                            <td class="" style="border-top: 0;padding-left:1px;padding-right:1px;color:black;"><span style="font-weight:bold;color:black;">Customer/</span>اسم العميل<br>{{ ($i18n.locale == 'en' ||isLeftToRight()) ? (list.customerNameEn != '' && list.customerNameEn != null) ? list.customerNameEn : list.customerNameAr : (list.customerNameAr != '' && list.customerNameAr != null) ? list.customerNameAr : list.customerNameEn}}</td>
                        </tr>
                    </table>



                    <!--<p style="font-size:15px;font-weight:bold;color:black;">
                        Customer :
                        <span style="margin-right:20px;margin-left:10px;padding-left:20px;color:black;" v-if="list.cashCustomer != null">{{list.cashCustomer}}</span>
                        <span style="margin-right:20px;margin-left:10px;padding-left:20px;color:black;" v-else> {{ ($i18n.locale == 'en' ||isLeftToRight()) ? (list.customerNameEn != '' && list.customerNameEn != null) ? list.customerNameEn : list.customerNameAr : (list.customerNameAr != '' && list.customerNameAr != null) ? list.customerNameAr : list.customerNameEn}} </span>
                        اسم العميل
                    </p>-->
                </div>
                <div style="width:100%;">
                    <table class="table report-tbl" style="width:400px;">
                        <tr class="heading" style="font-size:15px;border-bottom:1px solid black;border-top:1px solid; color:black">
                            <th style="text-align: left; width: 5%; border-bottom: 1px solid #000000;border-top: 1px solid #000000; padding-left: 1px; padding-right: 1px; color: black;">رقم </th>
                            <th style="text-align: center; width: 50%; border-bottom: 1px solid #000000;border-top: 1px solid #000000; padding-left: 1px; padding-right: 1px; color: black;">الصنف</th>
                            <th style="text-align: center; width: 10%; border-bottom: 1px solid #000000;border-top: 1px solid #000000; padding-left: 1px; padding-right: 1px; color: black;">الكمية</th>
                            <th style="text-align: right; width: 15%; border-bottom: 1px solid #000000;border-top: 1px solid #000000; padding-left: 1px; padding-right: 1px; color: black;">السعر</th>
                            <th style="text-align: right; width: 20%; border-bottom: 1px solid #000000;border-top: 1px solid #000000; padding-left: 1px; padding-right: 1px; color: black;">مجموع</th>
                        </tr>
                        <tr class="heading" style="font-size:15px;border-bottom:1px solid black;border-top:1px solid; color:black">
                            <th style="text-align: left; width: 5%; border-bottom: 1px solid #000000;border-top: 1px solid #000000; padding-left: 1px; padding-right: 1px; color: black;">No.</th>
                            <th style="text-align: center; width: 50%; border-bottom: 1px solid #000000;border-top: 1px solid #000000; padding-left: 1px; padding-right: 1px; color: black;">Item</th>
                            <th style="text-align: center; width: 10%; border-bottom: 1px solid #000000;border-top: 1px solid #000000; padding-left: 1px; padding-right: 1px; color: black;">Qty</th>
                            <th style="text-align: right; width: 15%; border-bottom: 1px solid #000000;border-top: 1px solid #000000; padding-left: 1px; padding-right: 1px; color: black;">Price</th>
                            <th style="text-align: right; width: 20%; border-bottom: 1px solid #000000;border-top: 1px solid #000000; padding-left: 1px; padding-right: 1px; color: black;">Total</th>
                        </tr>
                        <template v-for="(item, index) in returnList.saleItems">
                            <tr style="font-size:15px;" v-bind:key="item.id">
                                <td style="text-align: left; border-top: 0; padding-top: 0; padding-left: 1px; padding-right: 1px; color: black;">{{index+1}}</td>
                                <td style="text-align: left; border-top: 0; padding-top: 0; padding-left: 1px; padding-right: 1px; color: black; font-weight: 600;">  {{item.arabicName}} <br>{{item.productName }}</td>
                                <td style="text-align: center; border-top: 0; padding-top: 0; padding-left: 1px; padding-right: 1px; color: black;">-{{item.quantity }}</td>
                                <td style="text-align: right; border-top: 0; padding-top: 0; padding-left: 1px; padding-right: 1px; color: black;">{{item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                <td style="text-align: right; border-top: 0; padding-top: 0; padding-left: 1px; padding-right: 1px; color: black;">-{{(item.total).toFixed(3).slice(0,-1)}}</td>
                            </tr>
                        </template>
                        <tr class="heading" style="font-size:16px;">
                            <th style="text-align:center; padding-left:1px;padding-right:1px;color:black;border-bottom: 1px solid #000000;border-top: 1px solid #000000;" colspan="2">T.Items عدد العنف <br /> {{returnList.saleItems.filter(prod => prod.quantity>0).length}}</th>
                            <th style="text-align: center; padding-left: 1px; padding-right: 1px; color: black;border-bottom: 1px solid #000000;border-top: 1px solid #000000;" colspan="2">T.Pieces عدد القطع <br /> {{calulateTotalQtyR}}</th>
                            <th style="text-align: center; padding-left: 1px; padding-right: 1px; color: black;border-bottom: 1px solid #000000;border-top: 1px solid #000000;">G.Total الإجمالي <br /> -{{calulateTotalExclVATR.toFixed(3).slice(0,-1)}}</th>
                        </tr>
                        <tr style="font-size:16px;">
                            <td colspan="3" style="text-align: right; border-bottom: 0; padding-bottom: 1px; padding-left: 1px; padding-right: 0px; color: black;">Total wihout VAT الإجمالي قبل ضريبة:</td>
                            <td colspan="2" style="padding-right:3rem; text-align: right; border-bottom: 0; padding-bottom: 1px; padding-left: 10px; padding-right: 1px; color: black;">-{{(calulateTotalExclVATR - calulateTotalInclusiveVATR).toFixed(3).slice(0,-1)}}</td>
                        </tr>
                        <tr style="font-size:16px;">
                            <td colspan="3" style="text-align: right; padding-left: 1px; padding-right: 0px; border-top: 0; padding-top: 0; border-bottom: 0; padding-bottom: 1px; color: black;">Discount قيمة الخصم:</td>
                            <td colspan="2" style="padding-right: 3rem; text-align: right; padding-left: 10px; padding-right: 1px; border-top: 0; padding-top: 0; border-bottom: 0; padding-bottom: 1px; color: black; ">-{{ calulateDiscountAmountR.toFixed(3).slice(0,-1) }}</td>
                        </tr>
                        <tr style="font-size:16px;">
                            <td style="text-align: right; padding-left: 1px; padding-right: 0px; border-top: 0; padding-top: 0; border-bottom: 0; padding-bottom: 1px; color: black;" colspan="3">Total after Discount الإجمالي بعد الخصم:</td>
                            <td colspan="2" style="padding-right: 3rem; text-align: right; padding-left: 10px; padding-right: 1px; border-top: 0; padding-top: 0; border-bottom: 0; padding-bottom: 1px; color: black; ">-{{ ((calulateTotalExclVATR - calulateTotalInclusiveVATR) - calulateDiscountAmountR).toFixed(3).slice(0,-1) }}</td>
                        </tr>

                        <tr style="font-size:16px;">
                            <td style="text-align: right; padding-left: 1px; padding-right: 0px; border-top: 0; padding-top: 0; padding-bottom: 3px; color: black;" colspan="3">VAT 15% الضريبة:</td>
                            <td colspan="2" style="padding-right: 3rem; text-align: right; padding-left: 10px; padding-right: 1px; border-top: 0; padding-top: 0; padding-bottom: 3px; color: black; ">-{{ calulateTotalVATR.toFixed(3).slice(0,-1) }}</td>
                        </tr>
                        <tr style="font-size: 16px; font-weight: bold; border-bottom: 1px solid #000000" v-if="!isDoublePrint && !returnList.paymentTypes.length>0">
                            <td style="text-align: right; padding-left: 1px; padding-right: 0px; padding-top: 5px; color: black;border-top: 0;" colspan="3">Refund Amount المبلغ المسترد:</td>
                            <td colspan="2" style="padding-right: 3rem; text-align: right; padding-left: 10px; padding-right: 1px; padding-top: 5px; color: black;border-top: 0; ">-{{ (calulateNetTotalR - calulateDiscountAmountR).toFixed(3).slice(0,-1) }}</td>
                        </tr>
                        <tr style="font-size:16px;font-weight:bold;" v-else>
                            <td style="text-align: right; padding-left: 1px; padding-right: 0px; padding-top: 5px; color: black;border-top: 0;" colspan="3">Total with Vat إجمالي مع ضريبةالقيمةالمضافة:</td>
                            <td colspan="2" style="padding-right: 3rem; text-align: right; padding-left: 10px; padding-right: 1px; padding-top: 5px; color: black;border-top: 0; ">-{{ (calulateNetTotalR - calulateDiscountAmountR).toFixed(3).slice(0,-1) }}</td>
                        </tr>

                        <tr style="font-size:19px;font-weight:bold;" v-if="isDoublePrint || returnList.paymentTypes.length>0">
                            <td style="text-align:center; color: black;border-top: 1px solid #000000;" colspan="5">Payment Details بيانات الدفع</td>
                        </tr>
                        <tr style="font-size:17px;" v-if="isDoublePrint || returnList.paymentTypes.length>0">
                            <td style="text-align: left; border-top: 0; padding-top: 0; color: black;" colspan="3">Return Amount المبلغ العائد:</td>
                            <td colspan="2" style="text-align: right; border-top: 0; padding-top: 0; color: black;">-{{ (totalReturnAmount = calulateNetTotalR - calulateDiscountAmountR).toFixed(3).slice(0,-1) }}</td>
                        </tr>
                        <tr style="font-size:17px;" v-if="isDoublePrint">
                            <td style="text-align: left; border-top: 0; padding-top: 0; color: black;" colspan="3">New Invoice ({{list.registrationNo}}) فاتورة جديدة:</td>
                            <td colspan="2" style="text-align: right; border-top: 0; padding-top: 0; color: black;">{{ (totalNewInvoiceAmount = calulateNetTotal - calulateDiscountAmount).toFixed(3).slice(0,-1) }}</td>
                        </tr>

                        <tr style="font-size: 15px; font-weight: bold; border-bottom: 1px solid #000000; border-top: 1px solid #000000 " v-if="isDoublePrint">
                            <td style="text-align: left; border-top:0; padding-top: 0; color: black;" colspan="3" v-if="(totalReturnAmount*(-1) + totalNewInvoiceAmount) > 0">Payable Amount الإجمالي المستحق:</td>
                            <td style="text-align: left; border-top:0; padding-top: 0; color: black;" colspan="3" v-else>Refund Amount المبلغ المسترد:</td>
                            <td colspan="2" style="text-align: right; border-top: 0; padding-top: 0; color: black;">{{ (totalReturnAmount*(-1) + totalNewInvoiceAmount).toFixed(3).slice(0,-1) }}</td>
                        </tr>

                        <tr style="font-size:17px; " v-if="isDoublePrint">
                            <td style="text-align: center; text-transform: capitalize; color: black;border-top: 0;" colspan="5">{{ (totalReturnAmount*(-1) + totalNewInvoiceAmount).toFixed(3).slice(0,-1) | toWords}}</td>
                        </tr>


                        <tr style="font-size:17px;" v-if="!isDoublePrint && returnList.paymentTypes.length>0">
                            <td style="text-align: left; border-top: 0; padding-top: 0; color: black;" colspan="3">{{returnList.paymentTypes[0].name}}):</td>
                            <td colspan="2" style="text-align: right; border-top: 0; padding-top: 0; color: black;">{{ (totalNewInvoiceAmount = totalReturnAmount - (-1)*returnList.paymentTypes[0].amount).toFixed(3).slice(0,-1) }}</td>
                        </tr>

                        <tr style="font-size: 15px; font-weight: bold; border-bottom: 1px solid #000000; border-top: 1px solid #000000 " v-if="!isDoublePrint && returnList.paymentTypes.length>0">
                            <td style="text-align: left; border-top:0; padding-top: 0; color: black;" colspan="3" v-if="(returnList.paymentTypes[0].amount) > 0">Payable Amount الإجمالي المستحق:</td>
                            <td style="text-align: left; border-top:0; padding-top: 0; color: black;" colspan="3" v-else>Refund Amount المبلغ المسترد:</td>
                            <td colspan="2" style="text-align: right; border-top: 0; padding-top: 0; color: black;">{{ (returnList.paymentTypes[0].amount).toFixed(3).slice(0,-1) }}</td>
                        </tr>

                        <tr style="font-size:17px;" v-if="!isDoublePrint && returnList.paymentTypes.length>0 ">
                            <td style="text-align: center; text-transform: capitalize; color: black;border-top: 0;" colspan="5">{{ (returnList.paymentTypes[0].amount).toFixed(3).slice(0,-1) | toWords}}</td>
                        </tr>
                        <tr style="font-size:17px;" v-if="!isDoublePrint && !returnList.paymentTypes.length>0">
                            <td style="text-align: center; text-transform: capitalize; color: black;border-top: 0;" colspan="5">{{ ((calulateNetTotalR - calulateDiscountAmountR)*(-1)).toFixed(3).slice(0,-1)| toWords }}</td>
                        </tr>
                    </table>
                </div>
            </div>


            <div style="width:400px;display:block;margin-left:20px;margin-top:1.5rem">
                <!--<p style="color:transparent;">hidden</p>-->
                <p style="text-align:center;color:black;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Gratitude Message (Ar)')) == true"><span style="margin-right:5px;color:black;">*</span> <span style="margin-right:5px;color:black;">*</span> <span style="margin-right:10px;color:black;">*</span> <span style="font-size:25px; font-weight:bold;color:black;">{{headerFooters.closingLineAr}}</span>  <span style="margin-left: 10px;color:black;">*</span> <span style="margin-left: 5px;color:black;">*</span> <span style="margin-left:5px;color:black;">*</span></p>
                <h6 style="text-align:center;color:black;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Gratitude Message (Eng)')) == true">{{headerFooters.closingLineEn}}</h6>
            </div>
            <div style="width:400px;display:block;margin-left:20px;margin-top:1.5rem;text-align:center;" v-if="PrintOption(headerFooters.printOptions.find(x => x.label=='Print QR'))">
                <vue-qrcode v-bind:value="qrValueReturn" style="width:200px" v-bind:errorCorrectionLevel="correctionLevel" />
            </div>

        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import VueQrcode from 'vue-qrcode'
    import VueBarcode from 'vue-barcode';
    import moment from "moment";
    const options = {
        name: '',
        specs: [
            'fullscreen=no',
            'titlebar=yes',
            'scrollbars=yes'
        ],
        styles: [
            'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
            'https://unpkg.com/kidlat-css/css/kidlat.css'
        ],
        timeout: 700,
        autoClose: true,
        windowTitle: window.document.title
    }
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],        props: ['printDetail', 'headerFooter', 'isTouchScreen', 'isDoublePrint', 'saleId', 'saleReturnRegNo'],
        components: {
            VueQrcode,
            'barcode': VueBarcode,
        },
        data: function () {
            return {
                arabic: '',
                english: '',
                totalReturnAmount: 0,
                totalInvoiceAmount: 0,
                totalNewInvoiceAmount: 0,
                totalReturnInvoiceAmount: 0,
                totalPaidAmount: 0,
                isCalculationShow: false,
                counterCode: "",

                qrValue: "",
                qrValueReturn: "",
                correctionLevel: "H",
                printInterval: '',
                b2b: false,
                b2c: false,
                taxInvoiceLabel: "",
                taxInvoiceLabelAr: '',
                simplifyTaxInvoiceLabel: '',
                simplifyTaxInvoiceLabelAr: '',
                jsonData: '',
                list: {
                    registrationNo: '',
                    date: '',
                    dueDate: '',
                    companyName: '',
                    companyPhoneNo: '',
                    companyAddress: '',
                    discountAmount: '',
                    cashCustomer: '',
                    creditCustomer: '',
                    customerPhoneNo: '',
                    customerAddress: '',
                    paymentMethod: '',
                    paymentMethodNo: '',
                    invocieType: '',
                    saleItems:
                        [

                        ]
                },
                returnList: {
                    registrationNo: '',
                    date: '',
                    dueDate: '',
                    companyName: '',
                    companyPhoneNo: '',
                    companyAddress: '',
                    discountAmount: '',
                    cashCustomer: '',
                    creditCustomer: '',
                    customerPhoneNo: '',
                    customerAddress: '',
                    paymentMethod: '',
                    paymentMethodNo: '',
                    invocieType: '',
                    paymentTypes: [],
                    saleItems:
                        [

                        ],
                    returnList: ''
                },
                render: 0,
                CompanyID: '',
                UserID: '',
                employeeId: '',
                employeeName: '',
                headerFooters: {
                    footerEn: '',
                    footerAr: '',
                    company: ''
                },
                htmlData: {
                    htmlString: ''
                },
                htmlDataReturn: {
                    htmlString: ''
                }
            }
        },
        filters: {
            toWords: function (value) {
                var converter = require('number-to-words');
                if (!value) return ''
                return converter.toWords(value);
            }
        },

        computed: {
            calulateTotalQty: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + (Number((c.quantity) || 0) > 0 ? Number((c.quantity) || 0) : 0) }, 0)
            },
            calulateNetTotal: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + Number((c.total + c.includingVat) || 0) }, 0)
            },
            calulateTotalExclVAT: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + Number((c.total) || 0) }, 0)
            },
            calulateTotalVAT: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + Number((c.includingVat + c.inclusiveVat) || 0) }, 0)
            },
            calulateTotalInclusiveVAT: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + Number((c.inclusiveVat) || 0) }, 0)
            },
            calulateDiscountAmount: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + Number((c.discountAmount) || 0) }, 0)
            },
            calulateBundleAmount: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + Number(c.bundleAmount || 0) }, 0)
            },
            calulateTotalQtyR: function () {
                return this.returnList.saleItems.reduce(function (a, c) { return a + (Number((c.quantity) || 0) > 0 ? Number((c.quantity) || 0) : 0) }, 0)
            },
            calulateNetTotalR: function () {
                return this.returnList.saleItems.reduce(function (a, c) { return a + Number((c.total + c.includingVat) || 0) }, 0)
            },
            calulateTotalExclVATR: function () {

                return this.returnList.saleItems.reduce(function (a, c) { return a + Number((c.total - c.inclusiveVat) || 0) }, 0)
            },
            calulateTotalVATR: function () {
                return this.returnList.saleItems.reduce(function (a, c) { return a + Number((c.includingVat + c.inclusiveVat) || 0) }, 0)
            },
            calulateTotalInclusiveVATR: function () {

                return this.returnList.saleItems.reduce(function (a, c) { return a + Number((c.inclusiveVat) || 0) }, 0)
            },
            calulateDiscountAmountR: function () {
                return this.returnList.saleItems.reduce(function (a, c) { return a + Number((c.discountAmount) || 0) }, 0)
            },
            calulateBundleAmountR: function () {
                return this.returnList.saleItems.reduce(function (a, c) { return a + Number(c.bundleAmount || 0) }, 0)
            }
        },
        methods: {
            getdate: function (x) {
                return moment(x).format("lll");
            },
            PrintOption: function (x) {                
                if (x == undefined || !x.value) {

                    return false;
                }
                return true;
            },
            calulateDiscountAmount1: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + Number((c.discountAmount) || 0) }, 0)
            },
            calulateBundleAmount1: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + Number(c.bundleAmount || 0) }, 0)
            },
            calulateNetTotalWithVAT: function () {
                var total = this.list.saleItems.reduce(function (a, c) { return a + Number((c.total + c.includingVat) || 0) }, 0);
                var grandTotal = parseFloat(total) - (this.calulateDiscountAmount1() + this.calulateBundleAmount1())
                return (parseFloat(grandTotal).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,"));
            },
            calulateTotalVATofInvoice: function () {
                var total = this.list.saleItems.reduce(function (a, c) { return a + Number((c.includingVat + c.inclusiveVat) || 0) }, 0);
                return (parseFloat(total).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,"));
            },
            calulateNetTotalWithVATReturn: function () {
                var total = this.returnList.saleItems.reduce(function (a, c) { return a + Number((c.total + c.includingVat) || 0) }, 0);
                return (parseFloat(total).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,"));
            },
            calulateTotalVATofInvoiceReturn: function () {
                var total = this.returnList.saleItems.reduce(function (a, c) { return a + Number((c.includingVat + c.inclusiveVat) || 0) }, 0);
                return (parseFloat(total).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,"));
            },
            printInvoice: function () {
                var root = this;
                // this.$htmlToPaper('purchaseInvoice');
                this.htmlData.htmlString = this.$refs.mychildcomponent.innerHTML;
                //  var html1 = this.$refs.mychildcomponent.innerHTML;
                //  var data = { html: html1 }
                //
                var printerName = localStorage.getItem('PrinterName')
                var form = new FormData();
                form.append('htmlString', this.htmlData.htmlString);
                form.append('PrintType', 'invoice');
                form.append('PrinterName', printerName);
                //this.$htmlToPaper('purchaseInvoice');
                //axios.post('http://localhos:7013/print/from-pdf', form);
                //axios.post('http://127.0.0.1:7013/print/from-pdf', form);
                //alert();
                //var root = this;
                var isBlindPrint = localStorage.getItem("IsBlindPrint")

                if (isBlindPrint === 'true') {
                    axios.post('http://127.0.0.1:7014/print/from-pdf', form).then(function (x) {
                        console.log(x);

                    });
                    //if (root.isTouchScreen === true) {
                    //    root.$router.go('/TouchInvoice')
                    //}
                }
                else {
                    this.$htmlToPaper('purchaseInvoice', options, () => {
                        if (root.isTouchScreen === true) {
                            root.$router.go('/TouchInvoice')
                        }
                    });

                }


                //var token = '';
                //if (this.$session.exists()) {
                //    token = localStorage.getItem('token');
                //}
                //root.loading = true;
                //root.$https.post('/EmployeeRegistration/PrintPos', data, { headers: { "Authorization": `Bearer ${token}` } }).then(function (x) {
                //    alert(x.data)
                //});



            },
            printReturnInvoice: function () {
                var root = this;

                // this.$htmlToPaper('purchaseInvoice');
                this.htmlDataReturn.htmlString = this.$refs.myReturnComponent.innerHTML;
                //  var html1 = this.$refs.mychildcomponent.innerHTML;
                //  var data = { html: html1 }
                //
                var printerName = localStorage.getItem('PrinterName')
                var form = new FormData();
                form.append('htmlString', this.htmlDataReturn.htmlString);
                form.append('PrintType', 'invoice');
                form.append('PrinterName', printerName);
                //this.$htmlToPaper('purchaseInvoice');
                //axios.post('http://localhos:7013/print/from-pdf', form);
                //axios.post('http://127.0.0.1:7013/print/from-pdf', form);
                //alert();
                //var root = this;

                var isBlindPrint = localStorage.getItem("IsBlindPrint")

                if (!isBlindPrint === 'true') {
                    axios.post('http://127.0.0.1:7014/print/from-pdf', form).then(function (x) {
                        console.log(x);
                        if (root.isDoublePrint) {
                            root.printInvoice()


                        }
                    });
                    //if (root.isTouchScreen === true) {
                    //    root.$router.go('/TouchInvoice')
                    //}
                }
                else {
                    this.$htmlToPaper('saleReturn', options, () => {
                        if (root.isDoublePrint) {
                            root.printInvoice()


                        }
                        if (root.isTouchScreen === true) {
                            root.$router.go('/TouchInvoice')
                        }
                    });

                }


                //var token = '';
                //if (this.$session.exists()) {
                //    token = localStorage.getItem('token');
                //}
                //root.loading = true;
                //root.$https.post('/EmployeeRegistration/PrintPos', data, { headers: { "Authorization": `Bearer ${token}` } }).then(function (x) {
                //    alert(x.data)
                //});



            },
            EmployeeData: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/EmployeeRegistration/EmployeeDetail?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {
                            root.employeeName = response.data.englishName;
                        }
                    },
                        function (error) {
                            console.log(error);
                        });
            },
            saleReturnData: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https.get("/Sale/SaleDetail?id=" + root.saleId, { headers: { Authorization: `Bearer ${token}` }, })
                    .then(function (response) {
                        if (response.data != null) {
                            debugger
                            root.list = response.data;

                            //root.list.date = moment(root.list.date).format('DD/MM/YYYY hh:mm:ss A');
                            var sellerNameBuff = root.GetTLVForValue('1', root.headerFooters.company.nameEnglish)
                            var vatRegistrationNoBuff = root.GetTLVForValue('2', root.headerFooters.company.vatRegistrationNo)
                            var timeStampBuff = root.GetTLVForValue('3', root.list.date)
                            var totalWithVat = root.GetTLVForValue('4', root.calulateNetTotalWithVATReturn())
                            var totalVat = root.GetTLVForValue('5', root.calulateTotalVATofInvoiceReturn())
                            var tagArray = [sellerNameBuff, vatRegistrationNoBuff, timeStampBuff, totalWithVat, totalVat]
                            var qrCodeBuff = Buffer.concat(tagArray)
                            root.qrValue = qrCodeBuff.toString('base64')
                            if (root.isDoublePrint) {
                                if (root.list.paymentTypes[root.list.paymentTypes.length - 1].name.includes("SIR")) {
                                    root.totalPaidAmount = root.list.paymentTypes[root.list.paymentTypes.length - 1].amount
                                    root.isCalculationShow = true
                                }
                            }
                            root.PrintData();
                        }
                    });
            },
            PrintData: function () {
                var root = this;

                if (!this.printDetail.registrationNo.includes("SIR")) {
                    if (this.printDetail.saleItems.length > 0) {
                        this.list = this.printDetail;
                        if (root.list.paymentTypes.length > 0) {
                            if (root.list.paymentTypes[root.list.paymentTypes.length - 1].name.includes("SIR")) {
                                root.totalPaidAmount = root.list.paymentTypes[root.list.paymentTypes.length - 1].amount
                                root.isCalculationShow = true
                            }
                        }
                        //this.list.date = moment(root.list.date).format('DD/MM/YYYY hh:mm:ss A');
                        if (root.$session.exists()) {


                            root.EmployeeData(root.employeeId);
                            var sellerNameBuff = root.GetTLVForValue('1', this.headerFooters.company.nameEnglish)
                            var vatRegistrationNoBuff = root.GetTLVForValue('2', this.headerFooters.company.vatRegistrationNo)
                            var timeStampBuff = root.GetTLVForValue('3', this.list.date)
                            var totalWithVat = root.GetTLVForValue('4', this.calulateNetTotalWithVAT())
                            var totalVat = root.GetTLVForValue('5', this.calulateTotalVATofInvoice())
                            var tagArray = [sellerNameBuff, vatRegistrationNoBuff, timeStampBuff, totalWithVat, totalVat]
                            var qrCodeBuff = Buffer.concat(tagArray)
                            root.qrValue = qrCodeBuff.toString('base64')


                        }
                        setTimeout(function () {
                            root.printInvoice();

                        }, 125)

                    }
                }
                else {
                    if (root.printDetail.saleItems.length > 0) {
                        root.returnList = root.printDetail;
                        //root.returnList.date = moment(root.returnList.date).format('DD/MM/YYYY hh:mm:ss A');
                        if (root.$session.exists()) {
                            root.EmployeeData(root.employeeId);
                            var sellerNameBuffR = root.GetTLVForValue('1', root.headerFooters.company.nameEnglish)
                            var vatRegistrationNoBuffR = root.GetTLVForValue('2', root.headerFooters.company.vatRegistrationNo)
                            var timeStampBuffR = root.GetTLVForValue('3', root.returnList.date)
                            var totalWithVatR = root.GetTLVForValue('4', root.calulateNetTotalWithVATReturn())
                            var totalVatR = root.GetTLVForValue('5', root.calulateTotalVATofInvoiceReturn())
                            var tagArrayR = [sellerNameBuffR, vatRegistrationNoBuffR, timeStampBuffR, totalWithVatR, totalVatR]
                            var qrCodeBuffR = Buffer.concat(tagArrayR)
                            root.qrValueReturn = qrCodeBuffR.toString('base64')
                            setTimeout(function () {
                                root.printReturnInvoice();

                            }, 125)


                        }
                    }
                }
            },
            GetTLVForValue: function (tagNumber, tagValue) {
                var tagBuf = Buffer.from([tagNumber], 'utf-8')
                var tagValueLenBuf = Buffer.from([tagValue.length], 'utf-8')
                var tagValueBuf = Buffer.from(tagValue, 'utf-8')
                var bufsArray = [tagBuf, tagValueLenBuf, tagValueBuf]
                return Buffer.concat(bufsArray)
            }

        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            var root = this;

            root.isCalculationShow = false
            root.counterCode = localStorage.getItem('CounterCode');
            root.CompanyID = localStorage.getItem('CompanyID');

            root.UserID = localStorage.getItem('UserID');
            root.employeeId = localStorage.getItem('EmployeeId');
            this.invoicePrint = localStorage.getItem('InvoicePrint');
            root.b2b = localStorage.getItem('b2b') == 'true' ? true : false;
            root.b2c = localStorage.getItem('b2c') == 'true' ? true : false;
            root.taxInvoiceLabel = localStorage.getItem('taxInvoiceLabel');
            this.taxInvoiceLabelAr = localStorage.getItem('taxInvoiceLabelAr');
            this.simplifyTaxInvoiceLabel = localStorage.getItem('simplifyTaxInvoiceLabel');
            this.simplifyTaxInvoiceLabelAr = localStorage.getItem('simplifyTaxInvoiceLabelAr');
                        
            this.headerFooters = this.headerFooter;
            
            if (this.isDoublePrint) {
                root.saleReturnData();

            }
            else {
                root.PrintData()
            }





        },

    }
</script>

