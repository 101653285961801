<template>
    <div class="row" v-if="isValid('CanBackUpData')">
        <div class="col-lg-6 col-sm-12 ml-auto mr-auto">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title text-center DayHeading"> Backup Database</h4>
                    <div class="row">
                        <div class="col-md-12 col-lg-12 pb-3">
                            <div class="col-sm-12" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <label><span>Backup Path *:</span></label>
                                <div class="form-group">
                                    <input class="form-control" v-model="path" type="text" placeholder="Example: C:\FolderName" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" />
                                </div>
                            </div>
                            <div class="form-group text-center">
                                <a href="javascript:void(0)" class="btn btn-primary  " v-on:click="backupData" :disabled="loading1 || path == ''"> Backup Database</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <loading :name="loading1" :active.sync="loading1"
                 :can-cancel="true"
                 :is-full-page="true"></loading>

    </div>

    <div v-else>  <acessdenied></acessdenied></div>
</template>

<script>
    import 'vue-loading-overlay/dist/vue-loading.css';
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        data: function () {
            return {
                loading1: false,
                fileName:'Choose file',
                path: '',
                oldPath: ''
            }
        },
        methods: {

                    getBackUpPath: function() {
                        var root = this;
                        this.loading1= true;
                        var token = '';
                        if (root.$session.exists()) {
                            token = localStorage.getItem('token');
                        }

                        root.loading1 = true;

                        root.$https
                    .get('/System/GetBackUpPath', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                         if (response.status == 200) {
                             root.path = response.data;
                             root.oldPath = root.path;
                        root.loading1 = false;

                         }
                    });
            },

            backupData: function () {
                        var root = this;
                        this.loading1= true;
                        var token = '';
                        if (root.$session.exists()) {
                            token = localStorage.getItem('token');
                        }

                        root.loading1 = true;
                        var isNewPath = false;
                        if(this.path != this.oldPath){
                            isNewPath = true;
                        }
                        root.$https
                    .get('/System/BackUp?path=' + this.path + '&isNewPath=' + isNewPath, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                         if (response.status == 200) {
                                    root.$swal({
                                                title: "Success!",
                                                text: "Backup save successfully",
                                                type: 'success',
                                                confirmButtonClass: "btn btn-Success",
                                                buttonStyling: false,
                                                icon: 'success'

                                            });
                                        }else{
                                               root.$swal({
                                                title: "Error!",
                                                text: "Backup save successfully",
                                                type: 'error',
                                                confirmButtonClass: "btn btn-Success",
                                                buttonStyling: false,
                                                icon: 'error'

                                            });
                                        }

                                        root.loading1 = false;
                    },(error)=> {
                                        root.loading1 = false;

                                         root.$swal({
                                                title: "Error!",
                                                text: error,
                                                type: 'error',
                                                confirmButtonClass: "btn btn-Success",
                                                buttonStyling: false,
                                                icon: 'error'

                                            });
                    });
            },

        },
        created: function () {
            this.$emit('input', this.$route.name);
        },
        mounted:function(){
        this.getBackUpPath();
        }

    }
</script>