<template>
    <modal :show="show" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-if=" isValid('CanAddDenomination') || isValid('CanEditDenomination') " >

        <div style="margin-bottom:0px" class="card">
            <div class="card-body">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="modal-header" v-if="type=='Edit'">

                            <h5 class="modal-title DayHeading" id="myModalLabel"> {{ $t('AddDenominationSetup.UpdateDenominationSetup') }}</h5>

                        </div>
                        <div class="modal-header" v-else>

                            <h5 class="modal-title DayHeading" id="myModalLabel"> {{ $t('AddDenominationSetup.AddDenominationSetup') }} </h5>

                        </div>
                        <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                            <div class="card-body ">
                                <div class="row ">
                                    <div class="col-sm-12" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                        <label>{{ $t('AddDenominationSetup.DenominationNumber')}} :<span class="LabelColour"> *</span></label>
                                        <div v-bind:class="{'has-danger' : $v.denominationSetup.number.$error}">
                                            <input class="form-control" type="number" v-model="$v.denominationSetup.number.$model" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" />
                                            <span v-if="$v.denominationSetup.number.$error" class="error text-danger">
                                                <span v-if="!$v.denominationSetup.number.required">{{ $t('AddDenominationSetup.Name') }}</span>
                                                <span v-if="!$v.denominationSetup.number.maxLength">{{ $t('AddDenominationSetup.NameLength') }}</span>
                                            </span>
                                        </div>
                                    </div>

                                    <div class="col-sm-2">
                                        <label style="margin: 7px;">{{ $t('AddDenominationSetup.Active') }}</label> <br />
                                        <div class="bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-animate" v-bind:class="{'bootstrap-switch-on': denominationSetup.isActive, 'bootstrap-switch-off': !denominationSetup.isActive}" v-on:click="denominationSetup.isActive = !denominationSetup.isActive" style="width: 72px;">
                                            <div class="bootstrap-switch-container" style="width: 122px; margin-left: 0px;">
                                                <span class="bootstrap-switch-handle-on bootstrap-switch-success" style="width: 50px;">
                                                    <i class="nc-icon nc-check-2"></i>
                                                </span>
                                                <span class="bootstrap-switch-label" style="width: 30px;">&nbsp;</span>
                                                <span class="bootstrap-switch-handle-off bootstrap-switch-success" style="width: 50px;">
                                                    <i class="nc-icon nc-simple-remove"></i>
                                                </span>
                                                <input class="bootstrap-switch" type="checkbox" data-toggle="switch" checked="" data-on-label="<i class='nc-icon nc-check-2'></i>" data-off-label="<i class='nc-icon nc-simple-remove'></i>" data-on-color="success" data-off-color="success">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer justify-content-right" v-if="type=='Edit' && isValid('CanEditDenomination')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">

                            <button type="button" class="btn btn-primary  " v-on:click="SaveDenominationSetup" :disabled="$v.denominationSetup.$invalid"><i class="far fa-save"></i> {{ $t('AddDenominationSetup.btnUpdate') }}</button>
                            <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{ $t('AddDenominationSetup.btnClear') }}</button>

                        </div>
                        <div class="modal-footer justify-content-right" v-if="type!='Edit' && isValid('CanAddDenomination')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">

                            <button type="button" class="btn btn-primary  " v-on:click="SaveDenominationSetup" :disabled="$v.denominationSetup.$invalid"><i class="far fa-save"></i> {{ $t('AddDenominationSetup.btnSave') }}</button>
                            <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{ $t('AddDenominationSetup.btnClear') }}</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </modal>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import { maxLength, required } from "vuelidate/lib/validators";
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default ({
        mixins: [clickMixin],
        props: ['show', 'denominationSetup', 'type'],
        data: function () {
            return {
                render: 0,
                arabic: '',
                english: '',

            }
        },
        validations: {
            denominationSetup:
            {
                number:
                {
                    required,
                    maxLength: maxLength(50)
                },
            }
        },
        methods: {

            close: function () {
                this.$emit('close');
            },
            SaveDenominationSetup: function () {
                var root = this;

                var url = '/Product/SaveDenominationSetup';
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https
                    .post(url, root.denominationSetup, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data.isSuccess == true) {


                            if (root.type != "Edit") {
                                root.$store.state.denominationSetupList.push({
                                    id: response.data.denominationSetup.id,
                                    number: response.data.denominationSetup.number,
                                    isActive: response.data.denominationSetup.isActive
                                })
                                root.$swal({
                                    text: root.$t('AddDenominationSetup.Saved'),
                                    title: root.$t('AddDenominationSetup.SavedSuccessfully'),
                                    type: 'success',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                    timerProgressBar: true,
                                });
                                root.close();

                            }
                            else {
                                var data = root.$store.state.denominationSetupList.find(function (x) {
                                    return x.id == response.data.denominationSetup.id;
                                });
                                data.id = response.data.denominationSetup.id;
                                data.number = response.data.denominationSetup.number;
                                data.isActive = response.data.denominationSetup.isActive;
                                root.$swal({
                                    title: root.$t('AddDenominationSetup.Updated'),
                                    text: root.$t('AddDenominationSetup.UpdateSuccessfully'),
                                    type: 'success',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                    timerProgressBar: true,
                                });
                                root.close();
                            }
                        }
                        else {
                            root.$swal({
                                title: root.$t('AddDenominationSetup.Error'),
                                text: root.$t('AddDenominationSetup.NameAlreadyExist'),
                                type: 'error',
                                icon: 'error',
                                showConfirmButton: false,
                                timer: 800,
                                timerProgressBar: true,
                            });
                        }
                    });
            }
        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            if (this.$route.query.data != undefined) {
                this.denominationSetup = this.$route.query.data;
            }
        }
    })

</script>