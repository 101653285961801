<template>
    <div class="row">
        <div class="col-xs-12 col-sm-9 col-md-9 col-lg-9">
            <div class="card">
                <div class="card-body">
                    <div class="row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                            <h5 class="view_page_title"> {{ $t('PurchaseBillView.Bills') }}</h5>
                        </div>
                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">

                            <a href="javascript:void(0)" class="btn btn-outline-danger" @click="BackToList">  <i class="fas fa-arrow-circle-left fa-lg"></i> </a>


                        </div>
                    </div>



                    <div class="row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'arabicLanguage'">
                        <div class="col-lg-12">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <label class="font-weight-bold">{{ $t('PurchaseBillView.Reference') }}</label>
                                <p>{{purchaseBill.reference}}</p>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <label class="font-weight-bold">{{ $t('PurchaseBillView.Narration') }}</label>
                                <div class="card" style="border: 1px #dddddd solid;">
                                    <div class="card-body">
                                        <label>{{purchaseBill.narration}}</label>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-12">

                            <PurchaseBillItem :isDisable="'true'" v-on:updatedailyExpenseRows="getupdatedailyExpenseRows"></PurchaseBillItem>

                        </div>
                        <div class="col-md-6 ">
                            <button class="btn btn-primary mr-2" v-on:click="Attachment()" v-if="isValid('CanUploadExpenseBillAttachment')">
                                {{ $t('PurchaseView.Attachment') }}
                            </button>
                        </div>
                        <div class="col-md-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                            <button class="btn btn-danger  mr-2"
                                    v-on:click="BackToList">
                                {{ $t('PurchaseOrderView.Cancel') }}
                            </button>
                        </div>

                        <!--<div class="col-md-12" v-if="isValid('CanUploadExpenseBillAttachment')">
                            <div class="accordion" role="tablist">
                                <b-card no-body class="mb-1">
                                    <b-card-header header-tag="header" class="p-1" role="tab">
                                        <b-button block v-b-toggle.accordion-1 variant="primary">{{ $t('PurchaseBillView.Attachment') }}</b-button>
                                    </b-card-header>
                                    <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                                        <b-card-body>
                                            <import-attachment :purchase="purchaseBill" :show="attachments" @close="attachments = false" v-if="attachments" @billAttachments="billAttachments" :document="'Bills'" />
                                            <div>
                                                <div class="row">
                                                    <div class="col-md-12 text-right">
                                                        <a href="javascript:void(0)" class="btn btn-outline-primary " v-on:click="attachments=true" v-if="purchaseBill.billAttachments.length==0"> Upload</a>
                                                    </div>
                                                </div>
                                                <div class=" table-responsive">
                                                    <table class="table ">
                                                        <thead class="m-0">
                                                            <tr>
                                                                <th>#</th>
                                                                <th>{{ $t('PurchaseBillView.Date') }} </th>
                                                                <th>{{ $t('PurchaseBillView.Description') }} </th>
                                                                <th>{{ $t('PurchaseBillView.Attachment') }}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(contact,index) in purchaseBill.billAttachments" v-bind:key="index">
                                                                <td>
                                                                    {{index+1}}
                                                                </td>
                                                                <th>{{getDate(contact.date)}}</th>
                                                                <th>{{contact.description}}</th>

                                                                <td>
                                                                    <button class="btn btn-primary  btn-icon mr-2"
                                                                            v-if="contact.path != ''"
                                                                            v-on:click="DownloadAttachment(contact.path)">
                                                                        <i class="fa fa-download"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>

                            </div>

                        </div>-->

                    </div>

                </div>
            </div>
        </div>
        <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <h5 class="view_page_title">{{ $t('PurchaseBillView.BasicInfo') }}</h5>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <label class="invoice_lbl">{{ $t('PurchaseBillView.BillNo') }}#</label>
                            <hr style="margin-top: 0.3rem; margin-bottom: 0.1rem;" />
                            <label>{{purchaseBill.registrationNo}}</label>
                            <hr style="margin-top: 0.1rem;" />
                        </div>



                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            <label class="invoice_lbl">{{ $t('PurchaseBillView.Date') }}</label>
                            <hr style="margin-bottom: 0.1rem; margin-top: 0.3rem;" />
                            <label>{{purchaseBill.date}}</label>
                            <hr style="margin-top: 0.1rem;" />
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            <label class="invoice_lbl">{{ $t('PurchaseBillView.DueDate') }}</label>
                            <hr style="margin-bottom: 0.1rem; margin-top: 0.3rem;" />
                            <label>{{purchaseBill.dueDate}}</label>
                            <hr style="margin-top: 0.1rem;" />
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            <label class="invoice_lbl">{{ $t('PurchaseBillView.Account') }}</label>
                            <hr style="margin-bottom: 0.1rem; margin-top: 0.3rem;" />
                            <label>{{purchaseBill.billerAccount}}</label>
                            <hr style="margin-top: 0.1rem;" />
                        </div>


                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 mt-2">
                            <button class="btn btn-light btn-block text-left">PDF <i class="fas fa-file-pdf float-right" style="color:#EB5757;"></i></button>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 mt-2">
                            <button class="btn btn-light btn-block text-left">Sheets <i class="fas fa-file-excel float-right" style="color:#198754;"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <bulk-attachment :documentid="purchaseBill.id" :show="isAttachshow" v-if="isAttachshow" @close="attachmentSaved" />

            <!--<div class="card" style="background-color:#EBF2FF;margin-bottom:0;">
        <div class="card-body">

            <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 mb-2">
                    <label>{{ $t('Sale.Customer') }} :</label>
                    <div v-if="!sale.isCredit">{{($i18n.locale == 'en' ||isLeftToRight())?sale.cashCustomer:(sale.cashCustomer==''?sale.cashCustomer:sale.cashCustomer)}}</div> <div>{{($i18n.locale == 'en' ||isLeftToRight())?sale.customerNameEn:(sale.customerNameAr==''?sale.customerNameEn:sale.customerNameAr)}}</div>
                </div>
            </div>
        </div>
    </div>-->

        </div>

    </div>

</template>
<style scoped>
    .bg_color {
        background-color: #e5e5e5;
    }

    .vue-radio-button {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    .icon {
        object-fit: contain;
    }

    .v-radio-button-active {
        background-color: #b6d7ff73 !important;
    }

    .title {
        font-size: 11px;
        margin-left: -10px;
    }
</style>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import moment from 'moment';
    //import Multiselect from 'vue-multiselect'

    import { required, maxLength } from 'vuelidate/lib/validators';
    export default ({
        mixins: [clickMixin],

        data: function () {
            return {
                purchaseBillItems: [],
                render: 0,
                isAttachshow: false,
                attachment: false,
                attachments: false,
                language: 'Nothing',
                options: [],
                purchaseBill: {
                    id: '00000000-0000-0000-0000-000000000000',
                    registrationNo: '',
                    date: '',
                    dueDate: '',
                    taxMethod: '',
                    reference: '',
                    billerId: '',
                    purchaseBillItems: [],
                    billAttachments: [],
                },

            }
        },
        validations: {
            purchaseBill:
            {
                registrationNo:
                {
                    required,
                    maxLength: maxLength(30)
                },
                date:
                {
                    required,
                },
                dueDate:
                {
                    required,
                },
                narration:
                {
                },
                purchaseBillItems:
                {
                    required,

                },


            }
        },
        methods: {
            Attachment: function () {
                this.isAttachshow = true;
            },

            attachmentSaved: function () {
                this.isAttachshow = false;
            },

            DownloadAttachment(path) {

                var root = this;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                var ext = path.split('.')[1];
                root.$https.get('/Contact/DownloadFile?filePath=' + path, { headers: { "Authorization": `Bearer ${token}` }, responseType: 'blob' })
                    .then(function (response) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'file.' + ext);
                        document.body.appendChild(link);
                        link.click();
                    });
            },
            getDate: function (date) {


                return moment(date).format('LLL');

            },

            billAttachments: function (x) {

                var root = this;
                if (x != undefined && x != null && x != '') {
                    this.purchaseBill.billAttachments.push({
                        path: x.path,
                        date: x.date,
                        description: x.description
                    })
                }
                this.attachments = false;
                var url = '/Purchase/SavePurchaseBillInformation';
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }


                root.$https
                    .post(url, root.purchaseBill, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {
                        root.info = response.data.bpi
                        this.$swal.fire({
                            title: root.$t('PurchaseBillView.SavedSuccessfully'),
                            text: root.$t('PurchaseBillView.Saved'),
                            type: 'success',
                            confirmButtonClass: "btn btn-success",
                            buttonStyling: false,
                            icon: 'success',
                            timer: 1500,
                            timerProgressBar: true,

                        });
                    })
                    .catch(error => {
                        console.log(error)
                        this.$swal.fire(
                            {
                                icon: 'error',
                                title: this.$t('PurchaseBillView.Error'),
                                text: this.$t('PurchaseBillView.Error'),
                            });
                        root.errored = true
                    })
                    .finally(() => root.loading = false)
            },
            languageChange: function (lan) {
                if (this.language == lan) {
                    if (this.purchaseBill.id == '00000000-0000-0000-0000-000000000000') {

                        var getLocale = this.$i18n.locale;
                        this.language = getLocale;

                        this.$router.go('/adddailyexpense');
                    }
                    else {

                        this.$swal({
                            title: this.$t('PurchaseBillView.Error'),
                            text: this.$t('PurchaseBillView.ChangeLanguageError'),
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            icon: 'error',
                            timer: 4000,
                            timerProgressBar: true,
                        });
                    }
                }


            },
            getupdatedailyExpenseRows: function (items) {
                this.purchaseBillItems = items;
                this.purchaseBill.purchaseBillItems = items;
            },
            AutoIncrementVoucherNo: function () {
                var root = this;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https
                    .get('/Purchase/PurchaseBillAutoGenerateNo', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                        if (response.data != null) {
                            root.purchaseBill.registrationNo = response.data;
                        }
                    });
            },
            BackToList: function () {
                this.$router.push({
                    path: '/PurchaseBill',
                    query: {
                        data: 'PurchaseBills'
                    }
                })
            },
            SaveDailyExpenseInformation: function (value) {

                var root = this;
                this.purchaseBill.approvalStatus = value
                var url = '/Purchase/SavePurchaseBillInformation';
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                var prd = root.purchaseBill.purchaseBillItems.findIndex(x => x.amount == 0);
                if (prd >= 0) {
                    root.purchaseBill.purchaseBillItems.splice(prd, 1)
                }
                root.purchaseBill.date = root.purchaseBill.date + " " + moment().format("hh:mm A");
                root.purchaseBill.dueDate = root.purchaseBill.dueDate + " " + moment().format("hh:mm A");

                root.$https
                    .post(url, root.purchaseBill, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {
                        root.info = response.data.bpi
                        this.$swal.fire({
                            title: root.$t('PurchaseBillView.SavedSuccessfully'),
                            text: root.$t('PurchaseBillView.Saved'),
                            type: 'success',
                            confirmButtonClass: "btn btn-success",
                            buttonStyling: false,
                            icon: 'success',
                            timer: 1500,
                            timerProgressBar: true,

                        });
                        this.$router.push('/PurchaseBill')
                    })
                    .catch(error => {
                        console.log(error)
                        this.$swal.fire(
                            {
                                icon: 'error',
                                title: this.$t('PurchaseBillView.Error'),
                                text: this.$t('PurchaseBillView.Error'),
                            });
                        root.errored = true
                    })
                    .finally(() => root.loading = false)
            },

        },
        created: function () {

            this.language = this.$i18n.locale;

            if (this.$route.query.data != undefined) {

                this.purchaseBill = this.$route.query.data;
                this.purchaseBill.date = moment(this.purchaseBill.date).format('llll');
                this.purchaseBill.dueDate = moment(this.purchaseBill.dueDate).format('llll');

            }


            this.$emit('input', this.$route.name);
        },

        mounted: function () {


            if (this.$route.query.data == undefined) {
                this.AutoIncrementVoucherNo();
                this.purchaseBill.date = moment().format('llll');
                this.render++;
            }



        }
    })

</script>