<template>
    <div class="row" v-if="isValid('CanViewAutoTemplate')">
        <div class="col-lg-12 col-sm-12 ml-auto mr-auto">
            <div class="row mb-4">
                <div class="col-lg-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                    <h5 class="page_title">{{ $t('AutoPurchaseTemplate.AutoPurchaseTemplate') }}</h5>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link :to="'/StartScreen'"><a href="javascript:void(0)"> {{ $t('AutoPurchaseTemplate.Home') }}</a></router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">{{ $t('AutoPurchaseTemplate.AutoPurchaseTemplate') }}</li>
                        </ol>
                    </nav>
                </div>
                <div class="col-lg-6 ">
                    <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'" class="col-md-12">
                        <a href="javascript:void(0)" class="btn btn-outline-primary "  v-if="isValid('CanAddAutoTemplate')" v-on:click="AddPurchaseOrder"><i class="fa fa-plus"></i> {{ $t('AutoPurchaseTemplate.AddNew') }}</a>
                        <router-link :to="'/StartScreen'"><a href="javascript:void(0)" class="btn btn-outline-danger "> {{ $t('AutoPurchaseTemplate.Close') }}</a></router-link>
                    </div>
                </div>
            </div>
            <div class="card ">
                <div class="card-header">
                    <div class="row mb-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label>{{ $t('AutoPurchaseTemplate.SearchByNameAndCode')}}</label>
                                <div>
                                    <input type="text" class="form-control search_input" v-model="search" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" name="search" id="search" :placeholder="$t('AutoPurchaseTemplate.Search')" />
                                    <span class="fas fa-search search_icon"></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                            <div class="form-group">
                                <label>{{ $t('AutoPurchaseTemplate.FromDate') }}</label>
                                <datepicker v-model="fromDate" />
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                            <div class="form-group">
                                <label>{{ $t('AutoPurchaseTemplate.ToDate') }}</label>
                                <datepicker v-model="toDate" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">

                            <div>
                                <div class="table-responsive">
                                    <table class="table table-hover table-striped table_list_bg" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                        <thead class="">
                                            <tr>
                                                <th>#</th>
                                                <th v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                    {{ $t('AutoPurchaseTemplate.AutoPurchaseTemplateNo') }}
                                                </th>
                                                <th v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                    {{ $t('AutoPurchaseTemplate.CreatedDate') }}
                                                </th>
                                                <th v-if="english=='true'" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                    {{ $t('AutoPurchaseTemplate.SupplierName') }}
                                                </th>
                                                <th v-if="isOtherLang()" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                    {{ $t('AutoPurchaseTemplate.SupplierNameAr') }}
                                                </th>
                                                <th class="text-center">
                                                    {{ $t('AutoPurchaseTemplate.ReOrderDay') }}
                                                </th>
                                                <th v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                    {{ $t('AutoPurchaseTemplate.NetAmount') }}
                                                </th>
                                                <th>
                                                    {{ $t('AutoPurchaseTemplate.Status') }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(purchaseOrder,index) in purchaseOrderList" v-bind:key="purchaseOrder.id">
                                                <td v-if="currentPage === 1">
                                                    {{index+1}}
                                                </td>
                                                <td v-else>
                                                    {{((currentPage*10)-10) +(index+1)}}
                                                </td>
                                                <td v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-if="isValid('CanEditAutoTemplate')">
                                                    <strong>
                                                        <a href="javascript:void(0)" v-on:click="EditPurchaseOrder(purchaseOrder.id)">{{purchaseOrder.registrationNumber}}</a>
                                                    </strong>
                                                </td>
                                                <td v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-else>
                                                    {{purchaseOrder.registrationNumber}}
                                                </td>
                                                <td v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                    {{purchaseOrder.date}}
                                                </td>
                                                <td v-if="english=='true'" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                    {{purchaseOrder.supplierName}}
                                                </td>
                                                <td v-if="isOtherLang()" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                    {{purchaseOrder.supplierNameArabic}}
                                                </td>
                                                <td class="text-center">
                                                    {{purchaseOrder.day}}
                                                </td>
                                                <td v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                    {{currency}} {{parseFloat(purchaseOrder.netAmount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                                </td>
                                                <td>
                                                    <span class="small badge badge-success" v-if="!purchaseOrder.isActive">{{ $t('Close') }}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>

                                <div class="float-left">
                                    <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                                    <span v-else-if="currentPage===1 && rowCount < 10">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                                    <span v-else-if="currentPage===1 && rowCount >= 11  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                    <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                    <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                    <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                </div>
                                <div class="float-right">
                                    <div class="overflow-auto" v-on:click="getPage()" v-if="($i18n.locale == 'en' ||isLeftToRight())">
                                        <b-pagination pills size="lg" v-model="currentPage"
                                                      :total-rows="rowCount"
                                                      :key="randerList"
                                                      :per-page="10"
                                                      first-text="First"
                                                      prev-text="Previous"
                                                      next-text="Next"
                                                      last-text="Last"></b-pagination>
                                    </div>
                                    <div class="overflow-auto" v-on:click="getPage()" v-else>
                                        <b-pagination pills size="lg" v-model="currentPage"
                                                      :total-rows="rowCount"
                                                      :key="randerList"
                                                      :per-page="10"
                                                      first-text="الأولى"
                                                      prev-text="السابقة"
                                                      next-text="التالية"
                                                      last-text="الأخيرة"></b-pagination>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <purchaseorder-payment :totalAmount="totalAmount" :customerAccountId="customerAccountId" :show="payment" v-if="payment" @close="paymentSave" :isPurchase="'true'" :isSaleOrder="'false'" :purchaseOrderId="purchaseId" :formName="'AdvancePay'" />
        </div>
    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>

<script>
    import clickMixin from '@/Mixins/clickMixin'
    import moment from "moment";
    export default {
        mixins: [clickMixin],
        data: function () {
            return {
                versionAllow: '',
                internationalPurchase: '',
                fromDate: '',
                toDate: '',
                arabic: '',
                english: '',
                search: '',
                searchQuery: '',
                purchaseOrderList: [],
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                currency: '',
                purchaseId: '',
                totalAmount: 0,
                customerAccountId: '',
                payment: false,
                isMultiUnit: false,


                selected: [],
                selectAll: false,
                updateApprovalStatus: {
                    id: '',
                    approvalStatus: ''
                },
                counter: 0,
                randerList: 0,
            }
        },
        watch: {
            search: function (val) {
                this.getData(val, 1, this.fromDate, this.toDate);
            },
            fromDate: function (fromDate) {
                this.counter++;
                if (this.counter != 1) {
                    localStorage.setItem('fromDate', fromDate);
                    this.getData(this.search, this.currentPage, fromDate, this.toDate);
                }
            },
            toDate: function (toDate) {
                this.counter++;
                if (this.counter != 2) {
                    localStorage.setItem('toDate', toDate);
                    this.getData(this.search, this.currentPage, this.fromDate, toDate);
                }
            }
        },
        methods: {
            //changeStatus: function (id) {
            //    var root = this;
            //    var token = '';
            //    if (this.$session.exists()) {
            //        token = localStorage.getItem('token');
            //    }

            //    var purchase = { id: id, isClose: true };
            //    this.$https.post('/Purchase/SavePurchaseOrderInformation', purchase, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
            //        if (response.data.isSuccess == true) {
            //            root.$swal({
            //                title: "Saved!",
            //                text: "Purchase Order Closed Successfully!",
            //                type: 'success',
            //                icon: 'success',
            //                showConfirmButton: true
            //            });
            //            root.getPage();
            //        }
            //    });
            //},
            paymentSave: function () {
                this.payment = false;
            },
            paymentModel: function (purchaseId, totalAmount, customerAccountId) {

                this.purchaseId = purchaseId;
                this.totalAmount = totalAmount;
                this.customerAccountId = customerAccountId;
                this.payment = true;
            },
            //DeleteFile: function () {
            //    var root = this;
            //    var token = '';
            //    if (this.$session.exists()) {
            //        token = localStorage.getItem('token');
            //    }
            //    this.$https.get('/Purchase/DeletePo', this.selected, { headers: { "Authorization": `Bearer ${token}` } })
            //        .then(function (response) {
            //            if (response.data != null) {
            //                if (response.data.message.id != '00000000-0000-0000-0000-000000000000') {
            //                    root.$swal({
            //                        title: 'Deleted!',
            //                        text: response.data.message.isAddUpdate,
            //                        type: 'success',
            //                        confirmButtonClass: "btn btn-success",
            //                        buttonsStyling: false
            //                    }).then(function (result) {
            //                        if (result) {
            //                            root.$router.push('/purchase');
            //                        }
            //                    });
            //                } else {
            //                    root.$swal({
            //                        title: "Error!",
            //                        text: response.data.message.isAddUpdate,
            //                        type: 'error',
            //                        confirmButtonClass: "btn btn-danger",
            //                        buttonsStyling: false
            //                    });
            //                }
            //            }
            //        },
            //            function () {

            //                root.$swal({
            //                    title: "Error!",
            //                    text: "Update UnSuccessfully",
            //                    type: 'error',
            //                    confirmButtonClass: "btn btn-danger",
            //                    buttonsStyling: false
            //                });
            //            });
            //},
            select: function () {
                this.selected = [];
                if (!this.selectAll) {
                    for (let i in this.purchaseOrderList) {
                        this.selected.push(this.purchaseOrderList[i].id);
                    }
                }
            },
            getPage: function () {
                this.getData(this.search, this.currentPage, this.fromDate, this.toDate);
            },

            makeActive: function () {
                this.selectAll = false;
                this.selected = [];
                this.getData(this.search, 1, this.fromDate, this.toDate);
            },
            getData: function (search, currentPage, fromDate, toDate) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                localStorage.setItem('currentPage', this.currentPage);
                this.$https.get('/Purchase/PurchaseTemplateList?searchTerm=' + search + '&pageNumber=' + currentPage + '&fromDate=' + fromDate + '&toDate=' + toDate, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {
                            root.$store.dispatch('GetPurchaseOrderList', response.data.results);
                            root.purchaseOrderList = response.data.results;
                            root.pageCount = response.data.pageCount;
                            root.rowCount = response.data.rowCount;
                            root.currentPage = currentPage;
                            root.randerList++;
                        }
                    });
            },
            //RemovePurchaseOrder: function (id) {
            //    var root = this;
            //    // working with IE and Chrome both
            //    this.$swal({
            //        title: "Are you sure?",
            //        text: "You will not be able to recover this!",
            //        type: "warning",
            //        showCancelButton: true,
            //        confirmButtonColor: "#DD6B55",
            //        confirmButtonText: "Yes, delete it!",
            //        closeOnConfirm: false,
            //        closeOnCancel: false
            //    }).then(function (result) {
            //        if (result) {

            //            var token = '';
            //            if (root.$session.exists()) {
            //                token = localStorage.getItem('token');
            //            }
            //            root.$https.get('/Purchase/DeletePurchaseOrder?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } })
            //                .then(function (response) {
            //                    if (response.data.message.id != '00000000-0000-0000-0000-000000000000') {


            //                        root.$store.state.purchaseOrderList.splice(root.$store.state.purchaseOrderList.findIndex(function (i) {
            //                            return i.id === response.data.message.id;
            //                        }), 1);
            //                        root.$swal({
            //                            title: 'Deleted!',
            //                            text: response.data.message.isAddUpdate,
            //                            type: 'success',
            //                            confirmButtonClass: "btn btn-success",
            //                            buttonsStyling: false
            //                        });
            //                    } else {
            //                        root.$swal({
            //                            title: "Error!",
            //                            text: response.data.message.isAddUpdate,
            //                            type: 'error',
            //                            confirmButtonClass: "btn btn-danger",
            //                            buttonsStyling: false
            //                        });
            //                    }
            //                },
            //                    function () {

            //                        root.$swal({
            //                            title: "Error!",
            //                            text: "Delete UnSuccessfully",
            //                            type: 'error',
            //                            confirmButtonClass: "btn btn-danger",
            //                            buttonsStyling: false
            //                        });
            //                    });
            //        }
            //        else {
            //            this.$swal('Cancelled', 'Your file is still intact', 'info');
            //        }
            //    });
            //},
            AddPurchaseOrder: function () {

                this.$router.push('/AddAutoPurchaseTemplate');
            },
            //ViewPurchaseOrder: function (id) {
            //    var root = this;
            //    var token = '';
            //    if (this.$session.exists()) {
            //        token = localStorage.getItem('token');
            //    }
            //    root.$https.get('/Purchase/PurchaseOrderDetail?Id=' + id + '&isPoView=' + true + '&isMultiUnit=' + this.isMultiUnit, { headers: { "Authorization": `Bearer ${token}` } })
            //        .then(function (response) {
            //            if (response.data != null) {
            //                root.$router.push({
            //                    path: '/PurchaseOrderView',
            //                    query: { data: response.data }
            //                })
            //            }
            //        },
            //            function (error) {
            //                root.loading = false;
            //                console.log(error);
            //            });
            //},
            EditPurchaseOrder: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Purchase/PurchaseTemplateDetail?Id=' + id + '&isMultiUnit=' + this.isMultiUnit, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {
                            root.$router.push({
                                path: '/AddAutoPurchaseTemplate',
                                query: { data: response.data }
                            })
                        }
                    },
                        function (error) {
                            root.loading = false;
                            console.log(error);
                        });
            },


        },
        created: function () {
            
            if (this.$route.query.data == 'purchaseorders') {
                this.$emit('input', 'purchaseorders');

            }
            else {
                this.$emit('input', this.$route.name);

            }

            if (localStorage.getItem('fromDate') != null && localStorage.getItem('fromDate') != '' && localStorage.getItem('fromDate') != undefined) {
                this.fromDate = localStorage.getItem('fromDate');

            }
            else {
                this.fromDate = moment().startOf('year').format("DD MMM YYYY");

            }
            if (localStorage.getItem('toDate') != null && localStorage.getItem('toDate') != '' && localStorage.getItem('toDate') != undefined) {
                this.toDate = localStorage.getItem('toDate');

            }
            else {
                this.toDate = moment().endOf('year').format("DD MMM YYYY");
            }
        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.internationalPurchase = localStorage.getItem('InternationalPurchase');
            this.isMultiUnit = localStorage.getItem('IsMultiUnit') == 'true' ? true : false;
            this.versionAllow = localStorage.getItem('VersionAllow');
            if (localStorage.getItem('currentPage') != null && localStorage.getItem('currentPage') != '' && localStorage.getItem('currentPage') != undefined) {
                this.currentPage = parseInt(localStorage.getItem('currentPage'));
                this.makeActive();
            }
            else {
                this.makeActive();

            }
            this.currency = localStorage.getItem('currency');
            //this.getData(this.search, 1);
        },
        updated: function () {
            if (this.selected.length < this.purchaseOrderList.length) {
                this.selectAll = false;
            } else if (this.selected.length == this.purchaseOrderList.length) {
                if (this.selected.length == 0) {
                    this.selectAll = false;
                }
                else {
                    this.selectAll = true
                }
            }
        }
    }
</script>