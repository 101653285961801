<template>
    <div class="row " v-if="isValid('CanAddProductionBatch')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
        <div class="col-lg-12">
            <div>

                <div class="mb-2 row">
                    <div class="col-lg-9  ">
                        <span v-if="purchase.id === '00000000-0000-0000-0000-000000000000'"> <span class="MainLightHeading">{{ $t('AddProductionBatch.AddProductionBatch') }} - </span><span class="DayHeading">{{purchase.registrationNo}}</span></span>
                        <span v-else><span class="MainLightHeading">{{ $t('AddProductionBatch.UpdateProductionBatch') }} - </span><span class="DayHeading">{{purchase.registrationNo}}</span></span>

                    </div>
                    <div class="col-lg-3 text-right">
                        <span>
                            {{purchase.date}}
                        </span>
                    </div>
                </div>
                <div class="card">

                    <div class="row card-body">


                        <!--<div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                            <label>{{ $t('AddProductionBatch.NoOfBatches') }}  : <span class="LabelColour"> *</span></label>
                            <div v-bind:class="{'has-danger': $v.purchase.noOfBatches.$error}">
                                <input class="form-control " type="number" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'"
                                       v-model="$v.purchase.noOfBatches.$model" v-on:input="EffectOnItems" />
                                <span v-if="$v.purchase.noOfBatches.$error" class="error text-danger">
                                    <span v-if="!$v.purchase.noOfBatches.required">No of Batches is required</span>
                                </span>
                            </div>
                        </div>-->
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                            <label>{{ $t('AddProductionBatch.SaleOrder') }} :</label>                            
                                <saleorderdropdown v-model="purchase.saleOrderId" :values="purchase.saleOrderId"></saleorderdropdown>
                            
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                            <div class="form-group has-label">
                                <label class="text">{{ $t('AddProductionBatch.RecipeNumber') }}:<span class="LabelColour"> *</span></label>
                                <reciptdropdown v-model="purchase.recipeNoId" :values="purchase.recipeNoId" v-on:input="GetFinishProduct(purchase.recipeNoId)" />
                            </div>


                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                            <label class="text  font-weight-bolder">{{ $t('AddProductionBatch.FromDate') }}:</label>
                            <VueCtkDateTimePicker v-model="purchase.startTime"></VueCtkDateTimePicker>
                        </div>

                        <!--<div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                            <label class="text  font-weight-bolder">{{ $t('AddProductionBatch.ToDate') }}:</label>
                            <VueCtkDateTimePicker v-model="purchase.endTime"></VueCtkDateTimePicker>
                        </div>-->
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                            <label class="text  font-weight-bolder">{{ $t('AddProductionBatch.Employee') }}:</label>
                            <employeeDropdown v-model="purchase.employeeRegistrationId"></employeeDropdown>
                        </div>

                    </div>
                </div>
                <div class="card">
                    <div class="card-body row">

                        <div class="col-lg-4 col-md-6 col-sm-6 ">

                            <span class="ProcessHeading">{{ $t('AddProductionBatch.Processes') }} </span>
                            <draggable v-model="resultQuery">
                                <div v-for="process in resultQuery" class="row cardStyle  m-2 " v-bind:key="process.id" v-on:click="GetContractor(process.id)" v-bind:class="process.isActive ? 'opacity50' : ''">
                                    <div class="col-3 ">
                                        <div class="circletag ml-auto mr-auto mt-4" :style="{ 'background-color': process.color }">

                                            <i class="fas fa-clock" style="color:white;align-self:center;vertical-align:central;padding-top:9px"></i>

                                        </div>
                                    </div>
                                    <div class="col-7 mt-3 " style="align-self:center">
                                        <div class=" CardHeading text-truncate">
                                            {{process.englishName}}
                                        </div>
                                        <p class="description text-truncate">{{process.description}}</p>
                                        <a href="javascript:void(0)" v-on:click="RemoveProcess(process.id,true)" class="text-danger">Remove</a>
                                    </div>
                                    <div class="col-2" style="align-self:center">

                                        <img src="DragAndDrop.png" style="align-self:center" />
                                    </div>
                                </div>

                            </draggable>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6 ">
                            <span class="ProcessHeading">{{ $t('AddProductionBatch.ContractorsOrSupervisor') }} </span>
                            <div v-for="contractor in contractorlist" class="row cardStyle m-2 " v-bind:key="contractor.contractorId" v-on:click="SelectContractor(contractor.contractorId,contractor.processId)" v-bind:class="contractor.isActive ? 'opacity50' : ''">
                                <div class="col-3 ml-auto mr-auto mt-3 ">
                                    <img src="Frame.png" />
                                    

                                </div>
                                <div class="col-7 mt-3 " style="align-self:center">
                                    <div class=" CardHeading">
                                        {{contractor.contractorNameEn}}
                                    </div>
                                    <p class="description">Subtitle Text</p>
                                </div>
                                <div class="col-2" style="align-self:center">

                                    <img src="DragAndDrop.png" style="align-self:center" />

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 col-sm-12 ">
                            <span class="ProcessHeading">{{ $t('AddProductionBatch.ListOfItems') }} </span>
                            <productionbatch-item @input="SavePurchaseItems" v-bind:purchase="purchase" :key="purchaseItemRander" />
                        </div>
                    </div>
                </div>
                <br />

            </div>
            <div v-if="!loading" class="col-md-12 text-right">
                <div v-if="purchase.id === '00000000-0000-0000-0000-000000000000'">

                    <div>
                        <button class="btn btn-primary  mr-2"
                                v-if="isValid('CanAddProductionBatch')"
                                v-on:click="savePurchase('Draft')">
                            <i class="far fa-save"></i> {{ $t('AddProductionBatch.SaveAsDraft') }}
                        </button>
                        <button class="btn btn-danger  mr-2"
                                v-on:click="goToPurchase">
                            {{ $t('AddProductionBatch.Cancel') }}
                        </button>
                    </div>

                </div>
                <div v-else>
                    <button class="btn btn-primary  mr-2"
                            v-on:click="savePurchase('Rejected')">
                        <i class="far fa-save"></i>  {{ $t('AddProductionBatch.UpdateAsRejected') }}
                    </button>
                    <button class="btn btn-primary  mr-2"
                            v-on:click="savePurchase('Draft')"
                            v-if="isValid('CanAddProductionBatch')">
                        <i class="far fa-save"></i>  {{ $t('AddProductionBatch.UpdateAsDraft') }}
                    </button>

                    <button class="btn btn-danger  mr-2"
                            v-on:click="goToPurchase">
                        {{ $t('AddProductionBatch.Cancel') }}
                    </button>
                </div>
            </div>

            <div class="card-footer col-md-3" v-else>
                <loading :active.sync="loading" :can-cancel="true" :is-full-page="true"></loading>
            </div>
            <remainingStockmodel :purchase="purchase"
                                 :show="show"
                                 v-if="show"
                                 @close="show=false" />
        </div>

    </div>
    <div v-else> <acessdenied></acessdenied></div>

</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import Loading from 'vue-loading-overlay';
    import moment from "moment";
    import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
    import draggable from 'vuedraggable';
    import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
    import Vue from 'vue'
    Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);

    import { required } from "vuelidate/lib/validators";


    export default {
        mixins: [clickMixin],
        components: {
            Loading,
            draggable
        },


        data: function () {
            return {
                purchaseItemRander: 0,
                currentPage: 1,
                search: '',
                purchase: {
                    id: "00000000-0000-0000-0000-000000000000",
                    date: "",
                    registrationNo: "",
                    employeeRegistrationId: "",
                    expireDate: "",
                    recipeNoId: "",
                    saleOrderId: "",
                    noOfBatches: 1,
                    productionBatchItems: [],
                    processlist: [],
                    startTime: "",
                    endTime: "",

                },
                ProcessActive: false,
                disable: false,
                loading: false,
                processlist: [],
                contractorlist: [],
                productList: [],
                show: false,
                isRemove: false,
            };
        },
        validations: {
            purchase: {
                date: { required },
                expireDate: {},
                noOfBatches: {},
                registrationNo: { required },
                recipeNoId: {

                },


                productionBatchItems: {
                },
            },
        },
        computed: {
            resultQuery: {
                get() {

                    return this.$store.state.processList
                },

                set(val) {

                    this.$store.dispatch('GetProcessList', val);

                    this.processlist = val;
                }
            }
        },
        methods: {

            RemoveProcess: function (id, isRemove) {
                
                this.isRemove = isRemove;
                this.$store.state.processList = this.$store.state.processList.filter((prod) => {
                    return prod.id != id;
                });
            },

            EffectOnItems: function () {
                this.purchaseItemRander++;
            },

            SelectContractor: function (contractorId, processId) {
                
                var root = this;
                this.contractorlist.find(function (x) {
                    if (x.contractorId == contractorId) {
                        if (x.isActive == false) {
                            x.isActive = true;
                        }
                        else {
                            x.isActive = false;
                        }

                    }

                });
                root.processlist.forEach(function (x) {
                    if (x.id == processId) {

                        x.processContractors.forEach(function (x) {
                            
                            if (x.contractorId == contractorId) {
                                x = root.contractorlist;
                            }

                        });
                    }
                });



            },
            GetContractor: function (id) {
                
                if (!this.isRemove) {
                    var root = this;
                    root.productList = [];

                    this.processlist.find(function (x) {
                        if (x.id == id) {

                            x.isActive = true;
                        }
                        else {
                            x.isActive = false;
                        }
                    });

                    this.processlist.find(function (x) {
                        if (x.id == id) {
                            root.contractorlist = x.processContractors;
                            root.productList = x.processItems;
                        }
                    });
                }
                this.isRemove = false;

            },
            GetProcessData: function () {

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('Batch/ProcessList?isDropdown=true', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {


                        response.data.results.forEach(function (result) {
                            root.processlist.push({
                                id: result.id,
                                code: result.code,
                                color: result.color,
                                description: result.description,
                                englishName: result.englishName,
                                processContractors: result.processContractors,
                                processItems: result.processItems,
                                isActive: false,
                            })
                        })

                        root.$store.dispatch('GetProcessList', root.processlist);
                    }
                    root.loading = false;
                });
            },

            GetFinishProduct: function (id) {
                var root = this;
                var token = "";
                if (root.$session.exists()) {
                    token = localStorage.getItem("token");
                }

                root.$https.get('/Batch/RecipeNoDetail?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {
                            

                            //root.purchase.recipeQuantity = response.data.quantity;
                            //response.data.recipeNoItems.forEach(function (recipe) {
                            //    alert(root.processItems);
                            //    root.processItems.forEach(function (x) {
                            //        if (x.isActive == true) {
                            //            x.forEach(function (result) {
                            //                if (result.productId == recipe.productId) {
                            //                    root.purchase.productionBatchItems.push(recipe);
                            //                }
                            //            })
                            //        }


                            //    });


                            //});

                            root.purchase.recipeQuantity = response.data.quantity;
                            root.purchase.productionBatchItems = response.data.recipeNoItems;
                            //root.productList.forEach(function (result) {
                            //    response.data.recipeNoItems.forEach(function (recipe) {
                            //        if (result.productId == recipe.productId) {
                            //            root.purchase.productionBatchItems.push(recipe);
                            //        }

                            //    });

                            //});
                            root.purchaseItemRander++;


                        }
                    });

            },

            AutoIncrementCode: function () {
                var root = this;
                var token = "";
                if (root.$session.exists()) {
                    token = localStorage.getItem("token");
                }
                root.$https
                    .get("/Batch/ProductionBatchAutoGenerateNo", {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then(function (response) {
                        if (response.data != null) {
                            root.purchase.registrationNo = response.data;
                        }
                    });
            },

            SavePurchaseItems: function (productionBatchItems) {

                //var root = this;
                //for (var y in productionBatchItems) {
                //    if (productionBatchItems[y].wareHouseId == null) {
                //        root.disable = true;
                //        break;
                //    }
                //    else {
                //        root.disable = false;

                //    }

                //}
                this.purchase.productionBatchItems = productionBatchItems;
            },
            savePurchase: function (status) {
                
                var root = this;
                this.purchase.approvalStatus = status;

                this.purchase.processlist = [];
                this.$store.state.processList.forEach(function (item) {
                    var process = root.processlist.find((value) => value.id == item.id);
                    if (process != undefined) {
                        root.purchase.processlist.push(process);
                    }                    
                });

                this.loading = true;
                
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.post('/Batch/SaveProductionBatchInformation', root.purchase, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {
                        if (response.data != null && response.data != '00000000-0000-0000-0000-000000000000') {
                            root.loading = false
                            root.info = response.data
                            root.$swal({
                                title: "Saved!",
                                text: "Data Saved Successfully!",
                                type: 'success',
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,
                            }).then(function (response) {
                                if (response != undefined) {
                                    root.loading = false;

                                    if (root.purchase.id == "00000000-0000-0000-0000-000000000000") {
                                        root.$router.push({
                                            path: '/ProductionBatch',
                                            query: { data: status }
                                        });

                                    } else {
                                        root.loading = false;

                                        root.$router.push({
                                            path: '/ProductionBatch',
                                            query: { data: status }
                                        });
                                    }
                                }
                            });
                        }
                        else {
                            root.loading = false
                            root.info = response.data
                            root.$swal({
                                title: "Error!",
                                text: "Something Went Wrong!",
                                type: 'error',
                                icon: 'error',
                                timer: 1500,
                                timerProgressBar: true,
                            });
                        }

                    })
                    .catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Something Went Wrong!',
                                text: error,
                            });

                        root.loading = false
                    })
                    .finally(() => root.loading = false)

            },

            goToPurchase: function () {
                this.$router.push('/ProductionBatch');
            },
        },
        
        created: function () {
            this.$emit('input', this.$route.name);
            if (this.$route.query.data != undefined) {
                this.purchase = this.$route.query.data;
                this.purchase.date = moment(this.purchase.date).format('LLL');
            }
        },
        mounted: function () {
            if (this.$route.query.data == undefined) {
                this.AutoIncrementCode();
                this.GetProcessData();

                this.purchase.date = moment().format('LLL');
            }
        },
    };
</script>
<style scoped>
    .cardStyle {
        height: 80px !important;
        max-width: 302px;
        min-width: 200px;
        left: 0px;
        top: 0px;
        background-color: #FFFFFF;
        border-style: solid;
        border: 1px solid rgba(0,0,0,.2);
        height: 50px;
        border-radius: 10px
    }

    .CardHeading {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 25px;
        /* identical to box height, or 156% */
        /* Text/Heading/Dark */

        color: #27272E;
    }

    .description {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 23px;
        /* identical to box height, or 164% */

        font-feature-settings: 'salt' on;
        /* Text/Body/Light */

        color: #425466;
    }

    .drop-zone {
        background-color: #eee;
        margin-bottom: 10px;
        padding: 10px;
    }

    .drag-el {
        background-color: #fff;
        margin-bottom: 10px;
        padding: 5px;
    }


    .circletag {
        display: block;
        width: 36px;
        height: 36px;
        text-align: center;
        align-content: center;
        align-items: center;
        border-radius: 50%;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
    }

    .iconSize {
        width: 16px;
        height: 16px;
    }

    .ProcessHeading {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 23px;
        /* identical to box height */

        letter-spacing: 0.01em;
        color: #3178F6;
    }

    .opacity50 {
        background-color: #ececec;
    }

    .cardStyle:hover {
        border-color: #3178F6;
    }
</style>
