<template>
    <modal :show="show" :modalLarge="true" :extraLarge="true" :key="randerList">

        <div class="col-lg-12 col-sm-12 ml-auto mr-auto">
            <div>

                <div class="mt-2">
                    <div class="row">
                        <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8">
                            <div>
                                <span class="Heading1">{{ $t('LoanDetail.LoanDetail') }}</span> <span class="Heading1">-{{loanDetail.description}}</span>
                            </div>
                            <div>
                                <span class="Heading2">{{loanDetail.employeeName}}</span>
                            </div>

                        </div>

                        <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 text-right" v-if="loanDetail.remainingLoan!=0">
                            <a href="javascript:void(0)" class="btn btn-primary" style="margin-top:0px !important" v-on:click="openmodel"><i class="fa fa-plus"></i>{{ $t('LoanDetail.AddPayment') }}</a>

                        </div>
                        <div class="col-lg-12">
                            <div class="mt-2">

                                <table class="table table-shopping" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <thead class="">
                                        <tr>
                                            <th>
                                                {{ $t('LoanDetail.Transaction') }}
                                            </th>
                                            <th>

                                                {{ $t('LoanDetail.LoanDate') }}
                                            </th>
                                            <th>
                                                {{ $t('LoanDetail.LoanAmount') }}
                                            </th>
                                            <th>
                                                {{ $t('LoanDetail.RecoveryLoanAmount') }}
                                            </th>
                                            <th>
                                                {{ $t('LoanDetail.Payment') }}
                                            </th>
                                            <th>
                                                {{ $t('LoanDetail.RecoveryBalance') }}
                                            </th>
                                            <!--<th>
                                                {{ $t('LoanDetail.Action') }}
                                            </th>-->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {{ $t('LoanDetail.Loan') }}
                                            </td>
                                            <td>
                                                {{loanDetail.loanDate}}
                                            </td>
                                            <td>
                                                {{currency}}  {{parseFloat(loanDetail.loanAmount).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                            </td>
                                            <td>
                                                {{currency}}  {{parseFloat(loanDetail.recoveryLoanAmount).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}

                                            </td>
                                            <td>
                                            </td>

                                            <td>
                                                {{currency}}  {{parseFloat(loanDetail.recoveryLoanAmount).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}

                                            </td>

                                            <td>
                                            </td>
                                        </tr>
                                        <tr v-for="loanPayment in loanDetail.loanPays" v-bind:key="loanPayment.id">

                                            <td>
                                                {{ $t('LoanDetail.LoanPaymentCash') }}
                                            </td>
                                            <td>
                                                {{loanPayment.recoveryDate}}
                                            </td>
                                            <td>
                                            </td>
                                            <td>
                                            </td>
                                            <td>
                                                {{currency}}  {{parseFloat(loanPayment.amount).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                            </td>

                                            <td>
                                                {{currency}}  {{parseFloat(loanPayment.remainingLoan).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                            </td>
                                            <!--<td>
                                                <a href="javascript:void(0)" class="btn btn-danger btn-sm btn-icon btn-round" v-on:click="RemovePayment(loanPayment.id)"><i class=" fa fa-trash"></i></a>
                                            </td>-->



                                        </tr>
                                        <tr>
                                            <td>
                                            </td>
                                            <td>
                                                {{ $t('LoanDetail.ToDateTotal') }}
                                            </td>
                                            <td>
                                                {{currency}}  {{parseFloat(loanDetail.loanAmount).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                            </td>
                                            <td>
                                                {{currency}}  {{parseFloat(loanDetail.recoveryLoanAmount).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                            </td>
                                            <td>
                                                {{currency}} {{parseFloat(TotalPayment).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                            </td>

                                            <td>
                                                {{currency}} {{parseFloat(loanDetail.remainingLoan).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                            </td>

                                            <!--<td>
                                            </td>-->
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div class=" modal-footer justify-content-right pt-2">
                    <button type="button" class="btn btn-danger   " v-on:click="close()">{{ $t('LoanDetail.Cancel') }}</button>
                </div>

                <loanRecovery :loanRecovery="newLoanRecovery"
                              :loanDetail="loanDetail"
                              :show="loanRecoveryShow"
                              v-if="loanRecoveryShow"
                              @close="RefreshList" />

            </div>
        </div>
    </modal>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'

    export default {
        mixins: [clickMixin],
        props: ['show', 'loanDetail'],

        data: function () {
            return {
                currency: '',
                arabic: '',
                english: '',
                render: 0,
                randerList: 0,
                loading: false,
                loanRecoveryShow: false,
                newLoanRecovery: {
                    id: '00000000-0000-0000-0000-000000000000',
                    loanPaymentId: this.loanDetail.id,
                    amount: 0,
                    paymentDate: '',
                    comments: '',
                },
            }
        },
        computed: {
            TotalPayment: function () {
                
                return this.loanDetail.loanPays.reduce(function (a, c) { return a + Number((c.amount) || 0) }, 0)
            },






        },

        methods: {
            openmodel: function () {
                this.newLoanRecovery = {
                    id: '00000000-0000-0000-0000-000000000000',
                    loanPaymentId: this.loanDetail.id,
                    amount: 0,
                    paymentDate: '',
                    comments: '',

                }
                this.loanRecoveryShow = !this.loanRecoveryShow;
            },
            RefreshList: function (x) {
                if (x == true) {
                    var root = this;
                    var token = '';
                    if (this.$session.exists()) {
                        token = localStorage.getItem('token');
                    }
                    
                    root.$https.get('/Payroll/LoanPaymentDetail?Id=' + this.loanDetail.id, { headers: { "Authorization": `Bearer ${token}` } })
                        .then(function (response) {
                            if (response.data) {

                                root.loanDetail = response.data;
                                root.loanRecoveryShow = false;
                                root.randerList++;


                            }
                        },
                            function (error) {
                                this.loading = false;
                                console.log(error);
                            });
                }
                else {
                    this.loanRecoveryShow = false;

                }
            },
            RemovePayment: function (id) {


                var root = this;
                // working with IE and Chrome both
                this.$swal({
                    title: "Are you sure?",
                    text: "You will not be able to recover this!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes, delete it!",
                    closeOnConfirm: false,
                    closeOnCancel: false
                }).then(function (result) {
                    if (result) {

                        var token = '';
                        if (root.$session.exists()) {
                            token = localStorage.getItem('token');
                        }
                        root.$https.get('/Payroll/DeleteLoanRecovery?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } })
                            .then(function (response) {
                                if (response.data != null) {


                                    root.$swal({
                                        title: 'Deleted!',
                                        text: 'Record Deleted Sucessfully!',
                                        type: 'success',
                                        confirmButtonClass: "btn btn-success",
                                        buttonsStyling: false
                                    });
                                    root.RefreshList(true);
                                }
                            },
                                function () {

                                    root.$swal({
                                        title: "Error!",
                                        text: "Something Went Wrong",
                                        type: 'error',
                                        confirmButtonClass: "btn btn-danger",
                                        buttonsStyling: false
                                    });
                                });
                    }
                    else {
                        this.$swal('Cancelled', 'Your file is still intact', 'info');
                    }
                });
            },



            close: function () {
                this.$emit('close');
            },

        },
        mounted: function () {

            this.currency = localStorage.getItem('currency');

        }
    }
</script>

<style scoped>
    .Heading1 {
        font-size: 25px !important;
        font-style: normal;
        font-weight: 600;
        color: #3178F6;
    }

    .Heading2 {
        font-size: 18px !important;
        font-style: normal;
        color: black;
    }
</style>
