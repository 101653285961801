<template>
    <modal :show="show"  v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">

        <div style="margin-bottom:0px" class="card">
            <div class="card-body">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">
                      
                        <div class="modal-header" >

                            <h5 class="modal-title" id="myModalLabel">{{ $t('HoldModel.HoldInvoices') }} </h5>

                        </div>

                        <div class="card-body">
                            <div class="row ">

                                <div class="form-group has-label col-sm-12 ">
                                    <label class="text  font-weight-bolder"> {{ $t('HoldModel.RefrenceNo') }}:</label>
                                    <input class="form-control" v-model="sale.refrenceNo" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" type="text" />
                                </div>
                            </div>
                        </div>                      
                        <div class="modal-footer justify-content-right" v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'direction: rtl'" >
                            <button type="button" class="btn btn-primary  " v-on:click="SaveOrigin">  {{ $t('HoldModel.Save') }}</button>
                            <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()"> {{ $t('HoldModel.Close') }}</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </modal>
   
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        mixins: [clickMixin],
        props: ['show','sale' ],
        data: function () {
            return {
               
          
              
            }
        },
        methods: {
            close: function () {
                    this.$emit('close', false);
            },         
            SaveOrigin: function () {
                this.$emit('saveSale', this.sale);               
                this.close();
            }
        },
        mounted: function () {
        }
    }
</script>
