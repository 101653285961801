<template>
    <div>
        <multiselect v-model="DisplayValue" :options="options" :multiple="false" v-bind:placeholder="$t('SubCategoryDropdown.PleaseSelectSubCategory')" track-by="dropDownName" :clear-on-select="false" :show-labels="false" label="dropDownName" :preselect-first="true" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left ' : 'arabicLanguage '">
            <!--<p slot="noResult" class="text-danger"> Oops! No SubCategory found.</p>-->
           
            <span slot="noResult" class="btn btn-primary " v-on:click="AddSubCategory('Add')" v-if=" isValid('CanAddSubCategory')">{{ $t('SubCategoryDropdown.AddSubCategory') }}</span><br />
            
        </multiselect>
        <modal :show="show" v-if="show">
            <div style="margin-bottom:0px" class="card">
                <div class="card-body">
                    <div class="card-body">
                        <div class="col-lg-12">
                            <div class="tab-content" id="nav-tabContent">
                                <div class="card-header p-0">
                                    <h5>{{ $t('SubCategoryDropdown.AddSubCategory') }}</h5>
                                </div>
                                <div class="text-left">
                                    <div class="card-body">
                                        <div class="row ">
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <label>{{ $t('SubCategoryDropdown.Code') }} :<span class="LabelColour"> *</span></label>
                                                <div v-bind:class="{'has-danger' : $v.subCategory.code.$error}">
                                                    <input readonly class="form-control" v-model="$v.subCategory.code.$model" />
                                                    <span v-if="$v.subCategory.code.$error" class="error text-danger">
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-sm-12">
                                                <label>{{ $t('SubCategoryDropdown.SelectCategory') }} :<span class="LabelColour"> *</span></label>
                                                <div>
                                                    <categorydropdown v-model="$v.subCategory.categoryId.$model" v-bind:values="subCategory.categoryId" :key="rendered"></categorydropdown>
                                                </div>
                                            </div>

                                            <div v-if="english=='true'" class="col-sm-12 form-group">
                                                <label>{{ $t('SubCategoryDropdown.SubCategoryName') |englishLanguage}} :<span class="LabelColour"> *</span></label>
                                                <div v-bind:class="{'has-danger' : $v.subCategory.name.$error}">
                                                    <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" v-model="$v.subCategory.name.$model" />
                                                    <span v-if="$v.subCategory.name.$error" class="error text-danger">
                                                        <span v-if="!$v.subCategory.name.required"> {{ $t('SubCategoryDropdown.NameRequired') }}</span>
                                                        <span v-if="!$v.subCategory.name.maxLength">{{ $t('SubCategoryDropdown.NameLength') }}</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div v-if="isOtherLang()" class="has-label col-sm-12 form-group " v-bind:class="{'has-danger' : $v.subCategory.nameArabic.$error}">
                                                <label class="text  font-weight-bolder"> {{ $t('SubCategoryDropdown.SubCategoryNameAr') |arabicLanguage }}: <span class="LabelColour"> *</span></label>
                                                <input class="form-control " v-bind:class="isLeftToRight() ? 'text-left' : 'arabicLanguage'"  v-model="$v.subCategory.nameArabic.$model" type="text" />
                                                <span v-if="$v.subCategory.nameArabic.$error" class="error">
                                                    <span v-if="!$v.subCategory.nameArabic.required"> {{ $t('SubCategoryDropdown.NameRequired') }}</span>
                                                    <span v-if="!$v.subCategory.nameArabic.maxLength">{{ $t('SubCategoryDropdown.NameLength') }}</span>
                                                </span>
                                            </div>
                                            <div class="col-sm-12">
                                                <label>{{ $t('SubCategoryDropdown.Description') }} :</label>
                                                <div v-bind:class="{'has-danger' : $v.subCategory.description.$error}">
                                                    <textarea class="form-control" v-model="$v.subCategory.description.$model" />
                                                    <span v-if="$v.subCategory.description.$error" class="error text-danger">

                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="!loading" class=" col-md-12 text-right">
                                    <button class="btn btn-primary   mr-2" v-bind:disabled="$v.subCategory.$invalid" v-on:click="SaveSubCategory">{{ $t('SubCategoryDropdown.btnSave') }}</button>
                                    <button class="btn btn-danger   mr-2" v-on:click="close()">{{ $t('SubCategoryDropdown.btnClear') }}</button>
                                </div>
                                <div v-else>
                                    <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
    import Multiselect from 'vue-multiselect'
    import { required, maxLength, requiredIf } from "vuelidate/lib/validators"
    import clickMixin from '@/Mixins/clickMixin'
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        mixins: [clickMixin],
        name: 'subcategorydropdown',
        props: ["values", "categoryId"],

        components: {
            Multiselect,
            Loading
        },
        data: function () {
            return {
                arabic: '',
                english: '',
                options: [],
                value: '',
                show: false,
                type: '',
                rendered: 0,
                subCategory: {
                    id: '00000000-0000-0000-0000-000000000000',
                    code: '',
                    name: '',
                    nameArabic: '',
                    description: '',
                    categoryId: this.categoryId,
                    isActive: true
                },
                loading: false,
                render: 0
            }
        },
        validations: {
            subCategory:
            {
                code: {
                    required
                },
                name: {
                    maxLength: maxLength(50)
                },
                nameArabic: {
                    required: requiredIf((x) => {
                        if (x.name == '' || x.name == null)
                            return true;
                        return false;
                    }),
                    maxLength: maxLength(50)
                },
                categoryId: {
                    required
                },
                description: {}
            }
        },
        methods: {
            getData: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                this.$https.get('/Product/GetSubCategoryInformation?isActive=true', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                    
                    if (response.data != null) {
                        response.data.subCategories.forEach(function (cat) {
                            
                            root.options.push({
                                id: cat.id,
                                dropDownName: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? (cat.name != '' ? cat.code + ' ' + cat.name : cat.code + ' ' + cat.nameArabic) : (cat.nameArabic != '' ? cat.code + ' ' + cat.nameArabic : cat.code + ' ' + cat.name),
                                name: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? (cat.name != '' ? cat.name : cat.nameArabic) : (cat.nameArabic != '' ? cat.nameArabic : cat.name)

                            })
                       })
                    }
                }).then(function () {
                    root.value = root.options.find(function (x) {
                        
                        return x.id == root.values;
                    })
                });
            },
            getCategoryBaseData: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                // eslint-disable-line
                this.$https.get('/Product/GetSubCategoryInformation?categoryId=' + id + '&isActive=' + true, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    // eslint-disable-line
                    if (response.data != null) {
                        
                        response.data.results.subCategories.forEach(function (scat) {
                            
                            root.options.push({
                                id: scat.id,
                                dropDownName: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? (scat.name != '' ? scat.code + ' '  + scat.name : scat.code + ' ' + scat.nameArabic) : (scat.nameArabic != '' ? scat.code + ' ' + scat.nameArabic : scat.code + ' ' + scat.name),
                                name: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? (scat.name != '' ? scat.name : scat.nameArabic) : (scat.nameArabic != '' ? scat.nameArabic : scat.name)
                            })
                       })
                    }
                    else{
                        root.options.push({
                                id: '',
                                name: ''
                            })
                    }
                }).then(function () {
                    root.value = root.options.find(function (x) {
                        
                        return x.id == root.values;
                    })
                });
            },
            AddSubCategory: function (type) {
                this.$v.$reset();
                this.AutoIncrementCode();
                this.subCategory = {
                    id: '00000000-0000-0000-0000-000000000000',
                    code: '',
                    name: '',
                    nameArabic: '',
                    description: '',
                    categoryId: this.categoryId,
                    isActive: true
                }

                this.show = !this.show;
                this.type = type;
                this.rendered++;
            },
            close: function () {
                this.show = false;
            },
            SaveSubCategory: function () {
                var root = this;
                this.loading = true;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.post('/Product/SaveSubCategoryInformation', this.subCategory, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                    
                    if (response.data.isSuccess == true) {
                        if (root.type != "Edit") {
                            root.$store.state.subCategoryList.push({
                                id: response.data.subCategory.id,
                                name: response.data.subCategory.name,
                                nameArabic: response.data.subCategory.nameArabic,
                                description: response.data.subCategory.description,
                                code: response.data.subCategory.code,
                            })
                            root.options.push({
                                id: response.data.id,
                                dropDownName: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? (root.subCategory.name != '' ? root.subCategory.code + ' ' + root.subCategory.name : root.subCategory.code + ' ' + root.subCategory.nameArabic) : (root.subCategory.nameArabic != '' ? root.subCategory.code + ' ' + root.subCategory.nameArabic : root.subCategory.code + ' ' + root.subCategory.name),

                                code : root.subCategory.code,
                                name : root.subCategory.name,
                                nameArabic: root.subCategory.nameArabic,
                                description: root.subCategory.description
                            })
                            root.show = false;
                            root.$swal({
                                icon: 'success',
                                title: 'Saved Successfully!',
                                showConfirmButton: false,
                                timer: 1500,
                                timerProgressBar: true,
                            });
                        }
                        else {
                            var data = root.$store.state.subCategoryList.find(function (x) {
                                return x.id == response.data.subCategory.id;
                            });
                            data.id = response.data.subCategory.id;
                            data.name = response.data.subCategory.name;
                            data.nameArabic = response.data.subCategory.nameArabic;
                            data.description = response.data.subCategory.description;
                            data.code = response.data.subCategory.code;
                            root.$swal({
                                title: "Update!",
                                icon: 'success',
                                text: "Your Size " + response.data.subCategory.name + " has been updated!",
                                type: 'success',
                                showConfirmButton: false,
                                timer: 800,
                                timerProgressBar: true,
                            });
                            root.close();
                        }
                    }
                    else {
                        root.$swal({
                            title: "Error!",
                            icon: 'error',
                            text: "Your Subcategory Already Exist!",
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            showConfirmButton: false,
                            timer: 1500,
                            timerProgressBar: true,
                        });
                    }

                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Something Went Wrong!',
                                text: error.response.data,

                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false
                    })
                    .finally(() => root.loading = false)
            },
            AutoIncrementCode: function () {
            var root = this;
            var token = '';
            if (root.$session.exists()) {
                token = localStorage.getItem('token');
            }
            root.$https
                .get('/Product/SubCategoryAutoGenerateCode', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.subCategory.code = response.data;
                        root.render++
                    }
                });
            }
        },
        computed: {
            DisplayValue: {
                get: function () {
                    if (this.value != "" || this.value != undefined) {
                        return this.value;
                    }
                    return this.values;
                },
                set: function (value) {
                    this.value = value;
                    this.$emit('input', value.id);
                }
            }
        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            if(this.categoryId != null){
                // eslint-disable-line
                this.getCategoryBaseData(this.categoryId);
            }
        },
    }
</script>