<template>
    <div v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? 'left:0' :'direction: rtl;right:0'">
        <div class="sidebar" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'sidebar_arabic'">
            <div class="logo">
                <a href="javascript:void(0)" class="simple-text logo-mini">
                    <div class="logo-image-small">
                        <img src="/images/logo-mini.svg ">
                    </div>
                </a>
                <a href="javascript:void(0)" class="simple-text logo-normal">
                    <div class="logo-image-small">
                        {{applicationName}}
                        <!--<img src="Noble-POS-logo.png" v-bind:style="$i18n.locale == 'en' ? 'padding-left:7px;width:157px' : 'padding-left:39px;width:174px'" class="hidee">-->
                    </div>
                </a>
            </div>
            <div class="sidebar-wrapper">


                <ul class="nav">
                    <li>
                        <router-link :to="'/TermsAndConditions'">
                            <i class="fas fa-clipboard"></i>
                            <p v-bind:class="$i18n.locale == 'ar' ? 'arabicLanguage' : ''">{{ $t('TermsDashboard.TermsCondition') }}</p>
                        </router-link>
                    </li>

                    <li v-if="termsCondition=='true'">
                        <router-link :to="'/Setup'">
                            <i class="fas fa-tasks"></i>
                            <p v-bind:class="$i18n.locale == 'ar' ? 'arabicLanguage' : ''">{{ $t('TermsDashboard.CompanySetup') }}</p>
                        </router-link>
                    </li>
                    <!--<li>
                    <router-link :to="'/Support'">
                        <i class=" fas fa-question-circle"></i>
                        <p v-bind:class="$i18n.locale == 'ar' ? 'arabicLanguage' : ''">  {{ $t('TermsDashboard.ContactSupports') }}</p>
                    </router-link>
                </li>-->

                </ul>
            </div>
        </div>
        <div class="main-panel" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'MainPanelPadding' : 'MainPanelPaddingRight'">
            <!-- Navbar -->
            <nav class="navbar navbar-expand-lg navbar-absolute fixed-top navbar-transparent" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'navPaddingLeft' : 'navPaddingRight'">
                <div class="container-fluid">
                    <div class="navbar-wrapper">
                        <div class="navbar-minimize">
                            <button id="minimizeSidebar" class="btn btn-primary btn-icon btn-round ">
                                <i class="nc-icon nc-minimal-right text-center visible-on-sidebar-mini"></i>
                                <i class="nc-icon nc-minimal-left text-center visible-on-sidebar-regular"></i>
                            </button>
                        </div>
                        <div class="navbar-toggle">
                            <button type="button" class="navbar-toggler">
                                <span class="navbar-toggler-bar bar1"></span>
                                <span class="navbar-toggler-bar bar2"></span>
                                <span class="navbar-toggler-bar bar3"></span>
                            </button>
                        </div>
                        <!--<a class="navbar-brand" href="#pablo"> {{ $t('TermsDashboard.NoblePOSRetail')}}</a>-->
                    </div>
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navigation" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-bar navbar-kebab"></span>
                        <span class="navbar-toggler-bar navbar-kebab"></span>
                        <span class="navbar-toggler-bar navbar-kebab"></span>
                    </button>
                    <div class="collapse navbar-collapse justify-content-end" id="navigation">
                        <ul class="navbar-nav">
                            <li class="nav-item dropdown" style="margin-top: -3px;" v-if="english=='true' && isOtherLang()">
                                <a class="nav-link dropdown-toggle" style="font-size:16px;" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fas fa-globe"></i>
                                    <p>
                                        <span class="d-lg-none d-md-block">Language</span>
                                    </p>
                                </a>
                                <div class="dropdown-menu" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'dropdown-menu-right' : 'dropdown-menu-left'" aria-labelledby="navbarDropdownMenuLink">
                                    <a class="dropdown-item" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" @click="setLocale('en')">English</a>
                                    <a class="dropdown-item" v-if="arabic=='true'" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" style="font-size: 0.8571em;  font-weight: 700" @click="setLocale('ar')">الـعــربـيـة</a>
                                    <a class="dropdown-item" v-if="portugues"  style="font-size: 0.8571em;  font-weight: 700" @click="setLocale('pt')">Portuguese</a>
                                </div>
                            </li>
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" style="margin-top: -5px;" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="nc-icon nc-circle-10"></i>
                                    <p>
                                        <span class="d-lg-none d-md-block">
                                            {{$t('TermsDashboard.Profile')}}
                                        </span>
                                    </p>
                                </a>
                                <div class="dropdown-menu " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'dropdown-menu-right' : 'dropdown-menu-left'" aria-labelledby="navbarDropdownMenuLink">
                                    <a class="dropdown-item" href="#">{{ DisplayUserName }}</a>
                                    <a class="dropdown-item" href="#" v-on:click="UserProfile">My Profile</a>
                                    <a class="dropdown-item" href="#" v-on:click="UpdateCompanyPermission"> &nbsp;Sync Permission</a>
                                    <a class="dropdown-item" href="#" v-on:click="logout"><i class="fas fa-sign-out-alt"></i> &nbsp;Logout</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <!-- End Navbar -->
            <div class="content">
                <router-view></router-view>
                <div v-if="dashboard=='/dashboard'">
                    <dashboard></dashboard>
                </div>
            </div>
        </div>
        <loading :active.sync="loading"
                 :can-cancel="true"
                 :is-full-page="true"></loading>
    </div>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import Loading from 'vue-loading-overlay';
    import "vue-loading-overlay/dist/vue-loading.css";
    import axios from 'axios'
    export default {
        mixins: [clickMixin],
        name: 'locale-changer',
        components: {
            Loading
        },

        data() {
            return {
                loading: false,
                portugues: false,

                noblePermissions: '',
                companyId: '',
                langs: ['en', 'ar'],
                DisplayUserName: '',
                role: '',
                dashboard: '',
                ur: '',
                isAccount: '',
                arabic: '',
                english: '',
                termsCondition: false,
                applicationName:''
            }
        },
        methods: {
            UpdateCompanyPermission: function () {
                
                this.loading = true
                var root = this;
                axios.get(root.$PermissionIp + '/NoblePermission/GetAllPermissionData?id=' + this.companyId + '&systemType=' + root.$SystemType).then(function (response) {
                    if (response.data != null) {

                        root.noblePermissions = response.data.result
                        root.SaveNoblePermissions(root.companyId)
                    }

                }).catch(error => {
                    console.log(error)
                    root.$swal.fire(
                        {
                            icon: 'error',
                            title: 'Something Went Wrong!',
                            text: 'Please Contact to support to update license',

                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                        });
                    root.loading = false

                });
            },
            SaveNoblePermissions: function (locationId) {
                
                var root = this;
                this.loading = true;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.noblePermissions.locationId = locationId;
                if (this.noblePermissions != '' || this.noblePermissions != null || this.noblePermissions != undefined) {
                    this.$https.post('/Company/SaveNoblePermission', this.noblePermissions, { headers: { "Authorization": `Bearer ${token}` } })
                        .then(function (response) {
                            if (response.data.isSuccess == true) {

                                root.$swal({
                                    title: "Saved!",
                                    text: "Update Sucessfully!",
                                    type: 'success',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 1500,
                                    timerProgressBar: true,
                                });
                                root.logout()
                            }
                            else {
                                root.$swal({
                                    title: "Error!",
                                    text: "Something went wrong",
                                    type: 'error',
                                    icon: 'error',
                                    showConfirmButton: false,
                                    timer: 1500,
                                    timerProgressBar: true,
                                });
                            }
                            root.loading = false
                        }).catch(error => {
                            console.log(error)
                            root.$swal.fire(
                                {
                                    icon: 'error',
                                    title: 'Something Went Wrong!',
                                    text: error,

                                    showConfirmButton: false,
                                    timer: 5000,
                                    timerProgressBar: true,
                                });

                            root.loading = false
                        });
                }
            },
            setLocale(locale) {

                this.$i18n.locale = locale
                localStorage.setItem('locales', locale);

            },


            UserProfile: function () {
                this.$router.push('/SetupUser');
            },
            logout: function () {
                var root = this;
                //  var Swal = this.$swal
                var url = '/account/logout';
                this.$https.post(url, this.login).then(function (response) {

                    if (response.data == "Success") {
                        // root.$swal.fire(
                        // {
                        //     toast: true,
                        //     position: 'top-end',
                        //     showConfirmButton: false,
                        //     timer: 1500,
                        //     timerProgressBar: true,
                        //     icon: 'success',
                        //     title: 'Logged Out Successfully',
                        //     didOpen: (toast) => {
                        //         toast.addEventListener('mouseenter', Swal.stopTimer)
                        //         toast.addEventListener('mouseleave', Swal.resumeTimer)
                        // }});
                        //eslint-disable-line
                        root.$session.destroy();
                        localStorage.clear();
                        //document.cookie.split(';').forEach(cookie => document.cookie = cookie.replace(/^ +/, '').replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`));

                        //localStorage.removeItem('token');
                        //localStorage.removeItem('UserName');
                        //localStorage.removeItem('RoleName');
                        //localStorage.removeItem('CompanyID');
                        //localStorage.removeItem('UserID');
                        //localStorage.removeItem('FullName');
                        //localStorage.removeItem('locales');
                        //localStorage.removeItem('ImagePath');
                        //localStorage.removeItem('EmployeeId');
                        //localStorage.removeItem('currency');




                        root.$router.push('/')
                    }
                    else {
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Error Logging Out'
                            });
                    }

                });

            }
        },
        mounted: function () {
            this.portugues = localStorage.getItem('Portugues') == "true" ? true : false;

            this.companyId = localStorage.getItem('CompanyID')
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.applicationName = localStorage.getItem('ApplicationName')
            if (this.$session.exists()) {
                //eslint-disable-line
                this.DisplayUserName = localStorage.getItem('UserName');
                this.role = localStorage.getItem('RoleName');
                this.isAccount = localStorage.getItem('isAccount');
                this.termsCondition = localStorage.getItem('TermsCondition');
                this.dashboard = this.$router.options.routes;

            }
            if (!this.$session.exists()) {
                this.$router.push('/')
            }
        }

    }
</script>
<style scoped>
    .PositionManager.sidebar .sidebar-wrapper > .nav [data-toggle="collapse"] ~ div > ul > li > a .sidebar-mini-icon,
    .sidebar .sidebar-wrapper .user .info [data-toggle="collapse"] ~ div > ul > li > a .sidebar-mini-icon,
    .off-canvas-sidebar .sidebar-wrapper > .nav [data-toggle="collapse"] ~ div > ul > li > a .sidebar-mini-icon,
    .off-canvas-sidebar .sidebar-wrapper .user .info [data-toggle="collapse"] ~ div > ul > li > a .sidebar-mini-icon {
        float: left !important;
        text-align: left !important;
    }
    /*@media  (max-width:992px) {
        .MainPanelPadding {
            padding-left: 260px !important;
        }

        .MainPanelPaddingRight {
            padding-right: 260px !important;
        }
    }*/
    @media (min-width: 993px) and (max-width:2560px) {
        .MainPanelPadding {
            padding-left: 85px !important;
        }

        .MainPanelPaddingRight {
            padding-right: 85px !important;
        }
    }

    .navPaddingLeft {
        padding-left: 85px !important;
    }

    .navPaddingRight {
        padding-right: 85px !important;
    }
    .router-link-exact-active, active {
        background-color: #0225506e !important;
        opacity: 100 !important;
    }
</style>
