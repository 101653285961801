<template>
    <modal :show="show" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">

        <div style="margin-bottom:0px" class="card">
            <div class="card-body">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="modal-header" v-if="type=='Edit'">

                            <h5 class="modal-title DayHeading" id="myModalLabel"> {{ $t('AddCurrency.UpdateCurrency') }}</h5>

                        </div>
                        <div class="modal-header" v-else>

                            <h5 class="modal-title DayHeading" id="myModalLabel" v-if="setup==undefined"> {{ $t('AddCurrency.AddCurrency') }} </h5>
                            <h5 class="modal-title DayHeading" id="myModalLabel" v-else>{{ $t('AddCurrency.SetupDefaultCurrency') }}  </h5>

                        </div>
                        <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                            <div class="card-body ">
                                <div class="row ">
                                    <div v-if="english=='true'" class="col-sm-12" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                        <label>{{ $t('AddCurrency.CurrencyName') |englishLanguage}} :<span class="LabelColour"> *</span></label>
                                        <div v-bind:class="{'has-danger' : $v.currency.name.$error}">
                                            <input :disabled="currency.isDisable"  class="form-control" v-model="$v.currency.name.$model" type="text" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" />
                                            <span v-if="$v.currency.name.$error" class="error text-danger">
                                                <span v-if="!$v.currency.name.required">{{ $t('AddCurrency.NameRequired') }}</span>
                                                <span v-if="!$v.currency.name.maxLength">{{ $t('AddCurrency.NameLength') }}</span>
                                            </span>
                                        </div>
                                    </div>
                   
                                    <div v-if="isOtherLang()" class="form-group has-label col-sm-12  " v-bind:class="{'has-danger' : $v.currency.nameArabic.$error}">
                                        <label class="text  font-weight-bolder">{{ $t('AddCurrency.CurrencyName') |arabicLanguage}}: <span class="LabelColour"> *</span></label>
                                        <input :disabled="currency.isDisable" class="form-control "  v-bind:class="isLeftToRight() ? 'text-left' : 'arabicLanguage'"  v-model="$v.currency.nameArabic.$model" type="text" />
                                        <span v-if="$v.currency.nameArabic.$error" class="error">
                                            <span v-if="!$v.currency.nameArabic.required"> {{ $t('AddCurrency.NameRequired') }}</span>
                                            <span v-if="!$v.currency.nameArabic.maxLength">{{ $t('AddCurrency.NameLength') }}</span>
                                        </span>
                                    </div>



                                    <div v-if="english=='true' || (english=='false' && isOtherLang())" class="col-sm-12" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                        <label>{{ $t('AddCurrency.SIGN') }} :<span class="LabelColour"> *</span></label>
                                        <div v-bind:class="{'has-danger' : $v.currency.sign.$error}">
                                            <input :disabled="currency.isDisable" class="form-control" v-model="$v.currency.sign.$model" type="text" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" />
                                            <span v-if="$v.currency.sign.$error" class="error text-danger">
                                                <span v-if="!$v.currency.sign.required">{{ $t('AddCurrency.SignRequired') }}</span>
                                                <span v-if="!$v.currency.sign.maxLength">{{ $t('AddCurrency.SignMax') }}</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div v-if="isOtherLang() " class="col-sm-12" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                        <label>{{ $t('AddCurrency.ArabicSign') }} :<span class="LabelColour"> *</span></label>
                                        <div v-bind:class="{'has-danger' : $v.currency.arabicSign.$error}">
                                            <input :disabled="currency.isDisable" class="form-control" v-model="$v.currency.arabicSign.$model" type="text"  v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'arabicLanguage' : 'arabicLanguage'" />
                                            <span v-if="$v.currency.arabicSign.$error" class="error text-danger">
                                                <span v-if="!$v.currency.arabicSign.required">{{ $t('AddCurrency.SignRequired') }}</span>
                                                <span v-if="!$v.currency.arabicSign.maxLength">{{ $t('AddCurrency.SignMax') }}</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12" v-if="setup==undefined">
                                        <div :key="renderImg">
                                            <input ref="imgupload" type="file" 
                                                   id="file-input"
                                                   @change="uploadImage()" 
                                                   accept="image/*"
                                                   name="image" 
                                                   v-if="!((imageSrc == '' && currency.image!='') || (imageSrc != '' && currency.image=='') || (imageSrc != '' && currency.image!=''))"
                                                   style="opacity:1;padding:25px">

                                            <div class="text-right mt-3" v-if="imageSrc != ''">
                                                <img v-if="imageSrc != ''" class="float-right" :src="imageSrc"  width="100" />
                                            </div>
                                            <div v-else class="text-right mt-3">
                                                <span v-if="currency.image!=null && currency.image!=''">
                                                    <!--<productimage v-bind:path="currency.image" />-->
                                                    <img class="float-right" :src="'data:image/png;base64,' + currency.image" width="100" />
                                                </span>
                                            </div>                                            
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="text-right">
                                            <button class="btn btn-danger  btn-sm" v-if="imageSrc != '' || currency.image!=''" v-on:click="removeImage()">Remove</button>
                                        </div>
                                    </div>
                                    <div class="col-sm-2" v-if="setup==undefined">
                                        <label style="margin: 7px;">{{ $t('AddCurrency.Active') }}</label> <br />
                                        <div class="bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-animate" v-bind:class="{'bootstrap-switch-on': currency.isActive, 'bootstrap-switch-off': !currency.isActive}" v-on:click="currency.isActive = !currency.isActive" style="width: 72px;">
                                            <div class="bootstrap-switch-container" style="width: 122px; margin-left: 0px;">
                                                <span class="bootstrap-switch-handle-on bootstrap-switch-success" style="width: 50px;">
                                                    <i class="nc-icon nc-check-2"></i>
                                                </span>
                                                <span class="bootstrap-switch-label" style="width: 30px;">&nbsp;</span>
                                                <span class="bootstrap-switch-handle-off bootstrap-switch-success" style="width: 50px;">
                                                    <i class="nc-icon nc-simple-remove"></i>
                                                </span>
                                                <input class="bootstrap-switch" type="checkbox" data-toggle="switch" checked="" data-on-label="<i class='nc-icon nc-check-2'></i>" data-off-label="<i class='nc-icon nc-simple-remove'></i>" data-on-color="success" data-off-color="success">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer justify-content-right" v-if="type=='Edit' && isValid('CanEditCurrency')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">

                            <button type="button" class="btn btn-primary  " v-on:click="SaveCurrency" :disabled="$v.currency.$invalid"><i class="far fa-save"></i> {{ $t('AddCurrency.btnUpdate') }}</button>
                            <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{ $t('AddCurrency.btnClear') }}</button>

                        </div>
                        <div class="modal-footer justify-content-right" v-if="(type!='Edit' && isValid('CanAddCurrency')) || setup"  v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">

                            <button type="button" class="btn btn-primary  " v-on:click="SaveCurrency" :disabled="$v.currency.$invalid"><i class="far fa-save"></i> {{ $t('AddCurrency.btnSave') }}</button>
                            <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{ $t('AddCurrency.btnClear') }}</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </modal>

</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import {  maxLength, requiredIf } from "vuelidate/lib/validators";
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default ({
        mixins: [clickMixin],
        props: ['show', 'currency', 'type','setup'],
        data: function () {
            return {
                render: 0,
                imageSrc: '',
                arabic: '',
                english: '',
                stepsVm: {
                    companyId: '',
                    step1: false,
                    step2: false,
                    step3: false,
                    step4: false,
                },
                renderImg:0
            }
        },
        validations: {
            currency:
            {
                name:
                {
                    maxLength: maxLength(50)
                },
                nameArabic:
                {
                    required: requiredIf((x) => {
                        if (x.name == '' || x.name == null)
                            return true;
                        return false;
                    }),
                    maxLength: maxLength(50)
                },
                sign:
                {
                    maxLength: maxLength(10)
                },
                arabicSign:
                {
                    required: requiredIf((x) => {
                        if (x.sign == '' || x.sign == null)
                            return true;
                        return false;
                    }),
                    maxLength: maxLength(10)
                }
            }
        },
        methods: {
            removeImage: function () {
                this.imageSrc = '';
                this.currency.image = '';
                this.renderImg++;

            },
            uploadImage: function () {
                var root = this;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                var file = this.$refs.imgupload.files;

                var fileData = new FormData();

                for (var k = 0; k < file.length; k++) {
                    fileData.append("files", file[k]);
                }

                this.imageSrc = URL.createObjectURL(this.$refs.imgupload.files[0]);

                root.$https.post('/Company/UploadFilesAsync', fileData, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {

                            root.currency.image = response.data;
                        }
                    },
                        function () {
                            this.loading = false;
                            root.$swal({
                                title: root.$t('AddCurrency.Error'),
                                text: root.$t('AddCurrency.SomethingWrong'),
                                type: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                        });
            },

            close: function () {
                this.$emit('close');
            },
            SaveCurrency: function () {
                var root = this;
                
                var url = '/Product/SaveCurrency';
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                root.$https
                    .post(url, root.currency, { headers: { "Authorization": `Bearer ${token}` } })
                   .then(function (response) {
                    if (response.data.isSuccess == true) {
                        if (root.type != "Edit") {
                            root.$store.state.currencyList.push({
                                id: response.data.currency.id,
                                name: response.data.currency.name,
                                nameArabic: response.data.currency.nameArabic,
                                sign: response.data.currency.sign,
                                arabicSign: response.data.currency.arabicSign,
                                image: response.data.currency.image,
                                isActive: response.data.currency.isActive
                            })
                            root.$swal({
                                text: root.$t('AddCurrency.Saved'),
                                title: root.$t('AddCurrency.SavedSuccessfully'),
                                type: 'success',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 800,
                                timerProgressBar: true,
                            });
                            root.close();
                            if (root.setup) {
                                root.$emit('CurrencySave', true);
                                localStorage.setItem('currency', response.data.currency.sign);
                            }
                        }
                        else {
                            var data = root.$store.state.currencyList.find(function (x) {
                                return x.id == response.data.currency.id;
                            });
                            data.id = response.data.currency.id;
                            data.name = response.data.currency.name;
                            data.nameArabic = response.data.currency.nameArabic;
                            data.sign = response.data.currency.sign;
                            data.arabicSign = response.data.currency.arabicSign;
                            data.isActive = response.data.currency.isActive;
                            root.$swal({
                                title: root.$t('Updated'),
                                text: root.$t('UpdateSuccessfully'),
                                type: 'success',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 800,
                                timerProgressBar: true,
                            });
                            root.close();
                        }
                    }
                    else {
                        root.$swal({
                            title: root.$t('Error'),
                            text: root.$t('NameAlreadyExist'),
                            type: 'error',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 800,
                            timerProgressBar: true,
                        });
                    }
                });
            }
        },
        mounted: function () {
            
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            if (this.$route.query.data != undefined) {
                this.currency = this.$route.query.data;
            }
        }
    })
    
</script>