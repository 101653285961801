<template>
    <div class="row" v-if="isValid('CanViewTerminal')|| isValid('Noble Admin')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
        <div class="col-lg-12 col-sm-12 ml-auto mr-auto">
            <div class="row mb-4" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                <div class="col-sm-6 col-md-6 col-lg-6">
                    <h5 class="page_title DayHeading">{{ $t('Terminal.Terminal') }}</h5>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link :to="'/StartScreen'"><a href="javascript:void(0)"> {{ $t('Terminal.Home') }}</a></router-link></li>

                            <li class="breadcrumb-item active" aria-current="page">{{ $t('Terminal.Terminal') }}</li>

                        </ol>
                    </nav>
                </div>
                <div class=" col-sm-6 col-md-6 col-lg-6">
                    <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                        <a href="javascript:void(0)" v-if="isValid('CanAddTerminal')" class="btn btn-primary  " v-on:click="openmodel"><i class="fa fa-plus"></i> {{ $t('Terminal.AddTerminal') }}</a>
                        <router-link :to="'/StartScreen'"><a href="javascript:void(0)" class="btn btn-outline-danger "> {{ $t('Terminal.Close') }}</a></router-link>
                    </div>

                </div>
            </div>
            <div class="card ">
                <div class="card-header">
                    <div class="row mb-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label>{{ $t('Terminal.SearchbyName')}}</label>
                                <div>
                                    <input type="text" class="form-control search_input" v-model="searchQuery" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" name="search" id="search" :placeholder="$t('Terminal.Search')" />
                                    <span class="fas fa-search search_icon"></span>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="row">


                        <div class="col-lg-12">
                            <div>
                                <table class="table table_list_bg">
                                    <thead class="">
                                        <tr>
                                            <th>#</th>
                                            <th>
                                                {{ $t('Terminal.Code') }}
                                            </th>
                                            <th>
                                                {{ $t('Terminal.Printer') }}
                                            </th>
                                            <!--<th>
                                                {{ $t('Terminal.MACAddress') }}
                                            </th>
                                            <th>
                                                {{ $t('Terminal.IPAddress') }}
                                            </th>-->
                                            <th>
                                                {{ $t('Terminal.Status') }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(terminal,index) in resultQuery" v-bind:key="terminal.id">
                                            <td>
                                                {{index+1}}
                                            </td>

                                            <td v-if="isValid('CanEditTerminal') || isValid('Noble Admin')">
                                                <strong>
                                                    <a href="javascript:void(0)" v-on:click="EditTerminal(terminal.id)">{{terminal.code}}</a>
                                                </strong>
                                            </td>
                                            <td v-else>
                                                {{terminal.code}}
                                            </td>
                                            <td>
                                                {{terminal.printerName}}
                                            </td>
                                            <!--<td>
                                                {{terminal.macAddress}}
                                            </td>
                                            <td>
                                                {{terminal.ipAddress}}
                                            </td>-->
                                        <td>

                                            {{(terminal.isActive == true?$t('Terminal.Active'):$t('Terminal.De-Active'))}}
                                        </td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <terminalmodel :terminal="newTerminal"
                       :show="show"
                       v-if="show"
                       @close="show = false"
                       :type="type" />

    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>

<script>
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        data: function () {
            return {
                searchQuery: '',
                show: false,
                terminallist: [],
                newTerminal: {
                    id: '',
                    code: '',
                    printerName: '',
                    macAddress: '',
                    accountId: '',
                    ipAddress: '',
                    isActive: false,
                    cashAccountId: '',
                    posTerminalId: '',
                    terminalType: '',
                    onPageLoadItem: false,
                    companyNameEnglish: '',
                    businessNameEnglish: '',
                    businessTypeEnglish: '',
                    companyNameArabic: '',
                    businessNameArabic: '',
                    businessTypeArabic: '',
                    businessLogo: '',
                    overWrite:false
                },
                type: '',
            }
        },
        computed: {
            resultQuery: function () {
                var root = this;
                if (this.searchQuery) {
                    return this.terminallist.filter((terminal) => {
                        return root.searchQuery.toLowerCase().split(' ').every(v => terminal.code.toLowerCase().includes(v))
                    })
                } else {
                    return root.terminallist;
                }
            },
        },
        methods: {
            openmodel: function () {
                this.newTerminal = {
                    id: '00000000-0000-0000-0000-000000000000',
                    code: '',
                    printerName: '',
                    macAddress: '',
                    ipAddress: '',
                    accountId: '',
                    posTerminalId: '',
                    isActive: false,
                    onPageLoadItem: false,
                    companyId: this.companyId,
                    companyNameEnglish: '',
                    businessNameEnglish: '',
                    businessTypeEnglish: '',
                    companyNameArabic: '',
                    businessNameArabic: '',
                    businessTypeArabic: '',
                    businessLogo: '',
                    overWrite: false,
                }
                //if (this.overWrite == 'true') {

                //}
                
                this.show = !this.show;
                this.type = "Add";
            },
            GetTerminalData: function (companyId) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('Company/TerminalList?companyId=' + companyId, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.$store.dispatch('GetTerminalList', response.data.terminals);
                        root.terminallist = response.data.terminals;
                    }
                });
            },
            EditTerminal: function (terminal) {


                if (terminal.macAddress == null) {
                    var root = this;

                    var token = '';
                    if (this.$session.exists()) {
                        token = localStorage.getItem('token');
                    }
                    root.$https.get('/Company/TerminalDetail?Id=' + terminal + '&companyId=' + this.companyId, { headers: { "Authorization": `Bearer ${token}` } })
                        .then(function (response) {
                            if (response.data) {
                                
                                root.newTerminal.id = response.data.id;

                                root.newTerminal.ipAddress = response.data.ipAddress;
                                root.newTerminal.macAddress = response.data.macAddress;
                                root.newTerminal.code = response.data.code;
                                root.newTerminal.accountId = response.data.accountId;
                                root.newTerminal.isActive = response.data.isActive;
                                root.newTerminal.cashAccountId = response.data.cashAccountId;
                                root.newTerminal.posTerminalId = response.data.posTerminalId;
                                root.newTerminal.printerName = response.data.printerName;
                                root.newTerminal.onPageLoadItem = response.data.onPageLoadItem;
                                root.newTerminal.companyNameEnglish = response.data.companyNameEnglish;
                                root.newTerminal.businessNameEnglish = response.data.businessNameEnglish;
                                root.newTerminal.businessTypeEnglish = response.data.businessTypeEnglish;
                                root.newTerminal.companyNameArabic = response.data.companyNameArabic;
                                root.newTerminal.businessNameArabic = response.data.businessNameArabic;
                                root.newTerminal.businessTypeArabic = response.data.businessTypeArabic;
                                root.newTerminal.businessLogo = response.data.businessLogo == null ? '' : response.data.businessLogo;
                                root.newTerminal.categoryIdList = response.data.categoryIdList;
                                root.newTerminal.companyId = root.companyId;
                                root.newTerminal.overWrite = response.data.overWrite;
                                if (response.data.terminalType == 1)
                                    root.newTerminal.terminalType = 'Terminal';
                                else if (response.data.terminalType == 2)
                                    root.newTerminal.terminalType = 'CashCounter';
                                else
                                    root.newTerminal.terminalType = '';
                                root.show = !root.show;
                                root.type = "Edit"
                            } else {
                                console.log("error: something wrong from db.");
                            }
                        },
                            function (error) {
                                this.loading = false;
                                console.log(error);
                            });

                }

            }
        },
        created: function () {
            this.$emit('input', this.$route.name);
        },
        mounted: function () {
            if (this.$route.query.id != undefined) {
                this.companyId = this.$route.query.id;
                this.overWrite = this.$route.query.option;
                this.GetTerminalData(this.$route.query.id);
            } else {
                this.companyId = '';
                this.GetTerminalData('');
            }
        }
    }
</script>