<template>
    <modal :show="show">

        <div style="margin-bottom:0px" class="card" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <div class="card-body">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="modal-header" v-if="type=='Edit'">

                            <h5 class="modal-title DayHeading" id="myModalLabel"> {{ $t('AddQuickEmployee.UpdateEmployee') }}</h5>

                        </div>
                        <div class="modal-header" v-else>

                            <h5 class="modal-title DayHeading" id="myModalLabel"> {{ $t('AddQuickEmployee.AddEmployee') }}</h5>

                        </div>
                        <div class="">
                            <div class="card-body">
                                <div class="row ">
                                    <div :key="render" class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.employee.code.$error}">
                                        <label class="text  font-weight-bolder"> {{ $t('AddQuickEmployee.EmployeeCode') }}: <span class="LabelColour"> *</span></label>
                                        <input disabled class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.employee.code.$model" type="text" />
                                        <span v-if="$v.employee.code.$error" class="error">

                                        </span>
                                    </div>


                                    <div v-if="english=='true'" class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.employee.englishName.$error}">
                                        <label class="text  font-weight-bolder"> {{ $t('AddQuickEmployee.EmployeeName(English)') |englishLanguage}}:<span class="LabelColour"> *</span> </label>
                                        <input class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.employee.englishName.$model" type="text" />
                                        <span v-if="$v.employee.englishName.$error" class="error text-danger">
                                            <span v-if="!$v.employee.englishName.required">{{ $t('AddQuickEmployee.EngValidation') }}</span>
                                            <span v-if="!$v.employee.englishName.maxLength">{{ $t('AddQuickEmployee.EngMax') }}</span>

                                        </span>
                                    </div>

                                    <div v-if="isOtherLang()" class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.employee.arabicName.$error}">
                                        <label class="text  font-weight-bolder"> {{ $t('AddQuickEmployee.EmployeeName(Arabic)') |arabicLanguage}}:<span class="LabelColour"> *</span> </label>
                                        <input class="form-control "  v-bind:class="isLeftToRight() ? 'text-left' : 'arabicLanguage'"  v-model="$v.employee.arabicName.$model" type="text" />
                                        <span v-if="$v.employee.arabicName.$error" class="error text-danger arabicLanguage">
                                            <span class="arabicLanguage" v-if="!$v.employee.arabicName.required">{{ $t('AddQuickEmployee.ArValidation') }}</span>
                                            <span class="arabicLanguage" v-if="!$v.employee.arabicName.maxLength">{{ $t('AddQuickEmployee.ArMax') }}</span>
                                        </span>
                                    </div>
                                    <div class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.employee.email.$error}">
                                        <label class="text  font-weight-bolder"> {{ $t('AddQuickEmployee.Email') }}:<span class="LabelColour"> *</span> </label>
                                        <input class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" @blur="EmailDuplicate(employee.email)" v-model="$v.employee.email.$model" type="text" />
                                        <span v-if="$v.employee.email.$error" class="error text-danger arabicLanguage">
                                            <span class="arabicLanguage" v-if="!$v.employee.email.required">{{ $t('AddQuickEmployee.EmailRequired') }}</span>
                                            <span class="arabicLanguage" v-if="!$v.employee.email.email">{{ $t('AddQuickEmployee.EmailInvalid') }}</span>

                                        </span>
                                    </div>


                                    <div class="col-sm-6">
                                        <label>{{ $t('AddQuickEmployee.EmGender') }} :<span class="LabelColour"> *</span></label>
                                        <div v-bind:class="{'has-danger' : $v.employee.gender.$error}">
                                            <link rel="stylesheet" href="https://unpkg.com/vue-multiselect@2.1.0/dist/vue-multiselect.min.css">
                                            <multiselect v-if="($i18n.locale == 'en' ||isLeftToRight())" v-model="$v.employee.gender.$model" :options="['Male', 'Fe-Male', 'Other']" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" :show-labels="false" :placeholder="$t('AddQuickEmployee.SelectGender')">
                                            </multiselect>
                                            <multiselect v-else v-model="$v.employee.gender.$model" :options="['ذكر', 'أنثى', 'آخر']" :show-labels="false" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" :placeholder="$t('AddQuickEmployee.SelectGender')">
                                            </multiselect>
                                            <span v-if="$v.employee.gender.$error" class="error text-danger">
                                                <span v-if="!$v.employee.gender.required">{{ $t('AddQuickEmployee.GValidation') }}</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label>{{ $t('AddQuickEmployee.RegistrationDate') }} :<span class="LabelColour"> *</span></label>
                                        <div v-bind:class="{'has-danger' : $v.employee.registrationDate.$error}">
                                            <datepicker v-model="$v.employee.registrationDate.$model" :key="daterander"></datepicker>
                                            <span v-if="$v.employee.registrationDate.$error" class="error text-danger">
                                                <span v-if="!$v.employee.englishName.required">{{ $t('AddQuickEmployee.RegistrationValidation') }}</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label>{{ $t('AddQuickEmployee.IDNumber') }}:<span class="LabelColour"> *</span></label>
                                        <div v-bind:class="{'has-danger' : $v.employee.idNumber.$error}">
                                            <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" type="text" v-model="$v.employee.idNumber.$model" />
                                            <span v-if="$v.employee.idNumber.$error" class="error text-danger">
                                                <span v-if="!$v.employee.idNumber.required">{{ $t('AddQuickEmployee.IDRequired') }}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer justify-content-right" v-if="type=='Edit'">

                            <button type="button" class="btn btn-primary  " v-on:click="SaveEmployee" v-bind:disabled="$v.employee.$invalid"> {{ $t('AddQuickEmployee.btnUpdate') }}</button>
                            <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{ $t('AddQuickEmployee.btnClear') }}</button>

                        </div>
                        <div class="modal-footer justify-content-right" v-else>

                            <button type="button" class="btn btn-primary  " v-on:click="SaveEmployee" v-bind:disabled="$v.employee.$invalid"> {{ $t('AddQuickEmployee.btnSave') }}</button>
                            <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{ $t('AddQuickEmployee.btnClear') }}</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </modal>
</template>
<script>

    import 'vue-loading-overlay/dist/vue-loading.css';
    import Multiselect from 'vue-multiselect';
    import moment from 'moment';
    import { required, maxLength, email, requiredIf } from "vuelidate/lib/validators"
    export default {
        components: {
            Multiselect,
        },
        props: ['show', 'employee', 'type'],
        data: function () {
            return {
                arabic: '',
                english: '',
                render: 0,
                daterander: 0,
                emailExist: false
            }
        },
        validations: {
            employee: {
                code: { required },
                email: {
                    required,
                    email,

                },
                registrationDate: { required },
                englishName: {
                    maxLength: maxLength(30)
                },
                arabicName: {
                    required: requiredIf((x) => {
                        if (x.englishName == '' || x.englishName == null)
                            return true;
                        return false;
                    }),
                    maxLength: maxLength(40)
                },
                gender: { required },
                idNumber: { required },
            }
        },
        methods: {
            EmailDuplicate: function (x) {
                var root = this;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.get('/account/DuplicateEmail?email=' + x, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data == true) {
                            root.emailExist = true;
                            root.$swal({
                                title: "Error!",
                                text: "Email Already Exist!",
                                type: 'error',
                                icon: 'error',
                                showConfirmButton: false,
                                timer: 1700,
                                timerProgressBar: true,
                            });
                        }
                        else {
                            root.emailExist = false;
                        }


                    })
            },
            close: function () {
                this.$emit('close');
            },
            GetAutoCodeGenerator: function () {

                var root = this;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https
                    .get('/EmployeeRegistration/EmployeeCode', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                        if (response.data != null) {
                            root.employee.code = response.data;
                        }
                    });
            },
            SaveEmployee: function () {
                
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                if (this.emailExist) {
                    this.$swal({
                        title: "Error!",
                        text: "Email Already Exist!",
                        type: 'error',
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 1700,
                        timerProgressBar: true,
                    });
                }
                else {


                    this.$https.post('/EmployeeRegistration/SaveEmployee', this.employee, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                        if (response.data.isSuccess == true) {

                            if (root.type != "Edit") {
                                root.$store.state.employeeList.push({
                                    id: response.data.employee.id,
                                    englishName: response.data.employee.englishName,
                                    arabicName: response.data.employee.arabicName,
                                    gender: response.data.employee.gender,
                                    idNumber: response.data.employee.idNumber,
                                    code: response.data.employee.code,
                                    registrationDate: response.data.employee.registrationDate,
                                })
                                root.$swal({
                                    title: "Saved!",
                                    text: "Your Employee " + response.data.employee.englishName + " has been created!",
                                    type: 'success',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 1500,
                                    timerProgressBar: true,
                                });
                                root.close();
                            }

                        }
                        else {
                            root.$swal({
                                title: "Error!",
                                text: "Your Employee Name  Already Exist!",
                                type: 'error',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1500,
                                timerProgressBar: true,
                            });
                        }
                    });
                }
            }
        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            if (this.employee.id == '00000000-0000-0000-0000-000000000000' || this.employee.id == undefined || this.employee.id == '')
                this.GetAutoCodeGenerator();
            this.employee.registrationDate = moment().format('llll');
            this.daterander++;

        }
    }
</script>
