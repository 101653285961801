<template>
    <div>
        <multiselect v-model="DisplayValue" :options="options" :disabled="disabled" :multiple="false" v-bind:placeholder="$t('UnitDropdown.SelectUnit')" track-by="name" :clear-on-select="false" :show-labels="false" label="name" :preselect-first="true" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left ' : 'arabicLanguage '">
            <!--<p slot="noResult" class="text-danger"> Oops! No Unit found.</p>-->
            <span slot="noResult" class="btn btn-primary " v-on:click="AddUnit('Add')" v-if=" isValid('CanAddUnit') ">{{ $t('UnitDropdown.AddProductUnit') }}</span><br />
        </multiselect>
        <modal :show="show" v-if="show">
            <div style="margin-bottom:0px" class="card">
                <div class="card-body">
                    <div class="col-lg-12">
                        <div class="tab-content" id="nav-tabContent">
                            <div class="modal-header" v-if="type=='Edit'">
                                <h5 class="modal-title" id="myModalLabel">{{ $t('UnitDropdown.UpdateProductUnit') }} </h5>
                            </div>
                            <div class="modal-header" v-else>
                                <h5 class="modal-title" id="myModalLabel"> {{ $t('UnitDropdown.AddProductUnit') }}</h5>
                            </div>
                            <div >
                                <div class="card-body ">
                                    <div class="card-body">
                                        <div class="row ">
                                            <div :key="render" class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.unit.code.$error}">
                                                <label class="text  font-weight-bolder"> {{ $t('UnitDropdown.Code') }}:<span class="LabelColour"> *</span></label>
                                                <input disabled class="form-control" v-model="$v.unit.code.$model" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" type="text" />
                                                <span v-if="$v.unit.code.$error" class="error">
                                                    <span v-if="!$v.unit.code.maxLength">{{ $t('UnitDropdown.CodeLength') }}</span>
                                                </span>
                                            </div>
                                            <div v-if="english=='true'" class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.unit.name.$error}">
                                                <label class="text  font-weight-bolder">  {{ $t('UnitDropdown.UnitName') |englishLanguage}}: <span class="LabelColour"> *</span></label>
                                                <input class="form-control" v-model="$v.unit.name.$model" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" type="text" />
                                                <span v-if="$v.unit.name.$error" class="error">
                                                    <span v-if="!$v.unit.name.required">{{ $t('UnitDropdown.NameRequired') }}</span>
                                                    <span v-if="!$v.unit.name.maxLength">{{ $t('UnitDropdown.NameLength') }}</span>
                                                </span>
                                            </div>
                                            <div v-if="isOtherLang()" class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.unit.nameArabic.$error}">
                                                <label class="text  font-weight-bolder">  {{ $t('UnitDropdown.UnitNameAr') |arabicLanguage}}: <span class="LabelColour"> *</span></label>
                                                <input class="form-control " v-bind:class="isLeftToRight() ? 'text-left' : 'arabicLanguage'"  v-model="$v.unit.nameArabic.$model"  type="text" />
                                                <span v-if="$v.unit.nameArabic.$error" class="error">
                                                    <span v-if="!$v.unit.nameArabic.required">{{ $t('UnitDropdown.NameRequired') }}</span>
                                                    <span v-if="!$v.unit.nameArabic.maxLength">{{ $t('UnitDropdown.NameLength') }}</span>
                                                </span>
                                            </div>


                                            <div class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.unit.description.$error}">
                                                <label class="text  font-weight-bolder"> {{ $t('UnitDropdown.Description') }}: </label>
                                                <textarea class="form-control" v-model="$v.unit.description.$model" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" type="text" />
                                                <span v-if="$v.unit.description.$error" class="error">{{ $t('UnitDropdown.descriptionLength') }}</span>
                                            </div>
                                            <div class="form-group col-md-12">
                                                <label style="margin: 7px;">{{ $t('UnitDropdown.Active') }}</label> <br />
                                                <div class="bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-animate" v-bind:class="{'bootstrap-switch-on': unit.isActive, 'bootstrap-switch-off': !unit.isActive}" v-on:click="unit.isActive = !unit.isActive" style="width: 72px;">
                                                    <div class="bootstrap-switch-container" style="width: 122px; margin-left: 0px;">
                                                        <span class="bootstrap-switch-handle-on bootstrap-switch-success" style="width: 50px;">
                                                            <i class="nc-icon nc-check-2"></i>
                                                        </span>
                                                        <span class="bootstrap-switch-label" style="width: 30px;">&nbsp;</span>
                                                        <span class="bootstrap-switch-handle-off bootstrap-switch-success" style="width: 50px;">
                                                            <i class="nc-icon nc-simple-remove"></i>
                                                        </span>
                                                        <input class="bootstrap-switch" type="checkbox" data-toggle="switch" checked="" data-on-label="<i class='nc-icon nc-check-2'></i>" data-off-label="<i class='nc-icon nc-simple-remove'></i>" data-on-color="success" data-off-color="success">
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="!loading">
                                <div class="modal-footer justify-content-right" v-if="type=='Edit'">
                                    <button type="button" class="btn btn-primary  " v-on:click="SaveUnit" v-bind:disabled="$v.unit.$invalid"> Update</button>
                                    <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">Cancel</button>
                                </div>
                                <div class="modal-footer justify-content-right" v-else>
                                    <button type="button" class="btn btn-primary  " v-on:click="SaveUnit" v-bind:disabled="$v.unit.$invalid"> {{ $t('UnitDropdown.btnSave') }}</button>
                                    <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{ $t('UnitDropdown.btnClear') }}</button>
                                </div>
                            </div>
                            <div v-else>
                                <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
    import { required, maxLength, requiredIf } from "vuelidate/lib/validators";
    import Multiselect from 'vue-multiselect'
    import clickMixin from '@/Mixins/clickMixin'
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: 'unitdropdown',
        props: ["values", 'disabled'],
        mixins: [clickMixin],

        components: {
            Multiselect,
            Loading
        },
        data: function () {
            return {
                arabic: '',
                english: '',
                options: [],
                value: '',
                show: false,
                type: '',
                unit: {
                    id: '00000000-0000-0000-0000-000000000000',
                    code: '',
                    name: '',
                    nameArabic: '',
                    description: '',
                    isActive: true
                },
                render: 0,
                loading: false,
            }
        },
        validations: {
            unit: {
                name: {
                    maxLength: maxLength(20)
                },
                nameArabic: {
                    required: requiredIf((x) => {
                        if (x.name == '' || x.name == null)
                            return true;
                        return false;
                    }),
                    maxLength: maxLength(20)
                },
                code: { required },
                description: {}
            }
        },
        methods: {
            getData: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                this.$https.get('/Product/UnitList?isActive=true', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {


                    if (response.data != null) {
                        response.data.results.units.forEach(function (cat) {

                            root.options.push({
                                id: cat.id,
                                name: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? (cat.name != '' && cat.name != null) ? cat.code + ' ' + cat.name : cat.code + ' ' + cat.nameArabic : (cat.nameArabic != '' && cat.nameArabic != null) ? cat.code + ' ' + cat.nameArabic : cat.code + ' ' + cat.name
                            })
                        })
                    }
                }).then(function () {
                    root.value = root.options.find(function (x) {
                        return x.id == root.values;
                    })
                });
            },
            AddUnit: function (type) {
                this.$v.$reset();
                this.GetAutoCodeGenerator();
                this.unit = {
                    id: '00000000-0000-0000-0000-000000000000',
                    code: '',
                    name: '',
                    nameArabic: '',
                    description: '',
                    isActive: true
                }

                this.show = !this.show;
                this.type = type;
            },
            close: function () {
                this.show = false;
            },
            GetAutoCodeGenerator: function () {

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.get('/Product/UnitCode', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                    if (response.data != null) {
                        root.unit.code = response.data;
                        root.render++;
                    }
                });
            },
            SaveUnit: function () {
                var root = this;
                this.loading = true;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.post('/Product/SaveUnit', this.unit, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                    if (response.data.isSuccess == true) {
                        if (root.type != "Edit") {
                            root.$store.state.unitList.push({
                                id: response.data.unit.id,
                                name: response.data.unit.name,
                                nameArabic: response.data.unit.nameArabic,
                                description: response.data.unit.description,
                                code: response.data.unit.code,
                            })
                            root.loading = false
                            root.options.push({
                                id: response.data.id,
                                code: root.unit.code,
                                name: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? (root.unit.name != '' && root.unit.name != null) ? root.unit.code + ' ' + root.unit.name : root.unit.code + ' ' + root.unit.nameArabic : (root.unit.nameArabic != '' && root.unit.nameArabic != null) ? root.unit.code + ' ' + root.unit.nameArabic : root.unit.code + ' ' + root.unit.name,
                                
                                nameArabic: root.unit.nameArabic,
                                description: root.unit.description
                            })
                            root.$swal({
                                icon: 'success',
                                title: 'Saved Successfully!',
                                showConfirmButton: false,
                                timer: 1500,
                                timerProgressBar: true,
                            });
                            root.show = false;
                        }
                        else {
                            var data = root.$store.state.unitList.find(function (x) {
                                return x.id == response.data.unit.id;
                            });
                            data.id = response.data.unit.id;
                            data.name = (root.$i18n.locale == 'en' || root.isLeftToRight()) ? (response.data.unit.name != '' && response.data.unit.name != null) ? response.data.unit.code + ' ' + response.data.unit.name : response.data.unit.code + ' ' + response.data.unit.nameArabic : (response.data.unit.nameArabic != '' && response.data.unit.nameArabic != null) ? response.data.unit.code + ' ' + response.data.unit.nameArabic : response.data.unit.code + ' ' + response.data.unit.name,
                            data.nameArabic = response.data.unit.nameArabic;
                            data.description = response.data.unit.description;
                            data.code = response.data.unit.code;
                            root.$swal({
                                title: "Update!",
                                text: "Your Unit " + response.data.unit.name + " has been updated!",
                                type: 'success',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1000,
                                timerProgressBar: true,

                            });
                            root.close();
                        }
                    }
                    else {
                        root.$swal({
                            title: "Error!",
                            text: "Your Unit Name  Already Exist!",
                            type: 'error',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 1500,
                            timerProgressBar: true,

                        });
                    }
                    })
                    .catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Something Went Wrong!',
                                text: error.response.data,
                                type: 'error',
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false
                    })
                    .finally(() => root.loading = false);
            }
        },
        computed: {
            DisplayValue: {
                get: function () {
                    if (this.value != "" || this.value != undefined) {
                        return this.value;
                    }
                    return this.values;
                },
                set: function (value) {
                    this.value = value;
                    this.$emit('input', value.id);
                }
            }
        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.getData();
        },
    }
</script>