<template>
    <div v-if="isValid('CanEditServiceQuotation') || isValid('CanDraftServiceQuotation') || isValid('CanAddServiceQuotation')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
        <div class="row">
            <div class="col-lg-12 top_auto_no" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'top_auto_no_ar'">
                {{purchase.date}}
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-lg-8">
                <h5 class="banner_btm_pd" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'banner_btm_pd_ar'" v-if="purchase.id == '00000000-0000-0000-0000-000000000000'">
                    {{ $t('AddQuotation.QuotationNo') }}
                    <span>{{purchase.registrationNo}}</span>
                </h5>
                <h5 class="banner_btm_pd" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'banner_btm_pd_ar'" v-else>
                    <span>{{ $t('AddQuotation.UpdateServiceQuotation') }} {{purchase.registrationNo}}</span>
                </h5>
                <div class="card invoice_top_bg">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-7" style="margin-bottom: auto; margin-top: auto;">
                                <h5 class="text-white title_heading" v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'text-align: right !important;'">{{ $t('AddQuotation.AddServiceQuotation') }}</h5>
                                <p class="text-white txt_description" v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'text-align: right !important;'">{{ $t('AddQuotation.QuotationDescription') }}</p>
                            </div>
                            <div class="col-lg-5 text-right">
                                <img class="inv_main_img" src="/images/make-invoice1.png" alt="Alternate Text" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-2">
                <div class="card payment_card">
                    <div class="card-body">
                        <div class="row pd_payment_methd">
                            <div class="col-lg-12 text-center">
                                <img class="payment_card_img" src="/images/walletpayment.png" alt="Alternate Text" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 mt-2">
                                <p class="payment_method" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'text-right'" style="margin-bottom: 0 !important;">{{ $t('AddQuotation.CashPayment') }}</p>
                                <button v-on:click="isCredit(false)" type="button" v-bind:class="{ bt_bg_color: !purchase.isCredit }" class="btn btn-outline-primary btn-block btn-sm payment_method_btn">{{ $t('AddQuotation.Cash') }} <img src="/images/Left_arrow2.png" v-bind:class="{ payment_method_btn_img: !purchase.isCredit }" alt="Alternate Text" /> </button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-2">
                <div class="card payment_card">
                    <div class="card-body">
                        <div class="row pd_payment_methd">
                            <div class="col-lg-12 text-center">
                                <img class="payment_card_img" src="/images/cardpayment.png" alt="Alternate Text" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 mt-2">
                                <p class="payment_method" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'text-right'" style="margin-bottom: 0 !important;">{{ $t('AddQuotation.CreditPayment') }}</p>
                                <button v-on:click="isCredit(true)" type="button" v-bind:class="{ bt_bg_color: purchase.isCredit }" class="btn btn-outline-primary btn-block btn-sm payment_method_btn">{{ $t('AddQuotation.Credit') }} <img src="/images/Left_arrow2.png" v-bind:class="{ payment_method_btn_img: purchase.isCredit }" alt="Alternate Text" /> </button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-12">



                        <div class="row mt-3">
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 ">
                                <label>{{ $t('AddQuotation.Customer') }} :<span class="LabelColour"> *</span></label>
                                <div v-bind:class="{'has-danger': $v.purchase.customerId.$error,}">
                                    <customerdropdown v-model="$v.purchase.customerId.$model" :paymentTerm="purchase.paymentMethod" ref="CustomerDropdown" v-on:input="getAddress" v-bind:values="purchase.customerId" :key="randerCustomer" />
                                    <span v-if="$v.purchase.customerId.$error"
                                          class="error text-danger">
                                    </span>
                                </div>
                            </div>

                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <label>{{ $t('AddQuotation.CustomerId') }} :</label>
                                <input v-bind:disabled="true" class="form-control" v-model="purchase.cashCustomerId" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" type="text" />
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <label class="text  font-weight-bolder"> {{ $t('AddQuotation.RegisterUser_EmailID') }}:</label>
                                <input v-bind:disabled="true" class="form-control" v-model="purchase.email" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" type="text" />
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <label>{{ $t('AddQuotation.Mobile') }}  :</label>
                                <input v-bind:disabled="true" class="form-control" v-model="purchase.mobile" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" type="text" />

                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <label>{{ $t('AddQuotation.CustomerAddress') }}  :</label>
                                <input v-model="purchase.customerAddressWalkIn" v-bind:disabled="true" class="form-control" />
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 ">
                                <label>{{ $t('AddQuotation.Refrence') }} :</label>
                                <div v-bind:class="{'has-danger': $v.purchase.refrence.$error,}">
                                    <input class="form-control" style="height:42px;" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'"
                                           v-model="$v.purchase.refrence.$model" />
                                    <span v-if="$v.purchase.refrence.$error"
                                          class="error text-danger">
                                    </span>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 ">
                                <label>{{ $t('AddQuotation.ClientPurchaseNo') }} :</label>
                                <input class="form-control" style="height:42px;" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'"
                                       v-model="purchase.clientPurchaseNo" />
                            </div>

                            <div class="col-9">
                                <div class="row">
                                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                                        <label>Customer Inquiry No  :</label>
                                        <input class="form-control" />
                                    </div>
                                    <!--<div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                                        <label>  Transporter  :</label>
                                        <logisticDropdown />
                                    </div>-->

                                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                                        <label>Delivery Date :</label>
                                        <input class="form-control" />
                                    </div>
                                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                                        <label>Delivery Address :</label>
                                        <input class="form-control" />
                                    </div>
                                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                                        <label>Billing Address :</label>
                                        <input class="form-control" />
                                    </div>
                                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                                        <label>Sale Person :</label>
                                        <input class="form-control" />
                                    </div>
                                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                                        <label>Refered By :</label>
                                        <input class="form-control" />
                                    </div>



                                    <div v-if="saleDefaultVat=='DefaultVatHead' || saleDefaultVat=='DefaultVatHeadItem'" class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-bind:class=" ($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                        <label>{{ $t('AddQuotation.TaxMethod') }} :<span class="LabelColour"> *</span></label>
                                        <multiselect v-if="($i18n.locale == 'en' ||isLeftToRight())" :options="['Inclusive', 'Exclusive']" v-bind:disabled="purchase.saleOrderItems.length>0" v-model="purchase.taxMethod" :show-labels="false" v-bind:placeholder="$t('AddStockValue.SelectMethod')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                        </multiselect>
                                        <multiselect v-else :options="['شامل', 'غير شامل']" v-bind:disabled="purchase.saleOrderItems.length>0" v-model="purchase.taxMethod" :show-labels="false" v-bind:placeholder="$t('AddStockValue.SelectMethod')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                        </multiselect>
                                    </div>
                                    <div v-if="saleDefaultVat=='DefaultVatHead' || saleDefaultVat=='DefaultVatHeadItem'" class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                        <label>{{ $t('AddQuotation.VAT%') }} :<span class="LabelColour"> *</span></label>
                                        <taxratedropdown v-model="purchase.taxRateId" v-bind:value="purchase.taxRateId" :isDisable="purchase.saleOrderItems.length>0? true :false" />
                                    </div>
                                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 " v-if="purchase.paymentMethod=='Cash'">
                                        <label>{{ $t('AddQuotation.SheduleDelivery') }} :</label>
                                        <div>
                                            <multiselect v-if="($i18n.locale == 'en' ||isLeftToRight())" v-model="purchase.sheduleDelivery" :options="['Advance', 'After Delivery']" :show-labels="false" v-bind:placeholder="$t('SelectOption')">
                                            </multiselect>
                                            <multiselect v-else v-model="purchase.sheduleDelivery" :options="['تقدم', 'بعد الولادة']" :show-labels="false" v-bind:placeholder="$t('AddQuotation.SelectOption')">
                                            </multiselect>

                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 " v-if="purchase.paymentMethod=='Cash' && (purchase.sheduleDelivery=='After Delivery' || purchase.sheduleDelivery=='بعد الولادة')">
                                        <label>{{ $t('AddQuotation.Days') }} :</label>
                                        <div>
                                            <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'"
                                                   v-model="purchase.days" />
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 " v-if="purchase.paymentMethod=='Cash'">
                                        <label class="row pl-3 mt-2 ">{{ $t('AddQuotation.DeliveryTerms') }}  :</label>
                                        <div class="row pl-3 " :key="render+'add'">
                                            <label>{{ $t('AddQuotation.Fregiht') }}  :</label> <toggle-button v-model="purchase.isFreight" class="ml-2" color="#3178F6" style="z-index:0 !important" />
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 " v-if="purchase.paymentMethod=='Cash'">
                                        <div class="row pl-3 pt-2 mt-4" :key="render+'add'">

                                            <label>{{ $t('AddQuotation.Labour') }} :</label> <toggle-button v-model="purchase.isLabour" class="ml-2" color="#3178F6" style="z-index:0 !important" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-3 form-group text-center">


                                <label>{{ $t('AddQuotation.UploadWarrantyLogo') }}  :</label>

                                <div :key="renderImg">
                                    <input ref="imgupload" type="file"
                                           id="file-input"
                                           @change="uploadImage()"
                                           accept="image/*"
                                           name="image"
                                           class="mt-4 pl-3"
                                           v-if="!((imageSrc == '' && purchase.warrantyLogoPath!='') || (imageSrc != '' && purchase.warrantyLogoPath=='') || (imageSrc != '' && purchase.warrantyLogoPath!=''))"
                                           style="opacity:1;z-index: 1;">

                                    <div class="mt-2" v-if="imageSrc != ''">
                                        <img v-if="imageSrc != ''" :src="imageSrc" width="100" />
                                    </div>
                                    <div v-else class="mt-2">
                                        <span v-if="purchase.warrantyLogoPath!=null && purchase.warrantyLogoPath!=''">
                                            <img :src="'data:image/png;base64,' + purchase.warrantyLogoPath" width="150" />
                                        </span>
                                    </div>
                                </div>
                                <button class="btn btn-danger btn-sm" v-if="imageSrc != '' || purchase.warrantyLogoPath!=''" v-on:click="removeImage()">{{ $t('AddQuotation.Remove') }}</button>

                            </div>



                        </div>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 form-group" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <label>{{ $t('AddQuotation.QuotationFor') }}  :</label>
                                <textarea class="form-control" v-model="$v.purchase.description.$model"></textarea>
                                <span v-if="$v.purchase.description.$error"
                                      class="error text-danger">
                                    {{$t('AddQuotation.DescriptionLength')}}
                                </span>
                            </div>
                        </div>

                        <br />
                        <sale-service-item :saleItems="purchase.saleOrderItems" ref="childComponentRef" @input="SavePurchaseItems" :taxMethod="purchase.taxMethod" :taxRateId="purchase.taxRateId" :formName="'Quotation'"/>
                        <!--<quotation-service-item @input="SavePurchaseItems" :key="rander" :taxMethod="purchase.taxMethod" :taxRateId="purchase.taxRateId" />-->

                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                <label>{{ $t('AddQuotation.TermAndConditionEnglish') }}:</label>
                                <VueEditor v-model="purchase.note" />
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                <label>{{ $t('AddQuotation.TermAndConditionArabic') }}:</label>
                                <VueEditor v-model="purchase.noteAr" />
                            </div>
                        </div>

                        <div class="row">
                            <div v-if="!loading && purchase.id === '00000000-0000-0000-0000-000000000000'" class="col-md-12  " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                                <button class="btn btn-primary mr-2 float-left" v-on:click="Attachment()">
                                    {{ $t('AddQuotation.Attachment') }}
                                </button>

                                <button class="btn btn-primary  mr-2"
                                        v-on:click="savePurchase('Draft')"
                                        v-if="isValid('CanDraftServiceQuotation')"
                                        v-bind:disabled="$v.$invalid">
                                    <i class="far fa-save"></i>  {{ $t('AddQuotation.SaveAsDraft') }}
                                </button>
                                <button class="btn btn-primary  mr-2"
                                        v-on:click="savePurchase('Approved')"
                                        v-if="isValid('CanAddServiceQuotation')"
                                        v-bind:disabled="$v.$invalid ">
                                    <i class="far fa-save"></i>  {{ $t('AddQuotation.SaveAsPost') }}
                                </button>
                                <button class="btn btn-danger  mr-2"
                                        v-on:click="goToPurchase">
                                    {{ $t('AddQuotation.Cancel') }}
                                </button>

                            </div>
                            <div v-if="!loading && purchase.id!='00000000-0000-0000-0000-000000000000'" class="col-md-12 " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                                <button class="btn btn-primary mr-2 float-left" v-on:click="Attachment()">
                                    {{ $t('AddQuotation.Attachment') }}
                                </button>

                                <button class="btn btn-primary  mr-2"
                                        v-on:click="savePurchase('Draft')"
                                        v-if="isValid('CanDraftServiceQuotation')"
                                        v-bind:disabled="$v.$invalid">
                                    <i class="far fa-save"></i>  {{ $t('AddQuotation.UpdateAsDraft') }}
                                </button>

                                <button class="btn btn-primary  mr-2"
                                        v-on:click="savePurchase('Approved')"
                                        v-if="isValid('CanEditServiceQuotation')"
                                        v-bind:disabled="$v.$invalid ">
                                    <i class="far fa-save"></i> {{ $t('AddQuotation.UpdateAsPost') }}
                                </button>
                                <button class="btn btn-danger  mr-2"
                                        v-on:click="goToPurchase">
                                    {{ $t('AddQuotation.Cancel') }}
                                </button>
                            </div>
                            <div class="card-footer col-md-3" v-else>
                                <loading :active.sync="loading"
                                         :can-cancel="true"
                                         :is-full-page="true"></loading>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <bulk-attachment :attachmentList="purchase.attachmentList" :show="show" v-if="show" @close="attachmentSave" />

        </div>
    </div>
    <div v-else> <acessdenied></acessdenied></div>

</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import Loading from 'vue-loading-overlay';
    import moment from "moment";
    import "vue-loading-overlay/dist/vue-loading.css";
    import { required, maxLength } from "vuelidate/lib/validators";
    import Multiselect from 'vue-multiselect';
    import { VueEditor } from "vue2-editor";
    //import VueBarcode from 'vue-barcode';
    export default {
        mixins: [clickMixin],
        components: {
            Multiselect,
            Loading,
            VueEditor
        },

        data: function () {
            return {
                saleDefaultVat: '',
                randerCustomer: 0,
                daterander: 0,
                rander: 0,
                render: 0,
                imageSrc: '',
                headerFooter: {
                    returnDays: '',
                    footerEn: '',
                    footerAr: '',
                    printSizeA4: '',
                    company: ''
                },
                purchase: {
                    id: "00000000-0000-0000-0000-000000000000",
                    date: "",
                    registrationNo: "",
                    customerId: "",
                    refrence: "",
                    days: '',
                    purchaseOrder: "",
                    paymentMethod: "",
                    sheduleDelivery: "",
                    note: '',
                    noteAr: '',
                    isFreight: false,
                    isLabour: false,
                    isQuotation: true,
                    saleOrderItems: [],
                    attachmentList: [],
                    path: '',
                    clientPurchaseNo: '',
                    description: '',
                    isService: true,
                    taxMethod: '',
                    taxRateId: '',
                    isCredit: false,
                    isRemove: false,
                    customerAddressWalkIn: '',
                    mobile: '',
                    email: '',
                    cashCustomerId: '',
                    warrantyLogoPath: '',
                },
                renderImg: 0,
                loading: false,
                show: false,
            };
        },
        validations: {
            purchase: {
                date: { required },
                registrationNo: { required },
                customerId: { required },
                refrence: {},

                paymentMethod: { required },

                saleOrderItems: { required },
                description: {
                    maxLength: maxLength(110)
                }
            },
        },
        methods: {
            getAddress: function () {
                this.purchase.customerAddressWalkIn = this.$refs.CustomerDropdown.GetCustomerAddress().address;
                this.purchase.mobile = this.$refs.CustomerDropdown.GetCustomerAddress().mobile;
                this.purchase.email = this.$refs.CustomerDropdown.GetCustomerAddress().email;
                this.purchase.cashCustomerId = this.$refs.CustomerDropdown.GetCustomerAddress().vatNo;
            },
            isCredit: function (value) {
                this.purchase.isCredit = value;
                value ? this.purchase.paymentMethod = 'Credit' : this.purchase.paymentMethod = 'Cash'
                this.randerCustomer++;

            },
            uploadImage: function () {
                var root = this;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                var file = this.$refs.imgupload.files;

                var fileData = new FormData();

                for (var k = 0; k < file.length; k++) {
                    fileData.append("files", file[k]);
                }

                this.imageSrc = URL.createObjectURL(this.$refs.imgupload.files[0]);

                root.$https.post('/Company/UploadFilesAsync', fileData, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {

                            root.purchase.warrantyLogoPath = response.data;
                        }
                    },
                        function () {
                            this.loading = false;
                            root.$swal({
                                title: root.$t('Error'),
                                text: root.$t('SomethingWrong'),
                                type: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                        });
            },
            removeImage: function () {
                this.imageSrc = '';
                this.purchase.warrantyLogoPath = '';
                this.renderImg++;
                this.purchase.isRemove = true

            },
            Attachment: function () {
                this.show = true;
            },

            attachmentSave: function (attachment) {
                this.purchase.attachmentList = attachment;
                this.show = false;
            },


            AutoIncrementCode: function () {
                var root = this;
                var token = "";
                if (root.$session.exists()) {
                    token = localStorage.getItem("token");
                }
                root.$https
                    .get("/Purchase/SaleOrderAutoGenerateNo?isQuotation=" + root.purchase.isQuotation, {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then(function (response) {
                        if (response.data != null) {
                            root.purchase.registrationNo = response.data;
                        }
                    });
            },
            SavePurchaseItems: function (saleOrderItems) {
                this.purchase.saleOrderItems = saleOrderItems
                //var root = this;
                //this.purchase.saleOrderItems = [];
                //saleOrderItems.forEach(function (x) {
                //    root.purchase.saleOrderItems.push({
                //        rowId: x.rowId,
                //        productId: x.productId,
                //        unitPrice: x.unitPrice,
                //        quantity: x.quantity,
                //        highQty: x.highQty,
                //        discount: x.discount,
                //        fixDiscount: x.fixDiscount,
                //        taxRateId: x.taxRateId,
                //        rate: x.rate,
                //        taxMethod: x.taxMethod,
                //        description: x.description,
                //        lineTotal: x.lineTotal,
                //        unitPerPack: x.unitPerPack,
                //        levelOneUnit: x.levelOneUnit,
                //        basicUnit: x.basicUnit,
                //        isFree: x.isFree,
                //        serviceItem: x.serviceItem,
                //    })
                //})
            },
            savePurchase: function (status) {
                this.purchase.approvalStatus = status
                this.loading = true;
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.purchase.saleOrderItems.forEach(function (x) {
                    x.quantity = x.totalPiece;
                });
                this.$https.post('/Purchase/SaveSaleOrderInformation', root.purchase, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {
                        root.loading = false
                        root.info = response.data

                        root.$swal({
                            title: "Saved!",
                            text: "Data Saved Successfully!",
                            type: 'success',
                            icon: 'success',
                            timer: 1500,
                            timerProgressBar: true,
                        }).then(function (response) {
                            if (response != undefined) {
                                if (root.purchase.id == "00000000-0000-0000-0000-000000000000") {
                                    root.$router.go('AddServiceQuotation');

                                } else {
                                    if (root.isValid('CanDraftServiceQuotation') || root.isValid('CanViewServiceQuotation')) {
                                        root.$router.push('/ServiceQuotation');
                                    }
                                    else {
                                        root.$router.go();
                                    }
                                }
                            }
                        });

                    })
                    .catch(error => {
                        console.log(error)
                        //if (localStorage.getItem('IsMultiUnit') == 'true') {
                        //    root.purchase.saleOrderItems.forEach(function (x) {

                        //        x.highQty = parseInt(parseInt(x.quantity) / parseInt(x.unitPerPack));
                        //        x.quantity = parseInt(parseInt(x.quantity) % parseInt(x.unitPerPack));

                        //    });
                        //}
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Something Went Wrong!',
                                text: error,
                            });

                        root.loading = false
                    })
                    .finally(() => root.loading = false)

            },

            goToPurchase: function () {
                if (this.isValid('CanDraftServiceQuotation') || this.isValid('CanViewServiceQuotation')) {
                    this.$router.push('/ServiceQuotation');
                }
                else {
                    this.$router.go();
                }

            },
            GetHeaderDetail: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get("/Company/GetCompanyDetail?id=" + localStorage.getItem('CompanyID'), { headers: { Authorization: `Bearer ${token}` }, })
                    .then(function (response) {
                        if (response.data != null) {

                            root.headerFooter.company = response.data;
                            if (root.overWrite) {

                                root.headerFooter.company.nameArabic = root.BusinessNameArabic;
                                root.headerFooter.company.nameEnglish = root.BusinessNameEnglish;
                                root.headerFooter.company.categoryArabic = root.BusinessTypeArabic;
                                root.headerFooter.company.categoryEnglish = root.BusinessTypeEnglish;

                                root.headerFooter.company.companyNameArabic = root.CompanyNameArabic;
                                root.headerFooter.company.companyNameEnglish = root.CompanyNameEnglish;


                                root.headerFooter.company.logoPath = root.BusinessLogo;
                            }
                            root.GetInvoicePrintSetting();
                            root.getBase64Image(root.headerFooter.company.logoPath);
                        }
                    });
            },
            GetInvoicePrintSetting: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https.get('/Sale/printSettingDetail', { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null && response.data != '') {

                            if (root.$route.query.data == undefined) {
                                root.purchase.note = response.data.termsInEng;
                                root.purchase.noteAr = response.data.termsInAr;
                                root.purchase.warrantyLogoPath = response.data.warrantyImageForPrint;

                            }


                            root.headerFooter.footerEn = response.data.termsInEng;
                            root.headerFooter.footerAr = response.data.termsInAr;
                            root.headerFooter.isHeaderFooter = response.data.isHeaderFooter;
                            root.headerFooter.englishName = response.data.englishName;
                            root.headerFooter.arabicName = response.data.arabicName;
                            root.headerFooter.customerAddress = response.data.customerAddress;
                            root.headerFooter.customerVat = response.data.customerVat;
                            root.headerFooter.customerNumber = response.data.customerNumber;
                            root.headerFooter.cargoName = response.data.cargoName;
                            root.headerFooter.customerTelephone = response.data.customerTelephone;
                            root.headerFooter.itemPieceSize = response.data.itemPieceSize;
                            root.headerFooter.styleNo = response.data.styleNo;
                            root.headerFooter.blindPrint = response.data.blindPrint;
                            root.headerFooter.showBankAccount = response.data.showBankAccount;
                            root.headerFooter.bankAccount1 = response.data.bankAccount1;
                            root.headerFooter.bankAccount2 = response.data.bankAccount2;
                            root.headerFooter.bankIcon1 = response.data.bankIcon1;
                            root.headerFooter.bankIcon2 = response.data.bankIcon2;
                            root.headerFooter.customerNameEn = response.data.customerNameEn;
                            root.headerFooter.customerNameAr = response.data.customerNameAr;
                            root.headerFooter.exchangeDays = response.data.exchangeDays;
                            root.headerFooter.printOptions = response.data.printOptions;
                            root.headerFooter.invoicePrint = response.data.invoicePrint;
                            root.headerFooter.welcomeLineEn = response.data.welcomeLineEn;
                            root.headerFooter.welcomeLineAr = response.data.welcomeLineAr;
                            root.headerFooter.closingLineEn = response.data.closingLineEn;
                            root.headerFooter.closingLineAr = response.data.closingLineAr;
                            root.headerFooter.contactNo = response.data.contactNo;
                            root.headerFooter.managementNo = response.data.managementNo;

                            root.headerFooter.businessAddressArabic = response.data.businessAddressArabic;
                            root.headerFooter.businessAddressEnglish = response.data.businessAddressEnglish;
                            root.headerFooter.headerImage = response.data.headerImageForPrint;
                            root.headerFooter.footerImage = response.data.footerImageForPrint;
                            root.headerFooter.footerImageForPrint = response.data.footerImageForPrint;
                            root.headerFooter.warrantyImage = response.data.warrantyImage;
                            root.headerFooter.warrantyImageForPrint = response.data.warrantyImageForPrint;

                        }
                    },
                        function (error) {
                            this.loading = false;
                            console.log(error);
                        });


            },
        },
        created: function () {
            this.$emit('input', this.$route.name);
            this.saleDefaultVat = localStorage.getItem('SaleDefaultVat');
            this.GetHeaderDetail()
            if (this.$route.query.data != undefined) {

                this.purchase = this.$route.query.data;
                this.purchase.date = moment(this.purchase.date).format('llll');
                //if (localStorage.getItem('IsMultiUnit') == 'true') {

                //    this.purchase.saleOrderItems.forEach(function (x) {

                //        x.highQty = parseInt(parseInt(x.quantity) / parseInt(x.product.unitPerPack));
                //        x.quantity = parseInt(parseInt(x.quantity) % parseInt(x.product.unitPerPack));
                //        x.unitPerPack = x.product.unitPerPack;
                //    });
                //}

                if (this.purchase.paymentMethod == "Credit") {
                    this.purchase.isCredit = true
                }
                this.attachment = true;
                this.rander++;
                this.render++;
                this.rendered++;
            }
            else {
                this.purchase.wareHouseId = localStorage.getItem('WareHouseId');
                this.purchase.taxRateId = localStorage.getItem('TaxRateId');
                this.purchase.taxMethod = localStorage.getItem('taxMethod');
                this.purchase.paymentMethod = "Cash";
                this.purchase.isCredit = false;

            }
        },
        mounted: function () {

            if (this.$route.query.data == undefined) {
                this.AutoIncrementCode();

                this.purchase.date = moment().format('llll');
                this.daterander++;
            }
            //var root = this
            //setTimeout(function () {
            //    root.getAddress();
            //}, 1000)
        },
    };
</script>

<style scoped>
    .poHeading {
        font-size: 32px;
        font-style: normal;
        line-height: 37px;
        font-weight: 500;
        font-size: 24px;
        line-height: 26px;
        color: #3178F6;
        text-align: center
    }

    .dateHeading {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.01em;
        color: #35353D;
    }

    .bottomBorder {
        padding-top: 24px !important;
        border-bottom: 1px solid #EFF4F7;
    }

    .invoice_top_bg {
        background-color: #3178f6;
    }

    .title_heading {
        font-weight: 600;
    }

    .bg_color {
        background-color: #dee9fe;
        border: 1px solid rgb(49 120 246);
    }

    .vue-radio-button {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    .icon {
        object-fit: contain;
    }

    .v-radio-button-active {
        background-color: #b6d7ff73 !important;
    }

    .title {
        font-size: 11px;
        margin-left: -10px;
    }

    .bt_bg_color {
        background-color: #3178F6 !important;
        color: #ffffff !important;
        border-color: #3178F6 !important;
        box-shadow: 0px 14px 34px rgba(49, 120, 246, 0.5) !important;
        border-radius: 10px;
    }

    .pointer-event-remove {
        pointer-events: none;
    }
</style>


