<template>
    <div class="row" v-if="isValid('CanViewGoodsReceiveasDraft') ||  isValid('CanAddGoodsReceiveasPost')|| isValid('CanEditGoodsReceiveasDraft')" v-bind:style="$i18n.locale == 'ar' ? languageChange('en') : languageChange('ar')">
        <div class="col-md-12 ml-auto mr-auto">
            <div class="card" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">


                            <div class="row " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <div class="col-lg-8 poHeading" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <span v-if="purchase.id === '00000000-0000-0000-0000-000000000000'">{{ $t('GoodReceive.AddGoodReceive') }} <span style="font-weight:bold"> - {{purchase.registrationNo}}</span></span>
                                    <span v-else>{{ $t('GoodReceive.UpdateGoodReceive') }}  <span style="font-weight:bold"> - {{purchase.registrationNo}}</span></span>
                                </div>
                                <div class="col-lg-4 dateHeading" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                                    <span>
                                        {{purchase.date}}
                                    </span>
                                </div>
                            </div>

                            <div class="row bottomBorder">

                            </div>



                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div v-if="raw=='true'" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                        <label>{{ $t('AddPurchaseOrder.RawProduct') }}  : </label>
                                        <toggle-button v-model="purchase.isRaw" @change="ChangeSupplier" class="ml-2 mt-2" color="#3178F6" />
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <label>{{ $t('AddPurchase.Supplier') }} :<span class="LabelColour"> *</span></label>
                                    <div v-bind:class="{'has-danger': $v.purchase.supplierId.$error}" :key="supplierRender">
                                        <supplierdropdown v-model="$v.purchase.supplierId.$model" :disable="purchase.purchaseOrderId!=null && purchase.purchaseOrderId!=''" :paymentTerm="purchase.isCredit" v-bind:values="purchase.supplierId" :status="purchase.isRaw" :key="rander" />
                                        <span v-if="$v.purchase.supplierId.$error"
                                              class="error text-danger">
                                        </span>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <label>{{ $t('AddPurchase.PurchaseOrder') }}  :</label>
                                    <purchase-order-dropdown v-on:input="GetPoData(purchase.purchaseOrderId,false)" v-model="purchase.purchaseOrderId" v-bind:values="purchase.purchaseOrderId" />
                                </div>

                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 form-group" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <label v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'pt-2'">{{ $t('AddPurchaseOrder.SupplierQuotationNumber') }} :</label>
                                    <div v-bind:class="{'has-danger': $v.purchase.invoiceNo.$error,}">
                                        <input class="form-control" v-bind:disabled="purchase.approvalStatus === 5 && purchase.id != '00000000-0000-0000-0000-000000000000'" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'"
                                               v-model="$v.purchase.invoiceNo.$model" />
                                        <span v-if="$v.purchase.invoiceNo.$error"
                                              class="error text-danger">
                                        </span>
                                    </div>
                                </div>

                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <label>{{ $t('AddPurchaseOrder.QuotationDate') }}:</label>
                                    <div v-bind:class="{'has-danger': $v.purchase.invoiceDate.$error,}">
                                        <datepicker v-model="$v.purchase.invoiceDate.$model" />
                                        <span v-if="$v.purchase.invoiceDate.$error"
                                              class="error text-danger">
                                        </span>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-bind:class=" ($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <label>{{ $t('AddPurchase.TaxMethod') }} :<span class="LabelColour"> *</span></label>
                                    <multiselect :options="options" v-bind:disabled="purchase.goodReceiveNoteItems.length>0" v-model="purchase.taxMethod" :show-labels="false" v-bind:placeholder="$t('SelectMethod')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    </multiselect>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <label>{{ $t('AddPurchase.VAT%') }} :<span class="LabelColour"> *</span></label>
                                    <taxratedropdown v-model="purchase.taxRateId" v-bind:values="purchase.taxRateId" :isDisable="purchase.goodReceiveNoteItems.length>0? true :false" v-bind:key="rander" />
                                </div>

                            </div>

                            <br />
                            <GoodReceiveItem @input="SavePurchaseItems" ref="childComponentRef" :purchaseItems="purchase.goodReceiveNoteItems" :taxMethod="purchase.taxMethod" :taxRateId="purchase.taxRateId" :raw="purchase.isRaw" :purchaseOrderId="purchase.purchaseOrderId" :key="randerLineItem" />
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <label>{{ $t('AddPurchaseOrder.TermandCondition') }}:</label>
                                    <textarea class="form-control " rows="3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'"
                                              v-model="purchase.note" />
                                </div>
                            </div>



                            <div class="row" v-if="!loading">
                                <div class="col-md-12 arabicLanguage">
                                    <div v-if="purchase.id === '00000000-0000-0000-0000-000000000000'">
                                        <button class="btn btn-primary mr-2 float-left" v-on:click="Attachment()">
                                            {{ $t('AddPurchase.Attachment') }}
                                        </button>

                                        <button class="btn btn-primary  mr-2"
                                                v-if="isValid('CanViewGoodsReceiveasDraft')"
                                                v-on:click="savePurchase('Draft')"
                                                :disabled="$v.$invalid || purchase.goodReceiveNoteItems.filter(x => x.totalPiece=='').length > 0 || purchase.goodReceiveNoteItems.filter(x => x.unitPrice=='').length > 0">
                                            {{ $t('AddPurchaseOrder.SaveAsDraft') }}
                                        </button>
                                     
                                        <button class="btn btn-primary mr-2"
                                                v-on:click="savePurchase('Approved')"
                                                v-if="isValid('CanAddGoodsReceiveasPost')"
                                                :disabled="$v.$invalid || purchase.goodReceiveNoteItems.filter(x => x.totalPiece=='').length > 0 || purchase.goodReceiveNoteItems.filter(x => x.unitPrice=='').length > 0">
                                            {{ $t('AddPurchaseOrder.SaveAsPost') }}
                                        </button>
                                        <button class="btn btn-outline-danger mr-2"
                                                v-on:click="goToPurchase">
                                            {{ $t('AddPurchaseOrder.Cancel') }}
                                        </button>
                                    </div>
                                    <div v-if="purchase.approvalStatus === 4 && purchase.id != '00000000-0000-0000-0000-000000000000'">
                                        <button class="btn btn-primary mr-2 float-left" v-on:click="Attachment()">
                                            {{ $t('AddPurchase.Attachment') }}
                                        </button>

                                        <button class="btn btn-primary  mr-2"
                                                v-on:click="savePurchase('Draft')"
                                                v-if="isValid('CanViewGoodsReceiveasDraft') && isValid('CanEditGoodsReceiveasDraft')"
                                                :disabled="$v.$invalid || purchase.goodReceiveNoteItems.filter(x => x.totalPiece=='').length > 0 || purchase.goodReceiveNoteItems.filter(x => x.unitPrice=='').length > 0">
                                            {{ $t('AddPurchaseOrder.UpdateAsDraft') }}
                                        </button>
                                     
                                        <button class="btn btn-primary  mr-2"
                                                v-on:click="savePurchase('Approved')"
                                                v-if="isValid('CanAddGoodsReceiveasPost') && isValid('CanEditGoodsReceiveasDraft')"
                                                :disabled="$v.$invalid || purchase.goodReceiveNoteItems.filter(x => x.totalPiece=='').length > 0 || purchase.goodReceiveNoteItems.filter(x => x.unitPrice=='').length > 0">
                                            {{ $t('AddPurchaseOrder.UpdateAsPost') }}
                                        </button>
                                        <button class="btn btn-outline-danger mr-2"
                                                v-on:click="goToPurchase">
                                            {{ $t('AddPurchaseOrder.Cancel') }}
                                        </button>
                                    </div>
                                    <div v-if="purchase.approvalStatus === 5 && purchase.id != '00000000-0000-0000-0000-000000000000'">
                                        <button class="btn btn-primary mr-2 float-left" v-on:click="Attachment()">
                                            {{ $t('AddPurchase.Attachment') }}
                                        </button>

                                      
                                        <button class="btn btn-primary  mr-2"
                                                v-on:click="savePurchase('Approved')"
                                                v-if="isValid('CanAddGoodsReceiveasPost') || isValid('CanEditGoodsReceiveasDraft')"
                                                :disabled="$v.$invalid || purchase.goodReceiveNoteItems.filter(x => x.totalPiece=='').length > 0 || purchase.goodReceiveNoteItems.filter(x => x.unitPrice=='').length > 0">
                                            {{ $t('AddPurchaseOrder.UpdateAsPost') }}
                                        </button>
                                        <button class="btn btn-outline-danger mr-2"
                                                v-on:click="goToPurchase">
                                            {{ $t('AddPurchaseOrder.Cancel') }}
                                        </button>
                                    </div>
                                </div>

                            </div>
                            <div class="card-footer col-md-3 text-left" v-else>
                                <loading :active.sync="loading"
                                         :can-cancel="false"
                                         :is-full-page="true"></loading>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <bulk-attachment :attachmentList="purchase.attachmentList" :show="isAttachshow" v-if="isAttachshow" @close="attachmentSaved" />
        </div>

    </div>
    <div v-else> <acessdenied></acessdenied></div>

</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import Loading from 'vue-loading-overlay';
    import moment from "moment";
    import "vue-loading-overlay/dist/vue-loading.css";
    import { required } from "vuelidate/lib/validators";
    import Multiselect from 'vue-multiselect'
    //import VueBarcode from 'vue-barcode';
    export default {
        mixins: [clickMixin],
        components: {
            Multiselect,
            Loading
        },
        data: function () {
            return {
                accountRender: 0,
                isFifo: false,
                purchaseOrder: false,
                internationalPurchase: '',
                currency: '',
                purchase: {
                    id: "00000000-0000-0000-0000-000000000000",
                    date: "",
                    purchaseOrderId: "",
                    bankCashAccountId: "",
                    paymentType: "Cash",
                    registrationNo: "",
                    supplierId: "",
                    invoiceNo: "",
                    isCredit: false,
                    isPurchaseReturn: false,
                    invoiceDate: "",
                    purchaseOrder: "",
                    wareHouseId: "",
                    goodReceiveNoteItems: [],
                    attachmentList: [],
                    isRaw: false,
                    isPurchasePost: false,
                    taxMethod: '',
                    taxRateId: "00000000-0000-0000-0000-000000000000",
                    actionProcess: [],
                    purchaseAttachments: [],
                    paymentVoucher: [],
                    purchasePostExpense: [],
                    partiallyPurchase: false,
                    autoPurchaseVoucher: false,
                    expenseToGst: false,
                    internationalPurchase: false,
                    dueAmount: 0,
                },

                printId: '00000000-0000-0000-0000-000000000000',
                printDetails: [],
                options: [],
                paymentTypeOptions: [],
                loading: false,
                rander: 0,
                raw: '',
                printRender: 0,
                randerLineItem: 0,
                autoNumber: '',
                language: 'Nothing',
                supplierRender: 0,
                wareRander: 0,
                show: false,
                isAttachshow: false,
                attachment: false,
                action: {
                    id: '00000000-0000-0000-0000-000000000000',
                    purchaseOrderId: '',
                    processId: '',
                    date: '',
                    description: '',
                },
            };
        },
        validations: {
            purchase: {
                date: { required },
                registrationNo: { required },
                supplierId: { required },
                invoiceNo: {},
                invoiceDate: {},

                goodReceiveNoteItems: { required },
            },
        },
        methods: {
            GetPoData: function (id, isEdit) {

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                console.log(isEdit);
                var multi = localStorage.getItem('IsMultiUnit') == 'true' ? true : false
                root.$https.get('/Purchase/PurchaseOrderDetail?Id=' + id + '&isMultiUnit=' + multi, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {

                            
                            if (!isEdit) {
                                root.purchase.goodReceiveNoteItems = [];
                                root.purchase.purchaseOrderId = response.data.id;
                                root.purchase.supplierId = response.data.supplierId;
                                root.purchase.taxMethod = response.data.taxMethod;
                                root.purchase.taxRateId = response.data.taxRateId;
                                root.purchase.isCredit = true;

                                response.data.purchaseOrderItems.forEach(function (item) {
                                    if (item.remainingQuantity > 0) {
                                        root.purchase.goodReceiveNoteItems.push({
                                            rowId: item.id,
                                            id: item.id,
                                            batchNo: item.batchNo,
                                            discount: item.discount,
                                            expiryDate: item.expiryDate,
                                            isExpire: item.product.isExpire,
                                            fixDiscount: item.fixDiscount,
                                            product: item.product,
                                            basicUnit: item.unit == null ? '' : item.unit.name,
                                            productId: item.productId,
                                            purchaseId: item.purchaseId,
                                            quantity: item.quantity,
                                            poQuantity: item.quantity,
                                            highQty: item.highQty,
                                            unitPerPack: item.unitPerPack,
                                            taxMethod: item.taxMethod,
                                            taxRateId: item.taxRateId,
                                            serial: item.product.serial,
                                            serialNo: item.serialNo,
                                            guarantee: item.product.guarantee,
                                            guaranteeDate: item.guaranteeDate,
                                            unitPrice: item.unitPrice == 0 ? '0' : item.unitPrice,
                                        });
                                    }
                                });
                                root.rander++;
                                root.randerLineItem++;
                            }


                        }
                    },
                        function (error) {
                            root.loading = false;
                            console.log(error);
                        });
            },

            Attachment: function () {
                this.isAttachshow = true;
            },

            attachmentSaved: function (attachment) {
                this.purchase.attachmentList = attachment;
                this.isAttachshow = false;
            },


            getTotalAmount: function () {
                this.totalAmount = this.$refs.childComponentRef.getTotalAmount();
            },
            getDate: function (date) {
                if (date == null || date == undefined) {
                    return "";
                }
                else {
                    return moment(date).format('LLL');
                }
            },
            ChangeSupplier: function () {
                this.supplierRender++;
                this.rander++;
            },
            languageChange: function (lan) {
                if (this.language == lan) {
                    if (this.purchase.id == '00000000-0000-0000-0000-000000000000') {

                        var getLocale = this.$i18n.locale;
                        this.language = getLocale;

                        this.$router.go('/addproduct');
                    }
                    else {
                        this.$swal({
                            title: 'Error!',
                            text: 'You cannot Change language during update, otherwise your current page data will be lose',
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            icon: 'error',
                            timer: 4000,
                            timerProgressBar: true,
                        });
                    }
                }


            },

            DownloadAttachment(path) {

                var root = this;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                var ext = path.split('.')[1];
                root.$https.get('/Contact/DownloadFile?filePath=' + path, { headers: { "Authorization": `Bearer ${token}` }, responseType: 'blob' })
                    .then(function (response) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'file.' + ext);
                        document.body.appendChild(link);
                        link.click();
                    });
            },

            uploadImage() {

                var root = this;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                var file = null;

                file = this.$refs.imgupload1.files;

                var fileData = new FormData();
                for (var k = 0; k < file.length; k++) {
                    fileData.append("files", file[k]);
                }
                root.$https.post('/Company/UploadFilesAsync', fileData, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {

                            root.purchase.path = response.data;

                        }
                    },
                        function () {
                            this.loading = false;
                            root.$swal({
                                title: "Error!",
                                text: "Something went wrong",
                                type: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                        });
            },

            AutoIncrementCode: function () {
                var root = this;
                var token = "";
                if (root.$session.exists()) {
                    token = localStorage.getItem("token");
                }
                root.$https
                    .get("/Purchase/GoodReceiveAutoGenerateNo", {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then(function (response) {
                        if (response.data != null) {
                            root.purchase.registrationNo = response.data;
                        }
                    });
            },
            SavePurchaseItems: function (goodReceiveNoteItems) {
                this.purchase.goodReceiveNoteItems = goodReceiveNoteItems;
                this.getTotalAmount();
            },

            savePurchase: function (status) {
                this.purchase.approvalStatus = status
                localStorage.setItem('active', status);

                this.loading = true;
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.purchase.internationalPurchase = this.internationalPurchase == 'true' ? true : false;
                this.purchase.isMultiUnit = localStorage.getItem('IsMultiUnit') == 'true' ? true : false;
                this.purchase.approvedBy = localStorage.getItem('LoginUserName');
                //root.purchase.date = root.purchase.date + " " + moment().format("hh:mm A");
                this.$https.post('/Purchase/SaveGoodReceiveInformation', root.purchase, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {
                        if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Add") {
                            root.info = response.data.bpi
                            root.$swal({
                                title: "Saved!",
                                text: response.data.message.isAddUpdate,
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonStyling: false,
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,

                            }).then(function (ok) {
                                if (ok != null) {
                                    root.$router.go('AddGoodReceive');
                                }
                            });
                        }
                        else if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Update") {
                            root.info = response.data.bpi
                            root.$swal({
                                title: "Saved!",
                                text: response.data.message.isAddUpdate,
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonStyling: false,
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,

                            }).then(function (ok) {
                                if (ok != null) {
                                    root.$router.push({
                                        path: '/GoodReceive',
                                        query: {
                                            data: 'goodReceives'
                                        }
                                    })
                                }
                            });
                        }
                        else {
                            root.$swal({
                                title: 'Error!',
                                text: response.data.message.isAddUpdate,
                                type: 'error',
                                confirmButtonClass: "btn btn-danger",
                                icon: 'error',
                                timer: 1500,
                                timerProgressBar: true,
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                type: 'error',
                                icon: 'error',
                                title: root.$t('AddPurchaseOrder.Error'),
                                text: error.response.data,
                                confirmButtonClass: "btn btn-danger",
                                showConfirmButton: true,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false
                    })
                    .finally(() => root.loading = false)

            },
            IsSave: function () {
                this.show = false;
                this.GetProcessType();
            },
            attachmentSave: function () {
                this.GetAttachment();
                this.GetProcessType();
                this.attachment = false;
                this.GetProcessType();
            },
            paymentSave: function () {
                this.payment = false;
                this.GetPaymentVoucher();
                this.GetProcessType();
            },
            expenseSave: function () {
                this.expense = false;
                this.GetExpenseVoucher();
                this.GetProcessType();
            },
            GetExpenseVoucher: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https.get('Purchase/PurchaseOrderExpensePaymentList?id=' + this.purchase.id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null && response.data != '') {
                            root.purchase.purchaseOrderExpenses = response.data;
                        }
                    });
            },
            GetPaymentVoucher: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https.get('Purchase/PurchaseOrderPaymentList?id=' + this.purchase.id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null && response.data != '') {
                            root.purchase.paymentVoucher = response.data;
                        }
                    });
            },
            GetAttachment: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https.get('Purchase/PurchaseOrderAttachmentList?id=' + this.purchase.id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null && response.data != '') {
                            root.purchase.purchaseAttachments = response.data;
                        }
                    });
            },
            GetProcessType: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https.get('Purchase/PurchaseOrderActionList?id=' + this.purchase.id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null && response.data != '') {
                            root.purchase.actionProcess = response.data;
                        }
                    });
            },
            goToPurchase: function () {
                
                if (this.isValid('CanViewGoodsReceiveasDraft') || this.isValid('CanViewGoodsReceiveasPost') ) {
                    this.$router.push({
                        path: '/GoodReceive',
                        query: {
                            data: 'goodReceives'
                        }
                    })
                }
                else {
                    this.$router.go();
                }
                
            },
        },
        created: function () {
            this.$emit('input', this.$route.name);
            
            if (this.$route.query.data != undefined) {
                this.purchase = this.$route.query.data;
                this.action.purchaseOrderId = this.purchase.id;
                this.advanceAccountId = this.$route.query.data.advanceAccountId;
                this.purchase.date = moment(this.purchase.date).format("LLL");
                this.rander++;
                this.rendered++;
            }
        },
        mounted: function () {
            this.language = this.$i18n.locale;
            this.currency = localStorage.getItem('currency');
            this.internationalPurchase = localStorage.getItem('InternationalPurchase');
            //this.versionAllow = localStorage.getItem('VersionAllow');
            if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {
                this.options = ['Inclusive', 'Exclusive'];
            }
            else {
                this.options = ['شامل', 'غير شامل'];
            }
            if (this.$session.exists()) {
                this.raw = localStorage.getItem('IsProduction');
            }
            if (this.$route.query.data == undefined) {
                this.AutoIncrementCode();

                this.purchase.date = moment().format("LLL");
                this.daterander++;
            }
        },
    };
</script>
<style scoped>
    .poHeading {
        font-size: 32px;
        font-style: normal;
        line-height: 37px;
        font-weight: 500;
        font-size: 24px;
        line-height: 26px;
        color: #3178F6;
        text-align: center
    }

    .dateHeading {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.01em;
        text-align: left;
        color: #35353D;
    }

    .bottomBorder {
        padding-top: 24px !important;
        border-bottom: 1px solid #EFF4F7;
    }
</style>
