<template>
    <modal :show="show" :modalLarge="true">
        <div style="margin-bottom:0px" class="card" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <div class="card-body">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">
                       
                        <div class="modal-header " >
                            <div class="row">
                                <div class="col-6">
                                    <h5 class="modal-title DayHeading" id="myModalLabel">Account of Cost-Center </h5>

                                </div>
                                <div class="col-6 text-right">
                                    <button type="button" class="btn btn-danger  mr-3 " style="margin-top:0px" v-on:click="close()">{{ $t('AddCity.btnClear') }}</button>
                                </div>
                            </div>
                          

                        </div>
                        <div class="">
                            <div class="card-body">
                                <div class="col-lg-12">
                                    <div class="table-responsive">
                                        <table class="table table-striped table-hover table_list_bg">
                                            <thead class="">
                                                <tr>
                                                    <th>#</th>


                                                    <th>
                                                        {{ $t('TrialBalanceReport.Name') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('TrialBalanceReport.Debit') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('TrialBalanceReport.Credit') }}
                                                    </th>
                                                    <th>
                                                        Total
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(account,index) in subAccount" v-bind:key="account.Code">
                                                    <td>
                                                        {{index+1}}
                                                    </td>



                                                    <td>
                                                        {{account.accountName}}

                                                    </td>
                                                    <td>{{Number(parseFloat(account.debit).toFixed(2)).toLocaleString()}}</td>
                                                    <td>{{Number(Math.abs(parseFloat(account.credit).toFixed(2))).toLocaleString()}}</td>
                                                    <td>{{Number(Math.abs(parseFloat(account.debit-account.credit).toFixed(2))).toLocaleString()}}</td>

                                                </tr>
                                            <tr >
                                                <td style="background-color:white" ></td>
                                            </tr>
                                           
                                            <tr style="margin-top:20px">
                                                <td></td>
                                                <td><b> {{$t('TrialBalanceReport.Total')}} </b></td>
                                                <td>
                                                    <b>{{Number(parseFloat(totalDebit).toFixed(2)).toLocaleString()}}</b>
                                                </td>
                                                <td>
                                                    <b>{{Number(parseFloat(totalCredit).toFixed(2)).toLocaleString()}}</b>
                                                </td>
                                                <td>
                                                    <b>{{Number(parseFloat(totalCredit+totalDebit).toFixed(2)).toLocaleString()}}</b>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>
                     
                       
                    </div>
                </div>
            </div>
        </div>

    </modal>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        props: ['show', 'subAccount', 'type'],
        mixins: [clickMixin],
        data: function () {
            return {
                render: 0,
                totalDebit: 0,
                totalCredit: 0
            }
        },
        methods: {
            close: function () {
                this.$emit('close');
            },
        },
        mounted: function () {
            var root = this;
            root.totalDebit = root.subAccount.reduce(function (prev, item) {
                return prev + Number(item.debit);

            }, 0);
            root.totalCredit = Math.abs(root.subAccount.reduce(function (prev, item) {
                return Math.abs(prev + Number(item.credit));

            }, 0));
            
           

        }
    }
</script>
