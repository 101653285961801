


<template>
    <div class="row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'"  v-if="isValid('CanAddSupplier') || isValid('CanEditSupplier') ">
        <div class="col-md-11 ml-auto mr-auto card" v-bind:style="$i18n.locale == 'ar' ? languageChange('en') : languageChange('ar')">



            <div class="card-header">
                <h5 class="card-title DayHeading" v-if="supplier.id== '00000000-0000-0000-0000-000000000000'">{{ $t('AddSupplier.AddSupplier') }}</h5>
                <h5 class="card-title DayHeading" v-else>{{ $t('AddSupplier.UpdateSupplier') }}</h5>
            </div>



            <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                <ul class="nav nav-tabs" data-tabs="tabs">
                    <li class="nav-item"><a class="nav-link" v-bind:class="{active:active == 'PersonalInfo'}" v-on:click="makeActive('PersonalInfo')" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">{{ $t('AddSupplier.PersonalInformation') }}</a></li>
                    <li class="nav-item"><a class="nav-link" v-bind:class="{active:active == 'CommercialInformation'}" v-on:click="makeActive('CommercialInformation')" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">{{ $t('AddSupplier.CommercialInformation') }}</a></li>
                    <li class="nav-item"><a class="nav-link" v-bind:class="{active:active == 'Banks'}" v-on:click="makeActive('Banks')" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">{{ $t('AddSupplier.BankDetails') }}</a></li>
                    <li class="nav-item"><a class="nav-link" v-bind:class="{active:active == 'TermsandConditions'}" v-on:click="makeActive('TermsandConditions')" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">{{ $t('AddSupplier.TermsandConditions') }}</a></li>
                    <!--<li class="nav-item"><a class="nav-link" v-bind:class="{active:active == 'Attachments'}" v-on:click="makeActive('Attachments')" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">{{ $t('AddSupplier.Attachments') }}</a></li>-->
                </ul>
                <!--<ul class="nav nav-tabs" data-tabs="tabs">
            <li class="nav-item"><a class="nav-link" v-bind:class="{active:active == 'PersonalInfo'}" v-on:click="makeActive('PersonalInfo')" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">{{ $t('Supplier.PersonalInformation') }}</a></li>
            <li class="nav-item"><a class="nav-link" v-bind:class="{active:active == 'CommercialInformation'}" v-on:click="makeActive('CommercialInformation')" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">{{ $t('Supplier.CommercialInformation') }}</a></li>
            <li class="nav-item"><a class="nav-link" v-bind:class="{active:active == 'Banks '}" v-on:click="makeActive('Banks')" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">{{ $t('Supplier.BankDetails') }}</a></li>
            <li class="nav-item"><a class="nav-link" v-bind:class="{active:active == 'TermsandConditions '}" v-on:click="makeActive('TermsandConditions')" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false"> {{ $t('Supplier.TermsandConditions') }}</a></li>
            <li class="nav-item"><a class="nav-link" v-bind:class="{active:active == 'Attachments '}" v-on:click="makeActive('Attachments')" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">{{ $t('Supplier.Attachments') }}</a></li>
        </ul>-->
            </div>
            <div class="tab-content mt-3" id="nav-tabContent" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                <div v-if="active == 'PersonalInfo'">


                    <div class="card-body ">
                        <div class="row ">

                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 " v-if="isRaw=='true'">
                                <label class="text  font-weight-bolder ">  {{ $t('AddSupplier.RawSupplier') }} :  </label>
                                <toggle-button v-model="supplier.isRaw" class="ml-3 mt-2" color="#3178F6" />
                            </div>
                        </div>
                        <div class="row ">
                            <div v-if="english=='true'" class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                <label>{{ $t('AddSupplier.SupplierName(English)') |englishLanguage}} :<span class="LabelColour"> *</span></label>
                                <div v-bind:class="{'has-danger' : $v.supplier.englishName.$error}">
                                    <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.supplier.englishName.$model" />
                                    <span v-if="$v.supplier.englishName.$error" class="error text-danger">
                                        <span v-if="!$v.supplier.englishName.required">{{ $t('AddSupplier.EngValidation') }}</span>
                                        <span v-if="!$v.supplier.englishName.maxLength">{{ $t('AddSupplier.EngMax') }}</span>
                                    </span>
                                </div>
                            </div>
                            <div v-if="isOtherLang()" class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                <label>{{ $t('AddSupplier.SupplierName(Arabic)') |arabicLanguage}} :</label>
                                <div v-bind:class="{'has-danger' : $v.supplier.arabicName.$error}">
                                    <input class="form-control "  v-bind:class="isLeftToRight() ? 'text-left' : 'arabicLanguage'" v-model="$v.supplier.arabicName.$model" />
                                    <span v-if="$v.supplier.arabicName.$error" class="error text-danger">
                                        <span v-if="!$v.supplier.arabicName.maxLength">{{ $t('AddSupplier.ArMax') }}</span>
                                    </span>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                <label>{{ $t('AddSupplier.SupplierCode') }} :<span class="LabelColour"> *</span></label>
                                <div v-bind:class="{'has-danger' : $v.supplier.code.$error}">
                                    <input readonly class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.supplier.code.$model" />
                                    <span v-if="$v.supplier.code.$error" class="error text-danger">
                                    </span>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                <label>{{ $t('AddSupplier.RegistrationDate') }} :</label>
                                <div v-bind:class="{'has-danger' : $v.supplier.registrationDate.$error}">

                                    <datepicker v-model="$v.supplier.registrationDate.$model" :key="daterander"></datepicker>
                                    <span v-if="$v.supplier.registrationDate.$error" class="error text-danger">
                                    </span>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                <label>{{ $t('AddSupplier.SupplierCategory') }} :</label>
                                <div v-bind:class="{'has-danger' : $v.supplier.category.$error}">
                                    <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.supplier.category.$model" />
                                    <span v-if="$v.supplier.category.$error" class="error text-danger">
                                        <span v-if="!$v.supplier.category.maxLength">{{ $t('AddSupplier.SupplierMax') }}</span>
                                    </span>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                <label>{{ $t('AddSupplier.SupplierType') }} :<span class="LabelColour"> *</span></label>
                                <div v-bind:class="{'has-danger' : $v.supplier.supplierType.$error}">
                                    <multiselect style="z-index:999" v-model="$v.supplier.supplierType.$model" v-if="($i18n.locale == 'en' ||isLeftToRight()) " :options="['wholesaler', 'Retailer','Wholesaler & Retailer', 'Dealer', 'Distributor','International Supplier', 'International Manufacturers', 'International Agent / Exporter']" :show-labels="false" v-bind:placeholder="$t('AddSupplier.SelectOption')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">  </multiselect>
                                    <multiselect style="z-index:999" v-model="$v.supplier.supplierType.$model" v-else :options="['جمله', 'قطاعي','بائع بالجملة', 'وكيل', 'موزع', 'مزود دولي', 'الشركات المصنعة العالمية', 'وكيل / مصدر دولي']" :show-labels="false" v-bind:placeholder="$t('SelectOption')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    </multiselect>
                                    <span v-if="$v.supplier.supplierType.$error" class="error text-danger">
                                    </span>
                                </div>
                            </div>

                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 mt-3" style="z-index:0">
                                <label>{{ $t('AddSupplier.Active') }} :</label> <toggle-button v-model="supplier.isActive" color="#3178F6" class="ml-2" v-bind:key="isActiveRander" />
                            </div>

                        </div>
                    </div>
                </div>

                <div>
                    <div v-if="active == 'CommercialInformation'">
                        <div class="card-body ">
                            <div class="row ">

                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                    <label>{{ $t('AddSupplier.CommercialRegistrationNo') }} :<span class="LabelColour"> *</span></label>
                                    <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="supplier.commercialRegistrationNo" />
                                </div>

                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                    <label>{{ $t('AddSupplier.VAT/NTN/Tax No') }}:<span class="LabelColour"> *</span></label>
                                    <div v-bind:class="{'has-danger' : $v.supplier.vatNo.$error}">
                                        <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.supplier.vatNo.$model" />
                                        <span v-if="$v.supplier.vatNo.$error" class="error text-danger">
                                            <span v-if="!$v.supplier.vatNo.required">{{ $t('AddSupplier.VatNoRequired') }}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                    <label>{{ $t('AddSupplier.Telephone') }} :</label>
                                    <div>
                                        <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="supplier.telephone" />

                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                    <label>{{ $t('AddSupplier.Email') }} :</label>
                                    <div v-bind:class="{'has-danger' : $v.supplier.email.$error}">
                                        <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.supplier.email.$model" />
                                        <span v-if="$v.supplier.email.$error" class="error text-danger">
                                        </span>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                    <label>{{ $t('AddSupplier.Website') }} :</label>
                                    <div>
                                        <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="supplier.website" />

                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                    <label>{{ $t('AddSupplier.locationCoordinates') }} :</label>
                                    <div>
                                        <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="supplier.coordinator" />

                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                    <label>{{ $t('AddSupplier.ContactPerson1') }} :</label>
                                    <div v-bind:class="{'has-danger' : $v.supplier.contactPerson1.$error}">
                                        <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.supplier.contactPerson1.$model" />
                                        <span v-if="$v.supplier.contactPerson1.$error" class="error text-danger">
                                        </span>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">

                                    <label>{{ $t('AddSupplier.ContactNumber1') }}:</label>
                                    <div v-bind:class="{'has-danger' : $v.supplier.contactNo1.$error}">
                                        <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.supplier.contactNo1.$model" />
                                        <span v-if="$v.supplier.contactNo1.$error" class="error text-danger">
                                        </span>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                    <label>{{ $t('AddSupplier.ContactPerson2') }}:</label>
                                    <div v-bind:class="{'has-danger' : $v.supplier.contactPerson2.$error}">
                                        <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.supplier.contactPerson2.$model" />
                                        <span v-if="$v.supplier.contactPerson2.$error" class="error text-danger">

                                        </span>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                    <label>{{ $t('AddSupplier.ContactNumber2') }} :</label>
                                    <div v-bind:class="{'has-danger' : $v.supplier.contactNo2.$error}">
                                        <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.supplier.contactNo2.$model" />
                                        <span v-if="$v.supplier.contactNo2.$error" class="error text-danger">

                                        </span>
                                    </div>
                                </div>


                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                    <label>{{ $t('AddSupplier.City') }}:</label>
                                    <div v-bind:class="{'has-danger' : $v.supplier.city.$error}">
                                        <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.supplier.city.$model" />
                                        <span v-if="$v.supplier.city.$error" class="error text-danger">

                                        </span>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                    <label>{{ $t('AddSupplier.Country') }}:</label>
                                    <div v-bind:class="{'has-danger' : $v.supplier.country.$error}">
                                        <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.supplier.country.$model" />
                                        <span v-if="$v.supplier.country.$error" class="error text-danger">
                                        </span>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                    <label>{{ $t('AddSupplier.Address') }}:</label>
                                    <div v-bind:class="{'has-danger' : $v.supplier.address.$error}">
                                        <textarea class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.supplier.address.$model" />
                                        <span v-if="$v.supplier.address.$error" class="error text-danger">
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

                <div v-if="active == 'Banks'">

                    <div class="card-body ">
                        <div class="row">

                            <div class="col-md-12"><h6>{{ $t('AddSupplier.BankAccount1') }}</h6></div>
                            <div class="col-sm-4">
                                <label>{{ $t('AddSupplier.BankAccountTitle') }} :</label>
                                <div v-bind:class="{'has-danger' : $v.supplier.bankAccountTitle1.$error}">
                                    <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" type="text" v-model="$v.supplier.bankAccountTitle1.$model" />
                                    <span v-if="$v.supplier.bankAccountTitle1.$error" class="error text-danger">
                                    </span>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <label for="supplier.bankAccountNo1">{{ $t('AddSupplier.BankAccountNo') }} :</label>
                                <div v-bind:class="{'has-danger' : $v.supplier.bankAccountNo1.$error}">
                                    <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" type="text" v-model="$v.supplier.bankAccountNo1.$model" />
                                    <span v-if="$v.supplier.bankAccountNo1.$error" class="error text-danger">
                                    </span>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <label for="supplier.iBAN1">{{ $t('AddSupplier.IBAN') }} :</label>
                                <div v-bind:class="{'has-danger' : $v.supplier.iBAN1.$error}">
                                    <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" type="text" v-model="$v.supplier.iBAN1.$model" />
                                    <span v-if="$v.supplier.iBAN1.$error" class="error text-danger">
                                    </span>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <label for="supplier.nameOfBank1">{{ $t('AddSupplier.NameofBank') }} :</label>
                                <div v-bind:class="{'has-danger' : $v.supplier.nameOfBank1.$error}">
                                    <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" type="text" v-model="$v.supplier.nameOfBank1.$model" />
                                    <span v-if="$v.supplier.nameOfBank1.$error" class="error text-danger">
                                    </span>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <label for="supplier.branchName1">{{ $t('AddSupplier.BranchName') }}:</label>
                                <div v-bind:class="{'has-danger' : $v.supplier.branchName1.$error}">
                                    <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" type="text" v-model="$v.supplier.branchName1.$model" />
                                    <span v-if="$v.supplier.branchName1.$error" class="error text-danger">
                                    </span>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <label for="supplier.routingCode1">{{ $t('AddSupplier.RoutingCode') }}:</label>
                                <div v-bind:class="{'has-danger' : $v.supplier.routingCode1.$error}">
                                    <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" type="text" v-model="$v.supplier.routingCode1.$model" />
                                    <span v-if="$v.supplier.routingCode1.$error" class="error text-danger">
                                    </span>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <label for="supplier.city1">{{ $t('AddSupplier.City') }}:</label>
                                <div v-bind:class="{'has-danger' : $v.supplier.city1.$error}">
                                    <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" type="text" v-model="$v.supplier.city1.$model" />
                                    <span v-if="$v.supplier.city1.$error" class="error text-danger">
                                    </span>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <label for="supplier.country1">{{ $t('AddSupplier.Country') }} :</label>
                                <div v-bind:class="{'has-danger' : $v.supplier.country1.$error}">
                                    <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" type="text" v-model="$v.supplier.country1.$model" />
                                    <span v-if="$v.supplier.country1.$error" class="error text-danger">
                                    </span>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <label>{{ $t('AddSupplier.AccountCurrency') }} :</label>
                                <div>
                                    <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="supplier.currency1" />

                                </div>
                            </div>
                            <div class="col-sm-12">
                                <label for="supplier.address1">{{ $t('AddSupplier.Address') }} :</label>
                                <div v-bind:class="{'has-danger' : $v.supplier.address1.$error}">
                                    <textarea class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" type="text" v-model="$v.supplier.address1.$model" />
                                    <span v-if="$v.supplier.address1.$error" class="error text-danger">
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-12 mt-3"><h6>{{ $t('AddSupplier.BankAccount2') }}</h6></div>
                            <div class="col-sm-4">
                                <label>{{ $t('AddSupplier.BankAccountTitle') }} :</label>
                                <div v-bind:class="{'has-danger' : $v.supplier.bankAccountTitle2.$error}">
                                    <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" type="text" v-model="$v.supplier.bankAccountTitle2.$model" />
                                    <span v-if="$v.supplier.bankAccountTitle2.$error" class="error text-danger">
                                    </span>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <label for="supplier.bankAccountNo2">{{ $t('AddSupplier.BankAccountNo') }} :</label>
                                <div v-bind:class="{'has-danger' : $v.supplier.bankAccountNo2.$error}">
                                    <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" type="text" v-model="$v.supplier.bankAccountNo2.$model" />
                                    <span v-if="$v.supplier.bankAccountNo2.$error" class="error text-danger">
                                    </span>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <label for="supplier.iBAN2">{{ $t('AddSupplier.IBAN') }} :</label>
                                <div v-bind:class="{'has-danger' : $v.supplier.iBAN2.$error}">
                                    <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" type="text" v-model="$v.supplier.iBAN2.$model" />
                                    <span v-if="$v.supplier.iBAN2.$error" class="error text-danger">
                                    </span>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <label for="supplier.nameOfBank2">{{ $t('AddSupplier.NameofBank') }}:</label>
                                <div v-bind:class="{'has-danger' : $v.supplier.nameOfBank2.$error}">
                                    <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" type="text" v-model="$v.supplier.nameOfBank2.$model" />
                                    <span v-if="$v.supplier.nameOfBank2.$error" class="error text-danger">
                                    </span>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <label for="supplier.branchName2">{{ $t('AddSupplier.BranchName') }}:</label>
                                <div v-bind:class="{'has-danger' : $v.supplier.branchName2.$error}">
                                    <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" type="text" v-model="$v.supplier.branchName2.$model" />
                                    <span v-if="$v.supplier.branchName2.$error" class="error text-danger">
                                    </span>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <label for="supplier.routingCode2">{{ $t('AddSupplier.RoutingCode') }} :</label>
                                <div v-bind:class="{'has-danger' : $v.supplier.routingCode2.$error}">
                                    <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" type="text" v-model="$v.supplier.routingCode2.$model" />
                                    <span v-if="$v.supplier.routingCode2.$error" class="error text-danger">
                                    </span>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <label for="supplier.city2">{{ $t('AddSupplier.City') }} :</label>
                                <div v-bind:class="{'has-danger' : $v.supplier.city2.$error}">
                                    <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" type="text" v-model="$v.supplier.city2.$model" />

                                </div>
                            </div>
                            <div class="col-sm-4">
                                <label for="supplier.country2">{{ $t('AddSupplier.Country') }} :</label>
                                <div v-bind:class="{'has-danger' : $v.supplier.country2.$error}">
                                    <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" type="text" v-model="$v.supplier.country2.$model" />

                                </div>
                            </div>
                            <div class="col-sm-4">
                                <label>{{ $t('AddSupplier.AccountCurrency') }} :</label>
                                <div>
                                    <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="supplier.currency2" />

                                </div>
                            </div>
                            <div class="col-sm-12">
                                <label for="supplier.address2">{{ $t('AddSupplier.Address') }} :</label>
                                <div v-bind:class="{'has-danger' : $v.supplier.address2.$error}">
                                    <textarea class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" type="text" v-model="$v.supplier.address2.$model" />

                                </div>
                            </div>
                        </div>


                    </div>


                </div>

                <div v-if="active == 'TermsandConditions'">
                    <div class="card-body ">
                        <div class="row ">
                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                <label>{{ $t('AddSupplier.PaymentTerms') }} :<span class="LabelColour"> *</span></label>
                                <div v-bind:class="{'has-danger' : $v.supplier.paymentTerms.$error}">
                                    <multiselect v-model="$v.supplier.paymentTerms.$model" v-if="($i18n.locale == 'en' ||isLeftToRight()) " :options="[ 'Cash', 'Credit']" :show-labels="false" placeholder="Select Type">
                                    </multiselect>
                                    <multiselect v-else v-model="$v.supplier.paymentTerms.$model" :options="[ 'نقد', 'آجل']" :show-labels="false" v-bind:placeholder="$t('AddSupplierSelectOption')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    </multiselect>
                                    <span v-if="$v.supplier.paymentTerms.$error" class="error text-danger">
                                        <span v-if="!$v.supplier.paymentTerms.required">{{ $t('AddSupplier.PaymentValidation') }} </span>
                                    </span>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4" v-if="supplier.paymentTerms=='Credit' || supplier.paymentTerms=='آجل'">
                                <label>{{ $t('AddSupplier.CreditLimit') }}:</label>
                                <my-currency-input v-model="supplier.creditLimit"></my-currency-input>
                                <!--<input class="form-control " v-model="supplier.creditLimit" type="number" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" />-->


                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4" v-if="supplier.paymentTerms=='Credit'|| supplier.paymentTerms=='آجل'">
                                <label>{{ $t('AddSupplier.CreditPeriod') }}:</label>

                                <input class="form-control " type="number" v-model="supplier.creditPeriod" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" />


                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                <label>{{ $t('AddSupplier.DeliveryTerm') }}:</label>
                                <div v-bind:class="{'has-danger' : $v.supplier.deliveryTerm.$error}">
                                    <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.supplier.deliveryTerm.$model" />
                                    <span v-if="$v.supplier.deliveryTerm.$error" class="error text-danger">
                                    </span>
                                </div>
                            </div>

                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                <label>{{ $t('AddSupplier.ExpiryDate') }} :</label>
                                <div v-bind:class="{'has-danger' : $v.supplier.expiryDate.$error}">
                                    <datepicker v-model="$v.supplier.expiryDate.$model" :key="daterander"></datepicker>
                                    <span v-if="$v.supplier.expiryDate.$error" class="error text-danger">
                                    </span>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <label>{{ $t('AddSupplier.Remarks') }} :</label>

                                <textarea class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="supplier.remarks" />

                            </div>




                        </div>
                    </div>

                </div>
                <!--<div v-if="active == 'Attachments'">
            <div class="card-body">
                <div class="" v-if="(supplier.contactAttachments[0].commercialRegistration == '' &&
                                         supplier.contactAttachments[0].vatCertificate == '' &&
                                         supplier.contactAttachments[0].zakatCertificate == '') || isAddAttachment">

                    <div class="card-body ">
                        <div class="row">
                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                <label>{{ $t('AddSupplier.CommercialRegistration') }}:</label>
                                <div>
                                    <span>
                                        <input ref="imgupload1" type="file" id="file-input"
                                               @change="uploadImage('commercialRegistration')"
                                               name="image" style="opacity:1;padding:25px">
                                    </span>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                <label>{{ $t('AddSupplier.VAT/NTN/TaxCertificate') }}:</label>
                                <div>
                                    <span>-->
                <!--<img src="Attachments.png" width="100" height="100"/>-->
                <!--<input ref="imgupload2" type="file" id="file-input"
                                               @change="uploadImage('vatCertificate')" name="image" style="opacity:1;padding:25px;">

                                    </span>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                <label>{{ $t('AddSupplier.ZakatCertificate') }} :</label>
                                <div>
                                    <span>
                                        <input ref="imgupload3" type="file" id="file-input"
                                               @change="uploadImage('zakatCertificate')"
                                               name="image" style="opacity:1;padding:25px">
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="" v-else>
                    <div class="col-md-12">
                        <div class="ml-2 mt-2 col-sm- float-left">
                            <h5 class="card-title pl-3 ">{{ $t('AddSupplier.Attachments') }}</h5>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 float-right">
                            <a href="javascript:void(0)" class="btn btn-outline-primary  float-right" v-on:click="isAddAttachment=true"><i class="fa fa-upload"></i> Upload</a>
                        </div>
                    </div>
                    <div class="card-body ">
                        <div class=" table-responsive">
                            <table class="table ">
                                <thead class="m-0">
                                    <tr>
                                        <th>#</th>
                                        <th>{{ $t('AddSupplier.Date') }} </th>
                                        <th>{{ $t('AddSupplier.CommercialRegistration') }} </th>
                                        <th>{{ $t('AddSupplier.VAT/NTN/TaxCertificate') }}</th>
                                        <th>{{ $t('AddSupplier.ZakatCertificate') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(contact,index) in supplier.contactAttachments" v-bind:key="index">
                                        <td>
                                            {{index+1}}
                                        </td>
                                        <th>{{contact.date  | formatDate}}</th>
                                        <td>
                                            <button class="btn btn-primary  btn-icon mr-2"
                                                    v-if="contact.commercialRegistration != ''"
                                                    v-on:click="DownloadAttachment(contact.commercialRegistration)">
                                                <i class="fa fa-download"></i>
                                            </button>
                                        </td>
                                        <td>
                                            <button class="btn btn-primary  btn-icon mr-2"
                                                    v-if="contact.vatCertificate != ''"
                                                    v-on:click="DownloadAttachment(contact.vatCertificate)">
                                                <i class="fa fa-download"></i>
                                            </button>
                                        </td>
                                        <td>
                                            <button class="btn btn-primary  btn-icon mr-2"
                                                    v-if="contact.zakatCertificate != ''"
                                                    v-on:click="DownloadAttachment(contact.zakatCertificate)">
                                                <i class="fa fa-download"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="float-left">
                            <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                            <span v-else-if="currentPage===1 && rowCount < 5">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                            <span v-else-if="currentPage===1 && rowCount >= 6  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                            <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                            <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                            <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                        </div>
                        <div class="float-right">
                            <div class="overflow-auto" v-on:click="GetContactAttachments(search, currentPage, supplier.id)">
                                <b-pagination pills size="lg" v-model="currentPage"
                                              :total-rows="rowCount"
                                              :per-page="5"
                                              first-text="First"
                                              prev-text="Previous"
                                              next-text="Next"
                                              last-text="Last"></b-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>-->
            </div>
            <div class="card-footer">
                <div class="row">
                    <div v-if="!loading" class=" col-md-12 arabicLanguage">
                        <button class="btn btn-primary mr-2 float-left" v-on:click="Attachment()">
                            {{ $t('PurchaseView.Attachment') }}
                        </button>

                        <button class="btn btn-primary   mr-2" :disabled="$v.supplier.$invalid" v-on:click="SaveSupplier" v-if="supplier.id=='00000000-0000-0000-0000-000000000000' && isValid('CanAddSupplier')"><i class="far fa-save"></i> {{ $t('AddSupplier.btnSave') }}</button>
                        <button class="btn btn-primary   mr-2" :disabled="$v.supplier.$invalid" v-on:click="SaveSupplier" v-if="supplier.id!='00000000-0000-0000-0000-000000000000' && isValid('CanEditSupplier')"><i class="far fa-save"></i> {{ $t('AddSupplier.btnUpdate') }}</button>
                        <button class="btn btn-danger   mr-2" v-on:click="Cancel">{{ $t('AddSupplier.btnClear') }}</button>
                    </div>
                    <div class="card-footer col-md-3 " v-else>
                        <loading :active.sync="loading" :can-cancel="true" :is-full-page="true"></loading>
                    </div>
                </div>
                
            </div>

            <bulk-attachment :attachmentList="supplier.attachmentList" :show="show" v-if="show" @close="attachmentSave" />

        </div>
    </div>
    <div v-else> <acessdenied></acessdenied></div>


</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import Multiselect from 'vue-multiselect'
    import moment from 'moment'
    import { required, maxLength, requiredIf } from 'vuelidate/lib/validators';
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default ({
        mixins: [clickMixin],
        components: {
            Loading,
            Multiselect,
        },
        data: function () {
            return {
                show: false,
                isRaw: '',
                arabic: '',
                english: '',
                value: '',
                isActiveRander: 0,
                active: 'PersonalInfo',
                supplier: {
                    id: '00000000-0000-0000-0000-000000000000',
                    code: '',
                    registrationDate: '',
                    category: '',
                    supplierType: '',
                    englishName: '',
                    arabicName: '',
                    commercialRegistrationNo: '',
                    expiryDate: '',
                    vatNo: '',
                    email: '',
                    contactPerson1: '',
                    contactNo1: '',
                    contactPerson2: '',
                    contactNo2: '',
                    country: '',
                    city: '',
                    address: '',
                    paymentTerms: '',
                    deliveryTerm: '',
                    remarks: '',
                    accountId: '',
                    bankAccountTitle1: '',
                    bankAccountNo1: '',
                    iBAN1: '',
                    nameOfBank1: '',
                    branchName1: '',
                    routingCode1: '',
                    address1: '',
                    city1: '',
                    country1: '',
                    telephone: '',
                    website: '',
                    coordinator: '',
                    currency1: '',
                    currency2: '',
                    bankAccountTitle2: '',
                    iBAN2: '',
                    bankAccountNo2: '',
                    nameOfBank2: '',
                    branchName2: '',
                    routingCode2: '',
                    address2: '',
                    city2: '',
                    country2: '',
                    commercialRegistration: '',
                    vatCertificate: '',
                    zakatCertificate: '',
                    creditLimit: 0,
                    creditPeriod: '',
                    isCutomer: false,
                    isActive: true,
                    contactAttachments: [{
                        commercialRegistration: '',
                        vatCertificate: '',
                        zakatCertificate: '',
                    }],
                    isRaw: false,
                    attachmentList: [],
                },
                loading: false,
                daterander: 0,
                isAddAttachment: false,
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                search: '',
                language: 'Nothing',
            }
        },
        validations: {

            supplier:
            {
                code: {},
                registrationDate: {},
                category: { maxLength: maxLength(250) },
                supplierType: { required },
                englishName: {
                    maxLength: maxLength(250)
                },
                arabicName: {
                    required: requiredIf((x) => {
                        if (x.englishName == '' || x.englishName == null)
                            return true;
                        return false;
                    }),
                    maxLength: maxLength(250)
                },
                vatNo: {
                    required: requiredIf((x) => {
                        if (x.commercialRegistrationNo == '' || x.commercialRegistrationNo == null || x.commercialRegistrationNo == undefined) {
                            if (x.supplierType == 'International Supplier' || x.supplierType == 'مزود دولي' || x.supplierType == 'International Manufacturers' || x.supplierType == 'الشركات المصنعة العالمية' || x.supplierType == 'International Agent / Exporter' || x.supplierType == 'وكيل / مصدر دولي')
                                return false;
                            return true;
                        }
                        else {
                            return false;
                        }

                    }),
                    maxLength: maxLength(250)
                },
                expiryDate: {},
                email: {},
                contactPerson1: {},
                contactNo1: {},
                contactPerson2: {},
                contactNo2: {},
                country: {},
                city: {},
                accountId: {},
                address: {},
                paymentTerms: { required},
                deliveryTerm: {},
                remarks: {},

                bankAccountTitle1: {},
                bankAccountNo1: {},
                iBAN1: {},
                nameOfBank1: {},
                branchName1: {},
                routingCode1: {},
                address1: {},
                city1: {},
                country1: {},

                bankAccountTitle2: {},
                bankAccountNo2: {},
                iBAN2: {},
                nameOfBank2: {},
                branchName2: {},
                routingCode2: {},
                address2: {},
                city2: {},
                country2: {}
            }
        },
        methods: {
            Attachment: function () {
                this.show = true;
            },

            attachmentSave: function (attachment) {
                this.supplier.attachmentList = attachment;
                this.show = false;
            },

            languageChange: function (lan) {
                if (this.language == lan) {
                    if (this.supplier.id == '00000000-0000-0000-0000-000000000000') {

                        var getLocale = this.$i18n.locale;
                        this.language = getLocale;

                        this.$router.go('/addsupplier');
                    }
                    else {

                        this.$swal({
                            title: (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'Error!' : 'خطأ',
                            text: (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'You cannot Change language during update, otherwise your current page data will be lose!' : 'لا يمكنك تغيير اللغة أثناء التحديث ، وإلا ستفقد بيانات صفحتك الحالية!',
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            icon: 'error',
                            timer: 4000,
                            timerProgressBar: true,
                        });
                    }
                }


            },


            DeleteAttachment(path, type) {
                var root = this;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.get('/Contact/DeleteAttachment?path=' + path, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        if (response.data != null) {
                            if (type == 'commercialRegistration') {
                                root.supplier.commercialRegistration = response.data;
                                root.supplier.contactAttachments[0].commercialRegistration = response.data;
                            }

                            if (type == 'vatCertificate') {
                                root.supplier.vatCertificate = response.data;
                                root.supplier.contactAttachments[0].vatCertificate = response.data;
                            }

                            if (type == 'zakatCertificate') {
                                root.supplier.zakatCertificate = response.data;
                                root.supplier.contactAttachments[0].zakatCertificate = response.data;
                            }
                        }
                    }
                });
            },
            uploadImage(type) {

                var root = this;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                var file = null;

                if (type == 'commercialRegistration') {
                    file = this.$refs.imgupload1.files;
                }

                if (type == 'vatCertificate') {
                    file = this.$refs.imgupload2.files;
                }

                if (type == 'zakatCertificate') {
                    file = this.$refs.imgupload3.files;
                }
                var fileData = new FormData();
                for (var k = 0; k < file.length; k++) {
                    fileData.append("files", file[k]);
                }
                root.$https.post('/Company/UploadFilesAsync', fileData, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {
                            if (type == 'commercialRegistration') {
                                root.supplier.commercialRegistration = response.data;
                            }

                            if (type == 'vatCertificate') {
                                root.supplier.vatCertificate = response.data;
                            }

                            if (type == 'zakatCertificate') {
                                root.supplier.zakatCertificate = response.data;
                            }
                        }
                    },
                        function () {
                            this.loading = false;
                            root.$swal({
                                title: "Error!",
                                text: "Something went wrong",
                                type: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                        });
            },

            DownloadAttachment(path) {
                var root = this;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                var ext = path.split('.')[1];
                root.$https.get('/Contact/DownloadFile?filePath=' + path, { headers: { "Authorization": `Bearer ${token}` }, responseType: 'blob' })
                    .then(function (response) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'file.' + ext);
                        document.body.appendChild(link);
                        link.click();
                    });
            },

            GetAutoCodeGenerator: function () {
                var root = this;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https
                    .get('/Contact/AutoGenerateCode?issupplier=false', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                        if (response.data != null) {
                            root.supplier.code = response.data;
                        }
                    });
            },
            Cancel: function () {
                if (this.isValid('CanViewSupplier')) {
                    this.$router.push({
                        path: '/supplier',

                    })
                }
                else {
                    this.$router.go();
                }
                
            },

            makeActive: function (tab) {

                this.active = tab;
            },
            SaveSupplier: function () {

                this.loading = true;
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                if (this.supplier.supplierType == 'جمله') {
                    this.supplier.supplierType = 1;
                }
                else if (this.supplier.supplierType == 'قطاعي') {
                    this.supplier.supplierType = 2;
                }
                else if (this.supplier.supplierType == 'بائع بالجملة') {
                    this.supplier.supplierType = 5;
                }
                else if (this.supplier.supplierType == 'وكيل') {
                    this.supplier.supplierType = 3;
                }
                else if (this.supplier.supplierType == 'موزع') {
                    this.supplier.supplierType = 4;
                }
                else if (this.supplier.supplierType == 'Wholesaler & Retailer') {
                    this.supplier.supplierType = 5;
                }
                else if (this.supplier.supplierType == 'International Supplier' || this.supplier.supplierType == 'مزود دولي') {
                    this.supplier.supplierType = 6;
                }
                else if (this.supplier.supplierType == 'International Manufacturers' || this.supplier.supplierType == 'الشركات المصنعة العالمية') {
                    this.supplier.supplierType = 7;
                }
                else if (this.supplier.supplierType == 'International Agent / Exporter' || this.supplier.supplierType == 'وكيل / مصدر دولي') {
                    this.supplier.supplierType = 8;
                }
                else {
                    console.log(this.supplier.supplierType);
                }
                root.$https
                    .post('/Contact/SaveContact', this.supplier, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {
                        if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Add") {
                            root.loading = false
                            root.info = response.data.bpi

                            root.$swal({
                                title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved Successfully' : 'حفظ بنجاح',
                                text: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved' : 'تم الحفظ',
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonStyling: false,
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,

                            }).then(function (ok) {
                                if (ok != null) {
                                    if (root.isValid('CanViewSupplier')) {
                                        root.$router.push({
                                            path: '/supplier',

                                        })
                                    }
                                    else {
                                        root.$router.go();
                                    }
                                   
                                }
                            });
                        }
                        else if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Update") {
                            root.loading = false
                            root.info = response.data.bpi

                            root.$swal({
                                title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved Successfully' : 'حفظ بنجاح',
                                text: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved' : 'تم الحفظ',
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonStyling: false,
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,

                            }).then(function (ok) {
                                if (ok != null) {
                                    if (root.isValid('CanViewSupplier')) {
                                        root.$router.push({
                                            path: '/supplier',

                                        })
                                    }
                                    else {
                                        root.$router.go();
                                    }
                                }
                            });
                        }
                        else {
                            root.$swal({
                                title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                                text: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'There is something wrong. Please contact to support.' : 'هناك شيء ما خاطئ. يرجى الاتصال للدعم.',
                                type: 'error',
                                confirmButtonClass: "btn btn-danger",
                                icon: 'error',
                                timer: 1500,
                                timerProgressBar: true,
                            });
                        }

                    })
                    .catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                                text: error.response.data,
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false
                    })
                    .finally(() => root.loading = false)
            },

            GetContactAttachments: function (search, currentPage, id) {
                var root = this;
                var url = '/contact/GetContactAttachments?searchTerm=' + search + '&pageNumber=' + currentPage + '&id=' + id;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get(url, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data.results.length > 0) {
                        root.supplier.contactAttachments = response.data.results;
                        root.supplier.commercialRegistration = response.data.results[0].commercialRegistration;
                        root.supplier.vatCertificate = response.data.results[0].vatCertificate;
                        root.supplier.zakatCertificate = response.data.results[0].zakatCertificate;
                    }
                    else {
                        root.supplier.contactAttachments = [{
                            commercialRegistration: '',
                            vatCertificate: '',
                            zakatCertificate: '',
                        }]
                    }

                    root.pageCount = response.data.pageCount;
                    root.rowCount = response.data.rowCount;
                });
            }

        },
        created: function () {
            this.$emit('input', this.$route.name);

            this.language = this.$i18n.locale;
            //eslint-disable-line
            if (this.$route.query.data != undefined) {


                this.supplier = this.$route.query.data;
                if (this.language == 'en') {
                    if (this.$route.query.data.supplierType == 1) {
                        this.supplier.supplierType = 'Wholesaler';
                    }
                    else if (this.$route.query.data.supplierType == 2) {
                        this.supplier.supplierType = 'Retailer';
                    }
                    else if (this.$route.query.data.supplierType == 5) {
                        this.supplier.supplierType = 'Wholesaler & Retailer';
                    }
                    else if (this.$route.query.data.supplierType == 3) {
                        this.supplier.supplierType = 'Dealer';
                    }
                    else if (this.$route.query.data.supplierType == 4) {
                        this.supplier.supplierType = 'Distributor';
                    }
                    else if (this.$route.query.data.supplierType == 6) {
                        this.supplier.supplierType = 'International Supplier';
                    }
                    else if (this.$route.query.data.supplierType == 7) {
                        this.supplier.supplierType = 'International Manufacturers';
                    }
                    else if (this.$route.query.data.supplierType == 8) {
                        this.supplier.supplierType = 'International Agent / Exporter';
                    }
                    else {
                        this.supplier.supplierType = '';
                    }                    
                }
                else {
                    if (this.$route.query.data.supplierType == 1) {
                        this.supplier.supplierType = 'جمله';
                    }
                    else if (this.$route.query.data.supplierType == 2) {
                        this.supplier.supplierType = 'قطاعي';
                    }
                    else if (this.$route.query.data.supplierType == 5) {
                        this.supplier.supplierType = 'بائع بالجملة';
                    }
                    else if (this.$route.query.data.supplierType == 3) {
                        this.supplier.supplierType = 'وكيل';
                    }
                    else if (this.$route.query.data.supplierType == 4) {
                        this.supplier.supplierType = 'موزع';
                    }
                    else if (this.$route.query.data.supplierType == 6) {
                        this.supplier.supplierType = 'مزود دولي';
                    }
                    else if (this.$route.query.data.supplierType == 7) {
                        this.supplier.supplierType = 'الشركات المصنعة العالمية';
                    }
                    else if (this.$route.query.data.supplierType == 8) {
                        this.supplier.supplierType = 'وكيل / مصدر دولي';
                    }
                    else {
                        this.supplier.supplierType = '';
                    }                    
                }

                this.GetContactAttachments(this.search, 1, this.supplier.id);
                this.daterander++;
                this.isActiveRander++;
            }
        },

        filters: {
            formatDate: function (value) {
                return moment(value).format("DD MMM yyyy hh:mm");
            }
        },

        mounted: function () {
            this.language = this.$i18n.locale;
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.isRaw = localStorage.getItem('IsProduction');
            if (this.$route.query.data == '00000000-0000-0000-0000-000000000000' || this.$route.query.data == undefined || this.$route.query.data == '') {
                this.GetAutoCodeGenerator();
                this.supplier.registrationDate = moment().format('llll');

                if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {
                    this.supplier.paymentTerms = 'Cash';
                }
                else {
                    this.supplier.paymentTerms = 'آجل';
                }
                this.daterander++;
            }
        }
    })

</script>
