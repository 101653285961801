<template>
    <div class="row " v-bind:style="$i18n.locale == 'ar' ? languageChange('en') : languageChange('ar')" v-if="isValid('CanAddSaleryTemplate') || isValid('CanEditSaleryTemplate')">
        <div class="col-md-12 ml-auto mr-auto" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <div class="card">
                <div>
                    <div class="card-header">
                        <h5 class="modal-title DayHeading" v-if="salaryTemplate.id=='00000000-0000-0000-0000-000000000000'" id="myModalLabel" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">{{ $t('AddSalaryTemplate.AddSalaryTemplate') }}</h5>
                        <h5 class="modal-title DayHeading" v-else id="myModalLabel" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">{{ $t('AddSalaryTemplate.UpdatePayrollSchedule') }}</h5>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4" v-bind:class="{'has-danger' : $v.salaryTemplate.code.$error}">
                                <label class="text  font-weight-bolder"> {{ $t('AddSalaryTemplate.Code') }}:<span class="LabelColour"> *</span></label>
                                <input disabled class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.salaryTemplate.code.$model" type="text" />
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4" v-bind:class="{'has-danger' : $v.salaryTemplate.structureName.$error}">
                                <label class="text  font-weight-bolder ">{{ $t('AddSalaryTemplate.StructureName') }}:<span class="LabelColour"> *</span></label>
                                <input class="form-control" v-model="$v.salaryTemplate.structureName.$model" type="text" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" />                                
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <h6 class="text  font-weight-bolder ">{{ $t('AddSalaryTemplate.AddAllowance') }}</h6>
                                <allowanceDropdown v-on:input="PushToList(allowanceId)" v-model="allowanceId"></allowanceDropdown>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <table class="table add_table_list_bg" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <thead v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                        <tr>
                                            <th>#</th>

                                            <th v-if="english=='true'">
                                                {{ $t('AddSalaryTemplate.AllowanceNameEnglish')}}
                                            </th>
                                            <th v-if="isOtherLang()" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                                {{ $t('AddSalaryTemplate.AllowanceNameArabic')}}
                                            </th>
                                            <th class="text-center">
                                                {{ $t('AddSalaryTemplate.CalculateAmount') }}
                                            </th>
                                            <th class="text-center">
                                                {{ $t('AddSalaryTemplate.AmountPercentage') }}
                                            </th>
                                            <th class="text-center">
                                                {{ $t('AddSalaryTemplate.Taxable') }}
                                            </th>
                                            <th class="text-center">
                                                {{ $t('AddSalaryTemplate.Action') }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                        <tr v-for="(allowance,index) in allowanceList" v-bind:key="allowance.id">
                                            <td>
                                                {{index+1}}
                                            </td>

                                            <td v-if="english=='true'">
                                                {{allowance.allowanceNameEn}}
                                            </td>
                                            <td v-if="isOtherLang()">
                                                {{allowance.allowanceNameAr}}
                                            </td>

                                            <td style="width:20%">
                                                <multiselect :options="calculateAmountOptions" v-model="allowance.amountType" @input="EditAllowance('amountType',allowance.amountType,index)" :show-labels="false" v-bind:placeholder="$t('SelectMethod')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                </multiselect>
                                            </td>
                                            <td class="text-center" style="width:20%">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? 'padding: 5px 15px 5px 5px;' : 'padding: 5px 5px 5px 15px;' "><i v-if="allowance.amountType=='% of Salary' || allowance.amountType=='٪ من الراتب'" class="fa fa-percent"></i> <i v-else>{{currency}}</i></span>
                                                    </div>
                                                    <input type="number" @focus="$event.target.select()" class="form-control amount_field" @blur="EditAllowance('amount',allowance.amount,index)" v-model="allowance.amount" />
                                                </div>
                                            </td>
                                            <td class="text-center">
                                                <input type="checkbox" class="checkBoxHeight" v-model="allowance.taxPlan"
                                                       v-on:change="EditAllowance('taxPlan',$event.target.checked, index)">
                                            </td>
                                            <td>
                                                <div>
                                                    <a href="javascript:void(0)" class="btn btn-danger btn-sm btn-icon btn-round" v-on:click="RemoveAllowance(index)"><i class=" fa fa-trash"></i></a>

                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>

                        </div>

                        <div class="row mt-4">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <h6 class="text  font-weight-bolder ">{{ $t('AddSalaryTemplate.AddDeduction') }} </h6>
                                <deductionDropdown v-on:input="PushToDeductionList(deductionId)" v-model="deductionId"></deductionDropdown>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <table class="table add_table_list_bg" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <thead v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                        <tr>
                                            <th>#</th>

                                            <th v-if="english=='true'">
                                                {{ $t('AddSalaryTemplate.DeductionNameEnglish')}}
                                            </th>
                                            <th v-if="isOtherLang()" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                                {{ $t('AddSalaryTemplate.DeductionNameArabic')}}
                                            </th>
                                            <th class="text-center">
                                                {{ $t('AddSalaryTemplate.CalculateAmount') }}
                                            </th>
                                            <th class="text-center">
                                                {{ $t('AddSalaryTemplate.AmountPercentage') }}
                                            </th>
                                            <th class="text-center">
                                                {{ $t('AddSalaryTemplate.Taxable') }}
                                            </th>
                                            <th class="text-center">
                                                {{ $t('AddSalaryTemplate.Action') }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                        <tr v-for="(deduction ,index) in deductionList" v-bind:key="deduction.id">
                                            <td>
                                                {{index+1}}
                                            </td>
                                            <td v-if="english=='true'">
                                                {{deduction.nameInPayslip}}
                                            </td>
                                            <td v-if="isOtherLang()">
                                                {{deduction.nameInPayslipArabic}}
                                            </td>
                                            <td style="width:20%">
                                                <multiselect :options="calculateAmountOptions" v-model="deduction.amountType" @input="EditDeduction('amountType',deduction.amountType,index)" :show-labels="false" v-bind:placeholder="$t('SelectMethod')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                </multiselect>
                                            </td>
                                            <td class="text-center" style="width:20%">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? 'padding: 5px 15px 5px 5px;' : 'padding: 5px 5px 5px 15px;' "><i v-if="deduction.amountType=='% of Salary' || deduction.amountType=='٪ من الراتب'" class="fa fa-percent"></i> <i v-else>{{currency}}</i></span>
                                                    </div>
                                                    <input type="number" @focus="$event.target.select()" class="form-control amount_field" @blur="EditDeduction('amount',deduction.amount,index)" v-model="deduction.amount" />
                                                </div>
                                            </td>
                                            <td class="text-center">
                                                <input type="checkbox" class="checkBoxHeight" v-model="deduction.taxPlan"
                                                       v-on:change="EditDeduction('taxPlan',$event.target.checked, index)">
                                            </td>
                                            <td class="text-center">
                                                <div>
                                                    <a href="javascript:void(0)" class="btn btn-danger btn-sm btn-icon btn-round" v-on:click="RemoveDeduction(index)"><i class=" fa fa-trash"></i></a>

                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>


                        <div class="row mt-4">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <h6 class="text  font-weight-bolder ">{{ $t('AddSalaryTemplate.AddContribution') }} </h6>
                                <contributionDropdown v-on:input="PushToContributionList(contributionId)" v-model="contributionId"></contributionDropdown>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <table class="table add_table_list_bg" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <thead v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                        <tr>
                                            <th>#</th>

                                            <th v-if="english=='true'">
                                                {{ $t('AddSalaryTemplate.ContributionNameEnglish')}}
                                            </th>
                                            <th v-if="isOtherLang()" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                                {{ $t('AddSalaryTemplate.ContributionNameArabic')}}
                                            </th>
                                            <th class="text-center">
                                                {{ $t('AddSalaryTemplate.CalculateAmount') }}
                                            </th>
                                            <th class="text-center">
                                                {{ $t('AddSalaryTemplate.AmountPercentage') }}
                                            </th>

                                            <th class="text-center">
                                                {{ $t('AddSalaryTemplate.Action') }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                        <tr v-for="(contribution ,index) in contributionList" v-bind:key="contribution.id">
                                            <td>
                                                {{index+1}}
                                            </td>
                                            <td v-if="english=='true'">
                                                {{contribution.nameInPayslip}}
                                            </td>
                                            <td v-if="isOtherLang()">
                                                {{contribution.nameInPayslipArabic}}
                                            </td>
                                            <td style="width:20%">
                                                <multiselect :options="calculateAmountOptions" v-model="contribution.amountType" @input="EditContribution('amountType',contribution.amountType,index)" :show-labels="false" v-bind:placeholder="$t('SelectMethod')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                </multiselect>
                                            </td>
                                            <td class="text-center" style="width:20%">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? 'padding: 5px 15px 5px 5px;' : 'padding: 5px 5px 5px 15px;' "><i v-if="contribution.amountType=='% of Salary' || contribution.amountType=='٪ من الراتب'" class="fa fa-percent"></i> <i v-else>{{currency}}</i></span>
                                                    </div>
                                                    <input type="number" @focus="$event.target.select()" class="form-control amount_field" @blur="EditContribution('amount',contribution.amount,index)" v-model="contribution.amount" />
                                                </div>
                                            </td>

                                            <td class="text-center">
                                                <div>
                                                    <a href="javascript:void(0)" class="btn btn-danger btn-sm btn-icon btn-round" v-on:click="RemoveContribution(index)"><i class=" fa fa-trash"></i></a>

                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row ">

                            <div class="col-sm-12 arabicLanguage" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <button type="button" class="btn btn-success" v-bind:disabled="$v.salaryTemplate.$invalid" v-on:click="SaveSalaryTemplate" v-if="salaryTemplate.id=='00000000-0000-0000-0000-000000000000' && isValid('CanAddSaleryTemplate') "><i class="far fa-save"></i> {{ $t('AddSalaryTemplate.Save') }} </button>
                                <button type="button" class="btn btn-success" v-bind:disabled="$v.salaryTemplate.$invalid" v-on:click="SaveSalaryTemplate" v-if="salaryTemplate.id!='00000000-0000-0000-0000-000000000000' && isValid('CanEditSaleryTemplate') "><i class="far fa-save"></i> {{ $t('AddSalaryTemplate.Update') }} </button>
                                <button type="button" class="btn btn-danger mr-1 ml-1" v-on:click="Close()">{{ $t('AddSalaryTemplate.Cancel') }}</button>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import 'vue-loading-overlay/dist/vue-loading.css';
    import { required, maxLength } from 'vuelidate/lib/validators';
    import Multiselect from 'vue-multiselect'


    export default ({
        components: {
            Multiselect
        },
        mixins: [clickMixin],

        data: function () {
            return {
                currency: '',
                allowanceId: '',
                deductionId: '',
                contributionId: '',
                arabic: '',
                english: '',
                salaryTemplate: {
                    id: '00000000-0000-0000-0000-000000000000',
                    code: '',
                    structureName: '',
                    date: '',
                    salaryAllowances: [],
                    salaryDeductions: [],
                    salaryContributions: [],
                },
                dateRender: 0,
                allowanceList: [],
                deductionList: [],
                contributionList: [],
                calculateAmountOptions: [],

                language: 'Nothing',
            }
        },
        validations: {
            salaryTemplate:
            {
                structureName: {
                    required,
                    maxLength: maxLength(50)
                },
                code: {
                    maxLength: maxLength(30)
                },

            },

        },
        methods: {
            GetAutoCodeGenerator: function () {

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.get('/Payroll/SalaryTemplateAutoGenerateNo', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                    if (response.data != null) {
                        root.salaryTemplate.code = response.data;
                    }
                });
            },

            EditContribution: function (label, value, index) {
                
                if (label == 'amount') {
                    this.contributionList[index].amount = value;
                }
                else if (label == 'amountType') {
                    this.contributionList[index].amountType = value;
                }
               


            },
            EditDeduction: function (label, value, index) {
                
                if (label == 'amount') {
                    this.deductionList[index].amount = value;
                }
                else if (label == 'amountType') {
                    this.deductionList[index].amountType = value;
                }
                else if (label == 'taxPlan') {
                    this.deductionList[index].taxPlan = value;

                }


            },
            RemoveContribution: function (index) {
                this.contributionList.splice(index, 1);
            },
            RemoveDeduction: function (index) {
                this.deductionList.splice(index, 1);
            },
            RemoveAllowance: function (index) {
                this.allowanceList.splice(index, 1);
            },
            PushToList: function (list) {
                
                var alreadyExist = false;
                alreadyExist = this.allowanceList.find(function (x) {
                    if (x.id === list.id)
                        return true;
                    return false;
                });
                if (alreadyExist) {
                    this.$swal({
                        title: 'Already Exist!',
                        text: 'Already Exist',
                        type: 'error',
                        confirmButtonClass: "btn btn-danger",
                        icon: 'error',
                        timer: 4000,
                        timerProgressBar: true,
                    });

                }
                else {
                    if (list.taxPlan == 2) {
                        list.taxPlan = false;
                    }
                    else if (list.taxPlan == 1) {
                        list.taxPlan = true;
                    }
                    if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {
                        if (list.amountType == 1) {
                            list.amountType = '% of Salary';
                        }
                        else if (list.amountType == 2) {
                            list.amountType = 'Fixed';
                        }



                    }
                    else {

                        if (list.amountType == 1) {
                            list.amountType = '٪ من الراتب';
                        }
                        else if (list.amountType == 2) {
                            list.amountType = 'مثبت';
                        }


                    }
                    this.allowanceList.push({
                        id: list.id,
                        allowanceNameEn: list.allowanceNameEn,
                        allowanceNameAr: list.allowanceNameAr,
                        amountType: list.amountType,
                        taxPlan: list.taxPlan,
                        amount: list.amount,

                    });
                }


            },
            PushToDeductionList: function (result) {
                
                var alreadyExist = false;
                alreadyExist = this.deductionList.find(function (x) {
                    if (x.id === result.id)
                        return true;
                    return false;
                });
                if (alreadyExist) {
                    this.$swal({
                        title: 'Already Exist!',
                        text: 'Already Exist',
                        type: 'error',
                        confirmButtonClass: "btn btn-danger",
                        icon: 'error',
                        timer: 4000,
                        timerProgressBar: true,
                    });

                }
                else {
                    if (result.taxPlan == 2) {
                        result.taxPlan = false;
                    }
                    else if (result.taxPlan == 1) {
                        result.taxPlan = true;
                    }
                    if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {
                        if (result.amountType == 1) {
                            result.amountType = '% of Salary';
                        }
                        else if (result.amountType == 2) {
                            result.amountType = 'Fixed';
                        }


                    }
                    else {

                        if (result.amountType == 1) {
                            result.amountType = '٪ من الراتب';
                        }
                        else if (result.amountType == 2) {
                            result.amountType = 'مثبت';
                        }

                    }
                    this.deductionList.push({
                        id: result.id,
                        nameInPayslip: result.nameInPayslip,
                        nameInPayslipArabic: result.nameInPayslipArabic,
                        amountType: result.amountType,
                        taxPlan: result.taxPlan,
                        amount: result.amount,

                    });
                }



            },
            PushToContributionList: function (result) {
                
                var alreadyExist = false;
                alreadyExist = this.contributionList.find(function (x) {
                    if (x.id === result.id)
                        return true;
                    return false;
                });
                if (alreadyExist) {
                    this.$swal({
                        title: 'Already Exist!',
                        text: 'Already Exist',
                        type: 'error',
                        confirmButtonClass: "btn btn-danger",
                        icon: 'error',
                        timer: 4000,
                        timerProgressBar: true,
                    });

                }
                else {
                   
                    if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {
                        if (result.amountType == 1) {
                            result.amountType = '% of Salary';
                        }
                        else if (result.amountType == 2) {
                            result.amountType = 'Fixed';
                        }


                    }
                    else {

                        if (result.amountType == 1) {
                            result.amountType = '٪ من الراتب';
                        }
                        else if (result.amountType == 2) {
                            result.amountType = 'مثبت';
                        }

                    }
                    this.contributionList.push({
                        id: result.id,
                        nameInPayslip: result.nameInPayslip,
                        nameInPayslipArabic: result.nameInPayslipArabic,
                        amountType: result.amountType,
                        amount: result.amount,

                    });
                }



            },
            languageChange: function (lan) {

                if (this.language == lan) {
                    if (this.salaryTemplate.id == '00000000-0000-0000-0000-000000000000') {

                        var getLocale = this.$i18n.locale;
                        this.language = getLocale;

                        this.$router.go('/addSale');
                    }
                    else {
                        this.$swal({
                            title: 'Error!',
                            text: 'You cannot Change language during update, otherwise your current page data will be lose',
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            icon: 'error',
                            timer: 4000,
                            timerProgressBar: true,
                        });
                    }
                }


            },
            Close: function () {
                this.$router.push('/SalaryTemplate');
            },
            SaveSalaryTemplate: function () {
                
                this.loading = true;
                var root = this;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.salaryTemplate.salaryAllowances = [];
                root.salaryTemplate.salaryDeductions = [];
                root.salaryTemplate.salaryContributions = [];


                root.allowanceList.forEach(function (result) {
                    root.salaryTemplate.salaryAllowances.push({
                        id: result.id,
                        amountType: result.amountType == '% of Salary' || result.amountType == '٪ من الراتب' ? 1 : 2,
                        taxPlan: result.taxPlan == true ? 1 : 2,
                        amount: result.amount,
                    })
                });
                root.deductionList.forEach(function (result) {
                    root.salaryTemplate.salaryDeductions.push({
                        id: result.id,
                        amountType: result.amountType == '% of Salary' || result.amountType == '٪ من الراتب' ? 1 : 2,
                        taxPlan: result.taxPlan == true ? 1 :2,
                        amount: result.amount,
                    })
                });
                root.contributionList.forEach(function (result) {
                    root.salaryTemplate.salaryContributions.push({
                        id: result.id,
                        amountType: result.amountType == '% of Salary' || result.amountType == '٪ من الراتب' ? 1 : 2,
                        amount: result.amount,
                    })
                });

                root.$https
                    .post('/Payroll/SaveSalaryTemplate', root.salaryTemplate, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {

                        if (response.data.isSuccess) {
                            root.loading = false

                            root.$swal({
                                title: "Saved!",
                                text: "Saved Sucessfully",
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonStyling: false,
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,

                            }).then(function (ok) {
                                if (ok != null) {
                                    root.$router.push('/SalaryTemplate');
                                }
                            });
                        }
                       
                        else {
                            root.$swal({
                                title: 'Error!',
                                text: response.data.message.isAddUpdate,
                                type: 'error',
                                confirmButtonClass: "btn btn-danger",
                                icon: 'error',
                                timer: 1500,
                                timerProgressBar: true,
                            });
                        }

                    })
                    .catch(error => {
                        console.log(error)
                        this.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Something Went Wrong!',
                                text: error,
                                showConfirmButton: false,
                                timer: 1000,
                                timerProgressBar: true,

                            });

                        this.loading = false
                    })
                    .finally(() => this.loading = false)
            }
        },
        created: function () {

            this.$emit('input', this.$route.name);
        },
        mounted: function () {
            var root = this;
            this.currency = localStorage.getItem('currency');
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            if (this.salaryTemplate.id == '00000000-0000-0000-0000-000000000000' || this.salaryTemplate.id == undefined || this.salaryTemplate.id == '')
                this.GetAutoCodeGenerator();
            this.language = this.$i18n.locale;
           
            if (this.$route.query.data != undefined) {
                

                this.salaryTemplate = this.$route.query.data;


                if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {
                    root.salaryTemplate.salaryAllowances.forEach(function (result) {
                        root.allowanceList.push({
                            id: result.id,
                            allowanceNameEn: result.allowanceNameEn,
                            allowanceNameAr: result.allowanceNameAr,
                            amountType: result.amountType == 1 ? '% of Salary' : 'Fixed',
                            taxPlan: result.taxPlan == 1 ? true : false,
                            amount: result.amount,
                        })
                    });
                    root.salaryTemplate.salaryDeductions.forEach(function (result) {
                        root.deductionList.push({
                            id: result.id,
                            nameInPayslip: result.nameInPayslip,
                            nameInPayslipArabic: result.nameInPayslipArabic,
                            amountType: result.amountType == 1 ? '% of Salary' : 'Fixed',
                            taxPlan: result.taxPlan == 1 ? true : false,
                            amount: result.amount,
                        })
                    });
                    root.salaryTemplate.salaryContributions.forEach(function (result) {
                        root.contributionList.push({
                            id: result.id,
                            nameInPayslip: result.nameInPayslip,
                            nameInPayslipArabic: result.nameInPayslipArabic,
                            amountType: result.amountType == 1? '% of Salary' : 'Fixed',
                            amount: result.amount,
                        })
                    });
                }
                else {
                    root.salaryTemplate.salaryAllowances.forEach(function (result) {
                        root.allowanceList.push({
                            id: result.id,
                            nameInPayslip: result.nameInPayslip,
                            nameInPayslipArabic: result.nameInPayslipArabic,
                            amountType: result.amountType == 1 ? '٪ من الراتب' : 'مثبت',
                            taxPlan: result.taxPlan == 1 ? true : false,
                            amount: result.amount,
                        })
                    });
                    root.salaryTemplate.salaryDeductions.forEach(function (result) {
                        root.deductionList.push({
                            id: result.id,
                            nameInPayslip: result.nameInPayslip,
                            nameInPayslipArabic: result.nameInPayslipArabic,
                            amountType: result.amountType == 1 ? '٪ من الراتب' : 'مثبت',
                            taxPlan: result.taxPlan == 1 ? true : false,
                            amount: result.amount,
                        })
                    });
                    root.salaryTemplate.salaryContributions.forEach(function (result) {
                        root.contributionList.push({
                            id: result.id,
                            amountType: result.amountType == 1 ? '٪ من الراتب' : 'مثبت',
                            amount: result.amount,
                        })
                    });                }


               

            }
            if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {
                this.calculateAmountOptions = ['% of Salary', 'Fixed'];
            }
            else {
                this.calculateAmountOptions = ['٪ من الراتب', 'مثبت'];
            }
        }
    })
</script>
<style scoped>
    .checkBoxHeight {
        width: 20px;
        height: 30px;
    }

    .input-group-append .input-group-text, .input-group-prepend .input-group-text {
        background-color: #e3ebf1;
        border: 1px solid #e3ebf1;
        color: #000000;
    }

    .input-group .form-control {
        border-left: 1px solid #e3ebf1;
    }

        .input-group .form-control:focus {
            border-left: 1px solid #3178F6;
        }

    .input-group-text {
        border-radius: 0;
    }
</style>