<template>
    <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
        <label>Add Items</label>

        <div v-if="invoiceWoInventory">
            <product-dropdown :raw="false"
                              @input="addProduct"
                              :fromSale="true"
                              width="100%" />
        </div>
        <div v-else>
            <product-dropdown :wareHouseId="wareHouseId"
                              :raw="false"
                              @input="addProduct"
                              :fromSale="true"
                              width="100%" />
        </div>

        <div class="  mt-4">
            <table class="table add_table_list_bg" style="table-layout:fixed;" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                <thead class="m-0">
                    <tr>
                        <th style="width: 20px;">
                            #
                        </th>
                        <th style="width: 150px;">
                            {{ $t('SaleItem.Product') }}
                        </th>
                        <th v-if="colorVariants" class="text-center" style="width: 200px;">
                            {{ $t('SaleItem.Description') }}
                        </th>

                        <th style="width: 150px;" v-if="colorVariants">
                            {{ $t('SaleItem.Color') }}
                        </th>

                        <th class="text-center" style="width: 90px;">
                            {{ $t('SaleItem.UnitPrice') }}
                        </th>

                        <th class="text-center" style="width: 70px;" v-if="isValid('CanViewUnitPerPack')">
                            {{ $t('SaleItem.UnitPerPack') }}
                        </th>
                        <th class="text-center" style="width: 90px;" v-if="isValid('CanViewCurrentQuantity')">
                            {{ $t('SaleItem.CurrentQuantity') }}
                        </th>
                        <th class="text-center" style="width: 80px;" v-if="isFifo">
                            {{ $t('SaleItem.BatchNo') }}
                        </th>
                        <th class="text-center" style="width: 80px;" v-if="isFifo">
                            {{ $t('SaleItem.ExpiryDate') }}
                        </th>
                        <th style="width: 100px;" class="text-center" v-if="isMultiUnit=='true'">
                            {{ $t('SaleItem.HighQty') }}
                        </th>
                        <th class="text-center" style="width: 80px;">
                            {{ $t('SaleItem.Quantity') }}
                        </th>
                        <th style="width: 100px;" class="text-center" v-if="isMultiUnit=='true'">
                            {{ $t('SaleItem.TOTALQTY') }}
                        </th>
                        <th class="text-center" style="width: 100px;" hidden>
                            {{ $t('SaleItem.ReturnDays') }}
                        </th>
                        <th style="width: 100px;" v-if="saleProducts.filter(x=> x.isBundleOffer).length > 0" hidden>
                            {{ $t('SaleItem.Bundle') }}
                        </th>

                        <th class="text-center" style="width: 115px;" v-if="isSerial">
                            {{ $t('SaleItem.Serial') }}
                        </th>
                        <th style="width: 100px;" v-if="isSerial">
                            {{ $t('SaleItem.Guarantee') }}
                        </th>

                        <th class="text-center" style="width: 70px;">
                            {{ $t('SaleItem.DISC%') }}
                        </th>
                        <th class="text-center" style="width: 70px;">
                            {{ $t('SaleItem.FixDisc') }}
                        </th>

                        <th v-if="saleDefaultVat == 'DefaultVatItem' || saleDefaultVat =='DefaultVatHeadItem'" style="width: 105px;">
                            {{ $t('AddPurchase.TaxMethod') }}
                        </th>
                        <th v-if="saleDefaultVat == 'DefaultVatItem' || saleDefaultVat =='DefaultVatHeadItem'" style="width: 130px;">
                            {{ $t('AddPurchase.VAT%') }}
                        </th>

                        <th class="text-right" style="width: 100px;">
                            {{ $t('SaleItem.LineTotal') }}
                        </th>
                        <th style="width: 40px;"></th>
                    </tr>
                </thead>
                <tbody id="sale-item">
                    <template v-for="(prod, index) in saleProducts">
                        <tr :key="rendered + index" v-bind:class="{'bg-danger':prod.outOfStock}" class="tble_border_remove" style="background:#EAF1FE ;">
                            <td>{{index+1}}</td>
                            <td>
                                <span>
                                    {{($i18n.locale == 'en' ||isLeftToRight())? products.find(x => x.id == prod.productId).englishName!=''? products.find(x => x.id == prod.productId).englishName : products.find(x => x.id == prod.productId).arabicName :    products.find(x => x.id == prod.productId).arabicName!=''? products.find(x => x.id == prod.productId).arabicName : products.find(x => x.id == prod.productId).englishName}}
                                </span>

                                <span v-if="products.find(x => x.id == prod.productId).promotionOffer!=undefined && products.find(x => x.id == prod.productId).promotionOffer.fixedDiscount > 0" class="badge badge-pill badge-success">
                                     {{(products.find(x => x.id == prod.productId).promotionOffer.fixedDiscount).toFixed(3).slice(0,-1)}}, ({{products.find(x => x.id == prod.productId).promotionOffer.stockLimit - products.find(x => x.id == prod.productId).promotionOffer.quantityOut}})
                                </span>
                                <span v-if="products.find(x => x.id == prod.productId).promotionOffer!=undefined && products.find(x => x.id == prod.productId).promotionOffer.discountPercentage > 0" class="badge badge-pill badge-success">
                                    {{(products.find(x => x.id == prod.productId).promotionOffer.discountPercentage).toFixed(3).slice(0,-1)}}%, ({{products.find(x => x.id == prod.productId).promotionOffer.stockLimit - products.find(x => x.id == prod.productId).promotionOffer.quantityOut}})
                                </span>
                                <span v-if="products.find(x => x.id == prod.productId).bundleCategory != undefined" class="badge badge-pill badge-success">
                                    {{products.find(x => x.id == prod.productId).bundleCategory.buy}} + {{products.find(x => x.id == prod.productId).bundleCategory.get}}, ({{products.find(x => x.id == prod.productId).bundleCategory.stockLimit - products.find(x => x.id == prod.productId).bundleCategory.quantityOut}})
                                </span>
                            </td>
                            <td v-if="colorVariants">
                                <textarea class="form-control input-border " style="background-color: #ffffff;padding: 0 5px 0 5px;" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'"
                                          v-model="prod.description" />
                            </td>
                            <td v-if="colorVariants">
                                <colordropdown v-model="prod.colorId" :isSaleItem="true" @input="GetColorName(prod.colorId, prod)" v-bind:values="prod.colorId" />
                            </td>

                            <td v-on:dblclick="counter += 1, openmodel('unitPrice'+index)" v-if="!changePriceDuringSale && dayStart=='true'">
                                <decimal-to-fixed disable="(isAuthour.changePriceDuringSale && isAuthour.column==('unitPrice'+index))==true?'true':'false'" v-model="prod.unitPrice" v-bind:salePriceCheck="false" v-on:input="updateLineTotal(prod.unitPrice, 'unitPrice', prod)" />
                            </td>
                            <td v-else>
                                <decimal-to-fixed v-model="prod.unitPrice" v-bind:salePriceCheck="false" v-on:input="updateLineTotal(prod.unitPrice, 'unitPrice', prod)" />
                            </td>

                            <td class="text-center" v-if="isValid('CanViewUnitPerPack')">
                                {{prod.unitPerPack}}
                            </td>
                            <td class="text-center" v-if="isValid('CanViewCurrentQuantity')">
                                {{prod.currentQuantity}}
                            </td>
                            <td class="text-center" v-if="isFifo">
                                {{prod.batchNo}}
                            </td>
                            <td class="text-center" v-if="isFifo">
                                {{getDate(prod.batchExpiry)}}

                            </td>
                            <td class="border-top-0 text-center" v-if="isMultiUnit=='true'">
                                <input type="number" v-model="prod.highQty"
                                       style=""
                                       @focus="$event.target.select()" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'"
                                       class="form-control input-border text-center tableHoverOn"
                                       @keyup="updateLineTotal($event.target.value, 'highQty', prod)" />
                                <small style="font-weight: 500;font-size:70%;">
                                    {{prod.levelOneUnit}}
                                </small>
                            </td>

                            <!--<td class="text-center" v-if="decimalQuantity">
        <decimal-to-fixed v-model="prod.quantity"  @keyup="updateLineTotal($event.target.value, 'quantity', prod)" />

    </td>-->
                            <td class="text-center">
                                <input v-model="prod.quantity " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'"
                                       type="number"
                                       @focus="$event.target.select()"
                                       class="form-control input-border text-center tableHoverOn"
                                       @keyup="updateLineTotal($event.target.value, 'quantity', prod)" />
                                <small style="font-weight: 500;font-size:70%;" v-if="isMultiUnit=='true'">
                                    {{prod.basicUnit}}
                                </small>
                            </td>

                            <td class="border-top-0 text-center" v-if="isMultiUnit=='true'">
                                {{parseInt(parseFloat(prod.highQty*prod.unitPerPack) + parseFloat(prod.quantity))}}
                            </td>
                            <td v-if="prod.saleReturnDays > 0" hidden>
                                <input v-model="prod.saleReturnDays "
                                       type="number"
                                       class="form-control input-border text-center tableHoverOn " />
                            </td>
                            <td class="text-center" v-else hidden>
                                <span>--</span>
                            </td>
                            <td class="text-center" v-if="saleProducts.filter(x=> x.isBundleOffer).length > 0" hidden>
                                <span class="badge badge-pill badge-info">{{prod.bundleOffer}}</span>
                            </td>

                            <td class="text-center" v-if="isSerial">
                                <button @click="AddSerial(prod)" v-if="prod.isSerial" title="Add Serial" class="btn btn-primary btn-sm"> Add Serial </button>
                                <span v-else>-</span>
                            </td>
                            <td class="border-top-0  text-center" v-if="isSerial">
                                <datepicker v-model="prod.guaranteeDate" v-if="prod.guarantee" />
                                <span v-else>-</span>
                            </td>

                            <td v-if="(!giveDiscountDuringSale && dayStart==='true') " v-on:dblclick="counter += 1, openmodel1('discount'+index)">
                                <decimal-to-fixed disable="(isAuthour.changePriceDuringSale && isAuthour.column==('discount'+index))==true?'true':'false'" v-model="prod.discount" v-bind:salePriceCheck="false" v-on:input="updateLineTotal(prod.discount, 'discount', prod)" />
                            </td>
                            <td v-else>
                                <decimal-to-fixed v-model="prod.discount" v-bind:disable="prod.fixDiscount != 0?true:false" v-bind:salePriceCheck="false" v-on:input="updateLineTotal(prod.discount, 'discount', prod)" />
                            </td>

                            <td v-if="(!giveDiscountDuringSale && dayStart==='true')" v-on:dblclick="counter += 1, openmodel2('fixDiscount'+index)">
                                <decimal-to-fixed disable="(isAuthour.changePriceDuringSale && isAuthour.column==('fixDiscount'+index))==true?'true':'false'" v-model="prod.fixDiscount" v-bind:salePriceCheck="false" v-on:input="updateLineTotal(prod.fixDiscount, 'fixDiscount', prod)" />
                            </td>

                            <td v-else>
                                <decimal-to-fixed v-model="prod.fixDiscount" :disable="prod.discount != 0?true:false" v-bind:salePriceCheck="false" v-on:input="updateLineTotal(prod.fixDiscount, 'fixDiscount', prod)" />
                            </td>

                            <td v-if="saleDefaultVat == 'DefaultVatItem' || saleDefaultVat =='DefaultVatHeadItem'">
                                <multiselect :options="options" v-model="prod.taxMethod" @input="getTaxMethod(prod.taxMethod, prod)" :show-labels="false" v-bind:placeholder="$t('PurchaseItem.TaxMethod')" v-bind:class="$i18n.locale == 'en' ? 'text-left' : 'arabicLanguage'">
                                </multiselect>
                            </td>

                            <td v-if="saleDefaultVat == 'DefaultVatItem' || saleDefaultVat =='DefaultVatHeadItem'">
                                <taxratedropdown v-model="prod.taxRateId" @input="getVatValue(prod.taxRateId, prod)" />
                            </td>

                            <td class="text-right">
                                {{currency}} {{parseFloat(prod.lineTotal).toFixed(3).slice(0,-1) | formatAmount}}
                            </td>
                            <td>
                                <button @click="removeProduct(prod.rowId)"
                                        title="Remove Item"
                                        class="btn btn-secondary btn-neutral btn-sm btn-round btn-icon float-right">
                                    <i class="nc-icon nc-simple-remove"></i>
                                </button>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>

        <div class="table-responsive  mt-4" v-if="colorVariants">
            <table class="table add_table_list_bg" style="table-layout:fixed;" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                <thead class="m-0">
                    <tr>
                        <th style="width: 20px;">
                            #
                        </th>
                        <th>
                            {{ $t('SaleItem.Product') }}
                        </th>
                        <th class="text-center">
                            {{ $t('SaleItem.Color') }}
                        </th>
                        <th v-for="size in saleSizeAssortment" class="text-center" :key="size.sizeId">
                            {{size.name}}
                        </th>
                        <th class="text-center">
                            Total
                        </th>
                    </tr>
                </thead>
                <tbody id="sale-item">
                    <template v-for="(prod, index) in saleProducts">
                        <tr :key="prod.rowId" v-bind:class="{'bg-danger':prod.outOfStock}" class="tble_border_remove" style="background:#EAF1FE ;">
                            <td>{{index+1}}</td>
                            <td>
                                <span>
                                    {{($i18n.locale == 'en' ||isLeftToRight())? products.find(x => x.id == prod.productId).englishName!=''? products.find(x => x.id == prod.productId).englishName : products.find(x => x.id == prod.productId).arabicName :    products.find(x => x.id == prod.productId).arabicName!=''? products.find(x => x.id == prod.productId).arabicName : products.find(x => x.id == prod.productId).englishName}}
                                </span>

                                <span v-if="products.find(x => x.id == prod.productId).promotionOffer!=undefined && products.find(x => x.id == prod.productId).promotionOffer.fixedDiscount > 0" class="badge badge-pill badge-success">
                                    Rs {{(products.find(x => x.id == prod.productId).promotionOffer.fixedDiscount).toFixed(3).slice(0,-1)}}, ({{products.find(x => x.id == prod.productId).promotionOffer.stockLimit - products.find(x => x.id == prod.productId).promotionOffer.quantityOut}})
                                </span>
                                <span v-if="products.find(x => x.id == prod.productId).promotionOffer!=undefined && products.find(x => x.id == prod.productId).promotionOffer.discountPercentage > 0" class="badge badge-pill badge-success">
                                    {{(products.find(x => x.id == prod.productId).promotionOffer.discountPercentage).toFixed(3).slice(0,-1)}}%, ({{products.find(x => x.id == prod.productId).promotionOffer.stockLimit - products.find(x => x.id == prod.productId).promotionOffer.quantityOut}})
                                </span>
                                <span v-if="products.find(x => x.id == prod.productId).bundleCategory != undefined" class="badge badge-pill badge-success">
                                    {{products.find(x => x.id == prod.productId).bundleCategory.buy}} + {{products.find(x => x.id == prod.productId).bundleCategory.get}}, ({{products.find(x => x.id == prod.productId).bundleCategory.stockLimit - products.find(x => x.id == prod.productId).bundleCategory.quantityOut}})
                                </span>
                            </td>

                            <td class="text-center">
                                {{prod.colorName}}
                            </td>
                            <td v-for="size in prod.saleSizeAssortment" :key="size.sizeId" class="text-center">
                                <input v-model="size.quantity " v-bind:class="size.quantity > size.currentQuantity ? 'input-txt-danger' : ''"
                                       type="number" @focus="$event.target.select()"
                                       @keyup="sizeQtyVerify(prod)" data-toggle="tooltip" data-placement="top" :title="'Current Quantity : ' + size.currentQuantity"
                                       v-bind:disabled="sizeAllowInput(size.sizeId, prod)"
                                       class="form-control text-center tableHoverOn" />
                            </td>
                            <td class="text-center">
                                {{prod.quantity}}
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>



        <div class=" table-responsive mt-3"
             v-bind:key="rendered + 'g'">
            <table class="table add_table_list_bg" style="table-layout:fixed;">
                <thead class="m-0" style=" background-color: #3178F6; color:#ffffff;">
                    <tr class="text-right">
                        <th class="text-center" style="width:100px;">
                            {{ $t('SaleItem.NoItem') }}
                        </th>
                        <th class="text-center" style="width:100px;" v-if="isMultiUnit=='true'">
                            {{ $t('SaleItem.TotalCarton') }}
                        </th>
                        <th class="text-center" style="width:100px;" v-if="isMultiUnit=='true'">
                            {{ $t('SaleItem.TotalPieces') }}
                        </th>
                        <th class="text-center" style="width:100px;">
                            {{ $t('SaleItem.TotalQty') }}
                        </th>
                        <th style="width:100px;">
                            {{ $t('SaleItem.Total') }}
                        </th>
                        <th style="width:100px;">
                            {{ $t('SaleItem.Disc') }}
                        </th>
                        <th style="width:160px;">
                            {{ $t('SaleItem.TotalAfterDisc') }}
                        </th>
                        <th style="width:100px;">
                            {{ $t('SaleItem.TotalVat') }}
                        </th>
                        <th v-if="summary.bundleAmount > 0" style="width:100px;">
                            {{ $t('SaleItem.BundleAmount') }}
                        </th>
                        <th style="width:155px;">
                            {{ $t('SaleItem.TotalWithVat') }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="text-right samary_tbl_pd" style="background-color:#EAF1FE;">
                        <td class="text-center">
                            {{ summary.item}}
                        </td>
                        <td class="text-center" v-if="isMultiUnit=='true'">
                            {{ summary.totalCarton}}
                        </td>
                        <td class="text-center" v-if="isMultiUnit=='true'">
                            {{ summary.totalPieces}}
                        </td>
                        <td class="text-center">
                            {{ summary.qty}}
                        </td>

                        <td>
                            {{parseFloat(summary.total).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                        </td>
                        <td>
                            {{  parseFloat(summary.discount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")  }}
                        </td>
                        <td>
                            {{currency}}  {{  parseFloat(summary.withDisc).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") }}
                        </td>
                        <td>
                            {{currency}}  {{ (parseFloat(summary.vat)).toFixed(3).slice(0,-1) | formatAmount }}
                        </td>
                        <td v-if="summary.bundleAmount > 0">
                            {{currency}} {{ parseFloat(summary.bundleAmount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                        </td>
                        <td>
                            {{currency}} {{  parseFloat(summary.withVat).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <authorize-user-model :authorize="authorize"
                              :show="show"
                              v-if="show"
                              @result="result"
                              @close="show = false" />

        <add-serial-model :item="serialItem"
                          :show="showSerial"
                          v-if="showSerial"
                          @input="updateSerial"
                          @close="showSerial = false" />
    </div>
</template>

<script>
    import moment from "moment";
    import clickMixin from '@/Mixins/clickMixin'
    import Multiselect from 'vue-multiselect'
    export default {
        name: "SaleItem",
        props: ['saleItems', 'wareHouseId', 'saleOrderId', 'taxMethod', 'wholesale', 'taxRateId'],
        mixins: [clickMixin],
        components: {
            Multiselect
        },
        data: function () {
            return {
                saleSizeAssortment: [],
                colorList: [],
                options: [],
                dayStart: '',
                colorVariants: false,
                isFifo: false,
                isSerial: false,
                soInventoryReserve: false,
                decimalQuantity: false,
                invoiceWoInventory: false,
                fixDiscount: '',
                discount: '',
                bundle: '',
                counter: 0,
                isMultiUnit: '',
                isAuthour: {
                    changePriceDuringSale: false,
                    giveDiscountDuringSale: false,
                    column: '',
                },
                changePriceDuringSale: false,
                giveDiscountDuringSale: false,
                useQuantity: false,
                show: false,
                authorize: {
                    column: '',
                    userName: '',
                    password: '',
                },
                rendered: 0,
                product: {
                    id: "",
                },
                products: [],
                saleProducts: [],
                loading: false,
                vats: [],
                summary: {
                    item: 0,
                    qty: 0,
                    total: 0,
                    discount: 0,
                    withDisc: 0,
                    vat: 0,
                    withVat: 0,
                    bundleAmount: 0,
                    totalCarton: 0,
                    totalPieces: 0
                },
                currency: '',
                count: 0,
                productList: [],
                serialItem: '',
                showSerial: false,
                vatSelectOnSale: false,
                wholsalePriceActive: true,
                saleDefaultVat: '',
            };
        },
        validations: {},
        filter: {},
        methods: {
            AddSerial: function (item) {

                this.serialItem = item;
                this.showSerial = true;
            },

            updateSerial: function (serial, item) {

                var prod = this.saleProducts.find(x => x.rowId == item.rowId);
                if (prod != undefined) {
                    prod.serial = serial;
                }
                this.showSerial = false;
            },

            //GetProductList: function () {

            //    var root = this;
            //    var token = "";
            //    if (this.$session.exists()) {
            //        token = localStorage.getItem("token");
            //    }

            //    this.isRaw = this.raw == undefined ? false : this.raw;
            //    //search = search == undefined ? '' : search;
            //    // var url = this.wareHouseId != undefined ? "/Product/GetProductInformation?searchTerm=" + search + '&wareHouseId=' + this.wareHouseId + "&isDropdown=true" + '&isRaw=' + root.isRaw : "/Product/GetProductInformation?searchTerm=" + search + '&status=' + root.status + "&isDropdown=true" + '&isRaw=' + root.isRaw;

            //    this.$https
            //        .get("/Product/GetProductBarcode?isRaw=" + root.isRaw + '&wareHouseId=' + this.wareHouseId, {
            //            headers: { Authorization: `Bearer ${token}` },
            //        })
            //        .then(function (response) {
            //            if (response.data != null) {
            //                root.productList = response.data.results.products;

            //            }
            //        });


            //},
            //onBarcodeScanned(barcode) {

            //    if (localStorage.getItem("BarcodeScan") != 'SaleItem')
            //        return
            //    var root = this;
            //    var token = "";
            //    if (this.$session.exists()) {
            //        token = localStorage.getItem("token");
            //    }
            //    var warehouseId = ''
            //    if (!this.invoiceWoInventory) {
            //        warehouseId = root.wareHouseId
            //    }

            //    this.$https.get('/Product/GetProductInformation?searchTerm=' + barcode + '&wareHouseId=' + warehouseId + "&isDropdown=true" + '&isDropdown=' + false, { headers: { "Authorization": `Bearer ${token}` } })

            //        .then(function (response) {
            //            if (response.data != null) {
            //                var product1 = [];
            //                response.data.results.products.forEach(function (product) {
            //                    product1.push({
            //                        id: product.id,
            //                        englishName: product.englishName != '' ? product.englishName : product.arabicName,
            //                        arabicName: product.arabicName != '' ? product.arabicName : product.englishName,
            //                        assortment: product.assortment,
            //                        barCode: product.barCode,
            //                        basicUnit: product.basicUnit,
            //                        brandId: product.brandId,
            //                        bundleCategory: product.bundleCategory,
            //                        categoryId: product.categoryId,
            //                        code: product.code,
            //                        colorId: product.colorId,
            //                        colorName: product.colorName,
            //                        colorNameArabic: product.colorNameArabic,
            //                        description: product.description,
            //                        inventory: product.inventory,
            //                        isActive: product.isActive,
            //                        image: product.image,
            //                        isExpire: product.isExpire,
            //                        isRaw: product.isRaw,
            //                        length: product.length,
            //                        levelOneUnit: product.levelOneUnit,
            //                        stockLevel: product.stockLevel,
            //                        originId: product.originId,
            //                        promotionOffer: product.promotionOffer,
            //                        purchasePrice: product.purchasePrice,
            //                        salePrice: product.salePrice,
            //                        salePriceUnit: product.salePriceUnit,
            //                        saleReturnDays: product.saleReturnDays,
            //                        shelf: product.shelf,
            //                        sizeId: product.sizeId,
            //                        sizeName: product.sizeName,
            //                        sizeNameArabic: product.sizeNameArabic,
            //                        styleNumber: product.styleNumber,
            //                        subCategoryId: product.subCategoryId,
            //                        taxMethod: product.taxMethod,
            //                        taxRate: product.taxRate,
            //                        taxRateId: product.taxRateId,
            //                        unit: product.unit,
            //                        unitId: product.unitId,
            //                        unitPerPack: product.unitPerPack,
            //                        width: product.width,

            //                    })
            //                })

            //                root.addProduct(product1[0].id, product1[0])
            //            }
            //        });



            //},

            result: function (x) {
                this.isAuthour = x;
            },
            openmodel: function (column) {

                this.authorize = {
                    userName: '',
                    password: '',
                    column: column
                }
                this.show = !this.show;
            },
            openmodel1: function (column) {

                this.authorize = {
                    userName: '',
                    password: '',
                    column: column
                }
                this.show = !this.show;
            },
            openmodel2: function (column) {

                this.authorize = {
                    userName: '',
                    password: '',
                    column: column
                }
                this.show = !this.show;
            },

            changeProduct: function (NewProdId, rowId) {
                this.saleProducts = this.saleProducts.filter(x => x.rowId != rowId);
                this.addProduct(NewProdId);
            },

            GetColorName: function (colorId, product) {

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('Product/ColorDetail?id=' + colorId + '&productId=' + product.productId + '&isVariance=true', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        product.saleSizeAssortment.forEach(function (item) {
                            var size = response.data.variationInventories.find(x => x.sizeId == item.sizeId);
                            if (size != undefined) {
                                item.currentQuantity = size.quantity;
                            }
                            else {
                                item.currentQuantity = 0;
                            }
                        });

                        if (colorId != null && colorId != undefined && colorId != '') {
                            product.colorName = response.data.name;
                            product.currentQuantity = response.data.quantity;
                        }
                        else {
                            product.colorName = '';
                        }
                    }
                });
            },

            sizeAllowInput: function (sizeId, product) {

                if (product.productSizes != null && product.productSizes != undefined && product.productSizes.length != 0) {
                    var size = product.productSizes.find(x => x.sizeId == sizeId);
                    if (size != undefined) {
                        return false;
                    }
                    else {
                        return true;
                    }
                }
                else {
                    return true;
                }
            },

            sizeQtyVerify: function (product) {
                if (product.saleSizeAssortment != null && product.saleSizeAssortment != undefined) {
                    if (parseFloat(product.totalPiece) < product.saleSizeAssortment.reduce(function (a, c) { return a + parseFloat(c.quantity == '' ? 0 : c.quantity) }, 0)) {
                        this.$set(product, 'outOfStock', true);
                    } else {
                        this.$set(product, 'outOfStock', false);
                    }
                }
                else {
                    this.$set(product, 'outOfStock', true);
                }
            },

            calcuateSummary: function () {
                debugger;
                this.summary.item = this.saleProducts.length;
                if (this.decimalQuantity) {
                    this.summary.totalPieces = this.saleProducts.reduce((totalQty, prod) => totalQty + parseFloat(prod.quantity), 0);
                }
                else {
                    this.summary.totalPieces = this.saleProducts.reduce((totalQty, prod) => totalQty + parseInt(prod.quantity), 0);
                }

                if (this.decimalQuantity) {
                    this.summary.totalCarton = this.saleProducts.reduce((totalCarton, prod) => totalCarton + parseFloat(prod.highQty == '' || prod.highQty == undefined ? 0 : prod.highQty), 0);
                }
                else {
                    this.summary.totalCarton = this.saleProducts.reduce((totalCarton, prod) => totalCarton + parseInt(prod.highQty == '' || prod.highQty == undefined ? 0 : prod.highQty), 0);
                }

                if (this.decimalQuantity) {
                    this.summary.qty = this.saleProducts.reduce((qty, prod) => qty + parseFloat(prod.totalPiece == '' ? 0 : prod.totalPiece), 0);
                    this.summary.qty = parseFloat(this.summary.qty).toFixed(2);
                }
                else {
                    this.summary.qty = this.saleProducts.reduce((qty, prod) => qty + parseInt(prod.highQty == '' || prod.highQty == undefined ? 0 : prod.highQty * (prod.unitPerPack == null ? 0 : prod.unitPerPack)) + parseInt(prod.quantity == '' ? 0 : prod.quantity), 0);
                    //this.summary.qty = this.purchaseProducts.reduce(
                    //    (qty, prod) => qty + parseInt(prod.highQty == '' ? 0 : prod.highQty * (prod.unitPerPack == null ? 0 : prod.unitPerPack)) + parseInt(prod.quantity == '' ? 0 : prod.quantity), 0);
                }

                this.summary.total = this.saleProducts.reduce((total, prod) =>
                    total + (prod.totalPiece) * prod.unitPrice, 0).toFixed(3).slice(0, -1);

                /*just calculate discount*/
                var discount = this.saleProducts.filter((x) => (x.discount != 0 || x.discount != "") && x.offerQuantity != 0)
                    .reduce((discount, prod) => discount +
                        prod.diff ?
                        ((prod.diff * prod.unitPrice * prod.discount) / 100) :
                        ((prod.totalPiece < (prod.offerQuantity > prod.remainingStockLimit ? prod.remainingStockLimit : prod.offerQuantity)) ? ((prod.totalPiece * prod.unitPrice * prod.discount) / 100) :
                            ((prod.offerQuantity > prod.remainingStockLimit ? prod.remainingStockLimit : prod.offerQuantity) * prod.unitPrice * prod.discount) / 100), 0);

                /*just calculate fix discount*/
                var fixDiscount = this.saleProducts
                    .filter((x) => (x.fixDiscount != 0 || x.fixDiscount != "") && x.offerQuantity != 0)
                    .reduce((discount, prod) =>
                        discount + prod.offerQuantity ? ((prod.totalPiece < (prod.offerQuantity > prod.remainingStockLimit ? prod.remainingStockLimit : prod.offerQuantity)) ? prod.fixDiscount * prod.totalPiece : prod.fixDiscount * (prod.offerQuantity > prod.remainingStockLimit ? prod.remainingStockLimit : prod.offerQuantity)) : 0, 0);


                /*just calculate discount without promotion*/
                var discountOnly = this.saleProducts
                    .filter((x) => x.discount != 0 || x.discount != "" || x.offerQuantity != 0)
                    .reduce((discount, prod) =>
                        discount + (prod.totalPiece ? (prod.offerQuantity ? 0 : ((prod.totalPiece * prod.discount * prod.unitPrice) / 100)) : 0), 0);

                /*just calculate fix discount without promotion*/
                var fixDiscountOnly = this.saleProducts
                    .filter((x) => x.fixDiscount != 0 || x.fixDiscount != "" || x.offerQuantity != 0)
                    .reduce((discount, prod) =>
                        discount + (prod.totalPiece ? (prod.offerQuantity ? 0 : (prod.totalPiece * prod.fixDiscount)) : 0), 0);


                this.summary.discount = (parseFloat(discount) + parseFloat(fixDiscount) + fixDiscountOnly + discountOnly).toFixed(3).slice(0, -1);

                this.summary.withDisc = (this.summary.total - this.summary.discount).toFixed(3).slice(0, -1);

                this.summary.vat = this.saleProducts.reduce((vat, prod) => vat + prod.lineItemVAt, 0).toFixed(3).slice(0, -1);

                var taxmethod = this.saleProducts.find(function (x) {
                    return x.taxMethod == ("Inclusive" || "شامل")
                })

                var exclusiveVat = this.saleProducts.reduce((vat, prod) => vat + ((prod.taxMethod == 'Exclusive' || prod.taxMethod == "غير شامل")? prod.lineItemVAt : 0), 0).toFixed(3).slice(0, -1);


                this.summary.withVat = (parseFloat(this.summary.withDisc) + parseFloat(exclusiveVat)).toFixed(3).slice(0, -1);

                //calculate bundle Amount
                if (this.saleProducts.filter(x => x.isBundleOffer).length > 0) {

                    //get bundle get quantity
                    var bundle = {
                        item: 0,
                        qty: 0,
                        total: 0,
                        discount: 0,
                        withDisc: 0,
                        vat: 0,
                        withVat: 0,
                        quantityLimit: 0
                    };

                    var bundleProducts = this.saleProducts.filter(x => x.isBundleOffer != undefined && x.offerQuantity > 0);

                    bundle.total = bundleProducts.reduce((total, prod) =>
                        total + prod.offerQuantity * prod.unitPrice, 0).toFixed(3).slice(0, -1);

                    //var bundleExclusiveTax = bundleProducts.reduce((total, prod) =>
                    //    total + (prod.taxMethod == "Exclusive" ? (bundle.total * prod.rate/100) : 0), 0);

                    var discountBundle = bundleProducts.filter((x) => x.discount != 0 || x.discount != "")
                        .reduce((discount, prod) =>
                            discount + (prod.offerQuantity * prod.unitPrice * prod.discount) / 100, 0);

                    var fixDiscountBundle = bundleProducts
                        .filter((x) => x.fixDiscount != 0 || x.fixDiscount != "")
                        .reduce((discount, prod) => discount + prod.fixDiscount, 0);

                    bundle.discount = (parseFloat(discountBundle) + parseFloat(fixDiscountBundle)).toFixed(3).slice(0, -1);

                    bundle.withDisc = (bundle.total - bundle.discount).toFixed(3).slice(0, -1);

                    bundle.vat = bundleProducts
                        .reduce((vat, prod) => vat + (((prod.unitPrice * prod.offerQuantity) -
                            ((prod.unitPrice * prod.offerQuantity * prod.discount) / 100)) *
                            parseFloat(prod.rate)) / ((prod.taxMethod == "Exclusive" || prod.taxMethod == "غير شامل") ? 100 : prod.rate + 100), 0).toFixed(3).slice(0, -1);

                    this.summary.bundleAmount = (parseFloat(bundle.withDisc) + ((taxmethod == undefined) ? parseFloat(bundle.vat) : 0)).toFixed(3).slice(0, -1);
                    this.summary.withVat = (this.summary.withVat - bundle.withDisc);
                }
                else {
                    this.summary.bundleAmount = 0;
                }

                this.$emit("input", this.saleProducts);

                this.$emit("summary", this.summary);
            },

            updateLineTotal: function (e, prop, product) {
                var root = this;

                if (e != undefined) {
                    var discount = product.discount == 0 || product.discount == "" ? product.fixDiscount == 0 || product.fixDiscount == ""
                        ? 0
                        : product.fixDiscount
                        : product.discount;

                    var prod = root.products.find((x) => x.id == product.productId && x.batchNo == product.batchNo);
                    if (prop == "unitPrice") {
                        product.unitPrice = e;
                    }

                    if (prop == "quantity") {
                        if (e <= 0 || e == '') {
                            e = 0;
                        }
                        if (String(e).split('.').length > 1 && String(e).split('.')[1].length > 2)
                            e = parseFloat(String(e).slice(0, -1))
                        product.quantity = this.decimalQuantity ? e : Math.round(e);
                    }
                   
                    if (prop == "highQty") {
                        debugger;
                        if (e < 0 || e == '' || e == undefined) {
                            e = 0;
                        }
                        product.highQty = Math.round(e);
                    }
                    debugger;
                    if (product.highUnitPrice) {

                        product.totalPiece = (parseFloat(product.highQty == undefined ? 0 : product.highQty) + (parseFloat(product.quantity == '' ? 0 : product.quantity) / parseFloat(product.unitPerPack == null ? 0 : product.unitPerPack)));
                    }
                    else {
                        product.totalPiece = (parseFloat(product.highQty == undefined ? 0 : product.highQty) * parseFloat(product.unitPerPack == null ? 0 : product.unitPerPack)) + parseFloat(product.quantity == '' ? 0 : product.quantity);
                    }

                    //product.totalPiece = (parseFloat(product.highQty == undefined ? 0 : product.highQty) * parseFloat(product.unitPerPack == null ? 0 : product.unitPerPack)) + parseFloat(product.quantity == '' ? 0 : product.quantity);
                    if (prod.promotionOffer != null) {
                        if (product.totalPiece > 0 && moment().format("DD MMM YYYY") >= moment(prod.promotionOffer.fromDate).format("DD MMM YYYY") &&
                            moment().format("DD MMM YYYY") <= moment(prod.promotionOffer.toDate).format("DD MMM YYYY")) {
                            product.fixDiscount = prod.promotionOffer.fixedDiscount;
                            product.discount = prod.promotionOffer.discountPercentage;
                            product.offerQuantity = prod.promotionOffer.totalPiece;
                            this.$set(product, 'isOfferQty', true);
                        } else {
                            if (product.isOfferQty) {
                                product.fixDiscount = 0;
                                product.discount = 0;
                                product.offerQuantity = 0;
                            }
                            this.$set(product, 'isOfferQty', false);
                        }
                    }

                    if (prod.bundleCategory != null) {
                        if (product.totalPiece >= prod.bundleCategory.buy && moment().format("DD MMM YYYY") >= moment(prod.bundleCategory.fromDate).format("DD MMM YYYY") &&
                            moment().format("DD MMM YYYY") <= moment(prod.bundleCategory.toDate).format("DD MMM YYYY")) {
                            this.$set(product, 'bundleOffer', prod.bundleCategory.buy.toString() + " + " + prod.bundleCategory.get.toString());
                            this.$set(product, 'get', prod.bundleCategory.get);
                            this.$set(product, 'buy', prod.bundleCategory.buy);
                            this.$set(product, 'quantityLimit', prod.bundleCategory.quantityLimit);
                            this.$set(product, 'isBundleOffer', true);
                        } else {
                            this.$set(product, 'bundleOffer', "");
                            this.$set(product, 'get', 0);
                            this.$set(product, 'buy', 0);
                            this.$set(product, 'quantityLimit', 0);
                            this.$set(product, 'isBundleOffer', false);
                        }
                        //bundle category calculation
                        if (product.quantityLimit != undefined && parseFloat(product.totalPiece) >= (product.get + product.buy)) {
                            if ((product.get + product.buy) > 0) {
                                product.offerQuantity = Math.floor(parseFloat(product.totalPiece) / (product.get + product.buy));
                                if ((prod.bundleCategory.quantityOut + product.offerQuantity) <= prod.bundleCategory.stockLimit) {
                                    if (product.offerQuantity <= product.quantityLimit) {
                                        product.offerQuantity = product.offerQuantity * product.get;
                                    }
                                    else {
                                        product.offerQuantity = product.quantityLimit * product.get;
                                    }
                                }
                                else {
                                    var diffBundle = prod.bundleCategory.stockLimit - prod.bundleCategory.quantityOut;
                                    if (diffBundle > product.quantityLimit) {
                                        product.offerQuantity = product.quantityLimit * product.get;
                                    }
                                    else {
                                        product.offerQuantity = diffBundle * product.get;
                                    }
                                }
                            }
                            else {
                                product.offerQuantity = 0;
                            }
                        }
                        else {
                            if ((product.get + product.buy) > 0) {
                                product.offerQuantity = Math.floor(parseFloat(product.totalPiece) / (product.get + product.buy));
                            }
                            else {
                                product.offerQuantity = 0;
                            }
                        }
                        //bundle category calculation end
                    }


                    if (!this.invoiceWoInventory) {
                        var bundleQuantity = product.bundleOfferQuantity == undefined ? 0 : product.bundleOfferQuantity;
                        if (prod.inventory != null) {

                            if (this.colorVariants) {
                                if ((parseFloat(product.totalPiece) + bundleQuantity < product.saleSizeAssortment.reduce(function (a, c) { return a + parseFloat(c.quantity == '' ? 0 : c.quantity) }, 0)) || (parseFloat(product.totalPiece) + bundleQuantity > (product.currentQuantity + ((this.saleOrderId != null && this.saleOrderId != '' && this.soInventoryReserve) ? parseFloat(product.soQty) : 0)))) {
                                    this.$set(product, 'outOfStock', true);
                                } else {
                                    this.$set(product, 'outOfStock', false);
                                }
                            }
                            else {
                                if (parseFloat(product.totalPiece) + bundleQuantity > (prod.inventory.currentQuantity + ((this.saleOrderId != null && this.saleOrderId != '' && this.soInventoryReserve) ? parseFloat(product.soQty) : 0))) {
                                    this.$set(product, 'outOfStock', true);
                                } else {
                                    this.$set(product, 'outOfStock', false);
                                }
                            }


                        }
                        else {
                            this.$set(product, 'outOfStock', true);
                        }
                    }


                    //End Calculate offer
                    if (prop == "discount") {
                        if (e == "" || e < 0) {
                            e = 0;
                        }
                        else if (e > 100) {
                            e = 100;
                        }
                        product.discount = e;
                    }

                    if (prop == "fixDiscount") {
                        if (e == "" || e < 0) {
                            e = 0;
                        }
                        else if (e > product.unitPrice) {
                            e = product.unitPrice;
                        }
                        product.fixDiscount = e;
                    }

                    var vat = 0;
                    var total = 0;
                    var calculateVAt = 0;
                    //here we will select quantity after deduct bundle quantity
                    if (product.offerQuantity > 0) {

                        if (product.isOfferQty) {
                            if (product.totalPiece > 0) {
                                if ((product.totalPiece) <= (prod.promotionOffer.stockLimit - prod.promotionOffer.quantityOut)) {
                                    this.$set(product, 'remainingStockLimit', (prod.promotionOffer.stockLimit - prod.promotionOffer.quantityOut));

                                    if (product.totalPiece <= product.offerQuantity) {
                                        discount = product.discount == 0 ? (product.fixDiscount * product.totalPiece) : (product.totalPiece * product.unitPrice * product.discount) / 100;

                                    }
                                    else {
                                        discount = product.discount == 0 ? (product.fixDiscount * product.offerQuantity) : (product.offerQuantity * product.unitPrice * product.discount) / 100;
                                    }
                                }
                                else {
                                    discount = product.discount == 0 ? (product.fixDiscount * (prod.promotionOffer.stockLimit - prod.promotionOffer.quantityOut)) : ((prod.promotionOffer.stockLimit - prod.promotionOffer.quantityOut) * product.unitPrice * product.discount) / 100;
                                }

                                vat = this.vats.find((value) => value.id == product.taxRateId);
                                total = product.totalPiece * product.unitPrice - discount;
                                if (product.taxMethod == 'Inclusive' || product.taxMethod == 'شامل') {
                                    calculateVAt = (total * vat.rate) / (100 + vat.rate);
                                    product.lineItemVAt = calculateVAt;
                                    //product.lineTotal = total - calculateVAt;
                                    product.lineTotal = total;
                                }
                                else {
                                    calculateVAt = (total * vat.rate) / 100;
                                    product.lineItemVAt = calculateVAt;
                                    product.lineTotal = total + calculateVAt;
                                }

                            }
                            else {
                                total = product.offerQuantity * product.unitPrice - discount;
                                if (product.taxMethod == 'Inclusive' || product.taxMethod == 'شامل') {
                                    calculateVAt = (total * vat.rate) / (100 + vat.rate);
                                    product.lineItemVAt = calculateVAt;
                                    //product.lineTotal = total - calculateVAt;
                                    product.lineTotal = total;
                                }
                                else {
                                    calculateVAt = (total * vat.rate) / 100;
                                    product.lineItemVAt = calculateVAt;
                                    product.lineTotal = total + calculateVAt;
                                }
                            }
                        }
                        else {
                            discount = product.discount == 0 ? product.fixDiscount : (product.offerQuantity * product.unitPrice * product.discount) / 100;
                            vat = this.vats.find((value) => value.id == product.taxRateId);

                            total = (product.totalPiece - product.offerQuantity) * product.unitPrice - discount;
                            if (product.taxMethod == 'Inclusive' || product.taxMethod == 'شامل') {
                                calculateVAt = (total * vat.rate) / (100 + vat.rate);
                                product.lineItemVAt = calculateVAt;
                                //product.lineTotal = total - calculateVAt;
                                product.lineTotal = total;
                            }
                            else {
                                calculateVAt = (total * vat.rate) / 100;
                                product.lineItemVAt = calculateVAt;
                                product.lineTotal = total + calculateVAt;
                            }
                        }

                        this.$set(this.saleProducts, product, product);
                        this.calcuateSummary();
                        this.$emit("input", this.saleProducts);

                    } else {

                        discount = product.discount == 0 ? (product.fixDiscount * product.totalPiece) : (product.totalPiece * product.unitPrice * product.discount) / 100;
                        vat = this.vats.find((value) => value.id == product.taxRateId);

                        total = product.totalPiece * product.unitPrice - discount;
                        if (product.taxMethod == 'Inclusive' || product.taxMethod == 'شامل') {
                            calculateVAt = (total * vat.rate) / (100 + vat.rate);
                            //product.lineTotal = total - calculateVAt;
                            product.lineItemVAt = calculateVAt;
                            product.lineTotal = total;
                        }
                        else {
                            calculateVAt = (total * vat.rate) / 100;
                            product.lineTotal = total + calculateVAt;
                            product.lineItemVAt = calculateVAt;
                        }

                        this.$set(this.saleProducts, product, product);
                        this.calcuateSummary();
                        this.$emit("input", this.saleProducts);
                    }
                }
            },

            updateBatch: function (productId, batch) {
                this.w
                var prd = this.saleProducts.find(x => x.productId == productId);
                if (prd != undefined) {
                    prd.batchNo = batch.batchNumber;
                    prd.batchExpiry = batch.expiryDate;
                }
                this.updateLineTotal(prd.quantity, "quantity", prd);
            },

            addProduct: function (productId, newProduct, qty, price, so, quantityOut, soItem, batch) {
                
                var uid = this.createUUID();

                if (this.saleProducts.some(x => x.productId == productId) && !this.isFifo && !this.colorVariants) {
                    var prd = this.saleProducts.find(x => x.productId == productId);
                    prd.quantity++;
                    this.updateLineTotal(prd.quantity, "quantity", prd);
                }
                else if (!so && batch != undefined && this.saleProducts.some(x => x.productId == productId && x.batchNo == batch.batchNumber) && this.isFifo) {
                    var prd1 = this.saleProducts.find(x => x.productId == productId && x.batchNo == newProduct.batchNo);
                    prd1.quantity++;
                    this.updateLineTotal(prd1.quantity, "quantity", prd1);
                }
                else {
                    var prod = ''
                    if (so && this.isFifo) {
                        this.products.push({
                            rowId: soItem.id,
                            arabicName: newProduct.arabicName,
                            assortment: newProduct.assortment,
                            barCode: newProduct.barCode,
                            basicUnit: soItem.product.basicUnit,
                            batchExpiry: soItem.expiryDate,
                            batchNo: soItem.batchNo,
                            brandId: newProduct.brandId,
                            bundleCategory: newProduct.bundleCategory,
                            category: newProduct.category,
                            categoryId: newProduct.categoryId,
                            code: newProduct.code,
                            colorId: newProduct.colorId,
                            colorName: newProduct.colorName,
                            discount: soItem.discount,
                            fixDiscount: soItem.fixDiscount,
                            colorNameArabic: newProduct.colorNameArabic,
                            currentQuantity: soItem.product.inventory.currentQuantity,
                            description: newProduct.description,
                            englishName: newProduct.englishName,
                            guarantee: soItem.product.guarantee,
                            id: newProduct.id,
                            image: newProduct.image,
                            inventory: soItem.product.inventory,
                            inventoryBatch: soItem.product.inventoryBatch,
                            isActive: newProduct.isActive,
                            isExpire: newProduct.isExpire,
                            isRaw: newProduct.isRaw,

                            length: soItem.product.length,
                            levelOneUnit: soItem.product.levelOneUnit,
                            originId: soItem.product.originId,
                            promotionOffer: soItem.product.promotionOffer,
                            purchasePrice: newProduct.purchasePrice,
                            salePrice: newProduct.salePrice,
                            salePriceUnit: newProduct.salePriceUnit,
                            saleReturnDays: soItem.product.saleReturnDays,
                            serial: soItem.product.serial,
                            serviceItem: newProduct.serviceItem,

                            shelf: newProduct.shelf,
                            sizeId: newProduct.sizeId,
                            sizeName: newProduct.sizeName,
                            sizeNameArabic: newProduct.sizeNameArabic,
                            stockLevel: newProduct.stockLevel,
                            styleNumber: newProduct.styleNumber,
                            subCategoryId: newProduct.subCategoryId,
                            taxMethod: soItem.product.taxMethod,
                            taxRate: newProduct.taxRate,
                            taxRateId: soItem.product.taxRateId,
                            taxRateValue: newProduct.taxRateValue,
                            unit: newProduct.unit,
                            unitId: newProduct.unitId,

                            unitPerPack: newProduct.unitPerPack,
                            width: newProduct.width,
                            highUnitPrice: newProduct.highUnitPrice,
                        });
                        prod = this.products.find((x) => x.rowId == soItem.id);
                    }
                    else if (this.isFifo && (batch != undefined || batch != null)) {

                        var inventoryData = {
                            autoNumbering: newProduct.inventory.autoNumbering,
                            averagePrice: newProduct.inventory.averagePrice,
                            batchNumber: newProduct.inventory.batchNumber,
                            bundleId: newProduct.inventory.bundleId,
                            buy: newProduct.inventory.buy,
                            currentQuantity: newProduct.inventory.currentQuantity,
                            currentStockValue: newProduct.inventory.currentStockValue,
                            date: newProduct.inventory.date,
                            documentId: newProduct.inventory.documentId,
                            documentNumber: newProduct.inventory.documentNumber,
                            expiryDate: newProduct.inventory.expiryDate,
                            get: newProduct.inventory.get,
                            id: newProduct.inventory.id,
                            isActive: newProduct.inventory.isActive,
                            isOpen: newProduct.inventory.isOpen,
                            price: newProduct.inventory.price,
                            product: newProduct.inventory.product,

                            productId: newProduct.inventory.productId,
                            promotionId: newProduct.inventory.promotionId,
                            quantity: newProduct.inventory.quantity,
                            remainingQuantity: newProduct.inventory.remainingQuantity,
                            salePrice: newProduct.inventory.salePrice,
                            serial: newProduct.inventory.serial,
                            stock: newProduct.inventory.stock,
                            stockId: newProduct.inventory.stockId,
                            transactionType: newProduct.inventory.transactionType,
                            wareHouseId: newProduct.inventory.wareHouseId,
                            warrantyDate: newProduct.inventory.warrantyDate,
                            highUnitPrice: newProduct.highUnitPrice,
                        };

                        this.products.push({
                            rowId: uid,
                            arabicName: newProduct.arabicName,
                            assortment: newProduct.assortment,
                            barCode: newProduct.barCode,
                            basicUnit: newProduct.basicUnit,
                            batchExpiry: newProduct.batchExpiry,
                            batchNo: newProduct.batchNo,
                            brandId: newProduct.brandId,
                            bundleCategory: newProduct.bundleCategory,
                            category: newProduct.category,
                            categoryId: newProduct.categoryId,
                            code: newProduct.code,
                            colorId: newProduct.colorId,
                            colorName: newProduct.colorName,

                            colorNameArabic: newProduct.colorNameArabic,
                            currentQuantity: newProduct.currentQuantity,
                            description: newProduct.description,
                            englishName: newProduct.englishName,
                            guarantee: newProduct.guarantee,
                            id: newProduct.id,
                            image: newProduct.image,
                            inventory: inventoryData,
                            inventoryBatch: newProduct.inventoryBatch,
                            isActive: newProduct.isActive,
                            isExpire: newProduct.isExpire,
                            isRaw: newProduct.isRaw,

                            length: newProduct.length,
                            levelOneUnit: newProduct.levelOneUnit,
                            originId: newProduct.originId,
                            promotionOffer: newProduct.promotionOffer,
                            purchasePrice: newProduct.purchasePrice,
                            salePrice: newProduct.salePrice,
                            salePriceUnit: newProduct.salePriceUnit,
                            saleReturnDays: newProduct.saleReturnDays,
                            serial: newProduct.serial,
                            serviceItem: newProduct.serviceItem,

                            shelf: newProduct.shelf,
                            sizeId: newProduct.sizeId,
                            sizeName: newProduct.sizeName,
                            sizeNameArabic: newProduct.sizeNameArabic,
                            stockLevel: newProduct.stockLevel,
                            styleNumber: newProduct.styleNumber,
                            subCategoryId: newProduct.subCategoryId,
                            taxMethod: newProduct.taxMethod,
                            taxRate: newProduct.taxRate,
                            taxRateId: newProduct.taxRateId,
                            taxRateValue: newProduct.taxRateValue,
                            unit: newProduct.unit,
                            unitId: newProduct.unitId,

                            unitPerPack: newProduct.unitPerPack,
                            width: newProduct.width,
                            highUnitPrice: newProduct.highUnitPrice,
                        });

                        prod = this.products.find((x) => x.rowId == uid);

                    }
                    else {
                        if (this.products.find(x => x.id == productId) == undefined || this.products.length <= 0) {
                            this.products.push(newProduct);
                        }
                        prod = this.products.find((x) => x.id == productId);
                    }

                    var rate = 0;
                    var taxRateId = '';
                    var taxMethod = '';
                    if (this.saleDefaultVat == 'DefaultVat' || this.saleDefaultVat == 'DefaultVatItem') {
                        if (prod.taxRateId != "00000000-0000-0000-0000-000000000000" && prod.taxRateId != undefined) {
                            rate = this.getVatValue(prod.taxRateId, prod);
                        }
                        taxRateId = prod.taxRateId;
                        taxMethod = prod.taxMethod;
                    }
                    if (this.saleDefaultVat == 'DefaultVatHead' || this.saleDefaultVat == 'DefaultVatHeadItem') {
                        if (this.taxRateId != "00000000-0000-0000-0000-000000000000" && this.taxRateId != undefined) {
                            rate = this.getVatValue(this.taxRateId, prod);
                        }
                        taxRateId = this.taxRateId;
                        taxMethod = this.taxMethod;
                    }


                    if (qty != null && qty != undefined && qty != 0) {
                        this.saleProducts.push({
                            rowId: uid,
                            productId: prod.id,
                            description: '',
                            unitPrice: so ? price : '0',
                            quantity: qty,
                            highQty: soItem.highQty,
                            soQty: qty,
                            discount: so ? soItem.discount:0,
                            fixDiscount: so ? soItem.fixDiscount: 0,
                            lineItemVAt: 0,
                            batchExpiry: prod.batchExpiry,
                            batchNo: prod.batchNo,
                            promotionId: prod.promotionOffer == null ? null : prod.promotionOffer.id,
                            quantityOut: quantityOut == null ? null : quantityOut,
                            bundleId: prod.bundleCategory == null ? null : prod.bundleCategory.id,
                            taxRateId: soItem.taxRateId,
                            saleReturnDays: prod.saleReturnDays,
                            taxMethod: soItem.taxMethod,
                            rate: rate,
                            serial: soItem.serial,
                            guaranteeDate: soItem.guaranteeDate,
                            isSerial: newProduct.serial,
                            guarantee: newProduct.guarantee,
                            inventoryList: prod.inventoryBatch == null ? null : prod.inventoryBatch,
                            currentQuantity: prod.inventory == null ? 0 : prod.inventory.currentQuantity,
                            lineTotal: prod.salePrice * 1,
                            buy: prod.bundleCategory != null ? prod.bundleCategory.buy : 0,
                            get: prod.bundleCategory != null ? prod.bundleCategory.get : 0,
                            quantityLimit: prod.bundleCategory != null ? prod.bundleCategory.quantityLimit : 0,
                            offerQuantity: 0,
                            unitPerPack: newProduct.unitPerPack,
                            levelOneUnit: prod.levelOneUnit,
                            basicUnit: prod.basicUnit,
                            highUnitPrice: newProduct.highUnitPrice,
                        });
                    }
                    else {

                        var sizeAssortmentList = [];
                        if (this.colorVariants) {
                            prod.saleSizeAssortment.forEach(function (item) {
                                sizeAssortmentList.push({
                                    sizeId: item.sizeId,
                                    name: item.name,
                                    quantity: item.quantity,
                                    currentQuantity: 0,
                                });

                            });
                        }


                        this.saleProducts.push({
                            rowId: uid,
                            productId: prod.id,
                            description: prod.englishName,
                            unitPrice: this.wholesale.id == 2 ? newProduct.wholesalePrice : (prod.salePrice == 0 ? '0' : prod.salePrice),
                            quantity: this.isMultiUnit == 'true' ? 0 : 1,
                            soQty: 0,
                            highQty: 0,
                            discount: 0,
                            fixDiscount: 0,
                            lineItemVAt: 0,
                            batchExpiry: prod.batchExpiry,
                            batchNo: prod.batchNo,
                            inventoryList: prod.inventoryBatch == null ? null : prod.inventoryBatch,
                            currentQuantity: prod.inventory == null ? 0 : prod.inventory.currentQuantity,
                            promotionId: prod.promotionOffer == null ? null : prod.promotionOffer.id,
                            bundleId: prod.bundleCategory == null ? null : prod.bundleCategory.id,
                            taxRateId: taxRateId,
                            taxMethod: taxMethod,
                            saleReturnDays: prod.saleReturnDays,
                            rate: rate,
                            serial: '',
                            guaranteeDate: '',
                            isSerial: newProduct.serial,
                            guarantee: newProduct.guarantee,
                            lineTotal: prod.salePrice * 1,
                            buy: prod.bundleCategory != null ? prod.bundleCategory.buy : 0,
                            get: prod.bundleCategory != null ? prod.bundleCategory.get : 0,
                            quantityLimit: prod.bundleCategory != null ? prod.bundleCategory.quantityLimit : 0,
                            offerQuantity: 0,
                            unitPerPack: newProduct.unitPerPack,
                            levelOneUnit: prod.levelOneUnit,
                            basicUnit: prod.basicUnit,
                            saleSizeAssortment: sizeAssortmentList,
                            productSizes: prod.productSizes,
                            colorId: '',
                            colorName: '',
                            highUnitPrice: newProduct.highUnitPrice,
                        });
                    }

                }

                var product = this.saleProducts.find((x) => {
                    return x.productId == productId && x.rowId == uid;
                });
                debugger;

                this.getVatValue(product.taxRateId, product);
                this.updateLineTotal(product.quantity, "quantity", product);
                this.updateLineTotal(product.highQty, "highQty", product);

                this.product.id = "";
                this.rendered++;
            },
            EmtySaleProductList: function () {

                this.saleProducts = [];
                this.products = [];
            },
            createUUID: function () {

                var dt = new Date().getTime();
                var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                    var r = (dt + Math.random() * 16) % 16 | 0;
                    dt = Math.floor(dt / 16);
                    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
                });
                return uuid;
            },
            getVatValue: function (id, prod) {

                var vat = this.vats.find((value) => value.id == id);
                prod.taxRateId = id;
                prod.rate = vat.rate;
                this.updateLineTotal(prod.unitPrice, "unitPrice", prod);
                return vat.rate;
            },
            getTaxMethod: function (method, prod) {
                prod.taxMethod = method;
                this.updateLineTotal(prod.unitPrice, "unitPrice", prod);
            },
            removeProduct: function (id) {

                this.saleProducts = this.saleProducts.filter((prod) => {
                    return prod.rowId != id;
                });

                this.calcuateSummary();
            },

            getDate: function (x) {
                return moment(x).format("l");
            },

            getData: function () {
                var root = this;

                var token = "";
                if (this.$session.exists()) {
                    token = localStorage.getItem("token");
                }
                root.$https
                    .get("/Product/TaxRateList", {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then(function (response) {
                        if (response.data != null) {
                            root.vats = response.data.taxRates;
                        }
                    }).then(function () {


                        if (root.saleItems != undefined) {

                            root.saleItems.forEach(function (item) {

                                if (root.isFifo) {
                                    root.products.push({
                                        rowId: item.id,
                                        arabicName: item.arabicName,
                                        assortment: item.assortment,
                                        barCode: item.barCode,
                                        basicUnit: item.product.basicUnit,
                                        batchExpiry: item.expiryDate,
                                        batchNo: item.batchNo,
                                        brandId: item.brandId,
                                        bundleCategory: item.bundleCategory,
                                        category: item.category,
                                        categoryId: item.categoryId,
                                        code: item.code,
                                        colorId: item.colorId,
                                        colorName: item.colorName,

                                        colorNameArabic: item.colorNameArabic,
                                        currentQuantity: item.currentQuantity,
                                        description: item.description,
                                        englishName: item.productName,
                                        guarantee: item.product.guarantee,
                                        id: item.productId,
                                        image: item.image,
                                        inventory: item.product.inventory,
                                        inventoryBatch: item.product.inventoryBatch,
                                        isActive: item.isActive,
                                        isExpire: item.isExpire,
                                        isRaw: item.isRaw,

                                        length: item.length,
                                        levelOneUnit: item.product.levelOneUnit,
                                        originId: item.originId,
                                        promotionOffer: item.promotionOffer,
                                        purchasePrice: item.purchasePrice,
                                        salePrice: item.salePrice,
                                        salePriceUnit: item.salePriceUnit,
                                        saleReturnDays: item.saleReturnDays,
                                        serial: item.product.serial,
                                        serviceItem: item.serviceItem,

                                        shelf: item.shelf,
                                        sizeId: item.sizeId,
                                        sizeName: item.sizeName,
                                        sizeNameArabic: item.sizeNameArabic,
                                        stockLevel: item.stockLevel,
                                        styleNumber: item.styleNumber,
                                        subCategoryId: item.subCategoryId,
                                        taxMethod: item.taxMethod,
                                        taxRate: item.taxRate,
                                        taxRateId: item.taxRateId,
                                        taxRateValue: item.taxRateValue,
                                        unit: item.unit,
                                        unitId: item.unitId,

                                        unitPerPack: item.unitPerPack,
                                        width: item.width,
                                        saleSizeAssortment: item.saleSizeAssortment,
                                        highUnitPrice: item.product.highUnitPrice,
                                    });


                                }
                                else {
                                    root.products.push(item.product);
                                }


                                var vat = root.vats.find((value) => value.id == item.taxRateId);

                                root.saleProducts.push({
                                    rowId: item.id,
                                    productId: item.productId,
                                    description: item.description,
                                    unitPrice: item.unitPrice == 0 ? '0' : item.unitPrice,
                                    quantity: item.quantity,
                                    highQty: item.highQty,
                                    discount: item.discount,
                                    offerQuantity: item.offerQuantity == undefined ? 0 : item.offerQuantity,
                                    fixDiscount: item.fixDiscount,
                                    taxRateId: item.taxRateId,
                                    taxMethod: item.taxMethod,
                                    rate: vat.rate,
                                    soQty: item.soQty,
                                    batchExpiry: item.batchExpiry,
                                    batchNo: item.batchNo,
                                    inventoryList: item.product.inventoryBatch == null ? null : item.product.inventoryBatch,
                                    currentQuantity: item.product.inventory == null ? 0 : item.product.inventory.currentQuantity,
                                    saleReturnDays: item.saleReturnDays,
                                    lineTotal: item.unitPrice * item.quantity,
                                    unitPerPack: item.unitPerPack,
                                    levelOneUnit: item.product.levelOneUnit,
                                    basicUnit: item.product.basicUnit,
                                    serial: item.serial,
                                    guaranteeDate: item.guaranteeDate,
                                    isSerial: item.product.serial,
                                    guarantee: item.product.guarantee,
                                    saleSizeAssortment: item.saleSizeAssortment,
                                    saleSizeAssortmentInventory: item.saleSizeAssortmentInventory,
                                    productSizes: item.product.productSizes,
                                    colorId: item.colorId,
                                    colorName: item.colorName,
                                    highUnitPrice: item.product.highUnitPrice,
                                });



                                var product = root.saleProducts.find((x) => {
                                    return x.productId == item.productId && x.rowId == item.id;
                                });

                                if (root.colorVariants) {
                                    root.UpdateColorName(item.colorId, product);
                                }

                                root.getVatValue(product.taxRateId, product);
                                root.updateLineTotal(item.quantity, "quantity", product);
                                root.updateLineTotal(item.highQty, "highQty", product);
                                root.product.id = "";
                                root.rendered++;
                            });
                            root.$emit("details", root.saleProducts);
                        }
                    });
            },

            UpdateColorName: function (colorId, product) {
                product.saleSizeAssortment.forEach(function (item) {
                    var size = product.saleSizeAssortmentInventory.variationInventories.find(x => x.sizeId == item.sizeId);
                    if (size != undefined) {
                        item.currentQuantity = size.quantity;
                    }
                    else {
                        item.currentQuantity = 0;
                    }
                });

                if (colorId != null && colorId != undefined && colorId != '') {
                    product.currentQuantity = product.saleSizeAssortmentInventory.quantity;
                }
            },

            GetSizeData: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('Product/SizeList?isActive=true' + '&isVariance=true', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.saleSizeAssortment = [];
                        response.data.results.sizes.forEach(function (item) {
                            root.saleSizeAssortment.push({
                                sizeId: item.id,
                                name: item.name,
                                quantity: 0,
                            });
                        });
                    }
                });
            },
        },
        created: function () {
            if (this.$i18n.locale == 'en') {
                this.options = ['Inclusive', 'Exclusive'];
            }
            else {
                this.options = ['شامل', 'غير شامل'];
            }

            this.invoiceWoInventory = localStorage.getItem('InvoiceWoInventory') == 'true' ? true : false;
            this.saleDefaultVat = localStorage.getItem('SaleDefaultVat');

            //var barcode = '';
            //var interval;
            //document.addEventListener('keydown', function (evt) {
            //    if (interval)
            //        clearInterval(interval);
            //    if (evt.code === 'Enter') {
            //        if (barcode) {
            //            root.onBarcodeScanned(barcode);
            //        }
            //        barcode = '';
            //        return;

            //    }
            //    if (evt.key !== 'Shift')
            //        barcode += evt.key;
            //});

            //End
            this.getData();
            var root = this;
            localStorage.setItem("BarcodeScan", 'SaleItem')
            if (this.$route.query.mobiledata != undefined) {
                for (var j = 0; j < this.$route.query.mobiledata.mobileOrderItemLookupModels.length; j++) {
                    this.saleProducts.quantity[j] = this.$route.query.mobiledata.mobileOrderItemLookupModels[j].quantity[j];
                }
                root.calcuateSummary();
                this.saleProducts.rowId = this.$route.query.mobiledata.mobileOrderItemLookupModels.rowId;
                this.saleProducts.quantity = this.$route.query.mobiledata.mobileOrderItemLookupModels.quantity;
            }

            if (localStorage.getItem('ColorVariants') == 'true') {
                this.GetSizeData();
            }
        },

        mounted: function () {
            this.colorVariants = localStorage.getItem('ColorVariants') == 'true' ? true : false;
            this.isFifo = localStorage.getItem('fIFO') == 'true' ? true : false;
            this.soInventoryReserve = localStorage.getItem('SoInventoryReserve') == 'true' ? true : false;
            this.decimalQuantity = localStorage.getItem('decimalQuantity') == 'true' ? true : false;
            this.isMultiUnit = localStorage.getItem('IsMultiUnit');
            this.currency = localStorage.getItem('currency');
            this.dayStart = localStorage.getItem('DayStart');
           
            this.isSerial = localStorage.getItem('IsSerial') == 'true' ? true : false;

            this.changePriceDuringSale = localStorage.getItem('changePriceDuringSale');
            this.changePriceDuringSale == 'true' ? (this.changePriceDuringSale = true) : (this.changePriceDuringSale = false);
            this.giveDiscountDuringSale = localStorage.getItem('giveDicountDuringSale');
            this.giveDiscountDuringSale == 'true' ? (this.giveDiscountDuringSale = true) : (this.giveDiscountDuringSale = false);
        },

    };
</script>
<style scoped>

    #sale-item td {
        padding-bottom: 5px;
        padding-top: 5px;
    }

    #sale-item tr td {
        border-top: 0 !important;
        border-bottom: 0 !important;
    }

    .input-txt-danger {
        border: 1px solid #ef8157 !important;
        color: #ef8157 !important;
    }

    .input-border {
        border: transparent;
    }

        .input-border:focus {
            outline: none !important;
            border: none !important;
        }

    .samary_tbl_pd td {
        padding: 20px 7px !important;
    }

    .multiselect__tags {
        background-color: transparent !important;
    }

    .tableHoverOn {
        background-color: #ffffff !important;
        height: 32px !important;
        max-height: 32px !important;
    }

    .multiselect__input, .multiselect__single {
        background-color: transparent !important;
    }
</style>
