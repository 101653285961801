<template>
    <modal :show="show" :modalLarge="modalLarge" v-if=" isValid('CanAddTerminal') || isValid('CanEditTerminal') || isValid('Noble Admin')">

        <div style="margin-bottom:0px" class="card" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <div class="card-body">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="modal-header" v-if="type=='Edit'">
                            <h5 class="modal-title DayHeading" id="myModalLabel">      {{ $t('AddTerminal.UpdateTerminal') }}</h5>
                        </div>
                        <div class="modal-header" v-else>
                            <h5 class="modal-title DayHeading" id="myModalLabel">      {{ $t('AddTerminal.AddTerminal') }}</h5>
                        </div>
                        <div class="card-body">
                            <div class="row ">

                                <div :key="render" class="form-group has-label col-sm-6 ">
                                    <label class="text  font-weight-bolder"> {{ $t('AddTerminal.TerminalType') }}:<span class="LabelColour"> *</span></label>
                                    <multiselect v-model="terminal.terminalType" v-if="($i18n.locale == 'en' ||isLeftToRight()) " @input="GetAutoCodeGenerator" :options="[ 'Terminal', 'CashCounter']" :show-labels="false" placeholder="Select Type">
                                    </multiselect>
                                    <multiselect v-else v-model="terminal.terminalType" :options="[ 'شباك النقود', 'صالة']" @change="GetAutoCodeGenerator" :show-labels="false" v-bind:placeholder="$t('AddTerminal.SelectOption')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    </multiselect>
                                </div>
                                <div :key="renderCode" class="form-group has-label col-sm-6 " v-bind:class="{'has-danger' : $v.terminal.code.$error}">
                                    <label class="text  font-weight-bolder"> {{ $t('AddTerminal.Code') }}:<span class="LabelColour"> *</span></label>
                                    <input disabled class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" v-model="$v.terminal.code.$model" type="text" />
                                    <span v-if="$v.terminal.code.$error" class="error">
                                        <span v-if="!$v.terminal.code.maxLength">{{ $t('AddTerminal.CodeLength') }}</span>
                                    </span>
                                </div>


                                <div class="form-group has-label col-sm-6 ">
                                    <label class="text  font-weight-bolder">   {{$t('AddTerminal.BankAccount')}}:<span class="LabelColour"> *</span> </label>
                                    <accountdropdown v-on:input="getbankPosTerminals(terminal.accountId)" v-model="terminal.accountId" :formName="bank" :companyId="terminal.companyId"></accountdropdown>

                                </div>
                                <div class="form-group has-label col-sm-6 ">
                                    <label class="text  font-weight-bolder">   {{ $t('AddTerminal.BankPosTerminal') }} : </label>
                                    <!--<bankPosTerminalDropdown  v-model="terminal.posTerminalId" :formName='cash'></bankPosTerminalDropdown>-->

                                    <div v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'padding-top:5px;'">
                                        <multiselect v-model="posTerminalId" @input="OnSelectedValue(posTerminalId.id)" :options="bankposTerminalOptions" :disabled="terminal.accountId=='' || terminal.accountId==null" :show-labels="false" track-by="terminalId" label="terminalId" v-bind:placeholder="$t('AddTerminal.PleaseSelectBankAccount')">
                                        </multiselect>
                                    </div>
                                </div>
                                <div class="form-group has-label col-sm-6 " v-if="terminal.companyId != ''">
                                    <label class="text  font-weight-bolder"> {{ $t('AddTerminal.CompanyName(InEnglish)') | englishLanguage}}:</label>
                                    <input class="form-control" v-bind:disabled="!terminal.overWrite" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="terminal.companyNameEnglish" type="text" />

                                </div>
                                <div class="form-group has-label col-sm-6 " v-if="terminal.companyId != ''">
                                    <label class="text  font-weight-bolder"> {{ $t('AddTerminal.CompanyName(InEnglish)') | arabicLanguage}}:</label>
                                    <input class="form-control" v-bind:disabled="!terminal.overWrite" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="terminal.companyNameArabic" type="text" />

                                </div>
                                <div class="form-group has-label col-sm-6 " v-if="terminal.companyId != ''">
                                    <label class="text  font-weight-bolder"> {{ $t('AddTerminal.BusinessNameInEnglish') | englishLanguage}}:</label>
                                    <input class="form-control" v-bind:disabled="!terminal.overWrite" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="terminal.businessNameEnglish" type="text" />

                                </div>
                                <div class="form-group has-label col-sm-6 " v-if="terminal.companyId != ''">
                                    <label class="text  font-weight-bolder"> {{ $t('AddTerminal.BusinessNameInEnglish') | arabicLanguage}}:</label>
                                    <input class="form-control" v-bind:disabled="!terminal.overWrite" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="terminal.businessNameArabic" type="text" />

                                </div>
                                <div class="form-group has-label col-sm-6 " v-if="terminal.companyId != ''">
                                    <label class="text  font-weight-bolder"> {{ $t('AddTerminal.BusinessType(InEnglish)') | englishLanguage}}:</label>
                                    <input class="form-control" v-bind:disabled="!terminal.overWrite" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="terminal.businessTypeEnglish" type="text" />

                                </div>
                                <div class="form-group has-label col-sm-6 " v-if="terminal.companyId != ''">
                                    <label class="text  font-weight-bolder"> {{ $t('AddTerminal.BusinessType(InEnglish)') | arabicLanguage}}:</label>
                                    <input class="form-control" v-bind:disabled="!terminal.overWrite" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="terminal.businessTypeArabic" type="text" />

                                </div>
                                <div class="form-group has-label col-sm-12">
                                    <label class="text  font-weight-bolder" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'"> {{ $t('AddTerminal.Printer') }}: <span class="LabelColour"> *</span></label>
                                    <printer-list-dropdown v-model="terminal.printerName" v-bind:key="renderPrinter" :values="terminal.printerName"></printer-list-dropdown>
                                </div>

                                <div class="form-group has-label col-sm-12">
                                    <label>{{ $t('AddTerminal.ProductCategory') }} :<span class="LabelColour"> *</span></label>
                                    <multiselect v-model="categoryId" :options="categoryOptions" :show-labels="false" track-by="name" :multiple="true" label="name" v-bind:placeholder="$t('AddTerminal.PleaseSelectProductCategory')">
                                        <p slot="noResult" class="text-danger"> Oops! No Category found.</p>
                                    </multiselect>
                                </div>

                                <div class="form-group has-label col-xm-12 col-sm-6 col-md-6 col-lg-4">
                                    <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                        <label>{{ $t('AddTerminal.OnPageLoadItem') }}  : </label>
                                        <toggle-button v-model="terminal.onPageLoadItem" class="ml-2 mt-2" color="#3178F6" />
                                    </div>
                                </div>
                                <div class="form-group has-label col-xm-12 col-sm-6 col-md-6 col-lg-4">
                                    <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                        <label>Allow All  : </label>
                                        <toggle-button v-model="allowAll" v-on:change="SelectAllCategory(allowAll)" class="ml-2 mt-2" color="#3178F6" />
                                    </div>
                                </div>
                                <div class="form-group col-xm-12 col-sm-6 col-md-6 col-lg-4" v-if=" terminal.companyId != ''">
                                    <label style="margin: 7px;">Over Write</label>
                                    <toggle-button v-model="terminal.overWrite" class="ml-2 mt-2" color="#3178F6" />

                                    <!--<div class="bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-animate" v-bind:class="{'bootstrap-switch-on': terminal.overWrite, 'bootstrap-switch-off': !terminal.overWrite}" v-on:click="terminal.overWrite = !terminal.overWrite" style="width: 72px;">
                                        <div class="bootstrap-switch-container" style="width: 122px; margin-left: 0px;">
                                            <span class="bootstrap-switch-handle-on bootstrap-switch-success" style="width: 50px;">
                                                <i class="nc-icon nc-check-2"></i>
                                            </span>
                                            <span class="bootstrap-switch-label" style="width: 30px;">&nbsp;</span>
                                            <span class="bootstrap-switch-handle-off bootstrap-switch-success" style="width: 50px;">
                                                <i class="nc-icon nc-simple-remove"></i>
                                            </span>
                                            <input class="bootstrap-switch" type="checkbox" data-toggle="switch" checked="" data-on-label="<i class='nc-icon nc-check-2'></i>" data-off-label="<i class='nc-icon nc-simple-remove'></i>" data-on-region="success" data-off-region="success">
                                        </div>
                                    </div>-->
                                </div>
                                <div class="col-sm-12" v-if=" terminal.companyId != ''">
                                    <div :key="renderImg">
                                        <input ref="imgupload" type="file"
                                               id="file-input"
                                               @change="uploadImage()"
                                               accept="image/*"
                                               name="image"
                                               v-if="!((imageSrc == '' && terminal.businessLogo!='') || (imageSrc != '' && terminal.businessLogo=='') || (imageSrc != '' && terminal.businessLogo!=''))"
                                               style="opacity:1;padding:25px">

                                        <div class="mt-3" v-if="imageSrc != ''">
                                            <img v-if="imageSrc != ''" :src="imageSrc" width="100" />
                                        </div>
                                        <div v-else class="mt-3">
                                            <span v-if="terminal.businessLogo!=null && terminal.businessLogo!=''">
                                                <!--<productimage v-bind:path="terminal.businessLogo" />-->
                                                <img :src="'data:image/png;base64,' + terminal.businessLogo" width="100" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12" v-if="terminal.companyId != ''">
                                    <button class="btn btn-danger  btn-sm" v-if="imageSrc != '' || terminal.businessLogo!=''" v-on:click="removeImage()">Remove</button>
                                </div>

                                <!--<div class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.terminal.ipAddress.$error}">
        <label class="text  font-weight-bolder"> {{ $t('Terminal.IPAddress') }}</label>
        <input class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.terminal.ipAddress.$model" type="text" />
        <span v-if="$v.terminal.ipAddress.$error" class="error">{{ $t('Terminal.IPValidation') }}</span>
    </div>-->
                                <!--<div class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.terminal.macAddress.$error}">
        <label class="text  font-weight-bolder">{{ $t('Terminal.MACAddress') }} </label>
        <input class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.terminal.macAddress.$model" type="text" />
        <span v-if="$v.terminal.macAddress.$error" class="error">{{ $t('Terminal.MAValidation') }}</span>
    </div>-->
                            </div>
                        </div>

                        <div class="modal-footer justify-content-right" v-if="type=='Edit' && (isValid('CanEditTerminal')|| isValid('Noble Admin'))">

                            <button type="button" class="btn btn-primary  " v-on:click="SaveTerminal" v-bind:disabled="$v.terminal.$invalid"> {{ $t('AddTerminal.btnUpdate') }}</button>
                            <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{ $t('AddTerminal.btnClear') }}</button>

                        </div>
                        <div class="modal-footer justify-content-right" v-if="type!='Edit' && (isValid('CanAddTerminal')|| isValid('Noble Admin'))">

                            <button type="button" class="btn btn-primary  " v-on:click="SaveTerminal" v-bind:disabled="$v.terminal.$invalid"> {{ $t('AddTerminal.btnSave') }}</button>
                            <button type="button" class="btn btn-outline-danger  mr-3 " v-on:click="close()">{{ $t('AddTerminal.btnClear') }}</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </modal>
    <acessdenied v-else :model=true></acessdenied>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import 'vue-loading-overlay/dist/vue-loading.css';
    import Multiselect from 'vue-multiselect'


    import { required, maxLength } from "vuelidate/lib/validators"
    export default {
        mixins: [clickMixin],
        components: {
            Multiselect,
        },
        props: ['show', 'terminal', 'type'],
        data: function () {
            return {
                allowAll: false,
                modalLarge: true,
                render: 0,
                renderCode: 0,
                renderPrinter: 0,
                cash: 'CashReceipt',
                bank: 'BankReceipt',
                bankposTerminalOptions: [],
                categoryOptions: [],
                categoryId: [],
                posTerminalId: '',
                overWrite: false,
                renderImg: 0,
                imageSrc: '',
            }
        },
        validations: {
            terminal: {

                code: {
                    maxLength: maxLength(10),
                    required
                },
                accountId: {
                    required
                },



            }
        },
        methods: {
            removeImage: function () {
                this.imageSrc = '';
                this.terminal.businessLogo = '';
                this.renderImg++;

            },
            uploadImage: function () {
                var root = this;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                var file = this.$refs.imgupload.files;

                var fileData = new FormData();

                for (var k = 0; k < file.length; k++) {
                    fileData.append("files", file[k]);
                }

                this.imageSrc = URL.createObjectURL(this.$refs.imgupload.files[0]);

                root.$https.post('/Company/UploadFilesAsync', fileData, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {

                            root.terminal.businessLogo = response.data;
                        }
                    },
                        function () {
                            this.loading = false;
                            root.$swal({
                                title: root.$t('AddTerminal.Error'),
                                text: root.$t('AddTerminal.SomethingWrong'),
                                type: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                        });
            },
            OnSelectedValue: function (id) {

                this.terminal.posTerminalId = id;
            },
            getbankPosTerminals: function (x) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }


                this.posTerminalId = [];
                this.bankposTerminalOptions = [];
                this.$https.get('/Company/BankPosTerminalList?isActive=true' + '&bankId=' + x, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {

                        response.data.bankPosTerminals.forEach(function (result) {
                            if (result.id == root.terminal.posTerminalId) {
                                root.posTerminalId.push({
                                    id: result.id,
                                    terminalId: result.terminalId
                                })
                            }
                            root.bankposTerminalOptions.push({
                                id: result.id,
                                terminalId: result.terminalId
                            })
                        })
                    }
                })
            },
            close: function () {
                this.$emit('close');
            },
            GetAutoCodeGenerator: function () {

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.get('/Company/TerminalCode?terminalType=' + root.terminal.terminalType, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                    if (response.data != null) {
                        root.terminal.code = response.data;
                        root.render++;
                        root.renderCode++;
                        root.renderPrinter++;
                    }
                });
            },
            SaveTerminal: function () {
                
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                if (this.categoryId != null && this.categoryId != undefined && this.categoryId != '') {
                    this.terminal.categoryId = this.getCategoryId(this.categoryId);
                }
                //if (this.terminal.overWrite) {
                //    localStorage.setItem('BusinessLogo', this.terminal.businessLogo);
                //    localStorage.setItem('overWrite', this.terminal.overWrite);
                //    localStorage.setItem('BusinessNameArabic', this.terminal.businessNameArabic);
                //    localStorage.setItem('BusinessNameEnglish', this.terminal.businessNameEnglish);
                //    localStorage.setItem('BusinessTypeArabic', this.terminal.businessTypeArabic);
                //    localStorage.setItem('BusinessTypeEnglish', this.terminal.businessTypeEnglish);
                //    localStorage.setItem('CompanyNameArabic', this.terminal.companyNameArabic);
                //    localStorage.setItem('CompanyNameEnglish', this.terminal.companyNameEnglish);
                //}
               
                
                this.$https.post('/Company/SaveTerminal', this.terminal, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data.isSuccess == true) {

                        if (root.type != "Edit") {
                            root.$store.state.terminalList.push({
                                id: response.data.terminals.id,
                                macAddress: response.data.terminals.macAddress,
                                ipAddress: response.data.terminals.ipAddress,
                                accountId: response.data.terminals.accountId,
                                posTerminalId: response.data.terminals.posTerminalId,
                                code: response.data.terminals.code,
                                printerName: response.data.terminals.printerName,
                                onPageLoadItem: response.data.terminals.onPageLoadItem,
                            })
                            root.$swal({
                                title: "Saved!",
                                text: "Your Terminal " + response.data.terminals.code + " has been created!",
                                type: 'success',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 800,
                                timerProgressBar: true,
                            });
                            root.close();
                        }
                        else {
                            var data = root.$store.state.terminalList.find(function (x) {
                                return x.id == response.data.terminals.id;
                            });
                            data.id = response.data.terminals.id;
                            data.ipAddress = response.data.terminals.ipAddress;
                            data.macAddress = response.data.terminals.macAddress;
                            data.code = response.data.terminals.code;
                            data.accountId = response.data.terminals.accountId;
                            data.posTerminalId = response.data.terminals.posTerminalId;
                            data.printerName = response.data.terminals.printerName;
                            data.onPageLoadItem = response.data.terminals.onPageLoadItem;
                            root.$swal({
                                title: "Update!",
                                text: "Your Terminal " + response.data.terminals.code + " has been updated!",
                                type: 'success',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 800,
                                timerProgressBar: true,
                            });
                            root.close();
                        }
                    }
                    else {
                        root.$swal({
                            title: "Error!",
                            text: "Your terminal Name  Already Exist!",
                            type: 'error',
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 800,
                            timerProgressBar: true,
                        });
                    }
                });
            },

            SelectAllCategory: function () {
                this.getCategoryData(false);
                if (!this.allowAll) {
                    this.categoryId = [];
                }
            },

            getCategoryId: function (value) {
                var categoryId = [];
                for (var i = 0; i < value.length; i++) {
                    categoryId[i] = value[i].id
                }
                return categoryId;
            },

            getCategoryData: function (edit) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.categoryOptions = [];
                this.$https.get('/Product/GetCategoryInformation?isActive=true' + '&companyId=' + this.terminal.companyId, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                    if (response.data != null) {

                        response.data.results.categories.forEach(function (cat) {
                            root.categoryOptions.push({
                                id: cat.id,
                                dropDownName: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? (cat.name != "" ? cat.code + ' ' + cat.name : cat.code + ' ' + cat.nameArabic) : (cat.nameArabic != "" ? cat.code + ' ' + cat.nameArabic : cat.code + ' ' + cat.name),
                                name: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? (cat.name != "" ? cat.name : cat.nameArabic) : (cat.nameArabic != "" ? cat.nameArabic : cat.name)

                            })
                        });
                        
                        if (edit) {
                            root.terminal.categoryIdList.forEach(function (cat) {
                                var category = root.categoryOptions.find(x => x.id == cat);
                                if (category != undefined) {
                                    root.categoryId.push(category);
                                }
                            });
                        }

                        if (root.allowAll) {
                            root.categoryId = [];
                            response.data.results.categories.forEach(function (cat) {
                                root.categoryId.push({
                                    id: cat.id,
                                    dropDownName: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? (cat.name != "" ? cat.code + ' ' + cat.name : cat.code + ' ' + cat.nameArabic) : (cat.nameArabic != "" ? cat.code + ' ' + cat.nameArabic : cat.code + ' ' + cat.name),
                                    name: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? (cat.name != "" ? cat.name : cat.nameArabic) : (cat.nameArabic != "" ? cat.nameArabic : cat.name)

                                })
                            });
                        }
                        
                    }
                });
            },


        },
        mounted: function () {
            
            if (this.terminal.id != '00000000-0000-0000-0000-000000000000' || this.terminal.id != undefined || this.terminal.id != '') {
                this.getbankPosTerminals(this.terminal.accountId);
            }

            if (this.terminal.id == '00000000-0000-0000-0000-000000000000') {
                this.getCategoryData(false);
            }
            else {
                this.getCategoryData(true);
            }
            this.renderImg++;
        }
    }
</script>
