<template>
    <div>
        <div class="col-md-11 col-lg-12 ml-auto mr-auto">
            <div class="card">
                <div class="card-body">
                    <div class="col-lg-12">
                        <div class="tab-content" id="nav-tabContent">
                            <div class=" col-lg-12 mt-2">
                                <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                    <div>
                                        <h5 class="card-title  ">{{ $t('AddBusiness.Heading') }}</h5>
                                    </div>
                                    <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                        <div class="row">

                                            <div class="col-md-6">
                                                <div class="card" style="background-color: #8383830d">
                                                    <div>
                                                        <h5 class="card-title DayHeading  ">{{ $t('AddBusiness.EnglishInfo') }}</h5>
                                                    </div>
                                                    <div class="row  " v-if="($i18n.locale == 'en' ||isLeftToRight())">

                                                        <div class="col-md-6 " v-bind:class="{'has-danger' : $v.business.nameInEnglish.$error}">
                                                            <label class="text  font-weight-bolder ">{{ $t('AddBusiness.NameEn') }} :<span class="LabelColour"> *</span></label>
                                                            <input class="form-control" v-model="$v.business.nameInEnglish.$model" type="text" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" />
                                                            <span v-if="$v.business.nameInEnglish.$error" class="error text-danger">
                                                                <span v-if="!$v.business.nameInEnglish.required">{{ $t('AddBusiness.Name') }}</span>
                                                                <span v-if="!$v.business.nameInEnglish.maxLength">{{ $t('AddBusiness.NameLength') }}</span>
                                                            </span>
                                                        </div>
                                                        <div class="col-md-6 " v-bind:class="{'has-danger' : $v.business.categoryInEnglish.$error}">
                                                            <label class="text  font-weight-bolder">{{ $t('AddBusiness.BusinessTypeEng') }} :<span class="LabelColour"> *</span></label>
                                                            <input class="form-control" v-model="$v.business.categoryInEnglish.$model" type="text" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" />
                                                            <span v-if="$v.business.categoryInEnglish.$error" class="error text-danger">
                                                                <span v-if="!$v.business.categoryInEnglish.required">{{ $t('AddBusiness.Category') }}</span>
                                                                <span v-if="!$v.business.categoryInEnglish.maxLength">{{ $t('AddBusiness.CategoryLength') }}</span>
                                                            </span>
                                                        </div>
                                                        <div class="col-md-12 form-group">
                                                            <label for="business.address">Address  (English): <span class="LabelColour"> *</span></label>
                                                            <textarea class="form-control" v-model="business.addressInEnglish" />
                                                        </div>
                                                    </div>
                                                    <div class="row  " v-else>

                                                        <div class="col-md-6 " v-bind:class="{'has-danger' : $v.business.categoryInEnglish.$error}">
                                                            <label class="text  font-weight-bolder">{{ $t('AddBusiness.BusinessTypeEng') }} :<span class="LabelColour"> *</span></label>
                                                            <input class="form-control" v-model="$v.business.categoryInEnglish.$model" type="text" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" />
                                                            <span v-if="$v.business.categoryInEnglish.$error" class="error text-danger">
                                                                <span v-if="!$v.business.categoryInEnglish.required">{{ $t('AddBusiness.Category') }}</span>
                                                                <span v-if="!$v.business.categoryInEnglish.maxLength">{{ $t('AddBusiness.CategoryLength') }}</span>
                                                            </span>
                                                        </div>
                                                        <div class="col-md-6 " v-bind:class="{'has-danger' : $v.business.nameInEnglish.$error}">
                                                            <label class="text  font-weight-bolder ">{{ $t('AddBusiness.NameEn') }} :<span class="LabelColour"> *</span></label>
                                                            <input class="form-control" v-model="$v.business.nameInEnglish.$model" type="text" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" />
                                                            <span v-if="$v.business.nameInEnglish.$error" class="error text-danger">
                                                                <span v-if="!$v.business.nameInEnglish.required">{{ $t('AddBusiness.Name') }}</span>
                                                                <span v-if="!$v.business.nameInEnglish.maxLength">{{ $t('AddBusiness.NameLength') }}</span>
                                                            </span>
                                                        </div>

                                                    </div>



                                                </div>
                                            </div>

                                            <div class="col-md-6   ">
                                                <div class="card" style="background-color: #8383830d">
                                                    <div>
                                                        <h5 class="card-title DayHeading ">{{ $t('AddBusiness.ArabicInfo') }}</h5>
                                                    </div>
                                                    <div class="row" v-if="($i18n.locale == 'en' ||isLeftToRight())">
                                                        <div class="col-md-6 " v-bind:class="{'has-danger' : $v.business.nameInArabic.$error}">
                                                            <label class="text  font-weight-bolder ">{{ $t('AddBusiness.NameArabic') }} :<span class="LabelColour"> *</span></label>
                                                            <input class="form-control text-right" v-model="$v.business.nameInArabic.$model" type="text" v-bind:class="$i18n.locale == 'ar' ? 'text-left' : 'text-right'" />
                                                            <span v-if="$v.business.nameInArabic.$error" class="error text-danger">
                                                                <span v-if="!$v.business.nameInArabic.required">{{ $t('AddBusiness.Name') }}</span>
                                                                <span v-if="!$v.business.nameInArabic.maxLength">{{ $t('AddBusiness.NameLength') }}</span>
                                                            </span>
                                                        </div>
                                                        <div class="col-md-6 " v-bind:class="{'has-danger' : $v.business.categoryInArabic.$error}">
                                                            <label class="text  font-weight-bolder">{{ $t('AddBusiness.BusinessTypeArabic') }} :<span class="LabelColour"> *</span></label>
                                                            <input class="form-control text-right" v-model="$v.business.categoryInArabic.$model" type="text" v-bind:class="$i18n.locale == 'ar' ? 'text-left' : 'text-right'" />
                                                            <span v-if="$v.business.categoryInArabic.$error" class="error text-danger">
                                                                <span v-if="!$v.business.categoryInArabic.required">{{ $t('AddBusiness.Category') }}</span>
                                                                <span v-if="!$v.business.categoryInArabic.maxLength">{{ $t('AddBusiness.CategoryLength') }}</span>
                                                            </span>
                                                        </div>
                                                        <div class="col-md-12 form-group">
                                                            <label for="business.address">Address (Arabic): <span class="LabelColour"> *</span></label>
                                                            <textarea class="form-control" v-model="business.addressInArabic" />
                                                        </div>
                                                    </div>
                                                    <div class="row" v-else>
                                                        <div class="col-md-6 " v-bind:class="{'has-danger' : $v.business.categoryInArabic.$error}">
                                                            <label class="text  font-weight-bolder">{{ $t('AddBusiness.BusinessTypeArabic') }} :<span class="LabelColour"> *</span></label>
                                                            <input class="form-control text-right" v-model="$v.business.categoryInArabic.$model" type="text" v-bind:class="$i18n.locale == 'ar' ? 'text-left' : 'text-right'" />
                                                            <span v-if="$v.business.categoryInArabic.$error" class="error text-danger">
                                                                <span v-if="!$v.business.categoryInArabic.required">{{ $t('AddBusiness.Category') }}</span>
                                                                <span v-if="!$v.business.categoryInArabic.maxLength">{{ $t('AddBusiness.CategoryLength') }}</span>
                                                            </span>
                                                        </div>
                                                        <div class="col-md-6 " v-bind:class="{'has-danger' : $v.business.nameInArabic.$error}">
                                                            <label class="text  font-weight-bolder ">{{ $t('AddBusiness.NameArabic') }} :<span class="LabelColour"> *</span></label>
                                                            <input class="form-control text-right" v-model="$v.business.nameInArabic.$model" type="text" v-bind:class="$i18n.locale == 'ar' ? 'text-left' : 'text-right'" />
                                                            <span v-if="$v.business.nameInArabic.$error" class="error text-danger">
                                                                <span v-if="!$v.business.nameInArabic.required">{{ $t('AddBusiness.Name') }}</span>
                                                                <span v-if="!$v.business.nameInArabic.maxLength">{{ $t('AddBusiness.NameLength') }}</span>
                                                            </span>
                                                        </div>

                                                    </div>




                                                </div>
                                            </div>

                                        </div>

                                    </div>





                                </div>

                            </div>
                        </div>

                    </div>
                    <div class="card mt-2  ml-4 mr-4" style="background-color: #8383830d">
                        <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                            <div>
                                <h5 class="card-title DayHeading ">{{ $t('AddBusiness.AddSuperAdmin') }}</h5>
                            </div>
                            <div class="card-body" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                <div class="row">
                                    <div class="col-md-3">
                                        <label for="business.email">{{ $t('AddBusiness.RegisterUser_EmailID') }}  :<span class="LabelColour"> *</span></label>
                                        <div v-bind:class="{'has-danger' : $v.business.email.$error}">
                                            <input class="form-control" type="email" v-model="$v.business.email.$model" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" />
                                            <span v-if="$v.business.email.$error" class="error text-danger">
                                                <span v-if="!$v.business.email.required">{{ $t('AddBusiness.RegisterUser_EmailID') }}</span>
                                                <span v-if="!$v.business.email.email">{{ $t('AddBusiness.RegisterUser_Error_Format_EmailID') }}</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="business.email">{{ $t('AddBusiness.RegisterUser_UserName') }}  :<span class="LabelColour"> *</span></label>
                                        <div v-bind:class="{'has-danger' : $v.business.userId.$error}">
                                            <input class="form-control" type="text" v-model="$v.business.userId.$model" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" />
                                            <span v-if="$v.business.userId.$error" class="error text-danger">
                                                <span v-if="!$v.business.userId.required">{{ $t('AddBusiness.RegisterUser_Error_Required_DisplayName') }}</span>
                                                <span v-if="!$v.business.userId.maxLength">{{ $t('AddBusiness.RegisterUser_Error_Length_DisplayName') }}</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="business.password">{{ $t('AddBusiness.RegisterUser_Password') }}  :<span class="LabelColour"> *</span></label>
                                        <div v-bind:class="{'has-danger' : $v.business.password.$error}">
                                            <input class="form-control" type="password" v-model="$v.business.password.$model" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" />
                                            <span v-if="$v.business.password.$error" class="error text-danger">
                                                <span v-if="!$v.business.password.required">{{ $t('AddBusiness.RegisterUser_Error_Required_Password') }}</span>
                                                <span v-if="!$v.business.password.strongPassword">{{ $t('AddBusiness.RegisterUser_Error_Format_Password') }}</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="business.confirmPassword">{{ $t('AddBusiness.RegisterUser_ConfirmPassword') }} :<span class="LabelColour"> *</span></label>
                                        <div v-bind:class="{'has-danger' : $v.business.confirmPassword.$error}">
                                            <input class="form-control" type="password" v-model="$v.business.confirmPassword.$model" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" />
                                            <span v-if="$v.business.confirmPassword.$error" class="error text-danger">
                                                <span v-if="!$v.business.confirmPassword.required">{{ $t('AddBusiness.RegisterUser_Error_Required_ConfirmPassword') }}</span>
                                                <span v-if="!$v.business.confirmPassword.sameAsPassword">{{ $t('AddBusiness.RegisterUser_Error_SameAs_ConfirmPassword') }}</span>
                                            </span>
                                        </div>
                                    </div>

                                </div>

                                <div v-if="!loading" class="card-footer col-md-12 " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                                    <div v-if="($i18n.locale == 'en' ||isLeftToRight())">
                                        <a href="javascript:void(0)" class="btn btn-outline-primary  mr-2" v-on:click="SaveBusiness" v-bind:disabled="$v.business.$invalid"><i class="fa fa-save"></i> {{ $t('AddBusiness.btnSave') }}</a>
                                        <a href="javascript:void(0)" class="btn btn-outline-danger " v-on:click="GoToBusiness"> {{ $t('AddBusiness.btnClear') }}</a>

                                    </div>
                                    <div v-else>
                                        <a href="javascript:void(0)" class="btn btn-outline-danger  mr-2" v-on:click="GoToBusiness"> {{ $t('AddBusiness.btnClear') }}</a>
                                        <a href="javascript:void(0)" class="btn btn-outline-primary  " v-on:click="SaveBusiness" v-bind:disabled="$v.business.$invalid"><i class="fa fa-save"></i> {{ $t('AddBusiness.btnSave') }}</a>
                                    </div>


                                </div>
                                <div class="card-footer col-md-3" v-else>
                                    <loading :active.sync="loading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="true"></loading>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>


</template>
<script>
    import { required, requiredIf, maxLength, email, sameAs } from 'vuelidate/lib/validators';
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default ({
        components: {
            Loading
        },
        data: function () {
            return {
                active: 'business',
                country: '',
                country1: '',
                business: {
                    id: '',
                    nameInArabic: '',
                    nameInEnglish: '',
                    categoryInArabic: '',
                    categoryInEnglish: '',
                    cityInEnglish: '',
                    cityInArabic: '',
                    countryInEnglish: '',
                    countryInArabic: '',
                    addressInEnglish: '',
                    addressInArabic: '',
                    PhoneNumberInArabic: '',
                    PhoneNumberInEnglish: '',
                    userId: '',
                    password: '',
                    confirmPassword: '',
                    email: '',
                    clientParentId: '00000000-0000-0000-0000-000000000000'

                },
                loading: false,

            }
        },

        validations: {

            business: {
                nameInArabic: {
                    required: requiredIf((x) => {
                        if (x.nameInEnglish == '' || x.nameInEnglish == null)
                            return true;
                        return false;
                    }),
                    maxLength: maxLength(50)
                },
                nameInEnglish: {
                    maxLength: maxLength(50)
                },
                categoryInArabic: {
                    required: requiredIf((x) => {
                        if (x.categoryInEnglish == '' || x.categoryInEnglish == null)
                            return true;
                        return false;
                    }),
                    maxLength: maxLength(50)
                },
                categoryInEnglish: {
                    maxLength: maxLength(50)
                },

                userId: {
                    required,
                    maxLength: maxLength(30)
                },


                email:
                {
                    required,
                    email
                },
                password:
                {
                    required,
                    strongPassword(password) {
                        return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(password);
                    }
                },
                confirmPassword:
                {
                    required,
                    sameAsPassword: sameAs('password')
                },
            },

        },
        methods: {

            GoToBusiness: function () {
                if (this.$route.query.data != undefined) {
                    this.$router.push('/clientManagement');
                } else {
                    this.$router.push('/clientManagement');
                }
            },
            SaveBusiness: function () {
                //eslint-disable-line

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                if (this.$route.query.data != undefined) {
                    this.business.clientParentId = this.$route.query.data;
                }
                this.$https
                    .post('/Company/SaveBusiness', this.business, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {
                        this.info = response.data.bpi
                        this.loading = false
                        this.$swal.fire({
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1000,
                            timerProgressBar: true,
                            title: 'Saved Successfully'
                        });
                        if (this.$route.query.data != undefined) {
                            this.$router.push('/clientManagement');
                        } else {
                            this.$router.push('/clientManagement');
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        this.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Something Went Wrong!',
                                text: error,
                                showConfirmButton: false,
                                timer: 1000,
                                timerProgressBar: true,

                            });

                        this.loading = false
                    })
                    .finally(() => this.loading = false)
            }
        },
        mounted: function () {



            if (this.$route.query.data != undefined) {
                this.company = this.$route.query.data;

                this.country = this.business.countryInEnglish;
                this.country1 = this.business.countryInArabic;
            }

        }
    })
</script>