<template>
    <div class="row" v-if="isValid('CanViewVatRate')"  v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
        <div class="col-lg-12 col-sm-12 ml-auto mr-auto">
            <div class="card ">
                <div class="BorderBottom ml-4 mr-4 mt-3 mb-3">
                    <span class=" DayHeading">{{ $t('TaxRate.AddTaxRate') }}</span>
                </div>
               
          
                <div class="card-body">
                    <div class="col-lg-12">
                        <div class="row" >
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                <div class="form-group">
                                    <label>{{ $t('TaxRate.SearchbyName') }}</label>
                                    <div>
                                        <input type="text" class="form-control search_input" v-model="searchQuery" name="search" id="search" :placeholder="$t('TaxRate.Search')" />
                                        <span class="fas fa-search search_icon"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'" style="margin-top:18px;">
                                <a v-if="isValid('CanAddVatRate')" href="javascript:void(0)" class="btn btn-primary" v-on:click="openmodel"><i class="fa fa-plus"></i>  {{ $t('TaxRate.AddNew') }}</a>
                                <router-link :to="'/FinancialSetup'">
                                    <a href="javascript:void(0)" class="btn btn-outline-danger ">  {{ $t('TaxRate.Close') }} </a>
                                </router-link>
                            </div>

                        </div>
                       
                        <div class="mt-2">
                            <div>
                                <table class="table table-shopping" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <thead class="">
                                        <tr>
                                            <th>#</th>
                                            <th>
                                                {{ $t('TaxRate.Code') }}
                                            </th>
                                            <th>
                                                {{ $t('TaxRate.TaxRateNameEn') |englishLanguage}}
                                            </th>
                                            <th>
                                                {{ $t('TaxRate.TaxRateNameAr') |arabicLanguage}}
                                            </th>

                                            <th>
                                                {{ $t('TaxRate.Description') }}
                                            </th>
                                            <th>
                                                {{ $t('TaxRate.TaxRate') }}
                                            </th>
                                            <th>
                                                {{ $t('TaxRate.Status') }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(taxRate,index) in resultQuery" v-bind:key="taxRate.id">
                                            <td>
                                                {{index+1}}
                                            </td>

                                            <td v-if="isValid('CanEditVatRate')">
                                                <span v-if="taxRate.id==taxRateId" style="color:red !important; font-weight:bold">
                                                    <a>{{taxRate.code}}</a>
                                                </span>
                                                <strong v-else>
                                                    <a href="javascript:void(0)" v-on:click="EditTaxRate(taxRate.id)">{{taxRate.code}}</a>
                                                </strong>
                                            </td>
                                            <td v-else>
                                                {{taxRate.code}}
                                            </td>
                                            <td v-if="taxRate.id==taxRateId">
                                                <span style="font-weight:bold">{{ $t('TaxRate.Default') }}(</span> {{taxRate.name}}   <span style="font-weight:bold">)</span>
                                            </td>
                                            <td v-else>
                                                {{taxRate.name}}
                                            </td>
                                            <td v-if="taxRate.id==taxRateId">
                                                <span style="font-weight:bold">{{ $t('TaxRate.Default') }}(</span>   {{taxRate.nameArabic}}  <span style="font-weight:bold">)</span>
                                            </td>
                                            <td v-else>
                                                {{taxRate.nameArabic}}
                                            </td>

                                            <td>
                                                {{taxRate.description}}
                                            </td>
                                            <td v-if="index==0">
                                                {{taxRate.rate}}  <span style="font-weight:bold">({{taxMethod}})</span>
                                            </td>
                                            <td v-else>
                                                {{taxRate.rate}}
                                            </td>
                                            <td>{{taxRate.isActive==true?$t('TaxRate.Active'):$t('TaxRate.De-Active')}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <taxratemodel :taxRate="newTaxRate"
                      :show="show"
                      v-if="show"
                      @close="show = false"
                      :type="type" />

    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>

<script>
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        data: function () {
            return {
                searchQuery: '',
                show: false,
                taxRatelist: [],
                newTaxRate: {
                    id: '',
                    name: '',
                    taxMethod: '',
                    nameArabic: '',
                    description: '',
                    code: '',
                    rate: 0,
                    isActive: true
                },
                type: '',
                taxRateId: '',
            }
        },
        computed: {
            resultQuery: function () {
                var root = this;
                if (this.searchQuery) {
                    return this.taxRatelist.filter((taxRate) => {
                        return root.searchQuery.toLowerCase().split(' ').every(v => taxRate.name.toLowerCase().includes(v) || taxRate.nameArabic.toLowerCase().includes(v))
                    })
                } else {
                    return root.taxRatelist;
                }
            },
        },
        methods: {
            openmodel: function () {
                this.newTaxRate = {
                    id: '00000000-0000-0000-0000-000000000000',
                    name: '',
                    nameArabic: '',
                    description: '',
                    code: '',
                    rate:0,
                    isActive: true
                }
                this.show = !this.show;
                this.type = "Add";
            },
            GetTaxRateData: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('Product/TaxRateList?isActive=false', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.$store.dispatch('GetTaxRateList', response.data.taxRates);
                        root.taxRatelist = response.data.taxRates;
                        root.taxMethod = response.data.taxMethod;
                    }
                });
            },
            EditTaxRate: function (Id) {
                var root = this;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Product/TaxRateDetail?Id=' + Id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data) {
                            
                            root.newTaxRate.id = response.data.id;
                            root.newTaxRate.name = response.data.name;
                            root.newTaxRate.nameArabic = response.data.nameArabic;
                            root.newTaxRate.rate = response.data.rate;
                            root.newTaxRate.description = response.data.description;
                            root.newTaxRate.code = response.data.code;
                            root.newTaxRate.isActive = response.data.isActive;
                            root.show = !root.show;
                            root.type = "Edit"
                        } else {
                            console.log("error: something wrong from db.");
                        }
                    },
                        function (error) {
                            this.loading = false;
                            console.log(error);
                        });

            }
        },
        created: function () {
            this.$emit('input', this.$route.name);

            this.taxRateId = localStorage.getItem('TaxRateId')
        },
        mounted: function () {
            this.GetTaxRateData();
        }
    }
</script>