<template>
    <div class="row" v-if="isValid('CanViewSupplier')">
        <div class="col-lg-12 col-sm-12 ml-auto mr-auto" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <div class="row mb-4" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                <div class="col-sm-6 col-md-6 col-lg-6">
                    <h5 class="page_title">{{ $t('Supplier.ListOfSupplier') }}</h5>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link :to="'/StartScreen'"><a href="javascript:void(0)"> {{ $t('Supplier.Home') }}</a></router-link></li>

                            <li class="breadcrumb-item active" aria-current="page">{{ $t('Supplier.ListOfSupplier') }}</li>
                        </ol>
                    </nav>
                </div>
                <div class=" col-sm-6 col-md-6 col-lg-6">
                    <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'" v-if="isValid('CanImportSupplier') && ((rowCount < limitedSupplier) || (limitedSupplier == 0))">
                        <a href="javascript:void(0)" class="btn btn-outline-primary "  v-on:click="ImportDataFromXlsx">{{$t('Supplier.ImportSupplier')}}</a>
                    </div>

                </div>
            </div>
            <div class="card ">
                <div class="card-header " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                    <div class="row mb-3">
                        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label>{{ $t('Supplier.SearchbyBrand') }}</label>
                                <div>
                                    <input type="text" class="form-control search_input" v-model="search" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" name="search" id="search" :placeholder="$t('Supplier.Search')" />
                                    <span class="fas fa-search search_icon"></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-8 col-sm-6 col-12 " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left' ">
                            <a href="javascript:void(0)" class="btn btn-outline-primary " v-if="isValid('CanAddSupplier') && ((rowCount < limitedSupplier) || (limitedSupplier == 0))" v-on:click="AddSupplier"><i class="fa fa-plus"></i> {{ $t('Supplier.AddNew') }}</a>
                            <router-link :to="'/StartScreen'"><a href="javascript:void(0)" class="btn btn-outline-danger "> {{ $t('Supplier.Close') }}</a></router-link>

                        </div>



                    </div>
                </div>
            </div>
            <div class="card">
                <div class="mt-2">
                    <div class="col-lg-12">
                        <div  v-if="isRaw=='true'">
                          
                                <ul class="nav nav-tabs" data-tabs="tabs">
                                    <li class="nav-item"><a class="nav-link" v-bind:class="{active:active == false}" v-on:click="makeActive(false)" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">{{ $t('Supplier.Supplier') }}</a></li>
                                    <li class="nav-item"><a class="nav-link" v-bind:class="{active:active == true}" v-on:click="makeActive(true)" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">{{ $t('Supplier.RawSupplier') }}</a></li>

                                </ul>
                         
                        </div>
                        <div class="tab-content mt-3" id="nav-tabContent" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                            <div v-if="active == false">
                               
                                <div class=" table-responsive">
                                    <table class="table table-hover table-striped table table_list_bg">
                                        <thead class="m-0">
                                            <tr>
                                                <th>#</th>
                                                <th>{{ $t('Supplier.Code') }} </th>
                                                <th v-if="english=='true'">{{ $t('Supplier.SupplierName') |englishLanguage}} </th>
                                                <th v-if="isOtherLang()">{{ $t('Supplier.SupplierName') |arabicLanguage}} </th>
                                                <th>{{ $t('Supplier.RegistrationDate') }}</th>
                                                <th>{{ $t('Supplier.SupplierCategory') }}</th>
                                                <th>{{ $t('Supplier.SupplierType') }}</th>
                                                <th class="text-center">{{ $t('Supplier.RunningBalance') }}</th>
                                                <th>{{ $t('Supplier.PaymentType') }}</th>
                                                <th>{{ $t('Supplier.Active') }}</th>

                                                <!--<th>{{ $t('Supplier.Action') }}</th>-->
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(supplier,index) in supplierlist" v-bind:key="supplier.id">
                                                <td>
                                                    {{index+1}}
                                                </td>
                                                <td v-if="isValid('CanEditSupplier')">
                                                    <strong>
                                                        <a href="javascript:void(0)" v-on:click="EditSupplier(supplier.id)">{{supplier.code}}</a>
                                                    </strong>
                                                </td>
                                                <td v-else> {{supplier.code}}</td>
                                                <td v-if="english=='true'">{{supplier.englishName}}</td>
                                                <td v-if="isOtherLang()">{{supplier.arabicName}}</td>
                                                <td>{{supplier.registrationDate}}</td>
                                                <td>{{supplier.category}}</td>
                                                <td>{{getType(supplier.supplierType)}}</td>
                                                <td class="text-center">{{supplier.openingBalance>=0?'Dr '+ supplier.openingBalance*+1: 'Cr '+ supplier.openingBalance*(-1) }}</td>
                                                <td v-if="supplier.paymentTerms=='Cash'"> {{supplier.paymentTerms}}</td>
                                                <td v-else-if="supplier.paymentTerms=='Credit'|| supplier.paymentTerms=='آجل'">{{supplier.paymentTerms}} <div>Limit({{supplier.creditLimit}}), Periods({{supplier.creditPeriod}})</div></td>
                                                <td v-else> {{supplier.paymentTerms}}</td>
                                                <td> {{supplier.isActive==true?$t('City.Active'):$t('City.De-Active')}}</td>
                                                <!--<td><a href="javascript:void(0)" class="btn btn-danger btn-sm btn-icon " v-on:click="RemoveSupplier(supplier.id)"><i class=" fa fa-trash"></i></a></td>-->
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>


                                <div class="float-left">
                                    <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                                    <span v-else-if="currentPage===1 && rowCount < 10">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                                    <span v-else-if="currentPage===1 && rowCount >= 11  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                    <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                    <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                    <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                </div>
                                <div class="float-right">
                                    <div class="overflow-auto" v-on:click="getPage()" v-if="($i18n.locale == 'en' ||isLeftToRight())">
                                        <b-pagination pills size="lg" v-model="currentPage"
                                                      :total-rows="rowCount"
                                                      :per-page="10"
                                                      first-text="First"
                                                      prev-text="Previous"
                                                      next-text="Next"
                                                      last-text="Last"></b-pagination>
                                    </div>
                                    <div class="overflow-auto" v-on:click="getPage()" v-else>
                                        <b-pagination pills size="lg" v-model="currentPage"
                                                      :total-rows="rowCount"
                                                      :per-page="10"
                                                      first-text="الأولى"
                                                      prev-text="السابقة"
                                                      next-text="التالية"
                                                      last-text="الأخيرة"></b-pagination>
                                    </div>
                                </div>
                            </div>
                            <div v-if="active == true">
                               
                                <div class=" table-responsive">
                                    <table class="table table-striped table-hover table_list_bg" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                        <thead class="m-0">
                                            <tr>
                                                <th>#</th>
                                                <th>{{ $t('Supplier.Code') }} </th>
                                                <th v-if="english=='true'">{{ $t('Supplier.SupplierName') |englishLanguage}} </th>
                                                <th v-if="isOtherLang()">{{ $t('Supplier.SupplierName') |arabicLanguage}} </th>
                                                <th>{{ $t('Supplier.RegistrationDate') }}</th>
                                                <th>{{ $t('Supplier.SupplierCategory') }}</th>
                                                <th>{{ $t('Supplier.SupplierType') }}</th>
                                                <th>{{ $t('Supplier.PaymentType') }}</th>
                                                <th>{{ $t('Supplier.Active') }}</th>

                                                <!--<th>{{ $t('Supplier.Action') }}</th>-->
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(supplier,index) in supplierlist" v-bind:key="supplier.id">
                                                <td>
                                                    {{index+1}}
                                                </td>
                                                <td v-if="isValid('Can Edit Supplier')">
                                                    <strong>
                                                        <a href="javascript:void(0)" v-on:click="EditSupplier(supplier.id)" style="color:red">{{supplier.code}}</a>
                                                    </strong>
                                                </td>
                                                <td v-else> {{supplier.code}}</td>
                                                <td v-if="english=='true'">{{supplier.englishName}} <label style="color:red">{{ $t('RawMaterial') }}</label></td>
                                                <td v-if="isOtherLang()">{{supplier.arabicName}} <label style="color:red">{{ $t('RawMaterial') }}</label></td>
                                                <td>{{supplier.registrationDate}}</td>
                                                <td>{{supplier.category}}</td>
                                                <td>{{getType(supplier.supplierType)}}</td>
                                                <td v-if="supplier.paymentTerms=='Cash'"> {{supplier.paymentTerms}}</td>
                                                <td v-else-if="supplier.paymentTerms=='Credit'">{{supplier.paymentTerms}} <div>Limit({{supplier.creditLimit}}), Periods({{supplier.creditPeriod}})</div></td>
                                                <td v-else> {{supplier.paymentTerms}}</td>
                                                <td>{{supplier.isActive==true?'Active':'InActive'}}</td>
                                                <!--<td><a href="javascript:void(0)" class="btn btn-danger btn-sm btn-icon " v-on:click="RemoveSupplier(supplier.id)"><i class=" fa fa-trash"></i></a></td>-->
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="float-left">
                                    <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                                    <span v-else-if="currentPage===1 && rowCount < 10">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                                    <span v-else-if="currentPage===1 && rowCount >= 11  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                    <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                    <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                    <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                </div>
                                <div class="float-right">
                                    <div class="overflow-auto" v-on:click="getPage()">
                                        <b-pagination pills size="lg" v-model="currentPage"
                                                      :total-rows="rowCount"
                                                      :per-page="10"
                                                      first-text="First"
                                                      prev-text="Previous"
                                                      next-text="Next"
                                                      last-text="Last"></b-pagination>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        name: 'supplier',
        mixins: [clickMixin],
        data: function () {
            return {
                isRaw: '',
                supplierlist: [],
                supplier: [],
                loading: true,
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                search: '',
                arabic: '',
                english: '',
                active: false,
                language: '',
                limitedSupplier:0
            }
        },
        watch: {
            search: function (val) {
                this.GetSupplierData(val, 1, this.active);
            }
        },
        methods: {
            ImportDataFromXlsx: function () {
                var root = this;
                root.$router.push({
                    path: '/ImportExportRecords',
                    query: { data: 'Supplier' }
                })
            },
            getType: function (x) {

                this.language = this.$i18n.locale;

                if (this.language == 'en') {
                    if (x == 1) {
                        return 'Wholesaler';
                    }
                    else if (x == 2) {
                        return 'Retailer';
                    }
                    else if (x == 5) {
                        return 'Wholesaler & Retailer';
                    }
                    else if (x == 3) {
                        return 'Dealer';
                    }
                    else if (x == 4) {
                        return 'Distributor';
                    }
                    else {
                        return '';
                    }
                }
                else {
                    if (x == 1) {
                        return 'جمله';
                    }
                    else if (x == 2) {
                        return 'قطاعي';
                    }
                    else if (x == 5) {
                        return 'بائع بالجملة';
                    }
                    else if (x == 3) {
                        return 'وكيل';
                    }
                    else if (x == 4) {
                        return 'موزع';
                    }
                    else {
                        return '';
                    }
                }

            },
            AddSupplier: function () {
                this.$router.push('/addsupplier')
            },
            makeActive: function (item) {

                this.active = item;
                this.GetSupplierData(this.search, 1, item);
            },
            getPage: function () {
                this.GetSupplierData(this.search, this.currentPage, this.active);
            },
            EditSupplier: function (Id) {

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.get('/Contact/ContactDetail?Id=' + Id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {

                        root.supplier = response.data
                    }
                    root.$router.push({
                        path: '/addsupplier',
                        query: {
                            data: root.supplier
                        }
                    })
                });
            },

            GetSupplierData: function (search, currentPage, status) {

                var root = this;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.get('/Contact/ContactList?isCustomer=false' + '&searchTerm=' + search + '&pageNumber=' + currentPage + '&status=' + status, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {



                        root.supplierlist = response.data.results;
                        root.pageCount = response.data.pageCount;
                        root.rowCount = response.data.rowCount;
                    }
                });
            },
            RemoveSupplier: function (id) {
                var root = this;
                // working with IE and Chrome both

                this.$swal({
                    icon: 'error',
                    title: "Are you sure?",
                    text: "You will not be able to recover this!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes, delete it!",
                    closeOnConfirm: false,
                    closeOnCancel: true
                }).then(function (result) {
                    if (result) {

                        var token = '';
                        if (root.$session.exists()) {
                            token = localStorage.getItem('token');
                        }
                        root.$https
                            .get('/Contact/ContactDelete?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } })
                            .then(function (response) {

                                if (response.data.id != '00000000-0000-0000-0000-000000000000') {
                                    root.$store.state.supplierlist.splice(root.$store.state.supplierlist.findIndex(function (i) {
                                        return i.id === response.data;
                                    }), 1);
                                    root.$swal({
                                        title: 'Deleted!',
                                        text: response.data.isAddUpdate,
                                        type: 'success',
                                        confirmButtonClass: "btn btn-success",
                                        buttonsStyling: false
                                    });
                                } else {
                                    root.$swal({
                                        title: "Error!",
                                        text: response.data.isAddUpdate,
                                        type: 'error',
                                        confirmButtonClass: "btn btn-danger",
                                        buttonsStyling: false
                                    });
                                }
                            },
                                function () {
                                    root.loading = false;
                                    root.$swal({
                                        title: "Error!",
                                        text: "Something Went Wrong",
                                        type: 'error',
                                        confirmButtonClass: "btn btn-danger",
                                        buttonsStyling: false
                                    });
                                });
                    }
                    else {
                        this.$swal('Cancelled', 'Your file is still intact', 'info');
                    }
                });
            },
        },
        created: function () {
            
            this.$emit('input', this.$route.name);
        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.isRaw = localStorage.getItem('IsProduction');
            this.limitedSupplier = localStorage.getItem('LimitedSupplier');
            if (isNaN(this.limitedSupplier)) {
                this.limitedSupplier = 0
            }
            this.makeActive(false);
        }
    }
</script>