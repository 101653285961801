<template>
    <div class="row" v-if="isValid('CanViewPayRollSchedule')">
        <div class="col-lg-12 col-sm-12 ml-auto mr-auto" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">

            <div class="card">
                <div class="BorderBottom ml-2 mr-2 mt-3 mb-3">
                    <span class=" DayHeading">{{ $t('PayrollSchedule.PayrollSchedule') }}</span>
                </div>
                <div class="card-body">
                    <div class="row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label>{{ $t('PayrollSchedule.SearchByName') }}</label>
                                <div>
                                    <input type="text" class="form-control search_input" v-model="search" name="search" id="search" :placeholder="$t('PayrollSchedule.Search')" />
                                    <span class="fas fa-search search_icon"></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                            <a v-if="isValid('CanAddPayRollSchedule')" href="javascript:void(0)" class="btn btn-primary " style="margin-top:27px;" v-on:click="openmodel"><i class="fa fa-plus"></i>{{ $t('PayrollSchedule.AddPayrollSchedule') }}  </a>
                            <router-link :to="'/HrSetup'">
                                <a href="javascript:void(0)" class="btn btn-outline-danger " style="margin-top:27px;">  <i class="fas fa-arrow-circle-left fa-lg"></i> </a>
                            </router-link>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                            <table class="table table-striped table-hover table_list_bg" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <thead v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                    <tr>
                                        <th>#</th>
                                        <th>
                                            {{ $t('PayrollSchedule.Name') }}
                                        </th>
                                        <th>
                                            {{ $t('PayrollSchedule.PayPeriod') }}
                                        </th>
                                        <th>
                                            {{ $t('PayrollSchedule.PeriodFirstDate') }}
                                        </th>
                                        <th>
                                            {{ $t('PayrollSchedule.PeriodLastDate') }}
                                        </th>
                                        <th>
                                            {{ $t('PayrollSchedule.PayDate') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                    <tr v-for="(schedule ,index) in payrollscheduleList" v-bind:key="schedule.id">
                                        <td v-if="currentPage === 1">
                                            {{index+1}}
                                        </td>
                                        <td v-else>
                                            {{((currentPage*10)-10) +(index+1)}}
                                        </td>

                                        <td v-if="isValid('CanEditPayRollSchedule')">
                                            <strong>
                                                <a href="javascript:void(0)" v-on:click="EditDeduction(schedule.id)">{{schedule.name}}</a>
                                            </strong>
                                        </td>
                                        <td v-else>
                                            <strong>
                                                {{schedule.name}}
                                            </strong>
                                        </td>
                                        <td>
                                            {{schedule.payPeriod}}
                                        </td>
                                        <td>
                                            {{getDate(schedule.payPeriodStartDate) }}
                                        </td>
                                        <td>
                                            {{getDate(schedule.payPeriodEndDate) }}
                                        </td>
                                        <td>
                                            {{getDate(schedule.payDate) }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                    <div class="float-left">
                        <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                        <span v-else-if="currentPage===1 && rowCount < 10">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage===1 && rowCount >= 11  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                    </div>
                    <div class="float-right">
                        <div class="overflow-auto" v-on:click="GetBrandData()">
                            <b-pagination pills size="lg" v-model="currentPage"
                                          :total-rows="rowCount"
                                          :per-page="10"
                                          first-text="First"
                                          prev-text="Previous"
                                          next-text="Next"
                                          last-text="Last"></b-pagination>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <add-payroll-schedule :payrollschedule="newPayrollSchedule"
                       :show="show"
                       v-if="show"
                       @close="IsSave"
                       :type="type" />

    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>


<script>
    import clickMixin from '@/Mixins/clickMixin'
    import moment from "moment";
    export default {
        mixins: [clickMixin],
        data: function () {
            return {
                currency: '',
                searchQuery: '',
                show: false,
                payrollscheduleList: [],
                newPayrollSchedule: {
                    id: '',
                    payPeriod: '',
                    payPeriodStartDate: '',
                    name: '',
                    payPeriodEndDate: '',
                    ifPayDayFallOnHoliday: '',
                    payDate: '',
                    default: false,
                    isHourlyPay: false
                },
                type: '',
                search: '',
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                arabic: '',
                english: '',
            }
        },
        watch: {
            search: function () {
                this.GetDeductionData();
            }
        },
        methods: {
            getDate: function (date) {
                return moment(date).format("l");
            },
            IsSave: function () {
                this.show = false;
                this.GetDeductionData();
            },

            getPage: function () {
                this.GetDeductionData();
            },

            openmodel: function () {
                this.newPayrollSchedule = {
                    id: '00000000-0000-0000-0000-000000000000',
                    payPeriod: '',
                    payPeriodStartDate: '',
                    name: '',
                    payPeriodEndDate: '',
                    ifPayDayFallOnHoliday: '',
                    payDate: '',
                    default: false,
                    isHourlyPay: false
                }
                this.show = !this.show;
                this.type = "Add";
            },

            GetDeductionData: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                root.$https.get('Payroll/PayrollScheduleList?searchTerm=' + this.search + '&pageNumber=' + this.currentPage, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.payrollscheduleList = response.data.results;
                        root.pageCount = response.data.pageCount;
                        root.rowCount = response.data.rowCount;
                        root.loading = false;
                    }
                    root.loading = false;
                });
            },

            EditDeduction: function (Id) {

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Payroll/PayrollScheduleDetail?Id=' + Id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data) {
                            root.newPayrollSchedule.id = response.data.id;
                            root.newPayrollSchedule.payPeriod = response.data.payPeriod;
                            root.newPayrollSchedule.payPeriodStartDate = response.data.payPeriodStartDate;
                            root.newPayrollSchedule.name = response.data.name;
                            root.newPayrollSchedule.payPeriodEndDate = response.data.payPeriodEndDate;
                            root.newPayrollSchedule.ifPayDayFallOnHoliday = response.data.ifPayDayFallOnHoliday;
                            root.newPayrollSchedule.payDate = response.data.payDate;
                            root.newPayrollSchedule.default = response.data.default;
                            root.newPayrollSchedule.isHourlyPay = response.data.isHourlyPay;                            
                            
                            root.show = !root.show;
                            root.type = "Edit"
                        } else {
                            console.log("error: something wrong from db.");
                        }
                    },
                        function (error) {
                            this.loading = false;
                            console.log(error);
                        });

            }
        },

        created: function () {
            this.$emit('input', this.$route.name);
        },

        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.currency = localStorage.getItem('currency');
            this.GetDeductionData();

        }
    }
</script>