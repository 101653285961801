<template>
    <div class="row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-if="isValid('CanViewAllowanceType')">
        <div class="col-lg-12 col-sm-12 ml-auto mr-auto">
            <div class="card ">
                <div class="BorderBottom ml-4 mr-4 mt-3 mb-3">
                    <span class=" DayHeading">{{ $t('AllowanceType.AllowanceType') }}</span>
                </div>


                <div class="card-body">
                    <div class="col-lg-12">
                        <div class="row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                <div class="form-group">
                                    <label>{{ $t('AllowanceType.SearchByName') }}</label>
                                    <div>
                                        <input type="text" class="form-control search_input" v-model="searchQuery" name="search" id="search" :placeholder="$t('AllowanceType.Search')" />
                                        <span class="fas fa-search search_icon"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'" style="margin-top:18px;">
                                <a href="javascript:void(0)" class="btn btn-primary" v-on:click="openmodel" v-if="isValid('CanAddAllowanceType')"><i class="fa fa-plus"></i>  {{ $t('AllowanceType.AddNew') }}</a>
                                <router-link :to="'/GeographicalSetup'">
                                    <a href="javascript:void(0)" class="btn btn-outline-danger ">  {{ $t('AllowanceType.Close') }} </a>
                                </router-link>
                            </div>

                        </div>

                        <div class="mt-2">
                            <div>
                                <table class="table table-shopping" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <thead class="">
                                        <tr>
                                            <th>#</th>

                                            <th v-if="english=='true'" class="text-center">
                                                {{ $t('AllowanceType.NameEnglish')}}
                                            </th>
                                            <th v-if="isOtherLang()" class="text-center">
                                                {{ $t('AllowanceType.NameArabic')}}
                                            </th>
                                            <th class="text-center" width="40%">
                                                {{ $t('AllowanceType.Description')}}
                                            </th>
                                            <th>
                                                {{ $t('AllowanceType.Status')}}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(allowanceType,index) in resultQuery" v-bind:key="allowanceType.id">
                                            <td>
                                                {{index+1}}
                                            </td>

                                            <td class="text-center" v-if="isValid('CanEditAllowanceType')">
                                                <strong>
                                                    <a href="javascript:void(0)" v-on:click="EditAllowanceType(allowanceType.id)">  {{allowanceType.name}}</a>
                                                </strong>
                                            </td>
                                            <td class="text-center" v-else>
                                                <strong>
                                                    {{allowanceType.name}}
                                                </strong>
                                            </td>
                                            <td class="text-center" v-if="isValid('CanEditAllowanceType')">
                                                <strong>
                                                    <a href="javascript:void(0)" v-on:click="EditAllowanceType(allowanceType.id)">  {{allowanceType.nameArabic}}</a>
                                                </strong>
                                            </td>
                                            <td class="text-center" v-else>
                                                <strong>
                                                    {{allowanceType.nameArabic}}
                                                </strong>
                                            </td>

                                            <td class="text-center">
                                                {{allowanceType.description}}
                                            </td>


                                            <td>{{allowanceType.isActive==true?$t('Active'):$t('De-Active')}}</td>



                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <AddAllowanceType :allowanceType="newAllowanceType"
                          :show="show"
                          v-if="show"
                          @close="close"
                          :type="type" />

    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>

<script>
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        data: function () {
            return {
                arabic: '',
                english: '',
                searchQuery: '',
                show: false,
                allowanceTypelist: [],
                newAllowanceType: {
                    id: '00000000-0000-0000-0000-000000000000',
                    name: '',
                    nameArabic: '',
                    description: '',
                    isActive: true
                },
                type: '',
            }
        },
        computed: {
            resultQuery: function () {
                var root = this;
                if (this.searchQuery) {
                    return root.allowanceTypelist.filter((allowanceType) => {

                        return root.searchQuery.toLowerCase().split(' ').every(v => allowanceType.name.toLowerCase().includes(v) || allowanceType.nameArabic.toLowerCase().includes(v) )
                    })
                } else {
                    return root.allowanceTypelist;
                }
            },
        },        methods: {
            close: function () {
                
                this.show = false;
                this.GetAllowanceTypeData();
            },
            openmodel: function () {
                this.newAllowanceType = {
                    id: '00000000-0000-0000-0000-000000000000',
                    name: '',
                    nameArabic: '',
                    description: '',
                    isActive: true
                }
                this.show = !this.show;
                this.type = "Add";
            },
            GetAllowanceTypeData: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('Payroll/AllowanceTypeList?isDropdown=false', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.$store.dispatch('GetAllowanceTypeList', response.data.allowanceTypes);
                        root.allowanceTypelist = response.data.allowanceTypes;
                    }
                });
            },

            EditAllowanceType: function (Id) {


                var root = this;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Payroll/AllowanceTypeDetail?Id=' + Id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data) {
                            
                            root.newAllowanceType.id = response.data.id;
                            root.newAllowanceType.description = response.data.description;
                            root.newAllowanceType.name = response.data.name;
                            root.newAllowanceType.nameArabic = response.data.nameArabic;
                            root.newAllowanceType.isActive = response.data.isActive;
                            root.show = !root.show;
                            root.type = "Edit"
                        } else {
                            console.log("error: something wrong from db.");
                        }
                    },
                        function (error) {
                            this.loading = false;
                            console.log(error);
                        });

            }
        },
        created: function () {
            this.$emit('input', this.$route.name);
        },
        mounted: function () {
            this.currency = localStorage.getItem('currency');

            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.GetAllowanceTypeData();
        }
    }
</script>