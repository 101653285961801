<template>

    <div class="row" v-if="isValid('Can View   Mobile Order')">
        <div class="col-lg-12 col-sm-12 ml-auto mr-auto">
            <div class="card ">
                <div class="card-header">
                    <h4 class="card-title DayHeading">{{ $t('MobileOrder.MobileOrder') }}</h4>
                    <div class="row">
                        <div class="col-md-5 col-lg-5">
                            <div class="form-group">
                                <input type="text" class="form-control" v-model="search" name="search" id="search" placeholder="Search" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">

                            <div class="row">
                                <div class="col-md-2 " v-if="isValid('Can Save  Mobile Order')">
                                    <a href="javascript:void(0)" class="btn btn-outline-primary " v-on:click="AddMobileOrder"><i class="fa fa-plus"></i> {{ $t('MobileOrder.AddNew') }}</a>
                                </div>
                            </div>

                            <div class="mt-2 table-responsive">
                                <table class="table table-striped table-hover table_list_bg">
                                    <thead class="">
                                        <tr>
                                            <th>#</th>
                                            <th>
                                                {{ $t('MobileOrder.PONumber') }}
                                            </th>
                                            <th>
                                                {{ $t('MobileOrder.CreatedDate') }}
                                            </th>
                                            <th>
                                                {{ $t('MobileOrder.CustomerName') }}
                                            </th>
                                            <th>
                                                {{ $t('MobileOrder.NetAmount') }}
                                            </th>
                                            <!--<th>
                                                {{ $t('PurchaseOrder.Action') }}
                                            </th>-->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(mobileOrder,index) in mobileOrderList" v-bind:key="mobileOrder.id">
                                            <td v-if="currentPage === 1">
                                                {{index+1}}
                                            </td>
                                            <td v-else>
                                                {{((currentPage*10)-10) +(index+1)}}
                                            </td>
                                            <!--<td v-if="isValid('Can Edit  Mobile Order')">
                                                <strong>
                                                    <a href="javascript:void(0)" v-on:click="EditMobileOrder(mobileOrder.id)">{{mobileOrder.orderNo}}</a>
                                                </strong>

                                            </td>-->
                                            <td >
                                                {{mobileOrder.orderNo}}
                                            </td>
                                            <td>
                                                {{mobileOrder.orderDate}}
                                            </td>
                                            <td>
                                                {{mobileOrder.customerName}}
                                            </td>
                                            <td>
                                                {{currency}} {{parseFloat(mobileOrder.netAmount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                            </td>
                                            <!--<td>

                                                <a href="javascript:void(0)" class="btn    btn-primary " v-on:click="ConvertToInvoice(mobileOrder.id)">{{ $t('PurchaseOrder.Invoice') }}</a>
                                            </td>-->
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="float-left">
                                <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                                <span v-else-if="currentPage===1 && rowCount < 10">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage===1 && rowCount >= 11  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                            </div>
                            <div class="float-right">
                                <div class="overflow-auto" v-on:click="getPage()">
                                    <b-pagination pills size="lg" v-model="currentPage"
                                                  :total-rows="rowCount"
                                                  :per-page="10"
                                                  first-text="First"
                                                  prev-text="Previous"
                                                  next-text="Next"
                                                  last-text="Last"></b-pagination>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>

<script>
    import clickMixin from '@/Mixins/clickMixin'

    export default {
        mixins: [clickMixin],
        data: function () {
            return {

                search: '',
                mobileOrderList: [],
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                UserName: '',
                currency: '',
                CompanyID: '',
                UserID: '',


            }
        },
        watch: {
            search: function (val) {
                this.getData(val, 1);
            }
        },
        methods: {
            DeleteFile: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.get('/Purchase/DeletePo', this.selected, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {
                            if (response.data.message.id != '00000000-0000-0000-0000-000000000000') {
                                root.$swal({
                                    title: 'Deleted!',
                                    text: response.data.message.isAddUpdate,
                                    type: 'success',
                                    confirmButtonClass: "btn btn-success",
                                    buttonsStyling: false
                                }).then(function (result) {
                                    if (result) {
                                        root.$router.push('/mobile');
                                    }
                                });
                            } else {
                                root.$swal({
                                    title: "Error!",
                                    text: response.data.message.isAddUpdate,
                                    type: 'error',
                                    confirmButtonClass: "btn btn-danger",
                                    buttonsStyling: false
                                });
                            }
                        }
                    },
                        function () {

                            root.$swal({
                                title: "Error!",
                                text: "Update UnSuccessfully",
                                type: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                        });
            },
            getPage: function () {
                this.getData(this.search, this.currentPage, this.active);
            },

            getData: function (search, currentPage, status) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                this.$https.get('/Purchase/MobileOrderList?status=' + status + '&searchTerm=' + search + '&pageNumber=' + currentPage + '&CustomerId=' + this.UserID, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {

                        root.$store.dispatch('GetMobileOrderList', response.data.results);
                        root.mobileOrderList = response.data.results;
                        root.pageCount = response.data.pageCount;
                        root.rowCount = response.data.rowCount;

                    },
                        function () {

                            root.$swal({
                                title: "Error!",
                                text: "Something went wrong",
                                type: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                        });
            },
            ConvertToInvoice: function (id) {


                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https.get('/Purchase/MobileOrderDetail?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {

                            root.$router.push({
                                path: '/addSale',
                                query: { mobiledata: response.data }
                            })
                        }
                    }
                    );

            },
            AddMobileOrder: function () {

                this.$router.push('/addmobileorder');
            },
            EditMobileOrder: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Purchase/MobileOrderDetail?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {
                            root.$router.push({
                                path: '/addmobileorder',
                                query: { data: response.data }
                            })
                        }
                    }
                    );
            }
        },
        mounted: function () {
            if (this.$session.exists()) {
                this.UserName = localStorage.getItem('UserName');
                this.CompanyID = localStorage.getItem('CompanyID');
                this.UserID = localStorage.getItem('UserID');
            }
            this.getData(this.search, 1);
            this.currency = localStorage.getItem('currency');


        },
    }
</script>