<template>
    <modal :show="show" v-if="isValid('CanAddPosTerminal') || isValid('CanEditPosTerminal') ">

        <div style="margin-bottom:0px" class="card" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <div class="card-body">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="modal-header" v-if="type=='Edit'">

                            <h5 class="modal-title DayHeading" id="myModalLabel">{{ $t('AddBankPosTerminal.UpdateBankPosTerminal') }} </h5>

                        </div>
                        <div class="modal-header" v-else>

                            <h5 class="modal-title DayHeading" id="myModalLabel">{{ $t('AddBankPosTerminal.AddBankPosTerminal') }} </h5>

                        </div>
                        <div class="">
                            <div class="card-body">
                                <div class="row ">

                                    <div class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.bankPosTerminal.terminalId.$error} && ($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                        <label class="text  font-weight-bolder"> {{ $t('AddBankPosTerminal.TerminalId') }}: <span class="LabelColour"> *</span></label>
                                        <input class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.bankPosTerminal.terminalId.$model" type="text" />
                                        <span v-if="$v.bankPosTerminal.terminalId.$error" class="error">
                                            <span v-if="!$v.bankPosTerminal.terminalId.required">{{ $t('AddBankPosTerminal.NameRequired') }}</span>
                                            <span v-if="!$v.bankPosTerminal.terminalId.maxLength">{{ $t('AddBankPosTerminal.NameLength') }}</span>
                                        </span>
                                    </div>
                                    <div class="form-group has-label col-sm-12 ">
                                        <label class="text  font-weight-bolder">   {{$t('AddBankPosTerminal.BankAccount')}}:<span class="LabelColour"> *</span> </label>
                                        <accountdropdown v-model="bankPosTerminal.bankId" :formName="bank"></accountdropdown>

                                    </div>



                                    <div class="form-group col-md-12">
                                        <label style="margin: 7px;">{{ $t('AddBankPosTerminal.Active') }}</label> <br />
                                        <div class="bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-animate" v-bind:class="{'bootstrap-switch-on': bankPosTerminal.isActive, 'bootstrap-switch-off': !bankPosTerminal.isActive}" v-on:click="bankPosTerminal.isActive = !bankPosTerminal.isActive" style="width: 72px;">
                                            <div class="bootstrap-switch-container" style="width: 122px; margin-left: 0px;">
                                                <span class="bootstrap-switch-handle-on bootstrap-switch-success" style="width: 50px;">
                                                    <i class="nc-icon nc-check-2"></i>
                                                </span>
                                                <span class="bootstrap-switch-label" style="width: 30px;">&nbsp;</span>
                                                <span class="bootstrap-switch-handle-off bootstrap-switch-success" style="width: 50px;">
                                                    <i class="nc-icon nc-simple-remove"></i>
                                                </span>
                                                <input class="bootstrap-switch" type="checkbox" data-toggle="switch" checked="" data-on-label="<i class='nc-icon nc-check-2'></i>" data-off-label="<i class='nc-icon nc-simple-remove'></i>" data-on-bankPosTerminal="success" data-off-bankPosTerminal="success">
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div class="modal-footer justify-content-right" v-if="type=='Edit'">

                            <button type="button" class="btn btn-primary  " v-if="isValid('CanEditPosTerminal') " v-on:click="SaveBankPosTerminal" v-bind:disabled="$v.bankPosTerminal.$invalid"> {{ $t('AddBankPosTerminal.btnUpdate') }}</button>
                            <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{ $t('AddBankPosTerminal.btnClear') }}</button>

                        </div>
                        <div class="modal-footer justify-content-right" v-if="type!='Edit'">

                            <button type="button" class="btn btn-primary  " v-if="isValid('CanAddPosTerminal')" v-on:click="SaveBankPosTerminal" v-bind:disabled="$v.bankPosTerminal.$invalid"> {{ $t('AddBankPosTerminal.btnSave') }}</button>
                            <button type="button" class="btn btn-outline-danger  mr-3 " v-on:click="close()">{{ $t('AddBankPosTerminal.btnClear') }}</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </modal>
    <acessdenied v-else :model=true></acessdenied>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import 'vue-loading-overlay/dist/vue-loading.css';

    import { required, maxLength } from "vuelidate/lib/validators"
    export default {
        props: ['show', 'bankPosTerminal', 'type'],
        mixins: [clickMixin],
        data: function () {
            return {
                render: 0,
                bank: 'BankReceipt',

            }
        },
        validations: {
            bankPosTerminal: {
                terminalId: {
                    required,
                    maxLength: maxLength(50)
                },
                bankId: {
                    required,
                },

            }
        },
        methods: {
            close: function () {
                this.$emit('close');
            },

            SaveBankPosTerminal: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.post('/Company/SaveBankPosTerminal', this.bankPosTerminal, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data.isSuccess == true) {

                        if (root.type != "Edit") {

                            root.$swal({
                                title: "Saved!",
                                text: "Saved Successfully!",
                                type: 'success',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1500,
                                timerProgressBar: true,
                            });
                            root.close();
                            root.$router.go();

                        }
                        else {

                            root.$swal({
                                title: "Saved!",
                                text: "Update Sucessfully!",
                                type: 'success',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1500,
                                timerProgressBar: true,
                            });
                            root.close();
                            root.$router.go();

                        }
                    }
                    else {
                        root.$swal({
                            title: "Error!",
                            text: "Your BankPosTerminal Name  Already Exist!",
                            type: 'error',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 1500,
                            timerProgressBar: true,
                        });
                    }
                });
            }
        },
        mounted: function () {


        }
    }
</script>
