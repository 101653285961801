<template>
    <modal :show="show" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" >

        <div style="margin-bottom:0px" class="card">
            <div class="card-body">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="modal-header" v-if="type=='Edit'">

                            <h5 class="modal-title DayHeading" id="myModalLabel">{{ $t('AddPriceRecord.UpdatePriceRecord') }}</h5>

                        </div>
                        <div class="modal-header" v-else>

                            <h5 class="modal-title DayHeading" id="myModalLabel">{{ $t('AddPriceRecord.AddPriceRecord') }} </h5>

                        </div>

                        <div class="card-body">
                            <div class="row ">

                                <h6>{{priceRecord.product.englishName}}</h6>

                                <div class="form-group has-label col-sm-12 " >
                                    <label class="text  font-weight-bolder">{{ $t('AddPriceRecord.PriceLabeling') }} : <span class="LabelColour"> *</span></label>
                                    <priceLabelingDropdown v-model="priceLabeling" v-on:input="GetPrice(priceLabeling)" :values="priceRecord.priceLabelingId"   ></priceLabelingDropdown>

                                </div>
                                <div class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.priceRecord.price.$error}" :key="randerPrice">
                                    <label class="text  font-weight-bolder"> {{ $t('AddPriceRecord.Price') }}: <span class="LabelColour"> *</span></label>
                                    <input class="form-control " v-model="$v.priceRecord.price.$model" type="number" @click="$event.target.select()" />
                                </div>



                                <div class="form-group col-md-12">
                                    <label style="margin: 7px;"> {{ $t('AddPriceRecord.Status') }}</label> <br />
                                    <div class="bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-animate" v-bind:class="{'bootstrap-switch-on': priceRecord.isActive, 'bootstrap-switch-off': !priceRecord.isActive}" v-on:click="priceRecord.isActive = !priceRecord.isActive" style="width: 72px;">
                                        <div class="bootstrap-switch-container" style="width: 122px; margin-left: 0px;">
                                            <span class="bootstrap-switch-handle-on bootstrap-switch-success" style="width: 50px;">
                                                <i class="nc-icon nc-check-2"></i>
                                            </span>
                                            <span class="bootstrap-switch-label" style="width: 30px;">&nbsp;</span>
                                            <span class="bootstrap-switch-handle-off bootstrap-switch-success" style="width: 50px;">
                                                <i class="nc-icon nc-simple-remove"></i>
                                            </span>
                                            <input class="bootstrap-switch" type="checkbox" data-toggle="switch" checked="" data-on-label="<i class='nc-icon nc-check-2'></i>" data-off-label="<i class='nc-icon nc-simple-remove'></i>" data-on-color="success" data-off-color="success">
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div v-if="!loading">
                            <div class="modal-footer justify-content-right" v-if="type=='Edit'">
                                <button type="button" class="btn btn-primary  " v-on:click="SavePriceRecord" v-bind:disabled="$v.priceRecord.$invalid">  {{ $t('AddPriceRecord.Update') }} </button>
                                <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()"> {{ $t('AddPriceRecord.Cancel') }}</button>
                            </div>
                            <div class="modal-footer justify-content-right" v-if="type!='Edit'">
                                <button type="button" class="btn btn-primary  " v-on:click="SavePriceRecord" v-bind:disabled="$v.priceRecord.$invalid">  {{ $t('AddPriceRecord.Save') }}</button>
                                <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()"> {{ $t('AddPriceRecord.Cancel') }}</button>
                            </div>
                        </div>
                        <div v-else>
                            <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </modal>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import { required } from "vuelidate/lib/validators"
    export default {
        mixins: [clickMixin],
        props: ['show', 'priceRecord', 'type'],
        components: {
            Loading
        },
        data: function () {
            return {
                render: 0,
                priceLabeling: [],
                randerPrice: 0,
                arabic: '',
                english: '',
                loading: false,
            }
        },
        validations: {
            priceRecord: {
                price: {    
                    required
                },
                priceLabelingId: {
                    required
                },
               
               
               
            }
        },
        methods: {
            close: function () {
                this.$emit('close');
            }
            ,
            GetPrice: function (priceLabeling) {
                
                //this.priceRecord.price = this.$refs.priceLabelingDropdown.GetAmountOfSelected();
                this.priceRecord.price = priceLabeling.price;
                this.priceRecord.priceLabelingId = priceLabeling.id;
                this.randerPrice++;
            },
           
            SavePriceRecord: function () {
                
                var root = this;
                var token = '';
                this.loading = true;
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
               
                    this.priceRecord.productId = this.priceRecord.product.id
                this.$https.post('/Product/SavePriceRecordInformation', this.priceRecord, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                    if (response.data.isSuccess == true) {
                        if (root.type != "Edit") {

                            root.$swal({
                                title: "Saved!",
                                text: "Saved Successfully!",
                                type: 'success',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1500,
                                timerProgressBar: true,
                            });
                            root.close();
                        }
                        else {

                            root.$swal({
                                title: "Update!",
                                text: "Update Successfully!",
                                type: 'success',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1500,
                                timerProgressBar: true,
                            });
                            root.close();
                        }
                    }
                    else {
                        root.$swal({
                            title: "Error!",
                            text: "Your Origin Name  Already Exist!",
                            type: 'error',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 1500,
                            timerProgressBar: true,
                        });
                    }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Something Went Wrong!',
                                text: error.response.data,

                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false
                    })
                    .finally(() => root.loading = false)
            }
        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
           

        }
    }
</script>
