<template>
    <div>
        <div class="col-md-12 " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
            <div class="card">
                <div>
                    <div class="col-lg-12">
                        <div>
                            <div class="card col-lg-12 ml-auto mr-auto " style="background-color: #8383830d">
                                <div class="card-body">
                                    <div class="card-header p-0 ">
                                        <h5>{{ $t('AddLocation.AddLocation') }}</h5>
                                    </div>
                                    <div class="">
                                        <div class="row ">
                                            <div class="col-sm-4">
                                                <label>{{ $t('AddLocation.BusinessName') }} :<span class="LabelColour"> *</span></label>
                                                <div v-bind:class="{'has-danger' : $v.addLocation.nameInEnglish.$error}">
                                                    <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" v-model="$v.addLocation.nameInEnglish.$model" />
                                                    <span v-if="$v.addLocation.nameInEnglish.$error" class="error text-danger">
                                                        <span v-if="!$v.addLocation.nameInEnglish.required"> {{ $t('AddLocation.NameRequired') }}</span>
                                                        <span v-if="!$v.addLocation.nameInEnglish.maxLength"> {{ $t('AddLocation.NameMaximum') }}</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <label>{{ $t('AddLocation.CommercialRegistrationNo') }} :<span class="LabelColour"> *</span></label>
                                                <div v-bind:class="{'has-danger' : $v.addLocation.companyRegNo.$error}">
                                                    <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" v-model="$v.addLocation.companyRegNo.$model" />
                                                    <span v-if="$v.addLocation.companyRegNo.$error" class="error text-danger">
                                                        <span v-if="!$v.addLocation.companyRegNo.maxLength"> {{ $t('AddLocation.ComercialMaximum') }}</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <label for="addLocation.TaxRegisterNo">{{ $t('AddLocation.VATNo') }}   <span class="LabelColour"> *</span></label>
                                                <div v-bind:class="{'has-danger' : $v.addLocation.vatRegistrationNo.$error}">
                                                    <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" v-model="$v.addLocation.vatRegistrationNo.$model" />
                                                    <span v-if="$v.addLocation.vatRegistrationNo.$error" class="error text-danger">
                                                        <span v-if="!$v.addLocation.vatRegistrationNo.required">{{ $t('AddLocation.VatNoRequired') }}</span>
                                                        <span v-if="!$v.addLocation.vatRegistrationNo.maxLength">{{ $t('AddLocation.VatMaximum') }}</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <label for="addLocation.email">{{ $t('AddLocation.LocationEmail') }} : <span class="LabelColour"> *</span></label>
                                                <div v-bind:class="{'has-danger' : $v.addLocation.email.$error,'has-blue':($v.addLocation.email.required && $v.addLocation.email.email)}">
                                                    <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" v-model="$v.addLocation.email.$model" />
                                                    <span v-if="$v.addLocation.email.$error" class="error text-danger">
                                                        <span v-if="!$v.addLocation.email.required">{{ $t('AddLocation.EmailRequired') }} </span>
                                                        <span v-if="!$v.addLocation.email.email">{{ $t('AddLocation.EmailInvalid') }} </span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <label>{{ $t('AddLocation.Website') }}</label>
                                                <div>
                                                    <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" v-model="addLocation.website" />
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <label>{{ $t('AddLocation.PhoneNumber') }}: <span class="LabelColour"> *</span></label>
                                                <div v-bind:class="{'has-danger' : $v.addLocation.phoneNumber.$error}">
                                                    <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" v-model="$v.addLocation.phoneNumber.$model" type="number" />
                                                    <span v-if="$v.addLocation.phoneNumber.$error" class="error text-danger">
                                                        <span v-if="!$v.addLocation.phoneNumber.required">{{ $t('AddLocation.PhoneRequired') }} </span>
                                                        <span v-if="!$v.addLocation.phoneNumber.maxLength">{{ $t('AddLocation.PhoneMaximum') }}</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <label>{{ $t('AddLocation.LandlineNumber') }} : </label>
                                                <div v-bind:class="{'has-danger' : $v.addLocation.landLine.$error}">
                                                    <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" type="number" v-model="$v.addLocation.landLine.$model" />
                                                    <span v-if="$v.addLocation.landLine.$error" class="error text-danger">
                                                        <span v-if="!$v.addLocation.landLine.maxLength">{{ $t('AddLocation.LandLineMaximum') }}</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <label for="addLocation.country">{{ $t('AddLocation.Country') }}: </label>
                                                <div v-bind:class="{'has-danger' : $v.addLocation.countryInEnglish.$error}">
                                                    <country-select class="form-control" v-model="addLocation.countryInEnglish" :country="addLocation.countryInEnglish" topCountry="SA" />
                                                    <span v-if="$v.addLocation.countryInEnglish.$error" class="error text-danger">
                                                        <span v-if="!$v.addLocation.countryInEnglish.maxLength">{{ $t('AddLocation.CountryMaximum') }}</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <label for="addLocation.city">{{ $t('AddLocation.City') }}: </label>
                                                <div v-bind:class="{'has-danger' : $v.addLocation.cityInEnglish.$error}">
                                                    <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" v-model="$v.addLocation.cityInEnglish.$model" />
                                                    <span v-if="$v.addLocation.cityInEnglish.$error" class="error text-danger">
                                                        <span v-if="!$v.addLocation.cityInEnglish.maxLength">{{ $t('AddLocation.CityMaximum') }}</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-sm-8">
                                                <label for="addLocation.address">{{ $t('AddLocation.Address') }}: <span class="LabelColour"> *</span></label>
                                                <div v-bind:class="{'has-danger' : $v.addLocation.addressInEnglish.$error}">
                                                    <textarea class="form-control" v-model="$v.addLocation.addressInEnglish.$model" />
                                                    <span v-if="$v.addLocation.addressInEnglish.$error" class="error text-danger">
                                                        <span v-if="!$v.addLocation.addressInEnglish.required">{{ $t('AddLocation.AddressRequired') }}</span>
                                                        <span v-if="!$v.addLocation.addressInEnglish.maxLength">{{ $t('AddLocation.AddressMaximum') }}</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div v-if="$route.query.data!=undefined" class="card-footer col-md-12 text-right">
                                                <a href="javascript:void(0)" class="btn btn-outline-primary  mr-2" v-on:click="SaveaddLocation"><i class="fa fa-save"></i> {{ $t('AddLocation.Update') }}</a>
                                                <a href="javascript:void(0)" class="btn btn-outline-reset " v-on:click="GoToCompany"><i class="fa fa-refresh"></i>  {{ $t('AddLocation.Cancel') }}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card col-lg-12 ml-auto mr-auto " style="  background-color: #8383830d" v-if="$route.query.data==undefined">
                                <div class="card-body m-2 ">
                                    <div class="">
                                        <div>
                                            <h5 class="card-title DayHeading "> {{ $t('AddLocation.AdminInfo') }}</h5>
                                        </div>
                                        <div class="card-body ">
                                            <div class="row">
                                                <div class="col-sm-3">
                                                    <label for="addLocation.email">{{ $t('AddLocation.Email') }}:<span class="LabelColour"> *</span></label>
                                                    <div v-bind:class="{'has-danger' : $v.addLocation.userEmail.$error,'has-blue':($v.addLocation.userEmail.required && $v.addLocation.userEmail.email)}">
                                                        <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" type="text" v-model="$v.addLocation.userEmail.$model" />
                                                        <span v-if="$v.addLocation.userEmail.$error" class="error text-danger">
                                                            <span v-if="!$v.addLocation.userEmail.required">{{ $t('AddLocation.EmailRequired') }} </span>
                                                            <span v-if="!$v.addLocation.userEmail.email">{{ $t('AddLocation.EmailInvalid') }}</span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-sm-3">
                                                    <label>{{ $t('AddLocation.RegisterUser_UserName') }}:<span class="LabelColour"> *</span></label>
                                                    <div v-bind:class="{'has-danger' : $v.addLocation.firstName.$error}">
                                                        <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" autofocus="autofocus" v-model="$v.addLocation.firstName.$model" />
                                                        <span v-if="$v.addLocation.firstName.$error" class="error text-danger">
                                                            <span v-if="!$v.addLocation.firstName.required">{{ $t('AddLocation.RegisterUser_Error_Required_DisplayName') }}  </span>
                                                            <span v-if="!$v.addLocation.firstName.maxLength">{{ $t('AddLocation.RegisterUser_Error_Length_DisplayName') }}</span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-sm-3">
                                                    <label for="addLocation.password">{{ $t('AddLocation.RegisterUser_Password') }} :<span class="LabelColour"> *</span></label>
                                                    <div v-bind:class="{'has-danger' : $v.addLocation.password.$error,'has-blue':($v.addLocation.password.required && $v.addLocation.password.strongPassword)}">
                                                        <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" type="password" v-model="$v.addLocation.password.$model" />
                                                        <span v-if="$v.addLocation.password.$error" class="error text-danger">
                                                            <span v-if="!$v.addLocation.password.required">{{ $t('AddLocation.RegisterUser_Error_Required_Password') }} </span>
                                                            <span v-if="!$v.addLocation.password.strongPassword">{{ $t('AddLocation.RegisterUser_Error_Format_Password') }}</span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-sm-3">
                                                    <label for="addLocation.confirmPassword">{{ $t('AddLocation.RegisterUser_ConfirmPassword') }}:<span class="LabelColour"> *</span></label>
                                                    <div v-bind:class="{'has-danger' : $v.addLocation.confirmPassword.$error,'has-blue':($v.addLocation.confirmPassword.required && $v.addLocation.confirmPassword.sameAsPassword)}">
                                                        <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" type="password" v-model="$v.addLocation.confirmPassword.$model" />
                                                        <span v-if="$v.addLocation.confirmPassword.$error" class="error text-danger">
                                                            <span v-if="!$v.addLocation.confirmPassword.required">{{ $t('AddLocation.RegisterUser_Error_Required_ConfirmPassword') }}  </span>
                                                            <span v-if="!$v.addLocation.confirmPassword.sameAsPassword">{{ $t('AddLocation.RegisterUser_Error_SameAs_ConfirmPassword') }}</span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div v-if="!loading" class="card-footer col-md-12 text-right">
                                                    <a href="javascript:void(0)" class="btn btn-outline-primary  mr-2" v-on:click="SaveaddLocation" v-bind:disabled="$v.addLocation.$invalid"><i class="fa fa-save"></i> {{ $t('AddLocation.btnSave') }}</a>
                                                    <a href="javascript:void(0)" class="btn btn-outline-danger " v-on:click="BackToList"> {{ $t('AddLocation.btnClear') }}</a>
                                                </div>
                                                <div class="card-footer col-md-3 " v-else>
                                                    <loading :active.sync="loading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="true"></loading>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { required, maxLength, sameAs, email } from 'vuelidate/lib/validators';//sameAs,
    import Loading from 'vue-loading-overlay';
    import Vue from 'vue';
    import vueCountryRegionSelect from 'vue-country-region-select';
    Vue.use(vueCountryRegionSelect);
    export default ({
        components: {
            Loading
        },
        data: function () {
            return {
                active: 'addLocation',
                loading: false,
                country: '',
                addLocation: {
                    id: '',
                    nameInEnglish: '',
                    nameInArabic: '',
                    vatRegistrationNo: '',
                    companyRegNo: '',
                    cityInEnglish: '',
                    cityInArabic: '',
                    countryInEnglish: '',
                    countryInArabic: '',
                    phoneNumber: '',
                    website: '',
                    userEmail: '',
                    email: '',
                    addressInEnglish: '',
                    addressInArabic: '',
                    landLine: '',
                    firstName: '',
                    password: '',
                    confirmPassword: '',
                    clientParentId: '00000000-0000-0000-0000-000000000000',
                    businessParentId: '00000000-0000-0000-0000-000000000000',
                }
            }
        },
        validations: {
            addLocation:
            {
                nameInEnglish:
                {
                    required,
                    maxLength: maxLength(90)
                },
                vatRegistrationNo:
                {
                    required,
                    maxLength: maxLength(50)
                },
                companyRegNo:
                {
                    required,
                    maxLength: maxLength(50)
                },
                cityInEnglish:
                {

                    maxLength: maxLength(70)
                },
                countryInEnglish:
                {

                    maxLength: maxLength(60)
                },
                phoneNumber:
                {
                    required,
                    maxLength: maxLength(20)
                },
                addressInEnglish:
                {
                    required,
                    maxLength: maxLength(140)
                },
                firstName:
                {
                    required,
                    maxLength: maxLength(30)
                },
                password:
                {
                    required,
                    strongPassword(password) {
                        return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(password);
                    }
                },
                confirmPassword:
                {
                    required,
                    sameAsPassword: sameAs('password')
                },
                email:
                {
                    required,
                    email
                },
                userEmail:
                {
                    required,
                    email
                },
                landLine:
                {

                    maxLength: maxLength(15)
                }
            }
        },
        methods: {
            makeActive: function (tab) {
                this.active = tab;
            },
            BackToList: function () {
                if (this.$route.query.clientParentId != undefined) {
                    this.$router.push('/clientManagement');
                } else {
                    this.$router.push('/clientManagement');
                }
            },
            SaveaddLocation: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                if (this.$route.query.clientParentId != undefined) {
                    this.addLocation.clientParentId = this.$route.query.clientParentId;
                    this.addLocation.businessParentId = this.$route.query.busId;
                }
                this.$https
                    .post('/Company/SaveLocation', this.addLocation, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {
                        root.loading = false
                        root.info = response.data.bpi
                        root.$swal.fire({
                            icon: 'success',
                            title: 'Saved Successfully',
                            showConfirmButton: false,
                            timer: 1000,
                            timerProgressBar: true,

                        });
                        if (root.$route.query.clientParentId != undefined) {
                            root.$router.push('/clientManagement');
                        } else {
                            root.$router.push('/clientManagement');
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Something Went Wrong!',
                                text: error,
                                showConfirmButton: false,
                                timer: 1000,
                                timerProgressBar: true,

                            });

                        root.loading = false
                    })
                    .finally(() => root.loading = false)
            },
            GetLocationData: function () {
                var root = this;
                var url = '/Company/LocationDetails';
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get(url, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {

                        root.addLocation.nameInEnglish = '';
                        root.addLocation.companyRegNo = response.data.companyRegNo;
                        root.addLocation.vatRegistrationNo = response.data.vatRegistrationNo;
                        root.addLocation.email = response.data.companyEmail;
                        root.addLocation.website = response.data.website;
                        root.addLocation.phoneNumber = response.data.phoneNo;
                        root.addLocation.landLine = response.data.landLine;
                        root.addLocation.countryInEnglish = response.data.countryEnglish;
                        root.addLocation.cityInEnglish = response.data.cityEnglish;
                        root.addLocation.addressInEnglish = response.data.addressEnglish;
                    }
                });
            },
        },
        mounted: function () {
            this.GetLocationData();
            this.country = this.addLocation.countryInEnglish;
        }
    })
</script>