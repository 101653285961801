<template>
    <div class="row">
        <div class="col-lg-10 col-sm-10 ml-auto mr-auto mt-4 text-center ">
            <img src="Sale Order.jpg" alt="smart-digital-logo" style="width:auto !important;max-height:500px" />
        </div>
        <!--<div class="col-lg-10 col-sm-10 ml-auto mr-auto">
            <div class="card" style="height:500px;display: table-cell;vertical-align:middle;">
                <img src="Sale Order.jpg" alt="smart-digital-logo" style="width:100%" />

            </div>
        </div>-->
    </div>
</template>

<script>
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        data: function () {
            return {
                loading: false
            }
        },
        methods: {
        },
        mounted: function () {
            
            var abc = this.$i18n.locale;
            console.log(abc);

        }
    }
</script>