<template>
    <div hidden>
        <div ref="mychildcomponent" id='inventoryDetailReport'>
            <!--INFORMATION-->
            <div>
                <div>
                    <!--<div style="margin-top:500px; color:black;" v-if="printType == '38*28'">
                        <div style="height: 230px; color: black; ">
                            <div v-if="(lang === 'en' && item.showProdName)" style="text-align: center; font-size: 18px; color: black;">{{item.itemEngName}}</div>
                            <div v-if="(lang === 'ar' && item.showProdName)" style="text-align: center; font-size: 18px; color: black; ">{{item.itemArName}}</div>
                            <div style="text-align: center;"><svg id="barcodeView"></svg></div>
                            <div v-if="item.showPrice" style="text-align: center; font-size: 18px; color: black;">{{item.currency}}.{{item.price}}</div>
                        </div>
                        <div style="height: 200px;  color: black; ">
                            <div v-if="(lang === 'en' && item.showProdName)" style="text-align: center; font-size: 18px; color: black;">{{item.itemEngName}}</div>
                            <div v-if="(lang === 'ar' && item.showProdName)" style="text-align: center; font-size: 18px; color: black; ">{{item.itemArName}}</div>
                            <div style="text-align: center;"><svg id="barcodeView"></svg></div>
                            <div v-if="item.showPrice" style="text-align: center; font-size: 18px; color: black;">{{item.currency}}.{{item.price}}</div>
                        </div>
                        <div style="height: 200px;  color: black; ">
                            <div v-if="(lang === 'en' && item.showProdName)" style="text-align: center; font-size: 18px; color: black;">{{item.itemEngName}}</div>
                            <div v-if="(lang === 'ar' && item.showProdName)" style="text-align: center; font-size: 18px; color: black; ">{{item.itemArName}}</div>
                            <div style="text-align: center;"><svg id="barcodeView"></svg></div>
                            <div v-if="item.showPrice" style="text-align: center; font-size: 18px; color: black;">{{item.currency}}.{{item.price}}</div>
                        </div>
                        <div style="height: 200px;  color: black; ">
                            <div v-if="(lang === 'en' && item.showProdName)" style="text-align: center; font-size: 18px; color: black;">{{item.itemEngName}}</div>
                            <div v-if="(lang === 'ar' && item.showProdName)" style="text-align: center; font-size: 18px; color: black; ">{{item.itemArName}}</div>
                            <div style="text-align: center;"><svg id="barcodeView"></svg></div>
                            <div v-if="item.showPrice" style="text-align: center; font-size: 18px; color: black;">{{item.currency}}.{{item.price}}</div>
                        </div>
                        <div style="height: 200px;  color: black; margin-top:20px;">
                            <div v-if="(lang === 'en' && item.showProdName)" style="text-align: center; font-size: 18px; color: black;">{{item.itemEngName}}</div>
                            <div v-if="(lang === 'ar' && item.showProdName)" style="text-align: center; font-size: 18px; color: black; ">{{item.itemArName}}</div>
                            <div style="text-align: center;"><svg id="barcodeView"></svg></div>
                            <div v-if="item.showPrice" style="text-align: center; font-size: 18px; color: black;">{{item.currency}}.{{item.price}}</div>
                        </div>
                    </div>-->
                    <div style="margin-top:5mm ; color: black;">
                        <div style=" color: black; text-align: center; ">
                            <div v-if="lang === 'en'" style="text-align: center; font-size: 20mm; color: black;">{{headerFooter.company.nameEnglish}}</div>
                            <div v-else style="text-align: center; font-size: 20mm; color: black;">{{headerFooter.company.nameArabic}}</div>
                            <div v-if="(lang === 'en' && item.showProdName)" style="text-align: center; font-size: 20mm; color: black;">{{item.itemEngName}}</div>
                            <div v-if="(lang === 'ar' && item.showProdName)" style="text-align: center; font-size: 20mm; color: black; ">{{item.itemArName}}</div>
                            <div style="text-align: center;"><svg id="barcodeView"></svg></div>
                            <div v-if="item.showPrice" style="text-align: center; font-size: 30mm; color: black;">{{item.currency}} {{parseFloat(item.price ).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</div>
                        </div>                       
                    </div>
                    <!--<div style=" color:black;" v-if="printType == '50*26'">
                        <div style="height: 160px; color: black; margin-bottom:15px;">
                            <div v-if="(lang === 'en' && item.showProdName)" style="text-align: center; font-size: 18px; color: black;">{{item.itemEngName}}</div>
                            <div v-if="(lang === 'ar' && item.showProdName)" style="text-align: center; font-size: 18px; color: black; ">{{item.itemArName}}</div>
                            <div style="text-align: center;"><svg id="barcodeView"></svg></div>
                            <div v-if="item.showPrice" style="text-align: center; font-size: 18px; color: black;">{{item.currency}}.{{item.price}}</div>
                        </div>
                        <div style="height: 160px; color: black; margin-top: 10px;">
                            <div v-if="(lang === 'en' && item.showProdName)" style="text-align: center; font-size: 18px; color: black;">{{item.itemEngName}}</div>
                            <div v-if="(lang === 'ar' && item.showProdName)" style="text-align: center; font-size: 18px; color: black; ">{{item.itemArName}}</div>
                            <div style="text-align: center;"><svg id="barcodeView"></svg></div>
                            <div v-if="item.showPrice" style="text-align: center; font-size: 18px; color: black;">{{item.currency}}.{{item.price}}</div>
                        </div>
                        <div style="height: 160px; color: black; margin-top: 25px; padding-top:10px;">
                            <div v-if="(lang === 'en' && item.showProdName)" style="text-align: center; font-size: 18px; color: black;">{{item.itemEngName}}</div>
                            <div v-if="(lang === 'ar' && item.showProdName)" style="text-align: center; font-size: 18px; color: black; ">{{item.itemArName}}</div>
                            <div style="text-align: center;"><svg id="barcodeView"></svg></div>
                            <div v-if="item.showPrice" style="text-align: center; font-size: 18px; color: black;">{{item.currency}}.{{item.price}}</div>
                        </div>
                        <div style="height: 160px; color: black; margin-top: 45px; padding-top: 10px;">
                            <div v-if="(lang === 'en' && item.showProdName)" style="text-align: center; font-size: 18px; color: black;">{{item.itemEngName}}</div>
                            <div v-if="(lang === 'ar' && item.showProdName)" style="text-align: center; font-size: 18px; color: black; ">{{item.itemArName}}</div>
                            <div style="text-align: center;"><svg id="barcodeView"></svg></div>
                            <div v-if="item.showPrice" style="text-align: center; font-size: 18px; color: black;">{{item.currency}}.{{item.price}}</div>
                        </div>
                        <div style="height: 160px; color: black; margin-top: 28px;">
                            <div v-if="(lang === 'en' && item.showProdName)" style="text-align: center; font-size: 18px; color: black;">{{item.itemEngName}}</div>
                            <div v-if="(lang === 'ar' && item.showProdName)" style="text-align: center; font-size: 18px; color: black; ">{{item.itemArName}}</div>
                            <div style="text-align: center;"><svg id="barcodeView"></svg></div>
                            <div v-if="item.showPrice" style="text-align: center; font-size: 18px; color: black;">{{item.currency}}.{{item.price}}</div>
                        </div>
                    </div>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript" src="https://cdn.jsdelivr.net/npm/jsbarcode@3.11.4/dist/JsBarcode.all.min.js"></script>
<script>
    import axios from 'axios'
    const options = {
        name: '',
        specs: [
            'fullscreen=no',
            'titlebar=yes',
            'scrollbars=yes'
        ],
        styles: [
            'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
            'https://unpkg.com/kidlat-css/css/kidlat.css'
        ],
        timeout: 700,
        autoClose: true,
        windowTitle: window.document.title
    }
    export default {
        props: ['value', 'isShown', 'type', 'text', 'quantity', 'limit', 'item', 'printType', 'headerFooter'],
        data: function () {
            return {
                render: 0,
                barcodeList: [],
                htmlData: {
                    htmlString: ''
                },
                lang: ''
            }
        },
        mounted: function () {

            this.lang = localStorage.getItem('locales');
            this.abc = this.item
            if (this.quantity > 0) {

                this.GenerateBarcode();

            }
        },
        methods: {
            GenerateBarcode: function () {

                var barcodeValue = this.value;
                var barcodeType = this.type;
                var showText = this.text;
                JsBarcode("#barcodeView", barcodeValue, {
                    format: barcodeType,
                    displayValue: showText,
                    lineColor: "#000000",
                    width: 7,
                    height: 200,
                    fontSize: 110
                });
                var root = this;
                setTimeout(function () {
                    root.printInvoice();
                }, 125)
            },
            printInvoice: function () {
                var root = this;
                this.htmlData.htmlString = this.$refs.mychildcomponent.innerHTML;
                
                var form = new FormData();
                //form.append('BarCodeString', this.item.code);
                form.append('htmlString', this.htmlData.htmlString);
                form.append('NoOfPagesPrint', this.quantity);
                form.append('PrintType', 'barcode');
                form.append('PrinterName', this.item.printerName);
                //this.$htmlToPaper('inventoryDetailReport');
                if (this.$ServerIp.includes('localhost')) {

                    axios.post('http://127.0.0.1:7014/print/from-pdf', form).then(function (x) {

                        colsole.log(x.data)
                        //if (x.data === 'success') {
                        //    root.$swal({
                        //        title: "Saved!",
                        //        text: "Command sent tot printer successfully",
                        //        type: 'success',
                        //        confirmButtonClass: "btn btn-success",
                        //        buttonStyling: false,
                        //        icon: 'success',
                        //        timer: 1500,
                        //        timerProgressBar: true,

                        //    });
                        //}
                        //else {
                        //    root.$swal({
                        //        title: 'Error!',
                        //        text: 'Some error occured ' + x.data,
                        //        type: 'error',
                        //        confirmButtonClass: "btn btn-danger",
                        //        icon: 'error',
                        //        timer: 1500,
                        //        timerProgressBar: true,
                        //    });
                        //}

                    });
                    
                    setTimeout(function () {
                        root.$router.go('/MultiBarcodePrinting')

                    }, 125)

                }
                else {
                    this.$htmlToPaper('inventoryDetailReport', options, () => {
                        this.$router.go('/MultiBarcodePrinting')
                    });

                }
                //this.$htmlToPaper('inventoryDetailReport');
            }
        }
    }
</script>

