<template>
    <div class="row" v-if="isValid('CanViewStockTransfer') || isValid('CanDraftStockTransfer')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
        <div class="col-lg-12 col-sm-12 ml-auto mr-auto">
            <div class="row mb-4" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                <div class="col-sm-6 col-md-6 col-lg-6">
                    <h5 class="page_title">{{ $t('WareHouseTransfer.WareHouseTransfer') }}</h5>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link :to="'/StartScreen'"><a href="javascript:void(0)"> {{ $t('WareHouseTransfer.Home') }}</a></router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">{{ $t('WareHouseTransfer.WareHouseTransfer') }}</li>
                        </ol>
                    </nav>
                </div>
                <div class=" col-sm-6 col-md-6 col-lg-6">
                    <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                        <a href="javascript:void(0)" class="btn btn-outline-primary " v-on:click="AddWareHouseTransfer" v-if="isValid('CanDraftStockTransfer') || isValid('CanAddStockTransfer')"><i class="fa fa-plus"></i>  {{ $t('PurchaseOrder.AddNew') }}</a>
                        <router-link :to="'/StartScreen'"><a href="javascript:void(0)" class="btn btn-outline-danger "> {{ $t('WareHouseTransfer.Close') }}</a></router-link>
                    </div>

                </div>
            </div>
            <div class="card ">
                <div class="card-header">
                    <div class="row mb-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label>{{ $t('WareHouseTransfer.WareHouseTransferSearch')}}</label>
                                <div>
                                    <input type="text" class="form-control search_input" v-model="search" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" name="search" id="search" :placeholder="$t('WareHouseTransfer.Search')" />
                                    <span class="fas fa-search search_icon"></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                            <div class="form-group">
                                <label>{{ $t('WareHouseTransfer.FromDate') }}</label>
                                <datepicker v-model="fromDate" />
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                            <div class="form-group">
                                <label>{{ $t('WareHouseTransfer.ToDate') }}</label>
                                <datepicker v-model="toDate" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="card" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <div>
                                <ul class="nav nav-tabs" data-tabs="tabs">
                                    <li class="nav-item" v-if="isValid('CanDraftStockTransfer')"><a class="nav-link" v-bind:class="{active:active == 'Draft'}"  v-on:click="makeActive('Draft')" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">{{ $t('WareHouseTransfer.Draft') }}</a></li>
                                    <li class="nav-item"  v-if="isValid('CanViewStockTransfer')"><a class="nav-link" v-bind:class="{active:active == 'Approved'}" v-on:click="makeActive('Approved')" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">{{ $t('WareHouseTransfer.Post') }}</a></li>

                                </ul>
                            </div>
                            <div class="tab-content" id="nav-tabContent">
                                <div v-if="active == 'Draft'">
                                    <div class="col-lg-12">
                                        <div class="row" v-if="selected.length > 0">
                                            <div class="col-md-3 ">
                                                <div class="dropdown">
                                                    <button class="dropdown-toggle btn btn-primary  btn-block" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        {{ $t('WareHouseTransfer.BulkAction') }}
                                                    </button>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="mt-2">
                                            <div class="table-responsive">
                                                <table class="table table-hover table-striped table_list_bg">
                                                    <thead class="">
                                                        <tr>
                                                            <th>
                                                                #
                                                            </th>
                                                            <th v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                                {{ $t('WareHouseTransfer.CODE') }}
                                                            </th>
                                                            <th v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                                {{ $t('WareHouseTransfer.Date') }}
                                                            </th>
                                                            <th v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                                {{ $t('WareHouseTransfer.FromWareHouse') }}
                                                            </th>
                                                            <th v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                                {{ $t('WareHouseTransfer.ToWareHouse') }}
                                                            </th>
                                                            <!--<th></th>-->
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(wareHouseTransfer,index) in wareHouseTransferList" v-bind:key="index">
                                                            <td v-if="currentPage === 1">
                                                                {{index+1}}
                                                            </td>
                                                            <td v-else>
                                                                {{((currentPage*10)-10) +(index+1)}}
                                                            </td>
                                                            <td v-if="isValid('CanEditStockTransfer')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                                <strong>
                                                                    <a href="javascript:void(0)" v-on:click="EditWareHouseTransfer(wareHouseTransfer.id)">{{wareHouseTransfer.code}}</a>
                                                                </strong>
                                                            </td>
                                                            <td v-else v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                                {{wareHouseTransfer.code}}
                                                            </td>
                                                            <td v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                                {{wareHouseTransfer.date}}
                                                            </td>
                                                            <td v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                                {{wareHouseTransfer.fromWareHouse}}
                                                            </td>
                                                            <td v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                                {{wareHouseTransfer.toWareHouse}}
                                                            </td>
                                                            <!--<td>
                                                                <button class="btn btn-icon  btn-danger" @click="RemoveWareHouseTransfer(wareHouseTransfer.id)"><i class="fa fa-trash"></i></button>
                                                            </td>-->
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                                        <span v-else-if="currentPage===1 && rowCount < 10">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage===1 && rowCount >= 11  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                    </div>
                                    <div class="float-right">
                                        <div class="overflow-auto" v-on:click="getPage()" v-if="($i18n.locale == 'en' ||isLeftToRight())">
                                            <b-pagination pills size="lg" v-model="currentPage"
                                                          :total-rows="rowCount"
                                                          :key="randerList"
                                                          :per-page="10"
                                                          first-text="First"
                                                          prev-text="Previous"
                                                          next-text="Next"
                                                          last-text="Last"></b-pagination>
                                        </div>
                                        <div class="overflow-auto" v-on:click="getPage()" v-else>
                                            <b-pagination pills size="lg" v-model="currentPage"
                                                          :total-rows="rowCount"
                                                          :key="randerList"
                                                          :per-page="10"
                                                          first-text="الأولى"
                                                          prev-text="السابقة"
                                                          next-text="التالية"
                                                          last-text="الأخيرة"></b-pagination>
                                        </div>
                                    </div>

                                </div>
                                <div v-if="active == 'Approved'">
                                    <div class="col-lg-12">
                                        <div class="row" v-if="selected.length > 0">
                                            <div class="col-md-3 ">
                                                <div class="dropdown">
                                                    <button class="dropdown-toggle btn btn-primary  btn-block" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        {{ $t('WareHouseTransfer.BulkAction') }}
                                                    </button>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="mt-2">
                                            <div class="table-responsive">
                                                <table class="table table-hover table-striped table_list_bg">
                                                    <thead class="">
                                                        <tr>
                                                            <th>
                                                                #
                                                            </th>
                                                            <th v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                                {{ $t('WareHouseTransfer.CODE') }}
                                                            </th>
                                                            <th v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                                {{ $t('WareHouseTransfer.Date') }}
                                                            </th>
                                                            <th v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                                {{ $t('WareHouseTransfer.FromWareHouse') }}
                                                            </th>
                                                            <th v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                                {{ $t('WareHouseTransfer.ToWareHouse') }}
                                                            </th>
                                                            <!--<th></th>-->
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(wareHouseTransfer,index) in wareHouseTransferList" v-bind:key="index">
                                                            <td v-if="currentPage === 1">
                                                                {{index+1}}
                                                            </td>
                                                            <td v-else>
                                                                {{((currentPage*10)-10) +(index+1)}}
                                                            </td>

                                                            <td v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                                {{wareHouseTransfer.code}}
                                                            </td>
                                                            <td v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                                {{wareHouseTransfer.date}}
                                                            </td>
                                                            <td v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                                {{wareHouseTransfer.fromWareHouse}}
                                                            </td>
                                                            <td v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                                {{wareHouseTransfer.toWareHouse}}
                                                            </td>
                                                            <!--<td>
                                                                <button class="btn btn-icon  btn-danger" @click="RemoveWareHouseTransfer(wareHouseTransfer.id)"><i class="fa fa-trash"></i></button>
                                                            </td>-->
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                                        <span v-else-if="currentPage===1 && rowCount < 10">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage===1 && rowCount >= 11  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                    </div>
                                    <div class="float-right">
                                        <div class="overflow-auto" v-on:click="getPage()" v-if="($i18n.locale == 'en' ||isLeftToRight())">
                                            <b-pagination pills size="lg" v-model="currentPage"
                                                          :total-rows="rowCount"
                                                          :key="randerList"
                                                          :per-page="10"
                                                          first-text="First"
                                                          prev-text="Previous"
                                                          next-text="Next"
                                                          last-text="Last"></b-pagination>
                                        </div>
                                        <div class="overflow-auto" v-on:click="getPage()" v-else>
                                            <b-pagination pills size="lg" v-model="currentPage"
                                                          :total-rows="rowCount"
                                                          :key="randerList"
                                                          :per-page="10"
                                                          first-text="الأولى"
                                                          prev-text="السابقة"
                                                          next-text="التالية"
                                                          last-text="الأخيرة"></b-pagination>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div v-else> <acessdenied></acessdenied></div>

</template>

<script>
    import clickMixin from '@/Mixins/clickMixin'
    import moment from "moment";
    export default {
        mixins: [clickMixin],
        data: function () {
            return {
                fromDate: '',
                toDate: '',
                search: '',
                searchQuery: '',
                wareHouseTransferList: [],
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                active: 'Draft',
                selected: [],
                selectAll: false,
                counter: 0,
                randerList: 0,
            }
        },
        watch: {
            search: function (val) {
                this.getData(val, 1, this.active, this.fromDate, this.toDate);
            },
            fromDate: function (fromDate) {
                this.counter++;
                if (this.counter != 1) {
                    localStorage.setItem('fromDate', fromDate);
                    this.getData(this.search, this.currentPage, this.active, fromDate, this.toDate);
                }
            },
            toDate: function (toDate) {

                this.counter++;
                if (this.counter != 2) {
                    localStorage.setItem('toDate', toDate);
                    this.getData(this.search, this.currentPage, this.active, this.fromDate, toDate);
                }
            }
        },
        methods: {

            select: function () {
                this.selected = [];
                if (!this.selectAll) {
                    for (let i in this.wareHouseTransferList) {
                        this.selected.push(this.wareHouseTransferList[i].id);
                    }
                }
            },
            getPage: function () {
                this.getData(this.search, this.currentPage, this.active, this.fromDate, this.toDate);
            },
            makeActive: function (item) {
                this.active = item;
                this.selectAll = false;
                this.selected = [];
                this.getData(this.search, 1, item, this.fromDate, this.toDate);
            },
            EditWareHouseTransfer: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Purchase/WareHouseTransferDetail?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {

                            var isMultiUnit = localStorage.getItem('IsMultiUnit');
                            if (isMultiUnit == 'true' && localStorage.getItem('decimalQuantity') == 'true') {
                                response.data.wareHouseTransferItems.forEach(function (x) {
                                    x.highQty = parseInt(parseFloat(x.quantity) / parseFloat(x.product.unitPerPack));
                                    x.quantity = parseFloat(parseFloat(x.quantity) % parseFloat(x.product.unitPerPack)).toFixed(3).slice(0, -1);
                                });
                            }
                            if (isMultiUnit == 'true' && localStorage.getItem('decimalQuantity') != 'true') {
                                response.data.wareHouseTransferItems.forEach(function (x) {
                                    x.highQty = parseInt(parseInt(x.quantity) / parseInt(x.product.unitPerPack));
                                    x.quantity = parseInt(parseInt(x.quantity) % parseInt(x.product.unitPerPack));
                                });
                            }
                            root.$router.push({
                                path: '/addwareHouseTransfer',
                                query: { data: response.data }
                            })
                        }
                    },
                        function (error) {
                            console.log(error);
                        });
            },
            getData: function (search, currentPage, status, fromDate, toDate) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                localStorage.setItem('currentPage', this.currentPage);
                localStorage.setItem('active', this.active);
                this.$https.get('/Purchase/WareHouseTransferList?status=' + status + '&searchTerm=' + search + '&pageNumber=' + currentPage + '&fromDate=' + fromDate + '&toDate=' + toDate, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {
                            root.$store.dispatch('GetWareHouseTransferList', response.data.results);
                            root.wareHouseTransferList = response.data.results;
                            root.pageCount = response.data.pageCount;
                            root.rowCount = response.data.rowCount;
                            root.currentPage = currentPage;
                            root.randerList++;
                        }

                    });
            },

            RemoveWareHouseTransfer: function (id) {


                var root = this;
                // working with IE and Chrome both
                this.$swal({
                    title: "Are you sure?",
                    text: "You will not be able to recover this!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes, delete it!",
                    closeOnConfirm: false,
                    closeOnCancel: false
                }).then(function (result) {
                    if (result) {

                        var token = '';
                        if (root.$session.exists()) {
                            token = localStorage.getItem('token');
                        }
                        root.$https.get('/Purchase/DeleteWareHouseTransfer?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } })
                            .then(function (response) {
                                if (response.data.message.id != '00000000-0000-0000-0000-000000000000') {

                                    root.$store.state.wareHouseTransferList.splice(root.$store.state.wareHouseTransferList.findIndex(function (i) {
                                        return i.id === response.data.message.id;
                                    }), 1);
                                    root.$swal({
                                        title: 'Deleted!',
                                        text: response.data.message.isAddUpdate,
                                        type: 'success',
                                        confirmButtonClass: "btn btn-success",
                                        buttonsStyling: false
                                    });
                                } else {
                                    root.$swal({
                                        title: "Error!",
                                        text: response.data.message.isAddUpdate,
                                        type: 'error',
                                        confirmButtonClass: "btn btn-danger",
                                        buttonsStyling: false
                                    });
                                }
                            },
                                function () {

                                    root.$swal({
                                        title: "Error!",
                                        text: "Delete UnSuccessfully",
                                        type: 'error',
                                        confirmButtonClass: "btn btn-danger",
                                        buttonsStyling: false
                                    });
                                });
                    }
                    else {
                        this.$swal('Cancelled', 'Your file is still intact', 'info');
                    }
                });
            },
            AddWareHouseTransfer: function () {
                this.$router.push('/addwareHouseTransfer');
            },
        },
        created: function () {
            
            if (this.$route.query.data == 'WareHouseTransfers') {
                this.$emit('input', 'WareHouseTransfers');

            }
            else {
                this.$emit('input', this.$route.name);

            }
            if (localStorage.getItem('fromDate') != null && localStorage.getItem('fromDate') != '' && localStorage.getItem('fromDate') != undefined) {
                this.fromDate = localStorage.getItem('fromDate');

            }
            else {
                this.fromDate = moment().add(-7, 'days').format("DD MMM YYYY");

            }
            if (localStorage.getItem('toDate') != null && localStorage.getItem('toDate') != '' && localStorage.getItem('toDate') != undefined) {
                this.toDate = localStorage.getItem('toDate');

            }
            else {
                this.toDate = moment().format("DD MMM YYYY");
            }
        },
        mounted: function () {
            if (localStorage.getItem('active') != null && localStorage.getItem('active') != '' && localStorage.getItem('active') != undefined) {
                this.currentPage = parseInt(localStorage.getItem('currentPage'));
                this.active = (localStorage.getItem('active'));
                this.getPage();


            }
            else {
                if (this.isValid('CanDraftStockTransfer') ) {
                    this.makeActive('Draft')
                }
                else if (this.isValid('CanViewStockTransfer') ) {
                    this.makeActive('Approved')
                }

            }
        },
        updated: function () {
            if (this.selected.length < this.wareHouseTransferList.length) {
                this.selectAll = false;
            } else if (this.selected.length == this.wareHouseTransferList.length) {
                if (this.selected.length == 0) {
                    this.selectAll = false;
                }
                else {
                    this.selectAll = true
                }
            }
        }
    }
</script>