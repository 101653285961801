<template>
    <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">        
        <div class=" table-responsive mt-3">
            <table class="table add-table_list_bg" style="table-layout:fixed;" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                <thead style="background-color: #3178f6;color:#ffffff;">
                    <tr>
                        <th style="width: 10%">
                            #
                        </th>
                        <th style="width: 60%;">
                            {{ $t('AddTemporaryCashIssue.Description') }}
                        </th>

                        <th style="width: 30%;" class="text-center">
                            {{ $t('AddTemporaryCashIssue.Amount') }}
                        </th>
                        <th style="width: 10%"></th>
                    </tr>
                </thead>
                <tbody id="purchase-item">
                    <template v-for="(prod , index) in temporaryCash">

                        <tr :key="index" style="background:#EAF1FE;">
                            <td class="border-top-0">
                                {{index+1}}
                            </td>
                            <td class="border-top-0">
                                <input class="form-control" v-model="prod.description"  style="background-color:white;" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" />
                            </td>

                            <td class="border-top-0">
                                <decimal-to-fixed v-model="prod.amount" @input="calcuateSummary" />
                            </td>
                            <td class="border-top-0 pt-0 text-center">
                                <button @click="removeProduct(prod.rowId)"
                                        title="Remove Item"
                                        class="btn btn-secondary btn-neutral btn-round btn-sm  btn-icon">
                                    <i class="nc-icon nc-simple-remove"></i>
                                </button>
                            </td>
                        </tr>
                    </template>

                    <tr style="background:#EAF1FE;">
                        <td class="border-top-0">
                            
                        </td>
                        <td class="border-top-0">
                            <input class="form-control" v-model="description" style="background-color:white;" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" />
                        </td>

                        <td class="border-top-0">
                            <decimal-to-fixed v-model="amount" />
                        </td>
                        <td class="border-top-0 pt-0 text-center">
                            <button @click="addProduct()"
                                    v-bind:disabled="amount<=0 || description==''"
                                    title="Add Item"
                                    class="btn btn-primary btn-round btn-sm  btn-icon">
                                <i class="fa fa-plus"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class=" table-responsive mt-3"
             v-bind:key="rendered + 'g'">
            <table class="table " style="table-layout:fixed">
                <thead class="m-0" style=" background-color: #3178F6; color:#ffffff;">
                    <tr class="text-right">
                        <th class="text-center" style="width:85px;">
                            {{ $t('AddTemporaryCashIssue.NoItem') }}
                        </th>
                        <th class="text-center" style="width:100px;">
                            {{ $t('AddTemporaryCashIssue.TotalAmount') }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="text-right" style="background-color:#EAF1FE;">
                        <td class="text-center">
                            {{ summary.item }}
                        </td>
                        <td class="text-center">
                            {{ summary.amount}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>


<script>
    export default {
        name: "TemporaryCash",

        data: function () {
            return {
                rendered: 0,
                temporaryCash: [],
                loading: false,
                summary: {
                    item: 0,
                    amount: 0,
                },
                currency: '',
                amount: 0,
                description: '',
            };
        },
        methods: {            
            calcuateSummary: function () {
                this.summary.item = this.temporaryCash.length;
                this.summary.amount = this.temporaryCash.reduce((totalQty, prod) => totalQty + parseFloat(prod.amount), 0);

                this.$emit("input", this.temporaryCash, this.summary.amount);
            },


            addProduct: function () {
                var root = this;        
                this.temporaryCash.push({
                    rowId: root.createUUID(),
                    description: root.description,
                    amount: root.amount
                });

                this.description = '';
                this.amount = 0;
                this.calcuateSummary();
            },


            createUUID: function () {
                var dt = new Date().getTime();
                var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                    var r = (dt + Math.random() * 16) % 16 | 0;
                    dt = Math.floor(dt / 16);
                    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
                });
                return uuid;
            },
            removeProduct: function (id) {

                this.temporaryCash = this.temporaryCash.filter((prod) => {
                    return prod.rowId != id;
                });

                this.calcuateSummary();
            },

            GetTemporaryCashRequestDetail: function (temporaryCashIssueItems) {
                var root = this;
                
                root.temporaryCash = [];
                if (temporaryCashIssueItems != null && temporaryCashIssueItems != undefined) {
                    temporaryCashIssueItems.forEach(function (item) {
                        root.temporaryCash.push({
                            rowId: item.id,
                            description: item.description,
                            amount: item.amount
                        });
                    });
                }
                this.calcuateSummary();
            },
        },
        created: function () {
            var root = this;

            if (this.$route.query.data != undefined) {

                root.$route.query.data.temporaryCashIssueItems.forEach(function (item) {
                    root.temporaryCash.push({
                        rowId: item.id,
                        description: item.description,
                        amount: item.amount
                    });
                });
                this.calcuateSummary();
            }
        },
        mounted: function () {
            this.currency = localStorage.getItem('currency');
        },
    };
</script>

<style scoped>
    #sale-item td {
        padding-bottom: 0px;
        padding-top: 0px;
    }

    .input-border {
        border: transparent;
        background-color: transparent !important;
    }

        .input-border:focus {
            outline: none !important;
            border: none !important;
        }

    .multiselect__tags {
        background-color: transparent !important;
    }

    .multiselect__input, .multiselect__single {
        background-color: transparent !important;
    }

    .tableHoverOn {
        background-color: #ffffff !important;
        height: 32px !important;
        max-height: 32px !important;
    }
</style>
