<template>
    <div class="row">
        <div class="col-lg-12 col-sm-12 ml-auto mr-auto">

            <div class="card">
                <div class="BorderBottom ml-2 mr-2 mt-3 mb-3">
                    <span class=" DayHeading">{{ $t('UserDefineFlow.UserDefineFlow') }}</span>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3" v-if="(isValid('CanDraftServiceQuotation') || isValid('CanViewServiceQuotation')) || (isValid('CanDraftQuotation') || isValid('CanViewQuotation'))">
                            <div class="form-group">
                                <label>{{ $t('UserDefineFlow.QuotationToSaleOrder') }}</label> <br />
                                <toggle-button v-model="userDefineFlow.quotationToSaleOrder" class="ml-2 mt-2" color="#3178F6" :key="render" />
                            </div>
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3" v-if="(isValid('CanDraftServiceQuotation') || isValid('CanViewServiceQuotation')) || (isValid('CanDraftQuotation') || isValid('CanViewQuotation'))">
                            <div class="form-group">
                                <label>{{ $t('UserDefineFlow.QuotationToSaleInvoice') }}</label> <br />
                                <toggle-button v-model="userDefineFlow.quotationToSaleInvoice" class="ml-2 mt-2" color="#3178F6" :key="render" />
                            </div>
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3" v-if="(isValid('CanDraftServiceQuotation') || isValid('CanViewServiceQuotation')) || (isValid('CanDraftQuotation') || isValid('CanViewQuotation'))">
                            <div class="form-group">
                                <label>{{ $t('UserDefineFlow.PartiallyQuotation') }}</label> <br />
                                <toggle-button v-model="userDefineFlow.partiallyQuotation" class="ml-2 mt-2" color="#3178F6" :key="render" />
                            </div>
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3" v-if="(isValid('CanViewSaleOrder') || isValid('CanDraftSaleOrder')) || (isValid('CanViewServiceSaleOrder') || isValid('CanDraftServiceSaleOrder'))">
                            <div class="form-group">
                                <label>{{ $t('UserDefineFlow.PartiallySaleOrder') }}</label> <br />
                                <toggle-button v-model="userDefineFlow.partiallySaleOrder" class="ml-2 mt-2" color="#3178F6" :key="render" />
                            </div>
                        </div>

                    </div>
                </div>

                <div v-if="!loading" class="card-footer">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-right">
                            <button type="button" class="btn btn-primary ml-3  mr-3" v-on:click="SaveUserDefineFlow"> {{ $t('UserDefineFlow.Save') }}</button>
                            <button type="button" class="btn btn-danger " v-on:click="close()">{{ $t('UserDefineFlow.Cancel') }}</button>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import clickMixin from '@/Mixins/clickMixin';
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        mixins: [clickMixin],
        components: {
            Loading
        },
        data: function () {
            return {
                arabic: '',
                english: '',
                userDefineFlow: {
                    companyId: '',
                    quotationToSaleOrder: false,
                    quotationToSaleInvoice: false,
                    partiallyQuotation: false,
                    partiallySaleOrder: false,
                },
                loading: false,
                render: false,
            }
        },
        methods: {
            SaveUserDefineFlow: function () {
                this.loading = true;
                var root = this;
                this.userDefineFlow.companyId = localStorage.getItem('CompanyID');

                this.$https.post('/Company/UserDefineFlowSave', this.userDefineFlow, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
                    .then(function (response) {

                        if (response.data != null) {

                            root.$swal({
                                title: "Update!",
                                text: "Update Successfully!",
                                type: 'success',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1500,
                                timerProgressBar: true,
                            });
                            root.EditUserDefineFlow();
                            root.loading = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Something Went Wrong!',
                                text: error.response.data,
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false
                    })
                    .finally(() => root.loading = false)
            },


            EditUserDefineFlow: function () {
                var root = this;
                this.loading = true;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Company/UserDefineFlowEdit?companyId=' + localStorage.getItem('CompanyID'), { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {
                            
                            root.userDefineFlow.quotationToSaleOrder = response.data.quotationToSaleOrder;
                            root.userDefineFlow.quotationToSaleInvoice = response.data.quotationToSaleInvoice;
                            root.userDefineFlow.partiallyQuotation = response.data.partiallyQuotation;
                            root.userDefineFlow.partiallySaleOrder = response.data.partiallySaleOrder;

                            localStorage.setItem('quotationToSaleOrder', response.data.quotationToSaleOrder);
                            localStorage.setItem('quotationToSaleInvoice', response.data.quotationToSaleInvoice);
                            localStorage.setItem('partiallyQuotation', response.data.partiallyQuotation);
                            localStorage.setItem('partiallySaleOrder', response.data.partiallySaleOrder);

                            root.render++;
                            root.loading = false;
                        }
                        else {
                            console.log("error: something wrong from db.");
                        }
                    },
                        function (error) {
                            root.loading = false;
                            console.log(error);
                        });

            }
        },
        created: function () {
            this.$emit('input', this.$route.name);
            this.EditUserDefineFlow();
        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            
        }
    }
</script>