<template>
    <modal :show="show">
        <div style="margin-bottom:0px" class="card" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <div class="card-body">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="modal-header" v-if="type=='Edit'">
                            <h5 class="modal-title DayHeading" id="myModalLabel">{{ $t('AddPermissions.UpdateRoles') }}</h5>
                        </div>
                        <div class="modal-header" v-else>
                            <h5 class="modal-title DayHeading" id="myModalLabel"> {{ $t('AddPermissions.AddRoles') }}</h5>
                        </div>
                        <div >
                            <div class="card-body ">
                                <div class="row ">
                                    <div class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.roles.name.$error}">
                                        <label class="text  font-weight-bolder"> {{ $t('AddPermissions.Name') }}: *</label>
                                        <input class="form-control" v-model="$v.roles.name.$model" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" type="text" />
                                        <span v-if="$v.roles.name.$error" class="error">
                                            <span v-if="!$v.roles.name.required">{{ $t('AddPermissions.NameRequired') }}</span>
                                            <span v-if="!$v.roles.name.maxLength">{{ $t('AddPermissions.NameLength') }}</span>
                                        </span>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <label style="margin: 7px;">{{ $t('AddPermissions.Active') }}</label> <br />
                                        <div class="bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-animate" v-bind:class="{'bootstrap-switch-on': roles.isActive, 'bootstrap-switch-off': !roles.isActive}" v-on:click="roles.isActive = !roles.isActive" style="width: 72px;">
                                            <div class="bootstrap-switch-container" style="width: 122px; margin-left: 0px;">
                                                <span class="bootstrap-switch-handle-on bootstrap-switch-success" style="width: 50px;">
                                                    <i class="nc-icon nc-check-2"></i>
                                                </span>
                                                <span class="bootstrap-switch-label" style="width: 30px;">&nbsp;</span>
                                                <span class="bootstrap-switch-handle-off bootstrap-switch-success" style="width: 50px;">
                                                    <i class="nc-icon nc-simple-remove"></i>
                                                </span>
                                                <input class="bootstrap-switch" type="checkbox" data-toggle="switch" checked="" data-on-label="<i class='nc-icon nc-check-2'></i>" data-off-label="<i class='nc-icon nc-simple-remove'></i>" data-on-color="success" data-off-color="success">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer justify-content-right" v-if="type=='Edit'">
                            <button type="button" class="btn btn-primary  " v-on:click="SaveRoles" v-bind:disabled="$v.roles.$invalid"> {{ $t('AddPermissions.btnUpdate') }}</button>
                            <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{ $t('AddPermissions.btnClear') }}</button>
                        </div>
                        <div class="modal-footer justify-content-right" v-else>
                            <button type="button" class="btn btn-primary  " v-on:click="SaveRoles" v-bind:disabled="$v.roles.$invalid"> {{ $t('AddPermissions.btnSave') }}</button>
                            <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{ $t('AddPermissions.btnClear') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>

    import 'vue-loading-overlay/dist/vue-loading.css';

    import { required, maxLength } from "vuelidate/lib/validators"
    export default {
        props: ['show', 'roles', 'type'],
        data: function () {
            return {
                render: 0
            }
        },
        validations: {
            roles: {
                name: {
                    required,
                    maxLength: maxLength(50)
                }
            }
        },
        methods: {
            close: function () {
                this.$emit('close');
            },
            SaveRoles: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.roles.normalizedName = this.roles.name;
                this.$https.post('/Company/SaveNobleRoles', this.roles, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    
                    if (response.data.isSuccess == true) {
                        if (root.type != "Edit") {
                            root.$store.state.rolesList.push({
                                id: response.data.roles.id,
                                name: response.data.roles.name,
                                normalizedName: response.data.roles.normalizedName
                            })
                            root.$swal({
                                title: "Saved!",
                                icon:'success',
                                text: "Your Role " + response.data.roles.name + " has been created!",
                                type: 'success',
                                showConfirmButton: false,
                                timer: 1000,
                                timerProgressBar: true
                            });
                            root.close();
                        }
                        else {
                            var data = root.$store.state.rolesList.find(function (x) {
                                return x.id == response.data.roles.id;
                            });
                            data.id = response.data.roles.id;
                            data.name = response.data.roles.name;
                            data.description = response.data.roles.description;
                            data.code = response.data.roles.code;
                            root.$swal({
                                title: "Update!",
                                text: "Your Role " + response.data.roles.name + " has been updated!",
                                type: 'success',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1000,
                                timerProgressBar: true
                            });
                            root.close();
                        }
                    }
                    else {
                        root.$swal({
                            title: "Error!",
                            text: "Your Role Name Already Exist!",
                            type: 'error',
                            showConfirmButton: false,
                            timer: 1000,
                            timerProgressBar: true
                        });
                    }
                });
            }
        },
        mounted: function () {
            if (this.roles.id == '00000000-0000-0000-0000-000000000000' || this.roles.id == undefined || this.roles.id == '')
            {
                this.roles.name = '';
                this.roles.normalizedName = '';
                this.roles.id = '00000000-0000-0000-0000-000000000000';
            }
        }
    }
</script>
