<template>

    <div class="row" v-if="isValid('CanViewCurrency')">
        <div class="col-lg-12 col-sm-12 ml-auto mr-auto">
            <div class="card  " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                <div class="BorderBottom ml-4 mr-4 mt-3 mb-3">
                    <span class=" DayHeading">{{ $t('TheCurrency.Currency') }}</span>
                </div>

                <div class="card-body">
                    <div class="col-lg-12">
                        <div class="row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                <div class="form-group">
                                    <label>{{ $t('TheCurrency.SearchByCurrency') }}</label>
                                    <div>
                                        <input type="text" class="form-control search_input" v-model="searchQuery" name="search" id="search" :placeholder="$t('TheCurrency.Search')" />
                                        <span class="fas fa-search search_icon"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'" style="margin-top:18px;">
                                <a v-if="isValid('CanAddCurrency')" href="javascript:void(0)" class="btn btn-primary"  v-on:click="AddCurrency"><i class="fa fa-plus"></i>  {{ $t('TheCurrency.AddNew') }}</a>
                                <router-link :to="'/FinancialSetup'">
                                    <a href="javascript:void(0)" class="btn btn-outline-danger ">  <i class="fas fa-arrow-circle-left fa-lg"></i> </a>
                                </router-link>
                            </div>
                            
                        </div>
                        <div class="mt-2">
                            <div class=" table-responsive">
                                <table class="table table-striped table-hover table_list_bg " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <thead class="m-0">
                                        <tr>
                                            <th>
                                                #
                                            </th>
                                            <th class="text-center">
                                                {{ $t('TheCurrency.Image')}}
                                            </th>
                                            <th v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-if="english=='true'">
                                                {{ $t('TheCurrency.CurrencyName') |englishLanguage}}
                                            </th>
                                            <th v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-if="isOtherLang()">
                                                {{ $t('TheCurrency.CurrencyName') |arabicLanguage}}
                                            </th>
                                            <th v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                {{ $t('TheCurrency.SIGN') }}
                                            </th>
                                            <th v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                {{ $t('TheCurrency.Status') }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(details,index) in resultQuery" v-bind:key="details.id">
                                            <td>
                                                {{index+1}}
                                            </td>
                                            <td v-if="details.image==null || details.image=='' || details.image==undefined" class="text-center"><img src="Product.png" style="width: 50px;" /></td>
                                            <td v-else class="text-center"><img :src="'data:image/png;base64,' + details.image" style="width: 50px;" /></td>

                                            <td v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-if="english=='true'">
                                                <strong v-if="index==0">
                                                    <a href="javascript:void(0)" v-if="isValid('CanEditCurrency')" v-on:click="EditCurrency(details.id,true)">{{details.name}}</a>
                                                    <span  v-else>{{details.name}}</span>
                                                </strong>
                                                <strong v-else>
                                                    <a href="javascript:void(0)" v-if="isValid('CanEditCurrency')" v-on:click="EditCurrency(details.id,false)">{{details.name}}</a>
                                                    <span  v-else>{{details.name}}</span>
                                                </strong>
                                            </td>
                                            <td v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-if="isOtherLang()">
                                                <strong v-if="index==0">
                                                    <a href="javascript:void(0)" v-if="isValid('CanEditCurrency')" v-on:click="EditCurrency(details.id,true)">{{details.nameArabic}}</a>
                                                    <span  v-else>{{details.nameArabic}}</span>
                                                </strong>
                                                <strong v-else style="color :red !important">
                                                    <a href="javascript:void(0)" v-if="isValid('CanEditCurrency')" v-on:click="EditCurrency(details.id)">{{details.nameArabic}}</a>
                                                    <span  v-else>{{details.nameArabic}}</span>
                                                </strong>

                                            </td>
                                            <td v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                {{details.sign}}
                                            </td>
                                            <td v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                <span v-if="details.isActive">Active</span>
                                                <span v-else>InActive</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <currencymodel :currency="newCurrency"
                       :show="show"
                       v-if="show"
                       @close="show = false"
                       :type="type" />
    </div>
    <div v-else> <acessdenied></acessdenied></div>

</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'

    export default {
        mixins: [clickMixin],
        name: 'Currency',
        data: function () {
            return {
                arabic: '',
                english: '',
                show: false,
                searchQuery: '',
                currencylist: [

                ],
                newCurrency: {
                    id: '00000000-0000-0000-0000-000000000000',
                    name: '',
                    nameArabic: '',
                    sign: '',
                    arabicSign: '',
                    image: '',
                    isActive: true,
                    isDisable: false,
                },
                type: '',

            }
        },
        computed: {
            resultQuery: function () {
                var root = this;
                if (this.searchQuery) {
                    return this.currencylist.filter((cur) => {
                        return root.searchQuery.toLowerCase().split(' ').every(v => cur.name.toLowerCase().includes(v) || cur.nameArabic.toLowerCase().includes(v))
                    })
                } else {
                    return root.currencylist;
                }
            },
        },
        methods: {
            AddCurrency: function () {
                this.newCurrency = {
                    id: '00000000-0000-0000-0000-000000000000',
                    name: '',
                    nameArabic: '',
                    sign: '',
                    arabicSign: '',
                    image: '',
                    isActive: true,
                    isDisable: false,
                };
                this.show = !this.show;
                this.type = "Add";
            },
            GetCurrencyData: function () {
                var root = this;
                var url = '/Product/CurrencyList';
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get(url, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.$store.dispatch('GetCurrencyList', response.data.currencies);
                        root.currencylist = response.data.currencies;
                    }
                });
            },
            EditCurrency: function (id, isDisable) {

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Product/CurrencyDetail?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data) {
                            root.newCurrency.id = response.data.id;
                            root.newCurrency.name = response.data.name;
                            root.newCurrency.nameArabic = response.data.nameArabic;
                            root.newCurrency.sign = response.data.sign;
                            root.newCurrency.isDisable = isDisable;
                            root.newCurrency.arabicSign = response.data.arabicSign;
                            root.newCurrency.image = response.data.image;
                            root.newCurrency.isActive = response.data.isActive;
                            root.show = !root.show;
                            root.type = "Edit"
                        } else {
                            console.log("error: something wrong from db.");
                        }
                    },
                        function (error) {
                            this.loading = false;
                            console.log(error);
                        });

            }
        },
        created: function () {
            this.$emit('input', this.$route.name);
        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.GetCurrencyData();
        }
    }
</script>