<template>
    <div  v-bind:class="dropdownpo">
        <multiselect v-model="selectedValue"
                     @input="$emit('input', selectedValue.id, products.find(x => x.id == selectedValue.id))"
                     :options="options"
                     :multiple="false"
                     track-by="name"
                     :clear-on-select="false"
                     :show-labels="false"
                     label="name"
                     :preselect-first="true"
                     v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left ' : 'arabicLanguage '"
                     @search-change="asyncFind">
                     <p slot="noResult" class="text-danger"> Oops! No Product found.</p>
        </multiselect>
    </div>
</template>
<script>
    import Multiselect from "vue-multiselect";
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        name: "ProductDropdown",
        props: ["value", "wareHouseId", "dropdownpo"],

        components: {
            Multiselect,
        },
        data: function () {
            return {
                options: [],
                selectedValue: [],
                products: [],
                product: {}
            };
        },
        methods: {
            asyncFind: function (search) {
                var root = this;
                var token = "";
                if (this.$session.exists()) {
                    token = localStorage.getItem("token");
                }
                var url = "/Product/GetProductInformation?searchTerm=" + search;
                this.$https
                    .get(url, {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then(function (response) {
                        if (response.data != null) {
                            root.options = [];
                            root.products = response.data.results.products;

                            if (root.products != undefined) {
                                root.products.forEach(function (prod) {
                                    if (root.value == prod.id && root.value != undefined) {
                                        root.selectedValue.push({
                                            id: prod.id,
                                            name: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? prod.englishName : prod.arabicName,
                                        });
                                        root.product = prod;
                                    }

                                    root.options.push({
                                        id: prod.id,
                                        name: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? prod.code + ' ' + prod.englishName : prod.code + ' ' + prod.arabicName,
                                    });
                                });
                            }
                        }
                    });
            },
        },
        computed: {

        },
        created: function () {
            var val = this.value;
            if(val == '00000000-0000-0000-0000-000000000000'){
                val = '';
                this.asyncFind(val);
            }
            else{
                this.asyncFind(this.value);
            }
        },
    };
</script>
