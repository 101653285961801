<template>
    <div class="col-md-8 ml-auto mr-auto mt-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">

        <div class="accordion" role="tablist">
            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <h5 class="card-title ml-4">Add Customer</h5>
                    <b-button block v-b-toggle.accordion-1 variant="primary">Personal Information</b-button>
                </b-card-header>
                <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <div class="text-left">

                            <div class="card-body">
                                <div class="row ">
                                    <div class="col-sm-6">
                                        <label>Customer Code :*</label>
                                        <div v-bind:class="{'has-danger' : $v.Customer.code.$error}">
                                            <input readonly class="form-control" v-model="$v.Customer.code.$model" />
                                            <span v-if="$v.Customer.code.$error" class="error text-danger">
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label>Registration Date :*</label>
                                        <div v-bind:class="{'has-danger' : $v.Customer.registrationDate.$error}">

                                            <datepicker v-model="$v.Customer.registrationDate.$model" :key="daterander"></datepicker>
                                            <span v-if="$v.Customer.registrationDate.$error" class="error text-danger">
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label>Customer Category :*</label>
                                        <div v-bind:class="{'has-danger' : $v.Customer.category.$error}">
                                            <input class="form-control" v-model="$v.Customer.category.$model" />
                                            <span v-if="$v.Customer.category.$error" class="error text-danger">
                                                <span v-if="!$v.Customer.category.required"> Name is Required</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label>Customer Type :*</label>
                                        <div v-bind:class="{'has-danger' : $v.Customer.CustomerType.$error}">

                                            <link rel="stylesheet" href="https://unpkg.com/vue-multiselect@2.1.0/dist/vue-multiselect.min.css">
                                            <multiselect v-model="$v.Customer.CustomerType.$model" :options="['WholeSaler', 'Retailer', 'Dealer', 'Distributor']" :show-labels="false" placeholder="Select Type">
                                            </multiselect>
                                            <span v-if="$v.Customer.CustomerType.$error" class="error text-danger">
                                                <span v-if="!$v.Customer.CustomerType.required"> Name is Required</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label>Customer Name(English) :</label>
                                        <div v-bind:class="{'has-danger' : $v.Customer.englishName.$error}">
                                            <input class="form-control" v-model="$v.Customer.englishName.$model" />
                                            <span v-if="$v.Customer.englishName.$error" class="error text-danger">
                                                <span v-if="!$v.Customer.englishName.required"> Name is Required</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label>Customer Name(Arabic) :</label>
                                        <div v-bind:class="{'has-danger' : $v.Customer.arabicName.$error}">
                                            <input class="form-control" v-model="$v.Customer.arabicName.$model" />
                                            <span v-if="$v.Customer.arabicName.$error" class="error text-danger">
                                                <span v-if="!$v.Customer.arabicName.required"> Name is Required</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label>Account :*</label>
                                        <div>
                                            <accountdropdown v-model="Customer.accountId"  v-bind:values="Customer.accountId"></accountdropdown>
                                        </div>
                                    </div>
                                    <div class="card-footer col-md-12 ">

                                        <button class="btn btn-primary  float-right mr-2" v-b-toggle.accordion-2>Next</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-card-body>
                </b-collapse>
            </b-card>
            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-2 variant="primary">Commercial Information</b-button>
                </b-card-header>
                <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <div class="text-left">

                            <div class="card-body">
                                <div class="row ">

                                    <div class="col-sm-6">
                                        <label>Commercial Registration No. :</label>
                                        <div v-bind:class="{'has-danger' : $v.Customer.commercialRegistrationNo.$error}">
                                            <input class="form-control" v-model="$v.Customer.commercialRegistrationNo.$model" />
                                            <span v-if="$v.Customer.commercialRegistrationNo.$error" class="error text-danger">
                                            </span>
                                        </div>
                                    </div>

                                    <div class="col-sm-6">
                                        <label>VAT/NTN/Tax No. :</label>
                                        <div v-bind:class="{'has-danger' : $v.Customer.vatNo.$error}">
                                            <input class="form-control" v-model="$v.Customer.vatNo.$model" />
                                            <span v-if="$v.Customer.vatNo.$error" class="error text-danger">
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label>Email :</label>
                                        <div v-bind:class="{'has-danger' : $v.Customer.email.$error}">
                                            <input class="form-control" v-model="$v.Customer.email.$model" />
                                            <span v-if="$v.Customer.email.$error" class="error text-danger">
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label>Contact Person 1 :</label>
                                        <div v-bind:class="{'has-danger' : $v.Customer.contactPerson1.$error}">
                                            <input class="form-control" v-model="$v.Customer.contactPerson1.$model" />
                                            <span v-if="$v.Customer.contactPerson1.$error" class="error text-danger">
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label>Contact Number 1 :</label>
                                        <div v-bind:class="{'has-danger' : $v.Customer.contactNo1.$error}">
                                            <input class="form-control" v-model="$v.Customer.contactNo1.$model" />
                                            <span v-if="$v.Customer.contactNo1.$error" class="error text-danger">
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label>Contact Person 2 :</label>
                                        <div v-bind:class="{'has-danger' : $v.Customer.contactPerson2.$error}">
                                            <input class="form-control" v-model="$v.Customer.contactPerson2.$model" />
                                            <span v-if="$v.Customer.contactPerson2.$error" class="error text-danger">

                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label>Contact Number 2 :</label>
                                        <div v-bind:class="{'has-danger' : $v.Customer.contactNo2.$error}">
                                            <input class="form-control" v-model="$v.Customer.contactNo2.$model" />
                                            <span v-if="$v.Customer.contactNo2.$error" class="error text-danger">

                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label>City :</label>
                                        <div v-bind:class="{'has-danger' : $v.Customer.city.$error}">
                                            <input class="form-control" v-model="$v.Customer.city.$model" />
                                            <span v-if="$v.Customer.city.$error" class="error text-danger">

                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label>Country :</label>
                                        <div v-bind:class="{'has-danger' : $v.Customer.country.$error}">
                                            <input class="form-control" v-model="$v.Customer.country.$model" />
                                            <span v-if="$v.Customer.country.$error" class="error text-danger">
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label>Address :</label>
                                        <div v-bind:class="{'has-danger' : $v.Customer.address.$error}">
                                            <textarea class="form-control" v-model="$v.Customer.address.$model" />
                                            <span v-if="$v.Customer.address.$error" class="error text-danger">
                                            </span>
                                        </div>
                                    </div>
                                    <div class="card-footer col-md-12 ">

                                        <button class="btn btn-primary  float-right mr-2" v-b-toggle.accordion-3>Next</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-card-body>
                </b-collapse>
            </b-card>
            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-3 variant="primary">Bank Details</b-button>
                </b-card-header>
                <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <div class="text-left mb-1">

                            <div class="card-body">
                                <div class="row">

                                    <div class="col-md-12"><h6>Bank Account 1</h6></div>
                                    <div class="col-sm-4">
                                        <label>Bank Account Title :</label>
                                        <div v-bind:class="{'has-danger' : $v.Customer.bankAccountTitle1.$error}">
                                            <input class="form-control" type="text" v-model="$v.Customer.bankAccountTitle1.$model" />
                                            <span v-if="$v.Customer.bankAccountTitle1.$error" class="error text-danger">
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <label for="Customer.bankAccountNo1">Bank Account No. :</label>
                                        <div v-bind:class="{'has-danger' : $v.Customer.bankAccountNo1.$error}">
                                            <input class="form-control" type="text" v-model="$v.Customer.bankAccountNo1.$model" />
                                            <span v-if="$v.Customer.bankAccountNo1.$error" class="error text-danger">
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <label for="Customer.iBAN1">IBAN :</label>
                                        <div v-bind:class="{'has-danger' : $v.Customer.iBAN1.$error}">
                                            <input class="form-control" type="text" v-model="$v.Customer.iBAN1.$model" />
                                            <span v-if="$v.Customer.iBAN1.$error" class="error text-danger">
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <label for="Customer.nameOfBank1">Name of Bank :</label>
                                        <div v-bind:class="{'has-danger' : $v.Customer.nameOfBank1.$error}">
                                            <input class="form-control" type="text" v-model="$v.Customer.nameOfBank1.$model" />
                                            <span v-if="$v.Customer.nameOfBank1.$error" class="error text-danger">
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <label for="Customer.branchName1">Branch Name:</label>
                                        <div v-bind:class="{'has-danger' : $v.Customer.branchName1.$error}">
                                            <input class="form-control" type="text" v-model="$v.Customer.branchName1.$model" />
                                            <span v-if="$v.Customer.branchName1.$error" class="error text-danger">
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <label for="Customer.routingCode1">Routing Code :</label>
                                        <div v-bind:class="{'has-danger' : $v.Customer.routingCode1.$error}">
                                            <input class="form-control" type="text" v-model="$v.Customer.routingCode1.$model" />
                                            <span v-if="$v.Customer.routingCode1.$error" class="error text-danger">
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <label for="Customer.city1">City :</label>
                                        <div v-bind:class="{'has-danger' : $v.Customer.city1.$error}">
                                            <input class="form-control" type="text" v-model="$v.Customer.city1.$model" />
                                            <span v-if="$v.Customer.city1.$error" class="error text-danger">
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <label for="Customer.country1">Country :</label>
                                        <div v-bind:class="{'has-danger' : $v.Customer.country1.$error}">
                                            <input class="form-control" type="text" v-model="$v.Customer.country1.$model" />
                                            <span v-if="$v.Customer.country1.$error" class="error text-danger">
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <label for="Customer.address1">Address :</label>
                                        <div v-bind:class="{'has-danger' : $v.Customer.address1.$error}">
                                            <textarea class="form-control" type="text" v-model="$v.Customer.address1.$model" />
                                            <span v-if="$v.Customer.address1.$error" class="error text-danger">
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mt-3"><h6>Bank Account 2</h6></div>
                                    <div class="col-sm-4">
                                        <label>Bank Account Title :</label>
                                        <div v-bind:class="{'has-danger' : $v.Customer.bankAccountTitle2.$error}">
                                            <input class="form-control" type="text" v-model="$v.Customer.bankAccountTitle2.$model" />
                                            <span v-if="$v.Customer.bankAccountTitle2.$error" class="error text-danger">
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <label for="Customer.bankAccountNo2">Bank Account No. :</label>
                                        <div v-bind:class="{'has-danger' : $v.Customer.bankAccountNo2.$error}">
                                            <input class="form-control" type="text" v-model="$v.Customer.bankAccountNo2.$model" />
                                            <span v-if="$v.Customer.bankAccountNo2.$error" class="error text-danger">
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <label for="Customer.iBAN2">IBAN :</label>
                                        <div v-bind:class="{'has-danger' : $v.Customer.iBAN2.$error}">
                                            <input class="form-control" type="text" v-model="$v.Customer.iBAN2.$model" />
                                            <span v-if="$v.Customer.iBAN2.$error" class="error text-danger">
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <label for="Customer.nameOfBank2">Name of Bank :</label>
                                        <div v-bind:class="{'has-danger' : $v.Customer.nameOfBank2.$error}">
                                            <input class="form-control" type="text" v-model="$v.Customer.nameOfBank2.$model" />
                                            <span v-if="$v.Customer.nameOfBank2.$error" class="error text-danger">
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <label for="Customer.branchName2">Branch Name:</label>
                                        <div v-bind:class="{'has-danger' : $v.Customer.branchName2.$error}">
                                            <input class="form-control" type="text" v-model="$v.Customer.branchName2.$model" />
                                            <span v-if="$v.Customer.branchName2.$error" class="error text-danger">
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <label for="Customer.routingCode2">Routing Code :</label>
                                        <div v-bind:class="{'has-danger' : $v.Customer.routingCode2.$error}">
                                            <input class="form-control" type="text" v-model="$v.Customer.routingCode2.$model" />
                                            <span v-if="$v.Customer.routingCode2.$error" class="error text-danger">
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <label for="Customer.city2">City :</label>
                                        <div v-bind:class="{'has-danger' : $v.Customer.city2.$error}">
                                            <input class="form-control" type="text" v-model="$v.Customer.city2.$model" />
                                            <span v-if="$v.Customer.city2.$error" class="error text-danger">
                                                <span v-if="!$v.Customer.city2.city2">city2 is invalid</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <label for="Customer.country2">Country :</label>
                                        <div v-bind:class="{'has-danger' : $v.Customer.country2.$error}">
                                            <input class="form-control" type="text" v-model="$v.Customer.country2.$model" />
                                            <span v-if="$v.Customer.country2.$error" class="error text-danger">
                                                <span v-if="!$v.Customer.country2.country2">country2 is invalid</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <label for="Customer.address2">Address :</label>
                                        <div v-bind:class="{'has-danger' : $v.Customer.address2.$error}">
                                            <textarea class="form-control" type="text" v-model="$v.Customer.address2.$model" />
                                            <span v-if="$v.Customer.address2.$error" class="error text-danger">
                                                <span v-if="!$v.Customer.address2.address2">address2 is invalid</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="card-footer col-md-12 ">

                                    <button class="btn btn-primary  float-right  mb-2" v-b-toggle.accordion-4>Next</button>

                                </div>
                            </div>
                        </div>
                    </b-card-body>
                </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-4 variant="primary">Terms and Conditions</b-button>
                </b-card-header>
                <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <div class="text-left">

                            <div class="card-body">
                                <div class="row ">


                                    <div class="col-sm-6">
                                        <label>Payment Terms :</label>
                                        <div v-bind:class="{'has-danger' : $v.Customer.paymentTerms.$error}">
                                            <input class="form-control" v-model="$v.Customer.paymentTerms.$model" />
                                            <span v-if="$v.Customer.paymentTerms.$error" class="error text-danger">
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label>Delivery Term :</label>
                                        <div v-bind:class="{'has-danger' : $v.Customer.deliveryTerm.$error}">
                                            <input class="form-control" v-model="$v.Customer.deliveryTerm.$model" />
                                            <span v-if="$v.Customer.deliveryTerm.$error" class="error text-danger">
                                            </span>
                                        </div>
                                    </div>

                                    <div class="col-sm-6">
                                        <label>Expiry Date :</label>
                                        <div v-bind:class="{'has-danger' : $v.Customer.expiryDate.$error}">
                                            <datepicker v-model="$v.Customer.expiryDate.$model" :key="daterander"></datepicker>
                                            <span v-if="$v.Customer.expiryDate.$error" class="error text-danger">
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <label>Remarks :</label>

                                        <textarea class="form-control" v-model="Customer.remarks" />

                                    </div>


                                    <div class="card-footer col-md-12 ">

                                        <button class="btn btn-primary  float-right mr-2" v-b-toggle.accordion-5>Next</button>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </b-card-body>
                </b-collapse>
            </b-card>
            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-5 variant="primary">Attachments</b-button>
                </b-card-header>
                <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <div class="text-left">
                            <div class="col-md-12">
                               <h5 class="card-title DayHeading ">Attchments</h5>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <label>Commercial Registration :</label>
                                        <div class="fileUpload">
                                            <div id="preview">

                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <label>VAT/NTN/TaxCertificate :</label>
                                        <div class="fileUpload">
                                            <div id="preview">

                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <label>Zakat Certificate :</label>
                                        <div class="fileUpload">
                                            <div id="preview">

                                            </div>

                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </b-card-body>
                </b-collapse>
                <div v-if="!loading" class=" col-md-12 text-right">
                    <button class="btn btn-primary   mr-2" v-on:click="SaveCustomer" v-if="Customer.id=='00000000-0000-0000-0000-000000000000'">Save</button>
                    <button class="btn btn-primary   mr-2" v-on:click="SaveCustomer" v-else>Update</button>

                    <button class="btn btn-reset   mr-2" v-on:click="Cancel">Cancel</button>


                </div>
                <div class="card-footer col-md-3" v-else>
                    <loading :active.sync="loading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="true"></loading>
                </div>

            </b-card>
        </div>


    </div>


</template>
<script>
    import Multiselect from 'vue-multiselect'
    import moment from 'moment'
    import { required, maxLength } from 'vuelidate/lib/validators';
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default ({

        components: {
            Loading,
            Multiselect,
        },
        data: function () {
            return {
                value: '',

                active: 'personal',
                Customer: {
                    id: '00000000-0000-0000-0000-000000000000',
                    code: '',
                    registrationDate: '',
                    category: '',
                    CustomerType: '',
                    englishName: '',
                    arabicName: '',
                    commercialRegistrationNo: '',
                    expiryDate: '',
                    vatNo: '',
                    email: '',
                    contactPerson1: '',
                    contactNo1: '',
                    contactPerson2: '',
                    contactNo2: '',
                    country: '',
                    city: '',
                    address: '',
                    paymentTerms: '',
                    deliveryTerm: '',
                    remarks: '',
                    accountId:'',
                    bankAccountTitle1: '',
                    bankAccountNo1: '',
                    iBAN1: '',
                    nameOfBank1: '',
                    branchName1: '',
                    routingCode1: '',
                    address1: '',
                    city1: '',
                    country1: '',

                    bankAccountTitle2: '',


                    iBAN2: '',
                    bankAccountNo2: '',

                    nameOfBank2: '',
                    branchName2: '',
                    routingCode2: '',
                    address2: '',
                    city2: '',
                    country2: '',

                    commercialRegistration: '',
                    vATCertificate: '',
                    zakatCertificate: '',
                    isCustomer: true
                },
                loading: false,
                daterander: 0
            }
        },
        validations: {
            Customer:
            {
                code: {},
                registrationDate: {},
                category: { required, maxLength: maxLength(250) },
                CustomerType: { required, maxLength: maxLength(250) },
                englishName: { required, maxLength: maxLength(250) },
                arabicName: { required, maxLength: maxLength(250) },
                commercialRegistrationNo: {},
                expiryDate: {},
                vatNo: {},
                email: {},
                contactPerson1: {},
                contactNo1: {},
                contactPerson2: {},
                contactNo2: {},
                country: {},
                city: {},
                accountId: {required},
                address: {},
                paymentTerms: {},
                deliveryTerm: {},
                remarks: {},

                bankAccountTitle1: {},
                bankAccountNo1: {},
                iBAN1: {},
                nameOfBank1: {},
                branchName1: {},
                routingCode1: {},
                address1: {},
                city1: {},
                country1: {},

                bankAccountTitle2: {},
                bankAccountNo2: {},
                iBAN2: {},
                nameOfBank2: {},
                branchName2: {},
                routingCode2: {},
                address2: {},
                city2: {},
                country2: {},



            }
        },
        methods: {

            GetAutoCodeGenerator: function () {
                
                var root = this;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https
                    .get('/Contact/AutoGenerateCode?isCustomer=true', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                        
                        if (response.data != null) {
                            root.Customer.code = response.data;

                        }
                    });
            },
            Cancel: function () {
                this.$router.push({
                    path: '/Customer',

                })
            },

            makeActive: function (tab) {

                this.active = tab;
            },
            SaveCustomer: function () {
                //es;int-disable-line
                this.loading = true;
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                //es;int-disable-line
                root.$https
                    .post('/Contact/SaveContact', this.Customer, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {
                        
                        this.loading = false
                        this.info = response.data.bpi

                        this.$swal.fire({
                            icon: 'success',
                            title: 'Saved Successfully',
                            showConfirmButton: false,
                            timer: 1800,
                            timerProgressBar: true,

                        });
                        this.$router.push('/Customer');
                    })
                    .catch(error => {
                        console.log(error)
                        this.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Server Error',
                                text: error,
                            });

                        this.loading = false
                    })
                    .finally(() => this.loading = false)
            }
        }
        ,
        mounted: function () {
            //es;int-disable-line
            if (this.$route.query.data == '00000000-0000-0000-0000-000000000000' || this.$route.query.data == undefined || this.$route.query.data == '') {
                this.GetAutoCodeGenerator();
                this.Customer.registrationDate = moment().format('llll');
                if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {
                    this.Customer.paymentTerms = 'Cash';
                }
                else {
                    this.Customer.paymentTerms = 'آجل';
                }
                
            }
            if (this.$route.query.data != undefined) {
                this.Customer = this.$route.query.data;


            }
            this.daterander++;


        }
    })

</script>