<template>
    <div class="row " v-bind:style="$i18n.locale == 'ar' ? languageChange('en') : languageChange('ar')" v-if="isValid('CanAddSalaryTaxSlab') || isValid('CanEditSalaryTaxSlab')">
        <div class="col-md-12 ml-auto mr-auto" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <div class="card">
                <div>
                    <div class="card-header">
                        <h5 class="modal-title DayHeading" v-if="salaryTaxSlab.id=='00000000-0000-0000-0000-000000000000'" id="myModalLabel" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">{{ $t('AddSalaryTaxSlab.AddSalaryTaxSlab') }}</h5>
                        <h5 class="modal-title DayHeading" v-else id="myModalLabel" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">{{ $t('AddSalaryTaxSlab.UpdateSalaryTaxSlab') }}</h5>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="form-group col-xs-12 col-sm-6 col-md-4 col-lg-3">
                                <label class="text  font-weight-bolder"> {{ $t('AddSalaryTaxSlab.FromDate') }}: </label>
                                <datepicker v-model="salaryTaxSlab.fromDate" />
                            </div>
                            <div class="form-group col-xs-12 col-sm-6 col-md-4 col-lg-3">
                                <label class="text  font-weight-bolder">  {{ $t('AddSalaryTaxSlab.ToDate') }}: </label>
                                <datepicker v-model="salaryTaxSlab.toDate" />
                            </div>
                            <div class="form-group col-xs-12 col-sm-6 col-md-4 col-lg-6 text-right">
                                <button class="btn btn-primary btn-round mt-4" v-bind:disabled="isValueValid" v-on:click="AddTaxSlab()"> {{ $t('AddSalaryTaxSlab.Add') }}  </button>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 form-group">
                                <label class="text  font-weight-bolder">{{ $t('AddSalaryTaxSlab.IncomeSlabFrom') }} </label>
                                <input class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="salaryTaxSlab.incomeFrom" type="number" @click="$event.target.select()" />
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 form-group">
                                <label class="text  font-weight-bolder">{{ $t('AddSalaryTaxSlab.IncomeSlabTo') }} </label>
                                <input class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="salaryTaxSlab.incomeTo" type="number" @click="$event.target.select()" />
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 form-group">
                                <label class="text  font-weight-bolder">{{ $t('AddSalaryTaxSlab.FixTax') }} </label>
                                <input class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="salaryTaxSlab.fixedTax" type="number" @click="$event.target.select()" />
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 form-group">
                                <label class="text  font-weight-bolder">{{ $t('AddSalaryTaxSlab.Rate') }} </label>
                                <input class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="salaryTaxSlab.rate" type="number" @click="$event.target.select()" />
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <table class="table add_table_list_bg" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <thead v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                        <tr>
                                            <th style="width:5%;">#</th>

                                            <th style="width:20%;text-align:center;">
                                                {{ $t('AddSalaryTaxSlab.IncomeSlabFrom') }} 
                                            </th>
                                            <th style="width:20%;text-align:center;">
                                                {{ $t('AddSalaryTaxSlab.IncomeSlabTo') }} 
                                            </th>
                                            <th style="width:20%;text-align:center;">
                                                {{ $t('AddSalaryTaxSlab.FixTax') }} 
                                            </th>
                                            <th style="width:20%;text-align:center;">
                                                {{ $t('AddSalaryTaxSlab.Rate') }} 
                                            </th>
                                            <th style="width:5%;" class="text-center">
                                                {{ $t('AddSalaryTaxSlab.Action') }} 
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                        <tr v-for="(slab,index) in salaryTaxSlab.salaryTaxSlabList" v-bind:key="slab.id">
                                            <td>
                                                {{index+1}}
                                            </td>

                                            <td>                                                
                                                <input type="number" v-model="slab.incomeFrom"
                                                       @focus="$event.target.select()"
                                                       class="form-control input-border text-center tableHoverOn" />
                                            </td>
                                            <td>
                                                <input type="number" v-model="slab.incomeTo"
                                                       @focus="$event.target.select()"
                                                       class="form-control input-border text-center tableHoverOn" />
                                            </td>
                                            <td>
                                                <input type="number" v-model="slab.fixedTax"
                                                       @focus="$event.target.select()"
                                                       class="form-control input-border text-center tableHoverOn" />
                                            </td>
                                            <td>
                                                <input type="number" v-model="slab.rate"
                                                       @focus="$event.target.select()"
                                                       class="form-control input-border text-center tableHoverOn" />
                                            </td>
                                            <td class="text-center">
                                                <a href="javascript:void(0)" class="btn btn-danger btn-sm btn-icon btn-round" v-on:click="RemoveTaxSlab(index)"><i class=" fa fa-trash"></i></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-sm-12 arabicLanguage" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <button type="button" class="btn btn-primary" v-bind:disabled="$v.salaryTaxSlab.$invalid" v-on:click="SaveSalaryTemplate" v-if="salaryTaxSlab.id=='00000000-0000-0000-0000-000000000000' && isValid('CanAddSalaryTaxSlab')"><i class="far fa-save"></i> {{ $t('AddSalaryTaxSlab.Save') }} </button>
                                <button type="button" class="btn btn-primary" v-bind:disabled="$v.salaryTaxSlab.$invalid" v-on:click="SaveSalaryTemplate" v-if="salaryTaxSlab.id!='00000000-0000-0000-0000-000000000000' && isValid('CanEditSalaryTaxSlab')"><i class="far fa-save"></i> {{ $t('AddSalaryTaxSlab.Update') }} </button>
                                <button type="button" class="btn btn-danger mr-1 ml-1" v-on:click="Close()">{{ $t('AddSalaryTaxSlab.Cancel') }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import 'vue-loading-overlay/dist/vue-loading.css';
    import { required } from 'vuelidate/lib/validators';
    import moment from 'moment'
    export default ({
        mixins: [clickMixin],

        data: function () {
            return {
                currency: '',
                arabic: '',
                english: '',
                salaryTaxSlab: {
                    id: '00000000-0000-0000-0000-000000000000',
                    fromDate: '',
                    toDate: '',
                    incomeFrom: 0,
                    incomeTo: 0,
                    fixedTax: 0,
                    rate: 0,
                    salaryTaxSlabList: [],
                },
                language: 'Nothing',
            }
        },
        validations: {
            salaryTaxSlab:
            {
                fromDate: {
                    required
                },
                toDate: {
                    required
                },

            },

        },
        computed: {
            isValueValid: function () {
                if (this.salaryTaxSlab.incomeFrom >= 0 && this.salaryTaxSlab.incomeTo >= 0 && this.salaryTaxSlab.fixedTax >= 0 && this.salaryTaxSlab.rate >= 0) {
                    return false;
                }

                return true;
            },

        },

        methods: {
            AddTaxSlab: function () {
                this.salaryTaxSlab.incomeFrom = this.salaryTaxSlab.incomeFrom == '' ? 0 : this.salaryTaxSlab.incomeFrom;
                this.salaryTaxSlab.incomeTo = this.salaryTaxSlab.incomeTo == '' ? 0 : this.salaryTaxSlab.incomeTo;
                this.salaryTaxSlab.fixedTax = this.salaryTaxSlab.fixedTax == '' ? 0 : this.salaryTaxSlab.fixedTax;
                this.salaryTaxSlab.rate = this.salaryTaxSlab.rate == '' ? 0 : this.salaryTaxSlab.rate;

                this.salaryTaxSlab.salaryTaxSlabList.push({ incomeFrom: this.salaryTaxSlab.incomeFrom, incomeTo: this.salaryTaxSlab.incomeTo, fixedTax: this.salaryTaxSlab.fixedTax, rate: this.salaryTaxSlab.rate });
                this.salaryTaxSlab.incomeFrom = 0;
                this.salaryTaxSlab.incomeTo = 0;
                this.salaryTaxSlab.fixedTax = 0;
                this.salaryTaxSlab.rate = 0;
            },

            roundValua: function (x) {
                return parseFloat(x).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
            },

            RemoveTaxSlab: function (index) {
                this.salaryTaxSlab.salaryTaxSlabList.splice(index, 1);
            },


            languageChange: function (lan) {

                if (this.language == lan) {
                    if (this.salaryTemplate.id == '00000000-0000-0000-0000-000000000000') {

                        var getLocale = this.$i18n.locale;
                        this.language = getLocale;

                        this.$router.go('/AddSalaryTaxSlab');
                    }
                    else {
                        this.$swal({
                            title: 'Error!',
                            text: 'You cannot Change language during update, otherwise your current page data will be lose',
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            icon: 'error',
                            timer: 4000,
                            timerProgressBar: true,
                        });
                    }
                }
            },
            Close: function () {
                this.$router.push('/SalaryTaxSlab');
            },
            SaveSalaryTemplate: function () {
                
                this.loading = true;
                var root = this;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https
                    .post('/Payroll/SaveSalaryTaxSlab', root.salaryTaxSlab, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {

                        if (response.data.isSuccess) {
                            root.loading = false

                            root.$swal({
                                title: "Saved!",
                                text: "Saved Sucessfully",
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonStyling: false,
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,

                            }).then(function (ok) {
                                if (ok != null) {
                                    root.$router.push('/SalaryTaxSlab');
                                }
                            });
                        }

                        else {
                            root.$swal({
                                title: 'Error!',
                                text: response.data.message.isAddUpdate,
                                type: 'error',
                                confirmButtonClass: "btn btn-danger",
                                icon: 'error',
                                timer: 1500,
                                timerProgressBar: true,
                            });
                        }

                    })
                    .catch(error => {
                        console.log(error)
                        this.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Something Went Wrong!',
                                text: error,
                                showConfirmButton: false,
                                timer: 1000,
                                timerProgressBar: true,

                            });

                        this.loading = false
                    })
                    .finally(() => this.loading = false)
            }
        },
        created: function () {
            this.$emit('input', this.$route.name);

            if (this.$route.query.data != undefined) {
                var data = this.$route.query.data;
                this.salaryTaxSlab.id = data.id;
                this.salaryTaxSlab.fromDate = data.fromDate;
                this.salaryTaxSlab.toDate = data.toDate;
                this.salaryTaxSlab.salaryTaxSlabList = data.salaryTaxSlabList;
            }
            else {
                this.salaryTaxSlab.fromDate = moment().startOf('year').format('llll');
                this.salaryTaxSlab.toDate = moment().endOf('year').format('llll');
            }
        },
        mounted: function () {
            this.currency = localStorage.getItem('currency');
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.language = this.$i18n.locale;            
        }
    })
</script>
<style scoped>
    .checkBoxHeight {
        width: 20px;
        height: 30px;
    }

    .input-group-append .input-group-text, .input-group-prepend .input-group-text {
        background-color: #e3ebf1;
        border: 1px solid #e3ebf1;
        color: #000000;
    }

    .input-group .form-control {
        border-left: 1px solid #e3ebf1;
    }

        .input-group .form-control:focus {
            border-left: 1px solid #3178F6;
        }

    .input-group-text {
        border-radius: 0;
    }
</style>