<template>

    <div class="row" v-if="isValid('CanViewDenomination')">
        <div class="col-lg-12 col-sm-12 ml-auto mr-auto">
            <div class="card " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                <div class="BorderBottom ml-4 mr-4 mt-3 mb-3">
                    <span class=" DayHeading">{{ $t('DenominationSetup.DenominationSetup') }}</span>
                </div>


                <div class="card-body">
                    <div class="col-lg-12">

                        <div class="row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                <div class="form-group">
                                    <label>{{ $t('DenominationSetup.SearchByNumber') }}</label>
                                    <div>
                                        <input type="text" class="form-control search_input" v-model="searchQuery" name="search" id="search" :placeholder="$t('DenominationSetup.Search')" />
                                        <span class="fas fa-search search_icon"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'" style="margin-top:18px;">
                                <a v-if="isValid('CanAddDenomination')" href="javascript:void(0)" class="btn btn-primary" v-on:click="AddDenominationSetup"><i class="fa fa-plus"></i>  {{ $t('DenominationSetup.AddNew') }}</a>
                                <router-link :to="'/FinancialSetup'">
                                    <a href="javascript:void(0)" class="btn btn-outline-danger ">  <i class="fas fa-arrow-circle-left fa-lg"></i> </a>
                                </router-link>
                            </div>

                        </div>

                        <div class="mt-2">
                            <div class=" table-responsive">
                                <table class="table " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <thead class="m-0">
                                        <tr>
                                            <th>
                                                #
                                            </th>
                                            <th class="text-center">
                                                {{ $t('DenominationSetup.DenominationNumber') }}
                                            </th>
                                            <th class="text-center">
                                                {{ $t('DenominationSetup.Status') }}
                                            </th>


                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(details,index) in resultQuery" v-bind:key="details.id">
                                            <td>
                                                {{index+1}}
                                            </td>
                                            <td v-if="isValid('CanEditDenomination')" class="text-center">
                                                <strong>
                                                    <a href="javascript:void(0)" v-on:click="EditDenominationSetup(details.id)">{{details.number}}</a>
                                                </strong>
                                            </td>
                                            <td v-else class="text-center">{{details.number}}</td>
                                            <td class="text-center">{{details.isActive==true?$t('DenominationSetup.Active'):$t('DenominationSetup.De-Active')}}</td>


                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <denominationSetupmodel :denominationSetup="newDenominationSetup"
                                :show="show"
                                v-if="show"
                                @close="show = false"
                                :type="type" />
    </div>
    <div v-else> <acessdenied></acessdenied></div>

</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'

    export default {
        mixins: [clickMixin],
        name: 'DenominationSetup',
        data: function () {
            return {
                arabic: '',
                english: '',
                show: false,
                searchQuery: '',
                denominationSetuplist: [

                ],
                newDenominationSetup: {
                    id: '00000000-0000-0000-0000-000000000000',
                    number: '',
                    isActive: true
                },
                type: '',

            }
        },
        computed: {
            resultQuery: function () {
                var root = this;
                if (this.searchQuery) {

                    return this.denominationSetuplist.filter((cur) => {
                        return root.searchQuery.toLowerCase().split(' ').every(v => cur.number.toString().includes(v))
                    })
                } else {
                    return root.denominationSetuplist;
                }
            },
        },
        methods: {
            AddDenominationSetup: function () {
                this.newDenominationSetup = {
                    id: '00000000-0000-0000-0000-000000000000',
                    number: '',
                    isActive: true
                };
                this.show = !this.show;
                this.type = "Add";
            },
            GetDenominationSetupData: function () {
                var root = this;
                var url = '/Product/DenominationSetupList';
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get(url, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {

                        root.$store.dispatch('GetDenominationSetupList', response.data.denominationSetups);
                        root.denominationSetuplist = response.data.denominationSetups;
                    }
                });
            },
            EditDenominationSetup: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Product/DenominationSetupDetail?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data) {
                            root.newDenominationSetup.id = response.data.id;
                            root.newDenominationSetup.number = response.data.number;
                            root.newDenominationSetup.isActive = response.data.isActive;
                            root.show = !root.show;
                            root.type = "Edit"
                        } else {
                            console.log("error: something wrong from db.");
                        }
                    },
                        function (error) {
                            this.loading = false;
                            console.log(error);
                        });

            }
        },
        created: function () {
            this.$emit('input', this.$route.name);
        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.GetDenominationSetupData();
        }
    }
</script>