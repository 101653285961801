<template>
    <modal :show="show" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-if=" isValid('CanAddOrigin') || isValid('CanEditOrigin') ">

        <div style="margin-bottom:0px" class="card">
            <div class="card-body">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="modal-header" v-if="type=='Edit'">

                            <h5 class="modal-title DayHeading" id="myModalLabel">{{ $t('AddOrigin.UpdateProductOrigin') }}</h5>

                        </div>
                        <div class="modal-header" v-else>

                            <h5 class="modal-title DayHeading" id="myModalLabel">{{ $t('AddOrigin.AddProductOrigin') }} </h5>

                        </div>

                        <div class="card-body">
                            <div class="row ">
                                <div :key="render" class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.origin.code.$error}">
                                    <label class="text  font-weight-bolder">  {{ $t('AddOrigin.Code') }}:<span class="LabelColour"> *</span></label>
                                    <input disabled class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.origin.code.$model" type="text" />
                                    <span v-if="$v.origin.code.$error" class="error">
                                        <span v-if="!$v.origin.code.maxLength"> {{ $t('AddOrigin.CodeLength') }}</span>
                                    </span>
                                </div>
                                <div v-if="english=='true'" class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.origin.name.$error}">
                                    <label class="text  font-weight-bolder"> {{ $t('AddOrigin.OriginName') |englishLanguage}}: <span class="LabelColour"> *</span></label>
                                    <input class="form-control" v-model="$v.origin.name.$model" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" type="text" />
                                    <span v-if="$v.origin.name.$error" class="error">
                                        <span v-if="!$v.origin.name.required"> {{ $t('AddOrigin.NameRequired') }}</span>
                                        <span v-if="!$v.origin.name.maxLength"> {{ $t('AddOrigin.NameLength') }}</span>
                                    </span>
                                </div>
                                <div v-if="isOtherLang()" class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.origin.nameArabic.$error} ">
                                    <label class="text  font-weight-bolder"> {{ $t('AddOrigin.OriginNameAr') |arabicLanguage}}: <span class="LabelColour"> *</span></label>
                                    <input class="form-control "  v-bind:class="isLeftToRight() ? 'text-left' : 'arabicLanguage'"  v-model="$v.origin.nameArabic.$model" type="text" />
                                    <span v-if="$v.origin.nameArabic.$error" class="error">
                                        <span v-if="!$v.origin.nameArabic.required"> {{ $t('AddOrigin.NameRequired') }}</span>
                                        <span v-if="!$v.origin.nameArabic.maxLength">{{ $t('AddOrigin.NameLength') }}</span>
                                    </span>
                                </div>


                                <div class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.origin.description.$error}">
                                    <label class="text  font-weight-bolder">  {{ $t('AddOrigin.Description') }}: </label>
                                    <textarea class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.origin.description.$model" type="text" />
                                    <span v-if="$v.origin.description.$error" class="error"> {{ $t('AddOrigin.descriptionLength') }}</span>
                                </div>
                                <div class="form-group col-md-12">
                                    <label style="margin: 7px;"> {{ $t('AddOrigin.Active') }}</label> <br />
                                    <div class="bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-animate" v-bind:class="{'bootstrap-switch-on': origin.isActive, 'bootstrap-switch-off': !origin.isActive}" v-on:click="origin.isActive = !origin.isActive" style="width: 72px;">
                                        <div class="bootstrap-switch-container" style="width: 122px; margin-left: 0px;">
                                            <span class="bootstrap-switch-handle-on bootstrap-switch-success" style="width: 50px;">
                                                <i class="nc-icon nc-check-2"></i>
                                            </span>
                                            <span class="bootstrap-switch-label" style="width: 30px;">&nbsp;</span>
                                            <span class="bootstrap-switch-handle-off bootstrap-switch-success" style="width: 50px;">
                                                <i class="nc-icon nc-simple-remove"></i>
                                            </span>
                                            <input class="bootstrap-switch" type="checkbox" data-toggle="switch" checked="" data-on-label="<i class='nc-icon nc-check-2'></i>" data-off-label="<i class='nc-icon nc-simple-remove'></i>" data-on-color="success" data-off-color="success">
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div v-if="!loading">
                            <div class="modal-footer justify-content-right" v-if="type=='Edit' && isValid('CanEditOrigin')">
                                <button type="button" class="btn btn-primary  " v-on:click="SaveOrigin" v-bind:disabled="$v.origin.$invalid">  {{ $t('AddOrigin.btnUpdate') }} </button>
                                <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()"> {{ $t('AddOrigin.btnClear') }}</button>
                            </div>
                            <div class="modal-footer justify-content-right" v-if="type!='Edit' && isValid('CanAddOrigin')">
                                <button type="button" class="btn btn-primary  " v-on:click="SaveOrigin" v-bind:disabled="$v.origin.$invalid">  {{ $t('AddOrigin.btnSave') }}</button>
                                <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()"> {{ $t('AddOrigin.btnClear') }}</button>
                            </div>
                        </div>
                        <div v-else>
                            <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </modal>
    <acessdenied v-else :model=true></acessdenied>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import { requiredIf, maxLength } from "vuelidate/lib/validators"
    export default {
        mixins: [clickMixin],
        props: ['show', 'origin', 'type'],
        components: {
            Loading
        },
        data: function () {
            return {
                render: 0,
                arabic: '',
                english: '',
                loading: false,
            }
        },
        validations: {
            origin: {
                name: {                    
                    maxLength: maxLength(250)
                },
                code: {
                    maxLength: maxLength(30)
                },
                nameArabic: {
                    required: requiredIf((x) => {
                        if (x.name == '' || x.name == null)
                            return true;
                        return false;
                    }),
                    maxLength: maxLength(250)
                },
                description: {
                    maxLength: maxLength(500)
                }
            }
        },
        methods: {
            close: function () {
                this.$emit('close');
            },
            GetAutoCodeGenerator: function () {

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.get('/Product/OriginCode', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                    if (response.data != null) {
                        root.origin.code = response.data;
                        root.render++;
                    }
                });
            },
            SaveOrigin: function () {
                var root = this;
                var token = '';
                this.loading = true;
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.post('/Product/SaveOrigin', this.origin, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                    if (response.data.isSuccess == true) {
                        if (root.type != "Edit") {

                            root.$swal({
                                title: "Saved!",
                                text: "Saved Successfully!",
                                type: 'success',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1500,
                                timerProgressBar: true,
                            });
                            root.close();
                        }
                        else {

                            root.$swal({
                                title: "Update!",
                                text: "Update Successfully!",
                                type: 'success',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1500,
                                timerProgressBar: true,
                            });
                            root.close();
                        }
                    }
                    else {
                        root.$swal({
                            title: "Error!",
                            text: "Your Origin Name  Already Exist!",
                            type: 'error',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 1500,
                            timerProgressBar: true,
                        });
                    }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Something Went Wrong!',
                                text: error.response.data,

                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false
                    })
                    .finally(() => root.loading = false)
            }
        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            if (this.origin.id == '00000000-0000-0000-0000-000000000000' || this.origin.id == undefined || this.origin.id == '')
                this.GetAutoCodeGenerator();

        }
    }
</script>
