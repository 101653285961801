<template>
    <modal :show="show"  v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">

        <div style="margin-bottom:0px" class="card">
            <div class="card-body">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">                      
                        <div class="modal-header" >
                            <h5 class="modal-title" id="myModalLabel">{{ $t('WalkCustomerModel.Customer') }} </h5>
                        </div>

                        <div class="card-body">
                            <div class="row ">
                                <div class="form-group has-label col-sm-12 ">
                                    <label class="text  font-weight-bolder"> {{ $t('WalkCustomerModel.Search') }}:</label>
                                    <input class="form-control" v-on:input="cashCustomerSearch(search)" v-model="search" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" type="text" />
                                </div>

                                <div class="form-group has-label col-sm-12 ">
                                    <label class="text  font-weight-bolder"> {{ $t('WalkCustomerModel.Customer') }}:</label>
                                    <input class="form-control" v-bind:disabled="walkIn" v-model="sale.cashCustomer" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" type="text" />

                                </div>
                                <div class="form-group has-label col-sm-6 ">
                                    <label class="text  font-weight-bolder"> {{ $t('WalkCustomerModel.CustomerId') }}:</label>
                                    <input class="form-control" v-model="sale.cashCustomerId" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" type="text" />
                                </div>
                                <div class="form-group has-label col-sm-6 ">
                                    <label class="text  font-weight-bolder"> {{ $t('WalkCustomerModel.Mobile') }}:</label>
                                    <input class="form-control" v-bind:disabled="walkIn" v-model="sale.mobile" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" type="text" />

                                </div>
                                <div class="form-group has-label col-sm-12 ">
                                    <label class="text  font-weight-bolder"> {{ $t('WalkCustomerModel.RegisterUser_EmailID') }}:</label>
                                    <input class="form-control" v-bind:disabled="walkIn" v-model="sale.email" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" type="text" />
                                </div>
                                <div class="form-group has-label col-sm-12 ">
                                    <label class="text  font-weight-bolder"> {{ $t('WalkCustomerModel.PreviousCustomer') }}: </label>
                                    <customerdropdown v-model="sale.customerId" v-bind:values="sale.customerId" v-on:input="DisableWalkIn(sale.customerId)"></customerdropdown>
                                </div>
                            </div>
                        </div>
                      
                        <div class="modal-footer justify-content-right" v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'direction: rtl'" >
                            <button type="button" class="btn btn-primary  " v-on:click="SaveOrigin">  {{ $t('WalkCustomerModel.Select') }}</button>
                            <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()"> {{ $t('WalkCustomerModel.Close') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </modal>
   
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        mixins: [clickMixin],
        props: ['show','sale' ],
        data: function () {
            return {
                search:'',
                walkIn:false,
                save: false,
            }
        },
        methods: {
            cashCustomerSearch: function (value) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                if (value!='') {
                    this.$https.get('/Sale/SearchCashCustomer?search=' + value, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                        if (response.data != null) {
                            root.sale.cashCustomer = response.data.name;
                            root.sale.mobile = response.data.mobile;
                            root.sale.email = response.data.email;
                            root.sale.cashCustomerId = response.data.customerId;
                        }
                    })
                }
                else {
                    root.sale.cashCustomer = 'Walk-In';
                    root.sale.mobile = '';
                    root.sale.cashCustomerId = '';
                    root.sale.email = '';
                }
                
            },
            close: function () {
                if (this.sale.cashCustomer === '') {
                    this.sale.cashCustomer = 'Walk-In';
                }
                if (this.save) {
                    this.$emit('close', this.sale);
                }
                else {
                    this.$emit('close', false);
                    
                }
            },
            DisableWalkIn: function (x) {
                
                if (x != null || x != undefined) {
                    this.walkIn = true;
                    this.sale.mobile = '';
                    this.sale.email = '';
                    this.sale.cashCustomer = '';
                    this.sale.cashCustomerId = '';
                    this.search = '';
                }
                else {
                    this.sale.cashCustomer = 'Walk-In';
                    this.walkIn = false;
                }
            },
            SaveOrigin: function () {
                if (this.sale.cashCustomer === '') {
                    this.sale.cashCustomer = 'Walk-In';
                }
                this.save = true;
                var root = this;
                
                root.close();
            }
        },
        mounted: function () {
            
            
          
                this.saleTemp = this.sale;

           
            if (this.sale.customerId) {
                this.walkIn = true;
            }
          

        }
    }
</script>
