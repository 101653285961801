<template>
    <modal :show="show" v-if=" isValid('CanAddPaymentOption') || isValid('CanEditPaymentOption') ">

        <div style="margin-bottom:0px" class="card" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" >
            <div class="card-body">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="modal-header" v-if="type=='Edit'">

                            <h5 class="modal-title DayHeading" id="myModalLabel">    {{ $t('AddPaymentOptions.UpdatePaymentOptions') }}</h5>

                        </div>
                        <div class="modal-header" v-else>

                            <h5 class="modal-title DayHeading" id="myModalLabel">    {{ $t('AddPaymentOptions.AddPaymentOptions') }}</h5>

                        </div>
                        <div class="text-left">
                            <div class="card-body">
                                <div class="row ">
                                    <div class="col-sm-12" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" v-if="english=='true'">
                                        <label>{{ $t('AddPaymentOptions.NameEn') |englishLanguage}}:<span class="LabelColour"> *</span></label>
                                        <input class="form-control" v-model="$v.payment.name.$model" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" />
                                        <span v-if="$v.payment.name.$error" class="error text-danger">
                                            <span v-if="!$v.payment.name.required">{{ $t('AddPaymentOptions.NameRequired') }}</span>
                                            <span v-if="!$v.payment.name.maxLength">{{ $t('AddPaymentOptions.NameLength') }}</span>
                                        </span>
                                    </div>
                                    <div class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.payment.nameArabic.$error} && ($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" v-if="isOtherLang()">
                                        <label class="text  font-weight-bolder"> {{ $t('AddPaymentOptions.NameAr') |arabicLanguage}}: <span class="LabelColour"> *</span></label>
                                        <input class="form-control "  v-bind:class="isLeftToRight() ? 'text-left' : 'arabicLanguage'"  v-model="$v.payment.nameArabic.$model" type="text" />
                                        <span v-if="$v.payment.nameArabic.$error" class="error">
                                            <span v-if="!$v.payment.nameArabic.required"> {{ $t('AddPaymentOptions.NameRequired') }}</span>
                                            <span v-if="!$v.payment.nameArabic.maxLength">{{ $t('AddPaymentOptions.NameLength') }}</span>
                                        </span>
                                    </div>
                                    <div class="col-sm-12">
                                        <div :key="renderImg">
                                            <input ref="imgupload" 
                                                   type="file" 
                                                   id="file-input"
                                                   @change="uploadImage()" 
                                                   accept="image/*"
                                                   name="image" 
                                                   v-if="!((imageSrc == '' && payment.image!='') || (imageSrc != '' && payment.image=='') || (imageSrc != '' && payment.image!=''))"
                                                   style="opacity:1;padding:25px">
                                           
                                            <div class="float-right" v-if="imageSrc != ''">
                                                <img :src="imageSrc" style="width: 100px;" />
                                            </div>
                                            <div v-else class="float-right">
                                                <span v-if="payment.image!=null">
                                                    <productimage v-bind:path="payment.image" />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="text-right">
                                            <button class="btn btn-danger  btn-sm" v-if="imageSrc != '' || payment.image!=''" v-on:click="removeImage()">Remove</button>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <label style="margin: 7px;">{{ $t('AddPaymentOptions.Active') }}</label> <br />
                                        <div class="bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-animate" v-bind:class="{'bootstrap-switch-on': payment.isActive, 'bootstrap-switch-off': !payment.isActive}" v-on:click="payment.isActive = !payment.isActive" style="width: 72px;">
                                            <div class="bootstrap-switch-container" style="width: 122px; margin-left: 0px;">
                                                <span class="bootstrap-switch-handle-on bootstrap-switch-success" style="width: 50px;">
                                                    <i class="nc-icon nc-check-2"></i>
                                                </span>
                                                <span class="bootstrap-switch-label" style="width: 30px;">&nbsp;</span>
                                                <span class="bootstrap-switch-handle-off bootstrap-switch-success" style="width: 50px;">
                                                    <i class="nc-icon nc-simple-remove"></i>
                                                </span>
                                                <input class="bootstrap-switch" type="checkbox" data-toggle="switch" checked="" data-on-label="<i class='nc-icon nc-check-2'></i>" data-off-label="<i class='nc-icon nc-simple-remove'></i>" data-on-color="success" data-off-color="success">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer justify-content-right" v-if="type=='Edit' && isValid('CanEditPaymentOption')">
                            <button type="button" class="btn btn-primary  " v-on:click="SavePaymentOptions" v-bind:disabled="$v.payment.$invalid"> {{ $t('AddPaymentOptions.btnUpdate') }}</button>
                            <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{ $t('AddPaymentOptions.btnClear') }}</button>
                        </div>
                        <div class="modal-footer justify-content-right" v-if="type!='Edit' && isValid('CanAddPaymentOption')">
                            <button type="button" class="btn btn-primary  " v-on:click="SavePaymentOptions" v-bind:disabled="$v.payment.$invalid"> {{ $t('AddPaymentOptions.btnSave') }}</button>
                            <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{ $t('AddPaymentOptions.btnClear') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </modal>
    <acessdenied v-else :model=true></acessdenied>
</template>

<script>
    import clickMixin from '@/Mixins/clickMixin'
    import {  requiredIf, maxLength } from "vuelidate/lib/validators"
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default ({
        mixins: [clickMixin],
        props: ['show', 'payment', 'type'],
        data: function () {
            return {
                render: 0,
                imageSrc: '',
                arabic: '',
                english: '',
                renderImg: 0
            }
        },
        validations: {
            payment:
            {
                name:
                {
                    maxLength: maxLength(50)
                },
                nameArabic:
                {
                    required: requiredIf((x) => {
                        if (x.name == '' || x.name == null)
                            return true;
                        return false;
                    }),
                    maxLength: maxLength(50)
                }
            }
        },
        methods: {
            removeImage: function () {
                this.imageSrc = '';
                this.payment.image = '';
                this.renderImg++;

            },
            uploadImage: function () {
                var root = this;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                var file = this.$refs.imgupload.files;

                var fileData = new FormData();

                for (var k = 0; k < file.length; k++) {
                    fileData.append("files", file[k]);
                }

                this.imageSrc = URL.createObjectURL(this.$refs.imgupload.files[0]);

                root.$https.post('/Company/UploadFilesAsync', fileData, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {

                            root.payment.image = response.data;
                        }
                    },
                        function () {
                            this.loading = false;
                            root.$swal({
                                title: "Error!",
                                text: "Something went wrong",
                                type: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                        });
            },

            close: function () {
                this.$emit('close');
            },
            SavePaymentOptions: function () {
                var root = this;
                
                var url = '/Product/SavePaymentOptions?image=' + root.payment.image;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https
                    .post(url, root.payment, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data.isSuccess == true) {

                            if (root.type != "Edit") {

                                root.$swal({
                                    title: "Saved!",
                                    text: "Your Payment Option " + response.data.paymentOptions.name + " has been created!",
                                    type: 'success',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 1500,
                                    timerProgressBar: true,
                                });
                                root.close();
                                root.$emit('RefreshList',true);
                            }
                            else {
                                
                                root.$swal({
                                    title: "Update!",
                                    text: "Your Payment Option " + response.data.paymentOptions.name + " has been updated!",
                                    type: 'success',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 1500,
                                    timerProgressBar: true,
                                });
                                root.close();
                                root.$emit('RefreshList', true);

                            }
                        }
                        else {
                            root.$swal({
                                title: "Error!",
                                text: "Your Payment Option  Already Exist!",
                                type: 'error',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 800,
                                timerProgressBar: true,
                            });
                        }
                    });
            }
        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            if (this.$route.query.data != undefined) {
                this.paymentOptions = this.$route.query.data;
            }
        }
    })

</script>