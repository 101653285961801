<template>
    <modal :show="show" v-if="isValid('CanAddContribution') || isValid('CanEditContribution')">

        <div style="margin-bottom:0px" class="card" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <div class="card-body">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="modal-header" v-if="type=='Edit'">
                            <h5 class="modal-title DayHeading" id="myModalLabel"> {{ $t('AddContribution.UpdateContribution') }}</h5>
                        </div>

                        <div class="modal-header" v-else>
                            <h5 class="modal-title DayHeading" id="myModalLabel"> {{ $t('AddContribution.AddContribution') }}</h5>
                        </div>


                        <div class="card-body" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                            <div class="row ">
                                <div class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.contribution.code.$error}">
                                    <label class="text  font-weight-bolder"> {{ $t('AddContribution.Code') }}:<span class="LabelColour"> *</span></label>
                                    <input disabled class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.contribution.code.$model" type="text" />
                                </div>

                                <div v-if="english=='true'" class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.contribution.nameInPayslip.$error}">
                                    <label class="text  font-weight-bolder"> {{ $t('AddContribution.NameInPayslipEnglish')}}: <span class="LabelColour"> *</span></label>
                                    <input class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.contribution.nameInPayslip.$model" type="text" />
                                </div>

                                <div v-if="isOtherLang()" class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.contribution.nameInPayslipArabic.$error}">
                                    <label class="text  font-weight-bolder">{{ $t('AddContribution.NameInPayslipArabic')}}: <span class="LabelColour"> *</span></label>
                                    <input class="form-control "  v-bind:class="isLeftToRight() ? 'text-left' : 'arabicLanguage'" v-model="$v.contribution.nameInPayslipArabic.$model" type="text" />
                                </div>

                                <div class="form-group has-label col-sm-12 ">
                                    <label>{{ $t('AddContribution.CalculateAmount') }} :<span class="LabelColour"> *</span></label>
                                    <multiselect :options="calculateAmountOptions" v-model="contribution.amountType" :show-labels="false" v-bind:placeholder="$t('AddContribution.SelectType')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    </multiselect>
                                </div>



                                <div class="form-group has-label col-sm-8">
                                    <label class="text  font-weight-bolder">{{ $t('AddContribution.AmountPercentage')}}: <span class="LabelColour"> *</span></label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? 'padding: 5px 15px 5px 5px;' : 'padding: 5px 5px 5px 15px;' "><i v-if="contribution.amountType=='% of Salary' || contribution.amountType=='٪ من الراتب'" class="fa fa-percent"></i> <i v-else>{{currency}}</i></span>
                                        </div>
                                        <input type="number" @focus="$event.target.select()" class="form-control amount_field" v-model="contribution.amount" />
                                    </div>
                                </div>

                                <div class="form-group col-sm-4">
                                    <label style="margin: 7px;">{{ $t('AddContribution.Status') }}</label> <br />
                                    <div class="bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-animate" v-bind:class="{'bootstrap-switch-on': contribution.active, 'bootstrap-switch-off': !contribution.active}" v-on:click="contribution.active = !contribution.active" style="width: 72px;">
                                        <div class="bootstrap-switch-container" style="width: 122px; margin-left: 0px;">
                                            <span class="bootstrap-switch-handle-on bootstrap-switch-success" style="width: 50px;">
                                                <i class="nc-icon nc-check-2"></i>
                                            </span>
                                            <span class="bootstrap-switch-label" style="width: 30px;">&nbsp;</span>
                                            <span class="bootstrap-switch-handle-off bootstrap-switch-success" style="width: 50px;">
                                                <i class="nc-icon nc-simple-remove"></i>
                                            </span>
                                            <input class="bootstrap-switch" type="checkbox" data-toggle="switch" checked="" data-on-label="<i class='nc-icon nc-check-2'></i>" data-off-label="<i class='nc-icon nc-simple-remove'></i>" data-on-color="success" data-off-color="success">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="!loading">
                            <div class="modal-footer justify-content-right" v-if="type=='Edit' && isValid('CanEditContribution')">
                                <button type="button" class="btn btn-primary  " v-on:click="SaveContribution" v-bind:disabled="$v.contribution.$invalid || ((contribution.amountType==='% of Salary' || contribution.amountType==='٪ من الراتب') && contribution.amount>100)"> {{ $t('AddContribution.Update') }}</button>
                                <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{ $t('AddContribution.Cancel') }}</button>
                            </div>
                            <div class="modal-footer justify-content-right" v-if="type!='Edit' && isValid('CanAddContribution')">
                                <button type="button" class="btn btn-primary  " v-on:click="SaveContribution" v-bind:disabled="$v.contribution.$invalid || ((contribution.amountType==='% of Salary' || contribution.amountType==='٪ من الراتب') && contribution.amount>100)"> {{ $t('AddContribution.Save') }}</button>
                                <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{ $t('AddContribution.Cancel') }}</button>
                            </div>
                        </div>
                        <div v-else>
                            <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
    <acessdenied v-else :model=true></acessdenied>
</template>

<script>
    import clickMixin from '@/Mixins/clickMixin'
    import 'vue-loading-overlay/dist/vue-loading.css';
    import { required,maxLength, requiredIf } from "vuelidate/lib/validators"
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import Multiselect from 'vue-multiselect'

    export default {
        mixins: [clickMixin],
        props: ['show', 'contribution', 'type'],
        components: {
            Loading,
            Multiselect
        },
        data: function () {
            return {
                currency: '',
                arabic: '',
                english: '',
                loading: false,
                calculateAmountOptions:[],
            }
        },
        validations: {
            contribution: {
                nameInPayslip: {
                    maxLength: maxLength(100)
                },
                code: {
                    maxLength: maxLength(30)
                },
                amount: {
                    required
                },
                amountType: {
                    required
                },
               
                nameInPayslipArabic: {
                    required: requiredIf((x) => {
                        if (x.nameInPayslip == '' || x.nameInPayslip == null)
                            return true;
                        return false;
                    }),
                    maxLength: maxLength(100)
                }
            }
        },
        methods: {
            close: function () {
                this.$emit('close');
            },
            GetAutoCodeGenerator: function () {

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.get('/Payroll/ContributionAutoGenerateNo', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                    if (response.data != null) {
                        root.contribution.code = response.data;
                    }
                });
            },
            SaveContribution: function () {
                var root = this;
                this.loading = true;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                

                if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {
                    if (this.contribution.amountType == '% of Salary') {
                        this.contribution.amountType = 1;
                    }
                    else if (this.contribution.amountType == 'Fixed') {
                        this.contribution.amountType = 2;
                    }

                   
                }
                else {
                    if (this.contribution.amountType == '٪ من الراتب') {
                        this.contribution.amountType = 1;
                    }
                    else if (this.contribution.amountType == 'مثبت') {
                        this.contribution.amountType = 2;
                    }
                  
                }

                this.$https.post('/Payroll/SaveContributionInformation', this.contribution, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data.isSuccess == true) {
                            if (root.type != "Edit") {

                                root.$swal({
                                    title: "Saved!",
                                    text: "Saved Successfully!",
                                    type: 'success',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 1500,
                                    timerProgressBar: true,
                                });

                                root.close();
                            }
                            else {

                                root.$swal({
                                    title: "Update!",
                                    text: "Update Successfully!",
                                    type: 'success',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 1500,
                                    timerProgressBar: true,
                                });
                                root.close();

                            }
                        }
                        else {
                            root.$swal({
                                title: "Error!",
                                text: "Your Brand Name  Already Exist!",
                                type: 'error',
                                icon: 'error',
                                showConfirmButton: false,
                                timer: 1500,
                                timerProgressBar: true,
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Something Went Wrong!',
                                text: error.response.data,

                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false
                    })
                    .finally(() => root.loading = false);
            }
        },
        created: function () {
            if (this.contribution.id == '00000000-0000-0000-0000-000000000000' || this.contribution.id == undefined || this.contribution.id == '')
                this.GetAutoCodeGenerator();
        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.currency = localStorage.getItem('currency');

            if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {
                this.calculateAmountOptions = ['% of Salary', 'Fixed'];
            }
            else {
                this.calculateAmountOptions = ['٪ من الراتب', 'مثبت'];
            }

        }
    }
</script>


<style scoped>
    .input-group-append .input-group-text, .input-group-prepend .input-group-text {
        background-color: #e3ebf1;
        border: 1px solid #e3ebf1;
        color: #000000;
    }
    .input-group .form-control {
        border-left: 1px solid #e3ebf1;
    }
    .input-group .form-control:focus {
        border-left: 1px solid #3178F6;
    }
    .input-group-text{
        border-radius:0;
    }
</style>