<template>
    <modal :show="show" v-if=" isValid('CanAddUnit') || isValid('CanEditUnit') ">

        <div style="margin-bottom:0px" class="card" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <div class="card-body">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="modal-header" v-if="type=='Edit'">

                            <h5 class="modal-title DayHeading" id="myModalLabel">
                                {{ $t('AddUnit.UpdateProductUnit') }}
                            </h5>

                        </div>
                        <div class="modal-header" v-else>

                            <h5 class="modal-title DayHeading" id="myModalLabel"> {{ $t('AddUnit.AddProductUnit') }}</h5>

                        </div>
                        <div >
                            <div class="card-body ">
                                <div class="row ">
                                    <div :key="render" class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.unit.code.$error} && ($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicfont'">
                                        <label class="text font-weight-bolder blackColor"> {{ $t('AddUnit.Code') }}:<span class="LabelColour"> *</span></label>
                                        <input disabled class="form-control" v-model="$v.unit.code.$model" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicfont'" type="text" />
                                        <span v-if="$v.unit.code.$error" class="error">
                                            <span v-if="!$v.unit.code.maxLength">{{ $t('AddUnit.CodeLength') }}</span>
                                        </span>
                                    </div>
                                    <div v-if="english=='true'" class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.unit.name.$error} && ($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicfont'">
                                        <label class="text font-weight-bolder">  {{ $t('AddUnit.UnitName') |englishLanguage}}: <span class="LabelColour"> *</span></label>
                                        <input class="form-control" v-model="$v.unit.name.$model" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicfont'" type="text" />
                                        <span v-if="$v.unit.name.$error" class="error">
                                            <span v-if="!$v.unit.name.required">{{ $t('AddUnit.NameRequired') }}</span>
                                            <span v-if="!$v.unit.name.maxLength">{{ $t('AddUnitNameLength') }}</span>
                                        </span>
                                    </div>
                                    <div v-if="isOtherLang()" class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.unit.nameArabic.$error} && ($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicfont'">
                                        <label class="text  font-weight-bolder blackColor">  {{ $t('AddUnit.UnitNameAr') |arabicLanguage}}: <span class="LabelColour"> *</span></label>
                                        <input class="form-control "  v-bind:class="isLeftToRight() ? 'text-left' : 'arabicLanguage'" v-model="$v.unit.nameArabic.$model"  type="text" />
                                        <span v-if="$v.unit.nameArabic.$error" class="error">
                                            <span v-if="!$v.unit.nameArabic.required">{{ $t('AddUnit.NameRequired') }}</span>
                                            <span v-if="!$v.unit.nameArabic.maxLength">{{ $t('AddUnit.NameLength') }}</span>
                                        </span>
                                    </div>


                                    <div class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.unit.description.$error} && ($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicfont'">
                                        <label class="text  font-weight-bolder blackColor"> {{ $t('AddUnit.Description') }}: </label>
                                        <textarea class="form-control" v-model="$v.unit.description.$model" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicfont'" type="text" />
                                        <span v-if="$v.unit.description.$error" class="error">{{ $t('AddUnit.descriptionLength') }}</span>
                                    </div>
                                    <div class="form-group col-md-12" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicfont'">
                                        <label style="margin: 7px;">{{ $t('AddUnit.Active') }}</label> <br />
                                        <div class="bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-animate" v-bind:class="{'bootstrap-switch-on': unit.isActive, 'bootstrap-switch-off': !unit.isActive}" v-on:click="unit.isActive = !unit.isActive" style="width: 72px;">
                                            <div class="bootstrap-switch-container" style="width: 122px; margin-left: 0px;">
                                                <span class="bootstrap-switch-handle-on bootstrap-switch-success" style="width: 50px;">
                                                    <i class="nc-icon nc-check-2"></i>
                                                </span>
                                                <span class="bootstrap-switch-label" style="width: 30px;">&nbsp;</span>
                                                <span class="bootstrap-switch-handle-off bootstrap-switch-success" style="width: 50px;">
                                                    <i class="nc-icon nc-simple-remove"></i>
                                                </span>
                                                <input class="bootstrap-switch" type="checkbox" data-toggle="switch" checked="" data-on-label="<i class='nc-icon nc-check-2'></i>" data-off-label="<i class='nc-icon nc-simple-remove'></i>" data-on-color="success" data-off-color="success">
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div v-if="!loading">
                            <div class="modal-footer justify-content-right" v-if="type=='Edit' && isValid('CanEditUnit')">
                                <button type="button" class="btn btn-primary  " v-on:click="SaveUnit" v-bind:disabled="$v.unit.$invalid"> {{ $t('AddUnit.btnUpdate') }}</button>
                                <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{ $t('AddUnit.btnClear') }}</button>
                            </div>
                            <div class="modal-footer justify-content-right" v-if="type!='Edit' && isValid('CanAddUnit')">
                                <button type="button" class="btn btn-primary  " v-on:click="SaveUnit" v-bind:disabled="$v.unit.$invalid"> {{ $t('AddUnit.btnSave') }}</button>
                                <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{ $t('AddUnit.btnClear') }}</button>
                            </div>
                        </div>
                        <div v-else>
                            <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </modal>
    <acessdenied v-else :model=true></acessdenied>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import {maxLength, requiredIf } from "vuelidate/lib/validators"
    export default {
        mixins: [clickMixin],
        props: ['show', 'unit', 'type'],
        components: {
            Loading
        },
        data: function () {
            return {
                arabic: '',
                english: '',
                render: 0,
                loading: false,
            }
        },
        validations: {
            unit: {
                name: {                    
                    maxLength: maxLength(250)
                },
                code: {                    
                    maxLength: maxLength(30)
                },
                nameArabic: {
                    required: requiredIf((x) => {
                        if (x.name == '' || x.name == null)
                            return true;
                        return false;
                    }),
                    maxLength: maxLength(250)
                },
                description: {
                    maxLength: maxLength(500)
                }
            }
        },
        methods: {
            close: function () {
                this.$emit('close');
            },
            GetAutoCodeGenerator: function () {
                
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.get('/Product/UnitCode', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    
                    if (response.data != null) {
                        root.unit.code = response.data;
                        root.render++;
                    }
                });
            },
            SaveUnit: function () {
                var root = this;
                this.loading = true;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.post('/Product/SaveUnit', this.unit, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                    
                    // eslint-disable-line
                    if (response.data.isSuccess == true) {
                        if (root.type != "Edit") {
                           
                            root.$swal({
                                title: "Saved!",
                                icon:'success',
                                text: "Saved Successfully!",
                                type: 'success',
                                showConfirmButton: false,
                                timer: 1500,
                                timerProgressBar: true,

                            });
                            root.close();
                        }
                        else {
                           
                            root.$swal({
                                title: "Update!",
                                text: "Update Sucessfully!",
                                type: 'success',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1500,
                                timerProgressBar: true,

                            });
                            root.close();
                        }
                    }
                    else {
                        root.$swal({
                            title: "Error!",
                            text: "Your Unit Name  Already Exist!",
                            type: 'error',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 1500,
                            timerProgressBar: true,

                        });
                    }
                    })
                    .catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Something Went Wrong!',
                                text: error.response.data,
                                type: 'error',
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false
                    })
                    .finally(() => root.loading = false)
            }
        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            if (this.unit.id == '00000000-0000-0000-0000-000000000000' || this.unit.id == undefined || this.unit.id == '')
                this.GetAutoCodeGenerator();

        }
    }
</script>
