<template>
    <div class="row" v-if="isValid('CanViewExpenseType')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
        <div class="col-lg-12 col-sm-12 ml-auto mr-auto">
            <div class="card ">
                <div class="BorderBottom ml-4 mr-4 mt-3 mb-3">
                    <span class=" DayHeading">{{ $t('ExpenseTypes.ExpenseType') }}</span>
                </div>


                <div class="card-body">
                    <div class="col-lg-12">
                        <div class="row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                <div class="form-group">
                                    <label>{{ $t('ExpenseTypes.SearchbyColor') }}</label>
                                    <div>
                                        <input type="text" class="form-control search_input" v-model="searchQuery" name="search" id="search" :placeholder="$t('ExpenseTypes.Search')" />
                                        <span class="fas fa-search search_icon"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'" style="margin-top:18px;">
                                <a v-if="isValid('CanAddExpenseType')" href="javascript:void(0)" class="btn btn-primary" v-on:click="openmodel"><i class="fa fa-plus"></i>  {{ $t('ExpenseTypes.AddNew') }}</a>
                                <router-link :to="'/FinancialSetup'">
                                    <a href="javascript:void(0)" class="btn btn-outline-danger ">  <i class="fas fa-arrow-circle-left fa-lg"></i> </a>
                                </router-link>
                            </div>

                        </div>

                        <div class="mt-2">
                            <table class="table table-striped table-hover table_list_bg" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <thead class="">
                                    <tr>
                                        <th v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">#</th>
                                        <th v-if="english=='true'" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                            {{ $t('ExpenseTypes.ProcessName') |englishLanguage}}
                                        </th>
                                        <th v-if="isOtherLang()" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                            {{ $t('ExpenseTypes.ProcessName') |arabicLanguage}}
                                        </th>
                                        <th v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                            {{ $t('ExpenseTypes.Status') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(process,index) in processList" v-bind:key="process.id">
                                        <td v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                            {{index+1}}
                                        </td>
                                        <td v-if="english=='true'" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                            <strong>
                                                <a class="font-weight-bold" v-if="isValid('CanEditExpenseType')" href="#" v-on:click="EditProcess(process.id)">{{process.expenseTypeName}}</a>
                                                <span v-else>{{process.expenseTypeName}}</span>
                                            </strong>
                                        </td>
                                        <td v-if="isOtherLang()" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                            <strong>
                                                <a class="font-weight-bold" href="#" v-if="isValid('CanEditExpenseType')" v-on:click="EditProcess(process.id)">{{process.expenseTypeArabic}}</a>
                                                <span v-else>{{process.expenseTypeArabic}}</span>
                                            </strong>
                                        </td>
                                        <td v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">{{process.status==true?$t('ExpenseType.Active'):$t('ExpenseType.De-Active')}}</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                    <div class="float-left">
                        <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                        <span v-else-if="currentPage===1 && rowCount < 10">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage===1 && rowCount >= 11  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                    </div>
                    <div class="float-right">
                        <div class="overflow-auto" v-on:click="GetColorData()">
                            <b-pagination pills size="lg" v-model="currentPage"
                                          :total-rows="rowCount"
                                          :per-page="10"
                                          first-text="First"
                                          prev-text="Previous"
                                          next-text="Next"
                                          last-text="Last"></b-pagination>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <addExpense-type :process="newProcess"
                         :show="show"
                         v-if="show"
                         @close="IsSave"
                         :type="type" />

    </div>
    <div v-else> <acessdenied></acessdenied></div>

</template>

<script>
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        data: function () {
            return {
                arabic: '',
                english: '',
                searchQuery: '',
                show: false,
                processList: [],
                newProcess: {
                    id: '',
                    expenseTypeName: '',
                    expenseTypeArabic: '',
                    status: true
                },
                type: '',
                search: '',
                currentPage: 1,
                pageCount: '',
                rowCount: '',
            }
        },
        watch: {
            search: function (val) {
                this.GetColorData(val, 1);
            }
        },
        methods: {
            IsSave: function () {
                this.show = false;

                this.GetColorData(this.search, this.currentPage);
            },
            getPage: function () {
                this.GetColorData(this.search, this.currentPage);
            },
            openmodel: function () {
                this.newProcess = {
                    id: '00000000-0000-0000-0000-000000000000',
                    expenseTypeName: '',
                    expenseTypeArabic: '',
                    status: true
                }
                this.show = !this.show;
                this.type = "Add";
            },
            GetColorData: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https.get('Accounting/ExpenseTypeList?isActive=false' + '&pageNumber=' + this.currentPage + '&searchTerm=' + this.search, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.processList = response.data.results.expenseTypeLook;
                        root.pageCount = response.data.pageCount;
                        root.rowCount = response.data.rowCount;
                        root.loading = false;
                    }
                    root.loading = false;
                });
            },
            EditProcess: function (Id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https.get('/Accounting/ExpenseTypeDetail?id=' + Id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data) {
                            root.newProcess.id = response.data.id;
                            root.newProcess.expenseTypeName = response.data.expenseTypeName;
                            root.newProcess.expenseTypeArabic = response.data.expenseTypeArabic;
                            root.newProcess.status = response.data.status;
                            root.show = !root.show;
                            root.type = "Edit"
                        } else {
                            console.log("error: something wrong from db.");
                        }
                    },
                        function (error) {
                            this.loading = false;
                            console.log(error);
                        });

            }
        },
        created: function () {
            this.$emit('input', this.$route.name);
        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.GetColorData(this.search, 1);
        }
    }
</script>