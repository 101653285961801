<template>
    <div class="row" v-if="isValid('CanViewBundleOffer')">
        <div class="col-lg-12 col-sm-12" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'arabicLanguage'">
            <div class="row mb-4">
                <div class="col-lg-12">
                    <h5 class="page_title">{{ $t('Bundles.Bundles') }}</h5>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item active" aria-current="page">{{ $t('Bundles.Bundles') }}</li>
                        </ol>
                    </nav>
                </div>
            </div>

            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label>{{ $t('Bundles.SearchbyOfferName') }}</label>
                                <div>
                                    <input type="text" class="form-control search_input" v-model="search" name="search" id="search" :placeholder="$t('Bundles.Search')" />
                                    <span class="fas fa-search search_icon"></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                            <a href="javascript:void(0)" v-bind:disabled="activePromotion>=3" class="btn btn-primary" style="margin-top:27px;" v-if="isValid('CanAddBundleOffer')" v-on:click="AddBundles"><i class="fa fa-plus"></i>  {{ $t('Bundles.AddNew') }}</a>

                            <router-link :to="'/StartScreen'">
                                <a href="javascript:void(0)" class="btn btn-outline-danger" style="margin-top:27px;">{{ $t('Bundles.Close') }} </a>
                            </router-link>
                        </div>
                        <div class="col-lg-12">
                            <ul class="nav nav-tabs" data-tabs="tabs">
                                <li class="nav-item"><a class="nav-link" v-bind:class="{active:active == 'Active'}" v-on:click="makeActive('Active')" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">{{ $t('Bundles.Active') }}</a></li>
                                <li class="nav-item"><a class="nav-link" v-bind:class="{active:active == 'History'}" v-on:click="makeActive('History')" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">{{ $t('Bundles.History') }}</a></li>
                            </ul>

                            <div class="tab-content mt-3" id="nav-tabContent">
                                <div v-if="active == 'Active'">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="table-responsive">
                                                <table class="table table-hover table-striped table_list_bg" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                {{ $t('Bundles.ID') }}
                                                            </th>
                                                            <th>
                                                                {{ $t('Bundles.OFFER_NAME') }}
                                                            </th>
                                                            <th>
                                                                {{ $t('Bundles.ProductName') }}
                                                            </th>
                                                            <th>
                                                                {{ $t('Bundles.BUY') }}
                                                            </th>
                                                            <th>
                                                                {{ $t('Bundles.GET') }}
                                                            </th>

                                                            <th>
                                                                {{ $t('Bundles.FromDate') }}
                                                            </th>
                                                            <th>
                                                                {{ $t('Bundles.ToDate') }}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(details, index) in bundlesList" v-bind:key="details.id">
                                                            <td>
                                                                {{index+1}}
                                                            </td>
                                                            <td v-if="isValid('CanEditBundleOffer')">
                                                                <strong>
                                                                    <a href="javascript:void(0)" v-on:click="EditBundles(details.id)">{{details.offer}}</a>
                                                                </strong>
                                                            </td>
                                                            <td v-else>
                                                                {{details.offer}}
                                                            </td>
                                                            <td>{{details.productName}}</td>
                                                            <td>{{details.buy}}</td>
                                                            <td>{{details.get}}</td>

                                                            <td>{{details.fromDate}}</td>
                                                            <td>{{details.toDate}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                            </div>

                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <span v-if="currentPage===1 && rowCount === 0"> Showing 0 to 0 of 0 entries</span>
                                        <span v-else-if="currentPage===1 && rowCount < 10"> Showing {{currentPage}} to {{rowCount}} of {{rowCount}} entries</span>
                                        <span v-else-if="currentPage===1 && rowCount >= 11  "> Showing {{currentPage}} to {{currentPage*10}} of {{rowCount}} entries</span>
                                        <span v-else-if="currentPage===1"> Showing {{currentPage}} to {{currentPage*10}} of {{rowCount}} entries</span>
                                        <span v-else-if="currentPage!==1 && currentPage!==pageCount"> Showing {{(currentPage*10)-9}} to {{currentPage*10}} of {{rowCount}} entries</span>
                                        <span v-else-if="currentPage === pageCount"> Showing {{(currentPage*10)-9}} to {{rowCount}} of {{rowCount}} entries</span>
                                    </div>
                                    <div class="float-right">
                                        <div class="overflow-auto" v-on:click="getPage()" v-if="($i18n.locale == 'en' ||isLeftToRight())">
                                            <b-pagination pills size="lg" v-model="currentPage"
                                                          :total-rows="rowCount"
                                                          :per-page="10"
                                                          first-text="First"
                                                          prev-text="Previous"
                                                          next-text="Next"
                                                          last-text="Last"></b-pagination>
                                        </div>
                                        <div class="overflow-auto" v-on:click="getPage()" v-else>
                                            <b-pagination pills size="lg" v-model="currentPage"
                                                          :total-rows="rowCount"
                                                          :per-page="10"
                                                          first-text="الأولى"
                                                          prev-text="السابقة"
                                                          next-text="التالية"
                                                          last-text="الأخيرة"></b-pagination>
                                        </div>
                                    </div>

                                </div>
                                <div v-if="active == 'History'">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="table-responsive">
                                                <table class="table table-hover table-striped table_list_bg" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                    <thead class="m-0">
                                                        <tr>
                                                            <th>
                                                                {{ $t('Bundles.ID') }}
                                                            </th>
                                                            <th>
                                                                {{ $t('Bundles.OFFER_NAME') }}
                                                            </th>
                                                            <th>
                                                                {{ $t('Bundles.ProductName') }}
                                                            </th>
                                                            <th>
                                                                {{ $t('Bundles.BUY') }}
                                                            </th>
                                                            <th>
                                                                {{ $t('Bundles.GET') }}
                                                            </th>

                                                            <th>
                                                                {{ $t('Bundles.FromDate') }}
                                                            </th>
                                                            <th>
                                                                {{ $t('Bundles.ToDate') }}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(details, index) in bundlesList" v-bind:key="details.id">
                                                            <td>
                                                                {{index+1}}
                                                            </td>
                                                            <td>

                                                                {{details.offer}}

                                                            </td>
                                                            <td>{{details.productName}}</td>

                                                            <td>{{details.buy}}</td>
                                                            <td>{{details.get}}</td>

                                                            <td>{{details.fromDate}}</td>
                                                            <td>{{details.toDate}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <span v-if="currentPage===1 && rowCount === 0"> Showing 0 to 0 of 0 entries</span>
                                        <span v-else-if="currentPage===1 && rowCount < 10"> Showing {{currentPage}} to {{rowCount}} of {{rowCount}} entries</span>
                                        <span v-else-if="currentPage===1 && rowCount >= 11  "> Showing {{currentPage}} to {{currentPage*10}} of {{rowCount}} entries</span>
                                        <span v-else-if="currentPage===1"> Showing {{currentPage}} to {{currentPage*10}} of {{rowCount}} entries</span>
                                        <span v-else-if="currentPage!==1 && currentPage!==pageCount"> Showing {{(currentPage*10)-9}} to {{currentPage*10}} of {{rowCount}} entries</span>
                                        <span v-else-if="currentPage === pageCount"> Showing {{(currentPage*10)-9}} to {{rowCount}} of {{rowCount}} entries</span>
                                    </div>
                                    <div class="float-right">
                                        <div class="overflow-auto" v-on:click="getPage()" v-if="($i18n.locale == 'en' ||isLeftToRight())">
                                            <b-pagination pills size="lg" v-model="currentPage"
                                                          :total-rows="rowCount"
                                                          :per-page="10"
                                                          first-text="First"
                                                          prev-text="Previous"
                                                          next-text="Next"
                                                          last-text="Last"></b-pagination>
                                        </div>
                                        <div class="overflow-auto" v-on:click="getPage()" v-else>
                                            <b-pagination pills size="lg" v-model="currentPage"
                                                          :total-rows="rowCount"
                                                          :per-page="10"
                                                          first-text="الأولى"
                                                          prev-text="السابقة"
                                                          next-text="التالية"
                                                          last-text="الأخيرة"></b-pagination>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!--<saleInvoice :printDetails="printDetails" v-if="printDetails.length != 0" v-bind:key="printRender"></saleInvoice>-->
    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        name: 'Bundles',
        mixins: [clickMixin],
        data: function () {
            return {
                show: false,
                bundlesList: [],
                active: 'Active',
                search: '',
                searchQuery: '',
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                activePromotion: 0,
            }
        },
        watch: {
            search: function (val) {
                this.getData(val, 1, this.active);
            }
        },
        methods: {
            AddBundles: function () {
                this.$router.push('/AddBundles')
            },
            getPage: function () {
                this.getData(this.search, this.currentPage, this.active);
            },
            makeActive: function (item) {
                this.active = item;
                this.getData(this.search, 1, item);
            },
            getData: function (search, currentPage, status) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                this.$https.get('/Product/GetBundleCategoryItemsList?status=' + status + '&searchTerm=' + search + '&pageNumber=' + currentPage, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {

                        if (response.data != null) {
                            root.bundlesList = response.data.results;
                            root.activePromotion = response.data.activeBundle;
                            root.pageCount = response.data.pageCount;
                            root.rowCount = response.data.rowCount;
                        }
                    });
            },

            EditBundles: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Product/BundleCategoryItemsDetailsViaId?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.$router.push({
                            path: '/AddBundles',
                            query: { data: response.data }
                        })
                    }
                });

            }
        },
        created: function () {

            this.$emit('input', this.$route.name);
        },
        mounted: function () {
            this.makeActive("Active");
        }
    }
</script>