<template>
        <div v-if="isValid('CanViewDayWiseTransactions')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <div>
                <div class="card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-md-6 col-lg-6">
                                <div class="form-group ">
                                    <h5 class="card-title DayHeading">Daywise Transaction</h5>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6">
                                <div class="form-group " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left' ">
                                    <router-link :to="'/AllReports'">
                                        <a href="javascript:void(0)" class="btn btn-outline-primary "><i class="fas fa-arrow-circle-left fa-lg"></i> </a>
                                    </router-link>
                                    <!--<a href="javascript:void(0)" class="btn btn-outline-primary">{{ $t('DaywisetransactionReport.Print') }}</a>-->
                                    

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'pl-3' : 'pr-3' ">

                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
                            <div class="form-group ">
                                <label>{{ $t('DaywisetransactionReport.Date') }}</label>
                                <datepicker v-model="date" :key="render" />
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
                            <div class="form-group">
                                <label>Serach by Document Number</label>
                                <div>
                                    <input type="text" class="form-control search_input" v-model="search" name="search" id="search" :placeholder="$t('DaywisetransactionReport.Search')" />
                                    <span class="fas fa-search search_icon"></span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="card-body" v-for="(contact,index) in resultQuery" v-bind:key="index">
                        <div>
                            <h3>{{contact.header}}</h3>
                            <div class="table-responsive">
                                <table class="table table-striped table-hover table_list_bg">
                                    <thead class="">
                                        <tr>
                                            <th>#</th>

                                            <th>
                                                {{ $t('DaywisetransactionReport.Date') }}
                                            </th>
                                            <th>
                                                Account Type
                                            </th>
                                            <th>
                                                Cost center
                                            </th>
                                            <th>
                                                Account
                                            </th>
                                            <th>
                                                Document
                                            </th>
                                            <th>
                                                Transaction Type
                                            </th>
                                            <th>
                                                Debit
                                            </th>
                                            <th>
                                                Credit
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(product,i) in contact.dayWiseTransactionLookup" v-bind:key="i">
                                            <td>
                                                {{i+1}}
                                            </td>
                                            <td>
                                                {{product.date.split(' ')[0]}}
                                            </td>
                                            <td>
                                                {{product.accountType}}
                                            </td>
                                            <td>
                                                {{product.costCentre}}
                                            </td>
                                            <td>
                                                {{product.accountName}}
                                            </td>
                                            <td>
                                                {{product.documentNumber}}
                                            </td>
                                            <td>
                                                {{(getTransactionType(product.transactionType))}}
                                            </td>
                                            <td>
                                                {{Number(product.debit).toLocaleString()}}
                                            </td>
                                            <td>
                                                {{Number(product.credit).toLocaleString()}}
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right mr-3' : 'text-left ml-3' ">

                            <label style="font-weight:bold;font-size:16px;">Total:   <span> {{parseFloat(contact.total)>=0?'Dr '+ contact.total: 'Cr '+ parseFloat(contact.total)*(-1) }}</span></label>
                        </div>
                    </div>


                </div>
            </div>
        <!--<customerBalancePrintReport :headerFooter="headerFooter" :isPrint="isShown" :formName="formName" :accountId="accountId" :fromDate="fromDate" :toDate="toDate" v-if="contactList.length != 0" v-bind:key="printRender"></customerBalancePrintReport>-->
    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import moment from "moment";
    export default {
        mixins: [clickMixin],
        props: ['formName'],
        data: function () {
            return {
                search:'',
                date: '',
                transactionList: [],
            }
        },
        created: function () {
            this.date = moment().format("DD MMM YYYY");
        },
        watch: {
            date: function (date) {
                this.date = date;
                this.search = '';
                this.GetTransactionList(this.date)
            },

        },
        computed: {
            resultQuery: function () {
                var root = this;
                if (this.search) {
                    return root.transactionList.filter((x) => {

                        return root.search.toLowerCase().split(' ').every(v => x.header.toLowerCase().includes(v) )
                    })
                } else {
                    return root.transactionList;
                }
            //    || x.dayWiseTransactionLookup.filter((y) => { root.search.toLowerCase().split(' ').every(w => y.accountName.toLowerCase().includes(w)) })
            },
        },
        methods: {
            
            getTransactionType: function (x) {
                if (x == 0) {
                    return 'Purchase Invoice'
                }
                else if (x == 1) {
                    return 'Cash Receipt'
                }

                else if (x == 2) {
                    return 'Bank Receipt'
                }
                else if (x == 3) {
                    return 'Cash Pay'
                }
                else if (x == 4) {
                    return 'Bank Pay'
                }
                else if (x == 5) {
                    return 'Stock In'
                }
                else if (x == 6) {
                    return 'Stock Out'
                }
                else if (x == 7) {
                    return 'Journal Voucher'
                }
                else if (x == 8) {
                    return 'Expense Voucher'
                }
                else if (x == 9) {
                    return 'Sale Invoice'
                }
                else if (x == 10) {
                    return 'Purchase Return'
                } else if (x == 11) {
                    return 'Purchase Return'
                }
                else if (x == 12) {
                    return 'Day End'
                }
                else if (x == 13) {
                    return 'WareHouse Transfer From'
                }
                else if (x == 14) {
                    return 'Production Batch'
                }
                else if (x == 15) {
                    return 'Stock Production'
                }
                else if (x == 16) {
                    return 'Production Remaining Stock'
                }
                else if (x == 17) {
                    return 'Production Damage Stock'
                }
                else if (x == 18) {
                    return 'Petty Cash'
                }
                else if (x == 19) {
                    return 'WareHouse Transfer To'
                }
            },

            getDate: function (date) {
                return moment(date).format('l');
            },

            GetTransactionList: function (date) {

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.isShown = false;
                
                this.$https.get('/Report/GetTransactionList?date=' + date, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {
                            
                            root.transactionList = response.data;
                        }
                    }).catch(error => {

                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Some thing went wrong!',
                                text: error.response.data,
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false
                    });
            },
        },

        mounted: function () {
            
        }
    }
</script>
