<template>
    <div class="row" v-if="isValid('CanAddAutoTemplate')|| isValid('CanEditAutoTemplate')" v-bind:style="$i18n.locale == 'ar' ? languageChange('en') : languageChange('ar')">
        <div class="col-md-12 ml-auto mr-auto">
            <div class="card" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="row " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <div class="col-lg-8 poHeading" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <span v-if="purchase.id === '00000000-0000-0000-0000-000000000000'">{{ $t('AddAutoPurchaseTemplate.AddAutoPurchaseTemplate') }} <span style="font-weight:bold"> - {{purchase.registrationNo}}</span></span>
                                    <span v-else>{{ $t('AddAutoPurchaseTemplate.AddAutoPurchaseTemplate') }}  <span style="font-weight:bold"> - {{purchase.registrationNo}}</span></span>
                                </div>
                                <div class="col-lg-4 dateHeading" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                                    <span>
                                        {{purchase.date}}
                                    </span>
                                </div>
                            </div>

                            <div class="row bottomBorder"></div>

                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div v-if="raw=='true'" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                        <label>{{ $t('AddAutoPurchaseTemplate.Raw') }}  : </label>
                                        <toggle-button v-model="purchase.isRaw" @change="ChangeSupplier" class="ml-2 mt-2" color="#3178F6" />
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <label>{{ $t('AddAutoPurchaseTemplate.Supplier') }} :<span class="LabelColour"> *</span></label>
                                    <div v-bind:class="{'has-danger': $v.purchase.supplierId.$error,}" :key="supplierRender">
                                        <supplierdropdown v-model="$v.purchase.supplierId.$model" :disable="purchase.approvalStatus === 5 && purchase.id != '00000000-0000-0000-0000-000000000000'" v-bind:values="purchase.supplierId" :status="purchase.isRaw" />
                                        <span v-if="$v.purchase.supplierId.$error"
                                              class="error text-danger">
                                        </span>
                                    </div>
                                </div>

                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 form-group" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <label v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'pt-2'">{{ $t('AddAutoPurchaseTemplate.ReOrderDay') }} :</label>
                                    <input class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="purchase.day" />
                                </div>

                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <label>{{ $t('AddAutoPurchaseTemplate.StartDate') }}:</label>
                                    <datepicker v-model="purchase.invoiceDate" />
                                </div>

                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-bind:class=" ($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <label>{{ $t('AddAutoPurchaseTemplate.TaxMethod') }} :<span class="LabelColour"> *</span></label>
                                    <multiselect :options="options" v-bind:disabled="purchase.purchaseOrderItems.length>0" v-model="purchase.taxMethod" :show-labels="false" v-bind:placeholder="$t('SelectMethod')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    </multiselect>
                                </div>

                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <label>{{ $t('AddAutoPurchaseTemplate.VAT') }} :<span class="LabelColour"> *</span></label>
                                    <taxratedropdown v-model="purchase.taxRateId" :isDisable="purchase.purchaseOrderItems.length>0? true :false" />
                                </div>

                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <label>{{ $t('AddAutoPurchaseTemplate.WareHouse') }} :<span class="LabelColour"> *</span></label>
                                    <warehouse-dropdown v-model="purchase.wareHouseId" />
                                </div>

                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                                    <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                        <label>{{ $t('AddAutoPurchaseTemplate.Status') }}  : </label>
                                        <toggle-button v-model="purchase.isActive" class="ml-2 mt-2" color="#3178F6" />
                                    </div>
                                </div>
                            </div>

                            <br />
                            <auto-purchase-template-item @input="SavePurchaseItems" ref="childComponentRef" :taxMethod="purchase.taxMethod" :taxRateId="purchase.taxRateId" :raw="purchase.isRaw" :key="rander" />
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <label>{{ $t('AddAutoPurchaseTemplate.TermAndCondition') }}:</label>
                                    <textarea class="form-control " rows="3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'"
                                              v-model="purchase.note" />
                                </div>
                            </div>

                            <div class="row" v-if="!loading">
                                <div class="col-md-12 arabicLanguage">
                                    <div v-if="purchase.id === '00000000-0000-0000-0000-000000000000'">
                                        <button class="btn btn-primary mr-2"
                                                v-on:click="savePurchase()"
                                                v-if="isValid('CanAddAutoTemplate')"
                                                v-bind:disabled="$v.$invalid || purchase.purchaseOrderItems.filter(x => x.totalPiece=='').length > 0 || purchase.purchaseOrderItems.filter(x => x.unitPrice=='').length > 0">
                                            {{ $t('AddAutoPurchaseTemplate.SaveAsPost') }}
                                        </button>
                                        <button class="btn btn-outline-danger mr-2"
                                                v-on:click="goToPurchase">
                                            {{ $t('AddAutoPurchaseTemplate.Cancel') }}
                                        </button>
                                    </div>

                                    <div v-if="purchase.id != '00000000-0000-0000-0000-000000000000'">
                                        <button class="btn btn-primary  mr-2"
                                                v-on:click="savePurchase()"
                                                v-if="isValid('CanEditAutoTemplate')"
                                                v-bind:disabled="$v.$invalid || purchase.purchaseOrderItems.filter(x => x.totalPiece=='').length > 0 || purchase.purchaseOrderItems.filter(x => x.unitPrice=='').length > 0">
                                            {{ $t('AddAutoPurchaseTemplate.UpdateAsPost') }}
                                        </button>
                                        <button class="btn btn-outline-danger mr-2"
                                                v-on:click="goToPurchase">
                                            {{ $t('AddAutoPurchaseTemplate.Cancel') }}
                                        </button>
                                    </div>
                                </div>

                            </div>
                            <div class="card-footer col-md-3 text-left" v-else>
                                <loading :active.sync="loading"
                                         :can-cancel="false"
                                         :is-full-page="true"></loading>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import Loading from 'vue-loading-overlay';
    import moment from "moment";
    import "vue-loading-overlay/dist/vue-loading.css";
    import { required } from "vuelidate/lib/validators";
    import Multiselect from 'vue-multiselect'
    //import VueBarcode from 'vue-barcode';
    export default {
        mixins: [clickMixin],
        components: {
            Multiselect,
            Loading
        },
        data: function () {
            return {
                currency: '',
                totalAmount: 0,
                daterander: 0,
                rander: 0,
                purchase: {
                    id: "00000000-0000-0000-0000-000000000000",
                    date: "",
                    registrationNo: "",
                    supplierId: "",
                    day: "",
                    taxMethod: "",
                    taxRateId: "",
                    wareHouseId: "",
                    note: '',
                    purchaseOrderItems: [],
                    isRaw: false,
                    isActive: true,
                },
                raw: '',
                loading: false,
                language: 'Nothing',
                options: [],
                supplierRender: 0,
                show: false,
            };
        },
        validations: {
            purchase: {
                date: { required },
                registrationNo: { required },
                supplierId: { required },
                day: { required },
                purchaseOrderItems: { required },
                wareHouseId: { required },
            },
        },
        methods: {
            getTotalAmount: function () {
                this.totalAmount = this.$refs.childComponentRef.getTotalAmount();
            },
            getDate: function (date) {
                if (date == null || date == undefined) {
                    return "";
                }
                else {
                    return moment(date).format('LLL');
                }
            },
            ChangeSupplier: function () {
                this.supplierRender++;
                this.rander++;
            },
            languageChange: function (lan) {
                if (this.language == lan) {
                    if (this.purchase.id == '00000000-0000-0000-0000-000000000000') {

                        var getLocale = this.$i18n.locale;
                        this.language = getLocale;

                        this.$router.go('/addproduct');
                    }
                    else {
                        this.$swal({
                            title: 'Error!',
                            text: 'You cannot Change language during update, otherwise your current page data will be lose',
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            icon: 'error',
                            timer: 4000,
                            timerProgressBar: true,
                        });
                    }
                }


            },


            AutoIncrementCode: function () {
                var root = this;
                var token = "";
                if (root.$session.exists()) {
                    token = localStorage.getItem("token");
                }
                root.$https
                    .get("/Purchase/AutoPurchaseAutoGenerateNo", {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then(function (response) {
                        if (response.data != null) {
                            root.purchase.registrationNo = response.data;
                        }
                    });
            },
            SavePurchaseItems: function (purchaseOrderItems) {
                this.purchase.purchaseOrderItems = purchaseOrderItems;
                this.getTotalAmount();
            },

            savePurchase: function () {
                this.loading = true;
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.purchase.isMultiUnit = localStorage.getItem('IsMultiUnit') == 'true' ? true : false;
                //root.purchase.date = root.purchase.date + " " + moment().format("hh:mm A");
                this.$https.post('/Purchase/SaveAutoPurchaseTemplate', root.purchase, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {
                        if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Add") {
                            root.info = response.data.bpi
                            root.$swal({
                                title: "Saved!",
                                text: response.data.message.isAddUpdate,
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonStyling: false,
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,

                            }).then(function (ok) {
                                if (ok != null) {
                                    root.$router.go('AddAutoPurchaseTemplate');
                                }
                            });
                        }
                        else if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Update") {
                            root.info = response.data.bpi
                            root.$swal({
                                title: "Saved!",
                                text: response.data.message.isAddUpdate,
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonStyling: false,
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,

                            }).then(function (ok) {
                                if (ok != null) {
                                    root.$router.push({
                                        path: '/AutoPurchaseTemplate',
                                        query: {
                                            data: 'AutoPurchaseTemplate'
                                        }
                                    })
                                }
                            });
                        }
                        else {
                            root.$swal({
                                title: 'Error!',
                                text: response.data.message.isAddUpdate,
                                type: 'error',
                                confirmButtonClass: "btn btn-danger",
                                icon: 'error',
                                timer: 1500,
                                timerProgressBar: true,
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                type: 'error',
                                icon: 'error',
                                title: root.$t('Error'),
                                text: error.response.data,
                                confirmButtonClass: "btn btn-danger",
                                showConfirmButton: true,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false
                    })
                    .finally(() => root.loading = false)

            },
            goToPurchase: function () {
                
                this.$router.push({
                    path: '/AutoPurchaseTemplate',
                    query: {
                        data: 'AutoPurchaseTemplate'
                    }
                })
            },
            ViewPaymentVoucher: function (id, expense) {
                var root = this;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Purchase/PurchaseOrderPaymentDetail?Id=' + id + '&expense=' + expense, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.$https.get('/PaymentVoucher/PaymentVoucherDetails?Id=' + response.data.paymentVoucherId, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                            if (response.data != null) {
                                root.paymentview = response.data;
                                if (expense) {
                                    root.isExpenseview = true;
                                }
                                else {
                                    root.isPaymentview = true;
                                }
                            }
                        });
                    }
                });
            },
            paymentView: function () {
                this.isPaymentview = false;
                this.isExpenseview = false;
            }
        },
        created: function () {
            this.$emit('input', this.$route.name);

            if (this.$route.query.data != undefined) {
                this.purchase = this.$route.query.data;
                this.purchase.date = moment(this.purchase.date).format("LLL");
                this.rander++;
                this.rendered++;
            }
            else {
                this.purchase.invoiceDate = moment().isoWeekday(7);
            }
        },
        mounted: function () {
            this.language = this.$i18n.locale;
            this.currency = localStorage.getItem('currency');

            if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {
                this.options = ['Inclusive', 'Exclusive'];
            }
            else {
                this.options = ['شامل', 'غير شامل'];
            }
            if (this.$session.exists()) {
                this.raw = localStorage.getItem('IsProduction');
            }
            if (this.$route.query.data == undefined) {
                this.AutoIncrementCode();

                this.purchase.date = moment().format("LLL");
                this.daterander++;
            }
        },
    };
</script>
<style scoped>
    .poHeading {
        font-size: 32px;
        font-style: normal;
        line-height: 37px;
        font-weight: 500;
        font-size: 24px;
        line-height: 26px;
        color: #3178F6;
        text-align: center
    }

    .dateHeading {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.01em;
        text-align: left;
        color: #35353D;
    }

    .bottomBorder {
        padding-top: 24px !important;
        border-bottom: 1px solid #EFF4F7;
    }
</style>
