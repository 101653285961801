<template>
    <div class="row" v-if="(isValid('CanViewDetailStockIn')  && formName=='StockIn') || (isValid('CanViewDetailStockOut')  && formName=='StockOut')  " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-bind:style="$i18n.locale == 'ar' ? languageChange('en') : languageChange('ar')">
        <div class="col-xs-12 col-sm-9 col-md-9 col-lg-9" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <div class="card">
                <div class="card-body" :key="render">
                    <div class="overlay">
                        <div class="row align-items-center h-100 justify-content-sm-center">
                            <div class="loadingio-spinner-dual-ball-44dlc48bacw">
                                <div class="ldio-m86dw9oanea">
                                    <div> </div> <div> </div> <div> </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="invoice-area">
                        <div class="invoice-head">
                            <div class="row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 view_page_title" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <h5 v-if="formName=='StockIn'">   {{ $t('ViewStock.StockIn') }}</h5>
                                    <h5 v-else-if="formName=='StockProduction'">{{ $t('ViewStock.ProductionStock') }}</h5>
                                    <h5 v-else>   {{ $t('ViewStock.StockOut') }}</h5>
                                </div>
                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">

                                    <a href="javascript:void(0)" class="btn btn-outline-danger" v-on:click="onCancel">  <i class="fas fa-arrow-circle-left fa-lg"></i> </a>


                                </div>

                            </div>
                        </div>
                        <div class="row  " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">

                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" v-if="formName=='StockOut'">
                                <label class="font-weight-bold">{{ $t('ViewStock.Reason') }}</label>
                                <p> {{productDetail.reason}}</p>

                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <label class="font-weight-bold">{{ $t('ViewStock.Narration') }}</label>
                                <p>   {{productDetail.narration}}</p>

                            </div>




                            <div class="col-lg-12 mt-5" v-bind:class="{'has-danger' : $v.productDetail.$error}">
                                <viewstockitem @input="getstockAdjustmentDetails" :formName="formName" :stockAdjustmentDetailss="stockAdjustmentDetails" :taxMethod="productDetail.taxMethod" :taxRateId="productDetail.taxRateId" />
                                <span v-if="$v.productDetail.$error" class="error"></span>
                            </div>
                        </div>
                    </div>
                    <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                        <button class="btn btn-primary float-left mr-2" v-on:click="Attachment()">
                            {{ $t('QuotationView.Attachment') }}
                        </button>
                        
                        <button type="button" class="btn btn-primary " v-if="productDetail.stockAdjustmentDetails.length > 0 && ((isValid('CanPrintStockIn')  && formName=='StockIn') || (isValid('CanPrintStockOut')  && formName=='StockOut'))" v-on:click="PrintStock"> {{ $t('ViewStock.Print') }}</button>
                        <button class="btn btn-danger  " v-on:click="onCancel">{{ $t('ViewStock.Cancel') }}</button>


                    </div>
                </div>
            </div>
        </div>
        <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <h5 class="view_page_title">{{ $t('ViewStock.BasicInfo') }}</h5>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <label class="invoice_lbl" v-if="formName=='StockIn'">{{ $t('ViewStock.StockIn') }}#</label>
                            <label class="invoice_lbl" v-else-if="formName=='StockProduction'">{{ $t('ViewStock.ProductionStock') }}#</label>
                            <label class="invoice_lbl" v-else>{{ $t('ViewStock.StockOut') }}#</label>
                            <hr style="margin-top: 0.3rem; margin-bottom: 0.1rem;" />
                            <label>{{productDetail.code}}</label>
                            <hr style="margin-top: 0.1rem;" />
                        </div>






                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            <label class="invoice_lbl"> {{ $t('ViewStock.Warehouse') }}</label>
                            <hr style="margin-bottom: 0.1rem; margin-top: 0.3rem;" />
                            <label> {{productDetail.warehouseName}}</label>
                            <hr style="margin-top: 0.1rem;" />
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2" v-if="formName=='StockOut'">
                            <label class="invoice_lbl">  {{ $t('ViewStock.TaxMethod') }}</label>
                            <hr style="margin-bottom: 0.1rem; margin-top: 0.3rem;" />
                            <label> {{ productDetail.taxMethod }}</label>
                            <hr style="margin-top: 0.1rem;" />
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2" v-if="formName=='StockOut'">
                            <label class="invoice_lbl">  {{ $t('ViewStock.VAT%') }}</label>
                            <hr style="margin-bottom: 0.1rem; margin-top: 0.3rem;" />
                            <label> {{productDetail.taxRateName}}</label>
                            <hr style="margin-top: 0.1rem;" />
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            <label class="invoice_lbl">Send Copy To</label>
                            <hr style="margin-bottom: 0.1rem; margin-top: 0.3rem;" />
                            <label>Email</label>
                            <hr style="margin-top: 0.1rem;" />
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            <button class="btn btn-primary btn-block">Send Stock Report</button>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 mt-2">
                            <button class="btn btn-light btn-block text-left">PDF <i class="fas fa-file-pdf float-right" style="color:#EB5757;"></i></button>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 mt-2">
                            <button class="btn btn-light btn-block text-left">Sheets <i class="fas fa-file-excel float-right" style="color:#198754;"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <bulk-attachment :documentid="productDetail.id" :show="show" v-if="show" @close="attachmentSave" />
        <viewStockInPrint v-if="showPrint" :printDetails="productDetail" :headerFooter="headerFooter" v-bind:key="printRender"></viewStockInPrint>
    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>

<script>
    import clickMixin from '@/Mixins/clickMixin'
    import { required } from "vuelidate/lib/validators";
    import moment from "moment";
    export default {
        mixins: [clickMixin],
        name: "AddCheckOut",
        props: ['formName'],
        data: function () {
            return {
                stockAdjustmentDetails: [],
                render: 0,
                productDetail: {
                    id: '00000000-0000-0000-0000-000000000000',
                    date: '',
                    narration: '',
                    code: '',
                    warehouseId: '',
                    isDraft: true,
                    stockAdjustmentType: '',
                    stockAdjustmentDetails: [],
                    taxMethod: '',
                    taxRateId: "",
                    reason: '',
                },
                loading: false,
                language: 'Nothing',
                options: [],
                reasonOptions: [],
                headerFooter: {
                    company: '',
                    isStockIn: true

                },
                showPrint: false,
                show: false,
                printRender: 0
            }
        },
        validations: {
            productDetail: {
                date: {
                    required
                },
                code: {
                    required
                },
                warehouseId: {
                    required
                }
            }
        },
        methods: {
            Attachment: function () {
                this.show = true;
            },

            attachmentSave: function () {
                this.show = false;
            },

            languageChange: function (lan) {
                if (this.language == lan) {
                    if (this.productDetail.id == '00000000-0000-0000-0000-000000000000') {

                        var getLocale = this.$i18n.locale;
                        this.language = getLocale;

                        this.$router.go('/addSale');
                    }
                    else {
                        this.$swal({
                            title: 'Error!',
                            text: 'You cannot Change language during update, otherwise your current page data will be lose',
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            icon: 'error',
                            timer: 4000,
                            timerProgressBar: true,
                        });
                    }
                }


            },
            PrintStock: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https.get('/Product/StockAdjustmentDetails?id=' + this.productDetail.id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {

                        root.productDetail = response.data
                        root.showPrint = true;
                        root.printRender++

                    }
                });

            },

            onCancel: function () {
                var root = this;
                root.$router.push({
                    path: '/stockValue?formName=' + root.formName,
                    query: {
                        data: 'StockValues' + root.formName
                    }
                })
            },
            getstockAdjustmentDetails: function (stockAdjustmentDetails) {

                this.productDetail.stockAdjustmentDetails = stockAdjustmentDetails;
            },
            GetHeaderDetail: function () {
                var root = this;
                if (this.formName == 'StockIn')
                    this.headerFooter.isStockIn = true;
                else
                    this.headerFooter.isStockIn = false;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get("/Company/GetCompanyDetail?id=" + localStorage.getItem('CompanyID'), { headers: { Authorization: `Bearer ${token}` }, })
                    .then(function (response) {
                        if (response.data != null) {
                            root.headerFooter.company = response.data;
                        }
                    });
            },
        },
        watch: {
            formName: function () {
                if (this.formName == 'StockIn') {
                    if (this.$route.query.data != undefined) {
                        this.render++;
                        this.productDetail = this.$route.query.data.message;
                        this.productDetail.date = moment(this.productDetail.date).format("LLL");
                        this.productDetail.stockAdjustmentType = this.formName;
                    }
                }
                if (this.formName == 'StockProduction') {
                    if (this.$route.query.data != undefined) {
                        this.render++;
                        this.productDetail = this.$route.query.data.message;
                        this.productDetail.date = moment(this.productDetail.date).format("LLL");
                        this.productDetail.stockAdjustmentType = this.formName;
                    }
                }
                if (this.formName == 'StockOut') {
                    if (this.$route.query.data != undefined) {
                        this.render++;
                        this.productDetail = this.$route.query.data.message;
                        this.productDetail.date = moment(this.productDetail.date).format("LLL");
                        this.productDetail.stockAdjustmentType = this.formName;
                    }
                }
            },

        },
        mounted: function () {
            this.GetHeaderDetail()
            this.showPrint = false;
            this.language = this.$i18n.locale;
            if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {
                this.options = ['Inclusive', 'Exclusive'];
                this.reasonOptions = ['Damaged', 'Expire', 'Lose'];
            }
            else {
                this.options = ['شامل', 'غير شامل'];
                this.reasonOptions = ['تالف', 'تنقضي', 'تخسر'];
            }
            this.productDetail.date = moment().format("DD MMM YYYY");
            if (this.formName == 'StockIn') {
                if (this.$route.query.data != undefined) {
                    this.render++;
                    this.productDetail = this.$route.query.data;

                    this.productDetail.date = moment(this.productDetail.date).format("LLL");
                    this.productDetail.stockAdjustmentType = this.formName;
                }
            }
            if (this.formName == 'StockOut') {
                if (this.$route.query.data != undefined) {

                    this.productDetail = this.$route.query.data;
                    this.productDetail.date = moment(this.productDetail.date).format("LLL");
                    this.productDetail.stockAdjustmentType = this.formName;
                    this.render++;
                }
            }
            if (this.formName == 'StockProduction') {
                if (this.$route.query.data != undefined) {
                    this.render++;
                    this.productDetail = this.$route.query.data;
                    this.productDetail.date = moment(this.productDetail.date).format("LLL");

                    this.productDetail.stockAdjustmentType = this.formName;
                }
            }
        }
    }
</script>