<template>
    <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
        <div class="invoice-table table-responsive mt-4 " v-bind:class="{ 'horizontal-table': tableLength >= cardLength }" v-if="isDisable=='true'">
            <table class="table table-bordered" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                <thead class="tableHeaderColor">
                    <tr class="text-capitalize text-center">
                        <th style="width:5%;">#</th>
                        <th style="width:50%;">{{ $t('PurchaseBillItem.Description') }}</th>
                        <th style="width:25%;">{{ $t('PurchaseBillItem.Account') }}</th>
                        <th style="width:20%;" class="text-center">{{ $t('PurchaseBillItem.Amount') }}</th>

                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(expense,index) in purchaseBillItems" v-bind:key="expense.id" style="background:#EAF1FE;">

                        <td>{{index+1}}</td>
                        <td>
                            <input class="textareaauto" v-model="expense.description" disabled style="background-color:transparent" v-bind:placeholder="$t('WriteHere')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" />
                        </td>
                        <td>
                            <accountdropdown v-model="expense.accountId" :disabled="true" :dropdownaccount="'dropdownAccountcss'" :dropdownpo="'dropdownpo'" />
                        </td>
                        <td>
                            <input class="form-control text-center" disabled style="background-color:#ffffff;" v-model="expense.amount" type="number" @click="$event.target.select()" :text-dir="'true'" />
                        </td>

                    </tr>

                    <tr style="border:none !important;">
                        <td colspan="4"><h6 class="m-0 text-left"> &nbsp;</h6></td>
                    </tr>
                    <tr style="margin-top:24px;background-color:#E5E5E5;height:80px">
                        <td colspan="2"><h6 class="m-0">{{ $t('PurchaseBillItem.TotalAmount') }} :</h6></td>
                        <td style="" class="text-center">
                            <h6 class="m-0 ">   {{currency}} {{totalAmount | roundOffFilter}}</h6>
                        </td>

                        <td style="">&nbsp;</td>
                    </tr>
                </tbody>
                <!--<tfoot class="text-right">
                    <tr>
                        <td><h6 class="m-0">{{ $t('DailyExpense.TotalAmount') }} :</h6></td>
                        <td>
                            <h6 class="m-0">   {{currency}} {{totalAmount | roundOffFilter}}</h6>
                        </td>

                        <td></td>
                    </tr>

                </tfoot>-->
            </table>
        </div>
        <div class="invoice-table table-responsive mt-4 " v-bind:class="{ 'horizontal-table': tableLength >= cardLength }" v-else>
            <table class="table" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                <thead class="tableHeaderColor">
                    <tr class="text-capitalize text-center">

                        <th style="width:45%;">{{ $t('PurchaseBillItem.Description') }}</th>
                        <th style="width:25%;">{{ $t('PurchaseBillItem.Account') }}</th>
                        <th style="width:25%;" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">{{ $t('PurchaseBillItem.Amount') }}</th>
                        <th style="width:5%;" class="text-center">{{ $t('PurchaseBillItem.Action') }}</th>

                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(expense,index) in purchaseBillItems" v-bind:key="expense.id" style="background:#EAF1FE;">


                        <td>
                            <input class="form-control borderNone" v-model="expense.description" style="background-color:#ffffff;" v-bind:placeholder="$t('PurchaseBillItem.WriteHere')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" />
                        </td>
                        <td>
                            <accountdropdown v-model="expense.accountId" :PanelWidth="true" :disabled="false" :dropdownaccount="'dropdownAccountcss'" :dropdownpo="'dropdownpo'" />
                        </td>
                        <td>
                            <input class="form-control borderNone" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'" style="background-color:#ffffff;" v-model="expense.amount" type="number" @click="$event.target.select()" :text-dir="'true'" />
                        </td>
                        <td style="width:60px;text-align:center" v-if="index == purchaseBillItems.length - 1 && addItem == false">
                            <button title="Add New Item" class="btn btn-sm btn-danger" v-on:click="addDailyExpense">
                                <i class="fa fa-plus"></i> {{ $t('PurchaseBillItem.AddNew') }}
                            </button>
                        </td>
                        <td style="width:60px;text-align:center" v-else>
                            <button title="Remove Item" class="btn btn-danger btn-sm  btn-round  btn-icon" v-on:click="removeExpense(expense.id, 'true')">
                                <i class="nc-icon nc-simple-remove"></i>
                            </button>
                        </td>
                    </tr>

                    <tr v-if="addItem" style="background:#EAF1FE;">


                        <td>
                            <input class="form-control borderNone" v-model="purchaseBills.description" v-bind:placeholder="$t('PurchaseBillItem.WriteHere')" style="width:100% !important;background-color:#ffffff;" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" />
                        </td>
                        <td>
                            <accountdropdown v-model="purchaseBills.accountId" :PanelWidth="true" :disabled="false" :dropdownaccount="'dropdownAccountcss'" :value="purchaseBills.accountId" :dropdownpo="'dropdownpo'" :key="refresh" />

                        </td>
                        <td>
                            <input class="form-control borderNone" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'" v-model="purchaseBills.amount" type="number" style="width:100% !important;background-color:#ffffff" @click="$event.target.select()" />
                        </td>
                        <td class="text-center" v-if="(purchaseBills.description=='' ) ">
                            <button title="Add New Item" class="btn btn-sm btn-round btn-icon btn-danger" disabled v-on:click="addDailyExpense">
                                <i class="fa fa-plus"></i>
                            </button>
                        </td>
                        <td class="text-center" v-else-if="( purchaseBills.accountId=='') ">
                            <button title="Add New Item" class="btn btn-sm btn-round btn-icon btn-danger" disabled v-on:click="addDailyExpense">
                                <i class="fa fa-plus"></i>
                            </button>
                        </td>
                        <td class="text-center" v-else-if="( purchaseBills.amount==0) ">
                            <button title="Add New Item" class="btn btn-sm btn-round btn-icon btn-danger" disabled v-on:click="addDailyExpense">
                                <i class="fa fa-plus"></i>
                            </button>
                        </td>
                        <td class="text-center" v-else>
                            <button title="Add New Item" class="btn btn-sm btn-round btn-icon btn-danger" v-on:click="addDailyExpense">
                                <i class="fa fa-plus"></i>
                            </button>
                        </td>
                    </tr>
                    <tr style="border:none !important;">
                        <td colspan="4"><h6 class="m-0 text-left"> &nbsp;</h6></td>
                    </tr>
                    <tr style="margin-top:24px;background-color:#E5E5E5;height:80px">
                        <td colspan="2"><h6 class="m-0">{{ $t('PurchaseBillItem.TotalAmount') }} :</h6></td>
                        <td style="" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                            <h6 class="m-0 ">   {{currency}} {{totalAmount | roundOffFilter}}</h6>
                        </td>

                        <td style="">&nbsp;</td>
                    </tr>
                </tbody>
                <!--<tfoot class="text-right">
                    <tr>
                        <td><h6 class="m-0">{{ $t('DailyExpense.TotalAmount') }} :</h6></td>
                        <td>
                            <h6 class="m-0">   {{currency}} {{totalAmount | roundOffFilter}}</h6>
                        </td>

                        <td></td>
                    </tr>

                </tfoot>-->
            </table>
        </div>
    </div>
</template>
<style scoped>
    .borderNone {
        border: none !important
    }
</style>
<script>

    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        props: ['isDisable'],
        data: function () {
            return {
                purchaseBillItems: [],
                purchaseBills: {
                    id: '',
                    description: '',
                    amount: 0.00,
                    accountId: ''
                },
                loading: false,
                refresh: 0,
                currentItem: {
                    id: '',
                    description: '',
                    amount: 0.00,
                    accountId: ''
                },
                addItem: false,
                IsExpenseAccount: false,
                tableLength: 0,
                cardLength: 0,
                currency: '',
            }
        },
        validations: {
            purchaseBills: {
                amount: {

                }
            }
        },
        filters: {
            roundOffFilter: function (value) {

                return parseFloat(value).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
            }
        },

        computed: {


            totalAmount: function () {
                var total = 0;
                if (this.purchaseBillItems !== null && this.purchaseBillItems.length > 0) {
                    this.purchaseBillItems.forEach(function (purchaseBills) {

                        total = parseFloat(total) + parseFloat(purchaseBills.amount);

                    })
                }

                return total;
            },
        },

        methods: {

            checkTableWidth: function () {
                if (document.getElementsByClassName('itemtable')[0] != undefined) {
                    this.tableLength = document.getElementsByClassName('itemtable')[0].clientWidth;
                    this.cardLength = document.getElementsByClassName('card')[0].clientWidth - 25;
                }
            },
            //autoChange: function () {

            //    this.checkTableWidth();

            //    if (this.purchaseBills.accountId != '') {
            //        this.addJvItem();
            //    }
            //},
            //addJvItem: function (value) {
            //    this.loading = true;
            //    this.$emit('itemLoading', this.loading);

            //    if (this.purchaseBills.accountId != '') {
            //        this.purchaseBills.id = this.createUUID();

            //        this.purchaseBillItems.push({
            //            id: this.purchaseBills.id,
            //            accountId: this.purchaseBills.accountId,
            //            description: this.purchaseBills.description,
            //            amount: this.purchaseBills.amount,
            //        });
            //        this.addItem = false;
            //    }
            //    this.loading = false;
            //    this.$emit('itemLoading', this.loading);
            //    this.refresh += 1;
            //    if (value != 'AutoCall') {
            //        if (this.purchaseBills.amount > 0) {
            //            this.currentItem.id = this.purchaseBills.id;
            //            this.currentItem.type = 'Debit';
            //            this.currentItem.amount = this.purchaseBills.amount;
            //        }
            //    }
            //},
            addDailyExpense: function () {

                this.loading = true;
                this.purchaseBillItems.push({
                    id: this.createUUID(),
                    amount: this.purchaseBills.amount,
                    accountId: this.purchaseBills.accountId,
                    description: this.purchaseBills.description
                });

                this.purchaseBills = {
                    id: '',
                    amount: 0.00,
                    description: '',
                    accountId: '',
                };
                this.refresh += 1;
            },
            createUUID: function () {
                var dt = new Date().getTime();
                var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                    var r = (dt + Math.random() * 16) % 16 | 0;
                    dt = Math.floor(dt / 16);
                    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
                });
                return uuid;
            },

            removeExpense: function (id) {

                var ds = this.purchaseBillItems.findIndex(function (i) {
                    return i.id === id;
                });

                this.purchaseBillItems.splice(ds, 1);
            },
        },
        created: function () {

            if (this.$route.query.data != undefined) {


                this.purchaseBillItems = this.$route.query.data.purchaseBillItems;
            }
        },
        updated: function () {

            document.querySelector("html").classList.remove("perfect-scrollbar-on");
            this.$emit('updatedailyExpenseRows', this.purchaseBillItems);
        },
        mounted: function () {

            this.currency = localStorage.getItem('currency');
            this.IsExpenseAccount = localStorage.getItem('IsExpenseAccount') == 'true' ? true : false;


            this.addItem = this.purchaseBillItems.length > 0 ? false : true;
        }
    }</script>