<template>
    <modal :show="show" :modalLarge="true"  v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">

        <div style="margin-bottom:0px" class="card">
            <div class="card-body">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">

                        <div class="modal-header">

                            <h5 class="modal-title" id="myModalLabel">{{ $t('ProductFilterModel.InventoryInformation') }}</h5>



                        </div>
                        <div class="row">
                            <div class="form-group has-label col-sm-6 ">
                                <label class="text  font-weight-bolder"> {{ $t('ProductFilterModel.SelectItem') }} : </label>
                                <product-dropdown :emptyselect="true" v-model="productId" @input="ProductDetail(productId)" width="100%"  />

                            </div>

                        </div>

                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-6" v-for="(inv) in inventory" v-bind:key="inv.id">

                                    <div class="card">
                                        <div class="card-body">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-6" v-if="($i18n.locale == 'en' ||isLeftToRight())">
                                                                <label>{{ $t('ProductFilterModel.WareHouseName') }}:</label>  <h6>{{inv.warehouseName}}</h6>

                                                            </div>
                                                            <div class="col-lg-6" v-else-if="$i18n.locale == 'ar' & inv.warehouseNameArabic!=''">
                                                                <label>{{ $t('ProductFilterModel.WareHouseName') }}:</label>  <h6>{{inv.warehouseNameArabic}}</h6>

                                                            </div>
                                                            <div class="col-lg-6" v-else>
                                                                <label>{{ $t('ProductFilterModel.WareHouseName') }}:</label>  <h6>{{inv.warehouseName}}</h6>

                                                            </div>
                                                            <div class="col-lg-6">
                                                                <label>{{ $t('ProductFilterModel.Quantity') }}:</label>  <h6>{{inv.currentQuantity}}</h6>

                                                            </div>
                                                        </div>

                                                    </div>


                                                </div>
                                            </div>


                                        </div>
                                    </div>


                                </div>

                            </div>
                            <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                                <span style="font-size:medium; font-weight:600">{{ $t('ProductFilterModel.TotalQty') }} : </span>  {{totalQuantity}}
                            </div>


                        </div>



                        <div class="modal-footer justify-content-right">

                            <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()"> {{ $t('ProductFilterModel.btnClear') }}</button>

                        </div>
                        <div class="card-footer col-md-3" v-if="loading">
                            <loading :active.sync="loading" :can-cancel="true" :is-full-page="true"></loading>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </modal>

</template>
<script>
    import Loading from 'vue-loading-overlay';

    import clickMixin from '@/Mixins/clickMixin'
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        components: {
            Loading
        },
        mixins: [clickMixin],
        props: ['show'],
        data: function () {
            return {

                loading: false,
                productId:'',
                inventory: [],
                totalQuantity:0,


            }
        },
        methods: {

            close: function () {



                this.$emit('close', false);


            },
            ProductDetail: function (Id) {

                

                var root = this;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.totalQuantity = 0;
                root.$https.get('/Product/ProductInventoryDetailQuery?Id=' + Id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        
                        if (response.data) {
                            
                            root.inventory = response.data;
                            
                            root.inventory.forEach(function (x) {
                                root.totalQuantity += x.currentQuantity
                            });
                           
                           
                        } else {
                            console.log("error: something wrong from db.");
                        }
                    },
                        function (error) {
                            this.loading = false;
                            console.log(error);
                        });

            },
        },
        mounted: function () {

          
        }
    }
</script>
