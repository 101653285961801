<template>
    <div>
        <multiselect v-model="DisplayValue"
                     :options="options"
                     :multiple="false"
                     track-by="name"
                     :clear-on-select="false"
                     :show-labels="false"
                     label="name"
                     v-bind:placeholder="$t('LogisticDropdown.SelectOption')"
                    v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left ' : 'arabicLanguage '"
                     :preselect-first="true">
        </multiselect>
    </div>
</template>
<script>
    import Multiselect from "vue-multiselect";
    import clickMixin from '@/Mixins/clickMixin'

    export default {
        //name: "SupplierDropdown",
        props: ["values"],
        mixins: [clickMixin],
        components: {
            Multiselect,
        },
        data: function () {
            return {
                options: [],
                value: "",
            };
        },
        methods: {
            getData: function () {
                var root = this;
                var token = "";
                if (this.$session.exists()) {
                    token = localStorage.getItem("token");
                }

                this.$https
                    .get('/Region/LogisticList?logisticsForm=Transporter' + '&isActive=true', {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then(function (response) {

                        if (response.data != null) {
                            
                            response.data.results.logistics.forEach(function (sup) {
                                root.options.push({
                                    id: sup.id,
                                    name: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? (sup.englishName != '' && sup.englishName != null) ? sup.code + ' ' + sup.englishName : sup.code + ' ' + sup.arabicName : (sup.arabicName != '' && sup.arabicName != null) ? sup.code + ' ' + sup.arabicName : sup.code + ' ' + sup.name
                                });
                            });
                        }
                    })
                    .then(function () {
                        root.value = root.options.find(function (x) {
                            return x.id == root.values;
                        });
                    });
            },
        },
        computed: {
            DisplayValue: {
                get: function () {
                    if (this.value != "" || this.value != undefined) {
                        return this.value;
                    }
                    return this.values;
                },
                set: function (value) {

                    if (value != null) {
                        this.value = value;
                        this.$emit("input", value.id);
                    }
                    else {
                        this.value = value;
                        this.$emit("input", null);
                    }
                },
            },
        },
        mounted: function () {
            this.getData();
        },
    };
</script>