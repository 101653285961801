<template>
    <div class="col-lg-12 ml-auto mr-auto" v-if="isValid('CanAddDispatchNote')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
        <div class="card">
            <div class="card-body">

                <div class="row">
                    <div class="col-lg-6 ">
                        <span v-if="purchase.id === '00000000-0000-0000-0000-000000000000'"> <span class="MainLightHeading">{{ $t('AddDispatchNote.DispatchNote') }} - </span><span class="DayHeading">{{purchase.registrationNo}}</span></span>
                        <span v-else><span class="MainLightHeading">{{ $t('AddDispatchNote.UpdateDispatchNote') }} - </span><span class="DayHeading">{{purchase.registrationNo}}</span></span>
                    </div>
                    <div class="col-lg-6">
                        <h6 class="text-right">{{purchase.date}}</h6>
                    </div>
                </div>


                <div class="row mt-4">
                    <!--<div class="col-lg-6">
                        <label>{{ $t('Sale.DispatchNoteNo') }} :<span class="LabelColour"> *</span></label>
                        <div>
                            <input readonly
                                   class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'"
                                   v-model="purchase.registrationNo" />
                            <span v-if="$v.purchase.registrationNo.$error"
                                  class="error text-danger">
                            </span>
                        </div>
                    </div>-->
                    <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                        <label>{{ $t('AddDispatchNote.Customer') }} :<span class="LabelColour"> *</span></label>
                        <div v-bind:class="{'has-danger': $v.purchase.customerId.$error,}">
                            <customerdropdown v-model="$v.purchase.customerId.$model" v-bind:values="purchase.customerId" :key="saleOrderRender" />
                            <span v-if="$v.purchase.customerId.$error"
                                  class="error text-danger">
                            </span>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                        <label>{{ $t('AddDispatchNote.Refrence') }} :</label>
                        <div v-bind:class="{'has-danger': $v.purchase.refrence.$error,}">
                            <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" v-model="$v.purchase.refrence.$model" />
                            <span v-if="$v.purchase.refrence.$error"
                                  class="error text-danger">
                            </span>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                        <label>{{ $t('AddDispatchNote.SaleOrder') }} :</label>
                        <div>
                            <saleorderdropdown @input="GetSaleOrderData" v-bind:values="purchase.saleOrderId" />
                        </div>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-lg-12">
                        <dispatch-item @input="SavePurchaseItems" :purchaseItems="purchase.dispatchNoteItems" :key="saleOrderRender" />
                    </div>
                    <div class="col-lg-12 mt-3">
                        <label>{{ $t('AddDispatchNote.TermandCondition') }}:</label>
                        <textarea class="form-control " rows="3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'"
                                  v-model="purchase.note" />
                    </div>
                </div>

                <div v-if="!loading" class="col-md-12 text-right">
                    <div v-if="purchase.id === '00000000-0000-0000-0000-000000000000'">
                        <button class="btn btn-primary  mr-2"
                                v-if="isValid('CanAddDispatchNote')"
                                v-on:click="savePurchase('Draft')"
                                :disabled="$v.$invalid || purchase.dispatchNoteItems.filter(x => x.quantity=='').length > 0">
                            <i class="far fa-save"></i>  {{ $t('AddDispatchNote.SaveAsDraft') }}
                        </button>
                        <button class="btn btn-primary  mr-2"
                                v-if="isValid('CanAddDispatchNote')"
                                v-on:click="savePurchase('Approved')"
                                :disabled="$v.$invalid || purchase.dispatchNoteItems.filter(x => x.quantity=='').length > 0">
                            <i class="far fa-save"></i>  {{ $t('AddDispatchNote.SaveAsPost') }}
                        </button>
                        <button class="btn btn-danger  mr-2"
                                v-on:click="goToPurchase">
                            {{ $t('AddDispatchNote.Cancel') }}
                        </button>
                    </div>
                    <div v-else>
                        <button class="btn btn-primary  mr-2"
                                v-if="isValid('CanAddDispatchNote')"
                                v-on:click="savePurchase('Draft')"
                                :disabled="$v.$invalid || purchase.dispatchNoteItems.filter(x => x.quantity=='').length > 0">
                            <i class="far fa-save"></i>  {{ $t('AddDispatchNote.UpdateAsDraft') }}
                        </button>

                            <button class="btn btn-primary  mr-2"
                                    v-if="isValid('CanAddDispatchNote')"
                                    v-on:click="savePurchase('Approved')"
                                    :disabled="$v.$invalid || purchase.dispatchNoteItems.filter(x => x.quantity=='').length > 0">
                                <i class="far fa-save"></i> {{ $t('AddDispatchNote.SaveAsPost') }}
                            </button>
                            <button class="btn btn-danger  mr-2"
                                    v-on:click="goToPurchase">
                                {{ $t('AddDispatchNote.Cancel') }}
                            </button>
                        </div>




                </div>
                <div class="card-footer col-md-3" v-else>
                    <loading :active.sync="loading"
                             :can-cancel="true"
                             :on-cancel="onCancel"
                             :is-full-page="true"></loading>
                </div>

            </div>
        </div>
    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>
<style scoped>
    input {
        height: 40px !important;
    }
</style>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    //import Loading from 'vue-loading-overlay';
    import moment from "moment";
    import "vue-loading-overlay/dist/vue-loading.css";
    import { required } from "vuelidate/lib/validators";
    //import Multiselect from 'vue-multiselect';


    //import VueBarcode from 'vue-barcode';
    export default {
        mixins: [clickMixin],
        //components: {
        //    Multiselect,
        //},

        data: function () {
            return {
                purchase: {
                    id: "00000000-0000-0000-0000-000000000000",
                    date: "",
                    registrationNo: "",
                    customerId: "",
                    saleOrderId: null,
                    refrence: "",
                    purchaseOrder: "",
                    note: '',
                    dispatchNoteItems: [],
                },
                loading: false,
                saleOrderRender: 0
            };
        },
        validations: {
            purchase: {
                date: { required },
                registrationNo: { required },
                customerId: { required },
                refrence: {},
                /* dispatchNoteItems: { required },*/
            },
        },
        methods: {


            AutoIncrementCode: function () {
                var root = this;
                var token = "";
                if (root.$session.exists()) {
                    token = localStorage.getItem("token");
                }
                root.$https
                    .get("/Sale/DispatchNoteAutoGenerateNo", {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then(function (response) {
                        if (response.data != null) {
                            root.purchase.registrationNo = response.data;
                        }
                    });
            },
            SavePurchaseItems: function (dispatchNoteItems) {

                this.purchase.dispatchNoteItems = dispatchNoteItems;
            },
            savePurchase: function (status) {
                this.purchase.approvalStatus = status
                this.loading = true;
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https
                    .post('/Sale/SaveDispatchNote', root.purchase, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {
                        root.loading = false
                        root.info = response.data

                        root.$swal({
                            title: "Saved!",
                            text: "Data Saved Successfully!",
                            type: 'success',
                            icon: 'success',
                            timer: 1500,
                            timerProgressBar: true,
                        })
                            .then(function (response) {
                                if (response != undefined) {
                                    if (root.purchase.id == "00000000-0000-0000-0000-000000000000") {
                                        root.$router.go('AddDispatchNote');

                                    } else {
                                        root.$router.push("DispatchNotes");
                                    }
                                }
                            });

                    })
                    .catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Something Went Wrong!',
                                text: error,
                            });

                        root.loading = false
                    })
                    .finally(() => root.loading = false)

            },

            GetSaleOrderData: function (id) {

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                if (id != undefined && id != null) {
                    root.$https.get('/Purchase/SaleOrderDetail?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } })
                        .then(function (response) {
                            if (response.data != null) {
                                root.purchase.date = moment(response.data.date).format('llll');
                                root.purchase.saleOrderId = response.data.id;
                                root.purchase.refrence = response.data.refrence;
                                root.purchase.customerId = response.data.customerId;
                                root.purchase.note = response.data.note;
                                root.purchase.dispatchNoteItems = response.data.saleOrderItems;
                                root.saleOrderRender++;

                            }
                        });
                }
            },
            goToPurchase: function () {
                this.$router.push('/DispatchNotes');
            },
        },
        created: function () {
            this.$emit('input', this.$route.name);
            if (this.$route.query.data != undefined) {
                this.purchase = this.$route.query.data;
                this.purchase.date = moment(this.purchase.date).format('LLL');
                this.attachment = true;
            }
        },
        mounted: function () {

            if (this.$route.query.data == undefined) {
                this.AutoIncrementCode();
                this.purchase.date = moment().format('LLL');
            }





        },
    };
</script>
