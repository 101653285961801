<template>
    <div v-if="isValid('CanViewIncomeStatementReport')" v-bind:style="$i18n.locale == 'ar' ? languageChange('en') : languageChange('ar')">
        <div  v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-md-6 col-lg-6">
                            <h5 class="ml-3 DayHeading">{{ $t('IncomeStatementReport.IncomeStatementReport') }}</h5>
                        </div>
                        <div class="col-md-6 col-lg-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">

                            <router-link :to="'/AllReports'">
                                <a href="javascript:void(0)" class="btn btn-outline-primary  "><i class="fas fa-arrow-circle-left fa-lg"></i> </a>
                            </router-link>
                            <a href="javascript:void(0)" class="btn btn-outline-primary" v-on:click="PrintCsv">Export CSV</a>

                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="col-lg-12">
                        <div class="row">
                            <div class="col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label>{{ $t('IncomeStatementReport.FromDate') }}</label>
                                    <datepicker v-model="fromDate" :key="rander" />
                                </div>
                            </div>
                            <div class="col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label>{{ $t('IncomeStatementReport.ToDate') }}</label>
                                    <datepicker v-model="toDate" :key="rander" />
                                </div>
                            </div>

                        </div>
                        <br />
                        <div class="table  ">
                            <table class="table table-striped table-hover">
                                <thead>
                                    <tr><h5>{{$t('IncomeStatementReport.Revenue')}}</h5></tr>
                                    <tr>
                                        <th style="width: 20%">#</th>
                                        <th style="width: 20%">{{ $t('IncomeStatementReport.Code') }}</th>
                                        <th style="width: 30%">{{ $t('IncomeStatementReport.Account') }}</th>
                                        <th>{{$t('IncomeStatementReport.Amount')}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(sales,index) in Income" v-bind:key="sales.Id">
                                        <td>{{index+1}}</td>
                                        <td>{{sales.code}}</td>
                                        <td>
                                            <span v-if="language=='en'">{{sales.costCenter}}</span>
                                            <span v-else>{{sales.costCenterArabic}}</span>
                                        </td>
                                        <td>{{Number(Math.abs(parseFloat(sales.amount).toFixed(2))).toLocaleString()}}</td>
                                        <hr />
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td><b>{{ $t('IncomeStatementReport.Total') }}</b></td>
                                        <td><b>{{Number(parseFloat(totalIncome).toFixed(2)).toLocaleString()}}</b></td>
                                    </tr>
                                </tbody>
                                <thead>
                                    <tr>
                                        <h5>{{$t('IncomeStatementReport.Expenses')}}</h5>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(expense,index) in Expenses" v-bind:key="expense.Id">
                                        <td>{{index+1}}</td>
                                        <td>{{expense.code}}</td>
                                        <td>
                                            <span v-if="language=='en'">{{expense.costCenter}}</span>
                                            <span v-else>{{expense.costCenterArabic}}</span>
                                        </td>
                                        <td>{{Number(Math.abs(parseFloat(expense.amount).toFixed(2))).toLocaleString()}}</td>
                                        <hr />
                                    </tr>

                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td><b>{{ $t('IncomeStatementReport.Total') }}</b></td>
                                        <td><b>{{Number((parseFloat(totalExpenses).toFixed(2))).toLocaleString()}}</b></td>
                                        <hr />
                                    </tr>
                                    <tr>
                                        <td></td><td></td>
                                        <td><b>{{ $t('IncomeStatementReport.NetIncome')}}</b></td>
                                        <td :class="{'text-danger': isNegative}"><b>{{Number(parseFloat(NetIncome).toFixed(2)).toLocaleString()}}</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'


    import moment from "moment";
    export default {
        mixins: [clickMixin],
        data: function () {
            return {
                date:'',
                fromDate: '',
                toDate: '',
                rander: 0,
                printRender: 0,
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                isShown: false,
                Income: [],
                Expenses: [],
                advanceFilters: false,
                combineDate: '',
                language: 'Nothing',
                totalIncome:0,
                totalExpenses: 0,
                NetIncome: 0,
                isNegative: false

            }
        },
        watch: {
            toDate: function (todate) {
                var fromdate = this.fromDate;

                this.GetInventoryList(fromdate, todate);
            },
            fromDate: function (fromdate) {
                var todate = this.toDate;

                this.GetInventoryList(fromdate, todate);

            }
        },
        methods: {
            PrintCsv: function () {
                
                var root = this;
                root.$https.get('/Report/IncomeStatementCsv?language=' + this.$i18n.locale + '&fromDate=' + this.fromDate + '&toDate=' + this.toDate + '&companyId=' + localStorage.getItem('CompanyID'), { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` }, responseType: 'blob' })
                    .then(function (response) {
                        
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;

                        link.setAttribute('download', 'IncomeStatementReport.csv');


                        document.body.appendChild(link);
                        link.click();

                    });
            },
            languageChange: function (lan) {
                if (this.language == lan) {

                    var getLocale = this.$i18n.locale;
                    this.language = getLocale;

                    this.$router.go('/IncomeStatementReport');

                }
            },
            convertDate: function (date) {
                return moment(date).format('l');
            },
            getPage: function () {
                this.GetInventoryList(this.fromDate, this.toDate, this.currentPage);
            },

            GetInventoryList: function (fromdate, todate) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.get('/Report/IncomeStatement?fromDate=' + fromdate + '&toDate=' + todate , { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {
                            
                            root.Income = response.data.income;
                            root.totalIncome = Math.abs(root.Income.reduce(function (prev, item) {
                                return prev + Number(item.amount);

                            }, 0));
                            root.Expenses = response.data.expenses;
                            root.totalExpenses = Math.abs(root.Expenses.reduce(function (prev, item) {
                                return prev + Number(item.amount);

                            }, 0));
                            root.NetIncome = root.totalIncome - root.totalExpenses
                            if (root.NetIncome < 0) {
                                root.isNegative = true
                            } else {
                                root.isNegative = false
                            }
                        }
                    });

            },
        },
        mounted: function () {
            this.language = this.$i18n.locale;
            this.fromDate = moment().subtract(15, 'days').format("DD MMM YYYY");
            this.toDate = moment().format("DD MMM YYYY");
            this.rander++;
        }
    }
</script>