<template>
    <div v-bind:class="$i18n.locale == 'en' ? 'text-left' : 'text-right'">


        <product-dropdown @input="addProduct"
                          width="100%" />
        <div class=" table-responsive mt-3">

            <table class="table add_table_list_bg" style="table-layout:fixed;" v-bind:class="$i18n.locale == 'en' ? 'text-left' : 'arabicLanguage'">
                <thead style="background-color: #F4F6FC;">
                    <tr>
                        <th style="width:5%;">
                            #
                        </th>
                        <th style="width:70%;">
                            Part Name
                        </th>



                        <th style="width: 20%;" class="text-center">
                            Quantity
                        </th>



                        <th style="width: 5%"></th>
                    </tr>
                </thead>
                <tbody id="purchase-item">
                    <template v-for="(prod , index) in purchaseProducts">

                        <tr :key="prod.productId + index" style="background:#EAF1FE;">
                            <td>
                                {{index+1}}
                            </td>
                            <td>
                                {{$i18n.locale == 'en'? products.find(x => x.id == prod.productId).englishName!=''? products.find(x => x.id == prod.productId).englishName : products.find(x => x.id == prod.productId).arabicName :    products.find(x => x.id == prod.productId).arabicName!=''? products.find(x => x.id == prod.productId).arabicName : products.find(x => x.id == prod.productId).englishName}}
                            </td>





                            <td class="border-top-0 text-center">
                                <input type="number" v-model="prod.quantity"
                                       style=""
                                       @focus="$event.target.select()" v-bind:class="$i18n.locale == 'en' ? 'text-left' : 'text-right'"
                                       class="form-control input-border text-center tableHoverOn"
                                       @keyup="updateLineTotal($event.target.value, 'quantity', prod)" />

                            </td>


                            <td class="pt-0">
                                <button @click="removeProduct(prod.rowId)"
                                        title="Remove Item"
                                        class="btn btn-secondary btn-neutral btn-sm btn-round  btn-icon">
                                    <i class="nc-icon nc-simple-remove"></i>
                                </button>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>

        <hr hidden />
        <div class=" table-responsive" v-bind:key="rendered + 'g'" v-if="purchaseProducts.length > 0" hidden>
            <table class="table " style="table-layout:fixed">
                <thead class="m-0">
                    <tr class="text-right">
                        <th class="text-center" style="width:85px;">
                            {{ $t('AddSampleRequest.NoItem') }}
                        </th>
                        <th class="text-center" style="width:100px;">
                            {{ $t('AddSampleRequest.TotalQty') }}
                        </th>
                        <th style="width:100px;">
                            {{ $t('AddSampleRequest.TOTALWITHOUTWASTE') }}
                        </th>
                        <th style="width:100px;">
                            {{ $t('AddSampleRequest.TOTALWITHWASTE') }}
                        </th>

                    </tr>
                </thead>
                <tbody>
                    <tr class="text-right">
                        <td class="text-center">
                            {{ summary.item }}
                        </td>
                        <td class="text-center">
                            {{ summary.qty }}
                        </td>

                        <td>
                            {{ summary.total | formatAmount}}
                        </td>
                        <td>
                            {{ summary.totalWithWaste | formatAmount}}
                        </td>

                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>


<script>
    //import Loading from 'vue-loading-overlay';
    import "vue-loading-overlay/dist/vue-loading.css";
    //import moment from "moment";
    //import VueBarcode from 'vue-barcode';
    export default {
        name: "PurchaseItem",
        props: ['purchase', 'purchaseItems'],

        data: function () {
            return {
                rendered: 0,
                product: {
                    id: "",
                },
                products: [],
                purchaseProducts: [],
                loading: false,

                summary: {
                    item: 0,
                    qty: 0,
                    total: 0,
                    totalWithWaste: 0,
                },
                productList: [],
                searchTerm: '',
                isMultiUnit: ''
            };
        },
        validations: {},
        filters: {

        },
        methods: {
            GetProductList: function () {

                var root = this;
                var token = "";
                if (this.$session.exists()) {
                    token = localStorage.getItem("token");
                }

                debugger;
                this.$https
                    .get("/Product/GetProductBarcode?isRaw=" + false, {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then(function (response) {
                        if (response.data != null) {
                            root.productList = response.data.results.products;

                        }
                    });


            },
            onBarcodeScanned(barcode) {

                if (localStorage.getItem("BarcodeScan") != 'Recipe')
                    return
                var root = this;
                if (root.productList.length > 0) {
                    var product = this.productList.find(x => x.barCode == barcode)
                    if (product != null) {
                        root.addProduct(product.id, product)
                    }
                }


            },
            changeProduct: function (NewProdId, rowId) {
                this.purchaseProducts = this.purchaseProducts.filter(x => x.rowId != rowId);
                this.addProduct(NewProdId);

            },
            calcuateSummary: function () {

                this.summary.item = this.purchaseProducts.length;
                this.summary.qty = this.purchaseProducts.reduce(
                    (qty, prod) => qty + parseInt(prod.quantity == '' ? 0 : prod.quantity), 0);
                this.summary.total = this.purchaseProducts
                    .reduce((total, prod) => total + (prod.highQuantity == '' ? 0 : prod.highQuantity) * prod.unitPerPack + parseFloat(prod.quantity == '' ? 0 : prod.quantity), 0)
                    .toFixed(3).slice(0, -1);
                this.summary.totalWithWaste = this.purchaseProducts
                    .reduce((total, prod) => total + (((prod.highQuantity == '' ? 0 : prod.highQuantity) * prod.unitPerPack) + parseFloat(prod.quantity == '' ? 0 : prod.quantity)) - parseFloat(((((prod.highQuantity == '' ? 0 : prod.highQuantity) * prod.unitPerPack) + parseFloat(prod.quantity == '' ? 0 : prod.quantity)) * prod.waste) / 100), 0)
                    .toFixed(3).slice(0, -1);

                this.$emit("input", this.purchaseProducts);
            },

            updateLineTotal: function (e, prop, product) {

                if (prop == "highQuantity") {
                    if (e <= 0 || e == '') {
                        e = '';
                    }
                    product.highQuantity = Math.round(e);
                }
                if (prop == "quantity") {
                    if (e <= 0 || e == '') {
                        e = '';
                    }
                    product.quantity = Math.round(e);
                }






               

                this.calcuateSummary();

                this.$emit("input", this.purchaseProducts);

            },

            addProduct: function (productId, newProduct) {


                if (this.products.find(x => x.id == newProduct.id) == undefined || this.products.length <= 0) {
                    this.products.push(newProduct);
                }

                var prod = this.products.find((x) => x.id == productId);



                this.purchaseProducts.push({
                    rowId: this.createUUID(),
                    productId: prod.id,
                    unitPrice: 0,
                    highQuantity: 0,
                    unitPerPack: prod.unitPerPack == null ? 0 : prod.unitPerPack,
                    basicUnit: prod.unit == null ? '' : prod.unit.name,
                    quantity: 1,
                    waste: 0,
                    lineTotal: 0,
                    withoutWastageTotal: 0,
                    wareHouseId: '',
                });

                var prodDetail = this.purchaseProducts.find((x) => x.productId == productId);

                this.updateLineTotal(prodDetail.quantity, 'quantity', prodDetail);


                this.product.id = "";
                //    this.rendered++;
            },
            createUUID: function () {
                var dt = new Date().getTime();
                var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                    var r = (dt + Math.random() * 16) % 16 | 0;
                    dt = Math.floor(dt / 16);
                    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
                });
                return uuid;
            },


            removeProduct: function (id) {

                this.purchaseProducts = this.purchaseProducts.filter((prod) => {
                    return prod.rowId != id;
                });

                this.calcuateSummary();
            },

            getData: function () {

                var root = this;
                debugger;
                if (root.$route.query.data != undefined) {
                    //Recipt No Edit
                    if (root.$route.query.data.reparingItems != undefined) {
                        root.$route.query.data.reparingItems.forEach(function (item) {
                            root.purchaseProducts.push({
                                rowId: item.id,
                                id: item.id,
                                product: item.product,
                                productId: item.productId,
                                quantity: item.quantity,
                                unitPerPack: item.unitPerPack,
                            });
                        });

                        for (var k = 0; k < root.purchaseProducts.length; k++) {
                            root.products.push(root.purchaseProducts[k].product);

                            root.updateLineTotal(root.purchaseProducts[k].quantity, "quantity", root.purchaseProducts[k]);
                        }
                        root.calcuateSummary()
                    }

                }

            },
        },
        created: function () {
            //this.$barcodeScanner.init(this.onBarcodeScanned);
            //For Scanner Code
            var root = this;
            var barcode = '';
            var interval;
            document.addEventListener('keydown', function (evt) {
                if (interval)
                    clearInterval(interval);
                if (evt.code === 'Enter') {
                    if (barcode) {
                        root.onBarcodeScanned(barcode);
                    }
                    barcode = '';
                    return;

                }
                if (evt.key !== 'Shift')
                    barcode += evt.key;
            });
            localStorage.setItem("BarcodeScan", 'Recipe')
            //End
            this.getData();
        },
        mounted: function () {
            this.GetProductList();
            this.isMultiUnit = localStorage.getItem('IsMultiUnit');
        },
        destroyed() {
            // Remove listener when component is destroyed
            //this.$barcodeScanner.destroy()
        },
    };
</script>

<style scoped>

    #purchase-item td {
        padding-bottom: 5px;
        padding-top: 5px;
    }

    #purchase-item tr td {
        border-top: 0 !important;
        border-bottom: 0 !important;
    }

    .input-border {
        border: transparent;
        background-color: transparent !important;
    }

        .input-border:focus {
            outline: none !important;
            border: none !important;
        }

    .samary_tbl_pd td {
        padding: 20px 7px !important;
    }

    .multiselect__tags {
        background-color: transparent !important;
    }

    .tableHoverOn {
        background-color: #ffffff !important;
        height: 32px !important;
        max-height: 32px !important;
    }

    .multiselect__input, .multiselect__single {
        background-color: transparent !important;
    }
</style>
