<template>
    <div v-if="isValid('CanViewTrialBalance')" v-bind:style="$i18n.locale == 'ar' ? languageChange('en') : languageChange('ar')">
        <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-md-6 col-lg-6">
                            <h5 class="ml-3 DayHeading">{{ $t('TrialBalanceReport.TrialBalanceReport') }}</h5>
                        </div>
                        <div class="col-md-6 col-lg-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                            <router-link :to="'/AllReports'">
                                <a href="javascript:void(0)" class="btn btn-outline-primary  "><i class="fas fa-arrow-circle-left fa-lg"></i> </a>
                            </router-link>
                            <a href="javascript:void(0)" class="btn btn-outline-primary" v-on:click="PrintCsv">Export CSV</a>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="col-lg-12">
                        <div class="row">
                            <div class="col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label>{{ $t('TrialBalanceReport.FromDate') }}</label>
                                    <datepicker v-model="fromDate" :key="rander" />
                                </div>
                            </div>
                            <div class="col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label>{{ $t('TrialBalanceReport.ToDate') }}</label>
                                    <datepicker v-model="toDate" :key="rander" />
                                </div>
                            </div>

                        </div>
                        <div class="table-responsive">
                            <table class="table table-striped table-hover table_list_bg">
                                <thead class="">
                                    <tr>
                                        <th>#</th>
                                        <th>
                                            {{ $t('TrialBalanceReport.Code') }}
                                        </th>

                                        <th>
                                            {{ $t('TrialBalanceReport.Name') }}
                                        </th>
                                        <th>
                                            {{ $t('TrialBalanceReport.Debit') }}
                                        </th>
                                        <th>
                                            {{ $t('TrialBalanceReport.Credit') }}
                                        </th>
                                        <th>
                                            Total
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(account,index) in accounts" v-bind:key="account.Code">
                                        <td>
                                            {{index+1}}
                                        </td>

                                        <td>
                                            <span>{{account.code}}</span>

                                        </td>

                                        <td>
                                            <span v-if="language=='en'">
                                                <strong>
                                                    <a href="javascript:void(0)" v-on:click="EditCity(account.trialBalanceModel)">  {{account.name}}</a>
                                                </strong>
                                            </span>
                                            <span v-else>
                                                <strong>
                                                    <a href="javascript:void(0)" v-on:click="EditCity(account.trialBalanceModel)">  {{account.nameArabic}}</a>
                                                </strong>

                                            </span>
                                        </td>
                                        <td>{{Number(parseFloat(account.debit).toFixed(2)).toLocaleString()}}</td>
                                        <td>{{Number(Math.abs(parseFloat(account.credit).toFixed(2))).toLocaleString()}}</td>
                                        <td>{{Number(Math.abs(parseFloat(account.debit-account.credit).toFixed(2))).toLocaleString()}}</td>

                                    </tr>
                                  
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td><b> {{$t('TrialBalanceReport.Total')}} </b></td>
                                        <td>
                                            <b>{{Number(parseFloat(totalDebit).toFixed(2)).toLocaleString()}}</b>
                                        </td>
                                        <td>
                                            <b>{{Number(parseFloat(totalCredit).toFixed(2)).toLocaleString()}}</b>
                                        </td>
                                        <td>
                                            <b>{{Number(parseFloat(totalDebit-totalCredit).toFixed(2)).toLocaleString()}}</b>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <trialSubAccount :subAccount="subAccount"
                             :show="show"
                             v-if="show"
                             @close="show = false"
                              />
        </div>
    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'


    import moment from "moment";
    export default {
        mixins: [clickMixin],
        data: function () {
            return {
                date: '',
                fromDate: '',
                toDate: '',
                rander: 0,
                printRender: 0,
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                isShown: false,
                accounts: [],
                subAccount: [],
                advanceFilters: false,
                show: false,
                combineDate: '',
                language: 'Nothing',
                totalDebit: 0,
                totalCredit: 0

            }
        },
        watch: {
            toDate: function (todate) {
                var fromdate = this.fromDate;

                this.GetInventoryList(fromdate, todate);
            },
            fromDate: function (fromdate) {
                var todate = this.toDate;

                this.GetInventoryList(fromdate, todate);

            }
        },
        methods: {
            EditCity: function (subAccount) {
                this.subAccount = subAccount
              
                this.show = !this.show;
            },
            PrintCsv: function () {

                var root = this;
                root.$https.post('/Report/TrialBalanceCsv?language=' + this.$i18n.locale + '&fromDate=' + this.fromDate + '&toDate=' + this.toDate + '&formName=' + this.formName + '&companyId=' + localStorage.getItem('CompanyID'), root.accounts, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` }, responseType: 'blob' })
                    .then(function (response) {

                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;


                        link.setAttribute('download', 'TrialBalanceReport.csv');


                        document.body.appendChild(link);
                        link.click();

                    });
            },

            languageChange: function (lan) {
                if (this.language == lan) {

                    var getLocale = this.$i18n.locale;
                    this.language = getLocale;

                    this.$router.go('/TrialBalanceReport');

                }
            },
            convertDate: function (date) {
                return moment(date).format('l');
            },
            getPage: function () {
                this.GetInventoryList(this.fromDate, this.toDate, this.currentPage);
            },

            GetInventoryList: function (fromdate, todate) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                this.$https.get('/Report/TrialBalanceReportAsync?fromDate=' + fromdate + '&toDate=' + todate, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {
                            
                            root.accounts = response.data;
                            root.totalDebit = root.accounts.reduce(function (prev, item) {
                                return prev + Number(item.debit);

                            }, 0);
                            root.totalCredit = Math.abs(root.accounts.reduce(function (prev, item) {
                                return Math.abs(prev + Number(item.credit));

                            }, 0));
                        }
                    });

            },
        },
        mounted: function () {
            this.language = this.$i18n.locale;
            this.fromDate = moment().subtract(15, 'days').format("DD MMM YYYY");
            this.toDate = moment().format("DD MMM YYYY");
            this.rander++;
        }
    }
</script>
<style scoped>

    table {
        width: 100%;
    }

    thead, tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
    }

    tbody {
        display: block;
        overflow-y: auto;
        table-layout: fixed;
        max-height: 600px;
    }

    ::-webkit-scrollbar {
        width: 11px !important;
        height: 10px !important;
    }
</style>