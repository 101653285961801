<template>
    <div class="row " v-if="isValid('CanViewSignUpUser')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
        <div class="col-lg-12 col-sm-12 ml-auto mr-auto">
            <div class="card ">
                <div class="card-header">
                    <div class="BorderBottom ml-2 mr-2  ">
                        <span class=" DayHeading">{{ $t('SignUp.SignUpDetails') }}</span>
                    </div>
                   
                   
                </div>
           
                <div class="card-body">
                    <div class="col-lg-12">
                        <div class="row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                <div class="form-group">
                                    <label>{{ $t('SignUp.SearchBYNameAndEmail') }}</label>
                                    <div>
                                        <input type="text" class="form-control search_input" v-model="searchQuery" name="search" id="search" :placeholder="$t('SignUp.Search')" />
                                        <span class="fas fa-search search_icon"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'" style="margin-top:18px;">
                                <a v-if="isValid('CanAddSignUpUser')" href="javascript:void(0)" class="btn btn-primary" v-on:click="AddSignup"><i class="fa fa-plus"></i>  {{ $t('SignUp.AddNew') }}</a>
                                <router-link :to="'/UserSetup'">
                                    <a href="javascript:void(0)" class="btn btn-outline-danger ">  {{ $t('SignUp.Close') }} </a>
                                </router-link>
                            </div>

                        </div>
                        
                        <div class="mt-2">
                            <div class=" table-responsive">
                                <table class="table table-striped table-hover table_list_bg ">
                                    <thead class="m-0">
                                        <tr>
                                            <th>#</th>

                                            <th>
                                                {{ $t('SignUp.USERNAME') }}
                                            </th>
                                            <th>
                                                {{ $t('SignUp.EMAILID') }}
                                            </th>
                                            <th>
                                                {{ $t('SignUp.UserRole') }}
                                            </th>
                                            <th>
                                                {{ $t('SignUp.Location') }}
                                            </th>
                                            <!--<th>
                            {{ $t('SignUp.ACTION') }}
                        </th>-->
                                        <th>
                                            {{ $t('SignUp.Status') }}
                                        </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(details,index) in resultQuery" v-bind:key="details.id">
                                            <td>
                                                {{index+1}}
                                            </td>
                                            <td v-if="isValid('CanEditSignUpUser')">
                                                <strong>
                                                    <a href="javascript:void(0)" v-on:click="EditInfo(details.id)">{{details.fullName}}</a>
                                                </strong>
                                            </td>
                                            <td v-else>
                                                {{details.fullName}}
                                            </td>
                                            <td>{{details.email}}</td>
                                            <td>{{details.roleName}}</td>
                                            <td>{{details.companyName}}</td>
                                            <!--<td>
                            <a href="javascript:void(0)" class="btn btn-danger btn-sm btn-icon " v-on:click="DeleteData(details.id)"><i class=" fa fa-trash"></i></a>
                        </td>-->
                                            <td v-if="details.isActive">
                                                <toggle-button v-if="details.employeeNo!='EM-00001'" :value=false v-on:change="EditEmployeeStatus(details.id, true)" class="mr-2" color="#3178F6" />
                                            </td>
                                            <td v-else>
                                                <toggle-button v-if="details.employeeNo!='EM-00001'" :value=true v-on:change="EditEmployeeStatus(details.id, false)" class="mr-2" color="#3178F6" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        name: 'signUp',
        data: function () {
            return {
                searchQuery: '',
                show: false,
                loginList:[

                ],
                user: {
                    id: '',
                    isActive: '',
                    isUser: true,
                },

            }
        },
        computed: {
            resultQuery: function () {
                var root = this;
                if (this.searchQuery) {
                    return this.loginList.filter((city) => {
                        return root.searchQuery.toLowerCase().split(' ').every(v => city.email.toLowerCase().includes(v) || city.fullName.toLowerCase().includes(v))
                    })
                } else {
                    return root.loginList;
                }
            },
        },

        methods: {
            AddSignup: function () {
               this.$router.push('/AddSignUp') 
            },
            GetData: function () {
                var root = this;
                var url = '/account/UserList';
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get(url, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.loginList = response.data;
                    }
                });
            },
            EditInfo: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/account/UserDetail?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        
                        root.$router.push({
                            path: '/AddSignUp',
                            query: { data: response.data }
                        })
                    }
                });
                
            },
            DeleteData: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/account/UserDelete?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        
                        root.$swal.fire({
                            icon:'warning',
                            title:'Deleted Successfully',
                            showConfirmButton: false,
                            timer: 800,
                            timerProgressBar: true,
                        });
                        root.GetData();
                    }
                });
            },

            EditEmployeeStatus: function (Id, isActive) {

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                this.user.id = Id;
                this.user.isActive = !isActive;

                root.$https.post('/EmployeeRegistration/SaveEmployeeStatus', root.user, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != '00000000-0000-0000-0000-000000000000') {
                            root.GetData();
                            root.$swal.fire({
                                icon: 'success',
                                title: 'Employee Status Change',
                                showConfirmButton: false,
                                timer: 1800,
                                timerProgressBar: true,

                            });
                        }
                    },
                        function (error) {
                            root.loading = false;
                            console.log(error);
                        });

            }

        },
        created: function () {
            this.$emit('input', this.$route.name);
        },
        mounted: function () {
            this.GetData();
        }
    }
</script>