<template>
    <div class="row" v-if="isValid('CanViewLoanPayment')">
        <div class="col-lg-12 col-sm-12 ml-auto mr-auto" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">

            <div class="card">
                <div class="BorderBottom ml-2 mr-2 mt-3 mb-3">
                    <span class=" DayHeading"> {{ $t('LoanPayment.LoanPayment') }}</span>
                </div>
                <div class="card-body">
                    <div class="row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label>{{ $t('LoanPayment.SearchByEmployee') }}</label>
                                <div>
                                    <input type="text" class="form-control search_input" v-model="search" name="search" id="search" :placeholder="$t('LoanPayment.Search')" />
                                    <span class="fas fa-search search_icon"></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                            <a v-if="isValid('CanAddLoanPayment')" href="javascript:void(0)" class="btn btn-primary " style="margin-top:27px;" v-on:click="AddLoanPayment"><i class="fa fa-plus"></i> {{ $t('LoanPayment.AddNew') }} </a>
                            <router-link :to="'/HrSetup'">
                                <a href="javascript:void(0)" class="btn btn-outline-danger " style="margin-top:27px;">  <i class="fas fa-arrow-circle-left fa-lg"></i> </a>
                            </router-link>
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <table class="table table-striped table-hover table_list_bg" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <thead v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                    <tr>
                                        <th>#</th>
                                        <th>
                                            {{ $t('LoanPayment.Employee') }}
                                        </th>
                                        <th>
                                            {{ $t('LoanPayment.LoanDate') }}
                                        </th>

                                        <th>
                                            {{ $t('LoanPayment.LoanType') }}
                                        </th>
                                        <th>
                                            {{ $t('LoanPayment.Loans') }}
                                        </th>
                                        <th>
                                            {{ $t('LoanPayment.RecoveryLoans') }}

                                        </th>
                                        <th>
                                            {{ $t('LoanPayment.Payment') }}

                                        </th>
                                        <th>
                                            {{ $t('LoanPayment.RecoveryBalance') }}
                                        </th>
                                        <th>
                                            {{ $t('LoanPayment.Status') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                    <tr v-for="(loanPayment ,index) in loanPaymentList" v-bind:key="loanPayment.id">
                                        <td v-if="currentPage === 1">
                                            {{index+1}}
                                        </td>
                                        <td v-else>
                                            {{((currentPage*10)-10) +(index+1)}}
                                        </td>
                                        <td>
                                            <strong>
                                                <!--<a href="javascript:void(0)" v-on:click="EditLoanPayment(loanPayment.id)">{{loanPayment.employeeName}}</a>-->
                                                {{loanPayment.employeeName}}
                                            </strong>
                                        </td>
                                        <th>
                                            {{loanPayment.loanDate}}

                                        </th>
                                        <td>
                                            {{GetLoanType(loanPayment.loanType)}}
                                        </td>
                                        <td>
                                            {{currency}}  {{parseFloat(loanPayment.loanAmount).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                        </td>
                                        <td v-if="isValid('CanEditLoanPayment')">
                                            <strong>
                                                <a href="javascript:void(0)" v-on:click="LoanDetail(loanPayment)"> {{currency}}  {{parseFloat(loanPayment.recoveryLoanAmount).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</a>
                                            </strong>
                                          
                                        </td>
                                        <td v-else>
                                            <strong>
                                                {{currency}}  {{parseFloat(loanPayment.recoveryLoanAmount).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                            </strong>
                                          
                                        </td>
                                        <td>
                                            {{currency}}  {{parseFloat(loanPayment.payment).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                        </td>
                                        <td>
                                            {{currency}}  {{parseFloat(loanPayment.remainingLoan).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                        </td>
                                        <td>
                                            <span v-if="loanPayment.isActive" class="badge badge-danger">
                                                {{ $t('LoanPayment.Close') }}
                                            </span>
                                            <span v-else class="badge badge-success">
                                                {{ $t('LoanPayment.Open') }}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="float-left">
                        <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                        <span v-else-if="currentPage===1 && rowCount < 10">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage===1 && rowCount >= 11  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                    </div>
                    <div class="float-right">
                        <div class="overflow-auto" v-on:click="GetBrandData()">
                            <b-pagination pills size="lg" v-model="currentPage"
                                          :total-rows="rowCount"
                                          :per-page="10"
                                          first-text="First"
                                          prev-text="Previous"
                                          next-text="Next"
                                          last-text="Last"></b-pagination>
                        </div>
                    </div>

                </div>
            </div>
            <loanDetailModel :loanDetail="loanDetail"
                        :show="show"
                        v-if="show"
                        @close="RefreshRecord"
                         />
        </div>
       
    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>


<script>
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        data: function () {
            return {
                searchQuery: '',
                currency: '',
                loanPaymentList: [],
                search: '',
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                arabic: '',
                english: '',
                show: false,
                loanDetail:[]
            }
        },
        watch: {
            search: function () {
                this.GetLoanPaymentData();
            }
        },
        methods: {
            AddLoanPayment: function () {
                this.$router.push('/AddLoanPayment');

            },
            GetLoanType: function (x) {
                if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {
                    // Loan Type
                    if (x == 1) {
                        return 'Loan'
                    }
                    else if (x == 2) {
                        return  'Advance'
                    }
                    else if (x== 3) {
                        return  'Provident Fund'
                    }


                  

                    // Recovery Method

                   
                }
                else {
                    // Loan Type
                    if (x== 1) {
                        return  'يقرض'
                    }
                    else if (x== 2) {
                        return  'تقدم'
                    }
                    else if (x== 3) {
                        return  'صندوق التوفير او الادخار'
                    }


                    // Recovery Method

                  
                }
            },
            LoanDetail: function (loanPaymentList) {
                this.loanDetail = loanPaymentList
                
                this.show = !this.show;
            },
            RefreshRecord: function () {
                this.show = false;
                this.GetLoanPaymentData();
            },
            getPage: function () {
                this.GetLoanPaymentData();
            },


            GetLoanPaymentData: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                root.$https.get('Payroll/LoanPaymentList?searchTerm=' + this.search + '&pageNumber=' + this.currentPage, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        
                        root.loanPaymentList = response.data.results;
                        root.pageCount = response.data.pageCount;
                        root.rowCount = response.data.rowCount;
                        root.loading = false;
                    }
                    root.loading = false;
                });
            },

            EditLoanPayment: function (Id) {

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Payroll/LoanPaymentDetail?Id=' + Id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data) {
                            
                            root.$router.push({
                                path: '/AddLoanPayment',
                                query: { data: response.data }
                            })



                        }
                    },
                        function (error) {
                            this.loading = false;
                            console.log(error);
                        });

            }
        },

        created: function () {
            this.$emit('input', this.$route.name);
        },

        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.currency = localStorage.getItem('currency');
            this.GetLoanPaymentData();

        }
    }
</script>