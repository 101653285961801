<template>
    <div id="chartofaccount" v-cloak v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-if=" isValid('CanViewCOA')">


        <div >
            <div v-if="loading">
                <div class="row">
                    <div class="col-lg-5 mr-auto ml-auto text-center">
                        <div class="loadingio-spinner-dual-ball-44dlc48bacw">
                            <div class="ldio-m86dw9oanea">
                                <div> </div>
                                <div> </div>
                                <div> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="card mt-4">
                    <div class="card-header  mb-2">
                     
                        <span class="text-center mt-4 mb-1 DayHeading">{{ $t('AddUpdateAccount.ChartOfAccount') }}</span>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="mb-1" >
                                    <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'arabicLanguage'">
                                        <ul class="nav nav-tabs" data-tabs="tabs">
                                            <li class="nav-item" v-for="accountType in accounts.accountTypes" v-bind:key="accountType.id">
                                                <a class="nav-link" v-bind:class="{'active show':active == accountType.name}" v-on:click="makeActive(accountType.name)" :id="accountType.name" data-toggle="pill" :href="'#'+accountType.id" role="tab" :aria-controls="accountType.id" aria-selected="true">{{($i18n.locale == 'en')?accountType.name:accountType.nameArabic}}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="tab-content" id="nav-tabContent">
                                    <div v-for="accountType in accounts.accountTypes" v-bind:key="accountType.code">
                                        <div v-if="active == accountType.name">
                                            <div class="mt-2">
                                                <div v-for="costCenter in accountType.costCenters" v-bind:key="costCenter.code">
                                                    <div class="accordion" role="tablist">
                                                        <b-card no-body class="mb-1">
                                                            <b-card-header header-tag="header" class="p-1" role="tab">
                                                                <b-button block v-b-toggle.accordion-1 variant="primary" v-on:click="makeActiveCollapse(costCenter.code)">{{$i18n.locale == 'en' ?costCenter.code+': '+  costCenter.name :costCenter.code+': '+ costCenter.nameArabic }}</b-button>
                                                            </b-card-header>
                                                            <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel" v-if="costCenter.code==collpase">
                                                                <b-card-body>
                                                                    <div class="card-header">
                                                                        <h5 class="card-title" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">{{$i18n.locale == 'en' ?costCenter.code+': '+  costCenter.name :costCenter.code+': '+ costCenter.nameArabic }}</h5>
                                                                    </div>
                                                                    <div class="card-body">

                                                                        <table class="table table-striped table-hover table_list_bg" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>
                                                                                        {{ $t('AddUpdateAccount.Code') }}
                                                                                    </th>
                                                                                    <th v-if="english=='true'">
                                                                                        {{ $t('AddUpdateAccount.Name') |englishLanguage }}
                                                                                    </th>
                                                                                    <th v-if="isOtherLang()">
                                                                                        {{ $t('AddUpdateAccount.Name') |arabicLanguage }}
                                                                                    </th>
                                                                                    <th>
                                                                                        {{ $t('AddUpdateAccount.Status') }}
                                                                                    </th>
                                                                                    <!--<th>
                        Opening Balance
                    </th>-->
                                                                                    <th v-if=" isValid('CanEditCOA')">
                                                                                        {{ $t('AddUpdateAccount.Action') }}
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr v-for="account in costCenter.accounts " v-bind:key="account.id">
                                                                                    <td>
                                                                                        {{account.code}}

                                                                                    </td>
                                                                                    <td v-if="english=='true'">
                                                                                        {{account.name}}<br />
                                                                                        <span class="small">{{account.description}}</span>
                                                                                    </td>
                                                                                    <td v-if="isOtherLang()">
                                                                                        {{account.nameArabic}}<br />
                                                                                        <span class="small">{{account.description}}</span>
                                                                                    </td>
                                                                                    <td>{{account.isActive==true?$t('AddUpdateAccount.Active'):$t('AddUpdateAccount.De-Active')}}</td>
                                                                                    <td v-if="(costCenter.code=='160000' || costCenter.code=='120000' || costCenter.code=='200000' || costCenter.code=='210000') && isValid('CanEditCOA')">
                                                                                    </td>
                                                                                    <td v-else-if="isValid('CanEditCOA')">
                                                                                        <button title="Edit Account"  class="btn btn-success  btn-icon btn-sm" v-on:click="updateModal(account.id, accountType.id, 'Edit')">
                                                                                            <i class="fas fa-pencil-alt"></i>
                                                                                        </button>
                                                                                        &nbsp;
                                                                                        <!--<button title="Delete Account" class="btn btn-danger  btn-icon btn-sm" v-on:click="removeAccount(account.id,costCenter.id,accountType.id)">
                            <i class="fa fa-trash"></i>
                        </button>-->
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                            <tfoot>
                                                                                <tr>
                                                                                    <td colspan="4" class="text-center" v-if=" isValid('CanAddCOA')">
                                                                                        <button title="Add New Account" class="btn btn-primary  btn-icon" v-on:click="addModal(costCenter.id, accountType.id, 'Add')">
                                                                                            <i class="nc-icon nc-simple-add"></i>
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            </tfoot>
                                                                        </table>

                                                                    </div>

                                                                </b-card-body>
                                                            </b-collapse>
                                                        </b-card>

                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <coa-modal :show="showModal"
                   :account="newAccount"
                   :account-type-id="accountTypeId"
                   v-on:close="showModal = false"
                   :type="type"
                   v-if="showModal" />
    </div>
    <acessdenied v-else :model=true></acessdenied>
</template>
<script>

    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        data: function () {
            return {
                accounts: '',
                arabic: '',
                english: '',
                Accounts: '',
                poItem: [],
                loading: false,
                showModal: false,
                active: 'Assets',
                type: "",
                template: '',
                collpase: '',
                accountTypeId: '',
                accountType: '',
                newAccount: {
                    costCenterId: '',
                    code: '',
                    description: '',
                    id: '',
                    name: '',
                    nameArabic: '',
                    openingBalance: '',
                    runingBalance: '',
                    isActive: true
                },
                stepsVm: {
                    companyId: '',
                    step1: false,
                    step2: false,
                    step3: false,
                    step4: false,
                },
                templatetype: 'Business',
                accountslist: []
            }
        },
        methods: {
            makeActiveCollapse: function (item) {
                this.collpase = item;
            },
            makeActive: function (item) {
                this.active = item;
            },
            removeAccount: function (Id, costCenterId, accountTypeId) {
                var root = this;
                // working with IE and Chrome both


                this.$swal({
                    title: "Are you sure?",
                    text: "You will not be able to recover this!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes, delete it!"
                }).then(function () {


                    var token = '';
                    if (root.$session.exists()) {
                        token = localStorage.getItem('token');
                    }
                    root.$https.get('/Accounting/RemoveAccount?Id=' + Id, { headers: { "Authorization": `Bearer ${token}` } })
                        .then(function (response) {
                            if (response.data) {
                                var accountDelete = root.accounts.accountTypes.find(function (x) {
                                    return x.id === accountTypeId;
                                }).costCenters.find(function (y) {
                                    return y.id === costCenterId;
                                }).accounts.findIndex(function (z) {
                                    return z.id === Id;
                                });
                                root.accounts.accountTypes.find(function (x) {
                                    return x.id === accountTypeId;
                                }).costCenters.find(function (y) {
                                    return y.id === costCenterId;
                                }).accounts.splice(accountDelete, 1);

                                root.$swal({
                                    title: 'Deleted!',
                                    text: 'Account has been Deleted.',
                                    type: 'success',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                    timerProgressBar: true,
                                });
                            } else {
                                console.log("error: something wrong from db.");
                            }
                        },
                            function (error) {
                                root.$swal({
                                    title: 'Error!',
                                    type: 'error',
                                    text: error,
                                    icon: 'error',
                                    showConfirmButton: false,
                                    timer: 800,
                                    timerProgressBar: true,
                                });
                            });
                });
            },
            createChartOfAccount: function (template) {

                var root = this;
                this.loading = true;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                root.$https.get('/Accounting/TemplateAccountSetup?template=' + template, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(

                        root.stepsVm.companyId = localStorage.getItem('CompanyID'),
                        root.stepsVm.step1 = true,

                        root.$https.post('/account/SetupUpdateInCompany', root.stepsVm, { headers: { "Authorization": `Bearer ${token}` } })
                            .then(function (response) {

                                if (response.data) {
                                    localStorage.setItem('coa', true);
                                    if (root.$route.query.IsSetup != undefined) {
                                        root.$router.push({
                                            path: '/Setup',
                                            query: {
                                                coa: true
                                            }
                                        });
                                    }
                                } else {
                                    console.log("error: something wrong from db.");
                                }
                            })



                    )
            },
            updateModal: function (Id, accountTypId, type) {
                var root = this;
                this.showModal = !this.showModal;
                this.type = type;
                this.accountTypeId = accountTypId;

                // working with IE and Chrome both



                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Accounting/GetAccount?Id=' + Id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {

                        if (response.data) {
                            root.newAccount = {
                                id: response.data.id,
                                code: response.data.code,
                                costCenterId: response.data.costCenterId,
                                name: response.data.name,
                                nameArabic: response.data.nameArabic,
                                isActive: response.data.isActive,
                                description: response.data.description,
                                openingBalance: response.data.openingBalance,
                                runingBalance: response.data.runingBalance,
                            };
                        } else {
                            console.log("error: something wrong from db.");
                        }
                    },
                        function () {
                            this.loading = false;
                        });

            },

            addModal: function (costCenterId, accountTypId, type) {
                this.showModal = !this.showModal;
                this.type = type;
                this.accountTypeId = accountTypId;

                this.newAccount = {
                    id: '',
                    code: '',
                    costCenterId: costCenterId,
                    name: '',
                    nameArabic: '',
                    isActive: true,
                    description: ''
                };

            },
            GetData: function () {
                var token = '';
                var root = this;
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Accounting/Charts', { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        root.templatetype = "Business"
                        root.$store.dispatch('GetAccountList', response.data);
                        root.accounts = root.$store.state.accounts;
                    })
            }
        },
        created: function () {
            this.$emit('input', this.$route.name);
            this.GetData();

        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.makeActive('Assets');
        },
    }
</script>