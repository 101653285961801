<template>
    <div class="row"  v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage pr-4'" v-if="(isValid('CanViewTransporter')  && formName=='Transporter') ||  (isValid('CanViewClearanceAgent')  && formName=='ClearanceAgent') ||  (isValid('CanViewShippingLiner')  && formName=='ShippingLinear')">
        <div class="col-lg-12 col-sm-12 ml-auto mr-auto">
            <div class="row mb-4" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                <div class="col-sm-6 col-md-6 col-lg-6">
                    <h5 class="page_title" v-if="formName=='Transporter'">{{ $t('Logistics.Transporter/Cargo') }}</h5>
                    <h5 class="page_title" v-if="formName=='ClearanceAgent'">{{ $t('Logistics.ClearanceAgent') }}</h5>
                    <h5 class="page_title" v-if="formName=='ShippingLinear'">{{ $t('Logistics.ShippingLinear') }}</h5>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link :to="'/StartScreen'"><a href="javascript:void(0)"> {{ $t('Logistics.Home') }}</a></router-link></li>

                            <li class="breadcrumb-item active" aria-current="page" v-if="formName=='Transporter'">{{ $t('Logistics.Transporter/Cargo') }}</li>
                            <li class="breadcrumb-item active" aria-current="page" v-if="formName=='ClearanceAgent'">{{ $t('Logistics.ClearanceAgent') }}</li>
                            <li class="breadcrumb-item active" aria-current="page" v-if="formName=='ShippingLinear'">{{ $t('Logistics.ShippingLinear') }}</li>
                        </ol>
                    </nav>
                </div>
                <div class=" col-sm-6 col-md-6 col-lg-6">
                    <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                        <a href="javascript:void(0)" class="btn btn-primary  " v-on:click="Addlogistics"  v-if="(isValid('CanAddTransporter')  && formName=='Transporter') ||  (isValid('CanAddClearanceAgent')  && formName=='ClearanceAgent') ||  (isValid('CanAddShippingLiner')  && formName=='ShippingLinear')"><i class="fa fa-plus"></i> {{ $t('Logistics.AddNew') }}</a>

                        <router-link :to="'/StartScreen'"><a href="javascript:void(0)" class="btn btn-outline-danger "> {{ $t('Logistics.Close') }}</a></router-link>
                    </div>

                </div>
            </div>
            <div class="card ">
                <div class="card-header">
                    <div class="row mb-3 " v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'flex-direction: row-reverse;margin-right:2px;'">
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label>{{ $t('Logistics.SearchByLogistic') }}</label>
                                <div>
                                    <input type="text" class="form-control search_input" v-model="search" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" name="search" id="search" :placeholder="$t('Logistics.Search')" />
                                    <span class="fas fa-search search_icon"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                <div class="card-body">
                    <div class="row">


                        <div class="col-lg-12">

                            <div class="mt-2 table-responsive">
                                <table class="table table_list_bg" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <thead class="">
                                        <tr>

                                            <th>#</th>
                                            <th>
                                                {{ $t('Logistics.Code') }}
                                            </th>
                                            <th v-if="english=='true'">
                                                {{ $t('Logistics.Name') |englishLanguage}}
                                            </th>
                                            <th v-if="isOtherLang()">
                                                {{ $t('Logistics.NameAr') |arabicLanguage}}
                                            </th>

                                            <th>
                                                {{ $t('Logistics.DriverName')}}
                                            </th>
                                            <th>
                                                {{ $t('Logistics.ContactName')}}

                                            </th>
                                            <th>
                                                {{ $t('Logistics.ContactNumber')}}

                                            </th>

                                            <th v-if="formName=='ClearanceAgent'">
                                                {{ $t('Logistics.ServiceFor')}}

                                            </th>
                                            <th v-if="formName=='ClearanceAgent'">
                                                {{ $t('Logistics.Ports')}}

                                            </th>
                                            <th>
                                                {{ $t('Logistics.Status') }}
                                            </th>


                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(voucher,index) in logesticList" v-bind:key="voucher.id">

                                            <td v-if="currentPage === 1">
                                                {{index+1}}
                                            </td>
                                            <td v-else>
                                                {{((currentPage*10)-10) +(index+1)}}
                                            </td>

                                            <td v-if="(isValid('CanEditTransporter')  && formName=='Transporter') ||  (isValid('CanEditClearanceAgent')  && formName=='ClearanceAgent') ||  (isValid('CanEditShippingLiner')  && formName=='ShippingLinear')">
                                                <strong>
                                                    <a href="javascript:void(0)" v-on:click="Editlogistics(voucher.id)">{{voucher.code}}</a>
                                                </strong>
                                            </td>
                                            <td v-else>
                                                {{voucher.code}}
                                            </td>

                                            <td>
                                                {{voucher.englishName}}
                                            </td>
                                            <td>
                                                {{voucher.arabicName}}
                                            </td>
                                            <td>
                                                {{voucher.xcoordinates}}
                                            </td>
                                            <td>
                                                {{voucher.contactName}}
                                            </td>
                                            <td>
                                                {{voucher.contactNo}}
                                            </td>
                                            <td v-if="formName=='ClearanceAgent'">
                                                {{voucher.serviceFor}}
                                            </td>
                                            <td v-if="formName=='ClearanceAgent'">
                                                {{getPort(voucher.ports)}}
                                            </td>
                                            <td>{{voucher.isActive==true?$t('Logistics.Active'):$t('Logistics.De-Active')}}</td>


                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="float-left">
                                <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                                <span v-else-if="currentPage===1 && rowCount < 10">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage===1 && rowCount >= 11  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                            </div>
                            <div class="float-right">
                                <div class="overflow-auto" v-on:click="getPage()" v-if="($i18n.locale == 'en' ||isLeftToRight())">
                                    <b-pagination pills size="lg" v-model="currentPage"
                                                  :total-rows="rowCount"
                                                  :per-page="10"
                                                  first-text="First"
                                                  prev-text="Previous"
                                                  next-text="Next"
                                                  last-text="Last"></b-pagination>
                                </div>
                                <div class="overflow-auto" v-on:click="getPage()" v-else>
                                    <b-pagination pills size="lg" v-model="currentPage"
                                                  :total-rows="rowCount"
                                                  :per-page="10"
                                                  first-text="الأولى"
                                                  prev-text="السابقة"
                                                  next-text="التالية"
                                                  last-text="الأخيرة"></b-pagination>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </div>
        <logisticsInvoice :formName="formName" :printId="printId" v-if="printId!=undefined && printId!='00000000-0000-0000-0000-000000000000'" v-bind:key="printRender">

        </logisticsInvoice>
    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>

<script>
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        props: ['formName'],
        data: function () {
            return {
                selected: [],
                selectAll: false,
                search: '',
                show: false,
                logesticList: [],
                type: '',
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                currency: '',
                active: 'Draft',
                printDetails: [],
                printId: '00000000-0000-0000-0000-000000000000',
                printRender: 0,
                printed: false,
                arabic: '',
                english: '',

            }
        },

        methods: {

            getPort: function (x) {
                if (this.$i18n.locale == 'ar') {
                    if (x == 1) {
                        return 'ميناء جاف';
                    }
                    else if (x == 2) {
                        return 'الميناء البحري';
                    }
                    else if (x == 3) {
                        return 'مطار';
                    } if (x == 4) {
                        return 'الميناء الجاف والميناء البحري';
                    }
                    else if (x == 5) {
                        return 'الميناء الجاف والميناء الجوي';
                    }
                    else if (x == 6) {
                        return 'الميناء البحري والميناء الجوي';
                    }
                    else if (x == 7) {
                        return 'الميناء الجاف والميناء البحري والميناء الجوي';
                    }



                }
                if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {
                    if (x == 1) {
                        return 'Dry Port';
                    }
                    else if (x == 2) {
                        return 'Sea Port';
                    }
                    else if (x == 3) {
                        return 'Air Port';
                    } if (x == 4) {
                        return 'Dry Port & Sea Port';
                    }
                    else if (x == 5) {
                        return 'Dry Port & Air Port';
                    }
                    else if (x == 6) {
                        return 'Sea Port & Air Port';
                    }
                    else if (x == 7) {
                        return 'Dry Port,Sea Port & Air Port';
                    }


                }
            },
            Addlogistics: function () {
                this.$router.push('/AddLogistics?formName=' + this.formName);
            },
            getPage: function () {

                this.GetlogisticsData(this.formName, this.search, this.currentPage);
            },
            GetlogisticsData: function (vtype, search, currentPage) {

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                search == undefined ? '' : search;
                root.$https.get('Region/LogisticList?logisticsForm=' + vtype + '&searchTerm=' + search + '&pageNumber=' + currentPage + '&isActive=false', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {

                        root.logesticList = response.data.results.logistics;
                        root.pageCount = response.data.pageCount;
                        root.rowCount = response.data.rowCount;
                    }
                });
            },
            Editlogistics: function (id) {
                var root = this;
                var childFormName = this.formName;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }


                root.$https.get('/Region/LogisticDetail?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {


                        root.$router.push({
                            path: '/AddLogistics?formName=' + childFormName,
                            query: { data: response.data }
                        })



                    }
                });

            },

        },
        created: function () {
            this.$emit('input', this.$route.name + this.formName);
        },
        watch: {
            search: function (val) {
                this.GetlogisticsData(this.formName, val, 1,);

            },
            formName: function () {
                this.$emit('input', this.$route.name + this.formName);
                this.GetlogisticsData(this.formName, this.search, 1);
            }
        },
        mounted: function () {

            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.GetlogisticsData(this.formName, this.search, 1);
            this.currency = localStorage.getItem('currency');
        }
    }
</script>