<template>
    <modal :show="show">

        <div style="margin-bottom:0px" class="card" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <div class="card-body">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="modal-header">

                            <h5 class="modal-title" id="myModalLabel">{{ $t('RejectionModel.RejectedExpense') }}  </h5>

                        </div>
                        <div class="card">
                            <div class="card-body">
                                <div class="row ">
                                    <div class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.dailyExpense.reason.$error}">
                                        <label class="text  font-weight-bolder"> {{ $t('RejectionModel.Reason') }}: <span class="LabelColour"> *</span></label>
                                        <textarea class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.dailyExpense.reason.$model" type="text" />
                                        <span v-if="$v.dailyExpense.reason.$error" class="error">
                                            <span v-if="!$v.dailyExpense.reason.required">{{ $t('RejectionModel.NameRequired') }}</span>

                                        </span>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <h6 class="label">{{ $t('RejectionModel.PleaseSelectYourPaymentType') }}</h6>
                                        <div class="form-check form-check-inline">

                                            <input class="form-check-input" v-model="dailyExpense.paymentType" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="Cash">
                                            <label class="form-check-label" for="inlineRadio1">{{ $t('RejectionModel.Cash') }}</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" v-model="dailyExpense.paymentType" name="inlineRadioOptions" id="inlineRadio2" value="Credit">
                                            <label class="form-check-label" for="inlineRadio2">{{ $t('RejectionModel.Credit') }}</label>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer justify-content-right">

                            <button type="button" class="btn btn-primary  " v-on:click="SaveCity" v-bind:disabled="$v.dailyExpense.$invalid"> {{ $t('RejectionModel.btnSave') }}</button>
                            <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{ $t('RejectionModel.btnClear') }}</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </modal>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import 'vue-loading-overlay/dist/vue-loading.css';
    import { required } from "vuelidate/lib/validators"

    export default {
        props: ['show', 'dailyExpense'],
        mixins: [clickMixin],
        data: function () {
            return {
                render: 0,
                currency: '',
            }
        },
        validations: {
            dailyExpense: {
                reason: {
                    required,
                },
                paymentType: {
                    required
                },

            }
        },
        methods: {
            close: function () {
                this.$emit('close');
            },
            SaveCity: function () {

                var token = '';
                var root = this;
                var url = '/Company/SaveDailyExpense';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https
                    .post(url, root.dailyExpense, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {
                        if (response.data != null) {
                            this.$swal.fire({
                                title: root.$t('RejectionModel.SavedSuccessfully'),
                                text: root.$t('RejectionModelSaved'),
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonStyling: false,
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,

                            });
                            root.close();
                            this.$router.push('/dailyexpense')
                        }
                    })
                    .catch(error => {

                        console.log(error)
                        this.$swal.fire(
                            {
                                icon: 'error',
                                title: this.$t('RejectionModel.Error'),
                                text: this.$t('RejectionModel.Error'),
                            });
                        root.errored = true
                    })
                    .finally(() => root.loading = false)
            }
        },
        mounted: function () {
            this.currency = localStorage.getItem('currency');


        }
    }
</script>
