<template>
    <div class="row" v-if="isValid('CanViewPurchaseDetail')">
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-9" v-bind:style="$i18n.locale == 'ar' ? languageChange('en') : languageChange('ar')">
            <div class="card" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                <div class="card-body">
                    <div class="row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                            <h5 class="view_page_title">{{ $t('PurchaseView.PurchaseInvoice') }}</h5>
                        </div>
                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">

                            <button class="btn btn-outline-danger  mr-2"
                                    v-on:click="goToPurchase">
                                <i class="fas fa-arrow-circle-left fa-lg"></i>
                            </button>
                        </div>

                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                            <h5>{{ $t('PurchaseView.From') }}</h5>
                            <div class="card" style="border: 1px #dddddd solid;">
                                <div class="card-body">
                                    <label>{{($i18n.locale == 'en' ||isLeftToRight())?headerFooter.company.nameEnglish:headerFooter.company.companyNameArabic}}</label>
                                    <br />
                                    <label>{{($i18n.locale == 'en' ||isLeftToRight())?headerFooter.company.addressEnglish:headerFooter.company.addressArabic}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                            <h5>{{ $t('PurchaseView.InvoiceTo') }}</h5>
                            <div class="card" style="border: 1px #dddddd solid;">
                                <div class="card-body">
                                    <label>{{purchase.supplierName}}</label>
                                    <br />
                                    <!--<label v-if="!sale.isCredit">{{sale.customerAddress}}</label>-->
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">


                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 mb-2">
                            <label class="font-weight-bold">{{ $t('PurchaseView.TaxMethod') }} :</label>
                            <div>{{purchase.taxMethod}}</div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 mb-2">
                            <label class="font-weight-bold">{{ $t('PurchaseView.VAT%') }} :</label>
                            <div>{{purchase.taxRatesName}}</div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <purchase-view-item @input="SavePurchaseItems" :purchase="purchase" :taxMethod="purchase.taxMethod" :taxRateId="purchase.taxRateId" :raw="purchase.isRaw" :po="false" :key="randerLineItem" />
                            <div class="row">
                                <div class="col-md-6 ">
                                    <button class="btn btn-primary mr-2" v-on:click="Attachment()">
                                        {{ $t('PurchaseView.Attachment') }}
                                    </button>
                                </div>
                                <div class="col-md-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                                    <button class="btn btn-danger  mr-2"
                                            v-on:click="goToPurchase">
                                        {{ $t('PurchaseView.Cancel') }}
                                    </button>
                                </div>
                            </div>

                            <div class="accordion" role="tablist" v-if="purchase.id != '00000000-0000-0000-0000-000000000000' && internationalPurchase=='true'">
                                <b-card no-body class="mb-1" v-if="isValid('CanViewPIAttachment')">
                                    <b-card-header header-tag="header" class="p-1" role="tab">
                                        <b-button block v-b-toggle.accordion-1 variant="primary">{{ $t('PurchaseView.Attachment') }}</b-button>
                                    </b-card-header>
                                    <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                                        <b-card-body>
                                            <!--<import-attachment :purchase="purchase" :show="attachments" v-if="attachments" @close="attachmentSave" :document="'Purchase'" />-->
                                            <div>
                                                <!--<div class="row" v-if="(purchase.approvalStatus === 5 || purchase.approvalStatus === 9) && purchase.id != '00000000-0000-0000-0000-000000000000'">
                                                <div class="col-md-12 text-right">
                                                    <a href="javascript:void(0)" class="btn btn-outline-primary " v-on:click="attachments=true"> Upload</a>
                                                </div>
                                            </div>-->
                                                <div class=" table-responsive">
                                                    <table class="table ">
                                                        <thead class="m-0">
                                                            <tr>
                                                                <th>#</th>
                                                                <th>{{ $t('PurchaseView.Date') }} </th>
                                                                <th>{{ $t('PurchaseView.Description') }} </th>
                                                                <th v-if="isValid('CanDownloadPIAttachment')">{{ $t('PurchaseView.Attachment') }}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(contact,index) in purchase.purchaseInvoiceAttachments" v-bind:key="index">
                                                                <td>
                                                                    {{index+1}}
                                                                </td>
                                                                <th>{{getDate(contact.date)}}</th>
                                                                <th>{{contact.description}}</th>

                                                                <td v-if="isValid('CanDownloadPIAttachment')">
                                                                    <button class="btn btn-primary  btn-icon mr-2"
                                                                            v-if="contact.path != ''"
                                                                            v-on:click="DownloadAttachment(contact.path)">
                                                                        <i class="fa fa-download"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                                <b-card no-body class="mb-1" v-if="isValid('CanViewPIActions')">
                                    <b-card-header header-tag="header" class="p-1" role="tab">
                                        <b-button block v-b-toggle.accordion-2 variant="primary">Actions</b-button>
                                    </b-card-header>
                                    <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                                        <b-card-body>
                                            <!--<add-company-action :action="action" :show="show" v-if="show" @close="IsSave" :document="'Purchase'" />-->

                                            <div class="row">
                                                <!--<div class="col-md-12" v-if="(purchase.approvalStatus === 5 || purchase.approvalStatus === 9) && purchase.id != '00000000-0000-0000-0000-000000000000'">
                                                <div class="col-sm-6 float-right">
                                                    <a href="javascript:void(0)" class="btn btn-outline-primary  float-right" v-on:click="show=true"> Action</a>
                                                </div>
                                            </div>-->
                                                <div class=" table-responsive">
                                                    <table class="table ">
                                                        <thead class="m-0">
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Status</th>
                                                                <th>{{ $t('PurchaseView.Date') }} </th>
                                                                <th>Description/Reason</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(process,index) in purchase.purchaseInvoiceActions" v-bind:key="process.id">
                                                                <td>
                                                                    {{index+1}}
                                                                </td>
                                                                <th><span class="badge badge-primary">{{process.processName}}</span></th>
                                                                <th>{{getDate(process.date)}}</th>
                                                                <th>{{process.description}}</th>

                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                                <!--<b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                    <b-button block v-b-toggle.accordion-3 variant="primary">Payment</b-button>
                                </b-card-header>
                                <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                        <purchaseorder-payment :totalAmount="totalAmount" :customerAccountId="advanceAccountId" :show="payment" v-if="payment" @close="paymentSave" :isSaleOrder="'false'" :isPurchase="'true'" :purchaseOrderId="purchase.id" :formName="'AdvancePay'" />
                                        <div>
                                            <div class="row" v-if="versionAllow!='true' && internationalPurchase=='true' && (purchase.approvalStatus === 5 || purchase.approvalStatus === 3) && purchase.id != '00000000-0000-0000-0000-000000000000'">
                                                <div class="col-md-12 text-right">
                                                    <a href="javascript:void(0)" class="btn btn-outline-primary " v-on:click="payment=true"> Add Payment</a>
                                                </div>
                                            </div>
                                            <div class=" table-responsive">
                                                <table class="table ">
                                                    <thead class="m-0">
                                                        <tr>
                                                            <th>#</th>
                                                            <th style="width:20%;">{{ $t('PurchaseOrder.Date') }} </th>
                                                            <th style="width:20%;">{{ $t('PaymentVoucher.VoucherNumber') }} </th>
                                                            <th class="text-right">{{ $t('PaymentVoucher.Amount') }} </th>
                                                            <th class="text-center">{{ $t('PaymentMode') }} </th>
                                                            <th></th>
                                                            <th>{{ $t('Description') }} </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(payment,index) in purchase.paymentVoucher" v-bind:key="index">
                                                            <td>
                                                                {{index+1}}
                                                            </td>
                                                            <th>{{getDate(payment.date)}}</th>
                                                            <th>{{payment.voucherNumber}}</th>
                                                            <th class="text-right">{{currency}} {{parseFloat(payment.amount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</th>
                                                            <th class="text-center"><span v-if="payment.paymentMode==0">Cash</span><span v-if="payment.paymentMode==1">Bank</span></th>
                                                            <th>{{payment.narration}}</th>
                                                            <th>
                                                                <a href="javascript:void(0)" title="Payment View" class="btn  btn-icon btn-primary btn-sm" v-on:click="ViewPaymentVoucher(payment.id, false)"><i class=" fas fa-eye"></i></a>
                                                            </th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>-->
                                <b-card no-body class="mb-1" v-if="isValid('CanViewPIExpenses')">
                                    <b-card-header header-tag="header" class="p-1" role="tab">
                                        <b-button block v-b-toggle.accordion-4 variant="primary">Expense</b-button>
                                    </b-card-header>
                                    <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
                                        <b-card-body>
                                            <purchaseorder-expense :show="expense" v-if="expense" @close="expenseSave" :isPurchase="false" :isPurchasePostExpense="true" :purchaseOrderId="purchase.id" :formName="'AdvanceExpense'" />
                                            <purchaseorder-expensebill :show="bill" v-if="bill" @close="billSave" :isInvoice="true" :purchaseId="purchase.id" />
                                            <div>
                                                <div class="row" v-if="!purchase.isCost && purchase.isPurchasePost && purchase.id != '00000000-0000-0000-0000-000000000000'">
                                                    <div class="col-md-12 text-right">
                                                        <a href="javascript:void(0)" class="btn btn-outline-primary " v-on:click="bill=true" v-if="isValid('CanAddOrderExpense')"> Add Bill</a>
                                                        <a href="javascript:void(0)" class="btn btn-outline-primary " v-on:click="expense=true" v-if="isValid('CanAddOrderExpense')"> Add Expense</a>
                                                    </div>
                                                </div>
                                                <div class=" table-responsive">
                                                    <table class="table ">
                                                        <thead class="m-0">
                                                            <tr>
                                                                <th>#</th>
                                                                <th style="width:20%;">{{ $t('PurchaseView.Date') }} </th>
                                                                <th style="width:20%;">{{ $t('PurchaseView.VoucherNumber') }} </th>
                                                                <th class="text-right">{{ $t('PurchaseView.Amount') }} </th>
                                                                <th class="text-center">{{ $t('PurchaseView.PaymentMode') }} </th>
                                                                <th>{{ $t('PurchaseView.Description') }} </th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(payment,index) in purchase.purchasePostExpenses" v-bind:key="index">
                                                                <td>
                                                                    {{index+1}}
                                                                </td>
                                                                <th>{{getDate(payment.date)}}</th>
                                                                <th>{{payment.voucherNumber}}</th>
                                                                <th class="text-right">{{currency}} {{parseFloat(payment.amount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</th>
                                                                <th class="text-center"><span v-if="payment.paymentMode==0">Cash</span><span v-if="payment.paymentMode==1">Bank</span></th>
                                                                <th>{{payment.narration}}</th>
                                                                <th>
                                                                    <a href="javascript:void(0)" title="Payment View" class="btn  btn-icon btn-primary btn-sm" v-on:click="ViewPaymentVoucher(payment.id, true)" v-if="isValid('CanViewDetailOrderExpense')"><i class=" fas fa-eye"></i></a>
                                                                </th>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>

                            </div>

                        </div>
                    </div>


                </div>
            </div>
            <purchaseInvoice :printDetails="printDetails" v-if="printDetails.length != 0" v-bind:key="printRender"></purchaseInvoice>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <div class="card">
                <div class="card-body">
                    <div class="row">

                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                            <h5 class="view_page_title">{{ $t('PurchaseView.BasicInfo') }}</h5>

                        </div>
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                            <vue-qrcode v-bind:value="qrValue" style="width:140px;" />
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <label class="invoice_lbl">{{ $t('PurchaseView.PurchaseInvoice') }}#</label>
                            <hr style="margin-top: 0.3rem; margin-bottom: 0.1rem;" />
                            <label>{{purchase.registrationNo}}</label>
                            <hr style="margin-top: 0.1rem;" />
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <label class="invoice_lbl">{{ $t('PurchaseView.PurchaseOrder') }}#</label>
                            <hr style="margin-top: 0.3rem; margin-bottom: 0.1rem;" />
                            <label>{{purchase.purchaseOrderNo}}</label>
                            <hr style="margin-top: 0.1rem;" />
                        </div> 
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" v-if="isValid('CanAddGoodsReceiveasPost') || isValid('CanViewGoodsReceiveasPost')">
                            <label class="invoice_lbl">{{ $t('GoodReceive.GoodReceive') }} #</label>
                            <hr style="margin-top: 0.3rem; margin-bottom: 0.1rem;" />
                            <label>{{purchase.goodReceiveNo}}</label>
                            <hr style="margin-top: 0.1rem;" />
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            <label class="invoice_lbl">{{ $t('PurchaseView.WareHouse') }} :</label>
                            <hr style="margin-bottom: 0.1rem; margin-top: 0.3rem;" />
                            <label>{{ purchase.wareHouseName }}</label>
                            <hr style="margin-top: 0.1rem;" />
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            <label class="invoice_lbl">{{ $t('PurchaseView.Date') }}</label>
                            <hr style="margin-bottom: 0.1rem; margin-top: 0.3rem;" />
                            <label>{{purchase.date}}</label>
                            <hr style="margin-top: 0.1rem;" />
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            <label class="invoice_lbl">{{ $t('PurchaseView.SupplierInvoiceNumber') }}</label>
                            <hr style="margin-bottom: 0.1rem; margin-top: 0.3rem;" />
                            <label>{{ purchase.invoiceNo }}</label>
                            <hr style="margin-top: 0.1rem;" />
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            <label class="invoice_lbl">{{ $t('PurchaseView.SupplierInvoiceDate') }}</label>
                            <hr style="margin-bottom: 0.1rem; margin-top: 0.3rem;" />
                            <label>{{ purchase.invoiceDate }}</label>
                            <hr style="margin-top: 0.1rem;" />
                        </div>





                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            <label class="invoice_lbl">Send Copy To</label>
                            <hr style="margin-bottom: 0.1rem; margin-top: 0.3rem;" />
                            <label>Email</label>
                            <hr style="margin-top: 0.1rem;" />
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            <button class="btn btn-primary btn-block">Send Invoice</button>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 mt-2">
                            <button class="btn btn-light btn-block text-left">PDF <i class="fas fa-file-pdf float-right" style="color:#EB5757;"></i></button>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 mt-2">
                            <button class="btn btn-light btn-block text-left">Sheets <i class="fas fa-file-excel float-right" style="color:#198754;"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <bulk-attachment :documentid="purchase.id" :show="show" v-if="show" @close="attachmentSave" />

    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import moment from "moment";
    import { required } from "vuelidate/lib/validators";
    import VueQrcode from 'vue-qrcode'

    export default {
        components: {
            VueQrcode,
        },
        mixins: [clickMixin],
        name: "PurchaseView",
       
        data: function () {
            return {
                purchase: {
                    id: "00000000-0000-0000-0000-000000000000",
                    date: "",
                    purchaseOrderId: "",
                    registrationNo: "",
                    supplierId: "",
                    invoiceNo: "",
                    isPurchaseReturn: false,
                    invoiceDate: "",
                    purchaseOrder: "",
                    wareHouseId: "",
                    purchaseItems: [],
                    isRaw: false,
                    taxMethod: '',
                    taxRateId: "00000000-0000-0000-0000-000000000000",
                },

                printId: '00000000-0000-0000-0000-000000000000',
                printDetails: [],
                options: [],
                loading: false,
                rander: 0,
                qrValue: '',

                raw: '',
                printRender: 0,
                randerLineItem: 0,
                autoNumber: '',
                language: 'Nothing',
                supplierRender: 0,
                internationalPurchase: '',
                versionAllow: '',
                currency: '',
                bill: false,
                expense: false,
                show: false,
                headerFooter: {
                    footerEn: '',
                    footerAr: '',
                    company: ''
                },
            };
        },
        validations: {
            purchase: {
                date: { required },
                registrationNo: { required },
                supplierId: { required },
                invoiceNo: {},
                invoiceDate: {},
                wareHouseId: {},
                purchaseItems: { required },
            },
        },
        computed: {
            calulateDiscountAmount: function () {
                return this.purchase.purchasePostItems.reduce(function (a, c) { return a + Number(c.discountAmount || 0) }, 0)
            },
            calulateTotalQty: function () {
                return this.purchase.purchasePostItems.reduce(function (a, c) { return a + Number((c.quantity) || 0) }, 0)
            },
            calulateNetTotal: function () {
                return this.purchase.purchasePostItems.reduce(function (a, c) { return a + Number((c.total + c.includingVat) || 0) }, 0)
            },
            calulateTotalExclVAT: function () {
                return this.purchase.purchasePostItems.reduce(function (a, c) { return a + Number((c.total) || 0) }, 0)
            },
            calulateTotalVAT: function () {

                return this.purchase.purchasePostItems.reduce(function (a, c) { return a + Number((c.includingVat + c.inclusiveVat) || 0) }, 0)
            }
        },
        methods: {
            GetTLVForValue: function (tagNumber, tagValue) {
                var tagBuf = Buffer.from([tagNumber], 'utf-8')
                var tagValueLenBuf = Buffer.from([tagValue.length], 'utf-8')
                var tagValueBuf = Buffer.from(tagValue, 'utf-8')
                var bufsArray = [tagBuf, tagValueLenBuf, tagValueBuf]
                return Buffer.concat(bufsArray)
            },
            calulateDiscountAmount1: function () {
                return this.purchase.purchasePostItems.reduce(function (a, c) { return a + Number(c.discountAmount || 0) }, 0)
            },
            calulateBundleAmount1: function () {
                return this.purchase.purchasePostItems.reduce(function (a, c) { return a + Number(c.bundleAmount || 0) }, 0)
            },
            calulateNetTotalWithVAT: function () {
                var total = this.purchase.purchasePostItems.reduce(function (a, c) { return a + Number((c.total + c.includingVat) || 0) }, 0);
                var grandTotal = parseFloat(total) - (this.calulateDiscountAmount1() + this.calulateBundleAmount1())
                return (parseFloat(grandTotal).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,"));
            },
            calulateTotalVATofInvoice: function () {
                var total = this.purchase.purchasePostItems.reduce(function (a, c) { return a + Number((c.includingVat + c.inclusiveVat) || 0) }, 0);
                return (parseFloat(total).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,"));
            },
            Attachment: function () {
                this.show = true;
            },

            attachmentSave: function () {
                this.show = false;
            },

            GetExpenseVoucher: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                root.$https.get('PurchasePost/PurchasePostExpensePaymentList?id=' + this.purchase.id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null && response.data != '') {
                            root.purchase.purchasePostExpenses = response.data;
                        }
                    });
            },
            expenseSave: function () {
                this.expense = false;
                this.GetExpenseVoucher();
            },
            billSave: function () {
                this.bill = false;
                this.GetExpenseVoucher();
            },
            getDate: function (date) {
                if (date == null || date == undefined) {
                    return "";
                }
                else {
                    return moment(date).format('LLL');
                }
            },
            DownloadAttachment(path) {

                var root = this;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                var ext = path.split('.')[1];
                root.$https.get('/Contact/DownloadFile?filePath=' + path, { headers: { "Authorization": `Bearer ${token}` }, responseType: 'blob' })
                    .then(function (response) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'file.' + ext);
                        document.body.appendChild(link);
                        link.click();
                    });
            },

            languageChange: function (lan) {
                if (this.language == lan) {
                    if (this.purchase.id == '00000000-0000-0000-0000-000000000000') {

                        var getLocale = this.$i18n.locale;
                        this.language = getLocale;

                        this.$router.go('/addproduct');
                    }
                    else {
                        this.$swal({
                            title: 'Error!',
                            text: 'You cannot Change language during update, otherwise your current page data will be lose',
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            icon: 'error',
                            timer: 4000,
                            timerProgressBar: true,
                        });
                    }
                }


            },
            SavePurchaseItems: function (purchaseItems) {
                this.purchase.purchaseItems = purchaseItems;
            },
            GetHeaderDetail: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                root.$https.get("/Company/GetCompanyDetail?id=" + localStorage.getItem('CompanyID'), { headers: { Authorization: `Bearer ${token}` }, })
                    .then(function (response) {
                        if (response.data != null) {
                            
                            root.headerFooter.company = response.data;
                            var sellerNameBuff = root.GetTLVForValue('1', root.headerFooter.company.nameEnglish)
                            var vatRegistrationNoBuff = root.GetTLVForValue('2', root.headerFooter.company.vatRegistrationNo)
                            var timeStampBuff = root.GetTLVForValue('3', root.purchase.date)
                            var totalWithVat = root.GetTLVForValue('4', root.calulateNetTotalWithVAT())
                            var totalVat = root.GetTLVForValue('5', root.calulateTotalVATofInvoice())
                            var tagArray = [sellerNameBuff, vatRegistrationNoBuff, timeStampBuff, totalWithVat, totalVat]
                            var qrCodeBuff = Buffer.concat(tagArray)
                            root.qrValue = qrCodeBuff.toString('base64')
                        }
                    });
            },
            goToPurchase: function () {
                this.$router.push({
                    path: '/purchase',
                    query: {
                        data: 'Addpurchase'
                    }
                })
            },

            setDefaultWareHouse: function (id) {
                this.purchase.wareHouseId = id;
                this.render++;
            }
        },

        mounted: function () {

            this.language = this.$i18n.locale;
            this.internationalPurchase = localStorage.getItem('InternationalPurchase');
            this.versionAllow = localStorage.getItem('VersionAllow');
            this.currency = localStorage.getItem('currency');

            this.GetHeaderDetail();
            if (this.$session.exists()) {
                this.raw = localStorage.getItem('IsProduction');
            }
            if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {
                this.options = ['Inclusive', 'Exclusive'];
            }
            else {
                this.options = ['شامل', 'غير شامل'];
            }

            if (this.$route.query.data != undefined) {
                this.purchase = this.$route.query.data;
                this.purchase.date = moment(this.purchase.date).format("LLL");
                //  this.warehouse = this.$route.query.data;
                this.rander++;
            }
            this.rander++;
        },
    };
</script>
