<template>
    <div class="col-md-12 ml-auto mr-auto">
        <div class="card">
            <div class="card-body">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="card-header p-0 row">
                            <div class="col-lg-9 " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                <span v-if="process.id === '00000000-0000-0000-0000-000000000000'"> <span class="MainLightHeading">{{ $t('AddProcess.CreateProcess') }} - </span><span class="DayHeading">{{process.code}}</span></span>
                                <span v-else><span class="MainLightHeading">{{ $t('AddProcess.UpdateProcess') }} - </span><span class="DayHeading">{{process.code}}</span></span>

                            </div>
                            <div class="col-lg-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                                <span>
                                    {{process.date}}
                                </span>
                            </div>
                        </div>
                        <div class="mt-3">
                            <div class="row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                    <label>{{ $t('AddProcess.ProcessName') }} <span class="LabelColour"> *</span></label>
                                    <input class="form-control" v-model="$v.process.englishName.$model" type="text" />
                                    <span v-if="$v.process.englishName.$error" class="error">
                                    </span>
                                </div>
                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                    <label>{{ $t('AddProcess.Color') }} <span class="LabelColour"> *</span></label>
                                    <input class="form-control" v-model="process.color" type="text" @focus="showcolors=true" @blur="showcolors=false" />
                                    <sketch-picker v-model="color" />
                                </div>
                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                    <label>{{ $t('AddProcess.Description') }} </label>
                                    <input class="form-control" v-model="process.description" type="text" />
                                </div>
                            </div>
                            <div class="row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">

                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                    <label>{{ $t('AddProcess.SelectContractors') }}:</label>
                                    <multiselect v-model="processContractors" :options="options" :multiple="true" track-by="name" :clear-on-select="false" :show-labels="false" label="name" v-bind:placeholder="$t('SelectOption')"
                                                 @input="contractorListId(processContractors)">

                                    </multiselect>
                                   
                                </div>
                            </div>
                        </div>
                        <br />
                        <process-item @input="SaveProcessItems" />

                    </div>
                    <div class="col-md-12 text-right">
                        <div v-if="process.id === '00000000-0000-0000-0000-000000000000'">
                            <button class="btn btn-primary  mr-2"
                                    v-on:click="SaveProcess('Draft')"
                                    :disabled="$v.$invalid ">
                                <i class="far fa-save"></i>  {{ $t('AddProcess.SaveAsDraft') }}
                            </button>
                            <button class="btn btn-primary  mr-2"
                                    v-on:click="SaveProcess('Approved')"
                                    :disabled="$v.$invalid ">
                                <i class="far fa-save"></i>  {{ $t('AddProcess.SaveAsPost') }}
                            </button>
                            <button class="btn btn-danger  mr-2"
                                    v-on:click="close">
                                {{ $t('AddProcess.Cancel') }}
                            </button>
                        </div>
                        <div v-else>
                            <button class="btn btn-primary  mr-2"
                                    v-on:click="SaveProcess('Draft')"
                                    :disabled="$v.$invalid ">
                                <i class="far fa-save"></i>  {{ $t('AddProcess.UpdateAsDraft') }}
                            </button>

                            <button class="btn btn-primary  mr-2"
                                    v-on:click="SaveProcess('Approved')"
                                    :disabled="$v.$invalid || process.processItems.filter(x => x.quantity=='').length > 0">
                                <i class="far fa-save"></i> {{ $t('AddProcess.UpdateAsPost') }}
                            </button>
                            <button class="btn btn-danger  mr-2"
                                    v-on:click="close">
                                {{ $t('AddProcess.Cancel') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import moment from "moment";
    import { maxLength } from "vuelidate/lib/validators"
    import { Sketch } from 'vue-color'
    import Multiselect from 'vue-multiselect';
    export default {
        mixins: [clickMixin],
        props: ['type'],
        components: {
            'sketch-picker': Sketch, Multiselect,
        },
        data: function () {
            return {
                currency: '',
                process: {
                    id: '00000000-0000-0000-0000-000000000000',
                    code: '',
                    englishName: '',
                    arabicName: '',
                    color: '',
                    date: '',
                    processItems: [],
                    contractorId: [],
                    processContractors: [],
                    description: '',

                },
                contractorId: [],
                processContractors: [],

                color: '',
                arabic: '',
                english: '',
                render: 0,
                daterander: 0,
                loading: false,
                showcolors: false,
                options: [],
                employeeType: 'Contractor',
                DisplayValue: [],
                isUpdate: false



            }
        },
        validations: {
            process: {
                code: {

                    maxLength: maxLength(30)
                },
                englishName: {
                    maxLength: maxLength(30)
                },
                arabicName: {
                    maxLength: maxLength(30)
                },
                processItems: {
                },
            }
        },
        watch: {
            color: function (val) {
                
                this.process.color = val.hex;
                this.showcolors = false;
            }
        },
        methods: {
            close: function () {
                this.$router.push('/Process');
            },
            SaveProcessItems: function (processItems) {
                

                this.process.processItems = processItems;
            },
            contractorListId: function (value) {
                
                var root = this;
                root.process.processContractors = [];
                value.forEach(function (val) {
                    root.process.processContractors.push({
                        contractorId: val.id
                    })
                });
            },
            GetAutoCodeGenerator: function () {

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                this.$https.get('/Batch/ProcessAutoGenerateNo', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                    if (response.data != null) {
                        root.process.code = response.data;
                    }
                });
            },
            SaveProcess: function (status) {
                
                this.process.approvalStatus = status

                var root = this;
                this.loading = true;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.post('/Batch/SaveProcessInformation', this.process, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data.isSuccess == true) {
                            if (root.type != "Edit") {

                                root.$swal({
                                    title: "Saved!",
                                    text: "Saved Successfully!",
                                    type: 'success',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 1500,
                                    timerProgressBar: true,
                                });

                                root.close();
                            }
                            else {

                                root.$swal({
                                    title: "Update!",
                                    text: "Update Successfully!",
                                    type: 'success',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 1500,
                                    timerProgressBar: true,
                                });
                                root.close();

                            }
                        }
                        else {
                            root.$swal({
                                title: "Error!",
                                text: "Your Sample Request Name  Already Exist!",
                                type: 'error',
                                icon: 'error',
                                showConfirmButton: false,
                                timer: 1500,
                                timerProgressBar: true,
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Something Went Wrong!',
                                text: error.response.data,

                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false
                    })
                    .finally(() => root.loading = false);
            },
            getEmployeeData: function (x, value) {
                
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.get('/EmployeeRegistration/EmployeeList?IsDropDown=' + false + '&isSignup=' + true + '&employeeType=' + 'Contractor', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    
                    if (response.data != null) {
                        response.data.results.forEach(function (result) {
                            root.options.push({
                                id: result.id,
                                name: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? (result.englishName != '' && result.englishName != null) ? result.code + ' ' + result.englishName : result.code + ' ' + result.arabicName : (result.arabicName != '' && result.arabicName != null) ? result.code + ' ' + result.arabicName : result.code + ' ' + result.englishName
                            })
                            if (x) {
                                if (value != undefined) {
                                    value.forEach(function (ids) {

                                        if (ids.contractorId == result.id) {

                                            root.processContractors.push({
                                                id: result.id,
                                                name: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? (result.englishName != '' && result.englishName != null) ? result.code + ' ' + result.englishName : result.code + ' ' + result.arabicName : (result.arabicName != '' && result.arabicName != null) ? result.code + ' ' + result.arabicName : result.code + ' ' + result.englishName
                                            });
                                            root.contractorListId(root.processContractors);
                                        }
                                    })

                                }
                            }

                        })
                    }
                });
            },

        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.currency = localStorage.getItem('currency');
            if (this.$route.query.data == undefined) {
                this.getEmployeeData(false);

                this.GetAutoCodeGenerator();
                this.process.date = moment().format("LLL");
            }
            else {


                this.process = this.$route.query.data;
                this.daterander++;
                this.getEmployeeData(true, this.process.processContractors);
                //this.EmployeeData(this.process.processContractors);
                //if (this.process.code != null && this.process.englishName != '' && this.process.color != '') {
                //    this.isUpdate = true;
                //}
            }





        }
    }
</script>
