<template>
    <modal :show="show" v-if=" isValid('CanAddAllowance') || isValid('CanEditAllowance') ">

        <div style="margin-bottom:0px" class="card" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <div class="card-body">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="modal-header" v-if="type=='Edit'">

                            <h5 class="modal-title DayHeading" id="myModalLabel"> {{ $t('AddAllowance.UpdateAllowance') }}</h5>

                        </div>
                        <div class="modal-header" v-else>

                            <h5 class="modal-title DayHeading" id="myModalLabel"> {{ $t('AddAllowance.AddAllowance') }}</h5>

                        </div>
                        <div class="">
                            <div class="card-body" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <div class="row ">
                                    <div :key="render" class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.allowance.code.$error}">
                                        <label class="text  font-weight-bolder"> {{ $t('AddAllowance.Code') }}:<span class="LabelColour"> *</span></label>
                                        <input disabled class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.allowance.code.$model" type="text" />
                                    </div>

                                    <div class="form-group has-label col-sm-12 ">
                                        <label class="text  font-weight-bolder"> {{ $t('AddAllowance.AllowanceType') }}:<span class="LabelColour"> *</span> </label>
                                        <allowanceTypeDropdown v-model="allowance.allowanceTypeId" :values="allowance.allowanceTypeId"></allowanceTypeDropdown>
                                    </div>
                                    <div class="form-group has-label col-sm-12 ">
                                        <label class="text  font-weight-bolder"> {{ $t('AddAllowance.CalculateAmount') }} :<span class="LabelColour"> *</span> </label>
                                        <multiselect v-model="allowance.amountType" :options="calculateAmountOptions" :show-labels="false" v-bind:placeholder="$t('AddAllowance.SelectType')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left ' : 'arabicLanguage '">
                                        </multiselect>
                                    </div>

                                    <div class="form-group has-label col-sm-12 ">
                                        <label class="text  font-weight-bolder"> {{ $t('AddAllowance.AllowanceTaxPlan') }} :<span class="LabelColour"> *</span></label>
                                        <multiselect v-model="allowance.taxPlan" :options="taxOptions" :show-labels="false" v-bind:placeholder="$t('AddAllowance.SelectType')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left ' : 'arabicLanguage '">
                                        </multiselect>
                                    </div>

                                    <div class="form-group has-label col-sm-8">
                                        <label class="text  font-weight-bolder">{{ $t('AddAllowance.AmountPercentage')}}: <span class="LabelColour"> *</span></label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? 'padding: 5px 15px 5px 5px;' : 'padding: 5px 5px 5px 15px;' "><i v-if="allowance.amountType=='% of Salary' || allowance.amountType=='٪ من الراتب' " class="fa fa-percent"></i> <i v-else>{{currency}}</i></span>
                                            </div>
                                            <input type="number" @focus="$event.target.select()" class="form-control amount_field" v-model="allowance.amount" />
                                        </div>
                                    </div>

                                    <div class="form-group col-md-4">
                                        <label style="margin: 7px;">{{ $t('AddAllowance.Status') }}</label> <br />
                                        <div class="bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-animate" v-bind:class="{'bootstrap-switch-on': allowance.isActive, 'bootstrap-switch-off': !allowance.isActive}" v-on:click="allowance.isActive = !allowance.isActive" style="width: 72px;">
                                            <div class="bootstrap-switch-container" style="width: 122px; margin-left: 0px;">
                                                <span class="bootstrap-switch-handle-on bootstrap-switch-success" style="width: 50px;">
                                                    <i class="nc-icon nc-check-2"></i>
                                                </span>
                                                <span class="bootstrap-switch-label" style="width: 30px;">&nbsp;</span>
                                                <span class="bootstrap-switch-handle-off bootstrap-switch-success" style="width: 50px;">
                                                    <i class="nc-icon nc-simple-remove"></i>
                                                </span>
                                                <input class="bootstrap-switch" type="checkbox" data-toggle="switch" checked="" data-on-label="<i class='nc-icon nc-check-2'></i>" data-off-label="<i class='nc-icon nc-simple-remove'></i>" data-on-color="success" data-off-color="success">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="!loading">
                            <div class="modal-footer justify-content-right" v-if="type=='Edit' && isValid('CanEditAllowance')">
                                <button type="button" class="btn btn-primary  " v-on:click="SaveAllowance" v-bind:disabled="$v.allowance.$invalid || (allowance.amountType=='% of Salary' && allowance.amount>100)"> {{ $t('AddAllowance.Update') }}</button>
                                <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{ $t('AddAllowance.Cancel') }}</button>
                            </div>
                            <div class="modal-footer justify-content-right" v-if="type!='Edit' && isValid('CanAddAllowance')">
                                <button type="button" class="btn btn-primary  " v-on:click="SaveAllowance" v-bind:disabled="$v.allowance.$invalid || (allowance.amountType=='% of Salary' && allowance.amount>100)"> {{ $t('AddAllowance.Save') }}</button>
                                <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{ $t('AddAllowance.Cancel') }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
    <acessdenied v-else :model=true></acessdenied>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import { maxLength, required } from "vuelidate/lib/validators"
    import Multiselect from 'vue-multiselect'

    export default {
        mixins: [clickMixin],
        props: ['show', 'allowance', 'type'],
        components: {
            Multiselect,

        },

        data: function () {
            return {
                currency: '',
                calculateAmountOptions: [],
                taxOptions: [],

                arabic: '',
                english: '',
                render: 0,
                loading: false,
            }
        },
        validations: {
            allowance: {
                allowanceTypeId: {
                    required
                },
                amountType: {
                    required
                },
                taxPlan: {
                    required
                },
                amount: {
                    required
                },

                code: {

                    maxLength: maxLength(30)
                },
               
            }
        },
        methods: {
            close: function () {
                this.$emit('close');
            },
            GetAutoCodeGenerator: function () {

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.get('/Payroll/AllowanceAutoGenerateNo', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                    if (response.data != null) {
                        root.allowance.code = response.data;
                        root.render++;
                    }
                });
            },
            SaveAllowance: function () {
                var root = this;
                this.loading = true;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {
                    if (this.allowance.amountType == '% of Salary') {
                        this.allowance.amountType = 1;
                    }
                    else if (this.allowance.amountType == 'Fixed') {
                        this.allowance.amountType = 2;
                    }
                    if (this.allowance.taxPlan == 'Non Taxable') {
                        this.allowance.taxPlan = 2;
                    }
                    else if (this.allowance.taxPlan == 'Taxable') {
                        this.allowance.taxPlan = 1;
                    }

                }
                else {
                    if (this.allowance.amountType == '٪ من الراتب') {
                        this.allowance.amountType = 1;
                    }
                    else if (this.allowance.amountType == 'مثبت') {
                        this.allowance.amountType = 2;
                    }
                    if (this.allowance.taxPlan == 'غير خاضعة للضريبة') {
                        this.allowance.taxPlan = 2;
                    }
                    else if (this.allowance.taxPlan == 'خاضع للضريبة') {
                        this.allowance.taxPlan = 1;
                    }

                }
                this.$https.post('/Payroll/SaveAllowanceInformation', this.allowance, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data.isSuccess == true) {
                            if (root.type != "Edit") {

                                root.$swal({
                                    title: "Saved!",
                                    text: "Saved Successfully!",
                                    type: 'success',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 1500,
                                    timerProgressBar: true,
                                });

                                root.close();
                            }
                            else {

                                root.$swal({
                                    title: "Update!",
                                    text: "Update Successfully!",
                                    type: 'success',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 1500,
                                    timerProgressBar: true,
                                });
                                root.close();

                            }
                        }
                        else {
                            root.$swal({
                                title: "Error!",
                                text: "Your Allowance Name  Already Exist!",
                                type: 'error',
                                icon: 'error',
                                showConfirmButton: false,
                                timer: 1500,
                                timerProgressBar: true,
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Something Went Wrong!',
                                text: error.response.data,

                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false
                    })
                    .finally(() => root.loading = false);
            }
        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {
                this.calculateAmountOptions = ['% of Salary', 'Fixed'];
                this.taxOptions = ['Taxable', 'Non Taxable'];
            }
            else {
                this.calculateAmountOptions = ['٪ من الراتب', 'مثبت'];
                this.taxOptions = ['خاضع للضريبة', 'غير خاضعة للضريبة'];
            }
            this.currency = localStorage.getItem('currency');
            if (this.allowance.id == '00000000-0000-0000-0000-000000000000' || this.allowance.id == undefined || this.allowance.id == '')
                this.GetAutoCodeGenerator();

            if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {
                if (this.allowance.amountType == 1) {
                    this.allowance.amountType = '% of Salary';
                }
                else if (this.allowance.amountType == 2)
                {
                    this.allowance.amountType = 'Fixed';
                }
                if (this.allowance.taxPlan == 2) {
                    this.allowance.taxPlan = 'Non Taxable';
                }
                else if (this.allowance.taxPlan ==1) {
                    this.allowance.taxPlan = 'Taxable';
                }

            }
            else {

                if (this.allowance.amountType == 1) {
                    this.allowance.amountType = '٪ من الراتب';
                }
                else if (this.allowance.amountType == 2) {
                    this.allowance.amountType = 'مثبت';
                }
                if (this.allowance.taxPlan == 2) {
                    this.allowance.taxPlan = 'غير خاضعة للضريبة';
                }
                else if (this.allowance.taxPlan == 1) {
                    this.allowance.taxPlan = 'خاضع للضريبة';
                }
            }

        }
    }
</script>
<style scoped>
    .input-group-append .input-group-text, .input-group-prepend .input-group-text {
        background-color: #e3ebf1;
        border: 1px solid #e3ebf1;
        color: #000000;
    }

    .input-group .form-control {
        border-left: 1px solid #e3ebf1;
    }

        .input-group .form-control:focus {
            border-left: 1px solid #3178F6;
        }

    .input-group-text {
        border-radius: 0;
    }
</style>
