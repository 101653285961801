<template>
    <modal :show="show" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">

        <div style="margin-bottom:0px" class="card">
            <div class="card-body">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="modal-header">
                            <h5 class="modal-title DayHeading" id="myModalLabel">{{ $t('QuickProductItem.QuickItemRegistration') }}</h5>
                        </div>
                        <div>
                            <div class="card-body ">
                                <div class="row ">
                                    <div v-bind:key="rendered" class="col-12">
                                        <label v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">{{ $t('QuickProductItem.ProductCode') }} :<span class="LabelColour"> *</span></label>
                                        <div>
                                            <input readonly class="form-control" v-model="product.code" />

                                        </div>
                                    </div>
                                    <div v-if="english=='true'" class="col-12 " v-bind:class="{'has-danger' : $v.product.englishName.$error}">
                                        <label class="text  font-weight-bolder " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">{{ $t('QuickProductItem.ItemName') |englishLanguage}} :<span class="LabelColour"> *</span></label>
                                        <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.product.englishName.$model" type="text" />
                                        <span v-if="$v.product.englishName.$error" class="error text-danger">
                                            <span v-if="!$v.product.englishName.required">{{ $t('QuickProductItem.Name') }}</span>
                                            <span v-if="!$v.product.englishName.maxLength">{{ $t('QuickProductItem.NameLength') }}</span>
                                        </span>
                                    </div>

                                    <div v-if="isOtherLang()" class="col-12 " v-bind:class="{'has-danger' : $v.product.arabicName.$error}">
                                        <label class="text  font-weight-bolder " v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'padding-top:5px;'">{{ $t('QuickProductItem.ItemName') |arabicLanguage}} :<span class="LabelColour"> *</span></label>
                                        <input class="form-control" v-bind:class="isLeftToRight() ? 'text-left' : 'arabicLanguage'" v-model="$v.product.arabicName.$model" type="text" />
                                        <span v-if="$v.product.arabicName.$error" class="error text-danger">
                                            <span v-if="!$v.product.arabicName.required">{{ $t('QuickProductItem.Name') }}</span>
                                            <span v-if="!$v.product.arabicName.maxLength">{{ $t('QuickProductItem.NameLength') }}</span>
                                        </span>
                                    </div>
                                    <div class="col-12">
                                        <label v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'padding-top:10px;'">{{ $t('QuickProductItem.salePrice') }} :</label>
                                        <div>
                                            <my-currency-input v-model="product.salePrice"></my-currency-input>
                                        </div>
                                    </div>
                                    <div class="col-12 " v-if="invoiceWoInventory">
                                        <label>{{ $t('QuickProductItem.ProductCategory') }} :<span class="LabelColour"> *</span></label>
                                        <div>
                                            <categorydropdown :isTemporary="invoiceWoInventory? isTemporary:false" v-model="product.categoryId"></categorydropdown>
                                        </div>
                                    </div>
                                    <div class="col-12 " v-if="isMultiUnit=='true'">
                                        <label>{{ $t('QuickProductItem.LevelOneUnit') }} :</label>
                                        <div>
                                            <unitleveldropdown v-model="product.levelOneUnit" v-bind:values="product.levelOneUnit"></unitleveldropdown>
                                        </div>
                                    </div>
                                    <div class="col-12 "  v-if="invoiceWoInventory">
                                        <label>{{ $t('QuickProductItem.Unit') }} :</label>
                                        <div>
                                            <unitdropdown v-model="product.unitId" v-bind:values="product.unitId"></unitdropdown>
                                        </div>
                                    </div>
                                    <div class="col-12 "  v-if="invoiceWoInventory">
                                        <label>{{ $t('QuickProductItem.Barcode') }} :</label>
                                        <div class="p-0">
                                            <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" type="text" v-model="product.barcode" />
                                        </div>
                                    </div>

                                    <div class="col-xs-12 "  v-if="invoiceWoInventory">
                                        <div class="row">
                                            <div class="col-12  ">
                                                <button v-if="product.barcode==''" class="btn btn-primary " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'ml-3' : 'mr-3'" v-on:click="generateBarcode(false)">{{ $t('QuickProductItem.Generate') }}</button>
                                                <button v-if="product.barcode!=''" class="btn btn-primary" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'ml-3' : 'mr-3'" v-on:click="generateBarcode(true)">{{ $t('QuickProductItem.Delete') }}</button>
                                            </div>
                                            <div class="col-12">
                                                <barcode :height="30" v-bind:value="product.barcode"></barcode>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 mt-2">
                                        <label>Service Item :</label>
                                        <toggle-button v-model="product.serviceItem" class="ml-2" color="#3178F6" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="!loading">
                            <div class="modal-footer justify-content-right" >
                                <button type="button" class="btn btn-primary  " v-on:click="SaveProduct" v-bind:disabled="$v.product.$invalid"> {{ $t('QuickProductItem.btnSave') }}</button>
                                <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{ $t('QuickProductItem.btnClear') }}</button>
                            </div>
                        </div>
                        <div v-else>
                            <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </modal>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import 'vue-loading-overlay/dist/vue-loading.css';
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import VueBarcode from 'vue-barcode';

    import { requiredIf, maxLength } from "vuelidate/lib/validators"
    export default {
        mixins: [clickMixin],
        props: ['show', 'product', 'type'],
        components: {
            'barcode': VueBarcode,

            Loading
        },
        data: function () {
            return {
                rendered: 0,
                arabic: '',
                english: '',
                loading: false,
                isTemporary: true,
                isEnabled: false,
                isDisable: false,
                isMultiUnit: '',
                randomNumber: '',
                invoiceWoInventory: false,

            }
        },
        validations: {
            product:
            {
                englishName: {
                    maxLength: maxLength(50)
                },
                categoryId: {
                },
                arabicName: {
                    required: requiredIf((x) => {
                        if (x.englishName == '' || x.englishName == null)
                            return true;
                        return false;
                    }),
                    maxLength: maxLength(50)
                }
            }
        },
        methods: {


            generateBarcode: function (x) {
                
                if (x) {
                    
                    this.randomNumber = 0; //multiply to generate random number between 0, 100
                    this.product.barcode = '';
                    this.isDisable = false
                    this.isEnabled = false
                }
                else {

                    this.randomNumber = Math.floor(Math.random() * 10000000000); //multiply to generate random number between 0, 100
                    this.product.barcode = this.randomNumber
                    this.isDisable = true
                    this.isEnabled = true
                }

            },

            writeBarcode: function () {
                this.isDisable = true;
                this.isEnabled = true;
            },

            close: function () {
                this.$emit('close');
            },

            SaveProduct: function () {

                this.loading = true;
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                this.product.categoryIdQuick = this.product.categoryId;

                this.$https.post('/Product/SaveQuickProduct', this.product, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {

                        if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Add") {
                            root.$swal({
                                title: "Saved!",
                                text: response.data.message.isAddUpdate,
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonStyling: false,
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,

                            });
                            root.product.id = response.data.message.id
                            if (root.addInvoice) {
                                root.$emit('closeOnSave');
                            }
                            else {
                                root.$emit('closeOnSave', response.data.categoryList.results.products);

                            }
                        }

                        else {
                            root.$swal({
                                title: 'Error!',
                                text: response.data.message.isAddUpdate,
                                type: 'error',
                                confirmButtonClass: "btn btn-danger",
                                icon: 'error',
                                timer: 1500,
                                timerProgressBar: true,
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Something Went Wrong!',
                                text: error.response.data,
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false
                    })
                    .finally(() => root.loading = false)
            },
            AutoIncrementCode: function () {
                var root = this;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https
                    .get('/Product/ProductAutoGenerateCode', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                        if (response.data != null) {
                            
                            root.product.code = response.data;
                            root.rendered++;

                        }
                    });
            },
            GetLastDetails: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.get('/Company/CompanyAccountSetupDetails', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.product.taxMethod = response.data.taxMethod;
                        root.product.taxRateId = response.data.taxRateId;
                    }
                });
            },
        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.isMultiUnit = localStorage.getItem('IsMultiUnit');
            this.invoiceWoInventory = localStorage.getItem('InvoiceWoInventory') == 'true' ? true : false;

            if (this.product.id == '00000000-0000-0000-0000-000000000000' || this.product.id == undefined || this.product.id == '')
                this.AutoIncrementCode();
            this.GetLastDetails();
        }
    }
</script>
