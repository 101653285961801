<template>
    <div class="row" v-if="((isValid('CanAddStockIn') || isValid('CanEditStockIn') || isValid('CanDraftStockIn')) && formName=='StockIn') || ((isValid('CanAddStockOut') || isValid('CanEditStockOut') || isValid('CanDraftStockOut')) && formName=='StockOut') " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-bind:style="$i18n.locale == 'ar' ? languageChange('en') : languageChange('ar')">
        <div class="col-lg-12 col-md-12 ml-auto mr-auto" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <div class="card">
                <div class="card-body" :key="render">
                    <div class="overlay">
                        <div class="row align-items-center h-100 justify-content-sm-center">
                            <div class="loadingio-spinner-dual-ball-44dlc48bacw">
                                <div class="ldio-m86dw9oanea">
                                    <div> </div> <div> </div> <div> </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="invoice-area">
                        <div class="invoice-head">
                            <div class="row BorderBottom">
                                <div class=" col-lg-12 col-md-12 DayHeading ">
                                    <span v-if="formName=='StockIn'">   {{ $t('AddStockValue.StockIn') }}</span>
                                    <span v-else-if="formName=='StockProduction'">{{ $t('AddStockValue.ProductionStock') }}</span>
                                    <span v-else>   {{ $t('AddStockValue.StockOut') }}</span>
                                    <br />
                                </div>
                            </div>
                        </div>
                        <div class="row  " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 pt-2">
                                <label v-if="formName=='StockIn'">{{ $t('AddStockValue.StockInNo') }}:</label>
                                <label v-else-if="formName=='StockProduction'">{{ $t('AddStockValue.ProductionStockNo') }}:</label>
                                <label v-else>{{ $t('AddStockValue.StockOutNo') }}:</label>
                                <div class="form-group" v-bind:class="{ 'has-danger': $v.productDetail.code.$error}">
                                    <input disabled v-model="productDetail.code" class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <span v-if="$v.productDetail.code.$error" class="error text-danger">
                                        <span v-if="!$v.productDetail.code.required">{{ $t('AddStockValue.CodeRequired') }}</span>
                                    </span>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 pt-2">
                                <label>{{ $t('AddStockValue.Date') }}:</label>
                                <div class="form-group" v-bind:class="{ 'has-danger': $v.productDetail.date.$error}">
                                    <datepicker v-model="$v.productDetail.date.$model"></datepicker>
                                    <span v-if="$v.productDetail.date.$error" class="error">
                                        <span v-if="!$v.productDetail.date.required">{{ $t('AddStockValue.DateRequired') }}</span>
                                    </span>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 pt-2" v-if="formName=='StockOut'">
                                <label>{{ $t('AddStockValue.TaxMethod') }}:</label>
                                <multiselect :options="options" v-model="productDetail.taxMethod" v-bind:disabled="productDetail.stockAdjustmentDetails.length>0" :show-labels="false" v-bind:placeholder="$t('AddStockValue.SelectMethod')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                </multiselect>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 pt-2" v-if="formName=='StockOut'">
                                <label>{{ $t('AddStockValue.VAT%') }}:</label>
                                <taxratedropdown v-model="productDetail.taxRateId" :isDisable="productDetail.stockAdjustmentDetails.length>0? true :false" />
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 pt-2">
                                <label>{{ $t('AddStockValue.Warehouse') }}:<span class="LabelColour"> *</span></label>
                                <div class="form-group" v-bind:class="{ 'has-danger': $v.productDetail.warehouseId.$error}">
                                    <warehouse-dropdown v-model="productDetail.warehouseId" :values="productDetail.warehouseId" v-on:input="GetItemOnWarehouse"></warehouse-dropdown>
                                    <span v-if="$v.productDetail.warehouseId.$error" class="error text-danger">
                                        <span v-if="!$v.productDetail.warehouseId.required">{{ $t('AddStockValue.WarehouseRequired') }}</span>
                                    </span>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 pt-2 " v-if="formName=='StockOut'">
                                <label>{{ $t('AddStockValue.Reason') }}</label>
                                <multiselect :options="reasonOptions" v-model="productDetail.reason" :show-labels="false" v-bind:placeholder="$t('SelectType')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                </multiselect>
                            </div>

                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 pt-2">
                                <label v-if="formName=='StockOut'">{{ $t('AddStockValue.ExpenseAccount') }}</label>
                                <label v-else>{{ $t('AddStockValue.Account') }}</label>
                                <accountdropdown v-model="productDetail.bankCashAccountId" :key="accountRender" />
                            </div>

                            <div class="col-xs-12 col-sm-12 col-md-12 col pt-2">
                                <label>{{ $t('AddStockValue.Narration') }}:</label>
                                <div class="form-group">
                                    <textarea v-model="productDetail.narration" class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div v-bind:class="{'has-danger' : $v.productDetail.$error}">
                        <stocklinedetail @input="getstockAdjustmentDetails" :formName="formName" :stockAdjustmentDetailss="stockAdjustmentDetails" :wareHouseId="productDetail.warehouseId" :taxMethod="productDetail.taxMethod" :taxRateId="productDetail.taxRateId" :key="itemRender" />
                        <span v-if="$v.productDetail.$error" class="error"></span>
                    </div>
                    <div v-if="!loading" class="">
                        <button class="btn btn-primary mr-2 float-left" v-on:click="Attachment()">
                            {{ $t('AddPurchase.Attachment') }}
                        </button>

                        <button class="btn btn-danger float-right" v-on:click="onCancel">{{ $t('AddStockValue.Cancel') }}</button>
                        <span v-if="productDetail.stockAdjustmentDetails.filter(x => x.outOfStock).length == 0 && productDetail.stockAdjustmentDetails.length > 0 && productDetail.warehouseId !='' ">
                            <button type="button" class="btn btn-primary  float-right" v-on:click="SaveStockValue(false)" v-if=" ((isValid('CanAddStockIn') || isValid('CanEditStockIn'))  && formName=='StockIn') ||  ((isValid('CanAddStockOut') || isValid('CanEditStockOut'))  && formName=='StockOut')  "><i class="far fa-save"></i> {{ $t('AddStockValue.SaveandPost') }}</button>
                            <button type="button" class="btn btn-primary  float-right" v-on:click="SaveStockValue(true)" v-if="(isValid('CanDraftStockIn') && formName=='StockIn') ||  (isValid('CanDraftStockOut') && formName=='StockOut')  "><i class="far fa-save"></i> {{ $t('AddStockValue.SaveasDraft') }}</button>
                        </span>
                        <span v-else>
                            <button type="button" class="btn btn-primary  float-right" disabled v-on:click="SaveStockValue(false)" v-if=" ((isValid('CanAddStockIn') || isValid('CanEditStockIn'))  && formName=='StockIn') ||  ((isValid('CanAddStockOut') || isValid('CanEditStockOut'))  && formName=='StockOut') "><i class="far fa-save"></i> {{ $t('AddStockValue.SaveandPost') }}</button>
                            <button type="button" class="btn btn-primary  float-right" disabled v-on:click="SaveStockValue(true)" v-if="(isValid('CanDraftStockIn') && formName=='StockIn') ||  (isValid('CanDraftStockOut') && formName=='StockOut') "><i class="far fa-save"></i>{{ $t('AddStockValue.SaveasDraft') }}</button>
                        </span>
                    </div>
                    <div class="card-footer col-md-3" v-else>
                        <loading :name="loading" :active.sync="loading"
                                 :can-cancel="false"
                                 :is-full-page="true"></loading>
                    </div>
                </div>
            </div>
            <bulk-attachment :attachmentList="productDetail.attachmentList" :show="isAttachshow" v-if="isAttachshow" @close="attachmentSaved" />

        </div>
    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>

<script>
    import Loading from 'vue-loading-overlay';
    import "vue-loading-overlay/dist/vue-loading.css";
    import clickMixin from '@/Mixins/clickMixin'
    import { required } from "vuelidate/lib/validators";
    import Multiselect from 'vue-multiselect'
    import moment from "moment";
    export default {
        mixins: [clickMixin],
        name: "AddCheckOut",
        components: {
            Multiselect,
            Loading
        },
        props: ['formName'],
        data: function () {
            return {
                stockAdjustmentDetails: [],
                render: 0,
                productDetail: {
                    id: '00000000-0000-0000-0000-000000000000',
                    date: '',
                    narration: '',
                    code: '',
                    warehouseId: '',
                    isDraft: true,
                    stockAdjustmentType: '',
                    stockAdjustmentDetails: [],
                    attachmentList: [],
                    taxMethod: '',
                    taxRateId: "",
                    reason: '',
                    bankCashAccountId: '',
                    isSerial: false
                },
                isFifo: false,
                loading: false,
                isAttachshow: false,
                language: 'Nothing',
                options: [],
                reasonOptions: [],
                itemRender: 0,
                accountRender: 0
            }
        },
        validations: {
            productDetail: {
                date: {
                    required
                },
                code: {
                    required
                },
                warehouseId: {
                    required
                }
            }
        },
        methods: {
            Attachment: function () {
                this.isAttachshow = true;
            },

            attachmentSaved: function (attachment) {
                this.productDetail.attachmentList = attachment;
                this.isAttachshow = false;
            },

            GetItemOnWarehouse: function () {
                this.itemRender++;
            },
            languageChange: function (lan) {
                if (this.language == lan) {
                    if (this.productDetail.id == '00000000-0000-0000-0000-000000000000') {

                        var getLocale = this.$i18n.locale;
                        this.language = getLocale;

                        this.$router.go('/addSale');
                    }
                    else {
                        this.$swal({
                            title: 'Error!',
                            text: 'You cannot Change language during update, otherwise your current page data will be lose',
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            icon: 'error',
                            timer: 4000,
                            timerProgressBar: true,
                        });
                    }
                }
            },

            GetAutoCodeGenerator: function (value) {

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.get('/Product/StockAdjustmentCode?stockAdjustmentType=' + value, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.productDetail.code = response.data;

                    }
                });
            },
            onCancel: function () {
                var root = this;
                if (((root.isValid('CanViewStockIn') || root.isValid('CanDraftStockIn')) && root.formName == 'StockIn') || ((root.isValid('CanViewStockOut')  || root.isValid('CanDraftStockOut')) && root.formName == 'StockOut')) {
                    root.$router.push({
                        path: '/stockValue?formName=' + root.formName,
                        query: {
                            data: 'StockValues' + root.formName
                        }
                    })
                }
                else {
                    root.$router.go();
                }
               
                
            },
            getstockAdjustmentDetails: function (stockAdjustmentDetails) {

                this.productDetail.stockAdjustmentDetails = stockAdjustmentDetails;
            },
            SaveStockValue: function (x) {
                var root = this;
                var token = '';
                
                if (x == false) {
                    if ((this.isValid('CanDraftStockIn') && this.formName == 'StockIn') || (this.isValid('CanDraftStockOut') && this.formName == 'StockOut')) {
                        localStorage.setItem('active', 'Draft');
                    }
                    else if ((this.isValid('CanViewStockIn') && this.formName == 'StockIn') || (this.isValid('CanViewStockOut') && this.formName == 'StockOut')) {
                        localStorage.setItem('active', 'Approved');
                    }
                    

                }
                if (x == true) {
                    if ((this.isValid('CanDraftStockIn') && this.formName == 'StockIn') || (this.isValid('CanDraftStockOut') && this.formName == 'StockOut')) {
                        localStorage.setItem('active', 'Draft');
                    }
                    else if ((this.isValid('CanViewStockIn') && this.formName == 'StockIn') || (this.isValid('CanViewStockOut') && this.formName == 'StockOut')) {
                        localStorage.setItem('active', 'Approved');
                    }


                }
                this.productDetail.isDraft = x;
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                if (root.formName === 'StockIn')
                    root.loading = true;

                root.productDetail.stockAdjustmentDetails.forEach(function (x) {
                    x.quantity = x.totalPiece;
                });
                this.productDetail.isSerial = localStorage.getItem('IsSerial') == 'true' ? true : false;

                root.productDetail.date = root.productDetail.date + " " + moment().format("hh:mm A");
                this.$https.post('/Product/AddStockAdjustment', this.productDetail, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Add") {

                        root.loading = false
                        root.info = response.data.bpi

                        root.$swal({
                            title: "Saved!",
                            text: response.data.message.isAddUpdate,
                            type: 'success',
                            confirmButtonClass: "btn btn-success",
                            buttonStyling: false,
                            icon: 'success',
                            timer: 1500,
                            timerProgressBar: true,

                        }).then(function (ok) {
                            if (ok != null) {
                                if (((root.isValid('CanViewStockIn') || root.isValid('CanDraftStockIn')) && root.formName == 'StockIn') || ((root.isValid('CanViewStockOut') || root.isValid('CanDraftStockOut')) && root.formName == 'StockOut')) {
                                    root.$router.push({
                                        path: '/stockValue?formName=' + root.formName,
                                        query: {
                                            data: 'StockValues' + root.formName
                                        }
                                    })
                                }
                                else {
                                    root.$router.go();
                                }
                            }
                        });
                    }
                    else if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Update") {
                        root.loading = false
                        root.info = response.data.bpi

                        root.$swal({
                            title: "Saved!",
                            text: response.data.message.isAddUpdate,
                            type: 'success',
                            confirmButtonClass: "btn btn-success",
                            buttonStyling: false,
                            icon: 'success',
                            timer: 1500,
                            timerProgressBar: true,

                        }).then(function (ok) {
                            if (ok != null) {
                                if (((root.isValid('CanViewStockIn') || root.isValid('CanDraftStockIn')) && root.formName == 'StockIn') || ((root.isValid('CanViewStockOut') || root.isValid('CanDraftStockOut')) && root.formName == 'StockOut')) {
                                    root.$router.push({
                                        path: '/stockValue?formName=' + root.formName,
                                        query: {
                                            data: 'StockValues' + root.formName
                                        }
                                    })
                                }
                                else {
                                    root.$router.go();
                                }
                            }
                        });
                    }
                    else {
                        root.loading = false
                        root.$swal({
                            title: 'Error!',
                            text: response.data.message.isAddUpdate,
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            icon: 'error',
                            timer: 1500,
                            timerProgressBar: true,
                        });
                    }

                }, function (value) {
                    root.loading = false
                    root.$swal({
                        title: 'Error!',
                        text: value,
                        type: 'error',
                        confirmButtonClass: "btn btn-info",
                        buttonsStyling: false
                    });
                }
                );
            },
        },
        watch: {
            formName: function () {
                if (this.formName == 'StockIn') {

                    if (this.$route.query.data == undefined) {
                        this.render++;
                        this.GetAutoCodeGenerator(this.formName);
                        this.productDetail.stockAdjustmentType = this.formName;
                    }
                    if (this.$route.query.data != undefined) {
                        this.render++;
                        this.productDetail = this.$route.query.data.message;

                        this.productDetail.stockAdjustmentType = this.formName;
                        
                    }
                }
                if (this.formName == 'StockProduction') {
                    if (this.$route.query.data == undefined) {
                        this.render++;
                        this.GetAutoCodeGenerator(this.formName);
                        this.productDetail.stockAdjustmentType = this.formName;
                    }
                    if (this.$route.query.data != undefined) {
                        this.render++;
                        this.productDetail = this.$route.query.data.message;

                        this.productDetail.stockAdjustmentType = this.formName;
                    }
                }
                if (this.formName == 'StockOut') {
                    if (this.$route.query.data == undefined) {
                        this.render++;
                        this.GetAutoCodeGenerator(this.formName);
                        this.productDetail.stockAdjustmentType = this.formName;
                        this.productDetail.taxRateId = localStorage.getItem('TaxRateId');
                        this.productDetail.taxMethod = localStorage.getItem('taxMethod');
                    }
                    if (this.$route.query.data != undefined) {
                        this.render++;
                        this.productDetail = this.$route.query.data.message;

                        this.productDetail.stockAdjustmentType = this.formName;

                    }
                }
            }
        },
        created: function () {
            this.$emit('input', this.$route.name + this.formName);
            this.productDetail.warehouseId = localStorage.getItem('WareHouseId');
            this.isFifo = localStorage.getItem('fIFO') == 'true' ? true : false;
        },

        mounted: function () {

            this.language = this.$i18n.locale;
            if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {
                this.options = ['Inclusive', 'Exclusive'];
                this.reasonOptions = ['Damaged', 'Expire', 'Lost'];
            }
            else {
                this.options = ['شامل', 'غير شامل'];
                this.reasonOptions = ['تالف', 'تنقضي', 'ضائع'];
            }
            this.productDetail.date = moment().format("DD MMM YYYY");
            if (this.formName == 'StockIn') {

                if (this.$route.query.data == undefined) {
                    this.render++;
                    this.GetAutoCodeGenerator(this.formName);

                    this.productDetail.stockAdjustmentType = this.formName;
                }
                if (this.$route.query.data != undefined) {

                    this.render++;
                    this.productDetail = this.$route.query.data;
                    var isMultiUnit2 = localStorage.getItem('IsMultiUnit');
                    if (isMultiUnit2 == 'true' && localStorage.getItem('decimalQuantity') == 'true') {
                        this.productDetail.stockAdjustmentDetails.forEach(function (x) {

                            x.highQty = parseInt(parseFloat(x.quantity) / (parseFloat(x.product.unitPerPack) == 0 ? 1 : parseFloat(x.product.unitPerPack)));
                            x.quantity = parseFloat(parseFloat(x.quantity) % (parseFloat(x.product.unitPerPack) == 0 ? 1 : parseFloat(x.product.unitPerPack))).toFixed(3).slice(0, -1);

                        });
                    }
                    if (isMultiUnit2 == 'true' && localStorage.getItem('decimalQuantity') != 'true') {
                        this.productDetail.stockAdjustmentDetails.forEach(function (x) {

                            x.highQty = parseInt(parseInt(x.quantity) / (parseInt(x.product.unitPerPack) == 0 ? 1 : parseInt(x.product.unitPerPack)));
                            x.quantity = parseInt(parseInt(x.quantity) % (parseInt(x.product.unitPerPack) == 0 ? 1 : parseInt(x.product.unitPerPack)));

                        });
                    }
                    this.productDetail.stockAdjustmentType = this.formName;
                    this.accountRender++;
                }
            }
            
            if (this.formName == 'StockOut') {
                if (this.$route.query.data == undefined) {
                    this.render++;
                    this.GetAutoCodeGenerator(this.formName);
                    this.productDetail.stockAdjustmentType = this.formName;

                    this.productDetail.taxRateId = localStorage.getItem('TaxRateId');
                    this.productDetail.taxMethod = localStorage.getItem('taxMethod');
                }
                if (this.$route.query.data != undefined) {

                    this.productDetail = this.$route.query.data;
                    var isMultiUnit = localStorage.getItem('IsMultiUnit');
                    if (isMultiUnit == 'true' && localStorage.getItem('decimalQuantity') == 'true') {
                        this.productDetail.stockAdjustmentDetails.forEach(function (x) {

                            x.highQty = parseInt(parseFloat(x.quantity) / parseFloat(x.product.unitPerPack));
                            x.quantity = parseFloat(parseFloat(x.quantity) % parseFloat(x.product.unitPerPack)).toFixed(3).slice(0, -1);

                        });
                    }
                    if (isMultiUnit == 'true' && localStorage.getItem('decimalQuantity') != 'true') {
                        this.productDetail.stockAdjustmentDetails.forEach(function (x) {

                            x.highQty = parseInt(parseInt(x.quantity) / parseInt(x.product.unitPerPack));
                            x.quantity = parseInt(parseInt(x.quantity) % parseInt(x.product.unitPerPack));

                        });
                    }
                    this.productDetail.stockAdjustmentType = this.formName;
                    this.render++;
                    this.accountRender++;
                }
            }
            if (this.formName == 'StockProduction') {
                if (this.$route.query.data == undefined) {
                    this.render++;
                    this.GetAutoCodeGenerator(this.formName);
                    this.productDetail.stockAdjustmentType = this.formName;
                }
                if (this.$route.query.data != undefined) {

                    this.render++;
                    this.productDetail = this.$route.query.data;
                    var isMultiUnit1 = localStorage.getItem('IsMultiUnit');
                    if (isMultiUnit1 == 'true' && localStorage.getItem('decimalQuantity') == 'true') {
                        this.productDetail.stockAdjustmentDetails.forEach(function (x) {
                            x.highQty = parseInt(parseFloat(x.quantity) / parseFloat(x.product.unitPerPack));
                            x.quantity = parseFloat(parseFloat(x.quantity) % parseFloat(x.product.unitPerPack)).toFixed(3).slice(0, -1);
                        });
                    }
                    if (isMultiUnit1 == 'true' && localStorage.getItem('decimalQuantity') != 'true') {
                        this.productDetail.stockAdjustmentDetails.forEach(function (x) {
                            x.highQty = parseInt(parseInt(x.quantity) / parseInt(x.product.unitPerPack));
                            x.quantity = parseInt(parseInt(x.quantity) % parseInt(x.product.unitPerPack));
                        });
                    }
                    this.productDetail.stockAdjustmentType = this.formName;
                }
            }
        }
    }
</script>