<template>
    <div class="row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-if="isValid('CanDraftStockTransfer') || isValid('CanEditStockTransfer') || isValid('CanAddStockTransfer')" v-bind:style="$i18n.locale == 'ar' ? languageChange('en') : languageChange('ar')">
        <div class="col-md-12 ml-auto mr-auto">
            <div class="card" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                <div class="card-body">
                    <div class="tab-content" id="nav-tabContent">
                        <div class=" row BorderBottom ">
                            <div class="col-lg-9  ">

                                <span v-if="wareHouseTransfer.id === '00000000-0000-0000-0000-000000000000'"> <span class="MainLightHeading">{{ $t('AddWareHouseTransfer.AddWareHouseTransfer') }} - </span><span class="DayHeading">{{wareHouseTransfer.code}}</span></span>
                                <span v-else><span class="MainLightHeading">{{ $t('AddWareHouseTransfer.UpdateWareHouseTransfer') }} - </span><span class="DayHeading">{{wareHouseTransfer.code}}</span></span>
                            </div>
                            <div class="col-lg-3 text-right  ">
                                <span>
                                    {{wareHouseTransfer.date}}
                                </span>
                            </div>
                        </div>

                        <div class="row">


                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 pt-2">
                                <label>{{ $t('AddWareHouseTransfer.FromWareHouse') }} :<span class="LabelColour"> *</span></label>
                                <div>
                                    <warehouse-dropdown v-model="$v.wareHouseTransfer.fromWareHouseId.$model"
                                                        @default="setDefaultWareHouseFrom"
                                                        v-on:input="randerWareHouseFunc" :key="rendered" />
                                    <span v-if="$v.wareHouseTransfer.fromWareHouseId.$error" class="error text-danger"> </span>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 pt-2">
                                <label>{{ $t('AddWareHouseTransfer.ToWareHouse') }} :<span class="LabelColour"> *</span></label>
                                <div>
                                    <warehouse-dropdown v-model="$v.wareHouseTransfer.toWareHouseId.$model" :fromWareHouse="wareHouseTransfer.fromWareHouseId"
                                                        v-bind:key="randerWareHouse" />
                                    <span v-if="$v.wareHouseTransfer.toWareHouseId.$error" class="error text-danger"> </span>
                                </div>
                            </div>

                            <!--<div class="col-sm-4 form-group">
                            <label>{{ $t('PurchaseOrder.Date') }}  :<span class="LabelColour"> *</span></label>
                            <div>
                                <datepicker v-model="wareHouseTransfer.date" @pick="wareHouseTransfer.date = $event" :key="rander" />
                            </div>
                        </div>-->
                        </div>
                        <br />

                        <div class="row">
                            <div class="col-lg-12">

                                <warehousetransferitem :wareHouseTransferItem="wareHouseTransfer.wareHouseTransferItems" :wareHouse="wareHouseTransfer.fromWareHouseId" @input="SaveWareHouseTransferItems" v-bind:key="randerWareHouse" />

                            </div>
                        </div>

                        <div v-if="!loading" class="col-md-12 text-right">
                            <div v-if="wareHouseTransfer.id === '00000000-0000-0000-0000-000000000000'">
                                <button class="btn btn-primary mr-2 float-left" v-on:click="Attachment()">
                                    {{ $t('AddSaleOrder.Attachment') }}
                                </button>

                                <button class="btn btn-primary  mr-2"
                                        v-if="isValid('CanDraftStockTransfer')"
                                        v-on:click="saveWareHouseTransfer('Draft')">
                                    <i class="far fa-save"></i>  {{ $t('AddWareHouseTransfer.SaveAsDraft') }}
                                </button>
                                <button class="btn btn-primary  mr-2"
                                        v-if="isValid('CanAddStockTransfer')"
                                        v-on:click="saveWareHouseTransfer('Approved')">
                                    <i class="far fa-save"></i>  {{ $t('AddWareHouseTransfer.SaveAsPost') }}
                                </button>
                                <button class="btn btn-danger  mr-2"
                                        v-on:click="goToWareHouseTransfer">
                                    {{ $t('AddWareHouseTransfer.Cancel') }}
                                </button>
                            </div>
                            <div v-else>
                                <button class="btn btn-primary mr-2 float-left" v-on:click="Attachment()">
                                    {{ $t('AddSaleOrder.Attachment') }}
                                </button>

                                <button class="btn btn-primary  mr-2"
                                        v-if="isValid('CanDraftStockTransfer')"
                                        v-on:click="saveWareHouseTransfer('Draft')">
                                    <i class="far fa-save"></i>  {{ $t('AddWareHouseTransfer.UpdateAsDraft') }}
                                </button>

                                <button class="btn btn-primary  mr-2"
                                        v-on:click="saveWareHouseTransfer('Approved')"
                                        v-if="isValid('CanEditStockTransfer')">
                                    <i class="far fa-save"></i> {{ $t('AddWareHouseTransfer.UpdateAsPost') }}
                                </button>
                                <button class="btn btn-danger  mr-2"
                                        v-on:click="goToWareHouseTransfer">
                                    {{ $t('AddWareHouseTransfer.Cancel') }}
                                </button>
                            </div>




                        </div>

                        <div class="card-footer col-md-3" v-else>
                            <loading :active.sync="loading"
                                     :can-cancel="true"
                                     :is-full-page="true"></loading>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <bulk-attachment :attachmentList="wareHouseTransfer.attachmentList" :show="show" v-if="show" @close="attachmentSave" />

    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import Loading from "vue-loading-overlay";
    import moment from "moment";
    import "vue-loading-overlay/dist/vue-loading.css";
    import { required } from "vuelidate/lib/validators";

    //import VueBarcode from 'vue-barcode';
    export default {
        mixins: [clickMixin],
        name: "AddWareHouseTransfer",
        components: {
            Loading,
        },
        data: function () {
            return {

                wareHouseTransfer: {
                    id: "00000000-0000-0000-0000-000000000000",
                    date: "",
                    code: "",
                    fromWareHouseId: "",
                    toWareHouseId: "",
                    wareHouseTransferItems: [],
                    attachmentList: [],
                },
                loading: false,
                show: false,
                rendered: 0,
                language: 'Nothing',
                randerWareHouse: 0
            };
        },
        validations: {
            wareHouseTransfer: {
                date: { required },
                code: { required },
                fromWareHouseId: { required },
                toWareHouseId: { required },
                wareHouseTransferItems: {
                    required,
                    isOutOfStock: function (saleItems) {

                        if (saleItems.filter(x => x.outOfStock).length > 0) {
                            return false
                        }

                        return true;
                    }
                },
            },
        },
        methods: {
            Attachment: function () {
                this.show = true;
            },

            attachmentSave: function (attachment) {
                this.wareHouseTransfer.attachmentList = attachment;
                this.show = false;
            },

            setDefaultWareHouseFrom: function (id) {

                this.wareHouseTransfer.fromWareHouseId = id;
                this.rendered++;
                this.randerWareHouse++;
            },
            //setDefaultWareHouseTo: function (id) {
            //    this.wareHouseTransfer.toWareHouseId = id;
            //    this.rendered++;
            //},
            randerWareHouseFunc: function () {


                this.randerWareHouse++;
                this.wareHouseTransfer.toWareHouseId = '00000000-0000-0000-0000-000000000000';

            },
            languageChange: function (lan) {

                if (this.language == lan) {
                    if (this.wareHouseTransfer.id == '00000000-0000-0000-0000-000000000000') {

                        var getLocale = this.$i18n.locale;
                        this.language = getLocale;

                        this.$router.go('/addSale');
                    }
                    else {
                        this.$swal({
                            title: 'Error!',
                            text: 'You cannot Change language during update, otherwise your current page data will be lose',
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            icon: 'error',
                            timer: 4000,
                            timerProgressBar: true,
                        });
                    }
                }


            },

            AutoIncrementCode: function () {
                var root = this;
                var token = "";
                if (root.$session.exists()) {
                    token = localStorage.getItem("token");
                }
                root.$https
                    .get("/Purchase/WareHouseTranferAutoGenerateNo", {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then(function (response) {
                        if (response.data != null) {
                            root.wareHouseTransfer.code = response.data;
                        }
                    });
            },
            SaveWareHouseTransferItems: function (wareHouseTransferItems) {
                this.wareHouseTransfer.wareHouseTransferItems = wareHouseTransferItems;
            },
            saveWareHouseTransfer: function (status) {
                this.wareHouseTransfer.approvalStatus = status
                localStorage.setItem('active', status);

                this.loading = true;
                var root = this;
                var token = "";
                if (this.$session.exists()) {
                    token = localStorage.getItem("token");
                }

                var isMultiUnit = localStorage.getItem('IsMultiUnit');
                if (isMultiUnit == 'true') {
                    root.wareHouseTransfer.wareHouseTransferItems.forEach(function (x) {
                        x.quantity = parseInt(parseInt(x.quantity) + (parseInt(x.highQty) * parseInt(x.product.unitPerPack)));
                    });
                }

                this.$https
                    .post("/Purchase/SaveWareHouseTransferInformation", root.wareHouseTransfer, {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then((response) => {
                        root.loading = false;
                        root.info = response.data.bpi;

                        root.$swal.fire({
                            icon: "success",
                            title: "Saved Successfully",
                            showConfirmButton: false,

                            timer: 800,
                            timerProgressBar: true,
                        });
                        if (root.isValid('CanViewStockTransfer') || root.isValid('CanDraftStockTransfer')) {
                            root.$router.push({
                                path: '/wareHouseTransfer',
                                query: {
                                    data: 'WareHouseTransfers'
                                }
                            })
                        }
                        else {
                            root.$router.go();
                        }
                    })
                    .catch((error) => {

                        console.log(error);
                        root.$swal.fire({
                            icon: 'error',
                            title: 'Error!',
                            text: error.response.data,
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                        });

                        root.loading = false;
                    })
                    .finally(() => (root.loading = false));
            },

            goToWareHouseTransfer: function () {
                var root = this;
                if (root.isValid('CanViewStockTransfer') || root.isValid('CanDraftStockTransfer')) {
                    root.$router.push({
                        path: '/wareHouseTransfer',
                        query: {
                            data: 'WareHouseTransfers'
                        }
                    })
                }
                else {
                    root.$router.go();
                }
                
            },


        },
        beforeMount: function () {
            if (this.$route.query.data == undefined) {
                this.AutoIncrementCode();
                this.wareHouseTransfer.date = moment().format("LLL");
                this.rander++;
            }
            if (this.$route.query.data != undefined) {


                this.wareHouseTransfer = this.$route.query.data;

                //  this.warehouse = this.$route.query.data;
            }


        },
        created: function () {

            this.$emit('input', this.$route.name);
        },
        mounted: function () {
            this.language = this.$i18n.locale;
        }
    };
</script>
