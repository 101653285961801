<template>
    <modal :show="show" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" :modalLarge="true">

        <div style="margin-bottom:0px" class="card">
            <div class="card-body">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myModalLabel"> Invoice Return </h5>
                        </div>

                        <div class="card-body" style="background-color:#FBFBFB">
                            <div class="row " style="overflow: auto;">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <table class="table add_table_list_bg" style="table-layout:fixed;width:100%;" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                        <thead class="m-0" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                            <tr class="tbl_border">
                                                <th style="width: 5%;">#</th>
                                                <th style="width: 30%;">
                                                    {{ $t('ReturnItemModel.Product') }}
                                                </th>
                                                <th class="text-center" style="width: 15%;">
                                                    {{ $t('ReturnItemModel.UnitPrice') }}
                                                </th>
                                                <th class="text-center" style="width: 10%;">
                                                    {{ $t('ReturnItemModel.Quantity') }}
                                                </th>
                                                <th class="text-center" style="width: 20%;">
                                                    {{ $t('ReturnItemModel.ReturnQty') }}
                                                </th>
                                                <th class="text-center" style="width: 10%;">
                                                    {{ $t('ReturnItemModel.Expire') }}
                                                </th>
                                                <th style="width: 10%;"></th>
                                            </tr>
                                        </thead>
                                        <tbody id="sale-item" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                            <template v-for="(prod, index) in saleDetail.saleItems">
                                                <tr class="tbl_border" :key="prod.id" v-bind:class="{'bg-danger':prod.quantity<prod.returnQuantity || prod.outOfDate}" style="border-bottom: 1px solid #d6d6d6; background: rgb(234, 241, 254);">
                                                    <td>{{index+1}}</td>
                                                    <td>
                                                        {{($i18n.locale == 'en' ||isLeftToRight())?prod.productName==''? prod.arabicName:prod.productName : prod.arabicName==''?prod.productName:prod.arabicName}}
                                                    </td>
                                                    <td class="text-center">
                                                        {{parseFloat(prod.unitPrice).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                                    </td>
                                                    <td class="text-center" :key="quantityRander">
                                                        {{prod.quantity - (prod.returnQuantity != undefined && prod.returnQuantity != '' ? prod.returnQuantity : 0)}}
                                                    </td>
                                                    <td>
                                                        <input v-model="prod.returnQuantity"
                                                               type="number"
                                                               @focus="$event.target.select()"
                                                               class="form-control input-border text-center qty-input"
                                                               @keyup="updateLineTotal($event.target.value, 'returnQuantity', prod)" />
                                                    </td>
                                                    <td class="text-center">
                                                        {{prod.outOfDate? 'Expire':''}}
                                                    </td>
                                                    <td>
                                                        <button @click="removeProduct(prod.id)"
                                                                title="Remove Item"
                                                                class="btn btn-secondary btn-neutral btn-sm btn-round btn-icon float-right">
                                                            <i class="nc-icon nc-simple-remove"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer justify-content-right">
                            <button :disabled="saleDetail.saleItems.filter(x => x.quantity< x.returnQuantity).length > 0 || saleDetail.saleItems.filter(x => x.outOfDate).length > 0" type="button" class="btn btn-primary  mr-3 " v-on:click="SubmitReturnItem()"> {{ $t('ReturnItemModel.Select') }}</button>
                            <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()"> {{ $t('ReturnItemModel.btnClear') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </modal>

</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import moment from "moment";
    export default {
        mixins: [clickMixin],
        props: ['show', 'sale'],
        data: function () {
            return {
                saleDetail: '',
                saleList: [],
                save: false,
                quantityRander:0
            }
        },
        methods: {            
            SubmitReturnItem: function () {
                var root = this;
                root.saleDetail.isReturnItem = true;
                root.$emit('EditTOuchInvocie', root.saleDetail);
                root.close();
            },
            removeProduct: function (id) {
                
                this.saleDetail.saleItems = this.saleDetail.saleItems.filter((prod) => {
                    return prod.id != id;
                });

            },
            updateLineTotal: function (e, prop, product) {
                
                if (e != undefined) {
                    if (prop == "quantity") {                        
                        product.quantity = e;
                    }
                    if (prop == "returnQuantity") {
                        if (e < 0) {
                            e = 0;
                        }
                        product.returnQuantity = e;
                    }
                    if (product.product.saleReturnDays == 0 || product.product.saleReturnDays == '' || product.product.saleReturnDays == null) {
                        this.$set(product, 'outOfDate', false);
                    }
                    else {
                        var invoiceDate = moment(this.saleDetail.date).add(product.product.saleReturnDays, 'days').format("DD MMM YYYY");
                        var todayDate = moment().format("DD MMM YYYY");
                        var isTrue = moment(invoiceDate).isSameOrAfter(todayDate);
                        if (isTrue) {
                            this.$set(product, 'outOfDate', false);
                        }
                        else {
                            this.$set(product, 'outOfDate', true );
                        }
                    }
                }

            },

            close: function () {
                this.$emit('close', false);
            },

        },
        created: function () {
            this.sale.saleItems = this.sale.saleItems.filter(x => x.remainingQuantity > 0);
            this.saleDetail = this.sale;
        },
        mounted: function () {
            var root = this;
            root.saleDetail.saleItems.forEach(function (item) {
                root.updateLineTotal(item.remainingQuantity, "quantity", item);
            });
        }
    }
</script>
<style scoped>
    .cardClass:hover {
        background-color: #2d76f7;
        color: white;
        cursor: pointer
    }

    .qty-input {
        background-color:transparent;
        border-color: transparent !important;
        width: 100% !important;
        padding-left: 3px;
        padding-right: 3px;
        text-align: center;
    }
</style>
