<template>
    <modal :show="show" v-if=" isValid('CanAddSubCategory') || isValid('CanEditSubCategory') ">
        <div style="margin-bottom:0px" class="card" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <div class="card-body">
                <div class="card-body">
                    <div class="col-lg-12">
                        <div class="tab-content" id="nav-tabContent">
                            <div class="modal-header" v-if="type=='Edit'">

                                <h5 class="modal-title DayHeading" id="myModalLabel"> {{ $t('AddSubCategory.UpdateSubCategory') }}</h5>

                            </div>
                            <div class="modal-header" v-else>

                                <h5 class="modal-title DayHeading" id="myModalLabel"> {{ $t('AddSubCategory.AddSubCategory') }}</h5>

                            </div>
                            <div >
                                <div class="card-body">
                                    <div class="row ">
                                        <div class="form-group has-label col-sm-12" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                            <label>{{ $t('AddSubCategory.Code') }} :<span class="LabelColour"> *</span></label>
                                            <div>
                                                <input readonly class="form-control" :key="rendered" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="subCategory.code" />

                                            </div>
                                        </div>
                                        <div class="col-sm-12" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                            <label>{{ $t('AddSubCategory.SelectCategory') }} :<span class="LabelColour"> *</span></label>
                                            <div v-bind:class="{'has-danger' : $v.subCategory.categoryId.$error}">
                                                <categorydropdown v-model="$v.subCategory.categoryId.$model" v-bind:values="subCategory.categoryId"></categorydropdown>
                                                <span v-if="$v.subCategory.categoryId.$error" class="error text-danger">
                                                    <span v-if="!$v.subCategory.categoryId.required">Category is required</span>
                                                </span>
                                            </div>
                                        </div>

                                        <div v-if="english=='true'" class="form-group has-label col-sm-12" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                            <label>{{ $t('AddSubCategory.SubCategoryName') |englishLanguage}} :<span class="LabelColour"> *</span></label>
                                            <div v-bind:class="{'has-danger' : $v.subCategory.name.$error}">
                                                <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.subCategory.name.$model" />
                                                <span v-if="$v.subCategory.name.$error" class="error text-danger">
                                                    <span v-if="!$v.subCategory.name.required"> {{ $t('AddSubCategory.NameRequired') }}</span>
                                                    <span v-if="!$v.subCategory.name.maxLength">{{ $t('AddSubCategory.NameLength') }}</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div v-if="isOtherLang()" class=" form-group has-label col-sm-12" v-bind:class="{'has-danger' : $v.subCategory.nameArabic.$error}">
                                            <label class="text  font-weight-bolder">{{ $t('AddSubCategory.SubCategoryNameAr') |arabicLanguage}}: <span class="LabelColour"> *</span></label>
                                            <input class="form-control  " v-bind:class="isLeftToRight() ? 'text-left' : 'arabicLanguage'"  v-model="$v.subCategory.nameArabic.$model" type="text" />
                                            <span v-if="$v.subCategory.nameArabic.$error" class="error">
                                                <span v-if="!$v.subCategory.nameArabic.required"> {{ $t('AddSubCategory.NameRequired') }}</span>
                                                <span v-if="!$v.subCategory.nameArabic.maxLength">{{ $t('AddSubCategory.NameLength') }}</span>
                                            </span>
                                        </div>

                                        <!--<div class="col-sm-12" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                            <label>{{ $t('Description') }} :</label>
                                            <div>
                                                <textarea class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="subCategory.description" />

                                            </div>
                                        </div>-->
                                        <div class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.subCategory.description.$error}">
                                            <label class="text  font-weight-bolder"> {{ $t('AddSubCategory.Description') }}: </label>
                                            <textarea class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.subCategory.description.$model" type="text" />
                                            <span v-if="$v.subCategory.description.$error" class="error">{{ $t('AddSubCategory.descriptionLength150') }}</span>
                                        </div>
                                        <div class="form-group col-md-12" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                            <label style="margin: 7px;">{{ $t('AddSubCategory.Active') }}</label> <br />
                                            <div class="bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-animate" v-bind:class="{'bootstrap-switch-on': subCategory.isActive, 'bootstrap-switch-off': !subCategory.isActive}" v-on:click="subCategory.isActive = !subCategory.isActive" style="width: 72px;">
                                                <div class="bootstrap-switch-container" style="width: 122px; margin-left: 0px;">
                                                    <span class="bootstrap-switch-handle-on bootstrap-switch-success" style="width: 50px;">
                                                        <i class="nc-icon nc-check-2"></i>
                                                    </span>
                                                    <span class="bootstrap-switch-label" style="width: 30px;">&nbsp;</span>
                                                    <span class="bootstrap-switch-handle-off bootstrap-switch-success" style="width: 50px;">
                                                        <i class="nc-icon nc-simple-remove"></i>
                                                    </span>
                                                    <input class="bootstrap-switch" type="checkbox" data-toggle="switch" checked="" data-on-label="<i class='nc-icon nc-check-2'></i>" data-off-label="<i class='nc-icon nc-simple-remove'></i>" data-on-color="success" data-off-color="success">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="!loading">
                                <div class="modal-footer justify-content-right" v-if="type=='Edit' && isValid('CanEditSubCategory')">
                                    <button type="button" class="btn btn-primary  " v-on:click="SaveSubCategory" v-bind:disabled="$v.subCategory.$invalid"> {{ $t('AddSubCategory.btnUpdate') }}</button>
                                    <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{ $t('AddSubCategory.btnClear') }}</button>
                                </div>
                                <div class="modal-footer justify-content-right" v-if="type!='Edit' && isValid('CanAddSubCategory')">
                                    <button type="button" class="btn btn-primary  " v-on:click="SaveSubCategory" v-bind:disabled="$v.subCategory.$invalid"> {{ $t('AddSubCategory.btnSave') }}</button>
                                    <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{ $t('AddSubCategory.btnClear') }}</button>
                                </div>
                            </div>
                            <div v-else>
                                <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
    <acessdenied v-else :model=true></acessdenied>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import { required, maxLength, requiredIf } from "vuelidate/lib/validators"
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default ({
        mixins: [clickMixin],
        name: 'AddSubCategory',
        props: ['show', 'subCategory', 'type','categoryid'],
        components: {
            Loading
        },
        data: function () {

            return {
                loading: false,
                arabic: '',
                english: '',
                rendered: 0,
            }
        },
        validations: {
            subCategory:
            {
                name: {
                    maxLength: maxLength(250)
                },
                nameArabic: {
                    required: requiredIf((x) => {
                        if (x.name == '' || x.name == null)
                            return true;
                        return false;
                    }),
                    maxLength: maxLength(250)
                },
                categoryId: {
                    required
                },
                description: {
                    maxLength: maxLength(500)
                },
            }
        },
        methods: {
            close: function () {
                this.$emit('close');
            },

            SaveSubCategory: function () {
                var root = this;
                this.loading = true;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.post('/Product/SaveSubCategoryInformation', this.subCategory, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                    if (response.data.isSuccess == true) {

                        if (root.type != "Edit") {
                            root.$swal({
                                icon: 'success',
                                title: 'Saved Successfully!',
                                showConfirmButton: false,
                                timer: 1500,
                                timerProgressBar: true,
                            });

                            root.close();
                        }
                        else {
                            root.$swal({
                                title: "Update!",
                                icon: 'success',
                                text: "Update Successfully!",
                                type: 'success',
                                showConfirmButton: false,
                                timer: 1500,
                                timerProgressBar: true,
                            });
                            root.close();
                        }
                    }
                    else {
                        root.$swal({
                            title: "Error!",
                            icon: 'error',
                            text: "Your Sub Category Already Exist!",
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            showConfirmButton: false,
                            timer: 1500,
                            timerProgressBar: true,
                        });
                    }
                    })
                    .catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Something Went Wrong!',
                                text: error.response.data,

                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false
                    })
                    .finally(() => root.loading = false);
            },
            AutoIncrementCode: function () {
                var root = this;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https
                    .get('/Product/SubCategoryAutoGenerateCode', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                        if (response.data != null) {
                            root.subCategory.code = response.data;
                            root.rendered++
                        }
                    });
            }
        },


        mounted: function () {
            
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            if (this.subCategory.id == "00000000-0000-0000-0000-000000000000") {
                this.AutoIncrementCode();
            }
            if (this.categoryid != undefined) {
                this.subCategory.categoryId = this.categoryid;
            }
        }
    })

</script>