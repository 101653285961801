<template>
    <div v-bind:style="$i18n.locale == 'ar' ? languageChange('en') : languageChange('ar')" v-if="isValid('CanDraftExpense') || isValid('CanAddExpense') || isValid('CanEditExpense')  " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">

        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-12 ">
                        <div class="mt-2">
                            <h5 v-if="dailyExpense.id=='00000000-0000-0000-0000-000000000000'"> {{ $t('AddDailyExpense.ExpenseRecording') }}</h5>
                            <h5 v-else>{{ $t('AddDailyExpense.UpdateExpense') }}</h5>
                        </div>
                        <div class="row">

                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                                <label> {{ $t('AddDailyExpense.VoucherNo') }}: <span class="LabelColour"> *</span></label>
                                <div v-bind:class="{'has-danger' : $v.dailyExpense.voucherNo.$error}">
                                    <input :key="render" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" class="form-control" disabled v-model="dailyExpense.voucherNo" />
                                </div>
                                <span v-if="$v.dailyExpense.voucherNo.$error" class="error text-danger">
                                    <span v-if="!$v.dailyExpense.voucherNo.required">{{ $t('AddDailyExpense.voucherNoRequired') }}</span>
                                    <span v-if="!$v.dailyExpense.voucherNo.maxLength">{{ $t('AddDailyExpense.VoucherNoMaximum') }}</span>
                                </span>
                            </div>
                            <!--<div class="col-xs-12 col-sm-6 col-md-6 col-lg-4" v-if="IsExpenseAccount">
                            <label>
                                {{ $t('Bills') }}
                            </label>

                            <div class="form-group">

                                <BillsDropdown v-model="dailyExpense.billerAccountId" v-on:input="GetBilllerValue"  ref="BillerAccount" :values="dailyExpense.billerAccountId" ></BillsDropdown>

                            </div>
                        </div>-->
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4" v-if="IsExpenseAccount">
                                <label>
                                    {{ $t('AddDailyExpense.PaymentMode') }}:
                                    <span class="LabelColour"> *</span>
                                </label>

                                <div class="form-group">

                                    <multiselect v-if="($i18n.locale == 'en' ||isLeftToRight()) " :disabled="isTemporaryCashIssue" v-model="dailyExpense.paymentMode" v-on:input="GetAccount(dailyExpense.paymentMode)" :options="['Cash', 'Bank']" :show-labels="false" placeholder="Select Type">
                                    </multiselect>
                                    <multiselect v-else v-model="dailyExpense.paymentMode" :disabled="isTemporaryCashIssue" v-on:input="GetAccount(dailyExpense.paymentMode)" :options="[ 'السيولة النقدية', 'مصرف']" :show-labels="false" v-bind:placeholder="$t('AddDailyExpense.SelectOption')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    </multiselect>

                                </div>
                            </div>

                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4" v-if="IsExpenseAccount">
                                <div class="row">
                                    <div class="col-sm-12" v-if="dailyExpense.paymentMode=='Cash' || dailyExpense.paymentMode=='السيولة النقدية' " v-bind:key="randerAccount">
                                        <label>{{ $t('AddDailyExpense.Bank/CashAccount') }}: <span class="LabelColour"> *</span> <span v-if="IsExpenseAmount">{{runningBalance}}</span></label>
                                        <accountdropdown v-model="dailyExpense.accountId" :formName="'CashReceipt'" v-on:input="GetBankOpeningBalance(dailyExpense.accountId)" :disabled="isTemporaryCashIssue" />
                                    </div>
                                    <div class="col-sm-12" v-else>
                                        <label>{{ $t('AddDailyExpense.Bank/CashAccount') }}: <span class="LabelColour"> *</span></label>
                                        <accountdropdown v-model="dailyExpense.accountId" :formName="'BankReceipt'" v-bind:key="randerAccount"></accountdropdown>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                                <label>{{ $t('AddDailyExpense.SpentDate') }}: <span class="LabelColour"> *</span></label>
                                <div v-bind:class="{'has-danger' : $v.dailyExpense.date.$error}">
                                    <datepicker :key="render" v-model="$v.dailyExpense.date.$model"></datepicker>

                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 ">
                                <label class="text  font-weight-bolder ">{{ $t('AddDailyExpense.ReferenceNo')}}:</label>
                                <input class="form-control" v-model="dailyExpense.referenceNo" type="text" />

                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 " v-if="IsExpenseAccount">
                                <label class="text  font-weight-bolder ">{{ $t('AddDailyExpense.ExNameEn')}}:</label>
                                <input class="form-control" v-model="dailyExpense.name" type="text" />

                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 " v-if="IsExpenseAccount">
                                <label class="text  font-weight-bolder ">{{ $t('AddDailyExpense.TaxId')}}:</label>
                                <input class="form-control" v-model="dailyExpense.taxId" type="text" />

                            </div>


                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <adddailyexpenserow :balance="balance" :formName="formName" :dailyExpense="dailyExpense" :BillerRecord="BillerRecord" :dailyExpenseRows="dailyExpenseDetails" ref="dailyExpenseRef"
                                                    v-on:input="getupdatedailyExpenseRows"></adddailyexpenserow>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label>{{ $t('AddDailyExpense.ExpenseDescription') }}: </label>
                                <div v-bind:class="{'has-danger' : $v.dailyExpense.description.$error}">
                                    <textarea class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" autofocus="autofocus" v-model="dailyExpense.description" />
                                </div>
                                <span v-if="$v.dailyExpense.description.$error" class="error text-danger">
                                    <span v-if="!$v.dailyExpense.description.maxLength">{{ $t('AddDailyExpense.DescriptionMaximumlength') }}</span>
                                </span>
                            </div>
                            <!--<div class="col-lg-12 col-md-12 col-sm-12" v-if="isValid('CanUploadExpenseAttachment')">
                                <div class="accordion" role="tablist">
                                    <b-card no-body class="mb-1">
                                        <b-card-header header-tag="header" class="p-1" role="tab">
                                            <b-button block v-b-toggle.accordion-1 variant="primary">{{ $t('AddDailyExpense.Attachment') }}</b-button>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                                            <b-card-body>
                                                <import-attachment :purchase="dailyExpense" :show="attachments" @close="attachments = false" v-if="attachments" @billAttachments="billAttachments" :document="'DailyExpense'" />
                                                <div>
                                                    <div class="row">
                                                        <div class="col-md-12 text-right">
                                                            <a href="javascript:void(0)" class="btn btn-outline-primary " v-on:click="attachments=true"> Upload</a>
                                                        </div>
                                                    </div>
                                                    <div class=" table-responsive">
                                                        <table class="table ">
                                                            <thead class="m-0">
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>{{ $t('AddDailyExpense.Date') }} </th>
                                                                    <th>{{ $t('AddDailyExpense.Description') }} </th>
                                                                    <th>{{ $t('AddDailyExpense.Attachment') }}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(contact,index) in dailyExpense.expenseAttachment" v-bind:key="index">
                                                                    <td>
                                                                        {{index+1}}
                                                                    </td>
                                                                    <th>{{getDate(contact.date)}}</th>
                                                                    <th>{{contact.description}}</th>

                                                                    <td>
                                                                        <button class="btn btn-primary  btn-icon mr-2"
                                                                                v-if="contact.path != ''"
                                                                                v-on:click="DownloadAttachment(contact.path)">
                                                                            <i class="fa fa-download"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                            </div>-->

                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div v-if="dailyExpense.id == null || dailyExpense.id == '00000000-0000-0000-0000-000000000000'">
                                    <button class="btn btn-primary mr-2 float-left" v-on:click="Attachment()" v-if="isValid('CanUploadExpenseAttachment')">
                                        {{ $t('AddSaleOrder.Attachment') }}
                                    </button>

                                    <button class="btn btn-danger float-right mr-2" v-on:click="BackToList()">{{ $t('AddDailyExpense.Cancel') }}</button>
                                    <button class="btn btn-primary float-right mr-2" v-on:click="SaveDailyExpenseInformation('Approved')" v-bind:disabled="$v.dailyExpense.$invalid || (isTemporaryCashIssue? (temporaryCashIssue < expenseAmount):false)" v-if="isValid('CanAddExpense')"><i class="far fa-save"></i>  {{ $t('AddDailyExpense.SaveAndpost') }}</button>
                                    <button class="btn btn-primary float-right mr-2" v-on:click="SaveDailyExpenseInformation('Draft')" v-bind:disabled="$v.dailyExpense.$invalid" v-if="isValid('CanDraftExpense') && !isTemporaryCashIssue"><i class="far fa-save"></i>  {{ $t('AddDailyExpense.Save') }}</button>

                                </div>
                                <div v-else>
                                    <button class="btn btn-primary mr-2 float-left" v-on:click="Attachment()" v-if="isValid('CanUploadExpenseAttachment')">
                                        {{ $t('AddSaleOrder.Attachment') }}
                                    </button>

                                    <button class="btn btn-danger float-right mr-2" v-on:click="BackToList()">{{ $t('AddDailyExpense.Cancel') }}</button>
                                    <button class="btn btn-primary float-right mr-2" v-on:click="SaveDailyExpenseInformation('Approved')" v-if="isValid('CanEditExpense')" v-bind:disabled="$v.dailyExpense.$invalid"><i class="far fa-save"></i>  {{ $t('AddDailyExpense.Updateandpost') }}</button>
                                    <button class="btn btn-primary float-right mr-2" v-on:click="SaveDailyExpenseInformation('Draft')" v-if="isValid('CanDraftExpense') || isValid('Can Edit Expense as Draft')" v-bind:disabled="$v.dailyExpense.$invalid"><i class="far fa-save"></i>  {{ $t('AddDailyExpense.Update') }}</button>
                                    <!--<button class="btn btn-primary btn-round float-right mr-2" v-on:click="RejectionModel" v-if="isValid('Can Save Expense as Reject') || isValid('Can Edit Expense as Reject')" v-bind:disabled="$v.dailyExpense.$invalid"><i class="far fa-save"></i> {{ $t('DailyExpense.Reject') }}</button>-->

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <rejectionmodel :dailyExpense="dailyExpense"
                        :show="show"
                        v-if="show"
                        @close="show = false" />
        <bulk-attachment :attachmentList="dailyExpense.attachmentList" :show="isShow" v-if="isShow" @close="attachmentSave" />

        <!--<div class="col-lg-6 col-xs-12 col-sm-6 col-md-6 col-lg-4 ml-auto mr-auto" v-else>
        <div class="card p-3 text-center ">
            <h4 class="">{{ $t('FirstStartOperation') }}</h4>
            <router-link :to="'/dayStart'"><a href="javascript:void(0)" class="btn btn-outline-danger btn-round">{{ $t('Dashboard.DayStart') }}</a></router-link>
        </div>
    </div>-->
    </div>
    <div v-else> <acessdenied></acessdenied></div>

</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import moment from 'moment';
    import { required, maxLength, requiredIf } from 'vuelidate/lib/validators';
    import Multiselect from 'vue-multiselect'
    export default ({
        mixins: [clickMixin],
        props: ['formName'],

        components: {
            Multiselect,
        },
        data: function () {
            return {
                expenseAmount: 0,
                temporaryCashIssue: 0,
                isTemporaryCashIssue: false,
                attachment: false,
                date: false,
                attachments: false,
                dailyExpenseDetails: [],
                BillerRecord: [],
                render: 0,
                language: 'Nothing',
                dailyExpense: {
                    id: '00000000-0000-0000-0000-000000000000',
                    voucherNo: '',
                    accountId: '',
                    date: '',
                    referenceNo: '',
                    name: '',
                    taxId: '',
                    description: '',
                    billerAccountId: '',
                    isDraft: false,
                    IsExpenseAccount: false,
                    reason: '',
                    counterId: '00000000-0000-0000-0000-000000000000',
                    isDayStart: false,
                    dailyExpenseDetails: [],
                    expenseAttachment: [],
                    attachmentList: [],
                    paymentMode: '',
                    temporaryCashIssueId: '',
                },
                CompanyID: '',
                UserID: '',
                employeeId: '',
                isDayAlreadyStart: false,
                IsExpenseAccount: false,
                IsExpenseAmount: false,
                show: false,
                isShow: false,
                lengthCount: 0,
                runningBalance: 0,
                balance: 0,
                randerAccount: 0,
                summary: 0,

            }
        },
        validations: {
            dailyExpense:
            {
                voucherNo:
                {
                    required,
                    maxLength: maxLength(30)
                },
                date:
                {
                    required,
                },
                description:
                {
                    maxLength: maxLength(200)
                },
                dailyExpenseDetails:
                {
                    required,
                },
                paymentMode: {
                    required: requiredIf((x) => {
                        if (x.IsExpenseAccount == true)
                            return true;
                        return false;
                    }),
                },
                accountId: {
                    required: requiredIf((x) => {
                        if (x.IsExpenseAccount == true)
                            return true;
                        return false;
                    }),
                },


            }
        },

        methods: {
            Attachment: function () {
                this.isShow = true;
            },

            attachmentSave: function (attachment) {
                this.dailyExpense.attachmentList = attachment;
                this.isShow = false;
            },

            GetBankOpeningBalance: function (id) {
                if (this.IsExpenseAmount) {
                    if (this.dailyExpense.paymentMode == "السيولة النقدية" || this.dailyExpense.paymentMode == "Cash") {
                        var token = '';
                        if (this.$session.exists()) {
                            token = localStorage.getItem('token');
                        }
                        var root = this
                        this.$https.get('/Contact/GetCustomerRunningBalance?id=' + id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                            if (response.data != null) {
                                root.balance = response.data;
                                root.runningBalance = parseFloat(response.data) > 0 ? 'Dr ' + parseFloat(response.data) * +1 : 'Cr ' + parseFloat(response.data) * (-1);
                            }
                        });
                    }

                }


            },
            GetBilllerValue: function () {

                this.BillerRecord = this.$refs.BillerAccount.GetAmountOfSelected();
            },
            DownloadAttachment(path) {

                var root = this;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                var ext = path.split('.')[1];
                root.$https.get('/Contact/DownloadFile?filePath=' + path, { headers: { "Authorization": `Bearer ${token}` }, responseType: 'blob' })
                    .then(function (response) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'file.' + ext);
                        document.body.appendChild(link);
                        link.click();
                    });
            },
            GetBillerAccount: function (date) {


                return moment(date).format('LLL');

            },
            getDate: function (date) {


                return moment(date).format('LLL');

            },
            billAttachments: function (x) {

                var root = this;
                this.attachments = false;
                this.attachment = false;

                if (x != undefined && x != null && x != '') {
                    this.dailyExpense.expenseAttachment.push({
                        path: x.path,
                        date: x.date,
                        description: x.description
                    })
                }
                this.$swal.fire({
                    title: root.$t('AddDailyExpense.SavedSuccessfully'),
                    text: root.$t('AddDailyExpense.Saved'),
                    type: 'success',
                    confirmButtonClass: "btn btn-success",
                    buttonStyling: false,
                    icon: 'success',
                    timer: 1500,
                    timerProgressBar: true,

                });
            },
            GetAccount: function (x) {


                if (x == 'السيولة النقدية' || x == 'Bank') {
                    this.randerAccount++;

                }
                else if (x == 'مصرف' || x == 'Cash') {
                    this.randerAccount++;
                }

            },
            RejectionModel: function () {

                this.dailyExpense.approvalStatus = 'Rejected';
                this.show = !this.show;

            },
            languageChange: function (lan) {
                if (this.language == lan) {
                    if (this.dailyExpense.id == '00000000-0000-0000-0000-000000000000') {

                        var getLocale = this.$i18n.locale;
                        this.language = getLocale;

                        this.$router.go('/adddailyexpense');
                    }
                    else {

                        this.$swal({
                            title: this.$t('AddDailyExpense.Error'),
                            text: this.$t('AddDailyExpense.ChangeLanguageError'),
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            icon: 'error',
                            timer: 4000,
                            timerProgressBar: true,
                        });
                    }
                }


            },
            getupdatedailyExpenseRows: function (items, amount) {
                
                this.dailyExpenseDetails = items;
                this.dailyExpense.dailyExpenseDetails = items;
                this.expenseAmount = amount;
            },
            AutoIncrementVoucherNo: function () {
                var root = this;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https
                    .get('/Company/AutoGenerateCode', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                        if (response.data != null) {
                            root.dailyExpense.voucherNo = response.data;
                        }
                    });
            },
            BackToList: function () {
                if (this.isValid('CanViewExpense') || this.isValid('CanDraftExpense')) {
                    this.$router.push({
                        path: '/dailyexpense',
                        query: {
                            data: 'AddDailyExpense',
                            formName: this.formName
                        }
                    })
                }
                else {
                    this.$router.go();
                }
                
            },
            SaveDailyExpenseInformation: function (value) {
                
                var root = this;
                this.dailyExpense.approvalStatus = value;
                if (this.IsExpenseAmount) {
                    if (this.dailyExpense.paymentMode == "السيولة النقدية" || this.dailyExpense.paymentMode == "Cash") {
                        var totalAmount = this.$refs.dailyExpenseRef.GetAmountOfSelected();
                        if (totalAmount > this.balance) {
                            this.$swal.fire(
                                {
                                    icon: 'error',
                                    title: "Expense Exceed",
                                    text: "Your Expense Exceed Your Cash in Hand",
                                    showConfirmButton: false,
                                    timer: 3000,
                                    timerProgressBar: true,

                                });
                            return;
                        }

                    }
                }

                var url = '/Company/SaveDailyExpense';
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                if (this.dailyExpense.paymentMode == "") {
                    this.dailyExpense.paymentMode = 'Default';
                }
                if (this.dailyExpense.paymentMode == "السيولة النقدية") {
                    this.dailyExpense.paymentMode = 'Cash';
                }
                if (this.dailyExpense.paymentMode == "مصرف") {
                    this.dailyExpense.paymentMode = 'Bank';
                }

                if (!this.date) {
                    root.dailyExpense.date = root.dailyExpense.date + " " + moment().format("hh:mm A");

                }
                localStorage.setItem('active', value);


                var counterId = localStorage.getItem('CounterId');
                var dayStart = localStorage.getItem('IsDayStart');
                root.dailyExpense.counterId = counterId == null ? '00000000-0000-0000-0000-000000000000' : counterId;
                root.dailyExpense.isDayStart = dayStart == "true" ? true : false;

                var prd = root.dailyExpense.dailyExpenseDetails.findIndex(x => x.amount == 0);
                if (prd >= 0) {
                    root.dailyExpense.dailyExpenseDetails.splice(prd, 1)
                }

                root.$https
                    .post(url, root.dailyExpense, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {
                        root.info = response.data.bpi
                        this.$swal.fire({
                            title: root.$t('AddDailyExpense.SavedSuccessfully'),
                            text: root.$t('AddDailyExpense.Saved'),
                            type: 'success',
                            confirmButtonClass: "btn btn-success",
                            buttonStyling: false,
                            icon: 'success',
                            timer: 1500,
                            timerProgressBar: true,

                        });
                        if (this.isValid('CanViewExpense') || this.isValid('CanDraftExpense')) {
                            if (root.isTemporaryCashIssue) {
                                this.$router.push({
                                    path: '/TemporaryCashIssue',
                                })
                            }
                            else {
                                this.$router.push({
                                    path: '/dailyexpense',
                                    query: {
                                        data: 'AddDailyExpense',
                                        formName: root.formName
                                    }
                                })
                            }                            
                        }
                        else {
                            this.$router.go();
                        }

                    })
                    .catch(error => {
                        console.log(error)
                        this.$swal.fire(
                            {

                                icon: 'error',
                                title: error.response.data,
                                text: error.response.data,
                            });
                        root.date = true;
                        root.errored = true
                    })
                    .finally(() => root.loading = false)
            },
            UpdateDailyExpenseInformation: function (value) {

                var root = this;
                //if (this.lengthCount != root.dailyExpense.dailyExpenseDetails.length) {
                //    root.dailyExpense.dailyExpenseDetails.pop();
                //}
                root.dailyExpense.approvalStatus = value;
                var url = '/Company/SaveDailyExpense';
                var token = '';

                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                if (this.dailyExpense.paymentMode == "") {
                    this.dailyExpense.paymentMode = 'Default';
                }
                root.dailyExpense.date = root.dailyExpense.date + " " + moment().format("hh:mm A");
                var counterId = localStorage.getItem('CounterId');
                var dayStart = localStorage.getItem('IsDayStart');
                root.dailyExpense.counterId = counterId == null ? '00000000-0000-0000-0000-000000000000' : counterId;
                root.dailyExpense.isDayStart = dayStart == "true" ? true : false;
                var prd = root.dailyExpense.dailyExpenseDetails.findIndex(x => x.amount == 0);
                if (prd >= 0) {
                    root.dailyExpense.dailyExpenseDetails.splice(prd, 1)
                }
                root.$https
                    .post(url, root.dailyExpense, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {
                        root.info = response.data.bpi
                        this.$swal.fire({
                            title: root.$t('AddDailyExpense.UpdateSuccessfully'),
                            text: root.$t('AddDailyExpense.Updated'),
                            type: 'success',
                            confirmButtonClass: "btn btn-success",
                            buttonStyling: false,
                            icon: 'success',
                            timer: 1500,
                            timerProgressBar: true,

                        });
                        if (this.isValid('CanViewExpense') || this.isValid('CanDraftExpense')) {
                            this.$router.push({
                                path: '/dailyexpense',
                                query: {
                                    data: 'AddDailyExpense',
                                    formName: root.formName
                                }
                            })
                        }
                        else {
                            this.$router.go();
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        this.$swal.fire(
                            {
                                icon: 'error',
                                title: root.$t('AddDailyExpense.Error'),
                                text: root.$t('AddDailyExpense.SomethingWrong'),
                                showConfirmButton: false,
                                timer: 1000,
                                timerProgressBar: true,

                            });
                        root.errored = true
                    })
                    .finally(() => root.loading = false)
            },

        },
        created: function () {
            
            var root = this;
            this.language = this.$i18n.locale;
            var IsDayStart = localStorage.getItem('DayStart');
            var IsDayStartOn = localStorage.getItem('IsDayStart');
          
                if (this.formName == 'dailyexpense') {

                    this.IsExpenseAccount = false;
                }
                else {
                    this.IsExpenseAccount = localStorage.getItem('IsExpenseAccount') == 'true' ? true : false;
                }
            this.IsExpenseAmount = localStorage.getItem('expenseAmount') == 'true' ? true : false;


            //if (this.$route.query.data != undefined) {
            //    this.lengthCount = this.$route.query.data.dailyExpenseDetails.length;
            //}

            if (IsDayStart != 'true') {
                this.isDayAlreadyStart = true;
                if (this.$route.query.data == undefined) {
                    this.AutoIncrementVoucherNo();
                    this.dailyExpense.date = moment().format('llll');
                }
                if (this.$route.query.data != undefined) {

                    this.dailyExpense = this.$route.query.data;
                    if (this.formName == 'dailyexpense') {

                        this.dailyExpense.IsExpenseAccount = false;
                    }
                    else {
                        this.dailyExpense.IsExpenseAccount = this.IsExpenseAccount;
                    }

                    //this.dailyExpense.dailyExpenseDetails = this.$route.query.data.dailyExpenseDetails;

                }
                root.render++;
            }
            else {
                this.CompanyID = localStorage.getItem('CompanyID');
                this.UserID = localStorage.getItem('UserID');
                this.employeeId = localStorage.getItem('EmployeeId');
                if (IsDayStartOn == 'true') {

                    this.isDayAlreadyStart = true;
                    if (root.$route.query.data == undefined) {
                        root.AutoIncrementVoucherNo();
                        root.dailyExpense.date = moment().format('llll');
                        if (this.formName == 'dailyexpense') {

                            this.dailyExpense.IsExpenseAccount = false;
                        }
                        else {
                            this.dailyExpense.IsExpenseAccount = this.IsExpenseAccount;
                        }                    }

                    if (root.$route.query.data != undefined) {
                        if (this.formName == 'dailyexpense') {

                            this.dailyExpense.IsExpenseAccount = false;
                        }
                        else {
                            this.dailyExpense.IsExpenseAccount = this.IsExpenseAccount;
                        }
                        /*Temporary Cash Issue*/
                        if (root.$route.query.data.isTemporaryCashIssue) {
                            root.AutoIncrementVoucherNo();
                            root.dailyExpense.date = moment().format('llll');
                            root.isTemporaryCashIssue = root.$route.query.data.isTemporaryCashIssue;
                            root.dailyExpense.temporaryCashIssueId = root.$route.query.data.id;
                            root.temporaryCashIssue = root.$route.query.data.amount - (root.$route.query.data.returnAmount + root.$route.query.data.voucherAmount);

                            if (this.$i18n.locale == 'ar') {
                                this.dailyExpense.paymentMode = 'السيولة النقدية';
                            }
                            else {
                                this.dailyExpense.paymentMode = 'Cash';
                            }
                            root.dailyExpense.accountId = root.$route.query.data.temporaryCashAccountId;
                            root.GetBankOpeningBalance(root.$route.query.data.temporaryCashAccountId);
                        }
                        else {
                            root.dailyExpense = root.$route.query.data;                          

                            if (this.$i18n.locale == 'ar') {
                                if (this.dailyExpense.paymentMode == 0) {
                                    this.dailyExpense.paymentMode = 'السيولة النقدية';
                                }
                                if (this.dailyExpense.paymentMode == 1) {
                                    this.dailyExpense.paymentMode = 'مصرف';
                                }
                            }
                            if ((this.$i18n.locale == 'en' )) {

                                if (this.dailyExpense.paymentMode == 0) {
                                    this.dailyExpense.paymentMode = 'Cash';
                                }
                                if (this.dailyExpense.paymentMode == 1) {
                                    this.dailyExpense.paymentMode = 'Bank';
                                }
                            }
                            root.GetBankOpeningBalance(root.dailyExpense.accountId);
                            root.dailyExpense.dailyExpenseDetails = root.$route.query.data.dailyExpenseDetails;
                        }                        
                    }
                    root.render++;
                }
                else {
                    if (root.$route.query.data == undefined) {
                        root.AutoIncrementVoucherNo();
                        root.dailyExpense.date = moment().format('lll');
                        if (this.formName == 'dailyexpense') {

                            this.dailyExpense.IsExpenseAccount = false;
                        }
                        else {
                            this.dailyExpense.IsExpenseAccount = this.IsExpenseAccount;
                        }
                    }
                    if (root.$route.query.data != undefined) {

                        root.dailyExpense = root.$route.query.data;
                        if (this.formName == 'dailyexpense') {

                            this.dailyExpense.IsExpenseAccount = false;
                        }
                        else {
                            this.dailyExpense.IsExpenseAccount = this.IsExpenseAccount;
                        }
                        if (this.$i18n.locale == 'ar') {


                            if (this.dailyExpense.paymentMode == 0) {
                                this.dailyExpense.paymentMode = 'السيولة النقدية';
                            }
                            if (this.dailyExpense.paymentMode == 1) {
                                this.dailyExpense.paymentMode = 'مصرف';
                            }
                        }
                        if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {

                            if (this.dailyExpense.paymentMode == 0) {
                                this.dailyExpense.paymentMode = 'Cash';
                            }
                            if (this.dailyExpense.paymentMode == 1) {
                                this.dailyExpense.paymentMode = 'Bank';
                            }

                        }
                        root.GetBankOpeningBalance(root.dailyExpense.accountId);

                        root.dailyExpense.dailyExpenseDetails = root.$route.query.data.dailyExpenseDetails;
                    }
                    root.render++;
                }
            }

            

            this.$emit('input', this.$route.name);
        },

        mounted: function () {



        }
    })

</script>