<template>
    <modal :show="show" >

        <div style="margin-bottom:0px" class="card">
            <div class="card-body">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="modal-header" v-if="isFlushData">
                            <h5 class="modal-title DayHeading" id="myModalLabel"> {{ $t('TheSupervisorLogin.SuperAdminLogin') }}</h5>
                        </div>
                        <div class="modal-header DayHeading" v-else>
                            <h5 class="modal-title" id="myModalLabel"> {{ $t('TheSupervisorLogin.SupervisorLogin') }}</h5> 
                        </div>
                        
                        
                        <div class="card-body">
                            <div class="row " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">

                                <div class="form-group has-label col-sm-12 ">
                                    <label class="text  font-weight-bolder"> {{ $t('TheSupervisorLogin.User') }}:<span class="LabelColour"> *</span> </label>
                                    <input class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.login.email.$model" type="text" />
                                </div>
                                <div class="form-group has-label col-sm-12 ">
                                    <label class="text  font-weight-bolder"> {{ $t('TheSupervisorLogin.Password') }}:<span class="LabelColour"> *</span> </label>
                                    <input class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.login.password.$model" type="password" />
                                </div>

                            </div>
                            <div class="modal-footer justify-content-right">

                                <button type="button" class="btn btn-primary  " v-on:click="SupervisorLogin()"> {{ $t('TheSupervisorLogin.Login1') }}</button>
                                <button type="button" class="btn btn-outline-danger  mr-3 " v-on:click="close()">{{ $t('TheSupervisorLogin.Cancel') }}</button>

                            </div>
                        </div>

                        
                    </div>
                </div>
            </div>
        </div>

    </modal>
    
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import 'vue-loading-overlay/dist/vue-loading.css';
   

    import { required } from "vuelidate/lib/validators"
    export default {
        mixins: [clickMixin],
        
        props: ['show', 'isFlushData','isReset'],
        data: function () {
            return {
                login: {
                    email: '',
                    password: '',
                    isFlushData:false,
                },

            }
        },
        validations: {
            login: {
                email:
                {
                    required
                },
                password:
                {
                    required
                }
            }
        },
        methods: {
           
            close: function (x) {
                if (x) {
                    this.$emit('close', true);

                }
                else {
                    this.$emit('close', false);

                }
            },
            SupervisorLogin: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                if(this.isFlushData){
                    this.login.isFlushData = true
                }
               
                if (root.isReset) {
                    this.$swal({
                        title: "Are you sure?",
                        text: "You will not be able to recover this!",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Yes, delete it!",
                        closeOnConfirm: false,
                        closeOnCancel: false
                    }).then(function (result) {
                        
                        if (result.isConfirmed) {
                            root.$https.post('/Product/SupervisorLogin', root.login, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                                
                                if (response.data != null) {
                                    if (response.data === 'Not Valid Credential') {
                                        root.$swal({
                                            title: 'error',
                                            text: 'Please enter valid Credential',
                                            type: 'error',
                                            icon: 'error',
                                            showConfirmButton: false,
                                            timer: 2500,
                                            timerProgressBar: true,
                                        });
                                        root.close(false);
                                    }
                                    else if (response.data === 'No Permission') {
                                        root.$swal({
                                            title: 'error',
                                            text: 'You do not have permission. Please enter Supervisor credential',
                                            type: 'error',
                                            icon: 'error',
                                            showConfirmButton: false,
                                            timer: 2500,
                                            timerProgressBar: true,
                                        });
                                        root.close(false);

                                    }
                                    else {
                                        if (!root.isFlushData) {
                                            root.$swal({
                                                title: 'success',
                                                text: "Login Successfully",
                                                type: 'success',
                                                icon: 'success',
                                                showConfirmButton: false,
                                                timer: 1500,
                                                timerProgressBar: true,
                                            });
                                            debugger
                                            localStorage.setItem('IsSupervisor', true);
                                        }
                                        localStorage.setItem('SupervisorId', response.data);
                                        localStorage.setItem('SupervisorUserName', root.login.email);
                                        localStorage.setItem('SupervisorPassword', root.login.password);
                                        root.close(true);
                                    }

                                }

                            }).catch(error => {
                                console.log(error)
                                root.$swal.fire(
                                    {
                                        icon: 'error',
                                        title: 'Something Went Wrong!',
                                        text: error.response.data,
                                        showConfirmButton: false,
                                        timer: 5000,
                                        timerProgressBar: true,
                                    });

                            });
                        }
                    });
                }
                else {
                    root.$https.post('/Product/SupervisorLogin', this.login, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                        if (response.data != null) {
                            if (response.data === 'Not Valid Credential') {
                                root.$swal({
                                    title: 'error',
                                    text: 'Please enter valid Credential',
                                    type: 'error',
                                    icon: 'error',
                                    showConfirmButton: false,
                                    timer: 2500,
                                    timerProgressBar: true,
                                });
                                //root.close(false);
                            }
                            else if (response.data === 'No Permission') {
                                root.$swal({
                                    title: 'error',
                                    text: 'You do not have permission. Please enter Supervisor credential',
                                    type: 'error',
                                    icon: 'error',
                                    showConfirmButton: false,
                                    timer: 2500,
                                    timerProgressBar: true,
                                });
                                //root.close(false);

                            }
                            else {
                                if (!root.isFlushData) {
                                    root.$swal({
                                        title: 'success',
                                        text: "Login Successfully",
                                        type: 'success',
                                        icon: 'success',
                                        showConfirmButton: false,
                                        timer: 1500,
                                        timerProgressBar: true,
                                    });
                                    debugger
                                    localStorage.setItem('IsSupervisor', true);
                                }
                                localStorage.setItem('SupervisorId', response.data);
                                localStorage.setItem('SupervisorUserName', root.login.email);
                                localStorage.setItem('SupervisorPassword', root.login.password);
                                root.close(true);
                            }

                        }

                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Something Went Wrong!',
                                text: error.response.data,
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                    });
                }
                

            },
        },
        mounted: function () {
            
        }
    }
</script>
