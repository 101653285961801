<template>
    <div class="row" v-if="isValid('CanViewUserRole')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
        <div class="col-lg-12 col-sm-12 ml-auto mr-auto">
            <div class="card ">
                <div class="BorderBottom ml-4 mr-4 mt-3 mb-3">
                    <span class=" DayHeading">{{ $t('Roles.Roles') }}</span>
                </div>
               
            
                <div class="card-body">
                    <div class="col-lg-12">
                        <div class="row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                <div class="form-group">
                                    <label>{{ $t('Roles.SearchByName') }}</label>
                                    <div>
                                        <input type="text" class="form-control search_input" v-model="searchQuery" name="search" id="search" :placeholder="$t('Roles.Search')" />
                                        <span class="fas fa-search search_icon"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'" style="margin-top:18px;">
                                <a v-if="isValid('CanAddUserRole')" href="javascript:void(0)" class="btn btn-primary" v-on:click="openmodel"><i class="fa fa-plus"></i>  {{ $t('Roles.AddNew') }}</a>
                                <router-link :to="'/UserSetup'">
                                    <a href="javascript:void(0)" class="btn btn-outline-danger ">  {{ $t('Roles.Close') }} </a>
                                </router-link>
                            </div>

                        </div>
                       
                        <div class="mt-2">
                            <div>
                                <table class="table  table-striped table-hover table_list_bg ">
                                    <thead class="">
                                        <tr>
                                            <th>#</th>
                                            <th v-if="english=='true'">
                                                {{ $t('Roles.NameEn') |englishLanguage}}
                                            </th>
                                            <th v-if="isOtherLang()">
                                                {{ $t('Roles.NameAr') |arabicLanguage}}

                                            </th>
                                            <th>
                                                {{ $t('Roles.NormalizedName') }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(roles,index) in resultQuery" v-bind:key="roles.id">
                                            <td>
                                                {{index+1}}
                                            </td>

                                            <td v-if="english=='true'">
                                                <strong v-if="isValid('CanEditUserRole')">
                                                    <a href="javascript:void(0)" v-on:click="EditRoles(roles.id)">{{roles.name == 'Sales Man' ? 'Salesman' : roles.name}}</a>
                                                </strong>
                                                <strong v-else>
                                                    {{roles.name == 'Sales Man' ? 'Salesman' : roles.name}}
                                                </strong>
                                            </td>
                                            <td v-if="isOtherLang()">
                                                <strong v-if="isValid('CanEditUserRole')">
                                                    <a href="javascript:void(0)" v-on:click="EditRoles(roles.id)">{{roles.nameArabic}}</a>
                                                </strong>
                                                <strong v-else>
                                                    {{roles.nameArabic}}
                                                </strong>
                                            </td>
                                            <td>
                                                {{roles.normalizedName== 'SALES MAN' ? 'SALESMAN' : roles.normalizedName}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <addRoles :roles="newRoles"
                  :show="show"
                  v-if="show"
                  @close="show = false"
                  :type="type" />
    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>

<script>
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        data: function () {
           
            return {
                arabic: '',
                english: '',
                searchQuery: '',
                show: false,
                roleslist: [],
                newRoles: {
                    id: '00000000-0000-0000-0000-000000000000',
                    name: '',
                    nameArabic: '',
                    normalizedName: '',
                    isActive: true
                },
                type: '',
            }
        },
        computed: {
            resultQuery: function () {
                var root = this;
                if (this.searchQuery) {
                    return this.roleslist.filter((city) => {
                        return root.searchQuery.toLowerCase().split(' ').every(v => city.name.toLowerCase().includes(v))
                    })
                } else {
                    return root.roleslist;
                }
            },
        },

        methods: {
            openmodel: function () {
                this.newRoles = {
                    id: '00000000-0000-0000-0000-000000000000',
                    name: '',
                    nameArabic: '',
                    description: '',
                    isActive: true
                }
                this.show = !this.show;
                this.type = "Add";
            },
            GetRolesData: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Company/NobleRolesList?isActive=true', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                    if (response.data != null) {
                        root.$store.dispatch('GetRoleList', response.data.nobleRoleModel);
                        root.roleslist = response.data.nobleRoleModel;
                    }
                });
            },
            EditRoles: function (Id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Company/NobleRolesDetail?Id=' + Id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data) {
                            root.newRoles.id = response.data.id;
                            root.newRoles.name = response.data.name;
                            root.newRoles.nameArabic = response.data.nameArabic;
                            root.newRoles.normalizedName = response.data.normalizedName;
                            root.newRoles.isActive = response.data.isActive;
                            root.show = !root.show;
                            root.type = "Edit"
                        } else {
                            console.log("error: something wrong from db.");
                        }
                    },
                    function (error) {
                        this.loading = false;
                        console.log(error);
                    });
            }
        },
        created: function () {
            this.$emit('input', this.$route.name);
        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.GetRolesData();
        }
    }
</script>