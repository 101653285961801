<template>
    <modal :show="show" :modalLarge="true">
        <div style="margin-bottom:0px" class="card" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <div class="card-body">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">
                        <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                            <div class="row">
                                <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8">
                                    <div>
                                        <span class="Heading1">{{ $t('LoanRecoveryPayment.LoanRecoveryPayment') }}</span> <span class="Heading1">-{{loanDetail.description}}</span>
                                    </div>
                                    <div>
                                        <span class="Heading2">{{loanDetail.employeeName}}</span>
                                    </div>
                                </div>

                                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 text-right">
                                    <div style="width:125px;height:56px;color:white;background-color:forestgreen;text-align:center;align-self:center;">
                                        <span class="SpanColor">{{ $t('LoanRecoveryPayment.LoanBalance') }}</span>
                                        <p class="SpanColor">
                                            {{currency}} : {{loanDetail.remainingLoan}}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <hr style="background-color:blue" />
                            <div class="row ">
                                <div class="form-group has-label col-sm-6" :key="dateRender">
                                    <label>{{ $t('LoanRecoveryPayment.PaymentDate') }} :<span class="LabelColour"> *</span></label>
                                    <datepicker v-model="loanRecovery.paymentDate" />
                                    <span style="color:#777">{{ $t('LoanRecoveryPayment.LoanDate') }} - {{loanDetail.loanDate}}</span>
                                </div>
                                <div class="form-group has-label col-sm-6">
                                    <label class="text  font-weight-bolder">{{ $t('LoanRecoveryPayment.Amount') }} : <span class="LabelColour"> *</span></label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? 'padding: 5px 15px 5px 5px;' : 'padding: 5px 5px 5px 15px;' ">{{currency}}</span>
                                        </div>
                                        <input type="number" @focus="$event.target.select()" class="form-control amount_field" v-model="loanRecovery.amount" />
                                    </div>
                                </div>

                                <div class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.loanRecovery.comments.$error}">
                                    <label class="text  font-weight-bolder">{{ $t('LoanRecoveryPayment.Comments') }} :<span class="LabelColour"> *</span> </label>
                                    <textarea class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.loanRecovery.comments.$model" type="text" />
                                </div>
                            </div>
                        </div>

                        <div v-if="!loading">
                            <div class="modal-footer justify-content-right">
                                <button type="button" class="btn btn-primary  " v-on:click="SaveLoanRecovery" v-bind:disabled="$v.loanRecovery.$invalid"> {{ $t('LoanRecoveryPayment.Save') }}</button>
                                <button type="button" class="btn btn-danger  mr-3 " v-on:click="close(false)">{{ $t('LoanRecoveryPayment.Cancel') }}</button>
                            </div>
                        </div>
                        <div v-else>
                            <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import 'vue-loading-overlay/dist/vue-loading.css';
    import { required, minValue } from "vuelidate/lib/validators"
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import moment from 'moment';

    export default {
        mixins: [clickMixin],
        props: ['show', 'loanRecovery', 'loanDetail'],
        components: {
            Loading
        },
        data: function () {
            return {
                currency: '',
                arabic: '',
                english: '',
                render: 0,
                dateRender: 0,
                loading: false,
            }
        },
        validations: {
            loanRecovery: {
                paymentDate: {
                    required
                },

                amount: {
                    required,
                    minValue: minValue(1),

                },


                comments: {
                    required

                }
            }
        },
        methods: {
            close: function (x) {
                
                if (x == true)
                    this.$emit('close', x);
                else {
                    this.$emit('close', false);
                }

            },
            SaveLoanRecovery: function () {
                var root = this;
                this.loading = true;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                if (this.loanRecovery.amount > this.loanDetail.remainingLoan) {
                    root.$swal({
                        title: "Error!",
                        text: "Amount Increase Loan Amount",
                        type: 'error',
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 1500,
                        timerProgressBar: true,
                    });
                    root.loading = false;
                    return;
                }
                this.loanRecovery.remainingLoan = parseFloat(this.loanDetail.remainingLoan) - parseFloat(this.loanRecovery.amount);
                this.$https.post('/Payroll/SaveLoanRecovery', this.loanRecovery, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data.isSuccess == true) {
                            if (root.type != "Edit") {

                                root.$swal({
                                    title: "Saved!",
                                    text: "Saved Successfully!",
                                    type: 'success',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 1500,
                                    timerProgressBar: true,
                                });

                                root.close(true);
                            }
                            else {

                                root.$swal({
                                    title: "Update!",
                                    text: "Update Successfully!",
                                    type: 'success',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 1500,
                                    timerProgressBar: true,
                                });
                                root.close(true);

                            }
                        }
                        else {
                            root.$swal({
                                title: "Error!",
                                text: "Your LoanRecovery Name  Already Exist!",
                                type: 'error',
                                icon: 'error',
                                showConfirmButton: false,
                                timer: 1500,
                                timerProgressBar: true,
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Something Went Wrong!',
                                text: error.response.data,

                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false
                    })
                    .finally(() => root.loading = false);
            }
        },
        mounted: function () {
            if (this.loanDetail.loanType == 1) {
                this.loanRecovery.amount = this.loanDetail.installmentBaseSalary;
            }
            this.loanRecovery.paymentDate = moment().format('llll');
            this.dateRender++;


            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.currency = localStorage.getItem('currency');


        }
    }
</script>
<style scoped>
    .input-group-append .input-group-text, .input-group-prepend .input-group-text {
        background-color: #e3ebf1;
        border: 1px solid #e3ebf1;
        color: #000000;
    }

    .input-group .form-control {
        border-left: 1px solid #e3ebf1;
    }

        .input-group .form-control:focus {
            border-left: 1px solid #3178F6;
        }

    .input-group-text {
        border-radius: 0;
    }

    .Heading1 {
        font-size: 27px !important;
        font-style: normal;
        font-weight: 600;
        color: #3178F6;
    }

    .Heading2 {
        font-size: 20px !important;
        font-style: normal;
        color: black;
    }

    .SpanColor {
        font-size: 15px !important;
        font-style: normal;
        font-weight: 600;
    }
</style>


