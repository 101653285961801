<template>
    <div class="row" >
        <div class="col-lg-10 col-sm-12 ml-auto mr-auto" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">

            <div class="card " >
                <div class="BorderBottom ml-2 mr-2 mt-3 mb-3">
                    <span class=" DayHeading"  style="padding:0 10px !important">About Us</span>
                </div>
                <div class="card-body " style="padding:0 15px !important">
                    <div class="row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <div class="form-group">
                                <h4 style="padding:0; margin:0;">License Group: </h4>
                                <span>{{aboutUsDetail.groupName}}</span>

                            </div>
                        </div>

                    </div>
                    <hr style="background-color: red;" />
                    <div class="row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                        <div class="col-12">
                            <div class="form-group">
                                <h4 style="padding:0; margin:0;">Module List: </h4>
                            </div>
                        </div>
                        <div class="col-4" v-for="(module,index) in aboutUsDetail.moduleList" :key="index">
                            <div class="form-group">
                                <p style="padding: 0; margin: 0; font-size: 16px !important; font-weight: 500;">{{module}}</p>
                            </div>
                        </div>

                    </div>
                    <hr style="background-color: red;" />
                    <div class="row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                        <div class="col-12">
                            <div class="form-group">
                                <h4 style="padding:0; margin:0;">License Type: <span style="font-size:16px !important; font-weight:500;">{{aboutUsDetail.licenseType}}</span> </h4>

                            </div>
                        </div>
                        <div class="col-4" v-if="!aboutUsDetail.isTechnicalSupport && aboutUsDetail.licenseType != 'Unlimited'">
                            <div class="form-group">
                                <p style="padding: 0; margin: 0; font-size: 18px !important; font-weight:500;">From Date:<span style="font-size:14px !important; ">{{aboutUsDetail.fromDate}}</span> </p>
                            </div>
                        </div>
                        <div class="col-4" v-else-if="!aboutUsDetail.isTechnicalSupport && aboutUsDetail.licenseType === 'Unlimited' && !aboutUsDetail.isActive">
                            <div class="form-group">
                                <p style="padding: 0; margin: 0; font-size: 18px !important; font-weight:500;">From Date:<span style="font-size:14px !important; ">{{aboutUsDetail.fromDate}}</span> </p>
                            </div>
                        </div>
                        <div class="col-4" v-if="!aboutUsDetail.isTechnicalSupport  && aboutUsDetail.licenseType != 'Unlimited'">
                            <div class="form-group">
                                <p style="padding: 0; margin: 0; font-size: 18px !important; font-weight:500;">To Date:<span style="font-size:14px !important; ">{{aboutUsDetail.toDate}}</span> </p>
                            </div>
                        </div>
                        <div class="col-4" v-if="!aboutUsDetail.isTechnicalSupport  && aboutUsDetail.licenseType === 'Unlimited' && !aboutUsDetail.isActive">
                            <div class="form-group">
                                <p style="padding: 0; margin: 0; font-size: 18px !important; font-weight:500;">To Date:<span style="font-size:14px !important; ">{{aboutUsDetail.toDate}}</span> </p>
                            </div>
                        </div>

                        <div class="col-4">
                            <div class="form-group" v-if="aboutUsDetail.licenseType == 'Subscription' && aboutUsDetail.isActive">
                                <p style="padding: 0; margin: 0; font-size: 18px !important; font-weight:500;">Payment Frequency: <span style="font-size:14px !important; ">{{aboutUsDetail.paymentFrequency}}</span> </p>
                            </div>
                        </div>
                    </div>

                    <hr style="background-color:red;" v-if="aboutUsDetail.isTechnicalSupport " />
                    <div class="row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-if="aboutUsDetail.isTechnicalSupport">
                        <div class="col-12">
                            <div class="form-group">
                                <h4 style="padding:0 ; margin:0;">Technical Support: <span style="font-size:16px !important; font-weight:500;">{{aboutUsDetail.supportType}}</span> </h4>

                            </div>
                        </div>
                        <div class="col-4" v-if="aboutUsDetail.supportType != 'UnLimited'">
                            <div class="form-group">
                                <p style="padding: 0 ; margin: 0; font-size: 18px !important; font-weight: 500;">From Date:<span style="font-size:14px !important; ">{{aboutUsDetail.fromDate}}</span> </p>
                            </div>
                        </div>
                        <div class="col-4" v-if="aboutUsDetail.supportType != 'UnLimited'">
                            <div class="form-group">
                                <p style="padding: 0 ; margin: 0; font-size: 18px !important; font-weight:500;">To Date:<span style="font-size:14px !important; ">{{aboutUsDetail.toDate}}</span> </p>
                            </div>
                        </div>
                        <div class="col-4" v-if="aboutUsDetail.supportType != 'UnLimited'">
                            <div class="form-group">
                                <p style="padding: 0; margin: 0; font-size: 18px !important; font-weight:500;">Payment Frequency: <span style="font-size:14px !important; ">{{aboutUsDetail.paymentFrequency}}</span> </p>
                            </div>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-4">
                            <a href="javascript:void(0)" v-on:click="UpdateQuantity()" style="color:transparent"> Update Quantity</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <brandmodel :brand="newBrand"
                    :show="show"
                    v-if="show"
                    @close="IsSave"
                    :type="type" />

    </div>
    
</template>


<script>
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        data: function () {
            return {
                aboutUsDetail : ''
            }
        },
       
        methods: {
            UpdateQuantity: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('Sale/UpdateQuantity', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data) {
                        root.loading = false;
                    }
                    root.loading = false;
                });
            },

            GetAboutUsDetail: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('Company/AboutUs', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data.isSuccess) {
                        root.aboutUsDetail = response.data.message
                        root.loading = false;
                    }
                    root.loading = false;
                });
            },
        },
       
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.GetAboutUsDetail();

        }
    }
</script>