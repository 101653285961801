<template>
    <div>
        <div hidden id='purchaseInvoice' class="col-md-12">
            <!--HEADER-->
            <div class="border border-dark col-md-12 mb-1 " style="height:40mm;" v-if="isHeaderFooter!='true'">
                <div class="row">
                    <div class="col-md-4 text-center">
                        <table class="m-auto">
                            <tr>
                                <td>
                                    <p class="text-center">
                                        <span style="font-size:19px;">{{headerFooters.company.nameEnglish}}</span><br />
                                        <span style="font-size:13px;">{{headerFooters.company.categoryEnglish}}</span><br />
                                        <span style="font-size:13px;">VAT No.: {{headerFooters.company.vatRegistrationNo}}</span><br />
                                        <span style="font-size:12px;">{{headerFooters.company.companyNameEnglish}}</span><br />
                                        <span style="font-size:11px;">
                                            {{headerFooters.company.addressEnglish}}<br />
                                            Tel: {{headerFooters.company.phoneNo}}
                                        </span>
                                    </p>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-md-4 text-center my-5" style="padding:0px !important; margin:0 !important">
                        <img :src="headerFooters.company.logoPath" style="width:auto;max-width:300px; height:auto; max-height:120px; padding:5px !important; margin:0 !important">
                        <p style="text-align: center; margin: 0px; padding: 0px; font-size: 10px; line-height: 1; font-family: 'Times New Roman', Times;">
                            <span style="font-size:14px;">{{ $t('TheQuotation.Quotation') }}</span>
                        </p>
                    </div>
                    <div class="col-md-4 " style="height:40mm;">
                        <table class="m-auto">
                            <tr>
                                <td>
                                    <p class="text-center">
                                        <span style="font-size:19px;">{{headerFooters.company.nameArabic}}.</span><br />
                                        <span style="font-size:13px;">{{headerFooters.company.categoryArabic}}</span><br />
                                        <span style="font-size:13px;">رقم ضريبة القيمة المضافة: {{headerFooters.company.vatRegistrationNo}}</span><br />
                                        <span style="font-size:12px;">{{headerFooters.company.companyNameArabic}}</span><br />
                                        <span style="font-size:11px;">
                                            {{headerFooters.company.addressArabic}}<br />
                                            هاتف: {{headerFooters.company.phoneNo}}:
                                        </span>
                                    </p>
                                </td>

                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div style="height:90mm;" v-else></div>

            <div class="border border-dark" style="height:30mm;margin-top:1mm;">
                <div class="row">
                    <div class="col-md-12 ml-2 mr-2" style="height:8mm;font-size:12px;">
                        <div class="row">
                            <div class="col-md-2">Date:</div>
                            <div class="col-md-2 text-center">{{list.date}}</div>
                            <div class="col-md-2">:تاريخ</div>
                            
                            <div class="col-md-2">Quotation No.:</div>
                            <div class="col-md-2 text-center">{{list.registrationNo}}</div>
                            <div class="col-md-2">:سؤال رقم</div>                         
                        </div>
                    </div>
                    <div class="col-md-12 ml-2 mr-2" style="height:8mm;font-size:12px;">
                        <div class="row">
                            <div class="col-md-2">Customer Name:</div>
                            <div class="col-md-2 text-center">{{list.customerNameEn}}</div>
                            <div class="col-md-2">:اسم الزبون</div>

                            <div class="col-md-2">Refrence:</div>
                            <div class="col-md-2 text-center">{{list.refrence}}</div>
                            <div class="col-md-2">:المرجعي</div>
                        </div>
                    </div>
                    <!-- style="border-bottom:black 1px dashed;" -->
                    <div class="col-md-12 ml-2 mr-2" style="height:8mm;font-size:12px;">
                        <div class="row">                           

                            <div class="col-md-2">client Purchase No:</div>
                            <div class="col-md-2 text-center">{{list.clientPurchaseNo}}</div>
                            <div class="col-md-2">:العميل شراء لا</div>
                        </div>
                    </div>
                </div>
            </div>

            <!--INFORMATION-->
            <div class="border border-dark" style="height:230mm;">
                <div class="row">
                    <div class="col-md-12">
                        <table class="table table-striped table-hover table_list_bg">
                            <tr class="heading" style="font-size:13px;">
                                <th style="width:2%;padding-top:3px !important; padding-bottom:3px !important;">#</th>
                                <th style="width:50%;padding-top:3px !important; padding-bottom:3px !important;">{{ $t('TheQuotation.Description') }}</th>
                                <th class="text-center" style="width:5%;padding-top:3px !important; padding-bottom:3px !important;">{{ $t('TheQuotation.Quantity') }}</th>
                                <th class="text-center" style="width:5%;padding-top:3px !important; padding-bottom:3px !important;" v-if="isMultiUnit=='true'">{{ $t('TheQuotation.TOTALQTY') }}</th>
                                <th class="text-right" style="width:10%;padding-top:3px !important; padding-bottom:3px !important;">{{ $t('TheQuotation.UnitPrice') }}</th>
                                <th class="text-right" style="width:6%;padding-top:3px !important; padding-bottom:3px !important;">{{ $t('TheQuotation.ExclVa') }}</th>
                                <th class="text-right" style="width:5%;padding-top:3px !important; padding-bottom:3px !important;">{{ $t('TheQuotation.Vat%') }}</th>
                                <th class="text-right" style="width:10%;padding-top:3px !important; padding-bottom:3px !important;">{{ $t('TheQuotation.VatInSAR') }}</th>
                                <th class="text-right" style="width:12%;padding-top:3px !important; padding-bottom:3px !important;">{{ $t('TheQuotation.TotalAmount') }}</th>
                            </tr>

                            <tr style="font-size:13px;" v-for="(item, index) in list.saleOrderItems" v-bind:key="item.id">
                                <td class="text-left" style="padding-top:3px !important; padding-bottom:3px !important;border-top:0 !important; border-bottom:0 !important;">{{index+1}}</td>
                                <td v-if="english=='true' && arabic=='false'" class="text-left" style="border-top:0 !important; border-bottom:0 !important; padding-top:3px !important; padding-bottom:3px !important;">{{item.productNameEn}}</td>
                                <td v-else-if="isOtherLang() && english=='false'" class="text-left" style="border-top:0 !important; border-bottom:0 !important;padding-top:3px !important; padding-bottom:3px !important;">{{item.productNameAr}}</td>
                                <td v-else class="text-left" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">{{item.productNameEn}}  {{item.productNameAr}}</td>
                                <td class="text-center" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;" v-if="isMultiUnit=='true'">{{item.highQty }} - {{item.newQuantity }}</td>
                                <td class="text-center" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;" v-else>{{item.quantity }}</td>
                                <td class="text-center" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;" v-if="isMultiUnit=='true'">{{item.quantity }}</td>
                                <td class="text-right" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">{{item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                <td class="text-right" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">{{item.total.toFixed(3).slice(0,-1)}}</td>
                                <td class="text-right" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">{{parseFloat(item.taxRate).toFixed(0) }}%</td>
                                <td class="text-right" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">{{(item.includingVat+item.inclusiveVat).toFixed(3).slice(0,-1)}}</td>
                                <td class="text-right" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;">{{(((item.total - item.discountAmount) + item.includingVat)-item.inclusiveVat).toFixed(3).slice(0,-1)}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>

            <div style="height:35mm;margin-top:1mm;">
                <div class="row">
                    <div class="col-md-12">
                        <table class="table table-bordered tbl_padding">
                            <tr>
                                <td style="padding: 3px 7px;font-size:12px;width:30%;border:1px solid black !important;"></td>
                                <td style="padding: 3px 7px;font-size:12px;width:30%;border:1px solid black !important;">{{ $t('TheQuotation.TotalQuantity') }}: {{ calulateTotalQty }}</td>
                                <td style="padding: 3px 7px;font-size:12px;width:15%;border:1px solid black !important;">{{ $t('TheQuotation.TotalPriceExclVAT') }}:</td>
                                <td style="padding: 3px 7px;font-size:12px;width:15%;border:1px solid black !important;">{{ (calulateTotalExclVAT - calulateTotalInclusiveVAT).toFixed(3).slice(0,-1) }}</td>
                            </tr>
                            <tr>
                                <td style="padding: 3px 7px;font-size:12px;border:1px solid black !important;border-bottom:0!important;border-top:0!important;" colspan="2">{{ (calulateNetTotal - list.discountAmount) | toWords }}</td>
                                <td style="padding: 3px 7px;font-size:12px;border:1px solid black !important;">{{ $t('TheQuotation.DiscAmount') }}:</td>
                                <td style="padding: 3px 7px;font-size:12px;border:1px solid black !important;">{{ calulateDiscountAmount }}</td>
                            </tr>
                            <tr>
                                <td style="padding: 3px 7px;font-size:12px;border:1px solid black !important;border-top:0!important;" colspan="2"></td>
                                <td style="padding: 3px 7px;font-size:12px;border:1px solid black !important;">{{ $t('TheQuotation.VATAmount') }}:</td>
                                <td style="padding: 3px 7px;font-size:12px;border:1px solid black !important;">{{ calulateTotalVAT.toFixed(3).slice(0,-1) }}</td>
                            </tr>
                            <tr>
                                <td style="padding: 3px 7px;font-size:12px;border:1px solid black !important;" colspan="2"></td>
                                <td style="padding: 3px 7px;font-size:12px;border:1px solid black !important;">{{ $t('TheQuotation.NetTotalwithVAT') }}:</td>
                                <td style="padding: 3px 7px;font-size:12px;border:1px solid black !important;">{{ (calulateNetTotal - calulateDiscountAmount) }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-md-12  " style="height:20mm;" v-if="isHeaderFooter!='true'">
                <div class="row">
                    <table class="table text-center">
                        <tr>
                            <td>
                                {{ $t('TheQuotation.ReceivedBy') }}::
                            </td>
                            <td>
                                {{ $t('TheQuotation.ApprovedBy') }}:
                            </td>
                            <td>
                                {{ $t('TheQuotation.PreparedBy') }}:
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <!--Footer-->
            <div class="border border-dark col-md-12 " style="height: 30mm;" v-if="isHeaderFooter!='true'">
                <div class="row">
                    <div class="col-md-3 text-center">
                        <u><b><span style="font-size:14px;">Terms & Conditions</span></b></u><br />
                        <span style="font-size:11px;" v-html="headerFooters.footerEn">
                            
                        </span>
                    </div>
                    <div class="col-md-6  text-center">
                        <p style="padding-top:15px;font-size:11px;">
                            Warranty period 5 days after receiving the goods.
                        </p>
                    </div>
                    <div class="col-md-3 text-center">
                        <u><b><span style="font-size:14px;">البنود و الظروف</span></b></u><br />
                        <span class=" text-center" style="font-size:11px;" v-html="headerFooters.footerAr">
                           
                        </span>
                    </div>
                </div>
            </div>
            <div style="height: 40mm;" v-else></div>
        </div>
    </div>

</template>

<script>
    import moment from "moment";
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        props: ['printDetails', 'headerFooter'],
        data: function () {
            return {
                isHeaderFooter: '',
                arabic: '',
                english: '',
                isMultiUnit: '',
                list: {
                    number: 0,
                    listItemTotal: [],
                    registrationNo: '',
                    date: '',
                    dueDate: '',
                    companyName: '',
                    companyPhoneNo: '',
                    companyAddress: '',
                    discountAmount: '',
                    cashCustomer: '',
                    creditCustomer: '',
                    customerPhoneNo: '',
                    customerAddress: '',
                    paymentMethod: '',
                    paymentMethodNo: '',
                    invocieType: '',
                    saleOrderItems:
                        [

                        ]
                },
                render: 0,
                headerFooters: {
                    footerEn: '',
                    footerAr: '',
                    company: ''
                }
            }
        },
        filters: {
            toWords: function (value) {
                var converter = require('number-to-words');
                if (!value) return ''
                return converter.toWords(value);
            }
        },
        computed: {
            calulateTotalQty: function () {
                
                return this.list.saleOrderItems.reduce(function (a, c) { return a + Number((c.quantity) || 0) }, 0)
            },
            calulateNetTotal: function () {
                return this.list.saleOrderItems.reduce(function (a, c) { return a + Number((c.total + c.includingVat) || 0) }, 0)
            },
            calulateTotalExclVAT: function () {
                return this.list.saleOrderItems.reduce(function (a, c) { return a + Number((c.total) || 0) }, 0)
            },
            calulateTotalVAT: function () {
                return this.list.saleOrderItems.reduce(function (a, c) { return a + Number((c.includingVat + c.inclusiveVat) || 0) }, 0)
            },
            calulateTotalInclusiveVAT: function () {
                return this.list.saleOrderItems.reduce(function (a, c) { return a + Number((c.inclusiveVat) || 0) }, 0)
            },
            calulateDiscountAmount: function () {
                return this.list.saleOrderItems.reduce(function (a, c) { return a + Number(c.discountAmount || 0) }, 0)
            }
        },

        methods: { 
            printInvoice: function () {
                
                this.$htmlToPaper('purchaseInvoice');
            }

        },
        mounted: function () {
            this.isMultiUnit = localStorage.getItem('IsMultiUnit');
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.isHeaderFooter = localStorage.getItem('IsHeaderFooter');
            var root = this;
            if (this.printDetails.saleOrderItems.length > 0) {
                this.list = this.printDetails;
                this.headerFooters = this.headerFooter;
                this.list.date = moment().format('DD MMM YYYY');
                setTimeout(function () {
                    root.printInvoice();
                }, 125)
            }
        },

    }
</script>

