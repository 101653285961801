<template>
    <div v-if="color">
        <div v-if="barcodescreen">
            <div v-if="disable">
                <input type="text" disabled style="background-color: transparent !important;" class="form-control inp-border text-center tableHoverOn" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="displayValue" @blur="isInputActive = false" @focus="isInputActive=true" @click="$event.target.select()" />
            </div>
            <div v-else>
                <input type="text" style="background-color:transparent !important;" class="form-control inp-border text-center tableHoverOn" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="displayValue" @blur="isInputActive = false" @focus="isInputActive=true" @click="$event.target.select()" />
            </div>
        </div>
        <div v-else>
            <div v-if="disable">
                <input type="text" disabled style="background-color: transparent !important;color:white" class="form-control inp-border text-center tableHoverOn" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="displayValue" @blur="isInputActive = false" @focus="isInputActive=true" @click="$event.target.select()" />
            </div>
            <div v-else>
                <input type="text" style="background-color:transparent !important;color:white" class="form-control inp-border text-center tableHoverOn" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="displayValue" @blur="isInputActive = false" @focus="isInputActive=true" @click="$event.target.select()" />
            </div>
        </div>
    </div>
    <div v-else>
        <div v-if="disable">
            <input type="text" disabled class="form-control inp-border text-center tableHoverOn" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="displayValue" @blur="isInputActive = false" @focus="isInputActive=true" @click="$event.target.select()" />
        </div>
        <div v-else>
            <input type="text" class="form-control inp-border text-center tableHoverOn" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="displayValue" @blur="isInputActive = false" @focus="isInputActive=true" @click="$event.target.select()" />
        </div>
    </div>
  

</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'

    export default {
        name: 'DecimalDropdown',
        props: ["value", "salePriceCheck", "disable", "color", "barcodescreen"],
        mixins: [clickMixin],

        data: function () {
            return {
                isInputActive: false,
                currency: '',
            }
        },

        computed: {
            displayValue: {
                get: function () {
                    
                    if (this.isInputActive) {
                        
                        // Cursor is inside the input field. unformat display value for user
                        return this.value.toString()
                    } else {
                        
                        if (this.salePriceCheck) {
                            
                            this.$emit('CheckSalePrice', this.value);

                        }
                        // User is not modifying now. Format display value for user interface
                        return  parseFloat(this.value).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
                    }
                },
                set: function (modifiedValue) {
                    // Recalculate value after ignoring "$" and "," in user input
                    let newValue = parseFloat(modifiedValue.replace(/[^\d\.]/g, "")); // eslint-disable-line
                    // Ensure that it is not NaN
                    if (isNaN(newValue)) {
                        newValue = 0
                    }
                    // Note: we cannot set this.value as it is a "prop". It needs to be passed to parent component
                    // $emit the event so that parent component gets it
                    this.$emit('input', newValue)
                }
            }
        },
        mounted: function () {
            this.currency = localStorage.getItem('currency');
        }
    }
</script>
<style scoped>
    .inp-border {
        border: none;
        background-color: transparent;
    }
        .inp-border:focus {
            outline: none;
        }



    .tableHoverOn {
        background-color: #ffffff !important;
        height: 32px !important;
        max-height: 32px !important;
    }
</style>