<template>
    <modal :show="show" v-if="isValid('Day Start')">

        <div style="margin-bottom:0px" class="card">
            <div class="card-body">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="modal-header" v-if="type=='Edit'">
                            <h5 class="modal-title DayHeading" id="myModalLabel"> {{ $t('DayEnd') }}</h5>                            

                        </div>
                        <div class="modal-header" v-else>
                            <h5 class="modal-title DayHeading" id="myModalLabel"> {{ $t('Dashboard.DayStart') }}</h5> {{dayStart.fromTime}}
                            <h6 class="modal-title DayHeading" id="myModalLabel" v-if="isExpenseDay">{{ $t('ExpenseStartOperation') }}</h6>
                        </div>
                        <div class="card-body">
                            <div class="row " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <h6 class="text-center">  {{UserName}}</h6>

                                <div class="form-group has-label col-sm-12 " v-bind:class="{ 'has-danger': $v.dayStart.date.$error}">
                                    <label class="text  font-weight-bolder">   {{ $t('Date') }}:<span class="LabelColour"> *</span> </label>
                                    <datepicker :isDisable="true" v-model="$v.dayStart.date.$model" :key="dateRander" :datedisable="true" />
                                    <span v-if="$v.dayStart.date.$error" class="error text-danger">
                                        <span v-if="!$v.dayStart.date.required">  {{ $t('DateRequired') }}</span>
                                    </span>
                                </div>

                                <div class="form-group has-label col-sm-6" v-bind:class="{ 'has-danger': $v.dayStart.fromTime.$error}">
                                    <label class="text  font-weight-bolder">{{ $t('FromTime') }}:<span class="LabelColour"> *</span></label>
                                    <vue-timepicker disabled v-model="$v.dayStart.fromTime.$model" :key="time" input-width="100%"></vue-timepicker>
                                    <span v-if="$v.dayStart.fromTime.$error" class="error text-danger">
                                        <span v-if="!$v.dayStart.fromTime.required">{{ $t('TimeRequired') }}</span>
                                    </span>
                                </div>
                                <!--<div class="form-group has-label col-sm-6" v-if="type=='Edit'">
        <label class="text  font-weight-bolder">To Time:*</label>
        <vue-timepicker v-model="dayStart.toTime" :key="time" input-width="100%"></vue-timepicker>
    </div>-->

                                <div class="form-group has-label col-sm-6 ">
                                    <label class="text  font-weight-bolder"> {{ $t('Counter') }}:<span class="LabelColour"> *</span></label>
                                    <terminal-dropdown v-if="isOpenDay" v-model="dayStart.counterId" :terminalType="terminalType" :key="render" v-on:input="getOpeningBalance" :values="dayStart.counterId" :isDayStart=true />
                                    <terminal-dropdown v-else :isDisable="true" v-model="dayStart.counterId" :terminalType="terminalType" :key="render" v-on:input="getOpeningBalance" :values="dayStart.counterId" :isDayStart=true />

                                </div>

                                <div class="form-group has-label col-sm-6 ">
                                    <label class="text  font-weight-bolder"> {{ $t('OpeningCash') }}:* </label>
                                    <input class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="openingCash" type="number" disabled />
                                </div>
                                <div class="form-group has-label col-sm-6 " v-bind:class="{ 'has-danger': $v.dayStart.verifyCash.$error}">
                                    <label class="text  font-weight-bolder">  {{ $t('VerifyCash') }}:<span class="LabelColour"> *</span> </label>
                                    <input class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.dayStart.verifyCash.$model" type="number" @click="$event.target.select()" />
                                    <span v-if="$v.dayStart.verifyCash.$error" class="error text-danger">
                                        <span v-if="!$v.dayStart.verifyCash.required">{{ $t('VerifyCashRequired') }}</span>
                                    </span>
                                </div>
                                <div class="form-group has-label col-sm-6 ">
                                    <label class="text  font-weight-bolder"> {{ $t('User') }}:<span class="LabelColour"> *</span> </label>
                                    <input class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.dayStart.user.$model" type="text" />
                                </div>
                                <div class="form-group has-label col-sm-6 ">
                                    <label class="text  font-weight-bolder"> {{ $t('Password') }}:<span class="LabelColour"> *</span> </label>
                                    <input class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.dayStart.password.$model" type="password" />
                                </div>
                                <div class="form-group has-label col-sm-12 " v-if="dayStart.openingCash > dayStart.verifyCash || dayStart.openingCash < dayStart.verifyCash ">
                                    <label class="text  font-weight-bolder"> {{ $t('DayStarts.Reason') }}:<span class="LabelColour"> *</span> </label>
                                    <textarea class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.dayStart.reason.$model" type="text" @click="$event.target.select()" />
                                </div>

                            </div>
                        </div>

                        <div class="modal-footer justify-content-right" v-if="type=='Edit'&& isValid('Day Start')">

                            <button type="button" class="btn btn-primary  " v-on:click="SaveDayStart"><i class="far fa-save"></i> {{ $t('DayClose') }}</button>
                            <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{ $t('Cancel') }}</button>

                        </div>
                        <div class="modal-footer justify-content-right" v-else>

                            <button type="button" class="btn btn-success  " v-on:click="SaveDayStart" :disabled="$v.dayStart.$invalid"><i class="far fa-save"></i> {{ $t('Start') }}</button>
                            <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()"> {{ $t('Cancel') }}</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </modal>
    <acessdenied v-else :model=true></acessdenied>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import 'vue-loading-overlay/dist/vue-loading.css';
    import VueTimepicker from 'vue2-timepicker'
    import moment from "moment";
    import 'vue2-timepicker/dist/VueTimepicker.css'

    import { required, requiredIf, maxLength } from "vuelidate/lib/validators"
    export default {
        mixins: [clickMixin],
        components: {

            VueTimepicker
        },
        props: ['show', 'counterCode', 'openingCash', 'type', 'counterId','isExpenseDay','isFirstUser'],
        data: function () {
            return {
                render: 0,
                time: 0,
                dateRander: 0,
                UserName: '',
                CompanyID: '',
                UserID: '',
                isOpenDay:'',
                SupervisorLogin: false,
                terminalType : 'CashCounter',
                dayStart: {
                    id: '00000000-0000-0000-0000-000000000000',
                    date: '',
                    fromTime: '',
                    toTime: '',
                    isActive: true,
                    counterCode: '',
                    locationId: '',
                    openingCash: 0,
                    verifyCash: null,
                    user: '',
                    password: '',
                    reason: '',
                    counterId: '',
                    isExpenseDay: false,
                    isFirstUser:false,
                },
                
            }
        },
        validations: {
            dayStart: {
                date: {
                    required
                },
                fromTime: {
                    required,
                    isValid: function (value) {
                        if (value.includes('mm') || value.includes('HH')) {
                            return false
                        }
                        return true;
                    }
                },
                verifyCash: {
                    required
                },
                user: {
                    required
                },
                
                counterId: {
                    required
                },
                password: {
                    required
                },
                reason: {
                    required: requiredIf((x) => {
                        if (x.openingCash > x.verifyCash || x.openingCash < x.verifyCash)
                            return true;
                        return false;
                    }),
                    maxLength: maxLength(200)
                },
               

            }
        },
        methods: {
            getOpeningBalance: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                this.dayStart.counterId = id;
                this.$https.get('/Product/OpeningBalance?counterId=' + id + '&isOpeningCash=' + true, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                    if (response.data != null) {
                        
                        
                        root.openingCash = response.data.openingBalance < 0 ? response.data.openingBalance * (-1) : response.data.openingBalance
                        root.dayStart.openingCash = root.openingCash
                        
                    }
                   
                });
            },
            getTerminalByUserId: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                this.$https.get('/Product/GetTerminalByUserId', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                    if (response.data) {
                        
                        root.dayStart.counterId = response.data
                        root.render++;
                    }
                   
                });
            },
            close: function () {
                this.$emit('close');
            },
            SaveDayStart: function () {
                
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                localStorage.setItem('CounterId', this.dayStart.counterId);
                this.dayStart.isSupervisorLogin= this.SupervisorLogin;
                localStorage.setItem('DayStartTime', this.dayStart.fromTime);
                this.dayStart.isFirstUser = this.isFirstUser;
                if (this.dayStart.id == '00000000-0000-0000-0000-000000000000' || this.dayStart.id == undefined || this.dayStart.id == '') {
                    this.dayStart.saleId = this.UserID;
                    this.dayStart.locationId = this.CompanyID;
                }

                this.$https.post('/Product/SaveDayStart?isDayStart=' + true, this.dayStart, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    
                    if (response.data.dayId != '00000000-0000-0000-0000-000000000000') {
                        

                       {
                            
                            root.$swal({
                                title: root.$t('SavedSuccessfully'),
                                text: root.$t('Saved'),
                                type: 'success',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 800,
                                timerProgressBar: true,
                            });
                            
                            var time = moment().format('h:mm a');
                            localStorage.setItem('CounterCode', root.dayStart.counterCode);
                            localStorage.setItem('DayStartTime', time);
                            localStorage.setItem('IsDayStart', true);
                            root.close();

                            localStorage.setItem('token', response.data.result.token);
                            localStorage.setItem('IsExpenseDay', response.data.result.isExpenseDay);
                            localStorage.setItem('PrinterName', response.data.result.printerName);

                        }
                    }
                    else {
                        root.$swal({
                            title: root.$t('Error'),
                            text: root.$t('DayStartAlreadyExist'),
                            type: 'error',
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 800,
                            timerProgressBar: true,
                        });
                    }
                }).catch(error => {
                    
                    console.log(error)
                    root.$swal.fire(
                        {
                            icon: 'error',
                            title: 'Error!',
                            text: error.response.data,

                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                        });

                    root.loading = false
                })
            }
        },
        mounted: function () {
            
            if (this.dayStart.id == '00000000-0000-0000-0000-000000000000' || this.dayStart.id == undefined || this.dayStart.id == '') {
                this.dayStart.fromTime = moment().format('HH:mm');
                this.dayStart.date = moment().format('llll');
                this.dateRander++;
                this.time++;
                if (this.$session.exists()) {
                    this.UserName = localStorage.getItem('UserName');
                    this.CompanyID = localStorage.getItem('CompanyID');
                    this.UserID = localStorage.getItem('UserID');
                    this.isOpenDay = localStorage.getItem('IsOpenDay')== 'true'?true:false;
                }
                this.SupervisorLogin = localStorage.getItem('IsSupervisor') == 'true' ? true : false;
                this.dayStart.isOpenDay = this.isOpenDay;
                this.dayStart.counterCode = this.counterCode;
                this.dayStart.openingCash = this.openingCash;
                this.dayStart.counterId = this.counterId;
                this.dayStart.isExpenseDay = this.isExpenseDay;
                this.getTerminalByUserId();

            }
        }
    }
</script>
