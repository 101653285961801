<template>
    <div v-if="isDisabled">
        <!--Disable Date With 00 00 Date Fomrat-->
        <el-date-picker v-model="inputValue"
                        type="date"
                        v-bind:placeholder="$t('PickDate')"
                        style="width: 100%"
                        format='00 000 0000'
                        v-bind:class="($i18n.locale == 'en' ) ? 'text-left' : 'arabicLanguage'"
                        :picker-options="pickerOptions" disabled>
        </el-date-picker>
    </div>
    <div v-else-if="isDisable">
        <!--Disable Date-->
        <el-date-picker v-model="inputValue"
                        type="date"
                        disabled
                        v-bind:placeholder="$t('PickDate')"
                        style="width: 100%"
                        format='dd MMM yyyy'
                        v-bind:class="($i18n.locale == 'en') ? 'text-left' : 'arabicLanguage'"
                        :picker-options="pickerOptions">
        </el-date-picker>
    </div>
    <div v-else>


        <el-date-picker v-model="inputValue"
                        type="date"
                        v-bind:placeholder="$t('PickDate')"
                        style="width: 100%"
                        format='dd MMM yyyy'
                        v-bind:class="($i18n.locale == 'en') ? (dropdownDatecss? 'text-left date-border-none' :'text-left') : (dropdownDatecss? 'arabicLanguage date-border-none' :'arabicLanguage')"
                        :picker-options="pickerOptions">
        </el-date-picker>

    </div>
</template>
<script>
    import moment from 'moment'
    import 'element-ui/lib/theme-chalk/index.css';
    export default {
        props: ['value', 'dropdowndatecss', 'isDisabled', 'isDisable'],
        data: function () {
            return {
                dropdownDatecss: "",
                inputValue: '',
                editField: '',
                pickerOptions: {
                    shortcuts: [
                    {
                            text: this.$t('DateFilter.Today') ,
                        onClick(picker) {
                            picker.$emit('pick', new Date());
                        }
                    },
                    {
                        text: this.$t('DateFilter.Yesterday') ,
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', date);
                        }
                    },
                        {
                            text: this.$t('DateFilter.Aweekago'),
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', date);
                        }
                    },
                        {
                            text: this.$t('DateFilter.AMonthago'),
                        onClick(picker) {
                            const date = new Date();
                            
                            var d = date.getDate();
                            date.setFullYear(date.getFullYear() + -1);
                            if (date.getDate() != d) {
                                date.setDate(0);
                            }
                            picker.$emit('pick', date);
                        }
                    },
                        {
                            text: this.$t('DateFilter.Quarterago'), 

                        onClick(picker) {
                            const date = new Date();
                            var d = date.getDate();
                            date.setMonth(date.getMonth() + -4);
                            if (date.getDate() != d) {
                                date.setDate(0);
                            }
                            picker.$emit('pick', date);
                        }
                    },
                        {
                            text: this.$t('DateFilter.HalfYear'), 
                        onClick(picker) {
                            const date = new Date();
                            var d = date.getDate();
                            date.setMonth(date.getMonth() + -6);
                            if (date.getDate() != d) {
                                date.setDate(0);
                            }
                            picker.$emit('pick', date);
                        }
                    },
                        {
                            text: this.$t('DateFilter.AYearago'), 
                        onClick(picker) {
                            const date = new Date();
                            var d = date.getDate();
                            date.setFullYear(date.getFullYear() + -1);
                            if (date.getDate() != d) {
                                date.setDate(0);
                            }
                            picker.$emit('pick', date);
                        }
                    }
                    ]
                },
            }
        },
        mounted: function () {
            this.dropdownDatecss = this.dropdowndatecss;
            this.inputValue = this.value;
        },
        updated: function () {
            var input = "";
            if (this.inputValue != "" && this.inputValue != null && this.inputValue != undefined) {
                input = moment(String(this.inputValue)).format('DD MMM YYYY');
            }
            this.inputValue = input;
            this.$emit('input', this.inputValue);
        }
    }
</script>