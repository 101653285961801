<template>
    <modal :show="show" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" :modalLarge="true">

        <div style="margin-bottom:0px" class="card">
            <div class="card-body">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">

                        <div class="modal-header">

                            <h5 class="modal-title" id="myModalLabel"> {{ $t('UnHoldModel.UnHoldInvoices') }} </h5>

                        </div>

                        <div class="card-body" style="background-color:#FBFBFB">
                            <div class="row " style="overflow: auto;">
                                <div class="col-lg-3" v-for="item in saleList" :key="item.id">
                                   
                                        <div class="row card cardClass"  style="width:170px;height:170px;font-size:medium;font-weight:normal;padding-left:10px;padding-top:10px;vertical-align:central">
                                            <div class="row">
                                                <div class="col-10 text-left" >
                                                    <div style="width:130px;font-size:11px;font-weight:bold" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'pr-2'"> {{ $t('UnHoldModel.RefrenceNo') }} </div>

                                                </div>
                                                <div class="col-2 " style="height:0px; padding-right: 16px;">
                                                    <button  v-on:click="RemoveSale(item.id)" title="Remove Item" style="margin:0;"
                                                       class="btn btn-danger  btn-sm  btn-icon float-right">
                                                        <i class="nc-icon nc-simple-remove"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="row" v-on:click="EditSale(item.id)">
                                                <div  class="col-12 pl-3">{{item.refrenceNo}}</div>
                                                <div class="col-12 pl-3">{{ $t('UnHoldModel.Invoice') }}</div> <div class="col-12 pl-3">{{item.registrationNumber}}</div>
                                                <div  class="col-12 pl-3">{{ $t('UnHoldModel.Amount') }}</div> <div class="col-12 pl-3">{{item.netAmount}}</div>
                                            </div>
                                        </div>
                                   
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer justify-content-right">
                            <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()"> {{ $t('UnHoldModel.Close') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </modal>

</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        mixins: [clickMixin],
        props: ['show', 'sale'],
        data: function () {
            return {

                saleList: [],
                save: false,
            }
        },
        methods: {
            close: function () {

                this.$emit('close', false);
            },
            RemoveSale: function (id) {
                
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$swal({
                    title: "Are you sure?",
                    text: "You will not be able to recover this!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes, delete it!",
                    closeOnConfirm: false,
                    closeOnCancel: false
                }).then(function (result) {
                    if (result.isConfirmed) {
                        root.$https.get('/Sale/DeleteSale?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } })
                            .then(function (response) {
                                

                                if (response.data != null) {
                                    root.$swal({
                                        title: root.$t('UnHoldModel.SavedSuccessfully'),
                                        text: "Invoice removed successfully",
                                        type: 'success',
                                        confirmButtonClass: "btn btn-success",
                                        buttonStyling: false,
                                        icon: 'success',
                                        timer: 1500,
                                        timerProgressBar: true,

                                    }).then(function () {
                                        root.getData('Hold', 1,);
                                    })

                                }
                                else {
                                    root.$swal({
                                        title: 'Error!',
                                        text: "Invoice not removed successfully",
                                        type: 'error',
                                        confirmButtonClass: "btn btn-danger",
                                        icon: 'error',
                                        timer: 1500,
                                        timerProgressBar: true,
                                    });
                                }
                            }).catch(error => {
                                console.log(error)
                                root.$swal.fire(
                                    {
                                        icon: 'error',
                                        title: 'Something Went Wrong!',
                                        text: error.response.data,
                                        showConfirmButton: false,
                                        timer: 5000,
                                        timerProgressBar: true,
                                    });

                            });
                    }
                })
               
            },
            EditSale: function (id) {
                
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Sale/SaleDetail?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        
                        if (response.data != null) {
                            root.$emit('EditTOuchInvocie', response.data);
                            root.close();

                        }
                        else {
                            root.$swal({
                                title: 'Error!',
                                text: "Something Went Wrong",
                                type: 'error',
                                confirmButtonClass: "btn btn-danger",
                                icon: 'error',
                                timer: 1500,
                                timerProgressBar: true,
                            });
                        }
                    },
                        function (error) {
                            console.log(error);
                        });
            },

            getData: function (status, currentPage) {

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                this.$https.get('/Sale/SaleList?status=' + status + '&pageNumber=' + currentPage, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {

                        root.$store.dispatch('GetPurchaseOrderList', response.data.results);
                        root.saleList = response.data.results;


                    });
            },
            //SaveOrigin: function () {


            //    var root = this;

            //    root.close();
            //}
        },
        mounted: function () {

            this.getData('Hold', 1,);

        }
    }
</script>
<style scoped>
    .cardClass:hover {
        background-color: #2d76f7;
        color: white;
        cursor: pointer
    }
</style>
