<template>
    <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
        <modal :show="show">
            <div class="modal-header" v-if="type=='Edit'">

                <h5 class="modal-title DayHeading" id="myModalLabel"> Update Department</h5>

            </div>
            <div class="modal-header" v-else>

                <h5 class="modal-title DayHeading" id="myModalLabel"> Add Department</h5>

            </div>
            <div class="modal-body">
                <div class="row">

                    <div class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.department.name.$error}">
                        <label class="text  font-weight-bolder"> Department Name: <span class="LabelColour"> *</span></label>
                        <input class="form-control" v-model="$v.department.name.$model" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" type="text" />
                        <span v-if="$v.department.name.$error" class="error">
                            <span v-if="!$v.department.name.required">Department name is required</span>
                            <span v-if="!$v.department.name.maxLength">Department name has maximum Length 50</span>
                        </span>
                    </div>

                    <div class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.department.description.$error}">
                        <label class="text  font-weight-bolder"> Description</label>
                        <textarea class="form-control" v-model="$v.department.description.$model" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" type="text" />
                        <span v-if="$v.department.description.$error" class="error">Description have max Length 200</span>
                    </div>

                </div>

            </div>
            <div class="modal-footer justify-content-right" v-if="type=='Edit'">

                <button type="button" class="btn btn-primary  ">Update</button>
                <button type="button" class="btn btn-secondary  mr-3 " v-on:click="close()">Cancel</button>

            </div>
            <div class="modal-footer justify-content-right" v-else>

                <button type="button" class="btn btn-primary  "> Save</button>
                <button type="button" class="btn btn-secondary  mr-3 " v-on:click="close()">Cancel</button>

            </div>

        </modal>
    </div>
</template>
<script>
    import { required, maxLength } from "vuelidate/lib/validators"
    export default {
        props: ['show', 'department','type'],
        data: function () {
            return {
            }
        },
        validations: {
            department: {
                name: {
                    required,
                    maxLength: maxLength(50)
                },
                description: {
                    maxLength: maxLength(200)
                }
            }
        },
        methods: {
            close: function () {
                this.$emit('close');
            },
        },
        mounted: function () {
            
        }
    }
    </script>
    