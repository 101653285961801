<template>
    <div class="row" v-if="isValid('CanAddPurchaseReturn')" v-bind:style="$i18n.locale == 'ar' ? languageChange('en') : languageChange('ar')">
        <div class="col-md-12 ml-auto mr-auto">
            <div class="card" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                <div class="card-body">

                    <div class="tab-content" id="nav-tabContent">

                        <div class="row">
                            <div class="col-lg-6 poHeading" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                <span>{{ $t('AddPurchaseReturn.AddPurchaseReturn') }}  <span style="font-weight:bold">{{registrationNo}}</span></span><span v-if="disable"> <a href="javascript:void(0)" class=" badge badge-danger" v-on:click="ViewPurchaseHistory(purchase.purchaseInvoiceId)" v-if="isValid('CanViewReturnHistory')"> {{ $t('AddPurchaseReturn.PurchaseReturnHistory') }}  ({{counter}})</a></span>

                            </div>
                            <div class="col-lg-6 dateHeading" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                                <span>{{purchase.date}}</span> <br />
                            </div>
                        </div>
                        <div class="row bottomBorder">

                        </div>



                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div v-if="raw=='true'" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <label>{{ $t('AddPurchaseReturn.RawProduct') }} : </label>
                                    <toggle-button v-model="purchase.isRaw" @change="ChangeSupplier" class="ml-2 mt-2" color="#3178F6" />
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <label>{{ $t('AddPurchaseReturn.Supplier') }}:<span class="LabelColour"> *</span></label>
                                <div v-bind:class="{'has-danger': $v.purchase.supplierId.$error,}" :key="supplierRender">
                                    <supplierdropdown v-bind:key="rander" v-on:input="LoadSupplierInvoice(purchase.supplierId)" v-bind:values="purchase.supplierId" v-model="$v.purchase.supplierId.$model" :status="purchase.isRaw" />
                                    <span v-if="$v.purchase.supplierId.$error"
                                          class="error text-danger">
                                    </span>
                                </div>
                            </div>

                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <label>{{ $t('AddPurchaseReturn.PurchaseInvoice') }}  :</label>
                                <purchaseinvoicedropdown v-on:input="GetData" v-model="purchase.purchaseInvoiceId" :supplierid="purchase.supplierId" :key="purchaseInvoiceRender" />
                            </div>

                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <label>{{ $t('AddPurchaseReturn.WareHouse') }} :<span class="LabelColour"> *</span></label>
                                <warehouse-dropdown v-bind:key="rander" v-bind:values="purchase.wareHouseId" v-model="$v.purchase.wareHouseId.$model" />
                                <span v-if="$v.purchase.invoiceDate.$error"
                                      class="error text-danger">
                                </span>
                            </div>
                        </div>
                        <br />
                        <purchase-item :key="rander" :purchase="purchase" :raw="purchase.isRaw" :taxMethod="purchase.taxMethod" :taxRateId="purchase.taxRateId" @input="SavePurchaseItems" />
                        <div v-if="!loading" class="col-md-12 arabicLanguage">
                            <button class="btn btn-success mr-2"
                                    v-if="isValid('CanAddPurchaseReturn')"
                                    :disabled="$v.$invalid || purchase.purchasePostItems.filter(x => x.outOfStock).length > 0 || purchase.purchasePostItems.filter(x => x.totalPiece=='').length > 0 || purchase.purchasePostItems.filter(x => x.unitPrice=='').length > 0 || ( isFifo? (purchase.purchasePostItems.filter(x => x.expiryDate=='').length > 0 || purchase.purchasePostItems.filter(x => x.batchNo=='').length > 0):false)"
                                    v-on:click="savePurchase">
                                {{ $t('AddPurchaseReturn.Save') }}
                            </button>
                            <button class="btn btn-danger mr-2"
                                    v-on:click="goToPurchase">
                                {{ $t('AddPurchaseReturn.Cancel') }}
                            </button>
                        </div>
                        <div class="card-footer col-md-3 text-left" v-else>
                            <loading :name="loading" :active.sync="loading"
                                     :can-cancel="false"
                                     :is-full-page="true"></loading>
                        </div>


                    </div>

                </div>
                <purchaseReturnHistorymodel :purchaseReturn="purchaseReturn"
                                            :show="show"
                                            v-if="show"
                                            @close="show=false" />
            </div>
        </div>

    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import Loading from 'vue-loading-overlay';
    import moment from "moment";
    import "vue-loading-overlay/dist/vue-loading.css";
    import { required } from "vuelidate/lib/validators";
    //import Multiselect from 'vue-multiselect'
    //import VueBarcode from 'vue-barcode';
    export default {
        mixins: [clickMixin],
        components: {
            Loading
        },
        data: function () {
            return {
                rendered: 0,
                registrationNo: "",
                purchase: {
                    id: "00000000-0000-0000-0000-000000000000",
                    date: "",
                    registrationNo: "",
                    supplierId: "",
                    invoiceNo: "",
                    invoiceDate: "",
                    purchaseOrder: "",
                    wareHouseId: "",
                    isRaw: false,
                    isPurchaseReturn: true,
                    purchasePostItems: [],
                    purchaseInvoiceId: "",
                },
                purchaseReturn: [],
                show: false,
                raw: '',
                rander: 0,
                counter: 0,
                loading: false,
                disable: false,
                isFifo: false,
                language: 'Nothing',
                supplierRender: 0,
                options: [],
                purchaseInvoiceRender: 0
            };
        },
        validations: {
            purchase: {
                date: { required },
                registrationNo: { required },
                supplierId: { required },
                invoiceNo: {},
                invoiceDate: {},
                wareHouseId: {},
            },
        },
        methods: {

            ViewPurchaseHistory: function (id) {

                this.show = !this.show;

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                var isMultiUnit = localStorage.getItem('IsMultiUnit') == 'true' ? true : false;

                root.$https.get('/PurchasePost/PurchaseReturnHistory?id=' + id + '&isReturnView=' + true + '&isMultiUnit=' + isMultiUnit, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {

                            root.purchaseReturn = response.data.purchaseReturnListHistory;

                        }
                    },
                        function (error) {
                            this.loading = false;
                            console.log(error);
                        });
            },
            LoadSupplierInvoice: function () {
                this.purchaseInvoiceRender++;
            },
            ChangeSupplier: function () {
                this.supplierRender++;
            },
            languageChange: function (lan) {
                if (this.language == lan) {
                    if (this.purchase.id == '00000000-0000-0000-0000-000000000000') {

                        var getLocale = this.$i18n.locale;
                        this.language = getLocale;

                        this.$router.go('/addproduct');
                    }
                    else {
                        this.$swal({
                            title: 'Error!',
                            text: 'You cannot Change language during update, otherwise your current page data will be lose',
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            icon: 'error',
                            timer: 4000,
                            timerProgressBar: true,
                        });
                    }
                }


            },

            GetData: function (id) {
                var root = this;
                var token = "";
                if (root.$session.exists()) {
                    token = localStorage.getItem("token");
                }
                var isMultiUnit = localStorage.getItem('IsMultiUnit') == 'true' ? true : false;
                root.$https.get('/PurchasePost/PurchasePostDetail?id=' + id + '&isMultiUnit=' + isMultiUnit, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        
                        root.purchase = response.data;
                        root.purchase.purchaseInvoiceId = response.data.id;
                        root.purchase.purchasePostItems = response.data.purchasePostItems;
                        root.purchase.id = '00000000-0000-0000-0000-000000000000';
                        root.purchase.date = moment().format("LLL");

                        root.rander++;
                        root.CheckPurchaseReturnCounter(id);
                    }

                });
            },
            CheckPurchaseReturnCounter: function (id) {

                var root = this;
                var token = "";
                if (root.$session.exists()) {
                    token = localStorage.getItem("token");
                }
                root.disable = false;
                root.$https
                    .get('/PurchasePost/PurchaseReturnHistoryCounter?id=' + id, {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then(function (response) {

                        if (response.data != 0) {
                            root.counter = response.data;
                            root.disable = true;
                        }
                    });
            },
            AutoIncrementCode: function () {
                var root = this;
                var token = "";
                if (root.$session.exists()) {
                    token = localStorage.getItem("token");
                }
                root.$https
                    .get("/PurchasePost/PurchaseReturnAutoGenerateNo?isPurchaseReturn=true", {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then(function (response) {
                        if (response.data != null) {
                            root.registrationNo = response.data.purchaseReturn;
                        }
                    });
            },
            SavePurchaseItems: function (purchaseOrderItems) {
                this.purchase.purchasePostItems = purchaseOrderItems;
            },
            savePurchase: function () {
                this.loading = true;
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                this.purchase.isPurchaseReturn = true;
                this.purchase.isMultiUnit = localStorage.getItem('IsMultiUnit') == 'true' ? true : false;
                this.$https
                    .post('/PurchasePost/SavePurchasePostInformation', root.purchase, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {
                        if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Add") {
                            root.info = response.data.bpi
                            root.$swal({
                                title: "Saved!",
                                text: response.data.message.isAddUpdate,
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonStyling: false,
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,

                            }).then(function (ok) {
                                if (ok != null) {
                                    root.$router.go();
                                }
                            });
                        }
                        else if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Update") {
                            root.info = response.data.bpi
                            root.$swal({
                                title: "Saved!",
                                text: response.data.message.isAddUpdate,
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonStyling: false,
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,

                            }).then(function (ok) {
                                if (ok != null) {
                                    root.$router.push({
                                        path: '/PurchaseReturn',
                                        query: {
                                            data: 'PurchaseReturns'
                                        }
                                    })
                                }
                            });
                        }
                        else {

                            root.$swal({
                                title: 'Error!',
                                text: response.data.message.isAddUpdate,
                                type: 'error',
                                confirmButtonClass: "btn btn-danger",
                                icon: 'error',
                                timer: 1500,
                                timerProgressBar: true,
                            });
                        }
                    })
                    .catch(error => {

                        root.$swal.fire(
                            {
                                type: 'error',
                                icon: 'error',
                                title: root.$t('AddPurchaseReturn.Error'),
                                text: error.response.data,
                                confirmButtonClass: "btn btn-danger",
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false
                    })
                    .finally(() => root.loading = false)
            },

            goToPurchase: function () {
                if (this.isValid('CanViewPurchaseReturn')) {
                    this.$router.push({
                        path: '/PurchaseReturn',
                        query: {
                            data: 'PurchaseReturns'
                        }
                    })
                }
                else {
                    this.$router.go();
                }

                
            },
        },
        created: function () {

            this.$emit('input', this.$route.name);
            this.isFifo = localStorage.getItem('fIFO') == 'true' ? true : false;
        },
        mounted: function () {
            this.language = this.$i18n.locale;
            //this.options = ['Inclusive', 'Exclusive'];
            if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {
                this.options = ['Inclusive', 'Exclusive'];
            }
            else {
                this.options = ['شامل', 'غير شامل'];
            }
            if (this.$session.exists()) {
                this.raw = localStorage.getItem('IsProduction');
            }
            this.purchase.invoiceDate = moment().format("DD MM YYYY");
            if (this.$route.query.data == undefined) {
                this.AutoIncrementCode();
            }
            if (this.$route.query.data != undefined) {
                this.warehouse = this.$route.query.data;
            }
            this.purchase.date = moment().format("LLL");
        },
    };
</script>

<style scoped>
    .poHeading {
        font-size: 32px;
        font-style: normal;
        line-height: 37px;
        font-weight: 500;
        font-size: 24px;
        line-height: 26px;
        color: #3178F6;
    }

    .dateHeading {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.01em;
        text-align: left;
        color: #35353D;
    }

    .bottomBorder {
        padding-top: 24px !important;
        border-bottom: 1px solid #EFF4F7;
    }
</style>
