<template>
    <div class="row" v-if="isValid('CanViewDeduction')">
        <div class="col-lg-12 col-sm-12 ml-auto mr-auto" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">

            <div class="card">
                <div class="BorderBottom ml-2 mr-2 mt-3 mb-3">
                    <span class=" DayHeading">{{ $t('Deduction.Deduction') }}</span>
                </div>
                <div class="card-body">
                    <div class="row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label>{{ $t('Deduction.SearchByName') }}</label>
                                <div>
                                    <input type="text" class="form-control search_input" v-model="search" name="search" id="search" :placeholder="$t('Deduction.Search')" />
                                    <span class="fas fa-search search_icon"></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                            <a v-if="isValid('CanAddDeduction')" href="javascript:void(0)" class="btn btn-primary " style="margin-top:27px;" v-on:click="openmodel"><i class="fa fa-plus"></i> {{ $t('Deduction.AddNew') }} </a>
                            <router-link :to="'/HrSetup'">
                                <a href="javascript:void(0)" class="btn btn-outline-danger " style="margin-top:27px;">  <i class="fas fa-arrow-circle-left fa-lg"></i> </a>
                            </router-link>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                            <table class="table table-striped table-hover table_list_bg" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <thead v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                    <tr>
                                        <th>#</th>
                                        <th>
                                            {{ $t('Deduction.Code') }}
                                        </th>
                                        <th v-if="english=='true'">
                                            {{ $t('Deduction.NameEnglish') }}
                                        </th>
                                        <th v-if="isOtherLang()" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                            {{ $t('Deduction.NameArabic') }}
                                        </th>
                                        <th class="text-center">
                                            {{ $t('Deduction.AmountPercentage') }}
                                        </th>
                                        <th class="text-center">
                                            {{ $t('Deduction.Taxable') }}
                                        </th>
                                        <th>
                                            {{ $t('Status') }}
                                        </th>


                                    </tr>
                                </thead>
                                <tbody v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                    <tr v-for="(deduction ,index) in deductionList" v-bind:key="deduction.id">
                                        <td v-if="currentPage === 1">
                                            {{index+1}}
                                        </td>
                                        <td v-else>
                                            {{((currentPage*10)-10) +(index+1)}}
                                        </td>

                                        <td v-if="isValid('CanEditDeduction')">
                                            <strong>
                                                <a href="javascript:void(0)" v-on:click="EditDeduction(deduction.id)">{{deduction.code}}</a>
                                            </strong>
                                        </td>
                                        <td v-else>
                                            <strong>
                                                {{deduction.code}}
                                            </strong>
                                        </td>
                                        <td v-if="english=='true'">
                                            {{deduction.nameInPayslip}}
                                        </td>
                                        <td v-if="isOtherLang()">
                                            {{deduction.nameInPayslipArabic}}
                                        </td>
                                        <td class="text-center">
                                            {{deduction.amountType==2? currency : ''}} {{parseFloat(deduction.amount).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}} {{deduction.amountType==1? '%' : ''}}
                                        </td>
                                        <td class="text-center">
                                            <span v-if="($i18n.locale == 'en' ||isLeftToRight())"> {{deduction.taxPlan==1? 'Taxable' : 'Non Taxable'}}</span>
                                            <span v-else> {{deduction.taxPlan==1? 'خاضع للضريبة' : 'غير خاضعة للضريبة'}}</span>                                           
                                        </td>
                                        <td>{{deduction.active==true?$t('Active'):$t('De-Active')}}</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                    <div class="float-left">
                        <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                        <span v-else-if="currentPage===1 && rowCount < 10">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage===1 && rowCount >= 11  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                    </div>
                    <div class="float-right">
                        <div class="overflow-auto" v-on:click="GetBrandData()">
                            <b-pagination pills size="lg" v-model="currentPage"
                                          :total-rows="rowCount"
                                          :per-page="10"
                                          first-text="First"
                                          prev-text="Previous"
                                          next-text="Next"
                                          last-text="Last"></b-pagination>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <add-deduction :deduction="newDeduction"
                       :show="show"
                       v-if="show"
                       @close="IsSave"
                       :type="type" />

    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>


<script>
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        data: function () {
            return {
                currency: '',
                searchQuery: '',
                show: false,
                deductionList: [],
                newDeduction: {
                    id: '',
                    nameInPayslip: '',
                    nameInPayslipArabic: '',
                    amountType: '',
                    taxPlan: '',
                    code: '',
                    amount: 0,
                    active: true
                },
                type: '',
                search: '',
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                arabic: '',
                english: '',
            }
        },
        watch: {
            search: function () {
                this.GetDeductionData();
            }
        },
        methods: {
            IsSave: function () {
                this.show = false;
                this.GetDeductionData();
            },

            getPage: function () {
                this.GetDeductionData();
            },

            openmodel: function () {
                this.newDeduction = {
                    id: '00000000-0000-0000-0000-000000000000',
                    nameInPayslip: '',
                    nameInPayslipArabic: '',
                    amountType: '',
                    taxPlan: '',
                    code: '',
                    amount: 0,
                    active: true
                }
                this.show = !this.show;
                this.type = "Add";
            },

            GetDeductionData: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                root.$https.get('Payroll/DeductionList?searchTerm=' + this.search + '&pageNumber=' + this.currentPage, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.deductionList = response.data.results;
                        root.pageCount = response.data.pageCount;
                        root.rowCount = response.data.rowCount;
                        root.loading = false;
                    }
                    root.loading = false;
                });
            },

            EditDeduction: function (Id) {

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Payroll/DeductionDetail?Id=' + Id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data) {
                            root.newDeduction.id = response.data.id;
                            root.newDeduction.nameInPayslip = response.data.nameInPayslip;
                            root.newDeduction.nameInPayslipArabic = response.data.nameInPayslipArabic;
                            root.newDeduction.amount = response.data.amount;
                            root.newDeduction.code = response.data.code;
                            root.newDeduction.active = response.data.active;
                            
                            if ((root.$i18n.locale == 'en' || root.isLeftToRight())) {
                                root.newDeduction.amountType = response.data.amountType == 1 ? '% of Salary' : 'Fixed';
                                root.newDeduction.taxPlan = response.data.taxPlan == 1 ? 'Taxable' : 'Non Taxable';
                            }
                            else {
                                root.newDeduction.amountType = response.data.amountType == 1 ? '٪ من الراتب' : 'مثبت';
                                root.newDeduction.taxPlan = response.data.taxPlan == 1 ? 'خاضع للضريبة' : 'غير خاضعة للضريبة';
                            }
                            root.show = !root.show;
                            root.type = "Edit"
                        } else {
                            console.log("error: something wrong from db.");
                        }
                    },
                        function (error) {
                            this.loading = false;
                            console.log(error);
                        });

            }
        },

        created: function () {
            this.$emit('input', this.$route.name);
        },

        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.currency = localStorage.getItem('currency');
            this.GetDeductionData();

        }
    }
</script>