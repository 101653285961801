<template>
    <div class="Warehouse" v-if="isValid('CanViewPaymentOption')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
        <div class="row " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <div class="col-lg-12 col-sm-12 ">
                <div class="card ">
                    <div class="BorderBottom ml-4 mr-4 mt-3 mb-3">
                        <span class=" DayHeading">{{ $t('PaymentOptions.PaymentOptions') }}</span>
                    </div>
                  

                    <div class="card-body">
                        <div class="col-lg-12">
                            <div class="row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                    <!--<div class="form-group">
                                        <label>{{ $t('SearchByCurrency') }}</label>
                                        <div>
                                            <input type="text" class="form-control search_input" v-model="searchQuery" name="search" id="search" :placeholder="$t('Search')" />
                                            <span class="fas fa-search search_icon"></span>
                                        </div>
                                    </div>-->
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                                    <a v-if="isValid('CanAddPaymentOption')" href="javascript:void(0)" class="btn btn-primary" v-on:click="AddPaymentOptions"><i class="fa fa-plus"></i>  {{ $t('AddNew') }}</a>
                                    <router-link :to="'/FinancialSetup'">
                                        <a href="javascript:void(0)" class="btn btn-outline-danger ">  {{ $t('PaymentOptions.Close') }} </a>
                                    </router-link>
                                </div>

                            </div>
                            <div class="mt-2">
                                <div class=" table-responsive table-striped table-hover table_list_bg">
                                    <table class="table ">
                                        <thead class="m-0">
                                            <tr>
                                                <th>
                                                    #
                                                </th>
                                                <th class="text-center">
                                                    {{$t('PaymentOptions.Image')}}
                                                </th>
                                                <th v-if="english=='true'" class="text-center">
                                                    {{ $t('PaymentOptions.NameEn') |englishLanguage}}
                                                </th>
                                                <th v-if="isOtherLang()" class="text-center">
                                                    {{ $t('PaymentOptions.NameAr') |arabicLanguage}}
                                                </th>

                                                <th class="text-center">
                                                    {{ $t('PaymentOptions.Status') }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(details,index) in resultQuery" v-bind:key="details.id">
                                                <td>
                                                    {{index+1}}
                                                </td>
                                                <td class="text-center" v-if="details.image==null || details.image=='' || details.image==undefined"><img src="Product.png" style="width: 50px;" /></td>
                                                <td class="text-center" v-else><PaymentImageList v-bind:path="details.image" /></td>
                                                <td v-if="english=='true' " class="text-center">

                                                    <strong>
                                                        <a href="javascript:void(0)" v-if="isValid('CanEditPaymentOption')" v-on:click="EditPaymentOptions(details.id)">{{details.name}}</a>
                                                        <span  v-else>{{details.name}}</span>
                                                    </strong>
                                                </td>

                                                <td v-if="isOtherLang() " class="text-center">
                                                    <strong>
                                                        <a href="javascript:void(0)" v-if="isValid('CanEditPaymentOption')" v-on:click="EditPaymentOptions(details.id)">{{details.nameArabic}}</a>
                                                        <span  v-else>{{details.nameArabic}}</span>
                                                    </strong>

                                                </td>
                                                <td class="text-center">
                                                    {{details.isActive==true?$t('PaymentOptions.Active'):$t('PaymentOptions.De-Active')}}

                                                </td>



                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <paymentmodel :payment="newpayment"
                      :show="show"
                      v-if="show"
                      @close="show = false"
                      @RefreshList="RefreshList"
                      :type="type" />
    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        data: function () {
            return {
                searchQuery: '',
                show: false,
                newpayment: {
                    id: '',
                    name: '',
                    nameArabic: '',
                    image: '',
                    isActive: true
                },
                type: '',
                arabic: '',
                english: '',
                paymentOptionsList: [

                ]

            }
        },
        computed: {
            resultQuery: function () {
                var root = this;
                if (this.searchQuery) {
                    return this.paymentOptionsList.filter((pmt) => {
                        return root.searchQuery.toLowerCase().split(' ').every(v => pmt.name.toLowerCase().includes(v) || pmt.nameArabic.toLowerCase().includes(v))
                    })
                } else {
                    return root.paymentOptionsList;
                }
            },
        },
        methods: {
            AddPaymentOptions: function () {
                this.newpayment = {
                    id: '00000000-0000-0000-0000-000000000000',
                    name: '',
                    nameArabic: '',
                    image: '',
                    isActive: true
                }
                this.show = !this.show;
                this.type = "Add";
            },
            RefreshList: function () {
                this.$router.go();
            },
            GetPaymentOptionsData: function () {
                var root = this;
                var url = '/Product/PaymentOptionsList?isActive=false';
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get(url, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        
                        root.$store.dispatch('GetPaymentOptionsList', response.data.paymentOptions);
                        root.paymentOptionsList = response.data.paymentOptions;
                    }
                });
            },
            EditPaymentOptions: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Product/PaymentOptionsDetail?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data) {
                            root.newpayment.id = response.data.id;
                            root.newpayment.name = response.data.name;
                            root.newpayment.nameArabic = response.data.nameArabic;
                            root.newpayment.isActive = response.data.isActive;
                            root.newpayment.image = response.data.image;
                            root.show = !root.show;
                            root.type = "Edit"
                        } else {
                            console.log("error: something wrong from db.");
                        }
                    },
                        function (error) {
                            this.loading = false;
                            console.log(error);
                        });

            }
        },
        created: function () {
            this.$emit('input', this.$route.name);
        },
        mounted: function () {
            
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.GetPaymentOptionsData();
        }
    }
</script>