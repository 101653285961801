<template>
    <div class="row FontSizeSmall" v-if="isValid('Day Start')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
        <div class="col-lg-12 col-sm-12 ml-auto mr-auto" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <div class="card ">
                <div class="card-header">
                    <h6 class="card-title " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">{{ $t('StartYourDay') }}</h6>
                    <span style="color:red" v-if="isValid('Day Start') && iSupervisorLogin "></span>
                    <span style="color:red" v-else-if="isValid('Day Start') && !IsPermissionToStartDay ">{{$t('NotPermissionStartSupervisor')}}</span><br />
                    <span style="color:red" v-if="isValid('Day Close') && iSupervisorLogin "></span>
                    <span style="color:red" v-else-if="isValid('Day Close') && !IsPermissionToCloseDay">{{$t('NotPermissionEndSupervisor')}}</span>

                </div>
                <div class="card-body d-flex flex-row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                    <div class="col-md-8">
                        <a href="javascript:void(0)" class="btn btn-outline-primary " :disabled="isDayAlreadyStart" v-if="isValid('Day Start') && iSupervisorLogin " v-on:click="DayStart"><i class="fa fa-plus"></i> {{ $t('Dashboard.DayStart') }}</a>
                        <a href="javascript:void(0)" class="btn btn-outline-primary " :disabled="isDayAlreadyStart" v-else-if="isValid('Day Start') && IsPermissionToStartDay " v-on:click="DayStart"><i class="fa fa-plus"></i> {{ $t('Dashboard.DayStart') }}</a>
                        <a href="javascript:void(0)" class="btn btn-outline-primary " disabled v-else-if="isValid('Day Start') && !IsPermissionToStartDay " v-on:click="DayStart"><i class="fa fa-plus"></i> {{ $t('Dashboard.DayStart') }}</a>
                        <!--<a href="javascript:void(0)" class="btn btn-outline-primary " :disabled="!isDayAlreadyStart" v-on:click="DayRegister()" v-if="isValid('Day Register')"><i class="fa fa-plus"></i> {{ $t('DayRegister') }}</a>
                        <a href="javascript:void(0)" class="btn btn-outline-primary " :disabled="!isDayAlreadyStart" v-on:click="DayCounter()" v-if="isValid('Day Counter')"><i class="fa fa-plus"></i> {{ $t('DayCounter') }}  ({{dayCounter}})</a>-->
                        <a href="javascript:void(0)" class="btn btn-outline-primary " :disabled="!isDayAlreadyStart" v-on:click="TransferCounter()" v-if="IsTransferAllow"><i class="fa fa-plus"></i> {{ $t('Transfer') }} </a>
                        <a href="javascript:void(0)" :disabled="!isDayAlreadyStart " class="btn btn-outline-primary  " v-if="isValid('Day Close') && iSupervisorLogin" v-on:click="DayEnd(UserID)"><i class="fa fa-plus"></i> {{ $t('DayEnd') }}</a>
                        <a href="javascript:void(0)" :disabled="!isDayAlreadyStart " class="btn btn-outline-primary  " v-else-if="isValid('Day Close') && IsPermissionToCloseDay" v-on:click="DayEnd(UserID)"><i class="fa fa-plus"></i> {{ $t('DayEnd') }}</a>
                        <a href="javascript:void(0)" disabled class="btn btn-outline-primary  " v-else-if="isValid('Day Close') && !IsPermissionToCloseDay" v-on:click="DayEnd(UserID)"><i class="fa fa-plus"></i> {{ $t('DayEnd') }}</a>
                        <!--<a href="javascript:void(0)" class="btn btn-outline-primary  " v-on:click="DayEnd(UserID)"><i class="fa fa-plus"></i> {{ $t('Transfer') }}</a>-->
                        <router-link :to="'/StartScreen'"><a href="javascript:void(0)" class="btn btn-danger "> {{ $t('Close') }}</a></router-link>
                        <a href="javascript:void(0)" class="btn btn-primary  " :disabled="loginDisabled" v-on:click="SupervisorLogin()" v-if="!isSupervisor"> {{ $t('SupervisorLogin') }}</a>
                        <a href="javascript:void(0)" class="btn btn-primary  " :disabled="!loginDisabled" v-on:click="SupervisorLogout()" v-if="!isSupervisor"> {{ $t('LogOut') }}</a>
                        <!--<a href="javascript:void(0)" class="btn btn-primary  "  v-on:click="GetDetailOfInvoices" >Invoice Detail</a>-->
                        <!--<a href="javascript:void(0)" class="btn btn-primary  "  v-on:click="GetReport" >Report</a>-->

                    </div>
                    <div class="col-md-4" style=" margin-top: -50px;" v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? 'padding-left:50px;' : 'padding-right:50px;'">
                        <apexchart width="380" type="donut" :options="chartOptions" :series="series9"></apexchart>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header">
                    <h6 class="card-title " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">{{ $t('ActiveTerminals') }}</h6>

                </div>
                <div class="card-body" v-if="!loading">

                    <div class="col-lg-12">
                        <table class="table table-shopping">
                            <thead class="">
                                <tr>
                                    <th>#</th>
                                    <th>
                                        {{$t('Date')}}
                                    </th>
                                    <th>
                                        {{$t('FromTime')}}
                                    </th>
                                    <th>
                                        {{$t('CounterN')}}
                                    </th>
                                    <th>
                                        {{$t('StartBy')}}
                                    </th>
                                    <th>
                                        {{$t('StartFor')}}
                                    </th>
                                    <th class="text-center">
                                        {{$t('UserDashboard.OpeningCash')}}
                                    </th>
                                    <th class="text-center">
                                        {{$t('UserDashboard.CashInHand')}}
                                    </th>
                                    <th class="text-center">
                                        {{$t('UserDashboard.Expense')}}
                                    </th>
                                    <th class="text-center">
                                        {{$t('UserDashboard.Bank')}}
                                    </th>
                                    <th class="text-center">
                                        Return Amount
                                    </th>
                                    <th class="text-center">
                                        Return Count
                                    </th>

                                    <th class="text-center">
                                        {{$t('Total')}}
                                    </th>


                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(po,index) in dayStarts" v-bind:key="po.id">
                                    <td>
                                        {{index+1}}
                                    </td>
                                    <td>
                                        {{po.date}}
                                    </td>
                                    <td>
                                        {{po.fromTime}}
                                    </td>
                                    <td>
                                        {{po.counterName}}
                                    </td>
                                    <td>
                                        {{po.startTerminalBy}}
                                    </td>
                                    <td>
                                        {{po.startTerminalFor}}
                                    </td>
                                    <td class="text-center">
                                        {{parseFloat(po.openingCash).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                    </td>
                                    <td class="text-center">
                                        {{parseFloat(po.cashInHand+po.openingCash-po.draftExpense).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                    </td>
                                    <td class="text-center">
                                        {{parseFloat(po.expenseCash).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                    </td>
                                    <td class="text-center">
                                        {{parseFloat(po.bankAmount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                    </td>

                                    <td class="text-center">
                                        {{parseFloat(po.totalReturnValue).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                    </td>
                                    <td class="text-center">
                                        {{parseFloat(po.totalReturnCount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                    </td>

                                    <td class="text-center">
                                        {{parseFloat(po.totalCash).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
            <div class="card" v-if="isSupervisor || iSupervisorLogin" v-bind:key="randerList">
                <div class="card-header">
                    <h6 class="card-title " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">{{$t('InActiveTerminals')}}</h6>

                </div>
                <div class="card-body" v-if="!loading">
                    <div class="col-lg-12">
                        <table class="table table-shopping">
                            <thead class="">
                                <tr>
                                    <th>#</th>
                                    <th>
                                        {{$t('Date')}}
                                    </th>
                                    <th>
                                        {{$t('FromTime')}}
                                    </th>
                                    <th>
                                        {{$t('CounterN')}}
                                    </th>
                                    <th>
                                        {{$t('UserDashboard.StartUser')}}
                                    </th>
                                    <th class="text-center">
                                        {{$t('UserDashboard.Supervisor')}}
                                    </th>
                                    <th class="text-center">
                                        {{$t('DayStarts.CarryCash')}}
                                    </th>


                                    <th class="text-center">
                                        {{$t('UserDashboard.OpeningCash')}}
                                    </th>
                                    <th class="text-center">
                                        {{$t('UserDashboard.CashInHand')}}
                                    </th>
                                    <th class="text-center">
                                        {{$t('UserDashboard.Expense')}}
                                    </th>
                                    <th class="text-center">
                                        {{$t('DayStarts.EndTime')}}
                                    </th>
                                    <th class="text-center">
                                        {{$t('UserDashboard.EndUser')}}
                                    </th>

                                    <th class="text-center">
                                        {{$t('Total')}}
                                    </th>
                                    <th class="text-center">
                                        Receiving Amount
                                    </th>
                                    <th class="text-center">
                                        Action
                                    </th>


                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(po,index) in inactivedayStarts" v-bind:key="po.id">
                                    <td>
                                        {{index+1}}
                                    </td>
                                    <td>
                                        {{po.date}}
                                    </td>
                                    <td>
                                        {{po.fromTime}}
                                    </td>
                                    <td>
                                        {{po.counterName}}
                                    </td>
                                    <td>
                                        {{po.startTerminalBy}}
                                    </td>

                                    <td class="text-center">
                                        {{po.superVisorName}}
                                    </td>
                                    <td class="text-center">
                                        {{parseFloat(parseFloat(po.carryCash) - parseFloat(po.carryCash)).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                    </td>
                                    <td class="text-center">
                                        {{parseFloat(po.openingCash).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                    </td>
                                    <td class="text-center">
                                        {{parseFloat((po.cashInHand+po.openingCash)-po.draftExpense).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                    </td>
                                    <td class="text-center">
                                        {{parseFloat(po.expenseCash).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                    </td>
                                    <td class="text-center">
                                        {{po.toTime}}
                                    </td>
                                    <td class="text-center">
                                        {{po.dayEndUser}}
                                    </td>

                                    <td class="text-center">
                                        {{parseFloat(po.totalCash).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                    </td>
                                    <td class="text-center">
                                        {{po.receivingAmount}}
                                    </td>
                                    <td class="text-center">
                                        <a href="javascript:void(0)" title="Cash Receiving" class="btn   btn-primary btn-sm" v-on:click="ReceivingCash(po.totalCash,po.id)">Receiving</a>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>

            <div>
                <div v-for="(day,index) in dayWiseList" :key="index">
                    <div class="card" v-bind:key="randerList">
                        <div class="card-header">
                            <h6 class="card-title " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">OverDue ({{day[0].date}} {{day[0].fromTime}} - {{day[0].date}} {{day[0].toTime}} )</h6>

                        </div>
                        <div class="card-body">
                            <div class="col-lg-12">
                                <table class="table table-shopping">
                                    <thead class="">
                                        <tr>
                                            <th>#</th>
                                            <th>
                                                {{$t('Date')}}
                                            </th>
                                            <th>
                                                {{$t('FromTime')}}
                                            </th>
                                            <th>
                                                {{$t('CounterN')}}
                                            </th>
                                            <th>
                                                {{$t('UserDashboard.StartUser')}}
                                            </th>
                                            <th class="text-center">
                                                {{$t('UserDashboard.Supervisor')}}
                                            </th>
                                            <th class="text-center">
                                                {{$t('DayStarts.CarryCash')}}
                                            </th>


                                            <th class="text-center">
                                                {{$t('UserDashboard.OpeningCash')}}
                                            </th>
                                            <th class="text-center">
                                                {{$t('UserDashboard.CashInHand')}}
                                            </th>
                                            <th class="text-center">
                                                {{$t('UserDashboard.Expense')}}
                                            </th>
                                            <th class="text-center">
                                                {{$t('DayStarts.EndTime')}}
                                            </th>
                                            <th class="text-center">
                                                {{$t('UserDashboard.EndUser')}}
                                            </th>

                                            <th class="text-center">
                                                {{$t('Total')}}
                                            </th>
                                            <th class="text-center">
                                                Receiving Amount
                                            </th>
                                            <th class="text-center" v-if="isSupervisor || iSupervisorLogin ">
                                                Action
                                            </th>


                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(po,index) in day" v-bind:key="po.id">
                                            <td>
                                                {{index+1}}
                                            </td>
                                            <td>
                                                {{po.date}}
                                            </td>
                                            <td>
                                                {{po.fromTime}}
                                            </td>
                                            <td>
                                                {{po.counterName}}
                                            </td>
                                            <td>
                                                {{po.startTerminalBy}}
                                            </td>

                                            <td class="text-center">
                                                {{po.superVisorName}}
                                            </td>
                                            <td class="text-center">
                                                {{parseFloat(parseFloat(po.carryCash) - parseFloat(po.carryCash)).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                            </td>
                                            <td class="text-center">
                                                {{parseFloat(po.openingCash).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                            </td>
                                            <td class="text-center">
                                                {{parseFloat((po.cashInHand+po.openingCash)-po.draftExpense).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                            </td>
                                            <td class="text-center">
                                                {{parseFloat(po.expenseCash).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                            </td>
                                            <td class="text-center">
                                                {{po.toTime}}
                                            </td>
                                            <td class="text-center">
                                                {{po.dayEndUser}}
                                            </td>

                                            <td class="text-center">
                                                {{parseFloat(po.totalCash).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                            </td>
                                            <td class="text-center">
                                                {{po.receivingAmount}}
                                            </td>
                                            <td class="text-center">
                                                <a href="javascript:void(0)" title="Cash Receiving" class="btn   btn-primary btn-sm " v-if="isSupervisor || iSupervisorLogin " v-on:click="ReceivingCash(po.totalCash,po.id)">Receiving</a>
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="card" v-if="isSupervisor || iSupervisorLogin " v-bind:key="randerList">
                <div class="card-header">
                    <h6 class="card-title " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">{{$t('TotalOfDay')}}</h6>

                </div>
                <div class="card-body">
                    <div class="col-lg-12">
                        <table class="table table-shopping">
                            <thead class="">
                                <tr>

                                    <th class="text-center">
                                        {{$t('UserDashboard.OpeningCash')}}
                                    </th>
                                    <th class="text-center">
                                        {{$t('UserDashboard.CashInHand')}}
                                    </th>


                                    <th class="text-center">
                                        {{$t('UserDashboard.Expense')}}
                                    </th>
                                    <th class="text-center">
                                        {{$t('UserDashboard.Bank')}}
                                    </th>

                                    <th class="text-center">
                                        {{$t('Total')}}
                                    </th>


                                </tr>
                            </thead>
                            <tbody>
                                <tr>



                                    <td class="text-center">
                                        {{parseFloat(openingCash).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                    </td>
                                    <td class="text-center">
                                        {{parseFloat((cashInHand+openingCash)-draftExpense).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                    </td>
                                    <td class="text-center">
                                        {{parseFloat(expense).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                    </td>
                                    <td class="text-center">
                                        {{parseFloat(bank).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                    </td>

                                    <td class="text-center">
                                        {{parseFloat(totalCash).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>

            <div class="card" v-if="isSupervisor || iSupervisorLogin" v-bind:key="randerList">
                <div class="card-header">
                    <h6 class="card-title " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">{{$t('TransferHistory')}}</h6>

                </div>
                <div class="card-body">
                    <div class="col-lg-12">
                        <table class="table table-shopping">
                            <thead class="">
                                <tr>
                                    <th>#</th>
                                    <th>
                                        {{$t('Date')}}
                                    </th>
                                    <th>
                                        {{$t('UserDashboard.TransferTime')}}
                                    </th>
                                    <th>
                                        {{$t('CounterN')}}
                                    </th>
                                    <th>
                                        {{$t('UserDashboard.TransferBy')}}
                                    </th>
                                    <th>
                                        {{$t('UserDashboard.TransferTo')}}
                                    </th>
                                    <th class="text-center">
                                        {{$t('UserDashboard.OpeningCash')}}
                                    </th>
                                    <th class="text-center">
                                        {{$t('UserDashboard.CashInHand')}}
                                    </th>
                                    <th class="text-center">
                                        {{$t('UserDashboard.Expense')}}
                                    </th>
                                    <th class="text-center">
                                        {{$t('UserDashboard.Bank')}}
                                    </th>

                                    <th class="text-center">
                                        {{$t('Total')}}
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(po,index) in transferHistories" v-bind:key="po.id">
                                    <td>
                                        {{index+1}}
                                    </td>
                                    <td>
                                        {{po.date}}
                                    </td>
                                    <td>
                                        {{po.fromTime}}
                                    </td>
                                    <td>
                                        {{po.counterName}}
                                    </td>
                                    <td>
                                        {{po.startTerminalBy}}
                                    </td>
                                    <td>
                                        {{po.startTerminalFor}}
                                    </td>
                                    <td class="text-center">
                                        {{parseFloat(po.openingCash).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                    </td>
                                    <td class="text-center">
                                        {{parseFloat((po.cashInHand+po.openingCash)-po.expenseCash).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                    </td>
                                    <td class="text-center">
                                        {{parseFloat(po.expenseCash).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                    </td>
                                    <td class="text-center">
                                        {{parseFloat(po.bankAmount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                    </td>

                                    <td class="text-center">
                                        {{parseFloat(po.totalCash).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
        <adddaystartmodel :openingCash="openingCash"
                          :isFirstUser="isFirstUser"
                          :isExpenseDay="isExpenseDay"
                          :show="show"
                          v-if="show "
                          @close="show = false, isDayStart()"
                          :type="type" />


        <adddayendmodel @close="show1 = false, isDayStart()"
                        :counterCode="counterCode"
                        :cashInHand="cashInHand"
                        :openingCash="openingCash"
                        :bank="bank"
                        :dayStartId="dayStartId"
                        :expense="expense"
                        :outStandingBalance="outStandingBalance"
                        :counterId="counterId"
                        :dayWiseList="dayWiseList"
                        :show="show1"
                        v-if="show1"
                        :type="type" />

        <day-register-modal @close="show2 = false"
                            :counterCode="counterCode"
                            :counterId="counterId"
                            :show="show2"
                            v-if="show2"
                            :type="type" />

        <day-counter-modal @close="show3 = false"
                           :counterCode="counterCode"
                           :counterList="counterList"
                           :counterId="counterId"
                           :show="show3"
                           v-if="show3"
                           :type="type" />

        <supervisor-login-model @close="onCloseEvent"
                                :isFlushData=" false"
                                :show="show4"
                                :isReset="false"
                                v-if="show4" />

        <transfer-login-model :openingCash="openingCash"
                              :isFirstUser="isFirstUser"
                              :isExpenseDay="isExpenseDay"
                              :counterCode="counterCode"
                              :show="show5"
                              v-if="show5"
                              @close="show5 = false"
                              :type="type" />
        <cash-receiving-model :paidAmountProp="paidAmount"
                              :inActiveDayId="inActiveDayId"
                              v-if="receivingCash"
                              :show="receivingCash"
                              @close="receivingCash = false"
                              :type="type" />


    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>

<script>
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        data: function () {
            return {
                searchQuery: '',
                show: false,
                show1: false,
                show2: false,
                show3: false,
                show4: false,
                show5: false,
                receivingCash: false,
                paidAmount: 0,
                inActiveDayId: '00000000-0000-0000-0000-000000000000',
                IsTransferAllow: false,
                DayStartDisable: false,
                counterCode: null,
                loginDisabled: false,
                counterId: '00000000-0000-0000-0000-000000000000',
                openingCash: '',
                type: '',
                type1: '',
                CompanyID: '',
                UserID: '',
                employeeId: '',
                expense: '',
                draftExpense: '',
                postExpense: '',
                cashInHand: '',
                bank: '',
                dayWiseList: [],
                dayStartId: '00000000-0000-0000-0000-000000000000',
                isDayAlreadyStart: false,
                loading: false,
                dayCounter: '0',
                counterList: [],
                dayStarts: [],
                inactivedayStarts: [],
                outStandingBalance: 0,
                transferHistories: [],
                permissionToStartExpenseDay: false,
                isExpenseDay: false,
                isFirstUser: false,
                isSupervisor: false,
                IsPermissionToStartDay: false,
                IsPermissionToCloseDay: false,
                iSupervisorLogin: false,
                iSupervisorStartDay: false,
                randerList: 0,
                totalCash: 0,

                options: {
                    xaxis: {
                        categories: ['OPENING CASH', 'EXPENSE', 'BANK', 'CASH IN HAND', 'Total Cash', 'TOTAL']
                    }
                },
                series: [{
                    name: '',
                    data: []
                }],

                chartOptions: {
                    labels: ['OPENING CASH', 'EXPENSE', 'BANK', 'CASH IN HAND', 'TOTAL Cash', 'TOTAL']
                },
                series9: [],
            }
        },
        methods: {
            ReceivingCash: function (amount, id) {
                this.paidAmount = amount;
                this.inActiveDayId = id;
                this.receivingCash = !this.receivingCash
            },
            onCloseEvent: function (data) {

                this.loginDisabled = data;
                if (data) {

                    var root = this;
                    var token = '';
                    if (this.$session.exists()) {
                        token = localStorage.getItem('token');
                    }
                    localStorage.setItem('IsSupervisor', true);
                    localStorage.setItem('iSupervisorLogin', true);

                    root.iSupervisorLogin = true;
                    root.$https.get('/Product/IsDayStart?userId=' + this.UserID + '&employeeId=' + this.employeeId + '&isSupervisor=' + true, { headers: { "Authorization": `Bearer ${token}` } })
                        .then(function (response) {
                            if (response.data.isDayStart) {
                                root.isFirstUser = response.data.isFirstUser;
                                root.dayStarts = response.data.dayStarts;
                                root.transferHistories = response.data.transferHistories;
                                root.bank = response.data.bank;
                                root.cashInHand = response.data.cashInHand;
                                root.expense = response.data.expense;
                                root.draftExpense = response.data.draftExpense;
                                root.postExpense = response.data.postExpense;
                                root.openingCash = response.data.openingCash;
                                root.openingCash = response.data.openingCash;
                                root.totalCash = response.data.totalCash;
                                root.inactivedayStarts = response.data.inacticeDayStarts;
                                root.outStandingBalance = response.data.outStandingBalance;

                                root.series[0].data = [];
                                root.series9 = [];
                                root.series[0].data.push(root.openingCash);
                                root.series[0].data.push(root.expense);
                                root.series[0].data.push(root.bank);
                                root.series[0].data.push(root.cashInHand);
                                root.series[0].data.push(root.totalCash);
                                root.series9 = root.series[0].data;
                            }

                            root.randerList++;
                            root.loading = false;

                        },
                            function (error) {
                                root.loading = false;
                                console.log(error);
                            });
                }
                this.show4 = false
            },
            SupervisorLogout: function () {
                localStorage.setItem('iSupervisorLogin', false);
                localStorage.setItem('IsSupervisor', false);

                this.$router.go();
                //localStorage.setItem('SupervisorId', null);
                //localStorage.setItem('SupervisorUserName', null);
                //localStorage.setItem('SupervisorPassword', null);
                this.loginDisabled = false;

                this.$swal({
                    title: 'success',
                    text: "Logout Successfully",
                    type: 'success',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500,
                    timerProgressBar: true,
                });
            },
            GetDetailOfInvoices: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Product/GetDetailOfInvoices', { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data) {
                            console.log("error: something wrong from db.");
                        }

                    },
                        function (error) {
                            this.loading = false;
                            console.log(error);
                        });
            },
            GetReport: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Product/GetDayStartReport', { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data) {
                            console.log("error: something wrong from db.");
                        }

                    },
                        function (error) {
                            this.loading = false;
                            console.log(error);
                        });
            },
            DayStart: function () {

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                if (this.permissionToStartExpenseDay) {
                    root.$swal({
                        title: "Confirm!",
                        text: "Are You Sure? Want To Start Day Expense!",
                        type: 'warning',
                        icon: 'warning',
                        showConfirmButton: true,
                        confirmButtonText: 'Yes, I am sure!',
                        cancelButtonText: "No, Start Normal!",
                        showCancelButton: true,
                        confirmButtonColor: '#014c8c',
                        cancelButtonColor: '#ef8157'
                    }).then(function (response) {
                        if (response != undefined) {
                            if (response.isConfirmed) {

                                root.$https.get('/Product/IsDayStart?userId=' + root.UserID + '&employeeId=' + root.employeeId + '&isSupervisor=' + root.isSupervisor, { headers: { "Authorization": `Bearer ${token}` } })
                                    .then(function (response) {
                                        if (response.data.isDayStart) {
                                            root.isFirstUser = response.data.isFirstUser;
                                            console.log(response.date);
                                        }
                                        else {
                                            root.dayStartId = '00000000-0000-0000-0000-000000000000';
                                            root.$https.get('/Product/GetCounterInformation?userId=' + root.UserID + '&employeeId' + root.employeeId + '&isDayStart=' + true, { headers: { "Authorization": `Bearer ${token}` } })
                                                .then(function (response) {
                                                    if (response.data.counterCode != null) {
                                                        root.counterCode = response.data.counterCode;
                                                        root.openingCash = response.data.openingCash;
                                                        //root.counterId = response.data.counterId;
                                                        root.isExpenseDay = true;

                                                    } else {
                                                        console.log(response.date);
                                                    }
                                                },
                                                    function (error) {
                                                        root.loading = false;
                                                        console.log(error);
                                                    }).then(function () {
                                                        if (root.counterCode != null && root.counterCode != '' && root.counterCode != undefined) {
                                                            root.show = !root.show;
                                                            root.type = "Add";
                                                        }

                                                    });
                                        }
                                    },
                                        function (error) {
                                            root.loading = false;
                                            console.log(error);
                                        }).catch(error => {

                                            console.log(error)
                                            root.$swal.fire(
                                                {
                                                    icon: 'error',
                                                    title: 'Something Went Wrong!',
                                                    text: error.response.data,

                                                    showConfirmButton: false,
                                                    timer: 5000,
                                                    timerProgressBar: true,
                                                });

                                            root.loading = false
                                        })
                            }
                            else {
                                root.$https.get('/Product/IsDayStart?userId=' + root.UserID + '&employeeId=' + root.employeeId + '&isSupervisor=' + root.isSupervisor, { headers: { "Authorization": `Bearer ${token}` } })
                                    .then(function (response) {
                                        if (response.data.isDayStart) {
                                            root.isFirstUser = response.data.isFirstUser;
                                            console.log(response.date);
                                        }
                                        else {
                                            root.dayStartId = '00000000-0000-0000-0000-000000000000';

                                            root.$https.get('/Product/GetCounterInformation?userId=' + root.UserID + '&employeeId' + root.employeeId + '&isDayStart=' + true, { headers: { "Authorization": `Bearer ${token}` } })
                                                .then(function (response) {
                                                    if (response.data.counterCode != null) {
                                                        root.counterCode = response.data.counterCode;
                                                        root.openingCash = response.data.openingCash;
                                                        //root.counterId = response.data.counterId;

                                                        root.isExpenseDay = false;
                                                    } else {
                                                        console.log(response.date);
                                                    }
                                                },
                                                    function (error) {
                                                        root.loading = false;
                                                        console.log(error);
                                                    }).then(function () {
                                                        if (root.counterCode != null && root.counterCode != '' && root.counterCode != undefined) {
                                                            root.show = !root.show;
                                                            root.type = "Add";
                                                        }

                                                    });
                                        }
                                    },
                                        function (error) {
                                            root.loading = false;
                                            console.log(error);
                                        }).catch(error => {

                                            console.log(error)
                                            root.$swal.fire(
                                                {
                                                    icon: 'error',
                                                    title: 'Something Went Wrong!',
                                                    text: error.response.data,

                                                    showConfirmButton: false,
                                                    timer: 5000,
                                                    timerProgressBar: true,
                                                });

                                            root.loading = false
                                        })
                            }

                        }
                    });
                }
                else {

                    root.$https.get('/Product/IsDayStart?userId=' + root.UserID + '&employeeId=' + root.employeeId + '&isSupervisor=' + root.isSupervisor, { headers: { "Authorization": `Bearer ${token}` } })
                        .then(function (response) {
                            
                            if (response.data.isDayStart) {
                                root.isFirstUser = response.data.isFirstUser;
                                console.log(response.date);
                            }
                            else {
                                root.isFirstUser = response.data.isFirstUser;
                                root.dayStarts = response.data.dayStarts;
                                root.dayStartId = '00000000-0000-0000-0000-000000000000';
                                root.show = !root.show;
                                root.type = "Add";
                                //root.$https.get('/Product/GetCounterInformation?userId=' + root.UserID + '&employeeId' + root.employeeId + '&isDayStart=' + true, { headers: { "Authorization": `Bearer ${token}` } })
                                //    .then(function (response) {
                                //        if (response.data.counterCode != null) {
                                //            root.counterCode = response.data.counterCode;
                                //            root.openingCash = response.data.openingCash;
                                //            root.counterId = response.data.counterId;
                                //            root.isExpenseDay = false;
                                //        } else {
                                //            console.log(response.date);
                                //        }
                                //    },
                                //        function (error) {
                                //            root.loading = false;
                                //            console.log(error);
                                //        }).then(function () {
                                //            if (root.counterCode != null && root.counterCode != '' && root.counterCode != undefined) {
                                //                root.show = !root.show;
                                //                root.type = "Add";
                                //            }

                                //        });
                            }
                        },
                            function (error) {
                                root.loading = false;
                                console.log(error);
                            });
                }
            },
            TransferCounter: function () {
                this.counterCode = localStorage.getItem('CounterCode');

                this.show5 = !this.show5
            },
            DayEnd: function () {
                var root = this;
                debugger
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https.get('/Product/IsDayStart?userId=' + this.UserID + '&employeeId' + this.employeeId + '&isSupervisor=' + root.isSupervisor, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data.isDayStart) {
                            root.dayStartId = response.data.dayStartId;
                            root.isFirstUser = response.data.isFirstUser;
                            root.cashInHand = response.data.cashInHand;
                            //root.counterId = response.data.counterId;
                            root.bank = response.data.bank;
                            root.openingCash = response.data.openingCash;
                            root.show1 = !root.show1;
                            root.type = "Add";

                        } else {
                            console.log(response.date);
                        }
                    },
                        function (error) {
                            root.loading = false;
                            console.log(error);
                        });
            },

            DayRegister: function () {
                var root = this;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https.get('/Product/IsDayStart?userId=' + this.UserID + '&employeeId' + this.employeeId, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data.isDayStart) {


                            root.$https.get('/Product/GetCounterInformation?userId=' + root.UserID + '&employeeId' + root.employeeId + '&isDayEnd=' + true,
                                { headers: { "Authorization": `Bearer ${token}` } })
                                .then(function (response) {
                                    root.counterCode = response.data.counterCode;
                                    //root.counterId = response.data.counterId;

                                },
                                    function (error) {
                                        root.loading = false;
                                        console.log(error);
                                    }).then(function () {
                                        if (root.counterCode != null && root.counterCode != '' && root.counterCode != undefined) {
                                            root.show2 = !root.show2;
                                            root.type = "Add";
                                        }

                                    });
                        } else {
                            root.$swal({
                                title: root.$t('Error'),
                                text: root.$t('DayNotStart'),
                                type: 'error',
                                confirmButtonClass: "btn btn-info",
                                buttonsStyling: false
                            });
                        }
                    },
                        function (error) {
                            root.loading = false;
                            console.log(error);
                        });
            },

            DayCounter: function () {
                var root = this;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https.get('/Product/IsDayStart?userId=' + this.UserID + '&employeeId' + this.employeeId, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data.isDayStart) {

                            root.$https.get('/Product/GetCounterInformation?userId=' + root.UserID + '&employeeId' + root.employeeId + '&isDayEnd=' + true,
                                { headers: { "Authorization": `Bearer ${token}` } })
                                .then(function (response) {
                                    
                                    if (response.data.counterCode != null) {
                                        root.counterCode = response.data.counterCode;
                                        //    root.counterId = response.data.counterId;
                                    } else {
                                        console.log(response.date);
                                    }
                                },
                                    function (error) {
                                        root.loading = false;
                                        console.log(error);
                                    }).then(function () {
                                        if (root.counterCode != null && root.counterCode != '' && root.counterCode != undefined) {
                                            root.show3 = !root.show3;
                                        }

                                    });
                        } else {
                            root.$swal({
                                title: root.$t('Error'),
                                text: root.$t('DayNotStart'),
                                type: 'error',
                                confirmButtonClass: "btn btn-info",
                                buttonsStyling: false
                            });
                        }
                    },
                        function (error) {
                            root.loading = false;
                            console.log(error);
                        });
            },
            SupervisorLogin: function () {
                this.show4 = !this.show4;
            },

            isDayStart: function () {
                var root = this;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.loading = true;
                
                root.$https.get('/Product/IsDayStart?userId=' + this.UserID + '&employeeId=' + this.employeeId + '&isSupervisor=' + root.isSupervisor, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        debugger
                        if (response.data.isDayStart) {

                            root.isDayAlreadyStart = true;
                            //root.dayCounter = response.data.dayCount.length;
                            //root.counterList = response.data.dayCount;
                            root.isFirstUser = response.data.isFirstUser;
                            root.dayStarts = response.data.dayStarts;
                            root.transferHistories = response.data.transferHistories;
                            root.bank = response.data.bank;
                            root.cashInHand = response.data.cashInHand;
                            root.expense = response.data.expense;
                            root.draftExpense = response.data.draftExpense;
                            root.postExpense = response.data.postExpense;
                            root.openingCash = response.data.openingCash;
                            root.openingCash = response.data.openingCash;
                            root.totalCash = response.data.totalCash;
                            root.inactivedayStarts = response.data.inacticeDayStarts;
                            root.outStandingBalance = response.data.outStandingBalance;
                            root.dayWiseList = response.data.dayWiseList;
                            //localStorage.setItem('token', response.data.token);
                            localStorage.setItem('IsExpenseDay', response.data.isExpenseDay);

                            root.loadChartsDetails();

                        }
                        else {
                            root.dayStarts = response.data.dayStarts;
                            root.transferHistories = response.data.transferHistories;
                            root.isDayAlreadyStart = false;
                            root.inactivedayStarts = response.data.inacticeDayStarts;
                            root.outStandingBalance = response.data.outStandingBalance;
                            root.permissionToStartExpenseDay = response.data.isExpenseDay;
                            root.bank = response.data.bank;
                            root.cashInHand = response.data.cashInHand;
                            root.expense = response.data.expense;
                            root.draftExpense = response.data.draftExpense;
                            root.postExpense = response.data.postExpense;
                            root.openingCash = response.data.openingCash;
                            root.openingCash = response.data.openingCash;
                            root.totalCash = response.data.totalCash;
                            root.dayWiseList = response.data.dayWiseList;
                            localStorage.setItem('IsExpenseDay', response.data.isExpenseDay);
                        }
                        root.loading = false;

                    },
                        function (error) {
                            root.loading = false;
                            console.log(error);
                        });
            },
            loadChartsDetails() {

                var root = this;
                root.series = [{
                    name: '',
                    data: []
                }];
                if (root.isSupervisor) {
                    root.series[0].data.push(root.openingCash);
                    root.series[0].data.push(root.expense);
                    root.series[0].data.push(root.bank);
                    root.series[0].data.push(root.cashInHand);
                    root.series[0].data.push(root.cashInHand + root.openingCash);
                    root.series[0].data.push(root.totalCash);
                    root.series9 = root.series[0].data;
                }
                else {

                    root.series[0].data.push(root.dayStarts[0].openingCash);
                    root.series[0].data.push(root.dayStarts[0].expenseCash);
                    root.series[0].data.push(root.dayStarts[0].bankAmount);
                    root.series[0].data.push(root.dayStarts[0].cashInHand);
                    root.series[0].data.push(root.dayStarts[0].cashInHand + root.dayStarts[0].openingCash);
                    root.series[0].data.push(root.dayStarts[0].totalCash);
                    root.series9 = root.series[0].data;
                }

                root.loadChart = true;
            }

        },
        mounted: function () {


            if (this.$session.exists()) {
                var IsSupervisor = localStorage.getItem('IsSupervisor');

                if (IsSupervisor == 'true') {
                    this.isSupervisor = true
                }
                else {
                    this.isSupervisor = false
                }
                this.CompanyID = localStorage.getItem('CompanyID');
                this.UserID = localStorage.getItem('UserID');
                this.counterId = localStorage.getItem('CounterId');
                this.employeeId = localStorage.getItem('EmployeeId');
                if (this.employeeId == null) {
                    this.DayStartDisable = true;
                }
                this.isDayStart();

                this.IsTransferAllow = localStorage.getItem('IsTransferAllow') == 'true' ? true : false;
                this.IsPermissionToStartDay = localStorage.getItem('IsPermissionToStartDay') == 'true' ? true : false;
                this.IsPermissionToCloseDay = localStorage.getItem('IsPermissionToCloseDay') == 'true' ? true : false;

                //var supervisorId = localStorage.getItem('SupervisorId');
                //if (supervisorId != null) {
                //    this.loginDisabled = true;
                //    this.DayStartDisable = false
                //}
            }
        }
    }
</script>
<style scoped>
    .FontSizeSmall {
        font-size: 12px;
    }
</style>