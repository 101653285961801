<template>
    <modal :show="show">
        <div style="margin-bottom:0px" class="card">
            <div class="card-body">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="modal-header">
                            <h5 class="modal-title " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'arabicfont'" id="myModalLabel">{{ $t('forgetPassword.RecoverPassword') }}</h5>
                        </div>
                        <div class="card-body">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0">
                                <label class="labelHeading" style="margin-top:15px">  Email</label>
                                <input id="email" v-model="$v.emailTo.$model" name="email" type="text" class="form-control" v-bind:placeholder="$t('forgetPassword.EnterYourEmail')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <p  v-if="customError != ''" class="error text-danger">
                                    {{customError}}
                                </p>
                            </div>


                        </div>
                        <div class="modal-footer justify-content-right">
                            <button type="button" class="btn btn-primary " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'arabicfont'" v-on:click="SendEmail" v-bind:disabled="$v.$invalid">{{ $t('forgetPassword.Submit') }}</button>
                            <button type="button" class="btn btn-outline-danger " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'arabicfont'" v-on:click="close()">{{ $t('forgetPassword.ForgotClose') }}</button>
                        </div>
                        <div class="card-footer col-md-3" v-if="loading">
                            <loading :active.sync="loading" :can-cancel="true" :is-full-page="true"></loading>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
    import { email, required } from "vuelidate/lib/validators"
    import Loading from 'vue-loading-overlay';
    export default {
        components: {
            Loading
        },
        props: ['show'],
        data: function () {
            return {
                render: 0,
                emailTo: '',
                customError: '',
                loading: false
            }
        },
        validations: {
            emailTo: { email, required }
        },
        methods: {
            close: function () {
                this.$emit('close');
            },
            SendEmail: function () {
                var root = this;
                root.loading = true;
                root.customError = ''
                var url = '/account/SendUserEmail?emailTo=' + this.emailTo + '&appLink=' + this.$ClientIP;
                this.$https.get(url).then(function (response) {
                    if (response.data != "Error") {
                        root.loading = false;
                        root.$swal({
                            title: "Success!",
                            text: "An Email has sent to your personal Email Address regarding Reset Link.!",
                            type: 'success',
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 900,
                            timerProgressBar: true,
                        });
                        root.close();
                    }
                    if (response.data == "Error") {

                        root.customError = "Please Enter Correct Email";
                        root.loading = false;
                    }
                }).catch(error => {
                    console.log(error)
                    root.customError = "Something thing went wrong";
                    root.loading = false;
                })
            }
        }
    }
</script>
<style scoped>
    .btn-outline-primary.disabled, .btn-outline-primary:disabled {
        color: #014c8c;
    }
</style>
