<template>
    <div>

        <div class="row">
            <div class="col-lg-12 col-sm-12 ml-auto mr-auto">

                <div class="card">
                    <div class="BorderBottom ml-2 mr-2 mt-3 mb-3">
                        <span class="DayHeading">  {{ $t('PriceRecord.PriceRecord') }}</span>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                <div class="form-group">
                                    <label>{{ $t('PriceRecord.SearchByProductName') }}</label>
                                    <div>
                                        <input type="text" class="form-control search_input" v-model="search" name="search" id="search" :placeholder="$t('PriceRecord.Search')" />
                                        <span class="fas fa-search search_icon"></span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="mt-2" v-for="details in productList" v-bind:key="details.id">
                                    <div class="card-header">
                                        <h6 class="card-title"> {{details.code+" "+details.englishName+" "+details.arabicName}}</h6>
                                        <table class="table  table-hover add_table_list_bg" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        {{ $t('PriceRecord.ProductName') }}
                                                    </th>

                                                    <th>
                                                        {{ $t('PriceRecord.LabelName') }}

                                                    </th>

                                                    <th>
                                                        {{ $t('PriceRecord.Price') }}

                                                    </th>
                                                    <th>
                                                        {{ $t('PriceRecord.Status') }}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="priceRecord in details.priceRecordLookupModel " v-bind:key="priceRecord.id">
                                                    <td>
                                                        <strong>
                                                            <a href="javascript:void(0)" v-on:click="EditPriceRecord(priceRecord.id,details)">  {{details.englishName}}</a>
                                                        </strong>
                                                      

                                                    </td>
                                                    <td>
                                                        {{priceRecord.priceLabelName}}

                                                    </td>
                                                    <td>
                                                        {{priceRecord.price}}

                                                    </td>

                                                    <td>{{priceRecord.isActive==true?$t('Active'):$t('De-Active')}}</td>


                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colspan="4" class="text-center">
                                                        <button title="Add New Account" class="btn btn-primary  btn-icon" v-on:click="openmodel(details)">
                                                            <i class="nc-icon nc-simple-add"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>


                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="float-left">
                            <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                            <span v-else-if="currentPage===1 && rowCount < 100">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                            <span v-else-if="currentPage===1 && rowCount >= 101  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*100}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                            <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*100}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                            <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*100)-99}} {{ $t('Pagination.to') }} {{currentPage*100}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                            <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*100)-99}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                        </div>
                        <div class="float-right">
                            <div class="overflow-auto" v-on:click="GetProductData()" v-if="($i18n.locale == 'en' ||isLeftToRight())">
                                <b-pagination pills size="lg" v-model="currentPage"
                                              :total-rows="rowCount"
                                              :per-page="10"
                                              first-text="First"
                                              prev-text="Previous"
                                              next-text="Next"
                                              last-text="Last"></b-pagination>
                            </div>
                            <div class="overflow-auto" v-on:click="GetProductData()" v-else>
                                <b-pagination pills size="lg" v-model="currentPage"
                                              :total-rows="rowCount"
                                              :per-page="10"
                                              first-text="الأولى"
                                              prev-text="السابقة"
                                              next-text="التالية"
                                              last-text="الأخيرة"></b-pagination>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <AddPriceRecord :priceRecord="newPriceRecord"
                        :show="show"
                        v-if="show"
                        @close="IsSave"
                        :type="type">

        </AddPriceRecord>
    </div>
</template>

<script>
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        name: 'PriceRecord',
        data: function () {
            return {
                arabic: '',
                english: '',
                show: false,
                type: '',
                showPrint: false,
                productData: '',
                productList: [

                ],
                warehouseId: '00000000-0000-0000-0000-000000000000',
                search: '',
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                searchQuery: '',
                image: '',
                loading: true,
                renderedImage: 0,
                advanceFilters: false,

                printRender: 0,
                colorlist: [],
                newPriceRecord: {
                    id: '00000000-0000-0000-0000-000000000000',
                    priceLabelingId: '00000000-0000-0000-0000-000000000000',
                    product: [],
                    price: 0,
                    isActive: true
                },
            }
        },
        watch: {
            search: function (val) {
                this.GetProductData(val, 1);
            },

        },
        methods: {
            IsSave: function () {

                this.show = false;

                this.GetProductData(this.search, this.currentPage, this.warehouseId);
            },

            openmodel: function (product) {
                
                this.newPriceRecord = {
                    id: '00000000-0000-0000-0000-000000000000',
                    priceLabelingId: '00000000-0000-0000-0000-000000000000',
                    product: product,
                    price: 0,
                    isActive: true

                }
                this.show = !this.show;
                this.type = "Add";
            },
            EditPriceRecord: function (Id, details) {


                var root = this;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Product/PriceRecordDetail?Id=' + Id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data) {
                            
                            root.newPriceRecord.id = response.data.id;
                            root.newPriceRecord.priceLabelingId = response.data.priceLabelingId;
                            root.newPriceRecord.price = response.data.price;
                            root.newPriceRecord.product = details;
                            root.newPriceRecord.isActive = response.data.isActive;
                            root.show = !root.show;
                            root.type = "Edit"
                        } else {
                            console.log("error: something wrong from db.");
                        }
                    },
                        function (error) {
                            this.loading = false;
                            console.log(error);
                        });

            },

            getPage: function () {


                this.GetProductData(this.search, this.currentPage, this.warehouseId);
            },
            GetProductData: function () {

                var root = this;



                var url = '/Product/PriceRecordList?searchTerm=' + this.search + '&pageNumber=' + this.currentPage + '&wareHouseId=' + this.warehouseId + '&pageSize=100';
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https.get(url, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        
                        root.productList = response.data.results;
                        root.pageCount = response.data.pageCount;
                        root.rowCount = response.data.rowCount;
                        root.loading = false;
                    }
                    root.loading = false;
                });
            },
           
        },
        created: function () {

        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            if (this.$route.query.data != undefined) {
                this.currentPage = this.$route.query.data;
                this.GetProductData();
            }
            else {
                this.GetProductData(this.search, 1);
            }
        }
    }
</script>
<style scoped>
    .higlight {
        background-color: red;
    }
</style>