<template>
    <div v-if="isValid('CanUpdateCompanyInfo')">
        <div class="col-md-12 ml-auto mr-auto m-4" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <div class="card">

                <div class="card-body" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                    <div class="row">
                        <div class="card-header DayHeading col-xs-12 col-sm-12 col-md-12 col-lg-12 " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                            <span style="font-size:30px;font-weight:bold">{{ $t('CompanyProfile.BusinessInfo')}}</span>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-if="english=='true'">
                            <label>{{ $t('CompanyProfile.BusinessNameInEnglish') |englishLanguage}} :<span class="LabelColour"> *</span></label>
                            <div v-bind:class="{'has-danger' : $v.companyProfile.nameInEnglish.$error}">
                                <input class="form-control " disabled v-model="$v.companyProfile.nameInEnglish.$model" />
                                <span v-if="$v.companyProfile.nameInEnglish.$error" class="error text-danger">
                                    <span v-if="!$v.companyProfile.nameInEnglish.required">{{ $t('CompanyProfile.NameRequired') }}</span>
                                    <span v-if="!$v.companyProfile.nameInEnglish.maxLength"> {{ $t('CompanyProfile.Namelength') }}</span>
                                </span>
                            </div>
                        </div>

                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-if="isOtherLang()">
                            <label>{{ $t('CompanyProfile.BusinessName(InArabic)') |arabicLanguage}} :<span class="LabelColour"> *</span></label>
                            <div v-bind:class="{'has-danger' : $v.companyProfile.nameInArabic.$error}">
                                <input class="form-control text-right " v-bind:disabled="isDisable" v-model="$v.companyProfile.nameInArabic.$model" />
                                <span v-if="$v.companyProfile.nameInArabic.$error" class="error text-danger">
                                    <span v-if="!$v.companyProfile.nameInArabic.required"> {{ $t('CompanyProfile.NameRequired') }}</span>
                                    <span v-if="!$v.companyProfile.nameInArabic.maxLength"> {{ $t('CompanyProfile.Namelength') }}</span>
                                </span>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-if="english=='true'">
                            <label>{{ $t('CompanyProfile.BusinessType(InEnglish)') |englishLanguage}} :<span class="LabelColour"> *</span></label>
                            <div v-bind:class="{'has-danger' : $v.companyProfile.categoryInEnglish.$error}">
                                <input class="form-control " v-bind:disabled="isDisable" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" v-model="$v.companyProfile.categoryInEnglish.$model" />
                                <span v-if="$v.companyProfile.categoryInEnglish.$error" class="error text-danger">
                                    <span v-if="!$v.companyProfile.categoryInEnglish.required"> {{ $t('CompanyProfile.NameRequired') }}</span>
                                    <span v-if="!$v.companyProfile.categoryInEnglish.maxLength"> {{ $t('CompanyProfile.NameMaximum') }}</span>
                                </span>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-if="isOtherLang()">
                            <label>{{ $t('CompanyProfile.BusinessType(InArabic)') |arabicLanguage}} :<span class="LabelColour"> *</span></label>
                            <div v-bind:class="{'has-danger' : $v.companyProfile.categoryInArabic.$error}">
                                <input class="form-control text-right " v-bind:disabled="isDisable" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" v-model="$v.companyProfile.categoryInArabic.$model" />
                                <span v-if="$v.companyProfile.categoryInArabic.$error" class="error text-danger">
                                    <span v-if="!$v.companyProfile.categoryInArabic.required"> {{ $t('CompanyProfile.NameRequired') }}</span>
                                    <span v-if="!$v.companyProfile.categoryInArabic.maxLength"> {{ $t('CompanyProfile.NameMaximum') }}</span>
                                </span>
                            </div>
                        </div>


                    </div>

                    <div class="row">
                        <div class="card-header DayHeading col-sm-12 " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                            <span style="font-size:30px;font-weight:bold">{{ $t('CompanyProfile.CompanyProfile') }}</span>
                        </div>
                        <div class="col-sm-8">
                            <div class="row">
                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4" v-if="english=='true'">
                                    <label>{{ $t('CompanyProfile.CompanyName(InEnglish)') |englishLanguage}}  :<span class="LabelColour"> *</span></label>
                                    <div v-bind:class="{'has-danger' : $v.companyProfile.companyNameEnglish.$error}">
                                        <input class="form-control " v-bind:disabled="isDisable" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" v-model="$v.companyProfile.companyNameEnglish.$model" />
                                        <span v-if="$v.companyProfile.companyNameEnglish.$error" class="error text-danger">
                                            <span v-if="!$v.companyProfile.companyNameEnglish.required"> {{ $t('CompanyProfile.NameRequired') }}</span>
                                            <span v-if="!$v.companyProfile.companyNameEnglish.maxLength"> {{ $t('CompanyProfile.NameMaximum') }}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4" v-if="isOtherLang()">
                                    <label>{{ $t('CompanyProfile.CompanyName(InArabic)') |arabicLanguage}}:<span class="LabelColour"> *</span></label>
                                    <div v-bind:class="{'has-danger' : $v.companyProfile.companyNameArabic.$error}">
                                        <input class="form-control text-right " v-bind:disabled="isDisable" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" v-model="$v.companyProfile.companyNameArabic.$model" />
                                        <span v-if="$v.companyProfile.companyNameArabic.$error" class="error text-danger">
                                            <span v-if="!$v.companyProfile.companyNameArabic.required"> {{ $t('CompanyProfile.NameRequired') }}</span>
                                            <span v-if="!$v.companyProfile.companyNameArabic.maxLength"> {{ $t('CompanyProfile.NameMaximum') }}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                                    <label>{{ $t('CompanyProfile.CommercialRegistrationNo') }} :<span class="LabelColour"> *</span></label>
                                    <div v-bind:class="{'has-danger' : $v.companyProfile.companyRegNo.$error}">
                                        <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" disabled v-model="$v.companyProfile.companyRegNo.$model" />
                                        <span v-if="$v.companyProfile.companyRegNo.$error" class="error text-danger">
                                            <span v-if="!$v.companyProfile.companyRegNo.required">{{ $t('CompanyProfile.RegistrationRequired') }} </span>
                                            <span v-if="!$v.companyProfile.companyRegNo.maxLength"> {{ $t('CompanyProfile.RegistrationLength') }}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                                    <label for="companyProfile.TaxRegisterNo">{{ $t('CompanyProfile.VATNo') }} : <span class="LabelColour"> *</span></label>
                                    <div v-bind:class="{'has-danger' : $v.companyProfile.vatRegistrationNo.$error}">
                                        <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" disabled v-model="$v.companyProfile.vatRegistrationNo.$model" />
                                        <span v-if="$v.companyProfile.vatRegistrationNo.$error" class="error text-danger">
                                            <span v-if="!$v.companyProfile.vatRegistrationNo.required">{{ $t('CompanyProfile.VatNoRequired') }}</span>
                                            <span v-if="!$v.companyProfile.vatRegistrationNo.maxLength">{{ $t('CompanyProfile.VatMaximum') }}</span>
                                        </span>
                                    </div>
                                </div>

                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                                    <label>{{ $t('CompanyProfile.contactNo')}}:</label>
                                    <div>
                                        <input class="form-control  " v-bind:disabled="isDisable" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" v-model="companyProfile.phoneNo" />

                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 ">
                                    <label>{{ $t('CompanyProfile.Email')}}:</label>
                                    <div>
                                        <input class="form-control  " v-bind:disabled="isDisable" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" v-model="companyProfile.companyEmail" />

                                    </div>
                                </div>

                                <div class="col-sm-6" v-if="english=='true'">
                                    <label for="companyProfile.address">{{ $t('CompanyProfile.AddressEn') }}  : <span class="LabelColour"> *</span></label>
                                    <div v-bind:class="{'has-danger' : $v.companyProfile.addressInEnglish.$error}">
                                        <textarea disabled class="form-control" v-model="$v.companyProfile.addressInEnglish.$model" />
                                        <span v-if="$v.companyProfile.addressInEnglish.$error" class="error text-danger">
                                            <span v-if="!$v.companyProfile.addressInEnglish.required">{{ $t('CompanyProfile.AddressRequired') }}</span>
                                            <span v-if="!$v.companyProfile.addressInEnglish.maxLength">{{ $t('CompanyProfile.AddressMaximum') }}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-sm-6" v-if="isOtherLang()">
                                    <label for="companyProfile.address">{{ $t('CompanyProfile.AddressAr') }} : <span class="LabelColour"> *</span></label>
                                    <div v-bind:class="{'has-danger' : $v.companyProfile.addressInArabic.$error}">
                                        <textarea class="form-control text-right" v-bind:disabled="isDisable" v-model="$v.companyProfile.addressInArabic.$model" />
                                        <span v-if="$v.companyProfile.addressInArabic.$error" class="error text-danger">
                                            <span v-if="!$v.companyProfile.addressInArabic.required">{{ $t('CompanyProfile.AddressRequired') }}</span>
                                            <span v-if="!$v.companyProfile.addressInArabic.maxLength">{{ $t('CompanyProfile.AddressMaximum') }}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 mt-2  card" :key="renderedImage" style="height: 200px;padding:15px">
                            <AddProductImage v-on:picPath="getImage" v-bind:path="image" />

                        </div>
                        <div class="col-sm-12">
                            <div v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-align:right' : 'text-align:left' ">
                                <button class="btn btn-primary mr-2" v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? 'float:left' : 'float:right' " v-on:click="Attachment()">
                                    {{ $t('AddSaleOrder.Attachment') }}
                                </button>

                                <button v-bind:disabled="$v.companyProfile.$invalid" class="btn btn-primary   mr-2" v-on:click="SaveCompanyAccountSetup()">{{ $t('CompanyProfile.Update') }}</button>
                                <button class="btn btn-danger   mr-2" v-on:click="BackToList()">{{ $t('CompanyProfile.btnClear') }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <bulk-attachment :attachmentList="companyProfile.attachmentList" :show="show" v-if="show" @close="attachmentSave" />

    </div>
    <div v-else> <acessdenied></acessdenied></div>

</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import { required, maxLength, requiredIf } from 'vuelidate/lib/validators';
    export default ({

        mixins: [clickMixin],
        data: function () {
            return {
                companyProfile: {
                    id: '00000000-0000-0000-0000-000000000000',
                    nameInArabic: '',
                    nameInEnglish: '',
                    categoryInEnglish: '',
                    categoryInArabic: '',
                    CompanyRegNo: '',
                    vatRegistrationNo: '',
                    companyNameArabic: '',
                    companyNameEnglish: '',
                    addressInEnglish: '',
                    addressInArabic: '',
                    logoPath: '',
                    countryInArabic: '',
                    countryInEnglish: '',
                    cityInEnglish: '',
                    cityInArabic: '',
                    landLine: '',
                    companyEmail: '',
                    phoneNo: '',
                    attachmentList:[]
                },
                companyId: '',
                image: '',
                renderedImage: 0,
                isDisable: false,
                isDelete: false,
                show: false,
                arabic: '',
                english: '',
            }
        },
        validations: {
            companyProfile:
            {
                nameInEnglish: {

                    maxLength: maxLength(50)
                },
                nameInArabic: {
                    required: requiredIf((x) => {
                        if (x.nameInEnglish == '' || x.nameInEnglish == null)
                            return true;
                        return false;
                    }),
                    maxLength: maxLength(50)
                },

                companyNameEnglish: {

                    maxLength: maxLength(50)
                },
                companyNameArabic: {
                    required: requiredIf((x) => {
                        if (x.companyNameEnglish == '' || x.companyNameEnglish == null)
                            return true;
                        return false;
                    }),
                    maxLength: maxLength(50)
                },

                companyRegNo: {
                    required,
                    maxLength: maxLength(50)
                },
                vatRegistrationNo: {
                    required,
                    maxLength: maxLength(50)
                },

                categoryInEnglish: {

                    maxLength: maxLength(50)
                },
                categoryInArabic: {
                    required: requiredIf((x) => {
                        if (x.categoryInEnglish == '' || x.categoryInEnglish == null)
                            return true;
                        return false;
                    }),
                    maxLength: maxLength(50)
                },

                addressInEnglish: {
                    maxLength: maxLength(300)
                },
                addressInArabic: {
                    required: requiredIf((x) => {
                        if (x.addressInEnglish == '' || x.addressInEnglish == null)
                            return true;
                        return false;
                    }),
                    maxLength: maxLength(300)
                },

            }
        },
        methods: {
            Attachment: function () {
                this.show = true;
            },

            attachmentSave: function (attachment) {
                this.companyProfile.attachmentList = attachment;
                this.show = false;
            },

            DeleteAttachment(path) {


                var token = '';
                var root = this;
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.get('/Contact/DeleteAttachment?path=' + path, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data == true) {

                        root.renderedImage++;
                        root.isDelete = false;
                        root.image = null;
                        root.company.logoPath = null;

                    }
                });
            },
            getBase64Image: function (path) {

                var root = this;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https
                    .get('/Contact/GetBaseImage?filePath=' + path, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                        if (response.data != null) {

                            root.image = response.data;

                            root.renderedImage++;

                            root.isDelete = true;
                        }
                    });
            },
            getImage: function (value) {
                this.companyProfile.logoPath = value;
                this.isDelete = true;
            },
            BackToList: function () {
                this.$router.push('/StartScreen')


            },
            SaveCompanyAccountSetup: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                this.$https.post('/Company/SaveLocation', this.companyProfile, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        
                        root.$swal({
                            title: root.$t('CompanyProfile.Saved'),
                            text: root.$t('CompanyInfo.SavedSuccessfully'),
                            type: 'success',
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1500,
                            timerProgressBar: true,
                            confirmButtonClass: "btn btn-success",
                            buttonsStyling: false
                        });
                        root.$router.push('/StartScreen')

                    }
                });
            },
            GetCompanyDetails: function (Id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                this.$https.get('/Company/EditCompany?Id=' + Id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {

                        root.companyProfile.id = response.data.id;
                        root.companyProfile.nameInEnglish = response.data.nameEnglish;
                        root.companyProfile.nameInArabic = response.data.nameArabic;

                        root.companyProfile.cityInEnglish = response.data.cityEnglish;
                        root.companyProfile.cityInArabic = response.data.cityArabic;
                        root.companyProfile.addressInEnglish = response.data.addressEnglish;
                        root.companyProfile.addressInArabic = response.data.addressArabic;

                        root.companyProfile.countryInEnglish = response.data.countryEnglish;
                        root.companyProfile.landLine = response.data.landLine;
                        root.companyProfile.countryInArabic = response.data.countryArabic;

                        root.companyProfile.categoryInEnglish = response.data.categoryEnglish;
                        root.companyProfile.categoryInArabic = response.data.categoryArabic;

                        root.companyProfile.companyRegNo = response.data.comercialRegNo;
                        root.companyProfile.vatRegistrationNo = response.data.vatRegistrationNo;

                        root.companyProfile.companyNameEnglish = response.data.companyNameEnglish;
                        root.companyProfile.companyNameArabic = response.data.companyNameArabic;
                        root.companyProfile.logoPath = response.data.logoPath;
                        root.companyProfile.companyEmail = response.data.companyEmail;
                        root.companyProfile.phoneNo = response.data.phoneNo;
                        root.companyProfile.attachmentList = response.data.attachmentList;



                        if (response.data.logoPath != null) {
                            root.getBase64Image(response.data.logoPath);
                        }


                    }
                });
            }
        },
        created: function () {
            this.$emit('input', this.$route.name);
        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');

            this.isDisable = true;
            this.companyId = localStorage.getItem('CompanyID');
            this.GetCompanyDetails(this.companyId);

        }
    })

</script>