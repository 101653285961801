<template>
    <div class="col-md-12 ml-auto mr-auto" v-if="isValid('CanViewDetailPurchaseReturn')" v-bind:style="$i18n.locale == 'ar' ? languageChange('en') : languageChange('ar')">
        <div class="card" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <div class="card-body">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-lg-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <h5>{{ $t('PurchaseReturnView.PurchaseReturn') }} - {{purchase.registrationNo}}</h5>
                                </div>
                                <div class="col-lg-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'arabicLanguage' : 'text-left'">
                                    <span>
                                        {{purchase.date}}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-6">
                                    {{ $t('PurchaseReturnView.Supplier') }}
                                </div>
                                <div class="col-lg-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                                    {{ purchase.supplierName }}
                                </div>

                                <div class="col-lg-6">
                                    {{ $t('PurchaseReturnView.SupplierInvoiceNumber') }}
                                </div>
                                <div class="col-lg-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                                    {{ purchase.invoiceNo }}
                                </div>

                                <div class="col-lg-6">
                                    {{ $t('PurchaseReturnView.PurchaseOrderDate') }}
                                </div>
                                <div class="col-lg-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                                    {{ purchase.invoiceDate }}
                                </div>

                                <div class="col-lg-6">
                                    {{ $t('PurchaseReturnView.WareHouse') }}
                                </div>
                                <div class="col-lg-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                                    {{ purchase.wareHouseName }}
                                </div>

                                <div class="col-lg-6">
                                    {{ $t('PurchaseReturnView.TaxMethod') }}
                                </div>
                                <div class="col-lg-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                                    {{purchase.taxMethod}}
                                </div>

                                <div class="col-lg-6">
                                    {{ $t('PurchaseReturnView.VAT%') }}
                                </div>
                                <div class="col-lg-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                                    {{purchase.taxRatesName}}
                                </div>
                            </div>

                        </div>
                    </div>
                    <br />
                    <purchase-view-item :key="rander1" :purchase="purchase" :raw="purchase.isRaw" :taxMethod="purchase.taxMethod" :taxRateId="purchase.taxRateId" @input="SavePurchaseItems" />

                    <div class="row">
                        <div class="col-md-6 ">
                            <button class="btn btn-primary mr-2" v-on:click="Attachment()">
                                {{ $t('PurchaseView.Attachment') }}
                            </button>
                        </div>
                        <div class="col-md-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                            <button class="btn btn-danger  mr-2"
                                    v-on:click="goToPurchase">
                                {{ $t('PurchaseReturnView.Cancel') }}
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <bulk-attachment :documentid="purchase.id" :show="isAttachshow" v-if="isAttachshow" @close="attachmentSaved" />
    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    //import Loading from 'vue-loading-overlay';
  import moment from "moment";
    import "vue-loading-overlay/dist/vue-loading.css";
    import { required } from "vuelidate/lib/validators";
    export default {
        mixins: [clickMixin],
        data: function () {
            return {
                isAttachshow: false,
                rendered: 0,
                registrationNo: "",
                purchase: {
                    id: "00000000-0000-0000-0000-000000000000",
                    date: "",
                    registrationNo: "",
                    supplierId: "",
                    invoiceNo: "",
                    invoiceDate: "",
                    purchaseOrder: "",
                    wareHouseId: "",
                    isRaw: false,
                    purchaseOrderItems: [],
                },
                raw: '',
                rander: 0,
                rander1: 0,
                purchaseinvoicedropdown: "",
                loading: false,
                language: 'Nothing',
                supplierRender: 0,
                options: [],
            };
        },
        validations: {
            purchase: {
                date: { required },
                registrationNo: { required },
                supplierId: { required },
                invoiceNo: {},
                invoiceDate: {},
                wareHouseId: {},
                purchaseOrderItems: { required },
            },
        },
        methods: {
            Attachment: function () {
                this.isAttachshow = true;
            },

            attachmentSaved: function () {
                this.isAttachshow = false;
            },

            ChangeSupplier: function () {
                this.supplierRender++;
            },

            languageChange: function (lan) {
                if (this.language == lan) {
                    if (this.purchase.id == '00000000-0000-0000-0000-000000000000') {

                        var getLocale = this.$i18n.locale;
                        this.language = getLocale;

                        this.$router.go('/addproduct');
                    }
                    else {
                        this.$swal({
                            title: 'Error!',
                            text: 'You cannot Change language during update, otherwise your current page data will be lose',
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            icon: 'error',
                            timer: 4000,
                            timerProgressBar: true,
                        });
                    }
                }


            },

            GetData: function (id) {
                var root = this;
                var token = "";
                if (root.$session.exists()) {
                    token = localStorage.getItem("token");
                }
                var isMultiUnit = localStorage.getItem('IsMultiUnit') == 'true' ? true : false;
                root.$https.get('/PurchasePost/PurchasePostDetail?id=' + id + '&isReturnView=' + true + '&isMultiUnit=' + isMultiUnit, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        
                        root.purchase = response.data;
                        root.purchase.purchaseOrderItems = response.data.purchasePostItems;
                        root.rander++;
                        root.rander1++;
                    }
                });
            },

            SavePurchaseItems: function (purchaseOrderItems) {
                this.purchase.purchaseOrderItems = purchaseOrderItems;
            },

            goToPurchase: function () {
                
                this.$router.push({
                    path: '/PurchaseReturn',
                    query: {
                        data: 'PurchaseReturns'
                    }
                })
            },
        },
        mounted: function () {
            
            this.language = this.$i18n.locale;
            if (this.$session.exists()) {
                this.raw = localStorage.getItem('IsProduction');
            }

            if (this.$route.query.data != undefined) {
                this.purchase = this.$route.query.data;
                this.purchase.date = moment(this.purchase.date).format("LLL");
                this.purchase.purchaseOrderItems = this.$route.query.data.purchasePostItems;
            }

        },
    };
</script>
