<template>
    <modal :show="show" :modalLarge="true">
        <div style="margin-bottom:0px" class="card" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <div class="card-body">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">
                        <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                            <div class="row">
                                <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8">
                                    <div>
                                        <span class="Heading1">{{ $t('ChequeBook.ChequeBook') }}</span>
                                    </div>

                                </div>
                                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 text-right pt-2">
                                    <div style="">
                                        <span>{{chequeBook.date}}</span>
                                    </div>
                                </div>


                            </div>

                            <div class="row ">
                                <div class="form-group has-label col-sm-6 " v-bind:class="{'has-danger' : $v.chequeBook.bookNo.$error}">
                                    <label class="text  font-weight-bolder">{{ $t('ChequeBook.BookNo') }}:<span class="LabelColour"> *</span> </label>
                                    <input class="form-control"  v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.chequeBook.bookNo.$model" type="text" />
                                </div>

                                <div class="form-group has-label col-sm-6 " v-bind:class="{'has-danger' : $v.chequeBook.noOfCheques.$error}">
                                    <label class="text  font-weight-bolder"> {{ $t('ChequeBook.NoOfCheques') }}:<span class="LabelColour"> *</span> </label>
                                    <input class="form-control" v-on:input="LastNo" v-bind:disabled="type" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.chequeBook.noOfCheques.$model" type="number" />
                                </div>
                                <div class="form-group has-label col-sm-6 " v-bind:class="{'has-danger' : $v.chequeBook.startingNo.$error}">
                                    <label class="text  font-weight-bolder">{{ $t('ChequeBook.StartingNo') }}:<span class="LabelColour"> *</span> </label>
                                    <input class="form-control" v-bind:disabled="type" v-on:input="LastNo" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.chequeBook.startingNo.$model" type="number" />
                                </div>

                                <div class="form-group has-label col-sm-6 " v-bind:class="{'has-danger' : $v.chequeBook.lastNo.$error}">
                                    <label class="text  font-weight-bolder">{{ $t('ChequeBook.LastNo') }}:<span class="LabelColour"> *</span> </label>
                                    <input class="form-control" disabled v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.chequeBook.lastNo.$model" type="number" />
                                </div>

                                <div class="col-lg-12" v-if="chequeBook.id!='00000000-0000-0000-0000-000000000000'">
                                    <div class="mt-2">

                                        <table class="table table-shopping" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                            <thead class="">
                                                <tr>
                                                    <th>#</th>
                                                    <th class="text-center ">
                                                        {{ $t('ChequeBook.BookNo') }}
                                                    </th>
                                                    <th class="text-center ">
                                                        {{ $t('ChequeBook.SerialNo') }}
                                                    </th>
                                                    <th class="text-center ">
                                                        {{ $t('ChequeBook.ChequeNo') }}
                                                    </th>
                                                  
                                                    <th class="text-center ">
                                                        {{ $t('ChequeBook.Status') }}
                                                    </th>
                                                  


                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(region,index) in chequeBook.chequeBookItems" v-bind:key="region.id">
                                                    <td>
                                                        {{index+1}}
                                                    </td>
                                                    <td class="text-center ">
                                                        {{region.bookNo}}

                                                    </td>
                                                    <!--<td class="text-center ">
                                                        <strong>
                                                            <a href="javascript:void(0)" v-on:click="EditRegion(region.id)">{{region.serialNo}} </a>
                                                        </strong>
                                                    </td>-->
                                                <td class="text-center ">
                                                    {{region.serialNo}}

                                                </td>
                                                    <td class="text-center ">
                                                        {{region.chequeNo}}

                                                    </td>


                                                    <td class="text-center ">
                                                        {{region.isActive==true?$t('Active'):$t('De-Active')}}
                                                    </td>
                                                   



                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>


                            </div>
                        </div>

                        <div v-if="!loading">
                            <div class="modal-footer justify-content-right">
                                <button type="button" class="btn btn-primary  " v-on:click="SaveLoanRecovery" v-bind:disabled="$v.chequeBook.$invalid"> {{ $t('ChequeBook.Save') }}</button>
                                <button type="button" class="btn btn-danger  mr-3 " v-on:click="close(false)">{{ $t('ChequeBook.Cancel') }}</button>
                            </div>
                        </div>
                        <div v-else>
                            <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import moment from "moment";

    import 'vue-loading-overlay/dist/vue-loading.css';
    import { required } from "vuelidate/lib/validators"
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        mixins: [clickMixin],
        props: ['show', 'chequeBook', 'loanDetail','type'],
        components: {
            Loading
        },
        data: function () {
            return {

                currency: '',
                arabic: '',
                english: '',
                render: 0,
                dateRender: 0,
                loading: false,
            }
        },
        validations: {
            chequeBook: {
                bookNo: {
                    required
                },

                

                noOfCheques: {
                    required

                },
                startingNo: {
                    required

                },
                lastNo: {
                    required

                },

            }
        },
        methods: {

            LastNo: function () {
                
              
                this.chequeBook.lastNo = parseInt(this.chequeBook.startingNo) + parseInt(this.chequeBook.noOfCheques);

                this.chequeBook.lastNo = this.chequeBook.lastNo - 1;
                //this.chequeBook.lastNo = j + this.chequeBook.lastNo;
                

            },
            close: function (x) {
                
                if (x == true)
                    this.$emit('close', x);
                else {
                    this.$emit('close', false);
                }

            },
            SaveLoanRecovery: function () {
                
                var root = this;
                this.loading = true;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                if (this.chequeBook.lastNo <= this.chequeBook.startingNo) {
                    root.$swal({
                        title: "Error!",
                        text: "Last No not less than or equal to Starting No!",
                        type: 'error',
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 1500,
                        timerProgressBar: true,
                    });
                    return;
                }
                //if (this.chequeBook.noOfCheques < this.chequeBook.lastNo - this.chequeBook.startingNo) {
                //    root.$swal({
                //        title: "Error!",
                //        text: "You have only Create!" + this.chequeBook.noOfCheque+" of Cheques",
                //        type: 'error',
                //        icon: 'error',
                //        showConfirmButton: false,
                //        timer: 1500,
                //        timerProgressBar: true,
                //    });
                //    return;
                //}
                
                this.chequeBook.remaining = this.chequeBook.noOfCheques ;
               
                this.$https.post('/Payroll/SaveChequeBookInformation', this.chequeBook, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        
                        if (response.data.isSuccess == true) {
                            if (root.type != "Edit") {

                                root.$swal({
                                    title: "Saved!",
                                    text: "Saved Successfully!",
                                    type: 'success',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 1500,
                                    timerProgressBar: true,
                                });

                                root.close(true);
                            }
                            else {

                                root.$swal({
                                    title: "Update!",
                                    text: "Update Successfully!",
                                    type: 'success',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 1500,
                                    timerProgressBar: true,
                                });
                                root.close(true);

                            }
                        }
                        else {
                            root.$swal({
                                title: "Error!",
                                text: "Your LoanRecovery Name  Already Exist!",
                                type: 'error',
                                icon: 'error',
                                showConfirmButton: false,
                                timer: 1500,
                                timerProgressBar: true,
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Something Went Wrong!',
                                text: error.response.data,

                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false
                    })
                    .finally(() => root.loading = false);
            }
        },
        mounted: function () {
          


            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.currency = localStorage.getItem('currency');
            this.chequeBook.date = moment().format('LLL');



        }
    }
</script>
<style scoped>
    .input-group-append .input-group-text, .input-group-prepend .input-group-text {
        background-color: #e3ebf1;
        border: 1px solid #e3ebf1;
        color: #000000;
    }

    .input-group .form-control {
        border-left: 1px solid #e3ebf1;
    }

        .input-group .form-control:focus {
            border-left: 1px solid #3178F6;
        }

    .input-group-text {
        border-radius: 0;
    }

    .Heading1 {
        font-size: 27px !important;
        font-style: normal;
        font-weight: 600;
        color: #3178F6;
    }

    .Heading2 {
        font-size: 20px !important;
        font-style: normal;
        color: black;
    }

    .SpanColor {
        font-size: 15px !important;
        font-style: normal;
        font-weight: 600;
    }
</style>


