<template>
    <div class="row" v-if="isValid('CanRestoreData')">
        <div class="col-lg-6 col-sm-12 ml-auto mr-auto">
            <div class="card ">
                <div class="card-header">
                    <h4 class="card-title text-center DayHeading"> Restore Database</h4>
                    <div class="row">
                        <div class="col-md-12 col-lg-12 pb-3">
                            <div class="mb-3">
                                <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <label><span>Backup Path *: <small>(without file name)</small></span></label>
                                    <div class="form-group">
                                        <input class="form-control" v-model="path" type="text" placeholder="Example: C:\FolderName" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" />
                                    </div>
                                </div>

                                <div class="custom-file">
                                    <input type="file" class="file-input" @change="saveFile" id="inputGroupFile01">
                                    <label class="custom-file-label" for="inputGroupFile01">{{fileName}}</label>
                                </div>
                            </div>

                            <div class="form-group text-center">


                                <a href="javascript:void(0)" class="btn btn-primary  " v-on:click="restoreData" :disabled="loading1 || path == ''">Restore Database</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <loading :name="loading1" :active.sync="loading1"
                     :can-cancel="true"
                     :is-full-page="true"></loading>

        </div>

    </div>
    <div v-else>  <acessdenied></acessdenied></div>

</template>

<script>
    import 'vue-loading-overlay/dist/vue-loading.css';
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        data: function () {
            return {
                loading1: false,
                fileName: 'Choose file',
                path: '',
            }
        },
        methods: {
            getBackUpPath: function () {
                var root = this;
                this.loading1 = true;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.loading1 = true;

                root.$https
                    .get('/System/GetBackUpPath', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                        if (response.status == 200) {
                            root.path = response.data;
                            root.loading1 = false;

                        }
                    });
            },
            saveFile(e) {
                if (e.currentTarget.files.length == 0) {
                    this.fileName = 'choose file';
                    return;
                }

                var name = e.currentTarget.files[0].name;
                if (name.split('.')[1] != "bak") {
                    this.$swal({
                        title: "Error!",
                        text: "Please upload bak file.",
                        type: 'error',
                        confirmButtonClass: "btn btn-error",
                        buttonStyling: false,
                        icon: 'error'

                    });
                }
                this.fileName = name;

                console.log(e);
            },
            restoreData: function () {
                var root = this;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.loading1 = true;
                root.$https
                    .get('/System/Restore?fileName=' + this.fileName + '&path=' + this.path, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                        if (response.status == 200) {
                            root.$swal({
                                title: "Success!",
                                text: "Database restore successfully",
                                type: 'error',
                                confirmButtonClass: "btn btn-Success",
                                buttonStyling: false,
                                icon: 'success'

                            });
                        }

                        root.loading1 = false;

                    }, (e) => {
                        root.loading1 = false;
                        console.log(e);
                    });
            },
        },
        created: function () {
            this.$emit('input', this.$route.name);
        },
        mounted: function () {
            this.getBackUpPath();
        }
    }
</script>