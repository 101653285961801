<template>
    <div class="row" v-if="isValid('CanViewProduct')">
        <div class="col-xm-12 col-sm-12 col-md-12 col-lg-12" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">

            <div class="card">
                <div class="BorderBottom ml-2 mr-2 mt-3 mb-3">
                    <span class=" DayHeading">{{ $t('ProductMaster.Product') }}</span>
                </div>

                <div class="card-body">
                    <div class="row">
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label>{{ $t('ProductMaster.SearchbyProductName') }}</label>
                                <div>
                                    <input type="text" class="form-control search_input" v-model="search" name="search" id="search" :placeholder="$t('ProductMaster.Search')" />
                                    <span class="fas fa-search search_icon"></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                            <a href="javascript:void(0)" class="btn btn-primary" style="margin-top:27px;" v-on:click="openmodel"><i class="fa fa-plus"></i>  {{ $t('ProductMaster.AddNew') }}</a>
                            <router-link :to="'/ProductManagement'">
                                <a href="javascript:void(0)" class="btn btn-outline-danger " style="margin-top:27px;">  {{ $t('ProductMaster.Close') }} </a>
                            </router-link>
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <table class="table table-striped table-hover table_list_bg" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <thead v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                    <tr>
                                        <th>#</th>
                                        <th width="10%">
                                            {{ $t('ProductMaster.Code') }}
                                        </th>
                                        <th v-if="english=='true'">
                                            {{ $t('ProductMaster.ProductMasterName')|englishLanguage}}
                                        </th>
                                        <th v-if="isOtherLang()" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                            {{ $t('ProductMaster.ProductMasterName') |arabicLanguage}}
                                        </th>
                                        <th class="text-center" width="40%">
                                            {{ $t('ProductMaster.Description') }}
                                        </th>
                                        <th>
                                            {{ $t('ProductMaster.Status') }}
                                        </th>


                                    </tr>
                                </thead>
                                <tbody v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                    <tr v-for="(productMaster,index) in productMasterlist" v-bind:key="productMaster.id">
                                        <td v-if="currentPage === 1">
                                            {{index+1}}
                                        </td>
                                        <td v-else>
                                            {{((currentPage*10)-10) +(index+1)}}
                                        </td>

                                        <td>
                                            <strong>
                                                <a href="javascript:void(0)" v-on:click="EditProductMaster(productMaster.id)">{{productMaster.code}}</a>
                                            </strong>
                                        </td>
                                        <td v-if="english=='true'">
                                            {{productMaster.name}}
                                        </td>
                                        <td v-if="isOtherLang()">
                                            {{productMaster.nameArabic}}
                                        </td>


                                        <td class="text-center">
                                            {{productMaster.description}}
                                        </td>
                                        <td>{{productMaster.isActive==true?$t('ProductMaster.Active'):$t('ProductMaster.De-Active')}}</td>


                                    </tr>
                                </tbody>
                            </table>
                            <div class="float-left">
                                <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                                <span v-else-if="currentPage===1 && rowCount < 10">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage===1 && rowCount >= 11  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                            </div>
                            <div class="float-right">
                                <div class="overflow-auto" v-on:click="GetProductMasterData()">
                                    <b-pagination pills size="lg" v-model="currentPage"
                                                  :total-rows="rowCount"
                                                  :per-page="10"
                                                  first-text="First"
                                                  prev-text="Previous"
                                                  next-text="Next"
                                                  last-text="Last"></b-pagination>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <productMastermodel :productMaster="newProductMaster"
                            :show="show"
                            v-if="show"
                            @close="IsSave"
                            :type="type" />

    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>


<script>
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        data: function () {
            return {
                searchQuery: '',
                show: false,
                productMasterlist: [],
                newProductMaster: {
                    id: '',
                    name: '',
                    nameArabic: '',
                    description: '',
                    code: '',
                    isActive: true
                },
                type: '',
                search: '',
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                arabic: '',
                english: '',
                isMasterProduct: false,

            }
        },
        watch: {
            search: function (val) {
                this.GetProductMasterData(val, 1);
            }
        },
        methods: {
            IsSave: function () {

                this.show = false;

                this.GetProductMasterData(this.search, this.currentPage);
            },
            getPage: function () {
                this.GetProductMasterData(this.search, this.currentPage);
            },
            openmodel: function () {
                this.newProductMaster = {
                    id: '00000000-0000-0000-0000-000000000000',
                    name: '',
                    nameArabic: '',
                    description: '',
                    isActive: true

                }
                this.show = !this.show;
                this.type = "Add";
            },
            GetProductMasterData: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('Product/ProductMasterList?isActive=false' + '&pageNumber=' + this.currentPage + '&searchTerm=' + this.search, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.productMasterlist = response.data.results.productMasters;
                        root.pageCount = response.data.pageCount;
                        root.rowCount = response.data.rowCount;
                        root.loading = false;
                    }
                    root.loading = false;
                });
            },
            EditProductMaster: function (Id) {

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Product/ProductMasterDetail?Id=' + Id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data) {
                            root.newProductMaster.id = response.data.id;
                            root.newProductMaster.name = response.data.name;
                            root.newProductMaster.nameArabic = response.data.nameArabic;
                            root.newProductMaster.description = response.data.description;
                            root.newProductMaster.code = response.data.code;
                            root.newProductMaster.isActive = response.data.isActive;
                            root.show = !root.show;
                            root.type = "Edit"
                        } else {
                            console.log("error: something wrong from db.");
                        }
                    },
                        function (error) {
                            this.loading = false;
                            console.log(error);
                        });

            }
        },
        created: function () {

            this.$emit('input', this.$route.name);
        },
        mounted: function () {
            this.isMasterProduct = localStorage.getItem('IsMasterProductPermission') == 'true' ? true : false;

            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.GetProductMasterData(this.search, 1);

        }
    }
</script>
<style scoped>
</style>