<template>
    <div>
        <div class="row m-4">
            <div class="col-lg-12 col-sm-12 ml-auto mr-auto">
                <div class="card ">
                    <div class="card-header">
                        <div class="row ml-1">
                            <h4 class="card-title DayHeading">{{ $t('Customer.Customer') }}</h4>
                        </div>
                        <div class="row">
                            <div class="col-md-4 m-1 ">
                                <input class="form-control" type="text" placeholder="Search By Customers Name" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <div class="col-lg-12">
                            <div class="row">
                                <div>
                                    <a href="javascript:void(0)" class="btn btn-outline-primary " v-on:click="AddCustomer"><i class="fa fa-plus"></i>  {{ $t('AddNew') }}</a>
                                    <router-link :to="'/StartScreen'"><a href="javascript:void(0)" class="btn btn-outline-danger ">{{ $t('Close') }}</a></router-link>
                                </div>
                            </div>
                            <div class="mt-2">
                                <div class=" table-responsive">
                                    <table class="table ">
                                        <thead class="m-0">
                                            <tr>
                                                <th>Id</th>
                                                <th>Name </th>
                                                <th>Commercial RegNo</th>
                                                <th>Address</th>
                                                <th>City</th>
                                                <th>Email</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(cust,index) in customerlist" v-bind:key="cust.id">
                                                <td>
                                                    {{index+1}}
                                                </td>
                                                <td>
                                                    <strong>
                                                        <a href="javascript:void(0)" v-on:click="EditCustomer(cust.id)">{{cust.englishName}}</a>
                                                    </strong>
                                                </td>
                                                <td>{{cust.commercialRegistrationNo}}</td>
                                                <td>{{cust.address}}</td>
                                                <td>{{cust.city}}</td>
                                                <td>{{cust.email}}</td>
                                                <td><a href="javascript:void(0)" class="btn btn-danger btn-sm btn-icon " v-on:click="RemoveCustomer(cust.id)"><i class=" fa fa-trash"></i></a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    export default {
        name: 'Customer',
        data: function () {
            return {
                customerlist: [],
                searchQuery: '',
                customer:[],
                loading: true,
            }
        },
        methods: {
            AddCustomer: function () {
                this.$router.push('/addCustomer')
            },
           
            EditCustomer: function (Id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.get('/Contact/ContactDetail?Id=' + Id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.customer = response.data
                    }
                    root.$router.push({
                        path: '/addCustomer',
                        query: {
                            data: root.customer
                        }
                    })
                });
            },

                GetCustomerData: function () {
                    var root = this;

                    var token = '';
                    if (this.$session.exists()) {
                        token = localStorage.getItem('token');
                    }
                    this.$https.get('/Contact/ContactList?isCustomer=true', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                        if (response.data != null) {
                         
                                   
                            // root.$store.state.customerlist.push(response.data.contacts)
                            root.customerlist = response.data.contacts;
                        }
                    });
                },
                RemoveCustomer: function (id) {
                    var root = this;
                    // working with IE and Chrome both
                    this.$swal({
                        icon: 'error',
                        title: "Are you sure?",
                        text: "You will not be able to recover this!",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Yes, delete it!",
                        closeOnConfirm: false,
                        closeOnCancel: false
                    }).then(function (result) {
                        if (result) {
                            var token = '';
                            if (root.$session.exists()) {
                                token = localStorage.getItem('token');
                            }
                            root.$https
                                .get('/Contact/ContactDelete?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } })
                                .then(function (response) {
                                    if (response.data.id != '00000000-0000-0000-0000-000000000000') {
                                        root.$store.state.customerlist.splice(root.$store.state.customerlist.findIndex(function (i) {
                                            return i.id === response.data;
                                        }), 1);
                                        root.$swal({
                                            title: 'Deleted!',
                                            text: response.data.isAddUpdate,
                                            type: 'success',
                                            confirmButtonClass: "btn btn-success",
                                            buttonsStyling: false
                                        });
                                    } else {
                                        root.$swal({
                                            title: "Error!",
                                            text: response.data.isAddUpdate,
                                            type: 'error',
                                            confirmButtonClass: "btn btn-danger",
                                            buttonsStyling: false
                                        });
                                    }
                                },
                                    function () {
                                        root.loading = false;
                                        root.$swal({
                                            title: "Error!",
                                            text: "Something Went Wrong",
                                            type: 'error',
                                            confirmButtonClass: "btn btn-danger",
                                            buttonsStyling: false
                                        });
                                    });
                        }
                        else {
                            this.$swal('Cancelled', 'Your file is still intact', 'info');
                        }
                    });
                },
            },

            mounted: function () {
                this.GetCustomerData();
            }
        }
</script>