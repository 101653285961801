<template>
    <div class="row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" >
        <div class="col-lg-12 col-sm-12 ml-auto mr-auto">
            <div class="card ">
                <div class="BorderBottom ml-4 mr-4 mt-3 mb-3">
                    <span class=" DayHeading">{{ $t('Region.ListofRegions') }}</span>
                </div>
                
           
                <div class="card-body">
                    <div class="col-lg-12">
                        <div class="row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                <div class="form-group">
                                    <label>{{ $t('Region.SearchByCountryState') }}</label>
                                    <div>
                                        <input type="text" class="form-control search_input" v-model="searchQuery" name="search" id="search" :placeholder="$t('Region.Search')" />
                                        <span class="fas fa-search search_icon"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'" style="margin-top:18px;">
                                <a href="javascript:void(0)" class="btn btn-primary" v-on:click="openmodel"><i class="fa fa-plus"></i>  {{ $t('Region.AddNew') }}</a>
                                <router-link :to="'/GeographicalSetup'">
                                    <a href="javascript:void(0)" class="btn btn-outline-danger ">  {{ $t('Region.Close') }} </a>
                                </router-link>
                            </div>

                        </div>
                       
                        <div class="mt-2">
                            <div>
                                <table class="table table-shopping" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <thead class="">
                                        <tr>
                                            <th>#</th>
                                            <th>
                                                {{ $t('Region.Code') }}
                                            </th>
                                            <th>
                                                {{ $t('Region.CountryName') }}
                                            </th>
                                            <th>
                                                {{ $t('Region.StateName') }}
                                            </th>
                                            <th>
                                                {{ $t('Region.CityName') }}
                                            </th>
                                            <th>
                                                {{ $t('Region.AreaName') }}
                                            </th>
                                            <th>
                                                {{ $t('Region.Status') }}
                                            </th>


                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(region,index) in resultQuery" v-bind:key="region.id">
                                            <td>
                                                {{index+1}}
                                            </td>

                                            <td>
                                                <strong>
                                                    <a href="javascript:void(0)" v-on:click="EditRegion(region.id)">  {{region.code}}</a>
                                                </strong>
                                            </td>

                                            <td>
                                                {{region.description}}
                                            </td>
                                            <td>
                                                {{region.stateId}}
                                            </td>
                                            <td>
                                                {{region.cityName}}
                                            </td>
                                            <td>
                                                {{region.area}}
                                            </td>

                                            <td>{{region.isActive==true?$t('Active'):$t('De-Active')}}</td>



                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <regionmodal :region="newRegion"
                    :show="show"
                    v-if="show"
                    @close="close"
                    :type="type" />

    </div>

</template>

<script>
    export default {
        data: function () {
            return {
                searchQuery: '',
                show: false,
                regionlist: [],
                newRegion: {
                    id: '',
                    countryId: '',
                    stateId: '',
                    cityId: '',
                    area: '',
                    code: '',
                    description: '',
                    isActive: true
                },
                type: '',
            }
        },
        computed: {
            resultQuery: function () {
                var root = this;
                if (this.searchQuery) {
                    return root.regionlist.filter((region) => {
                        
                        return root.searchQuery.toLowerCase().split(' ').every(v => region.area.toLowerCase().includes(v) || region.stateId.toLowerCase().includes(v) || region.cityName.toLowerCase().includes(v) || region.code.toLowerCase().includes(v) || region.description.toLowerCase().includes(v) )
                    })
                } else {
                    return root.regionlist;
                }
            },
        },        methods: {
            close: function () {
                
                this.show = false;
                this.GetRegionData();
            },
            openmodel: function () {
                this.newRegion = {
                    id: '00000000-0000-0000-0000-000000000000',
                    countryId: '',
                    stateId: '',
                    cityId: '',
                    area: '',
                    code: '',
                    description: '',
                    isActive: true

                }
                this.show = !this.show;
                this.type = "Add";
            },
            GetRegionData: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('Region/RegionList?isActive=false', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.$store.dispatch('GetRegionList', response.data.regions);
                        root.regionlist = response.data.regions;
                    }
                });
            },
            getCities: function () {
                var root = this;
                var url = 'https://geodata.solutions/api/api.php';


                this.$https.get(url + '?type=getCountries').then(function (response) {

                    if (response.data != null) {
                        for (const [key, value] of Object.entries(response.data.result)) {
                            root.options.push({
                                id: key,
                                name: value
                            });
                        }


                    }
                }).then(function () {
                    root.value = root.options.find(function (x) {
                        return x.id == root.values;
                    })
                });

            },

            EditRegion: function (Id) {


                var root = this;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Region/RegionDetail?Id=' + Id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data) {
                            
                            root.newRegion.id = response.data.id;
                            root.newRegion.countryId = response.data.countryId;
                            root.newRegion.stateId = response.data.stateId;
                            root.newRegion.cityId = response.data.cityId;
                            root.newRegion.area = response.data.area;
                            root.newRegion.description = response.data.description;
                            root.newRegion.code = response.data.code;
                            root.newRegion.isActive = response.data.isActive;
                            root.show = !root.show;
                            root.type = "Edit"
                        } else {
                            console.log("error: something wrong from db.");
                        }
                    },
                        function (error) {
                            this.loading = false;
                            console.log(error);
                        });

            }
        },
        created: function () {
            this.$emit('input', this.$route.name);
        },
        mounted: function () {
            this.GetRegionData();
        }
    }
</script>