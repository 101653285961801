<template>
    <div class="row" v-if="isValid('CanViewRunPayroll')">
        <div class="col-lg-12 col-sm-12 ml-auto mr-auto" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">

            <div class="card">
                <div class="BorderBottom ml-2 mr-2 mt-3 mb-3">
                    <span class=" DayHeading">{{ $t('RunPayroll.RunPayroll') }} </span>
                </div>
                <div class="card-body">
                    <div class="row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label>{{ $t('RunPayroll.Search') }}</label>
                                <div>
                                    <input type="text" class="form-control search_input" v-model="search" name="search" id="search" :placeholder="$t('RunPayroll.Search')" />
                                    <span class="fas fa-search search_icon"></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                            <a v-if="isValid('CanAddRunPayroll') || isValid('CanDraftRunPayroll')" href="javascript:void(0)" class="btn btn-primary " style="margin-top:27px;" v-on:click="AddSalaryTemplate">{{ $t('RunPayroll.RunNewPayroll') }} </a>
                            <router-link :to="'/HrSetup'">
                                <a href="javascript:void(0)" class="btn btn-outline-danger " style="margin-top:27px;">  <i class="fas fa-arrow-circle-left fa-lg"></i> </a>
                            </router-link>
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <table class="table table-striped table-hover table_list_bg" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <thead v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                    <tr>
                                        <th>#</th>
                                        <th>
                                            {{ $t('RunPayroll.PayPlan') }}
                                        </th>
                                        <!--<th>
                                        Schedule Type
                                    </th>-->
                                        <th>
                                            {{ $t('RunPayroll.Status') }}
                                        </th>
                                        <th>
                                            {{ $t('RunPayroll.PayPeriod') }}
                                        </th>
                                        <th>
                                            {{ $t('RunPayroll.PayDate') }}
                                        </th>
                                        <th>
                                            {{ $t('RunPayroll.TotalEmployees') }}
                                        </th>
                                        <th>
                                            {{ $t('RunPayroll.TotalTax') }}
                                        </th>
                                        <th>
                                            {{ $t('RunPayroll.NetSalary') }}
                                        </th>
                                        <th class="text-center">
                                            {{ $t('RunPayroll.Print') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                    <tr v-for="(salaryTemplate ,index) in salaryTemplateList" v-bind:key="salaryTemplate.id">
                                        <td v-if="currentPage === 1">
                                            {{index+1}}
                                        </td>
                                        <td v-else>
                                            {{((currentPage*10)-10) +(index+1)}}
                                        </td>
                                        <td>
                                            <strong v-if="!salaryTemplate.status && isValid('CanEditOpenRunPayroll')" >
                                                <a href="javascript:void(0)" v-on:click="EditSalaryTemplate(salaryTemplate.id)">{{salaryTemplate.payPlan}}</a>
                                            </strong>
                                            <strong v-else>
                                                {{salaryTemplate.payPlan}}
                                            </strong>
                                        </td>
                                        <td>
                                            <span class="badge badge-danger" v-if="salaryTemplate.status"> Close</span>
                                            <span class="badge badge-success" v-else>Open</span>
                                        </td>
                                        <td>
                                            {{salaryTemplate.payPeriod}}
                                        </td>
                                        <td>
                                            {{salaryTemplate.payDate}}
                                        </td>
                                        <td>
                                            {{salaryTemplate.totalEmployees}}
                                        </td>
                                        <td>
                                            {{currency}} {{salaryTemplate.taxAmount}}
                                        </td>
                                        <td>
                                            {{currency}} {{salaryTemplate.netSalary}}
                                        </td>
                                        <td class="text-center">
                                            <a href="javascript:void(0)" v-on:click="GetCashSalary(salaryTemplate.id, 'Bank')" class="btn  btn-icon btn-primary btn-sm" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'mr-1' : 'ml-1'" v-if="isValid('CanViewBankRunPayroll')"><i class="fas fa-university"></i></a>
                                            <a href="javascript:void(0)" v-on:click="GetCashSalary(salaryTemplate.id, 'Cash')" class="btn  btn-icon btn-primary btn-sm mr-1" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'mr-1' : 'ml-1'" v-if="isValid('CanViewCashRunPayroll')"><i class="fas fa-money-bill"></i></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="float-left">
                        <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                        <span v-else-if="currentPage===1 && rowCount < 10">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage===1 && rowCount >= 11  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                    </div>
                    <div class="float-right">
                        <div class="overflow-auto" v-on:click="getPage()">
                            <b-pagination pills size="lg" v-model="currentPage"
                                          :total-rows="rowCount"
                                          :per-page="10"
                                          first-text="First"
                                          prev-text="Previous"
                                          next-text="Next"
                                          last-text="Last"></b-pagination>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <run-payroll-model :show="show"
                             v-if="show"
                             :runPayroll="runPayroll"
                             @close="show=false" />
    </div>
    <div v-else> <acessdenied></acessdenied></div>    
</template>


<script>
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        data: function () {
            return {
                currency: '',
                searchQuery: '',
                salaryTemplateList: [],
                search: '',
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                arabic: '',
                english: '',
                show: false,
                runPayroll: ''
            }
        },
        watch: {
            search: function () {
                this.GetSalaryTemplateData();
            }
        },
        methods: {
            AddSalaryTemplate: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Payroll/ChecKPaySchedule', { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data > 0) {
                            root.$router.push('/AddRunPayroll');
                        }
                        else {
                            root.$swal({
                                title: 'Warning!',
                                text: 'Please publish the opened payrolls before running the next payroll.',
                                type: 'warning',
                                confirmButtonClass: "btn btn-warning",
                                icon: 'warning',
                                timer: 1500,
                                timerProgressBar: true,
                            });
                        }
                    },
                        function (error) {
                            root.loading = false;
                            console.log(error);
                        });


            },

            getPage: function () {
                this.GetSalaryTemplateData();
            },

            GetSalaryTemplateData: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                root.$https.get('Payroll/RunPayrollList?searchTerm=' + this.search + '&pageNumber=' + this.currentPage, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.salaryTemplateList = response.data.results;
                        root.pageCount = response.data.pageCount;
                        root.rowCount = response.data.rowCount;
                        root.loading = false;
                    }
                    root.loading = false;
                });
            },

            EditSalaryTemplate: function (Id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Payroll/RunPayrollDetail?Id=' + Id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data) {
                            
                            root.$router.push({
                                path: '/AddRunPayroll',
                                query: { data: response.data }
                            })
                        }
                    },
                        function (error) {
                            root.loading = false;
                            console.log(error);
                        });

            },

            GetCashSalary: function (id, prop) {
                var root = this;

                root.$https.get('/Payroll/RunPayrollDetailPrint?id=' + id + '&prop=' + prop, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
                    .then(function (response) {
                        if (response.data) {
                            root.runPayroll = response.data;
                            root.show = true;
                        }
                    },
                        function (error) {
                            root.loading = false;
                            console.log(error);
                        });
            }
        },

        created: function () {
            this.$emit('input', this.$route.name);
        },

        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.currency = localStorage.getItem('currency');
            this.GetSalaryTemplateData();

        }
    }
</script>