<template>
    <div style="margin-bottom:0px" class="card">
        <div class="card-body">
            <div class="col-lg-12">
                <div class="tab-content" id="nav-tabContent">
                    <div class="modal-header">

                        <h5 class="modal-title"> {{roleName}}</h5>

                    </div>
                    <div class="card-body">
                        <div class="row ">


                            <div class="col-12 ">
                                <h5 style="border-bottom:1px red solid">Modules</h5>
                            </div>
                            <div class="form-group has-label col-12 ">
                                <div class="row">
                                    <div class="col-4" v-for="module of noblePermission.modules" :key='module.name + 3'>
                                        <h6 class="btn btn-primary btn-block" type="button" v-on:click="ShowOptions(module)">
                                            {{module.name}}
                                        </h6>
                                    </div>
                                </div>



                            </div>

                            <div class="col-12">
                                <div class="row" v-if="show">
                                    <div class="col-12 ">
                                        <h5>Permissions ({{moduleName}})</h5>
                                    </div>
                                    <div class="col-12 ">
                                        <h5 style="border-bottom:1px red solid">
                                            <input type="checkbox" v-model="isChecked" v-on:change="onClickCheckBox(moduleId)" /><span style="margin-left:5px;">Select All Permission Of Module</span>
                                        </h5>
                                    </div>
                                    <template v-for="perType of permissionType">
                                        <div class="col-12" v-if="perType.moduleId === moduleId && noblePermission.permissions.filter(x => x.typeId === perType.id ).length > 0" :key='perType.value + 3'>

                                            <h5>
                                                {{perType.value}}:
                                            </h5>
                                            <div class="row">
                                                <template v-for="per of noblePermission.permissions">
                                                    <div class="col-4" v-if="per.moduleId === moduleId && per.typeId === perType.id" :key='per.value + 3'>

                                                        <h6>
                                                            <input type="checkbox" v-model="per.checked" v-on:change="updateAllCheckStatuc(moduleId)" /><span style="margin-left:5px;">{{per.permissionName}}</span>
                                                        </h6>
                                                    </div>
                                                </template>

                                            </div>
                                            <hr />
                                        </div>
                                    </template>

                                </div>
                            </div>

                        </div>
                        <div class="modal-footer justify-content-right">
                            <button type="button" class="btn btn-primary  " v-bind:disabled="noblePermission.permissions.filter(x => x.checked==true).length <= 0" v-on:click="SavePermissions"> Save </button>
                            <button type="button" class="btn btn-danger  mr-3 " v-on:click="onCancel">Cancel</button>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    //import NoblePermission from '@/components/NoblePermission.vue'

    import modules from '@/enums/modules'
    import permissions from '@/enums/permissions'
    import permissionType from '@/enums/permissiontype'
    export default {
        name: 'Home',
        components: {
        },
        data() {
            return {
                noblePermission: {
                    modules: [],
                    permissions: [],
                    groupId: '',
                },
                roleName: '',
                moduleId: '',
                moduleName: '',
                modules: modules,
                isChecked: false,
                permissions: permissions,

                permissionType: permissionType,
                show: false,
                groupList: ['ERP', 'Retail', 'WholeSale'],
                groupTypeList: ['Basic', 'Advance', 'Premium', 'Customize']
            }
        },

        methods: {
            updateAllCheckStatuc: function (moduleId) {
                var allPermissionSelected = this.noblePermission.permissions.findIndex((y => !y.checked && y.moduleId === moduleId));
                if (allPermissionSelected < 0) {
                    this.isChecked = true
                }
                else {
                    this.isChecked = false
                }
            },
            onClickCheckBox: function (moduleId) {
                // var root = this;
                var checkPermissionUpdate = false
                this.noblePermission.modules.forEach(function (x) {
                    if (x.id == moduleId) {
                        x.checked = !x.checked
                        checkPermissionUpdate = x.checked
                    }
                })
                this.noblePermission.permissions.forEach(function (x) {
                    if (x.moduleId == moduleId && checkPermissionUpdate) {
                        x.checked = true
                    }
                    else if (x.moduleId == moduleId && !checkPermissionUpdate) {
                        x.checked = false
                    }
                })
            },
            GetSelectedtGroupData: function () {
                var root = this;
                this.ConvertEnumToList()
                if (this.noblePermission.groupId != "") {
                    this.$https.get('/NoblePermission/GetNoblePermissionByGroupId?id=' + this.noblePermission.groupId).then(function (response) {
                        if (response.data != null) {
                            response.data.result.forEach(function (x) {
                                var index = root.noblePermission.permissions.findIndex((y => y.key == x.key));
                                if (index >= 0) {
                                    root.noblePermission.permissions[index].checked = true;
                                }
                            });


                        }

                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Something Went Wrong!',
                                text: error.response.data,

                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                    });

                }
            },
            SavePermissions: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                //noblePermission.groupId = '00000000-0000-0000-0000-000000000000'
                this.$https.post('/Company/UpdateUserPermission', this.noblePermission.permissions, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data.isSuccess) {
                        root.$swal({
                            icon: 'success',
                            title: 'Saved Successfully!',
                            showConfirmButton: false,
                            timer: 1500,
                            timerProgressBar: true,
                        });
                        root.$router.push('/Permissions');


                    }
                    else {
                        root.$swal({
                            title: "Error!",
                            text: response.data.message,
                            type: 'error',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 1500,
                            timerProgressBar: true,
                        });
                    }
                }).catch(error => {
                    console.log(error)
                    root.$swal.fire(
                        {
                            icon: 'error',
                            title: 'Something Went Wrong!',
                            text: error.response.data,

                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                        });

                });
            },
            onCancel: function () {
                this.$router.push('/Permissions');
            },
            ShowOptions: function (module) {
                this.moduleId = module.id;
                this.moduleName = module.name;
                var root = this;
                
                var allPermissionSelected = root.noblePermission.permissions.findIndex((y => !y.checked && y.moduleId === module.id));
                if (allPermissionSelected < 0) {
                    var moduleIndex = root.noblePermission.modules.findIndex((y => y.id === module.id));
                    root.noblePermission.modules[moduleIndex].checked = true
                    root.isChecked = true
                }
                else {
                    root.isChecked = false
                }
                this.show = true
            },
            ConvertEnumToList: function () {
                this.noblePermission.modules = []
                this.noblePermission.permissions = []

                var root = this;
                for (let item in this.modules) {
                    
                    var moduleData = root.nobleModuleList.findIndex((y => y.moduleId === root.modules[item].id))
                    if (moduleData >= 0) {
                        this.noblePermission.modules.push({
                            id: root.modules[item].id,
                            name: root.modules[item].value,
                            checked: false
                        });
                    }



                }
                for (let item in this.permissions) {
                    //if (this.permissions[item].key != '3d1f65f1-3f72-4898-a175-1b6ab42b2b9d' || this.permissions[item].key != '7dc50e60-d5a2-419a-b12a-200ac71d7cb6') {
                    var permissionData = this.noblePermissionList.findIndex((y => y.key === this.permissions[item].key))
                    if (permissionData >= 0) {
                        this.noblePermission.permissions.push({
                            permissionName: this.permissions[item].permissionName,
                            key: this.permissions[item].key,
                            value: this.permissions[item].value,
                            moduleId: this.permissions[item].moduleId,
                            typeId: this.permissions[item].typeId,
                            checked: this.noblePermissionList[permissionData].isActive,
                            id: this.noblePermissionList[permissionData].id,
                            groupId: '00000000-0000-0000-0000-000000000000'
                        });
                    }
                    //}


                }
                this.show = false;
            }
        },
        created() {
            if (this.$route.query.data != undefined) {
                this.nobleModuleList = this.$route.query.data.nobleModuleLook
                this.noblePermissionList = this.$route.query.data.noblePermissionLook
                this.roleName = this.$route.query.roleName

            }
        },
        mounted() {
            this.ConvertEnumToList();

        }
    }
</script>
