<template>
    <div class="row" v-if="isValid('CanViewEmployeeReg')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
        <div class="col-lg-12 col-sm-12 ml-auto mr-auto" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'arabicLanguage'">
            <div class="row mb-4" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                <div class="col-sm-6 col-md-6 col-lg-6">
                    <h5 class="page_title">{{ $t('EmployeeRegistration.EmployeeRegistration') }}</h5>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link :to="'/StartScreen'"><a href="javascript:void(0)"> {{ $t('EmployeeRegistration.Home') }}</a></router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">{{ $t('EmployeeRegistration.EmployeeRegistration') }}</li>
                        </ol>
                    </nav>
                </div>
                <div class=" col-sm-6 col-md-6 col-lg-6">
                    <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                        <a href="javascript:void(0)" v-if="isValid('CanAddEmployeeReg')" class="btn btn-outline-primary  " v-on:click="AddEmployee"><i class="fa fa-plus"></i> {{ $t('EmployeeRegistration.AddNew') }}</a>
                        <a hidden href="javascript:void(0)" v-if="isValid('CanAddEmployeeReg')" class="btn btn-outline-primary " v-on:click="openmodel"><i class="fa fa-plus"></i> {{ $t('EmployeeRegistration.QuickRegister') }}</a>
                        <router-link :to="'/StartScreen'"><a href="javascript:void(0)" class="btn btn-outline-danger "> {{ $t('EmployeeRegistration.Close') }}</a></router-link>
                    </div>

                </div>
            </div>
            <div class="card ">
                <div class="card-header">

                    <div class="row mb-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label>{{ $t('EmployeeRegistration.SearchbyBrand')}}</label>
                                <div>
                                    <input type="text" class="form-control search_input" v-model="search" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" name="search" id="search" :placeholder="$t('EmployeeRegistration.Search')" />
                                    <span class="fas fa-search search_icon"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="col-lg-12">
                        <div class="mt-2">
                            <div class="table-responsive">
                                <table class="table  table_list_bg">
                                    <thead class="">
                                        <tr>
                                            <th>
                                                {{ $t('EmployeeRegistration.CODE') }}
                                            </th>
                                            <th v-if="english=='true'">
                                                {{ $t('EmployeeRegistration.ENGLISHNAME') |englishLanguage}}
                                            </th>
                                            <th v-if="isOtherLang()">
                                                {{ $t('EmployeeRegistration.ARABICNAME') |arabicLanguage}}
                                            </th>
                                            <th>
                                                {{ $t('EmployeeRegistration.REGISTRATIONDATE') }}
                                            </th>
                                            <th>
                                                {{ $t('EmployeeRegistration.GENDER') }}
                                            </th>
                                            <th>
                                                {{ $t('EmployeeRegistration.IDNO') }}
                                            </th>
                                            <th>
                                                {{ $t('EmployeeRegistration.Active') }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(employee) in employeelist" v-bind:key="employee.id">
                                            <td v-if="isValid('CanEditEmployeeReg')">
                                                <strong>
                                                    <a href="javascript:void(0)" v-on:click="EditEmployee(employee.id)">{{employee.code}}</a>
                                                </strong>
                                            </td>
                                            <td v-else>
                                                {{employee.code}}
                                            </td>

                                            <td v-if="english=='true'">
                                                {{employee.englishName}}
                                            </td>
                                            <td v-if="isOtherLang()">
                                                {{employee.arabicName}}
                                            </td>

                                            <td>
                                                {{convertDate(employee.registrationDate)}}
                                            </td>


                                            <td>
                                                {{employee.gender}}
                                            </td>
                                            <td>
                                                {{employee.idNumber}}
                                            </td>
                                            <td v-if="employee.isActive">
                                                <toggle-button v-if="employee.code!='EM-00001'" :value=false v-on:change="EditEmployeeStatus(employee.id, true)" class="mr-2" color="#3178F6" />
                                            </td>
                                            <td v-else>
                                                <toggle-button v-if="employee.code!='EM-00001'" :value=true v-on:change="EditEmployeeStatus(employee.id, false)" class="mr-2" color="#3178F6" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="float-left">
                        <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                        <span v-else-if="currentPage===1 && rowCount < 10">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage===1 && rowCount >= 11  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                    </div>
                    <div class="float-right">
                        <div class="overflow-auto" v-on:click="getPage()">
                            <b-pagination pills size="lg" v-model="currentPage"
                                          :total-rows="rowCount"
                                          :per-page="10"
                                          first-text="First"
                                          prev-text="Previous"
                                          next-text="Next"
                                          last-text="Last"></b-pagination>
                        </div>
                    </div>
                    <quickemployeemodel :employee="newEmployee"
                                        :show="show"
                                        v-if="show"
                                        @close="show = false"
                                        :type="type" />
                </div>
            </div>
        </div>
    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>

<script>
    import moment from 'moment';
    import clickMixin from '@/Mixins/clickMixin'
    //import { Status } from 'filepond';
    export default {
        mixins: [clickMixin],
        data: function () {
            return {
                arabic: '',
                english: '',
                searchQuery: '',
                employeelist: [],
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                search: '',
                show: false,
                newEmployee: {
                    id: '00000000-0000-0000-0000-000000000000',
                    code: '',
                    registrationDate: '',
                    englishName: '',
                    arabicName: '',
                    gender: '',
                    idNumber: '',
                    email: ''
                },
                type: '',
                employee: {
                    id: '',
                    isActive: ''
                },
            }
        },
        watch: {
            search: function (val) {
                this.GetEmployeeData(val, 1);
            }
        },
        computed: {
            employeeList: function () {
                return this.employeelist;

            },
        },
        methods: {
            openmodel: function () {
                this.newEmployee = {
                    id: '00000000-0000-0000-0000-000000000000',
                    code: '',
                    registrationDate: '',
                    englishName: '',
                    arabicName: '',
                    gender: '',
                    idNumber: '',
                    email: ''

                }
                this.show = !this.show;
                this.type = "Add";
            },
            convertDate: function (date) {
                return moment(date).format('DD MMM YYYY');
            },
            getPage: function () {
                this.GetEmployeeData(this.search, this.currentPage);
            },
            GetEmployeeData: function (search, currentPage) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('EmployeeRegistration/EmployeeList?searchTerm=' + search + '&pageNumber=' + currentPage, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.$store.dispatch('GetEmployeeList', response.data.results);
                        root.employeelist = response.data.results;
                        root.pageCount = response.data.pageCount;
                        root.rowCount = response.data.rowCount;
                    }
                });
            },
            AddEmployee: function () {
                this.$router.push('/addEmployeeRegistration')
            },
            EditEmployee: function (Id) {

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/EmployeeRegistration/EmployeeDetail?Id=' + Id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {
                            root.$router.push({
                                path: '/addEmployeeRegistration',
                                query: {
                                    data: response.data
                                }
                            })
                        }
                    },
                        function (error) {
                            this.loading = false;
                            console.log(error);
                        });

            },
            EditEmployeeStatus: function (Id, isActive) {

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                this.employee.id = Id;
                this.employee.isActive = !isActive;

                root.$https.post('/EmployeeRegistration/SaveEmployeeStatus' , root.employee, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != '00000000-0000-0000-0000-000000000000') {
                            root.getPage();
                            root.$swal.fire({
                                icon: 'success',
                                title: 'Employee Status Change',
                                showConfirmButton: false,
                                timer: 1800,
                                timerProgressBar: true,

                            });
                        }
                    },
                        function (error) {
                            root.loading = false;
                            console.log(error);
                        });

            }
        },
        created: function () {
            this.$emit('input', this.$route.name);
        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.GetEmployeeData(this.search, 1);
        }
    }
</script>