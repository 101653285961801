<template>


    <div class="row" v-if="isValid('CanViewProductionBatch')">
        <div class="col-lg-12 col-sm-12 ml-auto mr-auto" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <div class="row mb-4" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                <div class="col-sm-6 col-md-6 col-lg-6">
                    <h5 class="page_title DayHeading">{{ $t('ProductionBatch.ProductionBatch') }}</h5>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link :to="'/StartScreen'"><a href="javascript:void(0)"> {{ $t('ProductionBatch.Home') }}</a></router-link></li>

                            <li class="breadcrumb-item active" aria-current="page">{{ $t('ProductionBatch.ProductionBatch') }}</li>
                        </ol>
                    </nav>
                </div>
                <div class=" col-sm-6 col-md-6 col-lg-6">
                    <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                        <a v-if="isValid('CanAddProductionBatch')" href="javascript:void(0)" class="btn btn-outline-primary " v-on:click="AddPurchaseOrder"><i class="fa fa-plus"></i> {{ $t('ProductionBatch.AddNew') }}</a>
                        <router-link :to="'/StartScreen'"><a href="javascript:void(0)" class="btn btn-outline-danger "> {{ $t('ProductionBatch.Close') }}</a></router-link>
                    </div>

                </div>
            </div>
            <div class="card ">
                <div class="card-header">
                    <div class="row mb-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <div class="form-group ">
                                <label>{{ $t('ProductionBatch.SearchByPB')}}</label>
                                <div>
                                    <input type="text" class="form-control search_input" v-model="search" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" name="search" id="search" :placeholder="$t('ProductionBatch.Search')" />
                                    <span class="fas fa-search search_icon"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <div>
                                <div>
                                    <ul class="nav nav-tabs" data-tabs="tabs">
                                        <li class="nav-item"><a class="nav-link" v-bind:class="{active:active == 'Draft'}" v-if="isValid('CanViewProductionBatch')" v-on:click="makeActive('Draft')" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">{{ $t('ProductionBatch.Draft') }}</a></li>
                                        <li class="nav-item"><a class="nav-link" v-bind:class="{active:active == 'Approved'}" v-if="isValid('CanViewProductionBatch')" v-on:click="makeActive('Approved')" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">{{ $t('ProductionBatch.Post') }}</a></li>
                                        <li class="nav-item"><a class="nav-link" v-bind:class="{active:active == 'InProcess'}" v-on:click="makeActive('InProcess')" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">{{ $t('ProductionBatch.InProcess') }}</a></li>
                                        <li class="nav-item"><a class="nav-link" v-bind:class="{active:active == 'Complete'}" v-on:click="makeActive('Complete')" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">{{ $t('ProductionBatch.Complete') }}</a></li>
                                        <li class="nav-item"><a class="nav-link" v-bind:class="{active:active == 'Transfer'}" v-on:click="makeActive('Transfer')" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">{{ $t('ProductionBatch.Transfer') }}</a></li>
                                        <li class="nav-item"><a class="nav-link" v-bind:class="{active:active == 'Rejected'}" v-on:click="makeActive('Rejected')" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">{{ $t('ProductionBatch.Rejected') }}</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="tab-content mt-3" id="nav-tabContent">
                                <div v-if="active == 'Draft'">
                                    <div class="row" v-if="selected.length > 0">
                                        <div class="col-md-3 ">
                                            <div class="dropdown">
                                                <button class="dropdown-toggle btn btn-primary  btn-block" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    {{ $t('ProductionBatch.BulkAction') }}
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">

                                                    <a class="dropdown-item" href="javascript:void(0)" v-on:click="UpdateApprovalStatus('Approved')"> {{ $t('ProductionBatch.Approve') }}</a>
                                                    <a class="dropdown-item" href="javascript:void(0)" v-on:click="UpdateApprovalStatus('Rejected')">{{ $t('ProductionBatch.Reject') }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                   
                                    <div class="table-responsive">
                                        <table class="table table-striped table-hover table_list_bg">
                                            <thead class="">
                                                <tr>
                                                    <th>#</th>
                                                    <th>
                                                        {{ $t('ProductionBatch.ProductionBatchNo') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('ProductionBatch.CreatedDate') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('ProductionBatch.CreatedBy') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('ProductionBatch.RecipeNumber') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('ProductionBatch.TotalQuantity') }}
                                                    </th>
                                                    <th class="text-center">
                                                        {{ $t('ProductionBatch.Action') }}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(purchaseOrder,index) in productionBatchList" v-bind:key="purchaseOrder.id">
                                                    <td v-if="currentPage === 1">
                                                        {{index+1}}
                                                    </td>
                                                    <td v-else>
                                                        {{((currentPage*10)-10) +(index+1)}}
                                                    </td>
                                                    <td v-if="isValid('CanAddProductionBatch') || isValid('CanAddProductionBatch')">
                                                        <strong>
                                                            <a href="javascript:void(0)" v-on:click="EditPurchaseOrder(purchaseOrder.id)">{{purchaseOrder.registrationNumber}}</a>
                                                        </strong>

                                                    </td>
                                                    <td>
                                                        {{purchaseOrder.date}}
                                                    </td>
                                                    <td>
                                                        {{purchaseOrder.createdBy}}
                                                    </td>
                                                    <td>
                                                        {{purchaseOrder.reciptName}}
                                                    </td>
                                                    <td>
                                                        {{purchaseOrder.netAmount}}
                                                    </td>
                                                    <td class="text-center">
                                                        <button class="btn btn-icon btn-sm  btn-primary mr-1" v-on:click="ViewBatch(purchaseOrder.id)"><i class="fas fa-eye"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>

                                    <div class="float-left">
                                        <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                                        <span v-else-if="currentPage===1 && rowCount < 10">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage===1 && rowCount >= 11  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                    </div>
                                    <div class="float-right">
                                        <div class="overflow-auto" v-on:click="getPage()" v-if="($i18n.locale == 'en' ||isLeftToRight())">
                                            <b-pagination pills size="lg" v-model="currentPage"
                                                          :total-rows="rowCount"
                                                          :per-page="10"
                                                          first-text="First"
                                                          prev-text="Previous"
                                                          next-text="Next"
                                                          last-text="Last"></b-pagination>
                                        </div>
                                        <div class="overflow-auto" v-on:click="getPage()" v-else>
                                            <b-pagination pills size="lg" v-model="currentPage"
                                                          :total-rows="rowCount"
                                                          :per-page="10"
                                                          first-text="الأولى"
                                                          prev-text="السابقة"
                                                          next-text="التالية"
                                                          last-text="الأخيرة"></b-pagination>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="active == 'Rejected'">

                                    <div class="row" v-if="selected.length > 0">
                                        <div class="col-md-3 ">
                                            <div class="dropdown">
                                                <button class="dropdown-toggle btn btn-primary  btn-block" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    {{ $t('ProductionBatch.BulkAction') }}
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">

                                                    <a class="dropdown-item" href="javascript:void(0)" v-on:click="UpdateApprovalStatus('Approved')"> {{ $t('ProductionBatch.Approve') }}</a>
                                                    <a class="dropdown-item" href="javascript:void(0)" v-on:click="UpdateApprovalStatus('Rejected')">{{ $t('ProductionBatch.Reject') }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="table-responsive">
                                        <table class="table table-striped table-hover table_list_bg">
                                            <thead class="">
                                                <tr>
                                                    <th>#</th>
                                                    <th>
                                                        {{ $t('ProductionBatch.ProductionBatchNo') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('ProductionBatch.CreatedDate') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('ProductionBatch.CreatedBy') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('ProductionBatch.RecipeNumber') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('ProductionBatch.TotalQuantity') }}
                                                    </th>
                                                    <th class="text-center">
                                                        {{ $t('ProductionBatch.Action') }}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(purchaseOrder,index) in productionBatchList" v-bind:key="purchaseOrder.id">
                                                    <td v-if="currentPage === 1">
                                                        {{index+1}}
                                                    </td>
                                                    <td v-else>
                                                        {{((currentPage*10)-10) +(index+1)}}
                                                    </td>

                                                    <td>
                                                        {{purchaseOrder.registrationNumber}}
                                                    </td>
                                                    <td>
                                                        {{purchaseOrder.date}}
                                                    </td>
                                                    <td>
                                                        {{purchaseOrder.createdBy}}
                                                    </td>
                                                    <td>
                                                        {{purchaseOrder.reciptName}}
                                                    </td>
                                                    <td>
                                                        {{purchaseOrder.netAmount}}
                                                    </td>
                                                    <td class="text-center">
                                                        <button class="btn btn-icon btn-sm  btn-primary mr-1" v-on:click="ViewBatch(purchaseOrder.id)"><i class="fas fa-eye"></i></button>
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>

                                    <div class="float-left">
                                        <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                                        <span v-else-if="currentPage===1 && rowCount < 10">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage===1 && rowCount >= 11  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                    </div>
                                    <div class="float-right">
                                        <div class="overflow-auto" v-on:click="getPage()" v-if="($i18n.locale == 'en' ||isLeftToRight())">
                                            <b-pagination pills size="lg" v-model="currentPage"
                                                          :total-rows="rowCount"
                                                          :per-page="10"
                                                          first-text="First"
                                                          prev-text="Previous"
                                                          next-text="Next"
                                                          last-text="Last"></b-pagination>
                                        </div>
                                        <div class="overflow-auto" v-on:click="getPage()" v-else>
                                            <b-pagination pills size="lg" v-model="currentPage"
                                                          :total-rows="rowCount"
                                                          :per-page="10"
                                                          first-text="الأولى"
                                                          prev-text="السابقة"
                                                          next-text="التالية"
                                                          last-text="الأخيرة"></b-pagination>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="active == 'Approved'">

                                    <div class="row" v-if="selected.length > 0">
                                        <div class="col-md-3 ">
                                            <div class="dropdown">
                                                <button class="dropdown-toggle btn btn-primary  btn-block" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    {{ $t('ProductionBatch.BulkAction') }}
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">

                                                    <a class="dropdown-item" href="javascript:void(0)" v-on:click="UpdateApprovalStatus('Approved')"> {{ $t('ProductionBatch.Approve') }}</a>
                                                    <a class="dropdown-item" href="javascript:void(0)" v-on:click="UpdateApprovalStatus('Rejected')">{{ $t('ProductionBatch.Reject') }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                   
                                    <div class="table-responsive">
                                        <table class="table table-striped table-hover table_list_bg">
                                            <thead class="">
                                                <tr>
                                                    <th>#</th>
                                                    <th>
                                                        {{ $t('ProductionBatch.ProductionBatchNo') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('ProductionBatch.CreatedDate') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('ProductionBatch.CreatedBy') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('ProductionBatch.RecipeNumber') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('ProductionBatch.TotalQuantity') }}
                                                    </th>
                                                    <th class="text-center">
                                                        {{ $t('ProductionBatch.Action') }}
                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(purchaseOrder,index) in productionBatchList" v-bind:key="purchaseOrder.id">
                                                    <td v-if="currentPage === 1">
                                                        {{index+1}}
                                                    </td>
                                                    <td v-else>
                                                        {{((currentPage*10)-10) +(index+1)}}
                                                    </td>

                                                    <td>
                                                        {{purchaseOrder.registrationNumber}}
                                                    </td>

                                                    <td>
                                                        {{purchaseOrder.date}}
                                                    </td>
                                                    <td>
                                                        {{purchaseOrder.createdBy}}
                                                    </td>
                                                    <td>
                                                        {{purchaseOrder.reciptName}}
                                                    </td>
                                                    <td>
                                                        {{purchaseOrder.netAmount}}
                                                    </td>
                                                    <td class="text-center">
                                                        <button class="btn btn-success  mr-1" v-on:click="openmodel(purchaseOrder,false)">
                                                            {{ $t('ProductionBatch.Process') }}
                                                        </button>
                                                        <button title="View" class="btn btn-icon btn-sm  btn-primary " v-on:click="ViewBatch(purchaseOrder.id)"><i class="fas fa-eye" title="View "></i></button>
                                                        <!--<a href="javascript:void(0)" class="btn btn-primary  mr-2" v-on:click="openmodel(purchaseOrder,false)">Process</a>-->
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>

                                    <div class="float-left">
                                        <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                                        <span v-else-if="currentPage===1 && rowCount < 10">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage===1 && rowCount >= 11  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                    </div>
                                    <div class="float-right">
                                        <div class="overflow-auto" v-on:click="getPage()" v-if="($i18n.locale == 'en' ||isLeftToRight())">
                                            <b-pagination pills size="lg" v-model="currentPage"
                                                          :total-rows="rowCount"
                                                          :per-page="10"
                                                          first-text="First"
                                                          prev-text="Previous"
                                                          next-text="Next"
                                                          last-text="Last"></b-pagination>
                                        </div>
                                        <div class="overflow-auto" v-on:click="getPage()" v-else>
                                            <b-pagination pills size="lg" v-model="currentPage"
                                                          :total-rows="rowCount"
                                                          :per-page="10"
                                                          first-text="الأولى"
                                                          prev-text="السابقة"
                                                          next-text="التالية"
                                                          last-text="الأخيرة"></b-pagination>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="active == 'InProcess'">

                                    <div class="row" v-if="selected.length > 0">
                                        <div class="col-md-3 ">
                                            <div class="dropdown">
                                                <button class="dropdown-toggle btn btn-primary  btn-block" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    {{ $t('ProductionBatch.BulkAction') }}
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">

                                                    <a class="dropdown-item" href="javascript:void(0)" v-on:click="UpdateApprovalStatus('Approved')"> {{ $t('ProductionBatch.Approve') }}</a>
                                                    <a class="dropdown-item" href="javascript:void(0)" v-on:click="UpdateApprovalStatus('Rejected')">{{ $t('ProductionBatch.Reject') }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                   
                                    <div class="table-responsive">
                                        <table class="table table-striped table-hover table_list_bg">
                                            <thead class="">
                                                <tr>
                                                    <th>#</th>
                                                    <th>
                                                        {{ $t('ProductionBatch.ProductionBatchNo') }}
                                                    </th>

                                                    <th>
                                                        {{ $t('ProductionBatch.ProcessDate') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('ProductionBatch.CreatedBy') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('ProductionBatch.ProcessBy') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('ProductionBatch.Reason') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('ProductionBatch.RecipeNumber') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('ProductionBatch.TotalQuantity') }}
                                                    </th>
                                                    <th class="text-center">
                                                        {{ $t('ProductionBatch.Action') }}
                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(purchaseOrder,index) in productionBatchList" v-bind:key="purchaseOrder.id">
                                                    <td v-if="currentPage === 1">
                                                        {{index+1}}
                                                    </td>
                                                    <td v-else>
                                                        {{((currentPage*10)-10) +(index+1)}}
                                                    </td>

                                                    <td>
                                                        {{purchaseOrder.registrationNumber}}
                                                    </td>
                                                    <td>
                                                        {{purchaseOrder.processDate}}
                                                    </td>
                                                    <td>
                                                        {{purchaseOrder.createdBy}}
                                                    </td>
                                                    <td>
                                                        {{purchaseOrder.processBy}}
                                                    </td>
                                                    <td>
                                                        <span>
                                                            {{purchaseOrder.lateReason}}
                                                        </span>

                                                        <!--{{purchaseOrder.lateReason}}-->
                                                    </td>
                                                    <td>
                                                        {{purchaseOrder.reciptName}}
                                                    </td>
                                                    <td>
                                                        {{purchaseOrder.netAmount}}
                                                    </td>
                                                    <td class="text-center">
                                                        <button class="btn btn-success  mr-1" v-on:click="openmodel(purchaseOrder,true)">
                                                            {{ $t('ProductionBatch.Complete') }}
                                                        </button>
                                                        <button class="btn btn-icon btn-sm  btn-primary" v-on:click="ViewBatch(purchaseOrder.id)"><i class="fas fa-eye"></i></button>
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>

                                    <div class="float-left">
                                        <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                                        <span v-else-if="currentPage===1 && rowCount < 10">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage===1 && rowCount >= 11  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                    </div>
                                    <div class="float-right">
                                        <div class="overflow-auto" v-on:click="getPage()" v-if="($i18n.locale == 'en' ||isLeftToRight())">
                                            <b-pagination pills size="lg" v-model="currentPage"
                                                          :total-rows="rowCount"
                                                          :per-page="10"
                                                          first-text="First"
                                                          prev-text="Previous"
                                                          next-text="Next"
                                                          last-text="Last"></b-pagination>
                                        </div>
                                        <div class="overflow-auto" v-on:click="getPage()" v-else>
                                            <b-pagination pills size="lg" v-model="currentPage"
                                                          :total-rows="rowCount"
                                                          :per-page="10"
                                                          first-text="الأولى"
                                                          prev-text="السابقة"
                                                          next-text="التالية"
                                                          last-text="الأخيرة"></b-pagination>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="active == 'Complete'">

                                    <div class="row" v-if="selected.length > 0">
                                        <div class="col-md-3 ">
                                            <div class="dropdown">
                                                <button class="dropdown-toggle btn btn-primary  btn-block" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    {{ $t('ProductionBatch.BulkAction') }}
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">

                                                    <a class="dropdown-item" href="javascript:void(0)" v-on:click="UpdateApprovalStatus('Approved')"> {{ $t('ProductionBatch.Approve') }}</a>
                                                    <a class="dropdown-item" href="javascript:void(0)" v-on:click="UpdateApprovalStatus('Rejected')">{{ $t('ProductionBatch.Reject') }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="table-responsive">
                                        <table class="table table-striped table-hover table_list_bg">
                                            <thead class="">
                                                <tr>
                                                    <th>#</th>
                                                    <th>
                                                        {{ $t('ProductionBatch.ProductionBatchNo') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('ProductionBatch.CompletionDate') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('ProductionBatch.CreatedBy') }}
                                                    </th>

                                                    <th>
                                                        {{ $t('ProductionBatch.CompleteBy') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('ProductionBatch.Reason') }}
                                                    </th>

                                                    <th>
                                                        {{ $t('ProductionBatch.RecipeNumber') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('ProductionBatch.TotalQuantity') }}
                                                    </th>
                                                    <th class="text-center">
                                                        {{ $t('ProductionBatch.Action') }}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(purchaseOrder,index) in productionBatchList" v-bind:key="purchaseOrder.id">
                                                    <td v-if="currentPage === 1">
                                                        {{index+1}}
                                                    </td>
                                                    <td v-else>
                                                        {{((currentPage*10)-10) +(index+1)}}
                                                    </td>

                                                    <td>
                                                        {{purchaseOrder.registrationNumber}}
                                                    </td>
                                                    <td>
                                                        {{purchaseOrder.completeDate}}
                                                    </td>
                                                    <td>
                                                        {{purchaseOrder.createdBy}}
                                                    </td>
                                                    <td>
                                                        {{purchaseOrder.completeBy}}
                                                    </td>
                                                    <td>
                                                        <span>
                                                            {{purchaseOrder.lateReasonCompletion}}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        {{purchaseOrder.reciptName}}
                                                    </td>
                                                    <td>
                                                        {{purchaseOrder.netAmount}}
                                                    </td>
                                                    <td class="text-center">
                                                        <button class="btn btn-success  mr-1" v-on:click="transferModel(purchaseOrder)">
                                                            {{ $t('ProductionBatch.Transfer') }}
                                                        </button>
                                                        <button class="btn btn-icon btn-sm  btn-primary mr-1" v-on:click="ViewBatch(purchaseOrder.id)"><i class="fas fa-eye"></i></button>
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>

                                    <div class="float-left">
                                        <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                                        <span v-else-if="currentPage===1 && rowCount < 10">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage===1 && rowCount >= 11  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                    </div>
                                    <div class="float-right">
                                        <div class="overflow-auto" v-on:click="getPage()" v-if="($i18n.locale == 'en' ||isLeftToRight())">
                                            <b-pagination pills size="lg" v-model="currentPage"
                                                          :total-rows="rowCount"
                                                          :per-page="10"
                                                          first-text="First"
                                                          prev-text="Previous"
                                                          next-text="Next"
                                                          last-text="Last"></b-pagination>
                                        </div>
                                        <div class="overflow-auto" v-on:click="getPage()" v-else>
                                            <b-pagination pills size="lg" v-model="currentPage"
                                                          :total-rows="rowCount"
                                                          :per-page="10"
                                                          first-text="الأولى"
                                                          prev-text="السابقة"
                                                          next-text="التالية"
                                                          last-text="الأخيرة"></b-pagination>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="active == 'Transfer'">

                                    <div class="row" v-if="selected.length > 0">
                                        <div class="col-md-3 ">
                                            <div class="dropdown">
                                                <button class="dropdown-toggle btn btn-primary  btn-block" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    {{ $t('ProductionBatch.BulkAction') }}
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">

                                                    <a class="dropdown-item" href="javascript:void(0)" v-on:click="UpdateApprovalStatus('Approved')"> {{ $t('ProductionBatch.Approve') }}</a>
                                                    <a class="dropdown-item" href="javascript:void(0)" v-on:click="UpdateApprovalStatus('Rejected')">{{ $t('ProductionBatch.Reject') }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="table-responsive">
                                        <table class="table table-striped table-hover table_list_bg">
                                            <thead class="">
                                                <tr>
                                                    <th>#</th>
                                                    <th>
                                                        {{ $t('ProductionBatch.ProductionBatchNo') }}
                                                    </th>

                                                    <th>
                                                        {{ $t('ProductionBatch.TransferDate') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('ProductionBatch.CreatedBy') }}
                                                    </th>

                                                    <th>
                                                        {{ $t('ProductionBatch.TransferBy') }}
                                                    </th>

                                                    <th>
                                                        {{ $t('ProductionBatch.RecipeNumber') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('ProductionBatch.TotalQuantity') }}
                                                    </th>
                                                    <th class="text-center">
                                                        {{ $t('ProductionBatch.Action') }}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(purchaseOrder,index) in productionBatchList" v-bind:key="purchaseOrder.id">
                                                    <td v-if="currentPage === 1">
                                                        {{index+1}}
                                                    </td>
                                                    <td v-else>
                                                        {{((currentPage*10)-10) +(index+1)}}
                                                    </td>

                                                    <td>
                                                        {{purchaseOrder.registrationNumber}}
                                                    </td>

                                                    <td>
                                                        {{purchaseOrder.transferDate}}
                                                    </td>
                                                    <td>
                                                        {{purchaseOrder.createdBy}}
                                                    </td>
                                                    <td>
                                                        {{purchaseOrder.transferBy}}
                                                    </td>
                                                    <td>
                                                        {{purchaseOrder.reciptName}}
                                                    </td>
                                                    <td>
                                                        {{purchaseOrder.netAmount}}
                                                    </td>
                                                    <td class="text-center">
                                                        <button class="btn btn-icon btn-sm  btn-primary mr-1" v-on:click="ProductionBatchView(purchaseOrder.id)"><i class="fas fa-eye"></i></button>
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>

                                    <div class="float-left">
                                        <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                                        <span v-else-if="currentPage===1 && rowCount < 10">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage===1 && rowCount >= 11  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                        <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                    </div>
                                    <div class="float-right">
                                        <div class="overflow-auto" v-on:click="getPage()" v-if="($i18n.locale == 'en' ||isLeftToRight())">
                                            <b-pagination pills size="lg" v-model="currentPage"
                                                          :total-rows="rowCount"
                                                          :per-page="10"
                                                          first-text="First"
                                                          prev-text="Previous"
                                                          next-text="Next"
                                                          last-text="Last"></b-pagination>
                                        </div>
                                        <div class="overflow-auto" v-on:click="getPage()" v-else>
                                            <b-pagination pills size="lg" v-model="currentPage"
                                                          :total-rows="rowCount"
                                                          :per-page="10"
                                                          first-text="الأولى"
                                                          prev-text="السابقة"
                                                          next-text="التالية"
                                                          last-text="الأخيرة"></b-pagination>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ProcessModel :purchase="purchase"
                          :show="show"
                          v-if="show"
                          @close="show=false"
                          @RefreshList="RefreshList" />

            <CompletionModel :purchase="purchase"
                             :show="completeshow"
                             v-if="completeshow"
                             @close="completeshow=false"
                             @RefreshList="RefreshList" />
            <TransferModel :purchase="purchase"
                           :show="tranfershow"
                           v-if="tranfershow"
                           @close="tranfershow=false"
                           @RefreshList="RefreshList" />
        </div>
    </div>
    <div v-else> <acessdenied></acessdenied></div>

</template>

<script>
    import clickMixin from '@/Mixins/clickMixin'
    import moment from "moment";

    export default {
        mixins: [clickMixin],
        data: function () {

            return {
                active: 'Draft',
                search: '',
                UserName: '',
                searchQuery: '',
                productionBatchList: [],
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                show: false,
                completeshow: false,
                tranfershow: false,
                purchase: {

                },
                selected: [],
                selectAll: false,
                updateApprovalStatus: {
                    id: '',
                    approvalStatus: ''
                }
            }
        },
        watch: {
            search: function (val) {
                this.getData(val, 1, this.active);
            }
        },
        methods: {
            RefreshList: function (x) {
                
                if (x == 'InProcess') {
                    this.makeActive(x);

                }
                else if (x == 'Transfer') {
                    this.makeActive(x);

                }
                else {
                    x = 'Complete';
                    this.makeActive(x);
                }

            },
            transferModel: function (purchase) {
                this.purchase = purchase;
                this.tranfershow = !this.tranfershow;
            },
            openmodel: function (purchase, x) {
                
                var root = this;
                if (x) {
                    // For Completion
                    this.purchase = purchase;
                    this.completeshow = !this.completeshow;
                    //var systemTime2 = moment(this.purchase.endTime).format('DD/MM/YYYY HH:mm:ss');
                    //var endTime = moment(this.purchase.endTime).add(15, 'minutes').format('DD/MM/YYYY HH:mm:ss');
                    //console.log(systemTime2, endTime);
                }
                else {
                    // For IN PROCESS

                    
                    this.purchase = purchase;
                    this.purchase.processBy = this.UserName;
                    if (purchase.startTime != null) {
                        var systemTime = moment().add(1, 'hour').format('YYYY-MM-DD HH');
                        var startTime = moment(this.purchase.startTime).add(1, 'hour').format('YYYY-MM-DD HH');
                        var years = moment(systemTime).isSame(startTime, 'year');
                        if (years) {
                            var month = moment(systemTime).isSame(startTime, 'month ');
                            if (month) {
                                var days = moment(systemTime).isSame(startTime, 'day');
                                if (days) {
                                    var hour = moment(systemTime).isSame(startTime, 'hour');
                                    if (hour) {
                                        this.purchase.approvalStatus = 'InProcess';
                                        var token = '';
                                        if (this.$session.exists()) {
                                            token = localStorage.getItem('token');
                                        }
                                        this.$https.post('/Batch/BatchStatus', this.purchase, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                                            
                                            if (response.data) {
                                                

                                                root.$swal({
                                                    title: "Saved!",
                                                    text: "Saved Successfully!",
                                                    type: 'success',
                                                    icon: 'success',
                                                    showConfirmButton: false,
                                                    timer: 1500,
                                                    timerProgressBar: true,
                                                });
                                                root.makeActive('InProcess');
                                            }

                                            else {
                                                root.$swal({
                                                    title: "Error!",
                                                    text: "There is some Error On Status Change!",
                                                    type: 'error',
                                                    icon: 'error',
                                                    showConfirmButton: false,
                                                    timer: 1500,
                                                    timerProgressBar: true,
                                                });
                                            }
                                        });
                                    }
                                    else {
                                        this.purchase = purchase;
                                        this.show = !this.show;
                                    }

                                }
                                else {
                                    this.purchase = purchase;
                                    this.show = !this.show;
                                }
                            }
                            else {
                                this.purchase = purchase;
                                this.show = !this.show;
                            }
                        }
                        else {
                            this.purchase = purchase;
                            this.show = !this.show;
                        }
                    }
                    else {

                        this.purchase.approvalStatus = 'InProcess';


                        if (this.$session.exists()) {
                            token = localStorage.getItem('token');
                        }
                        this.$https.post('/Batch/BatchStatus', this.purchase, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                            
                            if (response.data) {
                                

                                root.$swal({
                                    title: "Saved!",
                                    text: "Saved Successfully!",
                                    type: 'success',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 1500,
                                    timerProgressBar: true,
                                });
                                root.makeActive('InProcess');
                            }

                            else {
                                root.$swal({
                                    title: "Error!",
                                    text: "There is some Error On Status Change!",
                                    type: 'error',
                                    icon: 'error',
                                    showConfirmButton: false,
                                    timer: 1500,
                                    timerProgressBar: true,
                                });
                            }
                        });
                    }
                    //var ms = moment(systemTime, "DD/MM/YYYY HH:mm:ss").diff(moment(startTime, "DD/MM/YYYY HH:mm:ss"));

                    //var month = startTime.format('M');
                    //var day = startTime.format('D');
                    //var year = startTime.format('YYYY');
                    //console.log(month, day, year);

                    //console.log(diff);
                    //console.log(ms);
                    //if (ms > 0 || ms > 900000) {
                    //    this.purchase = purchase;
                    //    this.show = !this.show;

                    //}
                    //else {


                    //}

                }
            },
            DeleteFile: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.get('/Batch/DeletePo', this.selected, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {
                            if (response.data.message.id != '00000000-0000-0000-0000-000000000000') {
                                root.$swal({
                                    title: 'Deleted!',
                                    text: response.data.message.isAddUpdate,
                                    type: 'success',
                                    confirmButtonClass: "btn btn-success",
                                    buttonsStyling: false
                                }).then(function (result) {
                                    if (result) {
                                        root.$router.push('/purchase');
                                    }
                                });
                            } else {
                                root.$swal({
                                    title: "Error!",
                                    text: response.data.message.isAddUpdate,
                                    type: 'error',
                                    confirmButtonClass: "btn btn-danger",
                                    buttonsStyling: false
                                });
                            }
                        }
                    },
                        function () {

                            root.$swal({
                                title: "Error!",
                                text: "Update UnSuccessfully",
                                type: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                        });
            },
            select: function () {
                this.selected = [];
                if (!this.selectAll) {
                    for (let i in this.productionBatchList) {
                        this.selected.push(this.productionBatchList[i].id);
                    }
                }
            },
            getPage: function () {
                this.getData(this.search, this.currentPage, this.active);
            },

            makeActive: function (item) {
                this.active = item;
                this.selectAll = false;
                this.selected = [];
                this.getData(this.search, 1, item);
            },
            getData: function (search, currentPage, status) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.get('/Batch/ProductionBatchList?status=' + status + '&searchTerm=' + search + '&pageNumber=' + currentPage, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {

                        /* root.$store.dispatch('GetProductionBatchList', response.data.results);*/
                        root.productionBatchList = response.data.results;
                        root.pageCount = response.data.pageCount;
                        root.rowCount = response.data.rowCount;

                    });
            },
            RemovePurchaseOrder: function (id) {


                var root = this;
                // working with IE and Chrome both
                this.$swal({
                    title: "Are you sure?",
                    text: "You will not be able to recover this!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes, delete it!",
                    closeOnConfirm: false,
                    closeOnCancel: false
                }).then(function (result) {
                    if (result) {

                        var token = '';
                        if (root.$session.exists()) {
                            token = localStorage.getItem('token');
                        }
                        root.$https.get('/Batch/DeleteProductionBatch?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } })
                            .then(function (response) {
                                if (response.data.message.id != '00000000-0000-0000-0000-000000000000') {


                                    root.$store.state.productionBatchList.splice(root.$store.state.productionBatchList.findIndex(function (i) {
                                        return i.id === response.data.message.id;
                                    }), 1);
                                    root.$swal({
                                        title: 'Deleted!',
                                        text: response.data.message.isAddUpdate,
                                        type: 'success',
                                        confirmButtonClass: "btn btn-success",
                                        buttonsStyling: false
                                    });
                                } else {
                                    root.$swal({
                                        title: "Error!",
                                        text: response.data.message.isAddUpdate,
                                        type: 'error',
                                        confirmButtonClass: "btn btn-danger",
                                        buttonsStyling: false
                                    });
                                }
                            },
                                function () {

                                    root.$swal({
                                        title: "Error!",
                                        text: "Delete UnSuccessfully",
                                        type: 'error',
                                        confirmButtonClass: "btn btn-danger",
                                        buttonsStyling: false
                                    });
                                });
                    }
                    else {
                        this.$swal('Cancelled', 'Your file is still intact', 'info');
                    }
                });
            },
            AddPurchaseOrder: function () {

                this.$router.push('/AddProductionBatch');
            },
            EditPurchaseOrder: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Batch/ProductionBatchDetail?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {
                            root.$router.push({
                                path: '/ProcessBatchScreen',
                                query: { data: response.data }
                            })
                        }
                    },
                        function (error) {
                            this.loading = false;
                            console.log(error);
                        });
            },
            ViewBatch: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Batch/ProductionBatchDetail?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {
                            root.$router.push({
                                path: '/BatchView',
                                query: { data: response.data }
                            })
                        }
                    },
                        function (error) {
                            this.loading = false;
                            console.log(error);
                        });
            },
            ProductionBatchView: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Batch/ProductionBatchDetail?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {
                            root.$router.push({
                                path: '/ProductionBatchView',
                                query: { data: response.data }
                            })
                        }
                    },
                        function (error) {
                            this.loading = false;
                            console.log(error);
                        });
            }
        },
        created() {
            this.$emit('input', this.$route.name);
        },
        mounted: function () {
            
            if (this.$route.query.data != undefined) {
                this.makeActive(this.$route.query.data);
            }
            else {
                this.makeActive("Draft");

            }
            this.UserName = localStorage.getItem('UserName');
            //this.getData(this.search, 1);
        },
        updated: function () {
            if (this.selected.length < this.productionBatchList.length) {
                this.selectAll = false;
            } else if (this.selected.length == this.productionBatchList.length) {
                if (this.selected.length == 0) {
                    this.selectAll = false;
                }
                else {
                    this.selectAll = true
                }
            }
        }
    }
</script>