<template>
    <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-bind:style="[(itemDisable==true && formName=='StockOut') ? {'pointer-events': 'none'} : {'pointer-events': 'auto'}] ">
        <label>Add Items</label>
        <product-dropdown @input="addProduct" width="100%" :wareHouseId="formName=='StockOut'? wareHouseId : undefined" :fromStockOut="true" />

        <div v-if="!loading" class=" table-responsive mt-4">
            <table class="table add_table_list_bg" style="table-layout:fixed;" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                <thead>
                    <tr>
                        <th style="width: 30px;">
                            #
                        </th>
                        <th style="width: 150px;">
                            {{ $t('StockLineDetails.ProductName') }}
                        </th>
                        <th style="width: 100px;" class="text-center">
                            {{ $t('StockLineDetails.UnitPrice') }}
                        </th>
                        <th style="width: 70px;" class="text-center">
                            {{ $t('StockLineDetails.salePrice') }}
                        </th>

                        <th class="text-center" style="width: 90px;" v-if="isValid('CanViewCurrentQuantity') && formName=='StockOut'">
                            {{ $t('SaleItem.CurrentQuantity') }}
                        </th>

                        <th style="width: 110px;" class="text-center" v-if="isMultiUnit=='true'">
                            {{ $t('StockLineDetails.HighQty') }}
                        </th>
                        <th style="width: 100px;" class="text-center">
                            {{ $t('StockLineDetails.Quantity') }}
                        </th>
                        <th style="width: 110px;" class="text-center" v-if="isMultiUnit=='true'">
                            {{ $t('StockLineDetails.TOTALQTY') }}
                        </th>

                        <th class="text-center" style="width: 100px;" v-if="isSerial && formName=='StockOut'">
                            {{ $t('StockLineDetails.Serial') }}
                        </th>
                        <th style="width: 100px;" v-if="isSerial && formName=='StockOut'">
                            {{ $t('StockLineDetails.Guarantee') }}
                        </th>

                        <th style="width:150px;" class="text-center" v-if="purchaseProducts.filter(x => x.guarantee).length > 0  && isSerial && formName=='StockIn'">
                            {{ $t('StockLineDetails.WarrantyType') }}
                        </th>
                        <th style="width: 100px;" v-if="purchaseProducts.filter(x => x.guarantee).length > 0  && isSerial && formName=='StockIn'">
                            {{ $t('StockLineDetails.Guarantee') }}
                        </th>
                        <th style="width: 100px;" class="text-center" v-if="purchaseProducts.filter(x => x.isSerial).length > 0  && isSerial && formName=='StockIn'">
                            {{ $t('StockLineDetails.Serial') }}
                        </th>
                        <th style="width:110px;" class="text-center" v-if="isFifo">
                            {{ $t('StockLineDetails.BatchNo') }}
                        </th>
                        <th style="width:110px;" class="text-center" v-if="isFifo">
                            {{ $t('StockLineDetails.ExpDate') }}
                        </th>
                        <th style="width: 100px;" class="arabicLanguage">
                            {{ $t('StockLineDetails.Total') }}
                        </th>
                        <th style="width: 40px"></th>
                    </tr>
                </thead>
                <tbody id="purchase-item">
                    <template v-for="(prod , index) in purchaseProducts">

                        <tr :key="prod.productId + index" v-bind:class="{'bg-danger':prod.outOfStock}" style="background:#EAF1FE;">
                            <td class="border-top-0">
                                {{index+1}}
                            </td>
                            <td class="border-top-0">
                                {{($i18n.locale == 'en' ||isLeftToRight())? products.find(x => x.id == prod.productId).englishName!=''? products.find(x => x.id == prod.productId).englishName : products.find(x => x.id == prod.productId).arabicName :    products.find(x => x.id == prod.productId).arabicName!=''? products.find(x => x.id == prod.productId).arabicName : products.find(x => x.id == prod.productId).englishName}}
                            </td>

                            <td class="border-top-0">
                                <!--<input type="number" v-model="prod.price"
                            @focus="$event.target.select()" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'"
                            class="form-control input-border text-center"
                            @blur="CheckSalePrice(prod.price,prod.productId)"
                            @keyup="updateLineTotal($event.target.value, 'price', prod)" />-->
                                <decimal-to-fixed v-model="prod.price" v-bind:salePriceCheck="true" v-on:input="updateLineTotal(prod.price, 'price', prod)" />
                            </td>
                            <td class="border-top-0 text-center">
                                {{currency}} {{parseFloat(prod.salePrice).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                            </td>
                            <td class="border-top-0 text-center" v-if="isValid('CanViewCurrentQuantity') && formName=='StockOut'">
                                {{prod.currentQuantity}}
                            </td>

                            <td class="border-top-0 text-center" v-if="isMultiUnit=='true'">
                                <input type="number" v-model="prod.highQty"
                                       style=""
                                       @focus="$event.target.select()" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'"
                                       class="form-control input-border text-center tableHoverOn"
                                       @keyup="updateLineTotal($event.target.value, 'highQty', prod)" />
                                <small style="font-weight: 500;font-size:70%;">
                                    {{prod.levelOneUnit}}
                                </small>
                            </td>
                            <td class="border-top-0 text-center">
                                <input type="number" v-model="prod.quantity"
                                       @focus="$event.target.select()" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'"
                                       class="form-control input-border text-center tableHoverOn"
                                       @keyup="updateLineTotal($event.target.value, 'quantity', prod)" />
                                <small v-if="isMultiUnit=='true'" style="font-weight: 500;font-size:70%;">
                                    {{prod.basicUnit}}
                                </small>
                            </td>
                            <td class="border-top-0 text-center" v-if="isMultiUnit=='true'">
                                {{prod.totalPiece}}
                            </td>

                            <td class="text-center border-top-0" v-if="isSerial && formName=='StockOut'">
                                <button @click="AddSerial(prod)" v-if="prod.isSerial" title="Add Serial" class="btn btn-primary btn-sm"> Add Serial </button>
                                <span v-else>-</span>
                            </td>
                            <td class="border-top-0  text-center" v-if="isSerial && formName=='StockOut'">
                                <datepicker v-model="prod.guaranteeDate" v-if="prod.guarantee" />
                                <span v-else>-</span>
                            </td>

                            <td class="border-top-0 text-center" v-if="purchaseProducts.filter(x => x.guarantee).length > 0 && isSerial && formName=='StockIn'">
                                <warranty-type-dropdown v-if="prod.guarantee" v-model="prod.warrantyTypeId" :values="prod.warrantyTypeId" />
                                <span v-else>
                                    -
                                </span>
                            </td>

                            <td class="border-top-0  text-center" v-if="purchaseProducts.filter(x => x.guarantee).length > 0 && isSerial && formName=='StockIn'">
                                <datepicker v-if="prod.guarantee" v-model="prod.guaranteeDate" />
                                <span v-else>
                                    -
                                </span>
                            </td>
                            <td class="border-top-0 text-center" v-if="purchaseProducts.filter(x => x.isSerial).length > 0  && isSerial && formName=='StockIn'">
                                <input type="text"
                                       v-model="prod.serialNo"
                                       v-if="prod.isSerial"
                                       @focus="$event.target.select()"
                                       v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'"
                                       class="form-control input-border text-center tableHoverOn" />
                                <span v-else>
                                    -
                                </span>
                            </td>



                            <td class="border-top-0 text-center" v-if="formName!='StockIn' && isFifo">
                                {{prod.batchNo}}

                            </td>

                            <td class="border-top-0 text-center" v-if="formName=='StockIn' && isFifo">
                                <input type="text" v-model="prod.batchNo"
                                       @focus="$event.target.select()" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'"
                                       class="form-control input-border text-center tableHoverOn" />

                            </td>
                            <td class="border-top-0 text-center" v-if="formName=='StockIn' && isFifo">
                                <datepicker v-model="prod.batchExpiry" />

                            </td>
                            <td class="border-top-0 text-center" v-if="formName!='StockIn' && isFifo">
                                {{getDate(prod.batchExpiry)}}

                            </td>

                            <td class="border-top-0 arabicLanguage">
                                {{currency}}  {{parseFloat(prod.lineTotal).toFixed(3).slice(0,-1) | formatAmount}}
                            </td>
                            <td class="border-top-0 pt-0">
                                <button @click="removeProduct(prod.rowId)"
                                        title="Remove Item"
                                        class="btn btn-secondary btn-neutral btn-sm btn-round btn-icon">
                                    <i class="nc-icon nc-simple-remove"></i>
                                </button>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <div class="card-footer col-md-3" v-else>
            <loading :name="loading" :active.sync="loading"
                     :can-cancel="false"
                     :is-full-page="true"></loading>
        </div>
        <div class=" table-responsive mt-3"
             v-bind:key="rendered + 'g'">
            <table class="table samary_tbl_pd ">
                <thead class="m-0">
                    <tr class="arabicLanguage">
                        <th class="text-center" style="width:85px;">
                            {{ $t('StockLineDetails.NoItem') }}
                        </th>
                        <th class="text-center" style="width:100px;" v-if="isMultiUnit=='true'">
                            {{ $t('StockLineDetails.TotalCarton') }}
                        </th>
                        <th class="text-center" style="width:100px;" v-if="isMultiUnit=='true'">
                            {{ $t('StockLineDetails.TotalPieces') }}
                        </th>
                        <th class="text-center" style="width:100px;">
                            {{ $t('StockLineDetails.TotalQty') }}
                        </th>
                        <th class="text-center" style="width:100px;" v-if="formName=='StockOut'">
                            {{ $t('StockLineDetails.VAT') }}
                        </th>
                        <th style="width:100px;">
                            {{ $t('StockLineDetails.Total') }}
                        </th>

                    </tr>
                </thead>
                <tbody>
                    <tr class="text-right samary_tbl_pd">
                        <td class="text-center">
                            {{ summary.item }}
                        </td>
                        <td class="text-center" v-if="isMultiUnit=='true'">
                            {{ summary.totalCarton}}
                        </td>
                        <td class="text-center" v-if="isMultiUnit=='true'">
                            {{ summary.totalPieces}}
                        </td>
                        <td class="text-center">
                            {{ summary.qty }}
                        </td>
                        <td class="text-center" v-if="formName=='StockOut'">
                            {{currency}} {{parseFloat(summary.vat).toFixed(3).slice(0,-1)}}
                        </td>
                        <td>
                            {{currency}} {{parseFloat(summary.total).toFixed(3).slice(0,-1)}}
                        </td>

                    </tr>
                </tbody>
            </table>
        </div>
        <add-serial-model :item="serialItem"
                          :show="showSerial"
                          v-if="showSerial"
                          @input="updateSerial"
                          @close="showSerial = false" />
    </div>
</template>


<script>
    import moment from "moment";
    import clickMixin from '@/Mixins/clickMixin'

    export default {
        name: "StockLineItem",
        mixins: [clickMixin],
        props: ['purchase', 'purchaseItems', 'taxMethod', 'taxRateId', 'formName', 'wareHouseId'],

        data: function () {
            return {
                isSerial: false,
                isFifo: false,
                decimalQuantity: false,
                rendered: 0,
                product: {
                    id: "",
                },
                isMultiUnit: '',
                vats: [],
                products: [],
                purchaseProducts: [],
                loading: false,
                summary: {
                    item: 0,
                    qty: 0,
                    total: 0,
                    vat: 0,
                    totalCarton: 0,
                    totalPieces: 0
                },
                currency: '',
                searchTerm: '',
                productList: [],
                serialItem: '',
                showSerial: false
            };
        },
        validations: {},
        filters: {

        },
        computed: {
            itemDisable() {
                if (this.taxMethod != '' && this.taxMethod != null && this.taxRateId != '00000000-0000-0000-0000-000000000000' && this.taxRateId != undefined)
                    return false;
                return true;
            }
        },
        methods: {
            getDate: function (x) {
                return moment(x).format("l");
            },
            AddSerial: function (item) {
                
                this.serialItem = item;
                this.showSerial = true;
            },

            updateSerial: function (serial, item) {
                
                var prod = this.purchaseProducts.find(x => x.rowId == item.rowId);
                if (prod != undefined) {
                    prod.serial = serial;
                }
                this.showSerial = false;
            },

            //GetProductList: function () {

            //    var root = this;
            //    var token = "";
            //    if (this.$session.exists()) {
            //        token = localStorage.getItem("token");
            //    }


            //    // var url = this.wareHouseId != undefined ? "/Product/GetProductInformation?searchTerm=" + search + '&wareHouseId=' + this.wareHouseId + "&isDropdown=true" + '&isRaw=' + root.isRaw : "/Product/GetProductInformation?searchTerm=" + search + '&status=' + root.status + "&isDropdown=true" + '&isRaw=' + root.isRaw;
            //    var url = ''
            //    if (this.formName == 'StockOut') {
            //        url = "/Product/GetProductBarcode?wareHouseId=" + this.wareHouseId + '&isRaw=' + false
            //    }
            //    else {
            //        url = "/Product/GetProductBarcode?isRaw=" + false
            //    }
            //    this.$https
            //        .get(url, {
            //            headers: { Authorization: `Bearer ${token}` },
            //        })
            //        .then(function (response) {
            //            if (response.data != null) {
            //                root.productList = response.data.results.products;

            //            }
            //        });


            //},
            //onBarcodeScanned(barcode) {
            //    if (localStorage.getItem("BarcodeScan") != 'StockLine')
            //        return
            //    var root = this;

            //    if (root.productList.length > 0) {
            //        var product = this.productList.find(x => x.barCode == barcode)
            //        if (product != null) {
            //            root.addProduct(product.id, product)
            //        }
            //    }

            //},
            CheckSalePrice: function (price, y) {

                var prod = this.purchaseProducts.find((x) => x.productId == y);
                if (parseFloat(price) > prod.salePrice) {
                    this.$swal({
                        title: "Error!",
                        text: "Unit Price Geater than Sale Price!",
                        type: 'error',
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });

                }
            },
            changeProduct: function (NewProdId, rowId) {
                this.purchaseProducts = this.purchaseProducts.filter(x => x.rowId != rowId);
                this.addProduct(NewProdId);

            },
            calcuateSummary: function () {

                this.summary.item = this.purchaseProducts.length;
                if (this.decimalQuantity) {
                    this.summary.totalPieces = this.purchaseProducts.reduce((totalQty, prod) => totalQty + parseFloat(prod.quantity), 0);
                }
                else {
                    this.summary.totalPieces = this.purchaseProducts.reduce((totalQty, prod) => totalQty + parseInt(prod.quantity), 0);
                }

                if (this.decimalQuantity) {
                    this.summary.totalCarton = this.purchaseProducts.reduce((totalCarton, prod) => totalCarton + parseFloat(prod.highQty), 0);
                }
                else {
                    this.summary.totalCarton = this.purchaseProducts.reduce((totalCarton, prod) => totalCarton + parseInt(prod.highQty), 0);
                }

                if (this.decimalQuantity) {
                    this.summary.qty = this.purchaseProducts.reduce((qty, prod) => qty + parseFloat(prod.totalPiece == '' ? 0 : prod.totalPiece), 0);
                    this.summary.qty = parseFloat(this.summary.qty).toFixed(2);
                }
                else {
                    this.summary.qty = this.purchaseProducts.reduce(
                        (qty, prod) => qty + parseInt(prod.totalPiece == '' ? 0 : prod.totalPiece), 0);
                }


                this.summary.total = this.purchaseProducts
                    .reduce((total, prod) => total + parseInt(prod.totalPiece == '' ? 0 : prod.totalPiece) * prod.price, 0)
                    .toFixed(3).slice(0, -1);

                if (this.taxMethod == 'Inclusive' || this.taxMethod == 'شامل') {
                    this.summary.vat = this.purchaseProducts
                        .reduce((vat, prod) => vat + (parseInt(prod.totalPiece == '' ? 0 : prod.totalPiece) * prod.price) * prod.rate / (100 + prod.rate), 0)
                        .toFixed(3).slice(0, -1);
                }
                if (this.taxMethod == 'Exclusive' || this.taxMethod == 'غير شامل') {
                    this.summary.vat = this.purchaseProducts
                        .reduce((vat, prod) => vat + (parseInt(prod.totalPiece == '' ? 0 : prod.totalPiece) * prod.price) * prod.rate / 100, 0)
                        .toFixed(3).slice(0, -1);
                    this.summary.total = (parseFloat(this.summary.total) + parseFloat(this.summary.vat)).toFixed(3).slice(0, -1);
                }

                this.$emit("input", this.purchaseProducts);
            },

            updateBatch: function (productId, batch) {
                debugger
                var prd = this.purchaseProducts.find(x => x.productId == productId);
                if (prd != undefined) {
                    prd.batchNo = batch.batchNumber;
                    prd.batchExpiry = batch.expiryDate;
                }
                this.updateLineTotal(prd.quantity, "quantity", prd);
            },

            updateLineTotal: function (e, prop, product) {
                debugger
                if (prop == "price") {
                    if (e < 0) {
                        e = 0;
                    }
                    product.price = e;
                }

                if (prop == "quantity") {
                    if (e <= 0 || e == '') {
                        e = '';
                    }
                    if (String(e).split('.').length > 1 && String(e).split('.')[1].length > 2)
                        e = parseFloat(String(e).slice(0, -1))
                    product.quantity = this.decimalQuantity ? e : Math.round(e);
                }
                if (prop == "highQty") {
                    if (e < 0 || e == '' || e == undefined) {
                        e = '';
                    }
                    product.highQty = Math.round(e);
                }
                //if (product.price == "") {
                //    product.price = 0;
                //}
                //if (product.quantity == "") {
                //    product.quantity = 0;
                //}

                product.totalPiece = (parseFloat(product.highQty == undefined ? 0 : product.highQty) * parseFloat((product.unitPerPack == null || product.unitPerPack == 0 || product.unitPerPack == undefined) ? 1 : product.unitPerPack)) + parseFloat(product.quantity == '' ? 0 : product.quantity);
                if (product.currentQuantity >= product.totalPiece || product.currentQuantity == undefined) {
                    this.$set(product, 'outOfStock', false);
                }
                else {
                    if (this.formName == 'StockIn') {
                        this.$set(product, 'outOfStock', false);
                    }
                    else {
                        this.$set(product, 'outOfStock', true);

                    }
                }

                product.lineTotal = (product.totalPiece == '' ? 0 : product.totalPiece) * product.price;

                var calculateVAt = 0;
                if (product.taxMethod == 'Inclusive' || product.taxMethod == 'شامل') {
                    calculateVAt = (product.lineTotal * product.rate) / (100 + product.rate);
                    product.lineTotal = product.lineTotal - calculateVAt;
                }
                if (product.taxMethod == 'Exclusive' || product.taxMethod == 'غير شامل') {
                    calculateVAt = (product.lineTotal * product.rate) / 100;
                    product.lineTotal = product.lineTotal + calculateVAt;
                }

                this.$set(this.purchaseProducts, product, product);

                this.calcuateSummary();

                this.$emit("input", this.purchaseProducts);
            },

            addProduct: function (productId, newProduct) {
                var prod = '';
                var uid = this.createUUID();
                if (this.formName != 'StockIn' && this.isFifo) {
                    var inventoryData = {
                        autoNumbering: newProduct.inventory.autoNumbering,
                        averagePrice: newProduct.inventory.averagePrice,
                        batchNumber: newProduct.inventory.batchNumber,
                        bundleId: newProduct.inventory.bundleId,
                        buy: newProduct.inventory.buy,
                        currentQuantity: newProduct.inventory.currentQuantity,
                        currentStockValue: newProduct.inventory.currentStockValue,
                        date: newProduct.inventory.date,
                        documentId: newProduct.inventory.documentId,
                        documentNumber: newProduct.inventory.documentNumber,
                        expiryDate: newProduct.inventory.expiryDate,
                        get: newProduct.inventory.get,
                        id: newProduct.inventory.id,
                        isActive: newProduct.inventory.isActive,
                        isOpen: newProduct.inventory.isOpen,
                        price: newProduct.inventory.price,
                        product: newProduct.inventory.product,

                        productId: newProduct.inventory.productId,
                        promotionId: newProduct.inventory.promotionId,
                        quantity: newProduct.inventory.quantity,
                        remainingQuantity: newProduct.inventory.remainingQuantity,
                        salePrice: newProduct.inventory.salePrice,
                        serial: newProduct.inventory.serial,
                        stock: newProduct.inventory.stock,
                        stockId: newProduct.inventory.stockId,
                        transactionType: newProduct.inventory.transactionType,
                        wareHouseId: newProduct.inventory.wareHouseId,
                        warrantyDate: newProduct.inventory.warrantyDate,

                    };

                    this.products.push({
                        rowId: uid,
                        arabicName: newProduct.arabicName,
                        assortment: newProduct.assortment,
                        barCode: newProduct.barCode,
                        basicUnit: newProduct.basicUnit,
                        batchExpiry: newProduct.batchExpiry,
                        batchNo: newProduct.batchNo,
                        brandId: newProduct.brandId,
                        bundleCategory: newProduct.bundleCategory,
                        category: newProduct.category,
                        categoryId: newProduct.categoryId,
                        code: newProduct.code,
                        colorId: newProduct.colorId,
                        colorName: newProduct.colorName,

                        colorNameArabic: newProduct.colorNameArabic,
                        currentQuantity: newProduct.currentQuantity,
                        description: newProduct.description,
                        englishName: newProduct.englishName,
                        guarantee: newProduct.guarantee,
                        id: newProduct.id,
                        image: newProduct.image,
                        inventory: inventoryData,
                        inventoryBatch: newProduct.inventoryBatch,
                        isActive: newProduct.isActive,
                        isExpire: newProduct.isExpire,
                        isRaw: newProduct.isRaw,

                        length: newProduct.length,
                        levelOneUnit: newProduct.levelOneUnit,
                        originId: newProduct.originId,
                        promotionOffer: newProduct.promotionOffer,
                        purchasePrice: newProduct.purchasePrice,
                        salePrice: newProduct.salePrice,
                        salePriceUnit: newProduct.salePriceUnit,
                        saleReturnDays: newProduct.saleReturnDays,
                        serial: newProduct.serial,
                        serviceItem: newProduct.serviceItem,

                        shelf: newProduct.shelf,
                        sizeId: newProduct.sizeId,
                        sizeName: newProduct.sizeName,
                        sizeNameArabic: newProduct.sizeNameArabic,
                        stockLevel: newProduct.stockLevel,
                        styleNumber: newProduct.styleNumber,
                        subCategoryId: newProduct.subCategoryId,
                        taxMethod: newProduct.taxMethod,
                        taxRate: newProduct.taxRate,
                        taxRateId: newProduct.taxRateId,
                        taxRateValue: newProduct.taxRateValue,
                        unit: newProduct.unit,
                        unitId: newProduct.unitId,

                        unitPerPack: newProduct.unitPerPack,
                        width: newProduct.width,
                    });

                    prod = this.products.find((x) => x.rowId == uid);


                }
                else {
                    if (this.products.find(x => x.id == newProduct.id) == undefined || this.products.length <= 0) {
                        this.products.push(newProduct);
                    }
                    prod = this.products.find((x) => x.id == productId);
                }
               
                //var prod = this.products.find((x) => x.rowId == uid);
                var rate = 0;
                var rateId = '00000000-0000-0000-0000-000000000000';
                if (this.taxRateId != "00000000-0000-0000-0000-000000000000" && this.taxRateId != undefined && this.taxRateId != "") {
                    rate = this.vats.find((value) => value.id == this.taxRateId).rate;
                    rateId = this.taxRateId;
                }
                else {
                    rate = 0;
                    rateId = '00000000-0000-0000-0000-000000000000';
                }
                if (this.formName == 'StockIn') {
                    this.purchaseProducts.push({
                        rowId: this.createUUID(),
                        productId: prod.id,
                        price: prod.inventory != null ? prod.inventory.price : 0,
                        salePrice: prod.salePrice,
                        quantity: 0,
                        highQty: 0,
                        totalPiece: 1,
                        currentQuantity: prod.inventory != null ? prod.inventory.currentQuantity : 0,
                        lineTotal: 0,
                        taxMethod: this.taxMethod,
                        taxRateId: rateId,
                        rate: rate,
                        isExpire: prod.isExpire,
                        batchNo: "",
                        batchExpiry: "",
                        inventoryList: prod.inventoryBatch == null ? null : prod.inventoryBatch,
                        unitPerPack: prod.unitPerPack,
                        levelOneUnit: prod.levelOneUnit,
                        basicUnit: prod.basicUnit,
                        serial: '',
                        guaranteeDate: '',

                        guarantee: prod.guarantee,
                        isSerial: prod.serial,
                        serialNo: '',
                        warrantyTypeId: '',
                    });
                }
                else {
                    this.purchaseProducts.push({
                        rowId: uid,
                        productId: prod.id,
                        price: prod.inventory != null ? prod.inventory.price : 0,
                        salePrice: prod.salePrice,
                        quantity: 0,
                        highQty: 0,
                        totalPiece: 1,
                        currentQuantity: prod.inventory != null ? prod.inventory.currentQuantity : 0,
                        lineTotal: 0,
                        taxMethod: this.taxMethod,
                        taxRateId: rateId,
                        rate: rate,
                        isExpire: prod.isExpire,
                        batchNo: prod.batchNo,
                        batchExpiry: prod.batchExpiry,
                        inventoryList: prod.inventoryBatch == null ? null : prod.inventoryBatch,
                        unitPerPack: prod.unitPerPack,
                        levelOneUnit: prod.levelOneUnit,
                        basicUnit: prod.basicUnit,
                        serial: '',
                        guaranteeDate: '',

                        guarantee: prod.guarantee,
                        isSerial: prod.serial,
                        serialNo: '',
                        warrantyTypeId: '',
                    });
                }


                this.product.id = "";
                var product = this.purchaseProducts.find((x) => {
                    return x.productId == productId && x.rowId == uid;
                });
                this.updateLineTotal(product.price, "unitPrice", product);
                this.rendered++;
            },
            createUUID: function () {
                var dt = new Date().getTime();
                var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                    var r = (dt + Math.random() * 16) % 16 | 0;
                    dt = Math.floor(dt / 16);
                    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
                });
                return uuid;
            },


            removeProduct: function (id) {

                this.purchaseProducts = this.purchaseProducts.filter((prod) => {
                    return prod.rowId != id;
                });

                this.calcuateSummary();
            },

            //getDate: function (x) {
                
            //    if (x != null && x != undefined) {
            //        return moment(x).format("l");
            //    }
            //},

            getData: function () {
                var root = this;
                var token = "";
                if (this.$session.exists()) {
                    token = localStorage.getItem("token");
                }
                if (root.formName == 'StockIn' && root.$route.query.data != undefined)
                    root.loading = true;
                root.$https
                    .get("/Product/TaxRateList", {
                        headers: { Authorization: `Bearer ${token}` },
                    }).then(function (response) {
                        if (response.data != null) {
                            root.vats = response.data.taxRates;
                        }
                    }).then(function () {
                        
                        if (root.$route.query.data != undefined) {
                            if (root.taxRateId != '' && root.taxRateId != null) {
                                if (root.$route.query.data != undefined) {
                                    root.$route.query.data.stockAdjustmentDetails.forEach(function (item) {
                                        root.purchaseProducts.push({
                                            rowId: item.id,
                                            id: item.id,
                                            product: item.product,
                                            batchNo: item.batchNo,
                                            batchExpiry: item.batchExpiry,
                                            inventoryList: item.product.inventoryBatch == null ? null : item.product.inventoryBatch,
                                            currentQuantity: item.product.inventory != null ? item.product.inventory.currentQuantity : 0,
                                            productId: item.productId,
                                            stockAdjustmentId: item.stockAdjustmentId,
                                            quantity: item.quantity,
                                            highQty: item.highQty,
                                            totalPiece: item.totalPiece,
                                            salePrice: item.salePrice,
                                            price: item.price,
                                            rate: root.vats.find((value) => value.id == root.taxRateId).rate,
                                            taxMethod: root.taxMethod,
                                            taxRateId: root.taxRateId,
                                            unitPerPack: item.product.unitPerPack,
                                            levelOneUnit: item.product.levelOneUnit,
                                            basicUnit: item.product.basicUnit,
                                            serial: item.serial,
                                            guaranteeDate: item.guaranteeDate,
                                            warrantyTypeId: item.warrantyTypeId,
                                            serialNo: item.serialNo,
                                            guarantee: item.product.guarantee,
                                            isSerial: item.product.serial,
                                        });
                                    });
                                }
                            }
                            else {
                                if (root.$route.query.data != undefined) {
                                    root.$route.query.data.stockAdjustmentDetails.forEach(function (item) {
                                        root.purchaseProducts.push({
                                            rowId: item.id,
                                            id: item.id,
                                            product: item.product,
                                            inventoryList: item.product.inventoryBatch == null ? null : item.product.inventoryBatch,
                                            currentQuantity: item.product.inventory != null ? item.product.inventory.currentQuantity : 0,
                                            productId: item.productId,
                                            stockAdjustmentId: item.stockAdjustmentId,
                                            quantity: item.quantity,
                                            highQty: item.highQty,
                                            totalPiece: item.totalPiece,
                                            salePrice: item.salePrice,
                                            price: item.price,
                                            serial: item.serial,
                                            guaranteeDate: item.guaranteeDate,
                                            warrantyTypeId: item.warrantyTypeId,
                                            serialNo: item.serialNo,
                                            rate: 0,
                                            taxMethod: root.taxMethod,
                                            taxRateId: root.taxRateId,
                                            unitPerPack: item.product.unitPerPack,
                                            levelOneUnit: item.product.levelOneUnit,
                                            basicUnit: item.product.basicUnit,
                                            guarantee: item.product.guarantee,
                                            isSerial: item.product.serial,
                                            isExpire: item.product.isExpire,
                                            batchNo: item.batchNo,
                                            batchExpiry: item.batchExpiry
                                        });
                                    });

                                }
                            }
                            debugger
                            for (var s = 0; s < root.purchaseProducts.length; s++) {
                                root.products.push(root.purchaseProducts[s].product);
                                root.updateLineTotal(root.purchaseProducts[s].quantity, "quantity", root.purchaseProducts[s]);
                                if (root.isMultiUnit == 'true') {
                                    root.updateLineTotal(root.purchaseProducts[s].highQty, "highQty", root.purchaseProducts[s]);
                                }

                                root.updateLineTotal(root.purchaseProducts[s].price, "price", root.purchaseProducts[s]);
                            }
                            root.calcuateSummary()
                            root.loading = false
                        }
                    });
            },
        },
        created: function () {
            //this.$barcodeScanner.init(this.onBarcodeScanned);
            //For Scanner Code
           // var root = this;
            //var barcode = '';
            //var interval;
            //document.addEventListener('keydown', function (evt) {
            //    if (interval)
            //        clearInterval(interval);
            //    if (evt.code === 'Enter') {
            //        if (barcode) {
            //            root.onBarcodeScanned(barcode);
            //        }
            //        barcode = '';
            //        return;

            //    }
            //    if (evt.key !== 'Shift')
            //        barcode += evt.key;
            //});
            localStorage.setItem("BarcodeScan", 'StockLine')
            //End
            //this.$barcodeScanner.init(this.onBarcodeScanned);
            this.isFifo = localStorage.getItem('fIFO') == 'true' ? true : false;
            this.getData();
        },
        mounted: function () {
            //this.GetProductList();
            this.decimalQuantity = localStorage.getItem('decimalQuantity') == 'true' ? true : false;
            this.currency = localStorage.getItem('currency');
            this.isMultiUnit = localStorage.getItem('IsMultiUnit');
            this.isSerial = localStorage.getItem('IsSerial') == 'true' ? true : false;
        },
    };
</script>


<style scoped>
    #sale-item td {
        padding-bottom: 0px;
        padding-top: 0px;
    }

    .input-border {
        border: transparent;
    }

        .input-border:focus {
            outline: none !important;
            border: none !important;
        }

    .multiselect__tags {
        background-color: transparent !important;
    }

    .multiselect__input, .multiselect__single {
        background-color: transparent !important;
    }

    .tableHoverOn {
        background-color: #ffffff !important;
        height: 32px !important;
        max-height: 32px !important;
    }
</style>
