<template>
    <div>
        <div class="card m-4">
            <div v-if="purchaseorder.id == '00000000-0000-0000-0000-000000000000'">
                <h5 class="card-title ml-4"> Add Invoice</h5>
            </div>
            <div v-else>

                <h5 class="card-title ml-4 mt-2"> Update Invoice</h5>

            </div>
            <div class="card-body">
                <div class="row">

                    <div :key="render" class="form-group has-label col-sm-4 " v-bind:class="{'has-danger' : $v.purchaseorder.poNumber.$error}">
                        <label class="text  font-weight-bolder"> P.O Number: </label>
                        <input class="form-control" v-model="$v.purchaseorder.poNumber.$model" :key='render' type="text" disabled />
                        <span v-if="$v.purchaseorder.poNumber.$error" class="error">
                            <span v-if="!$v.purchaseorder.poNumber.maxLength">P.O Number is required</span>
                            <span v-if="!$v.purchaseorder.poNumber.required">P.O Number Info has maximum Length 5</span>
                        </span>
                    </div>
                    <div class="form-group has-label col-sm-4" v-bind:class="{'has-danger' : $v.purchaseorder.poDate.$error}">
                        <label class="text  font-weight-bolder">PO Date:</label>
                        <datepicker v-model="$v.purchaseorder.poDate.$model"></datepicker>
                        <span v-if="$v.purchaseorder.poDate.$error" class="error">
                            <span v-if="!$v.purchaseorder.poDate.required">purchaseorder Date is required</span>
                        </span>

                    </div>
                    <div class="form-group has-label col-sm-4" v-bind:class="{'has-danger' : $v.purchaseorder.supplierId.$error}">
                        <label class="text  font-weight-bolder">Supplier:</label>
                        <supplierdropdown :isCustomer="false" v-model="$v.purchaseorder.supplierId.$model" :v-bind="purchaseorder.supplierId"></supplierdropdown>
                        <span v-if="$v.purchaseorder.supplierId.$error" class="error">
                            <span v-if="!$v.purchaseorder.supplierId.required">Supplier is required</span>
                        </span>

                    </div>





                </div>
                <div class="row">
                    <div class="form-group has-label col-sm-8" v-bind:class="{'has-danger' : $v.purchaseorder.note.$error}">
                        <label>Note</label>
                        <textarea v-model="$v.purchaseorder.note.$model" class="form-control"></textarea>
                        <span v-if="$v.purchaseorder.note.$error" class="error">
                            <span v-if="!$v.purchaseorder.note.maxLength">Note has max length 200</span>
                        </span>

                    </div>


                </div>


            </div>
            <div class="text-right">
                <div class="card-footer " v-if="purchaseorder.id != '00000000-0000-0000-0000-000000000000'">
                    <button type="button" class="btn btn-primary  m-2" v-on:click="Save" v-bind:disabled="$v.purchaseorder.$invalid"> Update</button>
                    <button type="button" class="btn btn-secondary  mr-3" v-on:click="close()">Cancel</button>
                </div>
                <div class="card-footer   " v-else>
                    <button type="button" class="btn btn-primary  m-2" v-on:click="Save" v-bind:disabled="$v.purchaseorder.$invalid"> Save</button>
                    <button type="button" class="btn btn-secondary  mr-3" v-on:click="close()">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { required, maxLength } from "vuelidate/lib/validators"
    export default {
        data: function () {
            return {
                render: 0,
                purchaseorder: []
            }
        },
        validations: {
            purchaseorder: {
                poNumber: {
                    required,
                    maxLength: maxLength(5)
                },

                poDate: {
                    required
                },
                supplierId: {
                    required
                },
                note: {
                    maxLength: maxLength(200)
                },


            },
        },
        methods: {
            Save: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                this.$https.post('/PurchaseOrder/SavePurchaseOrder', this.purchaseorder, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.$swal({
                            icon: 'success',
                            title: 'Saved Successfully',
                            showConfirmButton: false,
                            timer: 1800,
                            timerProgressBar: true,
                        });
                        root.$router.push('/invoice'); 
                    } else {
                        root.$swal({
                            title: "Error!",
                            text: "Please enter correct value and try again",
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            buttonsStyling: false
                        });
                    }
                },
                    function () {
                        root.$swal({
                            title: "Error!",
                            text: "Please enter correct value and try again",
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            buttonsStyling: false
                        });
                    });
            },
            close: function () {
                this.$router.push('/invoice');   
            },
        },
        mounted: function () {
            
            if (this.purchaseorder.id == '00000000-0000-0000-0000-000000000000' || this.purchaseorder.id == undefined || this.purchaseorder.id == '')
                
            this.purchaseorder = this.data;
            this.render++;
        }
    }
</script>