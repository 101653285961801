<template>
    <div class="row " v-if="isValid('CanViewCustomer')"  >
        <div class="col-lg-12 col-sm-12 ml-auto mr-auto">
            <div class="row mb-4" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                <div class="col-sm-6 col-md-6 col-lg-6">
                    <h5 class="page_title">{{ $t('Customer.Customer') }}</h5>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link :to="'/StartScreen'"><a href="javascript:void(0)"> {{ $t('Customer.Home') }}</a></router-link></li>

                            <li class="breadcrumb-item active" aria-current="page">{{ $t('Customer.DailyExpense') }}</li>
                        </ol>
                    </nav>
                </div>
                <div class=" col-sm-6 col-md-6 col-lg-6">
                    <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'" v-if="isValid('CanImportCustomer') && ((rowCount < limitedCustomer) || (limitedCustomer == 0))">
                        <a href="javascript:void(0)" class="btn btn-outline-primary " v-on:click="ImportDataFromXlsx">{{$t('Customer.ImportCustomer')}}</a>
                    </div>

                </div>
            </div>
            <div class="card ">
                <div class="card-header" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" >
                    <div class="row mb-3 ">
                        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label>{{ $t('Customer.SearchbyBrand') }}</label>
                                <div>
                                    <input type="text" class="form-control search_input" v-model="search" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" name="search" id="search" :placeholder="$t('Customer.Search')" />
                                    <span class="fas fa-search search_icon"></span>
                                </div>
                            </div>
                        </div>  
                        <div class="col-lg-8 col-md-8 col-sm-6 col-12 " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left' ">
                                <a href="javascript:void(0)" v-if="isValid('CanAddCustomer') && ((rowCount < limitedCustomer) || (limitedCustomer == 0))" class="btn btn-primary " v-on:click="AddCustomer"><i class="fa fa-plus"></i> {{ $t('Customer.AddNew') }}</a>
                                <router-link :to="'/StartScreen'"><a href="javascript:void(0)" class="btn btn-outline-danger "> {{ $t('Customer.Close') }}</a></router-link>
                        </div>
                    </div>
                </div>
            </div>
          
            <div class="card">
                <div class="mt-2">
                    <div class="col-lg-12">                       
                        <div class="mt-2" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'arabicLanguage'">
                            <div class=" table-responsive">
                                <table class="table table-hover table-striped table_list_bg ">
                                    <thead class="mt-0">
                                        <tr>
                                            <th>#</th>
                                            <th v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">{{ $t('Customer.Code') }} </th>
                                            <th v-if="english=='true'" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">{{ $t('Customer.CustomerName(English)') |englishLanguage}} </th>
                                            <th v-if="isOtherLang()" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">{{ $t('Customer.CustomerName(Arabic)') |arabicLanguage}}</th>
                                            <th v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">{{ $t('Customer.CRN') }}</th>
                                            <th v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">{{ $t('Customer.RegDate') }}</th>
                                            <th v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">{{ $t('Customer.Category') }}</th>
                                            <th class="text-center">{{ $t('Customer.RunningBalance') }}</th>
                                            <th v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">{{ $t('Customer.PaymentType') }}</th>

                                            <th>{{ $t('Customer.Status') }}</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(cust,index) in customerlist" v-bind:key="cust.id">
                                            <td>
                                                {{index+1}}
                                            </td>
                                            <td v-if="isValid('CanEditCustomer')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                                <strong>
                                                    <a href="javascript:void(0)" v-on:click="EditCustomer(cust.id)">{{cust.code}}</a>
                                                </strong>
                                            </td>
                                            <td v-else v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                                {{cust.code}}
                                            </td>
                                            <td v-if="english=='true'" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">{{cust.englishName}}</td>
                                            <td v-if="isOtherLang()" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">{{cust.arabicName}}</td>
                                            <td v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">{{cust.commercialRegistrationNo}}</td>
                                            <td v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">{{cust.registrationDate}}</td>
                                            <td v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">{{cust.category}}</td>
                                            <td class="text-center">{{cust.openingBalance>=0?'Dr '+ cust.openingBalance*+1: 'Cr '+ cust.openingBalance*(-1) }}</td>
                                            <td v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">{{cust.paymentTerms}}</td>
                                            <td>{{cust.isActive==true?$t('Customer.Active'):$t('Customer.De-Active')}}</td>
                                            <!--<td><a href="javascript:void(0)" class="btn btn-danger btn-sm btn-icon " v-on:click="RemoveCustomer(cust.id)"><i class=" fa fa-trash"></i></a></td>-->
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="float-left">
                        <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                        <span v-else-if="currentPage===1 && rowCount < 10">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage===1 && rowCount >= 11  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                    </div>
                    <div class="float-right">
                        <div class="overflow-auto" v-on:click="getPage()" v-if="($i18n.locale == 'en' ||isLeftToRight())">
                            <b-pagination pills size="lg" v-model="currentPage"
                                          :total-rows="rowCount"
                                          :per-page="10"
                                          first-text="First"
                                          prev-text="Previous"
                                          next-text="Next"
                                          last-text="Last"></b-pagination>
                        </div>
                        <div class="overflow-auto" v-on:click="getPage()" v-else>
                            <b-pagination pills size="lg" v-model="currentPage"
                                          :total-rows="rowCount"
                                          :per-page="10"
                                          first-text="الأولى"
                                          prev-text="السابقة"
                                          next-text="التالية"
                                          last-text="الأخيرة"></b-pagination>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        name: 'Customer',
        mixins: [clickMixin],
        data: function () {
            return {
                arabic: '',
                english: '',
                customerlist: [],
                search: '',
                customer: [],
                loading: true,
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                limitedCustomer: 0
            }
        },
        watch: {
            search: function (val) {
                this.GetCustomerData(val, 1);
            }
        },
        methods: {
            ImportDataFromXlsx: function () {
                var root = this;
                root.$router.push({
                    path: '/ImportExportRecords',
                    query: { data: 'Customer' }
                })
            },
            AddCustomer: function () {
                this.$router.push('/addCustomer2')
            },

            EditCustomer: function (Id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.get('/Contact/ContactDetail?Id=' + Id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.customer = response.data
                    }
                    root.$router.push({
                        path: '/addCustomer2',
                        query: {
                            data: root.customer
                        }
                    })
                });
            },
            getPage: function () {
                this.GetCustomerData(this.search, this.currentPage);
            },

            GetCustomerData: function (search, currentPage) {
                var root = this;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.get('/Contact/ContactList?isCustomer=true' + '&searchTerm=' + search + '&pageNumber=' + currentPage, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {


                        // root.$store.state.customerlist.push(response.data.contacts)
                        root.customerlist = response.data.results;
                        root.pageCount = response.data.pageCount;
                        root.rowCount = response.data.rowCount;

                    }
                });
            },
            RemoveCustomer: function (id) {
                var root = this;
                // working with IE and Chrome both
                this.$swal({
                    icon: 'error',
                    title: "Are you sure?",
                    text: "You will not be able to recover this!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes, delete it!",
                    closeOnConfirm: false,
                    closeOnCancel: false
                }).then(function (result) {
                    if (result) {
                        var token = '';
                        if (root.$session.exists()) {
                            token = localStorage.getItem('token');
                        }
                        root.$https
                            .get('/Contact/ContactDelete?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } })
                            .then(function (response) {
                                if (response.data.id != '00000000-0000-0000-0000-000000000000') {
                                    root.$store.state.customerlist.splice(root.$store.state.customerlist.findIndex(function (i) {
                                        return i.id === response.data;
                                    }), 1);
                                    root.$swal({
                                        title: 'Deleted!',
                                        text: response.data.isAddUpdate,
                                        type: 'success',
                                        confirmButtonClass: "btn btn-success",
                                        buttonsStyling: false
                                    });
                                } else {
                                    root.$swal({
                                        title: "Error!",
                                        text: response.data.isAddUpdate,
                                        type: 'error',
                                        confirmButtonClass: "btn btn-danger",
                                        buttonsStyling: false
                                    });
                                }
                            },
                                function () {
                                    root.loading = false;
                                    root.$swal({
                                        title: "Error!",
                                        text: "Something Went Wrong",
                                        type: 'error',
                                        confirmButtonClass: "btn btn-danger",
                                        buttonsStyling: false
                                    });
                                });
                    }
                    else {
                        this.$swal('Cancelled', 'Your file is still intact', 'info');
                    }
                });
            },
        },
        created: function () {
            
            this.$emit('input', this.$route.name);
        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.limitedCustomer = parseInt(localStorage.getItem('LimitedCustomer'));
            if (isNaN(this.limitedCustomer)) {
                this.limitedCustomer = 0
            }
            this.GetCustomerData(this.search, 1);
            

        }
    }
</script>